import styled from 'styled-components';

import { COLOR } from 'shared/consts';

export const StyledCard = styled.div`
  // min-width: 176px;
  background-color: ${COLOR.white};
  border-radius: 10px;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
  // overflow: hidden; // для градиента
`;

export const ConnectorInfo = styled.div`
  padding: 7px 10px 0px;
  margin-bottom: 8px;
`;

export const ConnectorInfoHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 1px;
`;

export const ConnectorControls = styled.div`
  display: flex;
  gap: 5px;
`;

export const ConnectorControlIcon = styled.img`
  height: 18px;
`;

export const ConnectorParams = styled.div`
  display: flex;
  gap: 4px;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: rgba(47, 47, 47, 0.5);
  width: max-content;
`;

export const ConnectorTypename = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: ${COLOR.connectorTitleColor};
`;

export const ConnectorIconContainer = styled.div`
  position: relative;
  height: 174px;
  width: 123px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Gradient = styled.div`
  background: linear-gradient(0deg, #fff 39.39%, rgba(255, 255, 255, 0) 95.45%);
  position: absolute;
  bottom: -5px;
  left: 0;
  width: calc(100% - 6px);
  // width: 187px;
  height: 40px;
`;

export const ConnectorIcon = styled.img`
  width: 100%;
  max-height: 100%;
  position: absolute;
  left: -6px;
`;

export const LiterBadgeContainer = styled.div`
  border-radius: 50%;
  padding: 2px;
`;

export const LiterBadge = styled.div`
  border: 4px solid ${COLOR.white};
  border-radius: 50%;
  background-color: ${COLOR.greenMenu};
  color: ${COLOR.white};
  font-weight: 600;
  font-size: 16px;
  position: absolute;
  z-index: 900;
  bottom: 0;
  left: 10px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StatusesContainer = styled.div`
  display: flex;
  gap: 6px;
  padding: 0 10px 10px;

  & > * {
    flex-grow: 1;
  }
`;

export const ConnectorMainContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

export const TariffInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-right: 10px;
  padding-top: 22px;
`;

export const TariffParamGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 2px;
`;

export const TariffParamName = styled.p`
  text-align: end;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  color: ${COLOR.connectorTitleColor};
`;

export const TariffParamValueContainer = styled.div`
  display: flex;
  align-items: end;
`;

export const TariffParamValue = styled.span`
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  color: ${COLOR.connectorTitleColor};
`;

export const TariffParamValuePostfix = styled.span`
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  color: rgba(47, 47, 47, 0.5);
  width: max-content;
  // padding-bottom: 1px;
`;

export const SpanContainer = styled.span`
  display: inline-block;
`;
