import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
  toCurrencyUnit,
} from 'shared/lib';

import {
  BonusProgramLevel,
  BonusLevelFormData,
  BonusLevelForm,
  useUpdateBonusLevelMutation,
  prepareLevelRequestData,
} from 'entities/bonus-program';

import { EDIT_SUCCESS, EDIT_ERROR } from '../../consts';

const getInitialValues = (
  programLevel: BonusProgramLevel
): BonusLevelFormData => {
  const { name, condition, level, awardPercent, awardSum } = programLevel;

  return {
    name,
    condition: String(toCurrencyUnit(condition)),
    level: String(level),
    awardType: awardPercent === 0 ? '1' : '0',
    awardPercent: String(awardPercent),
    awardSum: String(toCurrencyUnit(awardSum)),
  };
};

type Props = {
  level: BonusProgramLevel;
  closeModal: () => void;
};

export function EditBonusLevel({ level, closeModal }: Props) {
  const [trigger, { isLoading }] = useUpdateBonusLevelMutation();

  const handleSubmit = async (values: BonusLevelFormData) => {
    const req = {
      ...prepareLevelRequestData(values),
      bonusProgramId: level.bonusProgramId,
      id: level.id,
    };

    try {
      const res = await trigger(req).unwrap();

      openSuccessNotification(EDIT_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : EDIT_ERROR;

      openErrorNotification(errorText);
    } finally {
      closeModal();
    }
  };

  const initialValues = getInitialValues(level);

  return (
    <BonusLevelForm
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      loading={isLoading}
      onCancel={closeModal}
    />
  );
}
