import { COLOR } from 'shared/consts';

type Props = {
  inverted?: boolean;
};

export function PlusInCircleIcon({ inverted }: Props) {
  const fillColor = inverted ? COLOR.white : COLOR.greenMenu;
  const strokeColor = inverted ? COLOR.greenMenu : COLOR.white;

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 21 21"
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="50%" cy="50%" r="50%" fill={fillColor} />
      <path
        d="M10.5 5.25V15.75M15.75 10.5L5.25 10.5"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
