export function OcpiHubsIcon() {
  return (
    <svg viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="8" fill="#1D1D1B" />
      <circle cx="23" cy="8" r="8" fill="#23C990" />
      <path
        d="M12 8C12 10.2091 10.2091 12 8 12C5.79086 12 4 10.2091 4 8C4 5.79086 5.79086 4 8 4C10.2091 4 12 5.79086 12 8ZM5.78277 8C5.78277 9.22454 6.77546 10.2172 8 10.2172C9.22454 10.2172 10.2172 9.22454 10.2172 8C10.2172 6.77546 9.22454 5.78277 8 5.78277C6.77546 5.78277 5.78277 6.77546 5.78277 8Z"
        fill="white"
      />
      <path
        d="M25.8284 10.8284C25.269 11.3878 24.5563 11.7688 23.7804 11.9231C23.0044 12.0775 22.2002 11.9983 21.4693 11.6955C20.7384 11.3928 20.1136 10.8801 19.6741 10.2223C19.2346 9.56448 19 8.79113 19 8C19 7.20887 19.2346 6.43552 19.6741 5.77772C20.1136 5.11992 20.7384 4.60723 21.4693 4.30448C22.2002 4.00173 23.0044 3.92252 23.7804 4.07686C24.5563 4.2312 25.269 4.61216 25.8284 5.17157L24.5678 6.43218C24.2577 6.12209 23.8627 5.91092 23.4326 5.82537C23.0025 5.73982 22.5566 5.78373 22.1515 5.95154C21.7464 6.11936 21.4001 6.40355 21.1564 6.76817C20.9128 7.13279 20.7828 7.56147 20.7828 8C20.7828 8.43853 20.9128 8.86721 21.1564 9.23183C21.4001 9.59645 21.7464 9.88064 22.1515 10.0485C22.5566 10.2163 23.0025 10.2602 23.4326 10.1746C23.8627 10.0891 24.2577 9.87791 24.5678 9.56782L25.8284 10.8284Z"
        fill="white"
      />
      <g clipPath="url(#clip0_3893_22950)">
        <circle cx="23" cy="23" r="8" fill="#1D1D1B" />
        <circle cx="23" cy="23" r="2" fill="white" />
        <path d="M22 27H24V35H22V27Z" fill="white" />
      </g>
      <g clipPath="url(#clip1_3893_22950)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.06767 14.299C0.0413845 16.0182 -2 19.2707 -2 23C-2 28.5228 2.47715 33 8 33C13.5228 33 18 28.5228 18 23C18 19.2707 15.9586 16.0182 12.9323 14.299C11.5733 15.3646 9.86087 16 8 16C6.13913 16 4.42665 15.3646 3.06767 14.299Z"
          fill="#23C990"
        />
        <path
          d="M12 23C12 25.2091 10.2091 27 8 27C5.79086 27 4 25.2091 4 23C4 20.7909 5.79086 19 8 19C10.2091 19 12 20.7909 12 23ZM5.78277 23C5.78277 24.2245 6.77546 25.2172 8 25.2172C9.22454 25.2172 10.2172 24.2245 10.2172 23C10.2172 21.7755 9.22454 20.7828 8 20.7828C6.77546 20.7828 5.78277 21.7755 5.78277 23Z"
          fill="white"
        />
        <path d="M4 23H5.77323V31H4V23Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_3893_22950">
          <rect x="15" y="15" width="16" height="16" rx="8" fill="white" />
        </clipPath>
        <clipPath id="clip1_3893_22950">
          <rect y="15.5" width="16" height="15.5" rx="7.75" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
