import { RcFile } from 'antd/lib/upload';

import { useParams } from 'react-router-dom';

import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
  getServerErrorText,
} from 'shared/lib';

import { EmptyData, ErrorMessage } from 'shared/ui';

import {
  CarBrandForm,
  CarBrandLogo,
  CustomUpdateCarBrandRequest,
} from 'entities/car';

import { EDIT_SUCCESS, EDIT_ERROR } from '../consts';
import {
  CarBrand,
  CarBrandFormData,
  useCustomUpdateCarBrandMutation,
  useGetCarBrandQuery,
} from 'entities/car-brand';

const getInitialValues = (carBrand: CarBrand): CarBrandFormData => {
  const { name } = carBrand;

  return { name, files: [] };
};

const getCurrentLogo = (carBrand: CarBrand) => {
  const { logo } = carBrand;

  if (!logo) return null;

  const { baseAddress, url } = logo;

  return <CarBrandLogo photoSrc={`${baseAddress}${url}`} />;
};

export function EditCarBrand() {
  const [trigger, { isLoading: isMutationLoading }] =
    useCustomUpdateCarBrandMutation();

  const { carBrandId } = useParams() as { carBrandId: string };

  const { isFetching, error, data } = useGetCarBrandQuery(carBrandId);

  if (isFetching) {
    return <div>Loading...</div>;
  }

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!data || !data.carBrand) {
    return <EmptyData />;
  }

  const initialValues = getInitialValues(data.carBrand);

  const currentLogo = getCurrentLogo(data.carBrand);

  const handleSubmit = async (values: CarBrandFormData) => {
    const { name, files } = values;

    const req: CustomUpdateCarBrandRequest = {
      id: data.carBrand.id,
      name: name,
      oldName: data.carBrand.name,
      logoId: data.carBrand.logo?.id ?? null,
    };

    if (files.length) {
      const formData = new FormData();

      files.forEach((file) => {
        formData.append('fileLogo', file.originFileObj as RcFile);
      });

      req['fileLogo'] = formData;
    }

    try {
      const res = await trigger(req).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);

        return;
      }
      //

      openSuccessNotification(EDIT_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : EDIT_ERROR;

      openErrorNotification(errorText);
    }
  };

  return (
    <CarBrandForm
      disabled
      allowEmptyFile
      title={data.carBrand.name}
      handleSubmit={handleSubmit}
      loading={isMutationLoading}
      initialValues={initialValues}
      currentLogo={currentLogo}
    />
  );
}
