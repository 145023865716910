import { Link } from 'react-router-dom';

import { NavigationTabButton } from 'shared/ui';
import { ROLE_ROUTES } from 'shared/consts/routes/role';

export function AddRoleButtonLink() {
  return (
    <Link to={ROLE_ROUTES.ADD_ROLE}>
      <NavigationTabButton title="Добавить роль" />
    </Link>
  );
}
