import { createColumnHelper } from '@tanstack/react-table';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';

import { FORMAT_TO_SECONDS, showTimeString, toCurrencyUnit } from 'shared/lib';
import { useTableSettings } from 'shared/ui/table/visible-columns/use-table-settings';
import { TColumnDef } from 'shared/ui/table/types';

import { Transaction } from 'entities/balance/model/api';

import { selectBonusProgramTransactionsTable } from '../../../../redux/slices/tables/tables-slice';

import { setVisibleColumns, TableColumns, TableColumnsState } from '../store';

const columnHelper = createColumnHelper<Transaction>();

export const useColumns = () => {
  const dispatch = useDispatch();

  const settings = useSelector(selectBonusProgramTransactionsTable);

  // const roles = useSelector(selectTokenRoles);
  // const claims = useSelector(selectTokenClaims);

  // const hasPersonReadRight = checkControllerRight(
  //   'Person',
  //   'Read',
  //   roles,
  //   claims
  // );

  const DATA_COLUMNS: TColumnDef<Transaction, TableColumns>[] = [
    {
      accessorKey: 'lastUpdated',
      id: 'lastUpdated',
      header: 'Дата и время',
      cell: (props) => {
        const value = props.row.original.lastUpdated;

        return value ? showTimeString(value, FORMAT_TO_SECONDS) : '';
      },
      enableSorting: false,
    },
    {
      accessorKey: 'userName',
      id: 'userName',
      header: 'ID пользователя',
      cell: (props) => {
        // TODO: Апи не отдает userGroupId
        //
        // const { userGroupId } = props.row.original;

        // if (hasPersonReadRight) {
        //   return (
        //     <Link
        //       to={`/${PERSONS.key}/${
        //         userGroupId !== 0 ? userGroupId : null
        //       }/${props.getValue()}`}
        //     >
        //       {props.getValue()}
        //     </Link>
        //   );
        // }

        return props.getValue();
      },
      size: 120,
      minSize: 120,
      maxSize: 120,
      enableSorting: false,
    },
    {
      accessorKey: 'bonusSum',
      id: 'bonusSum',
      header: 'Количество баллов',
      cell: (props) => {
        const value = props.row.original.bonusSum;

        return toCurrencyUnit(value);
      },

      enableSorting: false,
    },
    {
      accessorKey: 'ezsId',
      id: 'ezsId',
      header: 'ЭЗС списания',
      cell: (props) => {
        // TODO: Апи не отдает chargePointGroupId
        //
        // const { chargePointGroupId, operation } = props.row.original;

        // const cpName = operation?.cpName ?? null;

        // if (!cpName) return;

        // return (
        //   <Link
        //     to={`/${CHARGE_POINTS.key}/${
        //       chargePointGroupId !== 0 ? chargePointGroupId : null
        //     }/${cpName}`}
        //   >
        //     {cpName}
        //   </Link>
        // );

        return props.getValue();
      },
      size: 100,
      minSize: 100,
      maxSize: 100,
      enableSorting: false,
    },
    {
      accessorKey: 'description',
      id: 'description',
      header: 'Комментарий',
      size: 600,
      minSize: 600,
      maxSize: 600,
      enableSorting: false,
    },
  ];

  const settingsColumn = useTableSettings({
    columnHelper,
    columns: DATA_COLUMNS.map(({ id, header }) => {
      return {
        key: id,
        label: header as string,
        isChecked: settings[id],
      };
    }),
    settings,
    renderCell: (props) => {
      return '';
    },
    setVisibleColumns: (cols: TableColumnsState) => {
      dispatch(setVisibleColumns(cols));
    },
  });

  const visibleColumns = useMemo(() => {
    const dataCols = settings
      ? DATA_COLUMNS.filter((el) => settings[el.id])
      : DATA_COLUMNS;

    return [...dataCols, settingsColumn];
  }, [settings]);

  return visibleColumns;
};
