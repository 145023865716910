import styled from 'styled-components';

import { COLOR } from 'shared/consts';

export const StyledProblemsDashboard = styled.div`
  padding: 15px;
  border-radius: 10px;
  background: ${COLOR.white};
`;

export const DashboardTitle = styled.h3`
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 9px;
`;

export const ProblemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ProblemRow = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const ProblemIconContainer = styled.div`
  background-color: ${COLOR.whiteSmoke};
  border-radius: 4px;
  width: 21px;
  height: 21px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ProblemIcon = styled.img`
  width: 15px;
  height: 15px;
`;

export const ProblemBrickIcon = styled.img`
  position: absolute;
  right: -2px;
  top: -2px;
`;

export const ProblemLabel = styled.p`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  color: ${COLOR.darkGrey};
`;

export const ProblemValue = styled.p`
  color: ${COLOR.black};
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
`;
