import { PUSH_NOTIFICATION_ROUTES } from 'shared/consts/routes/push-notification';
import { MenuAddButton } from 'shared/ui/section-menu';

export function AddButton() {
  return (
    <MenuAddButton
      path={PUSH_NOTIFICATION_ROUTES.ADD_PUSH_NOTIFICATION}
      requiredRights={[
        {
          controller: 'PushNotification',
          right: 'Write',
        },
      ]}
    />
  );
}
