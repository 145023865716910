import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import locale from 'antd/es/date-picker/locale/ru_RU';
import dayjs from 'dayjs';

import { StyledDateTimeRangePicker } from './styles';
import { RangePickerProps } from 'shared/ui';

interface DateTimeRangePickerProps {
  columnId: string;
}

export function DateTimeRangePickerFilter({
  columnId,
}: DateTimeRangePickerProps) {
  const [dateFrom, setDateFrom] = useState<dayjs.Dayjs | null>(null);
  const [dateTo, setDateTo] = useState<dayjs.Dayjs | null>(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const ordering = searchParams.get('ordering');
  const dateFromParam = searchParams.get('dateFrom');
  const dateToParam = searchParams.get('dateTo');

  useEffect(() => {
    if (ordering === columnId && dateFromParam && dateToParam) {
      setDateFrom(dayjs(dateFromParam));
      setDateTo(dayjs(dateToParam));
    } else {
      setDateFrom(null);
      setDateTo(null);
    }
  }, [ordering, dateFromParam, dateToParam]);

  const onChange = (
    values: RangePickerProps['value'],
    _dateString: [string, string] | string
  ) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());

    if (!values) {
      updatedSearchParams.delete('dateFrom');
      updatedSearchParams.delete('dateTo');
      updatedSearchParams.delete('ordering'); // ?
    } else {
      const [from, to] = values as [dayjs.Dayjs, dayjs.Dayjs];

      updatedSearchParams.set('dateFrom', from.toISOString());
      updatedSearchParams.set('dateTo', to.toISOString());
      updatedSearchParams.set('ordering', columnId);
    }

    setSearchParams(updatedSearchParams.toString());
  };

  return (
    <StyledDateTimeRangePicker
      locale={locale}
      separator={null}
      placeholder={['от', 'до']}
      showTime={{ format: 'HH:mm' }}
      format="DD-MM-YYYY HH:mm"
      onChange={onChange}
      value={[dateFrom, dateTo]}
    />
  );
}
