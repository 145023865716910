import styled from 'styled-components';

import { COLOR } from 'shared/consts';

export const TabSpinnerContainer = styled.div`
  width: 10px;
  height: 10px;
`;

export const StyledTabSpinner = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 7px;
  width: 100%;

  &::after {
    content: '';
    width: 10px;
    height: 10px;
    border: 2px solid #dddddd;
    border-top-color: ${COLOR.greenMenu};
    border-radius: 50%;
    transform: rotate(0.16turn);
    animation: loading 1s ease infinite;
  }

  @keyframes loading {
    /* Safari support */
    from {
      transform: rotate(0turn);
    }

    to {
      transform: rotate(1turn);
    }
  }
`;
