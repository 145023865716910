import { toConsumeUnit, toCurrencyUnit } from 'shared/lib';
import { BonusProgramType, FrequencyTypes } from '../model/program';

export const getFrequencyTypeText = (type: FrequencyTypes) => {
  if (type === 'Day') {
    return 'сегодня';
  } else if (type === 'Week') {
    return 'на этой неделе';
  } else {
    return 'в этом месяце';
  }
};

export const getBonusTypeProgramText = (
  type: BonusProgramType,
  value: number,
  currencySymbol: string
) => {
  if (type === 'SpendMoney') {
    const val = toCurrencyUnit(value);

    return `Осталось зарядиться на ${val}${currencySymbol}`;
  } else if (type === 'Birthday') {
    return 'Зарядитесь в свой день рождения';
  } else if (type === 'ChargedByStations') {
    return `Осталось зарядиться на ${value} разных станциях`;
  } else {
    const val = toConsumeUnit(value);

    return `Осталось зарядиться на ${val} кВт`;
  }
};

export const getAwardText = (
  value: number,
  isCashback: boolean,
  currencySymbol: string
) => {
  if (isCashback) {
    return `${value}% кэшбэка баллами`;
  } else {
    return `${toCurrencyUnit(value)}${currencySymbol} кэшбэка`;
  }
};

type Props = {
  bonusProgramType: BonusProgramType;
  currencySymbol: string;
  leftToNextLevel: number;
  awardPercent: number;
  awardSum: number;
  frequencyType: FrequencyTypes;
};

export const getNextLevelHint = ({
  bonusProgramType,
  currencySymbol,
  leftToNextLevel,
  awardPercent,
  awardSum,
  frequencyType,
}: Props) => {
  if (leftToNextLevel <= 0) {
    return 'Вы получили максимальный кэшбэк';
  }

  const condition = getBonusTypeProgramText(
    bonusProgramType,
    leftToNextLevel,
    currencySymbol
  );

  const award = getAwardText(
    awardPercent || awardSum,
    awardPercent > 0,
    currencySymbol
  );

  const frequency = getFrequencyTypeText(frequencyType);

  return `${condition}, чтобы получить ${award} ${frequency}`;
};

export const BONUS_PROGRAM_TYPE: Record<BonusProgramType, string> = {
  Birthday: 'День рождения',
  ChargedByCapacity: 'Заряжено кВт',
  ChargedByStations: 'Заряжено на разных станциях',
  SpendMoney: 'Потрачено денег',
};

export const FREQUENCY_TYPE: Record<FrequencyTypes, string> = {
  Day: 'День',
  Month: 'Месяц',
  Week: 'Неделя',
};

export const AWARD_TYPE: Record<0 | 1, string> = {
  '0': '%',
  '1': 'Сумма',
};
