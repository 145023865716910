import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { ConfirmDialog, UiModal } from 'shared/ui';
import { openErrorNotification, openSuccessNotification } from 'shared/lib';

import { useDeleteOwnerMutation } from 'entities/owner';

import { DELETE_ERROR, DELETE_SUCCESS } from '../consts';

type Props = {
  id: string;
  name: string;
};

export const DeleteOwnerModal = NiceModal.create((props: Props) => {
  const modal = useModal();

  const [trigger, { isLoading }] = useDeleteOwnerMutation();

  const handleDelete = async (id: string) => {
    try {
      const res = await trigger(id).unwrap();

      res.statusCode === 0
        ? openSuccessNotification(DELETE_SUCCESS)
        : openErrorNotification(res.statusDescription);
    } catch (e) {
      openErrorNotification(DELETE_ERROR);
    } finally {
      modal.hide();
    }
  };

  return (
    <UiModal
      title={`Удалить владельца ЭЗС "${props.name}"?`}
      visible={modal.visible}
      onCancel={modal.hide}
      afterClose={modal.remove}
    >
      <ConfirmDialog
        onCancel={modal.hide}
        onConfirm={() => handleDelete(props.id)}
        loading={isLoading}
      />
    </UiModal>
  );
});
