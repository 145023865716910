import { OnChangeFn, PaginationState } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';

export const useClientPagination = (
  tablePageSize: number
): [PaginationState, OnChangeFn<PaginationState>] => {
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: tablePageSize,
  });

  useEffect(() => {
    setPagination((curr) => ({ ...curr, pageSize: tablePageSize }));
  }, [tablePageSize]);

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  return [pagination, setPagination];
};
