import dayjs from 'dayjs';
import { BonusProgramFormData } from '../model';

import { BonusProgramType, FrequencyTypes } from '../model/program';

export const prepareProgramRequestData = (values: BonusProgramFormData) => {
  const {
    dateStart,
    dateStop,
    bonusProgramType,
    bankId,
    frequencyType,
    ...rest
  } = values;

  return {
    bankId: Number(bankId),
    bonusProgramType: bonusProgramType as BonusProgramType,
    frequencyType: frequencyType as FrequencyTypes,
    dateStart: dayjs(dateStart, 'DD-MM-YYYY HH:mm:ss').toISOString(),
    dateStop: dayjs(dateStop, 'DD-MM-YYYY HH:mm:ss').toISOString(),
    ...rest,
  };
};
