import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';

import { useGetBanksQuery } from 'entities/bank';

import { TariffForm } from '../form';

type Props = Omit<React.ComponentProps<typeof TariffForm>, 'banks'>;

export function ConnectedTariffForm(props: Props) {
  const { data, isFetching, error } = useGetBanksQuery();

  if (isFetching) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  if (!data) {
    return <EmptyData />;
  }

  return <TariffForm {...props} banks={data.banks} />;
}
