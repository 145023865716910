export const TABLE_PAGE_SIZE = 10;
export const INCREASED_TABLE_PAGE_SIZE = 16;

export const DEFAULT_TABLE_PAGE_SIZE = 16;

export const TBODY_TR_HEIGHT = 29.5;

export const TABLE_DEFAULT_COLUMN_WIDTH = 150;
export const TABLE_TIME_COLUMN_WIDTH = 150;
export const TABLE_CHARGE_POINT_NAME_COLUMN_WIDTH = 75;
export const TABLE_PHONE_COLUMN_WIDTH = 110;
export const TABLE_PERCENT_COLUMN_WIDTH = 75;
export const TABLE_INT_VALUE_COLUMN_WIDTH = 100;
export const TABLE_SETTINGS_COLUMN_WIDTH = 50;
export const TABLE_EVENT_MESSAGE_COLUMN_WIDTH = 330;

export const DEFAULT_MAP_ZOOM = 10;
export const DEFAULT_MAP_CENTER = [55.73, 37.66];
