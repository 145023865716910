const WRONG_PASSWORD = 'Wrong password';
const WRONG_LOGIN = `don't exist`;
const MAX_NUMBER_OF_ATTEMPTS = 'Maximum number of attempts reached';

export const getLoginErrorMessage = (error: string) => {
  if (error.includes(WRONG_LOGIN)) {
    return 'Неверный логин';
  }

  if (error.includes(WRONG_PASSWORD)) {
    return 'Неверный пароль';
  }

  if (error.includes(MAX_NUMBER_OF_ATTEMPTS)) {
    return 'Превышено максимальное количество попыток входа. Попробуйте через 5 минут';
  }

  return error;
};
