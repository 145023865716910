// Пересчитываем потребление из subunit в unit
// (из ватт в киловатты)

import { toFixedDecimals } from './to-fixed-decimals';

export const toConsumeUnit = (subunitValue: number) => {
  const unitValue = subunitValue / 1000;

  return toFixedDecimals(unitValue, 2);
};
