export function WcIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5208_27806)">
        <path
          d="M16.4886 3.74502C17.3229 3.60977 17.8896 2.82383 17.7543 1.98957C17.6191 1.1553 16.8331 0.588631 15.9989 0.723871C15.1646 0.859112 14.5979 1.64505 14.7332 2.47932C14.8684 3.31358 15.6543 3.88026 16.4886 3.74502Z"
          fill="#28303F"
        />
        <path
          d="M20.0013 5.98974C19.9965 5.03096 19.2003 4.25098 18.2265 4.25098C17.8327 4.25098 14.7264 4.25098 14.2621 4.25098C13.2866 4.25098 12.4892 5.031 12.4844 5.99346V11.4187C12.4844 11.8266 12.815 12.1572 13.2228 12.1572C13.6307 12.1572 13.9613 11.8266 13.9613 11.4187V5.99718C13.962 5.85625 14.1175 5.7279 14.2742 5.7279V18.411C14.2742 18.9004 14.6774 19.2972 15.1668 19.2972C15.6562 19.2972 16.053 18.9005 16.053 18.411V11.3279H16.4356V18.411C16.4356 18.9004 16.8323 19.2972 17.3218 19.2972C17.8111 19.2972 18.2117 18.9005 18.2117 18.411C18.2117 18.405 18.2117 5.73209 18.2117 5.7279C18.3669 5.7279 18.5237 5.85626 18.5244 5.9935V11.4187C18.5244 11.8266 18.855 12.1572 19.2629 12.1572C19.6707 12.1572 20.0013 11.8266 20.0013 11.4187V5.98974Z"
          fill="#28303F"
        />
        <path
          d="M4.85794 3.86573C5.69581 3.80613 6.32671 3.07858 6.2671 2.24072C6.20749 1.40285 5.47995 0.771947 4.64209 0.831554C3.80422 0.891162 3.17332 1.61871 3.23293 2.45657C3.29254 3.29444 4.02008 3.92534 4.85794 3.86573Z"
          fill="#28303F"
        />
        <path
          d="M9.45216 10.6893C9.44014 10.6564 8.24252 7.37985 7.71509 5.84499C7.3748 4.85476 6.80421 4.35264 6.01918 4.35264C5.25498 4.35264 4.33137 4.35264 3.47787 4.35264C2.69284 4.35264 2.12225 4.85473 1.78196 5.84499C1.25449 7.37969 0.0568319 10.6564 0.0448075 10.6893C-0.0943153 11.0698 0.101365 11.4909 0.481799 11.6301C0.861488 11.7689 1.28316 11.5742 1.42256 11.1931C1.43165 11.1683 2.11128 9.3088 2.67475 7.72805L1.82716 12.619C1.78396 12.8682 1.97662 13.0965 2.22863 13.0965H2.79816V18.417C2.79816 18.9031 3.19223 19.2972 3.67833 19.2972C4.16444 19.2972 4.5585 18.9031 4.5585 18.417V13.0965H4.93851V18.417C4.93851 18.9031 5.33257 19.2972 5.81868 19.2972C6.30479 19.2972 6.69885 18.9031 6.69885 18.417V13.0965H7.26834C7.52062 13.0965 7.71313 12.869 7.66981 12.619L6.82219 7.72785C7.38565 9.30865 8.06536 11.1682 8.07441 11.193C8.21373 11.5741 8.63529 11.7689 9.01517 11.63C9.39561 11.4909 9.59129 11.0697 9.45216 10.6893Z"
          fill="#28303F"
        />
        <path
          d="M10.655 19.8858C10.3321 19.8858 10.0703 19.6241 10.0703 19.3012V0.697934C10.0703 0.375037 10.3321 0.113281 10.655 0.113281C10.9779 0.113281 11.2396 0.375037 11.2396 0.697934V19.3012C11.2397 19.6241 10.9779 19.8858 10.655 19.8858Z"
          fill="#28303F"
        />
      </g>
      <defs>
        <clipPath id="clip0_5208_27806">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
