import { Col, Form } from 'antd';
import { FormProps } from 'antd/es/form/Form';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { formHasErrors, VALIDATION_RULE } from 'shared/lib';
import {
  ButtonsContainer,
  UiSubmitButton,
  UiEditFormButton,
  UiCancelFormButton,
  FormControlsContainer,
} from 'shared/ui';
import { UiInput, UiSelect, UiTextArea } from 'shared/ui/ui-kit';

import {
  selectTokenRoles,
  selectTokenClaims,
  checkControllerRight,
} from 'entities/authorization';

import { CardFormWrapper, FormItem, FormRow } from '../../../../common-styles';

import { PersonsGroupFormData, PersonsGroupFormProps } from '../../model/form';

type FormData = PersonsGroupFormData;

export function PersonsGroupForm({
  handleSubmit,
  initialValues,
  loading,
  title,
  disabled = false,
  groups,
  discounts,
}: PersonsGroupFormProps) {
  const roles = useSelector(selectTokenRoles);
  const claims = useSelector(selectTokenClaims);

  const hasWriteRight = checkControllerRight('Group', 'Write', roles, claims);

  const [isFormDisabled, setIsFormDisabled] = useState(disabled);

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [hasErrors, setHasErrors] = useState(false);

  const [form] = Form.useForm<FormData>();

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  const onValuesChange: FormProps<FormData>['onValuesChange'] = (
    _changedValues,
    allValues
  ) => {
    if (allValues.name !== '') {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  const onFieldsChange: FormProps<FormData>['onFieldsChange'] = (
    _,
    _allFields
  ) => {
    setHasErrors(formHasErrors(form));
  };

  const emptySelect = {
    value: '-1', // бэк так сделал
    label: 'Нет',
  };

  const getParentGroupsOptions = () => {
    if (initialValues.name) {
      return [
        emptySelect,
        ...groups
          .filter((group) => group.name !== initialValues.name)
          .map(({ id, name }) => ({ value: String(id), label: name })),
      ];
    }

    return [
      emptySelect,
      ...groups.map(({ id, name }) => ({ value: String(id), label: name })),
    ];
  };

  const parentGroupOptions = useMemo(() => getParentGroupsOptions(), [groups]);

  const discountOptions = useMemo(() => {
    return [
      emptySelect,
      ...discounts.map(({ id, name }) => ({ value: String(id), label: name })),
    ];
  }, [discounts]);

  const primaryButton = isFormDisabled ? (
    <UiEditFormButton onClick={() => setIsFormDisabled(false)} />
  ) : (
    <UiSubmitButton disabled={isSubmitDisabled || hasErrors} />
  );

  let formControls: JSX.Element | null = null;

  if (hasWriteRight) {
    formControls = (
      <FormControlsContainer>
        <ButtonsContainer spinning={loading}>
          {primaryButton}
          <UiCancelFormButton />
        </ButtonsContainer>
      </FormControlsContainer>
    );
  }

  return (
    <>
      <CardFormWrapper title={title}>
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={handleSubmit}
          onFieldsChange={onFieldsChange}
          onValuesChange={onValuesChange}
          initialValues={initialValues}
        >
          <FormRow gutter={20}>
            <Col span={12}>
              <FormItem label="Родительский узел" name="parentId">
                <UiSelect
                  disabled={isFormDisabled}
                  options={parentGroupOptions}
                />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Наименование"
                name="name"
                rules={[VALIDATION_RULE.REQUIRED]}
              >
                <UiInput disabled={isFormDisabled} />
              </FormItem>
            </Col>
          </FormRow>
          <FormRow gutter={20}>
            <Col span={24}>
              <FormItem label="Описание" name="comment">
                <UiTextArea autoSize />
              </FormItem>
            </Col>
          </FormRow>
          {initialValues.name !== '' ? (
            <FormRow gutter={20}>
              <Col span={24}>
                <FormItem label="Скидка" name="discountId">
                  <UiSelect
                    disabled={isFormDisabled}
                    options={discountOptions}
                  />
                </FormItem>
              </Col>
            </FormRow>
          ) : null}
          {formControls}
        </Form>
      </CardFormWrapper>
    </>
  );
}
