import { useParams } from 'react-router-dom';

import { ConnectedPersonsListTable } from 'features/person/table/ui/connected-table';

export function PersonsGroupListPage() {
  const { groupId } = useParams() as {
    groupId: string;
  };

  return <ConnectedPersonsListTable groupId={groupId} />;
}
