import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import useMeasure from 'react-use-measure';
import useWindowFocus from 'use-window-focus';

import { ErrorMessage, EmptyData } from 'shared/ui';
import { getServerErrorText, useTablePageSize } from 'shared/lib';
import { POLLING_INTERVAL } from 'shared/consts';

import { useGetSessionsQuery } from 'entities/session';

import SessionLogTableInstance from './table';

type Props = {
  groupId?: string;
  chargePointName?: string;
  userLogin?: string;
  handleSetFetchingTab: (val: string) => void;
};

export function ConnectedSessionLogTable({
  chargePointName,
  groupId,
  userLogin,
  handleSetFetchingTab,
}: Props) {
  const isWindowFocused = useWindowFocus();

  const [searchParams, setSearchParams] = useSearchParams();

  const [tableHeadRef, tableHeadBounds] = useMeasure();

  const [tablePageSize, setTablePageSize] = useTablePageSize(tableHeadBounds);

  const ordering = searchParams.get('ordering');
  const type = searchParams.get('type');
  const phone = searchParams.get('phone');
  const cpName = searchParams.get('cpName');
  const page = searchParams.get('page');
  const limit = searchParams.get('limit');
  const search = searchParams.get('search');
  const searchText = searchParams.get('searchText');
  const dateFrom = searchParams.get('dateFrom');
  const dateTo = searchParams.get('dateTo');
  const isProblemFiscal = searchParams.get('isProblemFiscal');

  const getChargePointParam = () => {
    if (chargePointName !== undefined) {
      return chargePointName;
    }
    return cpName !== null ? cpName : '';
  };

  const getPhoneParam = () => {
    if (userLogin !== undefined) {
      return userLogin;
    }
    return phone !== null ? phone : '';
  };

  const { isFetching, data, error, currentData, isSuccess } =
    useGetSessionsQuery(
      {
        limit: limit || String(tablePageSize),
        ordering,
        page: page || String(1),
        type,
        cpName: getChargePointParam(),
        phone: getPhoneParam(),
        groupId,
        search,
        searchText,
        dateFrom,
        dateTo,
        isProblemFiscal: isProblemFiscal ? Boolean(isProblemFiscal) : false,
      },
      {
        skip: !limit && !tablePageSize,
        pollingInterval: isWindowFocused ? POLLING_INTERVAL : undefined,
      }
    );

  useEffect(() => {
    if (isFetching) {
      handleSetFetchingTab('session-log');
    } else {
      handleSetFetchingTab('');
    }

    return () => {
      handleSetFetchingTab('');
    };
  }, [isFetching]);

  const tableData = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.sessions;
  }, [data]);

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (isSuccess && !data) {
    return <EmptyData />;
  }

  return (
    <SessionLogTableInstance
      tableData={tableData}
      loading={isFetching && !currentData}
      count={data?.count}
      tableHeadRef={tableHeadRef}
      tablePageSize={tablePageSize}
    />
  );
}
