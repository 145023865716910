// Пересчитываем сумму из unit в subunit
// (пример: из рублей в копейки)

export const toCurrencySubunit = (unitValue: number | string) => {
  const isDecimal = String(unitValue).includes('.');
  if (isDecimal) {
    const [, fractionalPart] = String(unitValue).split('.');

    if (fractionalPart.length === 2 || fractionalPart.length < 2) {
      return Math.round(Number(unitValue) * 100);
    }

    return Number(unitValue) * 100;
  }
  return Number(unitValue) * 100;
};
