import styled from 'styled-components';

export const MetricsContainer = styled.div`
  margin-bottom: 10px;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(3, minmax(170px, 260px)) auto;
  grid-template-rows: 180px;

  & canvas {
    height: 53px;
  }
`;

export const ChargePointListFiltersContainer = styled.div`
  margin-bottom: 20px;
`;
