export const COLOR = {
  green: 'rgb(35, 232, 123)',
  lightGreen: 'rgba(35, 232, 123, 0.15)',
  lightGreenMenu: 'rgba(35, 201, 144, 0.15)',
  greenMenu: 'rgb(35, 201, 144)',
  red: 'rgb(248, 103, 103)',
  lightYellow: 'rgb(255, 255, 0)',
  yellow: 'rgb(255, 193, 34)',
  darkYellow: 'rgb(191, 144, 0)',
  orange: 'rgb(255, 165, 0)',
  darkOrange: 'rgb(197, 90, 17)',
  whiteSmoke: '#F6F6F6',
  sortColumn: '#E0E0E0',
  darkWhiteSmoke: '#7F7F7F',
  grey: 'rgba(0, 0, 0, 0.15)',
  darkGrey: 'rgba(0, 0, 0, 0.5)',
  lightGrey: 'rgba(0, 0, 0, 0.25)',
  tableEvenRow: 'rgba(0, 0, 0, 0.02)',
  inputGrey: 'rgba(0, 0, 0, 0.3)',
  headerBorder: 'rgba(0, 0, 0, 0.15)',
  black: '#000000',
  title: '#28303F',
  white: '#ffffff',
  boxShadow: 'rgba(0, 0, 0, 0.05)',
  placeholder: 'rgba(40, 48, 63, 0.5)',
  cancelGrey: '#EAEAEA',
  layoutBorder: '#D9D9D9',
  reportTd: 'rgb(250,250,250)',
  uninitialized: 'rgb(189, 189, 189)',
  purple: 'rgb(247, 84, 255)',
  lightBlue: 'rgb(180, 199, 231)',
  connectorTitleColor: '#2F2F2F',
};

export const DEFAULT_BORDER = `1px solid ${COLOR.layoutBorder}`;
