import { ADD_PREFIX, SETTINGS } from './common';

export const BANKS = 'banks';
export const BANK = ':bankId';

export const BANK_ROUTES = {
  BANKS: `/${SETTINGS}/${BANKS}`,
  BANK: `/${SETTINGS}/${BANKS}/${BANK}`,
  ADD_BANK: `/${SETTINGS}/${BANKS}/${ADD_PREFIX}`,
};
