import { DiscountFormData } from '../model';
import { CustomUpdateDiscountRequest } from '../model/discount';

export const prepareRequestData = (
  values: DiscountFormData,
  discountId: string
): CustomUpdateDiscountRequest => {
  const { owners, ...rest } = values;

  return {
    discount: {
      ...rest,
      id: Number(discountId),
    },
    discountBannedOwners: {
      discountBannedOwners: [
        {
          discountId: Number(discountId),
          bannedOwnersIds: owners.map((ownerId) => Number(ownerId)),
        },
      ],
    },
  };
};
