import { getEndpointPath } from 'shared/lib';

import { PLATFORM_SERVICE } from 'entities/authorization';

import {
  AddFirmwareRequest,
  FirmwaresResponse,
  UpdateFirmwareRequest,
} from './types';

import { apiSlice } from '../../../redux/services/apiSlice';

const KEY = 'Firmware';

const getUrl = getEndpointPath(PLATFORM_SERVICE, KEY);

export const api = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFirmwares: builder.query<FirmwaresResponse, void>({
      query: () => getUrl('GetFirmwares'),
      providesTags: ['Firmwares'],
    }),
    addFirmware: builder.mutation<ResponseBase, AddFirmwareRequest>({
      query: ({ description, fileFirmware, formatUrl }) => ({
        url: getUrl(`AddFirmware`),
        params: {
          description,
          formatUrl,
        },
        method: 'POST',
        body: fileFirmware,
        formData: true,
      }),
      invalidatesTags: ['Firmwares'],
    }),
    updateFirmware: builder.mutation<ResponseBase, UpdateFirmwareRequest>({
      query: ({ id, description, fileFirmware, formatUrl }) => ({
        url: getUrl(`UpdateFirmware`),
        params: { id, description, formatUrl },
        method: 'PATCH',
        body: fileFirmware,
        formData: true,
      }),
      invalidatesTags: ['Firmwares'],
    }),
    deleteFirmware: builder.mutation<ResponseBase, string>({
      query: (id) => ({
        url: getUrl(`DeleteFirmware`),
        params: { id },
        method: 'DELETE',
      }),
      invalidatesTags: ['Firmwares'],
    }),
  }),
});

export const {
  useGetFirmwaresQuery,
  useAddFirmwareMutation,
  useUpdateFirmwareMutation,
  useDeleteFirmwareMutation,
} = api;
