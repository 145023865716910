import { useParams } from 'react-router-dom';

import { getServerErrorText } from 'shared/lib';
import { ErrorMessage, EmptyData } from 'shared/ui';

import { useGetChargePointGroupsQuery } from 'entities/group';
import {
  useGetRoleChargePointRightsQuery,
  useGetRoleGroupChargePointRightsQuery,
} from 'entities/charge-point-rights';

import { ConnectedRoleChargePointRightsForm } from '../ConnectedRoleChargePointRightsForm';

export function ConnectedRoleChargePointRights() {
  const { roleName } = useParams() as { roleName: string };

  const {
    data: groups,
    isLoading: isGroupsLoading,
    error: groupsError,
  } = useGetChargePointGroupsQuery();

  const {
    data: chargePointRights,
    isLoading: isChargePointRightsLoading,
    error: chargePointrightsError,
  } = useGetRoleChargePointRightsQuery(roleName);

  const {
    data: groupRights,
    isLoading: isGroupRightsLoading,
    error: groupRightsError,
  } = useGetRoleGroupChargePointRightsQuery(roleName);

  if (isGroupsLoading || isChargePointRightsLoading || isGroupRightsLoading) {
    return <div>Loading...</div>;
  }

  const apiResponseError = [groups, chargePointRights, groupRights].find(
    (el) => el && el.statusCode !== 0
  );

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusDescription} />;
  }

  const error = [groupsError, chargePointrightsError, groupRightsError].find(
    (err) => err !== undefined
  );

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!chargePointRights || !groups || !groupRights) {
    return <EmptyData />;
  }

  return (
    <ConnectedRoleChargePointRightsForm
      groups={groups.groups}
      chargePointRights={chargePointRights.chargePointsRights}
      isAllCpRights={chargePointRights.isAllCpRights}
      groupChargePointRights={groupRights.chargePointsRights}
    />
  );
}
