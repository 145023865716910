import { useMemo } from 'react';

import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';

import { useGetAllBonusProgramsQuery } from 'entities/bonus-program';

import { BonusProgramTableInstance } from './table';

export function ConnectedBonusProgramTable() {
  const { data, isFetching, error } = useGetAllBonusProgramsQuery();

  const tableData = useMemo(() => {
    if (!data) {
      return [];
    }

    return data;
  }, [data]);

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!isFetching && !data) {
    return <EmptyData />;
  }

  return (
    <BonusProgramTableInstance
      tableData={tableData}
      loading={isFetching}
      rowsCount={undefined}
    />
  );
}
