import { CHARGE_POINTS } from 'shared/consts/routes/charge-point';
import { OWNERS } from 'shared/consts/routes/owner';
import { NavigationTab } from 'shared/model';

export const TABS: Array<NavigationTab> = [
  {
    key: OWNERS,
    path: '',
    label: 'Настройки владельца',
  },
  {
    key: CHARGE_POINTS,
    path: CHARGE_POINTS,
    label: 'Список ЭЗС',
  },
];
