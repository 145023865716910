export function PersonsIcon() {
  return (
    <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse
        cx="12.5"
        cy="17.1875"
        rx="6.25"
        ry="2.60417"
        stroke="black"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <circle
        cx="12.5"
        cy="8.33337"
        r="3.125"
        stroke="black"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.65195 13.848C5.38914 13.9065 4.23125 14.1367 3.33019 14.4971C2.80664 14.7066 2.33262 14.9747 1.97722 15.3108C1.62023 15.6484 1.33325 16.1063 1.33325 16.6666C1.33325 17.227 1.62023 17.6849 1.97722 18.0225C2.33262 18.3586 2.80664 18.6267 3.33019 18.8361C3.83811 19.0393 4.42763 19.2011 5.07155 19.3141C4.58632 18.7789 4.27853 18.1843 4.19179 17.5548C4.08554 17.5193 3.98393 17.4821 3.88728 17.4434C3.46831 17.2758 3.17959 17.095 3.00788 16.9327C2.83775 16.7718 2.83325 16.6816 2.83325 16.6666C2.83325 16.6517 2.83775 16.5615 3.00788 16.4006C3.17959 16.2382 3.46831 16.0574 3.88728 15.8899C4.10855 15.8014 4.35584 15.7206 4.62532 15.6498C5.04944 14.9625 5.74992 14.3479 6.65195 13.848Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8081 17.5548C20.7213 18.1843 20.4135 18.7789 19.9283 19.3141C20.5722 19.2011 21.1617 19.0393 21.6697 18.8361C22.1932 18.6267 22.6672 18.3586 23.0226 18.0225C23.3796 17.6849 23.6666 17.227 23.6666 16.6666C23.6666 16.1063 23.3796 15.6484 23.0226 15.3108C22.6672 14.9747 22.1932 14.7066 21.6697 14.4971C20.7686 14.1367 19.6107 13.9065 18.3479 13.848C19.2499 14.3479 19.9504 14.9625 20.3745 15.6498C20.644 15.7206 20.8913 15.8014 21.1126 15.8899C21.5315 16.0574 21.8203 16.2382 21.992 16.4006C22.1621 16.5615 22.1666 16.6517 22.1666 16.6666C22.1666 16.6816 22.1621 16.7718 21.992 16.9327C21.8203 17.095 21.5315 17.2758 21.1126 17.4434C21.0159 17.4821 20.9143 17.5193 20.8081 17.5548Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1889 10.6033C16.9663 11.0622 16.6784 11.4835 16.3372 11.855C16.7434 12.0801 17.2109 12.2083 17.7083 12.2083C19.2731 12.2083 20.5416 10.9398 20.5416 9.37496C20.5416 7.81015 19.2731 6.54163 17.7083 6.54163C17.6034 6.54163 17.4999 6.54732 17.398 6.55842C17.5673 7.02537 17.6716 7.52341 17.7002 8.04165C17.7029 8.04163 17.7056 8.04163 17.7083 8.04163C18.4446 8.04163 19.0416 8.63858 19.0416 9.37496C19.0416 10.1113 18.4446 10.7083 17.7083 10.7083C17.524 10.7083 17.3485 10.6709 17.1889 10.6033Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.60182 6.55842C7.49994 6.54732 7.39643 6.54163 7.29159 6.54163C5.72678 6.54163 4.45825 7.81015 4.45825 9.37496C4.45825 10.9398 5.72678 12.2083 7.29159 12.2083C7.78896 12.2083 8.2564 12.0801 8.66269 11.855C8.32148 11.4835 8.03355 11.0622 7.81099 10.6033C7.65136 10.6709 7.47584 10.7083 7.29159 10.7083C6.55521 10.7083 5.95825 10.1113 5.95825 9.37496C5.95825 8.63858 6.55521 8.04163 7.29159 8.04163C7.29426 8.04163 7.29694 8.04163 7.29961 8.04165C7.32822 7.52341 7.43258 7.02537 7.60182 6.55842Z"
        fill="black"
      />
    </svg>
  );
}
