import 'dayjs/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';

import { StyledReportRangePicker } from './styles';
import { dayjsDatePickerFormat } from 'shared/ui/dayjs-date-picker';

import { ArrowRightIcon } from 'entities/report/images/arrow-right-icon';

// TODO: выяснить о пропсах
export function ReportRangePicker(props) {
  return (
    <StyledReportRangePicker
      locale={locale}
      separator={'-'}
      allowClear={false}
      suffixIcon={<ArrowRightIcon />}
      placeholder={['Дата начала', 'Дата конца']}
      format={dayjsDatePickerFormat}
      {...props}
    />
  );
}
