import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
  isErrorWithMessage,
  getServerErrorText,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';

import { ErrorMessage, EmptyData } from 'shared/ui';

import {
  ControllerRweRight,
  SetUserRightsRequest,
} from '../../../../../models/identity';
import {
  useGetRolesRightsQuery,
  useGetUserRightsQuery,
  useGetUserRolesQuery,
  useSetUserRightsMutation,
} from '../../../../../redux/services/identity';

import { CONTROLLERS, SUPERUSER_ROLE } from '../../../constants';
import { getEditRightsError, getEditRightsSuccess } from '../../constants';
import { UserControllerRightsTableItem } from '../../types';
import { UserControllerRights } from '../../components/UserControllerRights';

export function ConnectedUserControllerRights() {
  const { userName } = useParams() as { userName: string };

  const {
    data: userRoles,
    isFetching: isUserRolesLoading,
    error: userRolesError,
  } = useGetUserRolesQuery(userName);

  const isAdmin =
    Boolean(userRoles?.roles.find((role) => role.name === SUPERUSER_ROLE)) ??
    false;

  const rolesNamesArr = userRoles?.roles.map((role) => role.name) || [];

  const {
    data: rolesRights = {},
    isFetching: isRolesRightsLoading,
    error: rolesRightsError,
  } = useGetRolesRightsQuery(rolesNamesArr, {
    skip: Boolean(!userRoles?.roles) || isAdmin,
  });

  const {
    data: userRights,
    isFetching: isUserRightsLoading,
    error: userRightsError,
  } = useGetUserRightsQuery(userName);

  const [trigger, { isLoading: isMutationLoading }] =
    useSetUserRightsMutation();

  const handleSubmit = async (values: Record<string, boolean>) => {
    const rightsDict = Object.keys(values).reduce((acc, formItemName) => {
      const formItemNameArr = formItemName.split('/');

      const controllerName = formItemNameArr[0];
      const type = formItemNameArr[1] as 'read' | 'write' | 'execute';

      const obj = acc[controllerName] || {};

      obj[type] = values[formItemName];

      acc[controllerName] = { ...acc[controllerName], ...obj };

      return acc;
    }, {} as Record<string, { read: boolean; write: boolean; execute: boolean }>);

    const controllerRights = Object.keys(rightsDict).map((controller) => ({
      controllerName: controller,
      ...rightsDict[controller],
    }));

    const req: SetUserRightsRequest = {
      userName,
      controllerRights,
    };

    try {
      await trigger(req).unwrap();

      const successText = getEditRightsSuccess(userName);

      openSuccessNotification(successText);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : getEditRightsError(userName);

      openErrorNotification(errorText);
    }
  };

  const getInitialValues = () => {
    if (!userRights) {
      return CONTROLLERS.reduce((acc, controller) => {
        const { controllerName } = controller;

        acc[`${controllerName}/read`] = false;
        acc[`${controllerName}/write`] = false;
        acc[`${controllerName}/execute`] = false;

        return acc;
      }, {} as Record<string, boolean>);
    }

    return CONTROLLERS.reduce((acc, controller) => {
      const { controllerName } = controller;

      const userRight = userRights.rights.find(
        (right) => right.controllerName === controllerName
      );

      if (userRight) {
        const { execute, read, write } = userRight;

        acc[`${controllerName}/read`] = read;
        acc[`${controllerName}/write`] = write;
        acc[`${controllerName}/execute`] = execute;
      } else {
        acc[`${controllerName}/read`] = false;
        acc[`${controllerName}/write`] = false;
        acc[`${controllerName}/execute`] = false;
      }

      return acc;
    }, {} as Record<string, boolean>);
  };

  const initialValues = useMemo(() => getInitialValues(), [userRights]);

  const getTableData = (
    rights?: ControllerRweRight[]
  ): UserControllerRightsTableItem[] => {
    if (!rights) {
      return [];
    }

    return CONTROLLERS.map((controller) => {
      const userRight = rights.find(
        (right) => right.controllerName === controller.controllerName
      );

      if (userRight) {
        return {
          ...controller,
          ...userRight,
        };
      }

      return controller;
    });
  };

  const tableData = useMemo(
    () => getTableData(userRights?.rights),
    [userRights]
  );

  if (isRolesRightsLoading || isUserRightsLoading || isUserRolesLoading) {
    return <div>Loading...</div>;
  }

  const apiResponseError = [userRights, userRoles].find(
    (el) => el && el.statusCode !== 0
  );

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusDescription} />;
  }

  const error = [userRightsError, rolesRightsError, userRolesError].find(
    (err) => err !== undefined
  );

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!userRights) {
    return <EmptyData />;
  }

  return (
    <UserControllerRights
      tableData={tableData}
      loading={isMutationLoading}
      handleSubmit={handleSubmit}
      isAdmin={isAdmin}
      roleRight={rolesRights}
      initialValues={initialValues}
    />
  );
}
