import { useEffect, useState } from 'react';
import { getInputHtmlSize } from 'shared/lib';
import { PaginationTotalCount } from '../pagination-total-count';

import { PageSizeContainer, PageSizeInput, PageSizeLabel } from './styles';

type Props = {
  size: number;
  handleEnterPress: (val: number) => void;
  count: number | undefined;
};

export function PageSize({ size, handleEnterPress, count }: Props) {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(String(size));
  }, [size]);

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(e.target.value) > 0 || e.target.value === '') {
      setValue(e.target.value);
    }
  };

  return (
    <PageSizeContainer>
      <PageSizeLabel>Показывать строк</PageSizeLabel>
      <PageSizeInput
        value={value}
        onChange={handleValueChange}
        htmlSize={getInputHtmlSize(value)}
        onPressEnter={(e) => {
          const val = Number((e.target as HTMLInputElement).value);

          if (val > 0) {
            handleEnterPress(val);
          }
        }}
      />
      <PaginationTotalCount count={count} />
    </PageSizeContainer>
  );
}
