import { ADD_PREFIX, SETTINGS } from './common';

export const FIRMWARES = 'firmwares';
export const FIRMWARE = ':firmwareId';

export const FIRMWARE_ROUTES = {
  FIRMWARES: `/${SETTINGS}/${FIRMWARES}`,
  FIRMWARE: `/${SETTINGS}/${FIRMWARES}/${FIRMWARE}`,
  ADD_FIRMWARE: `/${SETTINGS}/${FIRMWARES}/${ADD_PREFIX}`,
};
