import { ErrorMessage, EmptyData } from 'shared/ui';

import { getServerErrorText } from 'shared/lib';

import { useGetCompaniesQuery } from 'entities/company';
import { useGetCountriesQuery } from 'entities/country';

import { PersonForm } from '../form';
import { useGetPersonsGroupsQuery } from 'entities/group';

type Props = Omit<
  React.ComponentProps<typeof PersonForm>,
  'countries' | 'companies' | 'groups'
>;

export function ConnectedPersonForm(props: Props) {
  const {
    data: groups,
    isFetching: isGroupsFetching,
    error: groupsError,
  } = useGetPersonsGroupsQuery();

  const {
    data: companies,
    isFetching: isCompaniesFetching,
    error: companiesError,
  } = useGetCompaniesQuery();

  const {
    data: countries,
    isFetching: isCountriesFetching,
    error: countriesError,
  } = useGetCountriesQuery();

  if (isGroupsFetching || isCompaniesFetching || isCountriesFetching) {
    return <div>Loading...</div>;
  }

  const apiResponseError = [groups, companies, countries].find(
    (el) => el && el.statusCode !== 0
  );

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusDescription} />;
  }

  const error = [groupsError, companiesError, countriesError].find(
    (err) => err !== undefined
  );

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!companies || !countries || !groups) {
    return <EmptyData />;
  }

  return (
    <PersonForm
      {...props}
      companies={companies.companies}
      countries={countries.countries}
      groups={groups.groups}
    />
  );
}
