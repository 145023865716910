import { Col, Form, FormProps, Space, Spin, Upload } from 'antd';
import { useEffect, useState } from 'react';
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
import { UploadOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

import { formHasErrors, getExtensionsString } from 'shared/lib';
import { UiInput, UiTextArea } from 'shared/ui/ui-kit';

import {
  checkControllerRight,
  selectTokenClaims,
  selectTokenRoles,
} from 'entities/authorization';
import { PushNotificationMessageDto } from 'entities/push-notification';

import {
  CardFormWrapper,
  FormItem,
  FormRow,
  StyledButton,
  StyledDraggerFormItem,
} from '../../../../common-styles';
import {
  ACCEPTED_PERSONS_LIST_EXTENSIONS,
  PUSH_NOTIFICATION_TEXT_MAX_LENGTH,
  PUSH_NOTIFICATION_TITLE_MAX_LENGTH,
} from '../../consts';
import { VALIDATION_RULE } from '../../../../constants/validation-rules';
import { PushNotificationFormData } from '../../model/form';

import { maxLengthValidationRule } from '../../../../constants/validation-rules';

import { FormButtonsContainer } from '../../../../components/FormButtons/styles';
import { LoadedPersonsMessage, UsersHint } from './styles';
import { UploadedFile } from 'shared/ui/uploaded-file';

const accepted = getExtensionsString(ACCEPTED_PERSONS_LIST_EXTENSIONS);

type Props = {
  title: string;
  loading: boolean;
  initialValues: PushNotificationFormData;
  handleSubmit: (values: PushNotificationFormData) => void;
  disabled?: boolean;
  id?: PushNotificationMessageDto['id'];
  disableFileUpload?: boolean;
  loadedPersonsCount: null | number;
  onLoadPersons?: (files: UploadFile<any>[]) => Promise<void>;
  onSendPush?: (id: number) => Promise<void>;
};

export function PushNotificationForm({
  title,
  handleSubmit,
  initialValues,
  loading,
  disabled = false,
  onSendPush,
  id,
  disableFileUpload = false,
  loadedPersonsCount,
  onLoadPersons,
}: Props) {
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const roles = useSelector(selectTokenRoles);
  const claims = useSelector(selectTokenClaims);

  useEffect(() => {
    setFileList([]);
  }, [loadedPersonsCount]);

  const hasWriteRight = checkControllerRight(
    'PushNotification',
    'Write',
    roles,
    claims
  );
  const hasExecuteRight = checkControllerRight(
    'PushNotification',
    'Execute',
    roles,
    claims
  );

  const [isFormDisabled, setIsFormDisabled] = useState(disabled);

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [hasErrors, setHasErrors] = useState(false);

  const [form] = Form.useForm<PushNotificationFormData>();

  const deleteFile = async (uid: string) => {
    setFileList([]);
  };

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  const onValuesChange: FormProps<PushNotificationFormData>['onValuesChange'] =
    (_changedValues, allValues) => {
      if (
        allValues.name !== '' &&
        allValues.title !== '' &&
        allValues.text !== ''
      ) {
        setIsSubmitDisabled(false);
      } else {
        setIsSubmitDisabled(true);
      }
    };

  const onFieldsChange: FormProps<PushNotificationFormData>['onFieldsChange'] =
    (_, _allFields) => {
      setHasErrors(formHasErrors(form));
    };

  const props: UploadProps = {
    multiple: false,
    maxCount: 1,
    accept: accepted,
    onRemove: (file) => {
      setFileList([]);
    },
    beforeUpload: (file, list) => {
      setFileList([file]);

      return false;
    },
    itemRender: (originNode, file, fileList) => {
      let errors: string[] = [];

      if (file.size) {
        errors = [...errors];
      }

      return (
        <UploadedFile
          fileName={file.name}
          errors={errors}
          handleDelete={() => deleteFile(file.uid)}
        />
      );
    },
    fileList,
  };

  const hasLoadedPersons = loadedPersonsCount !== null;

  return (
    <CardFormWrapper title={title}>
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues}
        onFinish={handleSubmit}
        onValuesChange={onValuesChange}
        onFieldsChange={onFieldsChange}
      >
        <FormRow>
          <Col span={24}>
            <FormItem
              label="Название"
              name="name"
              rules={[VALIDATION_RULE.REQUIRED]}
            >
              <UiInput disabled={isFormDisabled} />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow>
          <Col span={24}>
            <FormItem
              label="Заголовок"
              name="title"
              rules={[
                maxLengthValidationRule(PUSH_NOTIFICATION_TITLE_MAX_LENGTH),
                VALIDATION_RULE.REQUIRED,
              ]}
            >
              <UiTextArea disabled={isFormDisabled} />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow>
          <Col span={24}>
            <FormItem
              label="Текст"
              name="text"
              rules={[
                maxLengthValidationRule(PUSH_NOTIFICATION_TEXT_MAX_LENGTH),
                VALIDATION_RULE.REQUIRED,
              ]}
            >
              <UiTextArea disabled={isFormDisabled} />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow>
          <Col span={24}>
            <FormItem label="URL для открытия браузера" name="browserUrl">
              <UiTextArea disabled={isFormDisabled} />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow>
          <Col span={24}>
            <FormItem label="Deeplink" name="deepLink">
              <UiTextArea disabled={isFormDisabled} />
            </FormItem>
          </Col>
        </FormRow>
        {/* <FormRow>
                <Col span={24}>
                  <FormItem
                    label="URL Deeplink"
                    name="imgUrl"
                    rules={[VALIDATION_RULE.URL]}
                  >
                    <UiTextArea disabled={isFormDisabled} />
                  </FormItem>
                </Col>
              </FormRow> */}
        {disableFileUpload ? (
          <UsersHint>
            Загрузка списка номеров будет доступна после создания
            пуш-уведомления
          </UsersHint>
        ) : null}
        <FormRow>
          <Col span={24}>
            <StyledDraggerFormItem>
              <Upload.Dragger {...props} disabled={Boolean(disableFileUpload)}>
                <StyledButton
                  htmlType="button"
                  type="primary"
                  icon={<UploadOutlined />}
                  style={{ margin: '0 auto' }}
                  disabled={Boolean(disableFileUpload)}
                >
                  Выбрать файл
                </StyledButton>
              </Upload.Dragger>
            </StyledDraggerFormItem>
          </Col>
        </FormRow>
        {hasLoadedPersons ? (
          <LoadedPersonsMessage>{`Загружено телефонных номеров: ${loadedPersonsCount}`}</LoadedPersonsMessage>
        ) : null}

        <Spin spinning={loading}>
          <FormButtonsContainer>
            <Space direction="horizontal" size={10}>
              {hasWriteRight ? (
                isFormDisabled ? (
                  <StyledButton
                    htmlType="button"
                    type="primary"
                    onClick={() => setIsFormDisabled(false)}
                  >
                    Редактировать
                  </StyledButton>
                ) : (
                  <StyledButton
                    htmlType="submit"
                    type="primary"
                    disabled={isSubmitDisabled || hasErrors}
                  >
                    Сохранить
                  </StyledButton>
                )
              ) : null}

              {hasWriteRight ? (
                onLoadPersons &&
                (!hasLoadedPersons || loadedPersonsCount === 0) ? (
                  <StyledButton
                    disabled={fileList.length === 0}
                    htmlType="button"
                    type="primary"
                    onClick={() => {
                      onLoadPersons(fileList);
                    }}
                  >
                    Загрузить список телефонов
                  </StyledButton>
                ) : null
              ) : null}

              {hasExecuteRight ? (
                hasLoadedPersons && loadedPersonsCount > 0 && onSendPush ? (
                  <StyledButton
                    htmlType="button"
                    type="primary"
                    onClick={() => onSendPush(Number(id))}
                    disabled={!hasLoadedPersons}
                  >
                    Отправить пуш-уведомление
                  </StyledButton>
                ) : null
              ) : null}
            </Space>
          </FormButtonsContainer>
        </Spin>
      </Form>
    </CardFormWrapper>
  );
}
