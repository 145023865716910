import { Col, Form, FormProps, Select, Spin } from 'antd';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { formHasErrors } from 'shared/lib';

import {
  UiDivider,
  UiFormCheckbox,
  UiInput,
  UiSelect,
  UiTextArea,
} from 'shared/ui/ui-kit';
import { EditChargePointsPhotosWidget } from 'widgets/charge-point/edit-photos-list';

import {
  FormButtonsContainer,
  FormItem,
  FormRow,
  StyledButton,
} from '../../../../../../common-styles';
import { VALIDATION_RULE } from '../../../../../../constants/validation-rules';
import { Hub } from '../../../../../../models/hub';
import { ChargePointFormData } from '../../../../types';

type FormDataType = ChargePointFormData['cardSettings'];

interface CardSettingsFormProps {
  initialValues: FormDataType;
  hubs: Hub[];
  onCancel: () => void;
  handleSubmit: (values: FormDataType) => void;
  loading: boolean;
  editing: boolean;
}

export function CardSettingsForm({
  initialValues,
  hubs,
  onCancel,
  handleSubmit,
  loading,
  editing,
}: CardSettingsFormProps) {
  const { chargePointName } = useParams() as {
    chargePointName: string;
  };

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [hasErrors, setHasErrors] = useState(false);

  const [form] = Form.useForm<FormDataType>();

  const onFieldsChange: FormProps<FormDataType>['onFieldsChange'] = (
    _,
    _allFields
  ) => {
    setHasErrors(formHasErrors(form));
  };

  const onValuesChange: FormProps<FormDataType>['onValuesChange'] = (
    _,
    allValues
  ) => {
    if (allValues.latitude !== '' && allValues.longitude !== '') {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onFinish={handleSubmit}
      onFieldsChange={onFieldsChange}
      onValuesChange={onValuesChange}
      initialValues={initialValues}
    >
      <FormRow gutter={20}>
        <Col span={12}>
          <FormItem
            label="Географическая широта"
            name="latitude"
            rules={[
              VALIDATION_RULE.LATITUDE,
              VALIDATION_RULE.REQUIRED,
              VALIDATION_RULE.NO_WHITESPACE,
            ]}
          >
            <UiInput />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            label="Географическая долгота"
            name="longitude"
            rules={[
              VALIDATION_RULE.LONGITUDE,
              VALIDATION_RULE.REQUIRED,
              VALIDATION_RULE.NO_WHITESPACE,
            ]}
          >
            <UiInput />
          </FormItem>
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col>
          <FormItem
            label="Статус ЭЗС на карте"
            name="mapStatus"
            valuePropName="checked"
          >
            <UiFormCheckbox>Показывать</UiFormCheckbox>
          </FormItem>
        </Col>
        <Col>
          <FormItem
            label="Тестовая ЭЗС"
            name="isTestCp"
            valuePropName="checked"
          >
            <UiFormCheckbox>Да</UiFormCheckbox>
          </FormItem>
        </Col>
      </FormRow>
      <UiDivider />
      <FormRow gutter={20}>
        <Col span={12}>
          <FormItem label="Хаб" name="hubId">
            <UiSelect
              options={[
                {
                  value: '',
                  label: 'Без хаба',
                },
                ...hubs.map(({ id, name }) => ({
                  value: String(id),
                  label: name,
                })),
              ]}
            />
          </FormItem>
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={12}>
          <FormItem
            label="URL заявки на пропуск"
            name="passUrl"
            rules={[VALIDATION_RULE.URL]}
          >
            <UiInput />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            label="URL для просмотра видео"
            name="videoTranslationUrl"
            rules={[VALIDATION_RULE.URL]}
          >
            <UiInput />
          </FormItem>
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={12}>
          <FormItem
            label="Максимальное расстояние до пользователя"
            name="userLocationCheckDistance"
            rules={[VALIDATION_RULE.NULL_OR_POSITIVE_NUMBER]}
          >
            <UiInput />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem label="Время работы ЭЗС" name="workingTime">
            <UiInput />
          </FormItem>
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={24}>
          <FormItem label="Публичное описание" name="publicDescription">
            <UiTextArea autoSize />
          </FormItem>
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={24}>
          <FormItem
            label="Текст предупреждения для пользователя"
            name="warningText"
          >
            <UiTextArea autoSize />
          </FormItem>
        </Col>
      </FormRow>
      <Spin spinning={loading}>
        <FormButtonsContainer>
          <StyledButton
            htmlType="submit"
            type="primary"
            disabled={isSubmitDisabled || hasErrors}
          >
            {editing ? 'Сохранить' : 'Далее'}
          </StyledButton>

          <StyledButton htmlType="button" type="default" onClick={onCancel}>
            Отмена
          </StyledButton>
        </FormButtonsContainer>
      </Spin>
      {editing ? (
        <>
          <UiDivider />
          <EditChargePointsPhotosWidget chargePointName={chargePointName} />
        </>
      ) : null}
    </Form>
  );
}
