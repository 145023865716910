import { Select } from 'antd';
import styled from 'styled-components';

import { COLOR } from 'shared/consts';

export const StyledSelect = styled(Select)`
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR.black};
`;
