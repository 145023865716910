import { createColumnHelper } from '@tanstack/react-table';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';

import { FORMAT_TO_SECONDS, showTimeString } from 'shared/lib';
import { useTableSettings } from 'shared/ui/table/visible-columns/use-table-settings';
import { TColumnDef } from 'shared/ui/table/types';

import { selectManualCommandsTable } from '../../../../redux/slices/tables/tables-slice';

import { setVisibleColumns, TableColumns, TableColumnsState } from '../store';

import { ManualCommand, MANUAL_COMMAND_STATUS } from 'entities/manual-command';

const DATA_COLUMNS: TColumnDef<ManualCommand, TableColumns>[] = [
  {
    accessorKey: 'sendTime',
    id: 'sendTime',
    header: 'Дата отправки',
    cell: (props) => {
      const value = props.row.original.sendTime || '';

      return value ? showTimeString(value, FORMAT_TO_SECONDS) : '';
    },
  },
  {
    accessorKey: 'sender',
    id: 'sender',
    header: 'Отправитель',
  },
  {
    accessorKey: 'commandType',
    id: 'commandType',
    header: 'Команда',
  },
  {
    accessorKey: 'status',
    id: 'status',
    header: 'Статус',
    cell: (props) => {
      const value = props.row.original.status;

      return MANUAL_COMMAND_STATUS[value];
    },
  },
  {
    accessorKey: 'chargePointName',
    id: 'chargePointName',
    header: 'ЭЗС',
  },
  {
    accessorKey: 'connectorId',
    id: 'connectorId',
    header: 'Коннектор',
  },
];

const columnHelper = createColumnHelper<ManualCommand>();

export const useColumns = () => {
  const dispatch = useDispatch();

  const settings = useSelector(selectManualCommandsTable);

  const settingsColumn = useTableSettings({
    columnHelper,
    columns: DATA_COLUMNS.map(({ id, header }) => {
      return {
        key: id,
        label: header as string,
        isChecked: settings[id],
      };
    }),
    settings,
    renderCell: (props) => {
      return '';
    },
    setVisibleColumns: (cols: TableColumnsState) => {
      dispatch(setVisibleColumns(cols));
    },
  });

  const visibleColumns = useMemo(() => {
    const dataCols = settings
      ? DATA_COLUMNS.filter((el) => settings[el.id])
      : DATA_COLUMNS;

    return [...dataCols, settingsColumn];
  }, [settings]);

  return visibleColumns;
};
