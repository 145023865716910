const DEFAULT_INPUT_HTML_SIZE = 5;

export const getInputHtmlSize = (
  val: undefined | string,
  defaultSize = DEFAULT_INPUT_HTML_SIZE
): number => {
  if (val === undefined || val.length === 0) {
    return defaultSize;
  }

  return Math.max(val.length + 1, defaultSize);
};
