import styled from 'styled-components';

export const LevelContainer = styled.div`
  width: 100%;
`;

export const EditLevelIcon = styled.img`
  cursor: pointer;
  align-self: end;
`;
