import { StyledSettingsIcon } from './styles';
import SettingsIcon from '../images/settings.svg';

type Props = {
  onClick: () => void;
};

export function TableSettings({ onClick }: Props) {
  return (
    <StyledSettingsIcon
      src={SettingsIcon}
      alt="setting icon"
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();

        onClick();
      }}
    />
  );
}
