import styled from 'styled-components';

export const Wrapper = styled.div``;

export const VisibleColumnsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px 12px;

  & > * {
    width: 100%;
    min-width: max-content;
    padding: 10px;
    margin-left: 0 !important;
  }
`;
