import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';

import { TColumnDef } from 'shared/ui/table/types';
import { InputFilter } from 'shared/ui/table/filter';
import { useTableSettings } from 'shared/ui/table/visible-columns/use-table-settings';

import { setVisibleColumns, TableColumns, TableColumnsState } from '../store';

import {
  ChargePointDTO,
  ChargePointType,
  CHARGE_POINT_STATUS,
} from 'entities/charge-point';
import { GroupDto } from 'entities/group';

import { ChargePointTableActions } from 'features/charge-point/table/ui/table-actions';

import { selectChargePointsTable } from '../../../../redux/slices/tables/tables-slice';
import { CellContainer, ChargePointStatusBar } from './styles';

import { TurtleIcon } from '../images/turtle-icon';
import { LightningIcon } from '../images/lightning-icon';
import { ExclamationMarkIcon } from '../images/exclamation-mark-icon';
import { CHARGE_POINT_ROUTES } from 'shared/consts/routes/charge-point';

const columnHelper = createColumnHelper<ChargePointDTO>();

const CHARGE_POINT_TYPE_ICON: Record<
  Exclude<ChargePointType, 0>,
  JSX.Element
> = {
  1: <TurtleIcon />,
  2: <LightningIcon />,
};

type Props = {
  groups: GroupDto[];
};

export const useColumns = ({ groups }: Props) => {
  const dispatch = useDispatch();

  const DATA_COLUMNS: TColumnDef<ChargePointDTO, TableColumns>[] = [
    {
      accessorKey: 'idGroup',
      id: 'idGroup',
      header: 'Расположение',
      size: 200,
      minSize: 200,
      maxSize: 200,
      cell: (props) => {
        const { status, idGroup } = props.row.original;

        const groupName =
          groups.find((group) => group.id === idGroup)?.name || '';

        return (
          <CellContainer>
            <Tooltip title={CHARGE_POINT_STATUS[status]}>
              <ChargePointStatusBar status={status} />
            </Tooltip>
            {groupName && (
              <Link to={`${CHARGE_POINT_ROUTES.CHARGE_POINTS}/${idGroup}`}>
                {groupName}
              </Link>
            )}
          </CellContainer>
        );
      },
      enableSorting: false,
      meta: {
        filterElement: (
          <InputFilter
            column={{ id: 'idGroup', header: 'Расположение' }}
            disabled
          />
        ),
      },
    },
    {
      accessorKey: 'warningText',
      id: 'warningText',
      header: 'Alert для МП',
      size: 70,
      minSize: 70,
      maxSize: 70,
      cell: (props) => {
        const { warningText } = props.row.original;

        if (!warningText) {
          return '';
        }

        return (
          <Tooltip title={warningText}>
            <span>
              <ExclamationMarkIcon />
            </span>
          </Tooltip>
        );
      },
      enableSorting: false,
      meta: {
        filterElement: (
          <InputFilter column={{ id: 'warningText', header: '' }} disabled />
        ),
      },
    },
    {
      accessorKey: 'name',
      id: 'name',
      header: 'Наименование',
      cell: (props) => {
        const { idGroup, name } = props.row.original;

        return (
          <Link
            to={`${CHARGE_POINT_ROUTES.CHARGE_POINTS}/${
              idGroup ? idGroup : null
            }/${name}`}
          >
            {name}
          </Link>
        );
      },
      meta: {
        filterElement: (
          <InputFilter column={{ id: 'name', header: 'Наименование' }} />
        ),
      },
    },
    {
      accessorKey: 'type',
      id: 'type',
      header: 'Тип',
      size: 50,
      minSize: 50,
      maxSize: 50,
      cell: (props) => {
        const { type } = props.row.original;

        const tooltipText =
          type === 1 ? 'Медленная станция (АС)' : 'Быстрая станция (DC)';

        return (
          <Tooltip title={tooltipText}>
            <span>{CHARGE_POINT_TYPE_ICON[type]}</span>
          </Tooltip>
        );
      },
      meta: {
        filterElement: (
          <InputFilter
            column={{
              id: 'type',
              header: 'Тип',
            }}
            disabled
          />
        ),
      },
    },
    {
      accessorKey: 'manufacturer',
      id: 'manufacturer',
      header: 'Производитель',
      size: 200,
      minSize: 200,
      maxSize: 200,
      meta: {
        filterElement: (
          <InputFilter
            column={{ id: 'manufacturer', header: 'Производитель' }}
          />
        ),
      },
    },
    {
      accessorKey: 'softwareRevision',
      id: 'softwareRevision',
      header: 'Версия ПО',
      meta: {
        filterElement: (
          <InputFilter
            column={{ id: 'softwareRevision', header: 'Версия ПО' }}
          />
        ),
      },
    },
    {
      accessorKey: 'address',
      id: 'address',
      header: 'Адрес',
      size: 350,
      minSize: 350,
      maxSize: 350,
      meta: {
        filterElement: (
          <InputFilter column={{ id: 'address', header: 'Адрес' }} />
        ),
      },
    },
    {
      accessorKey: 'owner',
      id: 'owner.name',
      header: 'Владелец',
      cell: (props) => {
        const name = props.row.original.owner?.name || '';

        return name;
      },
      size: 200,
      minSize: 200,
      maxSize: 200,
      enableSorting: false,
      meta: {
        filterElement: (
          <InputFilter column={{ id: 'owner.name', header: 'Владелец' }} />
        ),
      },
    },
    {
      accessorKey: 'owner',
      id: 'owner.tariff.name',
      header: 'Тариф',
      cell: (props) => {
        const name = props.row.original.owner?.tariff?.name || '';

        return name;
      },
      enableSorting: false,
      meta: {
        filterElement: (
          <InputFilter column={{ id: 'owner.tariff.name', header: 'Тариф' }} />
        ),
      },
    },
    {
      accessorKey: 'deviceErrors',
      id: 'deviceErrors',
      header: 'Ошибки',
      size: 250,
      minSize: 250,
      maxSize: 250,
      meta: {
        filterElement: (
          <InputFilter column={{ id: 'deviceErrors', header: 'Ошибки' }} />
        ),
      },
    },
  ];

  const settings = useSelector(selectChargePointsTable);

  const settingsColumn = useTableSettings({
    columnHelper,
    columns: DATA_COLUMNS.map(({ id, header }) => {
      return {
        key: id,
        label: header as string,
        isChecked: settings[id],
      };
    }),
    settings,
    renderCell: (props) => {
      const { name } = props.row.original;

      return <ChargePointTableActions chargePointName={name} />;
    },
    setVisibleColumns: (cols: TableColumnsState) => {
      dispatch(setVisibleColumns(cols));
    },
  });

  const visibleColumns = useMemo(() => {
    const dataCols = settings
      ? DATA_COLUMNS.filter((el) => settings[el.id])
      : DATA_COLUMNS;

    return [...dataCols, settingsColumn];
  }, [settings, groups]);

  return visibleColumns;
};
