import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { UiModal } from 'shared/ui';

import { MongoReserveSessionDto } from 'entities/session';

import { ConnectedReserveDetails } from '../connected-reserve-details';

type Props = {
  session: MongoReserveSessionDto;
};

export const ReserveDetailsModal = NiceModal.create((props: Props) => {
  const modal = useModal();

  return (
    <UiModal
      title="Информация о детализации"
      visible={modal.visible}
      onCancel={modal.hide}
      afterClose={modal.remove}
    >
      <ConnectedReserveDetails {...props} closeModal={modal.hide} />
    </UiModal>
  );
});
