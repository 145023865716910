import { Link } from 'react-router-dom';

import { NavigationTabButton } from 'shared/ui';
import { OWNER_ROUTES } from 'shared/consts/routes/owner';

export function AddOwnerButtonLink() {
  return (
    <Link to={OWNER_ROUTES.ADD_OWNER}>
      <NavigationTabButton title="Добавить владельца" />
    </Link>
  );
}
