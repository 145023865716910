import {
  CarouselContainer,
  CarouselDescription,
  CarouselTitle,
  LogoContainer,
  PictureMock,
} from './styles';
import Picture from './images/picture.jpg';
import { LogoSitronicsElectro } from '../../../../../components/UI/LogoSitronicsElectro';

export function LoginCarousel() {
  return (
    <CarouselContainer>
      <LogoContainer>
        <LogoSitronicsElectro type="white" />
      </LogoContainer>
      <CarouselTitle>
        Управляйте зарядными станциями и следите за их состоянием в одном окне
      </CarouselTitle>
      <CarouselDescription>
        В веб-интерфейсе Sitronics Electro можно удаленно управлять станцией,
        проверять состояние ЭЗС, отслеживать сессии пользователей, формировать и
        выгружать отчеты. <br />
        <br />
        Авторизуйтесь, чтобы открыть доступ ко всем возможностям сервиса.
      </CarouselDescription>
      <PictureMock src={Picture} />
    </CarouselContainer>
  );
}
