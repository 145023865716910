import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';

import { useGetCountriesQuery } from 'entities/country';

import { AppSettingsForm } from '../form';

type Props = Omit<React.ComponentProps<typeof AppSettingsForm>, 'countries'>;

export function ConnectedAppSettingsForm(props: Props) {
  const { data, isFetching, error } = useGetCountriesQuery();

  if (isFetching) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  if (!data) {
    return <EmptyData />;
  }

  return <AppSettingsForm {...props} countries={data.countries} />;
}
