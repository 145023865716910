import { baseApi } from 'shared/api/base-api';

import { getEndpointPath } from 'shared/lib';

import {
  AcquiringReportBaseResponse,
  DebitReportBaseResponse,
  GetAcquiringReportRequestArgs,
  GetDebitReportRequestArgs,
  GetPaymentsReportRequestArgs,
  GetStatConsumeRequestArgs,
  PaymentsReportResponse,
  StatConsumeReportBaseResponse,
} from './types';
import { PLATFORM_SERVICE } from 'entities/authorization';

import { apiSlice } from '../../../redux/services/apiSlice';

const KEY = 'Report';

const getUrl = getEndpointPath(PLATFORM_SERVICE, KEY);

export const reportsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAcquiringReport: builder.query<
      AcquiringReportBaseResponse,
      GetAcquiringReportRequestArgs
    >({
      query: (params) => ({
        url: getUrl(`GetAcquiringReport`),
        params,
      }),
      providesTags: [{ type: 'Reports', id: 'Acquiring' }],
    }),
    getStatConsume: builder.query<
      StatConsumeReportBaseResponse,
      GetStatConsumeRequestArgs
    >({
      query: (params) => ({
        url: getUrl(`GetStatConsume`),
        params,
      }),
      providesTags: [{ type: 'Reports', id: 'Consume' }],
    }),
    getPaymentsReport: builder.query<
      PaymentsReportResponse,
      GetPaymentsReportRequestArgs
    >({
      query: (params) => ({
        url: getUrl(`GetPaymentsReport`),
        params,
      }),
      providesTags: [{ type: 'Reports', id: 'Payments' }],
    }),
    getDebitReport: builder.query<
      DebitReportBaseResponse,
      GetDebitReportRequestArgs
    >({
      query: (params) => ({
        url: getUrl(`GetDebitReport`),
        params,
      }),
      providesTags: [{ type: 'Reports', id: 'Debit' }],
    }),
  }),
});

export const {
  useGetAcquiringReportQuery,
  useGetStatConsumeQuery,
  useGetPaymentsReportQuery,
  useGetDebitReportQuery,
} = reportsApi;
