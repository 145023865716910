import styled from 'styled-components';

import { COLOR, DEFAULT_BORDER } from 'shared/consts';

export const TablaLayoutContainer = styled.div``;

export const TableContainer = styled.div`
  border-radius: 8px;
  overflow: auto;
`;

export const StyledFixedTable = styled.table`
  min-width: 100%;
  width: max-content;
  border-spacing: 0;
  border-collapse: collapse;
  background-color: ${COLOR.white};
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR.black};

  table-layout: fixed;
  width: 100%;

  & thead {
    // position: sticky;
    top: 0;
    color: ${COLOR.darkGrey};
    border-bottom: ${DEFAULT_BORDER};
  }

  & thead tr {
    // display: block;
  }

  & th {
    background-color: ${COLOR.white};
    text-align: left;
    vertical-align: bottom;
    box-sizing: border-box;
    padding: 20px 8px 18px 8px;

    &:first-child {
      padding-left: 16px;
    }

    &:last-child {
      padding-right: 16px;
    }

    & .cursor-pointer {
      cursor: pointer;
    }

    &.sorted {
      background-color: ${COLOR.sortColumn};
    }

    &.settings {
      vertical-align: top;
    }
  }

  & td {
    // width: max-content;
    padding: 7px 8px;

    &.sorted {
      background-color: ${COLOR.sortColumn};
    }

    &:first-child {
      padding-left: 16px;
    }

    &:last-child {
      padding-right: 16px;
    }

    & span {
      width: 100%;
    }
  }

  & tbody tr {
    &:nth-child(odd) {
      background-color: ${COLOR.tableEvenRow};
    }

    &.clickable:hover {
      cursor: pointer;
    }
  }
`;

export const HeaderCellContent = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin-bottom: 4px;
`;

export const HeaderCellContainer = styled.div``;

export const HeaderCellText = styled.span``;

export const TableControls = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: end;
`;
