import React from 'react';

import { ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';

import { useGetChargePointsQuery } from '../../../../redux/services/charge-points';

import { ChargePointsLocations } from '../charge-points-locations';

export function ConnectedChargePointsLocations() {
  const { data, isFetching, error } = useGetChargePointsQuery({
    limit: -1,
    page: 0,
  });

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  return (
    <ChargePointsLocations
      loading={isFetching}
      chargePoints={data?.chargePoints ?? []}
    />
  );
}

export default React.memo(ConnectedChargePointsLocations);
