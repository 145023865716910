export function ParkingIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 6C2 3.79086 3.79086 2 6 2H18C20.2091 2 22 3.79086 22 6V18C22 20.2091 20.2091 22 18 22H6C3.79086 22 2 20.2091 2 18V6Z"
        stroke="#28303F"
        stroke-width="1.5"
      />
      <path
        d="M9 7.76859L8.99999 17"
        stroke="#28303F"
        stroke-width="1.5"
        strokeLinecap="round"
      />
      <path
        d="M9.04613 7.00079C8.63192 7.00079 8.29613 7.33658 8.29613 7.75079C8.29613 8.16501 8.63192 8.50079 9.04613 8.50079L9.04613 7.00079ZM13.3754 7.00079L9.04613 7.00079L9.04613 8.50079L13.3754 8.50079L13.3754 7.00079ZM13.3754 12.25L9 12.25L9 13.75L13.3754 13.75L13.3754 12.25ZM15.25 10.3754C15.25 11.4107 14.4107 12.25 13.3754 12.25L13.3754 13.75C15.2391 13.75 16.75 12.2391 16.75 10.3754L15.25 10.3754ZM13.3754 8.50079C14.4107 8.50079 15.25 9.34008 15.25 10.3754L16.75 10.3754C16.75 8.51165 15.2391 7.00079 13.3754 7.00079L13.3754 8.50079Z"
        fill="#28303F"
      />
    </svg>
  );
}
