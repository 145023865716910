export const getDurationString = (duration: string | null): string => {
  if (duration === null) {
    return `0 мин 0 сек`;
  }

  const [hoursRaw, minutes, seconds] = duration.split(':');

  let hours;

  const hasDecimal = hoursRaw.indexOf('.') !== -1;

  if (hasDecimal) {
    const [days, hrs] = hoursRaw.split('.');

    hours = Number(days) * 24 + Number(hrs);
  } else {
    hours = Number(hoursRaw);
  }

  const minutesInt = Number(minutes);

  const mins = hours > 0 ? hours * 60 + minutesInt : minutesInt;

  const secs = Math.ceil(Number(seconds));

  return `${mins} мин ${secs} сек`;
};
