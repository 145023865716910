import { useParams } from 'react-router-dom';

import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
  getServerErrorText,
} from 'shared/lib';

import { EmptyData, ErrorMessage } from 'shared/ui';

import {
  BankDTO,
  useGetBankQuery,
  useUpdateBankMutation,
  BankFormData,
  ConnectedBankForm,
} from 'entities/bank';

import { EDIT_SUCCESS, EDIT_ERROR } from '../consts';

const getInitialValues = (bank: BankDTO): BankFormData => {
  const { id, name, bankId, country, currencyId } = bank;

  const countryId = country?.id || '';

  return {
    id: String(id),
    name,
    bankId: String(bankId),
    countryId: String(countryId),
    currencyId: String(currencyId),
  };
};

export function EditBank() {
  const [trigger, { isLoading: isMutationLoading }] = useUpdateBankMutation();

  const { bankId } = useParams() as { bankId: string };

  const { isFetching, error, data } = useGetBankQuery(bankId);

  const handleSubmit = async (values: BankFormData) => {
    try {
      const res = await trigger(values).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);
        return;
      }
      //

      openSuccessNotification(EDIT_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : EDIT_ERROR;

      openErrorNotification(errorText);
    }
  };

  if (isFetching) {
    return <div>Loading...</div>;
  }

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!data?.bank) {
    return <EmptyData />;
  }

  const initialValues = getInitialValues(data.bank);

  return (
    <ConnectedBankForm
      handleSubmit={handleSubmit}
      loading={isMutationLoading}
      title={data.bank.name}
      initialValues={initialValues}
      disabled
    />
  );
}
