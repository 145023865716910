import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Link } from 'react-router-dom';

import { getTelegramLink, renderBooleanAsString } from 'shared/lib';

import {
  ChargePointDTO,
  CHARGE_POINT_PROTOCOL,
  CHARGE_POINT_STATUS,
  isOlderThanOneHour,
} from 'entities/charge-point';

import {
  WarningText,
  StyledCard,
  CardTitle,
  TechInfoTable,
  TechInfoTableHead,
  TechInfoTableBody,
  TechInfoTableRow,
  TechInfoTableTh,
  TechInfoTableTd,
  HighlightedRedText,
} from './styles';
import { OWNER_ROUTES } from 'shared/consts/routes/owner';

dayjs.extend(utc);
dayjs.extend(timezone);

type TechProperty = {
  label: React.ReactNode;
  value: React.ReactNode;
};

type Props = {
  chargePoint: ChargePointDTO;
};

export function TechInfoCard({ chargePoint }: Props) {
  const {
    warningText,
    manufacturer,
    softwareRevision,
    status,
    owner: {
      id: ownerId,
      name: ownerName = '',
      phoneNumber: ownerPhone = '',
      email: ownerEmail = '',
      description: ownerDescription = '',
      telegram: ownerTelegram = '',
    },
    city,
    hub,
    multiconnectorSupport,
    commentary,
    chargePointProtocol,
    lastRequestTime,
    previousStatus,
    mapStatus,
    isTestCp,
    userLocationCheckDistance,
    passUrl,
    workingTime,
    description,
    publicDescription,
    ipAddress,
    ocppConnectionBlockTime,
    supportContact,
    isOcppConnectionBlocked,
    latitude,
    longitude,
    simNumber,
  } = chargePoint;

  const hubName = hub ? hub.name : '';

  const TECH_PROPERTIES: Array<{
    label: React.ReactNode;
    value: React.ReactNode;
  }> = [
    {
      label: 'Текст предупреждения для пользователя',
      value: <WarningText>{warningText}</WarningText>,
    },
    {
      label: 'Публичное описание',
      value: publicDescription,
    },
    { label: 'Внутренний комментарий', value: commentary },
    {
      label: 'Описание',
      value: description,
    },
    { label: 'Производитель', value: manufacturer },
    { label: 'Версия ПО', value: softwareRevision },
    {
      label: 'Протокол ЭЗС',
      value: CHARGE_POINT_PROTOCOL[chargePointProtocol],
    },
    {
      label: 'IP-адрес',
      value: ipAddress,
    },
    {
      label: 'Статус',
      value: CHARGE_POINT_STATUS[status],
    },
    {
      label: 'Номер SIM',
      value: simNumber,
    },
    {
      label: 'Последнее обращение на сервер',
      value: (
        <HighlightedRedText highlighted={isOlderThanOneHour(lastRequestTime)}>
          {lastRequestTime}
        </HighlightedRedText>
      ),
    },
    {
      label: 'Статус до этого',
      value: CHARGE_POINT_STATUS[previousStatus],
    },
    {
      label: 'Поддержка мультиконнектора',
      value: renderBooleanAsString(multiconnectorSupport),
    },
    {
      label: 'Показывать на карте',
      value: renderBooleanAsString(Boolean(mapStatus)),
    },
    {
      label: 'Тестовая',
      value: renderBooleanAsString(isTestCp),
    },
    {
      label: 'Координаты',
      value: `${latitude}, ${longitude}`,
    },
    {
      label: 'Макс. расстояние до пользователя',
      value: userLocationCheckDistance,
    },
    { label: 'Город', value: city },
    {
      label: 'Время работы ЭЗС',
      value: workingTime,
    },
    {
      label: 'Заявка на пропуск',
      value: passUrl ? (
        <a href={passUrl} target="_blank">
          {passUrl}
        </a>
      ) : (
        ''
      ),
    },
    { label: 'Зарядный хаб', value: hubName },
    {
      label: 'Владелец',
      value: <Link to={`${OWNER_ROUTES.OWNERS}/${ownerId}`}>{ownerName}</Link>,
    },
    {
      label: 'Телефон владельца',
      value: ownerPhone,
    },
    {
      label: 'Описание владельца',
      value: ownerDescription,
    },
    {
      label: 'Email владельца',
      value: ownerEmail ? (
        <a href={`mailto:${ownerEmail}`} target="_blank">
          {ownerEmail}
        </a>
      ) : null,
    },
    {
      label: 'Контакт тех. поддержки',
      value: supportContact,
    },
    {
      label: 'Telegram владельца',
      value: ownerTelegram ? (
        <a href={getTelegramLink(ownerTelegram)} target="_blank">
          {ownerTelegram}
        </a>
      ) : null,
    },
    {
      label: (
        <HighlightedRedText highlighted={isOcppConnectionBlocked}>
          Блокировано СПАМ фильтром
        </HighlightedRedText>
      ),
      value: (
        <HighlightedRedText highlighted={isOcppConnectionBlocked}>
          {isOcppConnectionBlocked
            ? dayjs(ocppConnectionBlockTime)
                .tz('Europe/Moscow')
                .format('YYYY-MM-DD HH:mm:ss')
            : null}
        </HighlightedRedText>
      ),
    },
  ];

  const getTableRows = () => {
    return TECH_PROPERTIES.reduce((acc, el, idx, arr) => {
      if (idx % 2 === 0) {
        acc[idx / 2] = [el];
      } else {
        acc[(idx - 1) / 2].push(el);
      }

      if (idx % 2 === 0 && idx === arr.length - 1) {
        acc[idx / 2].push({ label: '', value: '' });
      }

      return acc;
    }, [] as Array<Array<TechProperty>>);
  };

  const renderParams = () => {
    const rows = getTableRows();

    return rows.map((row, idx) => {
      const [first, second] = row;

      const renderParam = (prop: TechProperty) => {
        if (!prop) return null;

        const { label, value } = prop;

        return (
          <>
            <TechInfoTableTd>{label}</TechInfoTableTd>
            <TechInfoTableTd>{value}</TechInfoTableTd>
          </>
        );
      };

      return (
        <TechInfoTableRow key={idx}>
          {renderParam(first)}
          {renderParam(second)}
        </TechInfoTableRow>
      );
    });
  };

  return (
    <StyledCard>
      <CardTitle>Техническая информация</CardTitle>
      <TechInfoTable>
        <TechInfoTableHead>
          <TechInfoTableRow>
            <TechInfoTableTh highlighted>Параметр</TechInfoTableTh>
            <TechInfoTableTh>Значение</TechInfoTableTh>
            <TechInfoTableTh highlighted>Параметр</TechInfoTableTh>
            <TechInfoTableTh>Значение</TechInfoTableTh>
          </TechInfoTableRow>
        </TechInfoTableHead>
        <TechInfoTableBody>{renderParams()}</TechInfoTableBody>
      </TechInfoTable>
    </StyledCard>
  );
}
