import { SiderbarMenu } from './menu';
import { SidebarContainer, SidebarLayout } from './styles';
import { SiderbarMenuItem } from './types';

type Props = {
  items: SiderbarMenuItem[];
  logoutButton: JSX.Element;
};

export function Sidebar({ items, logoutButton }: Props) {
  return (
    <SidebarLayout>
      <SidebarContainer>
        <SiderbarMenu items={items} />
        {logoutButton}
      </SidebarContainer>
    </SidebarLayout>
  );
}
