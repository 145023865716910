import { Space, Spin } from 'antd';

import { UiDefaultButton, UiPrimaryButton } from '../buttons';

type Props = {
  onCancel: () => void;
  onConfirm: () => void;
  loading: boolean;
};

export function ConfirmDialog({ onCancel, onConfirm, loading }: Props) {
  return (
    <Spin spinning={loading}>
      <Space direction="horizontal" size={10}>
        <UiPrimaryButton onClick={onCancel}>Отмена</UiPrimaryButton>
        <UiDefaultButton onClick={onConfirm}>Да</UiDefaultButton>
      </Space>
    </Spin>
  );
}
