export function ChargePointsIcon() {
  return (
    <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.3863 21.3636C22.6792 21.6565 23.154 21.6565 23.4469 21.3636C23.7398 21.0707 23.7398 20.5959 23.4469 20.303L22.3863 21.3636ZM21.8844 18.7405C21.5915 18.4476 21.1166 18.4476 20.8238 18.7405C20.5309 19.0334 20.5309 19.5082 20.8238 19.8011L21.8844 18.7405ZM7.29158 15.3333C7.7058 15.3333 8.04158 14.9975 8.04158 14.5833C8.04158 14.1691 7.7058 13.8333 7.29158 13.8333V15.3333ZM2.08325 13.8333C1.66904 13.8333 1.33325 14.1691 1.33325 14.5833C1.33325 14.9975 1.66904 15.3333 2.08325 15.3333V13.8333ZM7.29158 10.125C7.7058 10.125 8.04158 9.78917 8.04158 9.37496C8.04158 8.96075 7.7058 8.62496 7.29158 8.62496V10.125ZM2.08325 8.62496C1.66904 8.62496 1.33325 8.96075 1.33325 9.37496C1.33325 9.78917 1.66904 10.125 2.08325 10.125V8.62496ZM15.6249 4.91663C16.0391 4.91663 16.3749 4.58084 16.3749 4.16663C16.3749 3.75241 16.0391 3.41663 15.6249 3.41663V4.91663ZM2.08325 3.41663C1.66904 3.41663 1.33325 3.75241 1.33325 4.16663C1.33325 4.58084 1.66904 4.91663 2.08325 4.91663V3.41663ZM23.4469 20.303L21.8844 18.7405L20.8238 19.8011L22.3863 21.3636L23.4469 20.303ZM22.1666 14.5833C22.1666 17.6209 19.7042 20.0833 16.6666 20.0833V21.5833C20.5326 21.5833 23.6666 18.4493 23.6666 14.5833H22.1666ZM16.6666 20.0833C13.629 20.0833 11.1666 17.6209 11.1666 14.5833H9.66658C9.66658 18.4493 12.8006 21.5833 16.6666 21.5833V20.0833ZM11.1666 14.5833C11.1666 11.5457 13.629 9.08329 16.6666 9.08329V7.58329C12.8006 7.58329 9.66658 10.7173 9.66658 14.5833H11.1666ZM16.6666 9.08329C19.7042 9.08329 22.1666 11.5457 22.1666 14.5833H23.6666C23.6666 10.7173 20.5326 7.58329 16.6666 7.58329V9.08329ZM7.29158 13.8333H2.08325V15.3333H7.29158V13.8333ZM7.29158 8.62496H2.08325V10.125H7.29158V8.62496ZM15.6249 3.41663H2.08325V4.91663H15.6249V3.41663Z"
        fill="black"
      />
    </svg>
  );
}
