import { ADD_PREFIX } from './common';

export const DISCOUNTS = 'discounts';
export const DISCOUNT = ':discountId';

export const DISCOUNTS_ROUTES = {
  DISCOUNTS: `/${DISCOUNTS}`,
  DISCOUNT: `/${DISCOUNTS}/${DISCOUNT}`,
  ADD_DISCOUNT: `/${DISCOUNTS}/${ADD_PREFIX}`,
};
