import {
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import useMeasure from 'react-use-measure';

import { ClientTableLayout } from 'shared/ui';
import { useClientPagination, useTablePageSize } from 'shared/lib';

import { DiscountDescription, DiscountListItem } from 'entities/discount';

import { useColumns } from '../hooks/use-columns';

type Props = {
  tableData: Array<DiscountListItem>;
  loading: boolean;
  rowsCount: number | undefined;
};

export function DiscountsTableInstance({
  loading,
  tableData,
  rowsCount,
}: Props) {
  const [tableHeadRef, tableHeadBounds] = useMeasure();

  const columns = useColumns();

  const [sorting, setSorting] = useState<SortingState>([]);
  const [tablePageSize, setTablePageSize] = useTablePageSize(tableHeadBounds);
  const [pagination, setPagination] = useClientPagination(tablePageSize);

  const data = useMemo(() => tableData, [tableData]);

  const table = useReactTable({
    data,
    columns,
    state: { sorting, pagination },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <>
      <DiscountDescription />
      <ClientTableLayout
        table={table}
        tableHeadRef={tableHeadRef}
        handlePageSizePressEnter={setTablePageSize}
        pageSize={tablePageSize}
        loading={loading}
        rowsCount={rowsCount}
      />
    </>
  );
}
