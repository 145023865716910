import { ADD_PREFIX } from './common';

export const OWNERS = 'owners';
export const OWNER = ':ownerId';

export const OWNER_ROUTES = {
  OWNERS: `/${OWNERS}`,
  OWNER: `/${OWNERS}/${OWNER}`,
  ADD_OWNER: `/${OWNERS}/${ADD_PREFIX}`,
};
