import dayjs from 'dayjs';

const DATE_FORMAT_RU = 'DD.MM.YYYY';

export const getRuFormatDateString = (value?: string): string => {
  if (value === undefined) {
    return dayjs().format(DATE_FORMAT_RU);
  }

  return dayjs(value).format(DATE_FORMAT_RU);
};
