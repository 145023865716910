import styled from 'styled-components';

import { COLOR } from 'shared/consts';

export const StyledCard = styled.article`
  background: ${COLOR.white};
  border-radius: 8px;
  padding: 20px;
`;

export const CardTitle = styled.h4`
  font-weight: 500;
  color: ${COLOR.black};
  margin-bottom: 20px;
`;

export const IconsContainer = styled.div`
  display: flex;
  gap: 10px;
`;
