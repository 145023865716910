import { useNavigate } from 'react-router-dom';
import useWindowFocus from 'use-window-focus';

import { ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';
import { POLLING_INTERVAL } from 'shared/consts';

import { useGetChargePointQuery } from '../../../../redux/services/charge-points';

import ChargePointInfo from '../ChargePointInfo';
import { SpinnerContent } from '../ChargePointInfo/styles';

interface ConnectedChargePointInfo {
  chargePointName: string;
}

export function ConnectedChargePointInfo({
  chargePointName,
}: ConnectedChargePointInfo) {
  const isWindowFocused = useWindowFocus();

  const navigate = useNavigate();

  const { isFetching, error, data, currentData, isSuccess } =
    useGetChargePointQuery(chargePointName, {
      pollingInterval: isWindowFocused ? POLLING_INTERVAL : undefined,
    });

  if (isFetching && !currentData) {
    return (
      <div>Загрузка данных...</div>
      // <Spin spinning={true}>
      //   <SpinnerContent>Загрузка данных...</SpinnerContent>
      // </Spin>
    );
  }

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  // TODO:
  if (!isSuccess) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  return (
    <ChargePointInfo
      chargePoint={data.chargePoint}
      chargePointName={chargePointName}
    />
  );
}
