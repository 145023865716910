import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { getServerErrorText } from 'shared/lib';
import { ErrorMessage, EmptyData } from 'shared/ui';

import { useGetChargePointGroupsQuery } from 'entities/group';
import { useGetStatConsumeQuery } from 'entities/report';

import { useGetChargePointsQuery } from '../../../../../redux/services/charge-points';

import { ConsumeReport } from '../consume-report';

export function ConnectedConsumeReport() {
  const [skip, setSkip] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();

  const dateFrom = searchParams.get('dateFrom');
  const dateTo = searchParams.get('dateTo');
  const chargePointsParam = searchParams.get('chargePoints');
  const groupsParam = searchParams.get('groups');
  const groupByParam = searchParams.get('groupBy');

  const {
    data: groups,
    isLoading: isGroupsLoading,
    error: groupsError,
  } = useGetChargePointGroupsQuery();

  const {
    data: chargePoints,
    isLoading: isChargePointsLoading,
    error: chargePointsError,
  } = useGetChargePointsQuery({
    limit: -1,
    page: 0,
  });

  const {
    data,
    isFetching,
    error: dataError,
  } = useGetStatConsumeQuery(
    {
      dateFrom,
      dateTo,
      chargePoints: chargePointsParam,
      groupsId: groupsParam,
      groupby: groupByParam ?? '0',
    },
    {
      skip,
    }
  );

  // Если нужно получать при загрузке с параметрами, а не клику
  useEffect(() => {
    if (dateFrom && dateTo) {
      allowReportFetching();
    }
  }, []);

  const allowReportFetching = () => {
    setSkip(false);
  };

  if (isGroupsLoading || isChargePointsLoading) {
    return <div>Loading...</div>;
  }

  const apiResponseError = [groups, chargePoints, data].find(
    (el) => el && el.statusCode !== 0
  );

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusDescription} />;
  }

  const error = [groupsError, chargePointsError, dataError].find(
    (err) => err !== undefined
  );

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!groups || !chargePoints) {
    return <EmptyData />;
  }

  return (
    <ConsumeReport
      stats={data?.stats ?? []}
      allowReportFetching={allowReportFetching}
      loading={isFetching}
      groups={groups.groups}
      chargePoints={chargePoints.chargePoints}
    />
  );
}
