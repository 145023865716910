import { useParams } from 'react-router-dom';

import { ConnectedChargePointStatuses } from 'features/charge-point/statuses-table';

export function ChargePointStatusesPage() {
  const { chargePointName, groupId } = useParams() as {
    chargePointName: string;
    groupId: string;
  };

  return <ConnectedChargePointStatuses chargePointName={chargePointName} />;
}
