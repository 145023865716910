import { useNavigate } from 'react-router-dom';

import {
  openErrorNotification,
  openSuccessNotification,
  isErrorWithMessage,
} from 'shared/lib';

import {
  useAddConnectorInstructionMutation,
  ConnectorInstructionFormData,
  ConnectorInstructionForm,
  prepareRequestData,
} from 'entities/instruction';

import { ADD_SUCCESS, ADD_ERROR } from '../consts';
import { CONNECTOR_INSTRUCTION_ROUTES } from 'shared/consts/routes/connector-instruction';

const initialValues: ConnectorInstructionFormData = {
  id: '',
  description: '',
  connectorType: '',
  connectorTypeName: '',
  name: '',
  instructionLinks: '',
};

export function AddConnectorInstruction() {
  const [trigger, { isLoading }] = useAddConnectorInstructionMutation();

  const navigate = useNavigate();

  const handleSubmit = async (values: ConnectorInstructionFormData) => {
    try {
      const { id, ...req } = prepareRequestData(values);

      const res = await trigger(req).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);

        return;
      }
      //

      openSuccessNotification(ADD_SUCCESS);

      navigate(
        `${CONNECTOR_INSTRUCTION_ROUTES.CONNECTOR_INSTRUCTIONS}/${res.connectorInstruction.connectorType}`
      );
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : ADD_ERROR;

      openErrorNotification(errorText);
    }
  };

  return (
    <ConnectorInstructionForm
      title="Добавление новой инструкции коннектора"
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      loading={isLoading}
    />
  );
}
