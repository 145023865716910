import { NavigationTab } from 'shared/model';

import { AddPersonButtonLink, AddPersonsGroupButtonLink } from './buttons';

export const TABS: Array<NavigationTab> = [
  {
    key: 'person-list',
    path: '',
    label: 'Список клиентов',
    buttons: [
      {
        element: <AddPersonButtonLink />,
        controller: 'Person',
        right: 'Write',
      },
    ],
  },
  {
    key: 'groups',
    path: 'groups',
    label: 'Список групп',
    controller: 'Group',
    right: 'Write',
    buttons: [{ element: <AddPersonsGroupButtonLink /> }],
  },
  {
    key: 'session-log',
    path: 'session-log',
    label: 'Журнал сессий',
    controller: 'PrivateMethods',
    right: 'Read', //
    hasLoader: true,
  },
  {
    key: 'reserves',
    path: 'reserves',
    label: 'Журнал брони',
    controller: 'PrivateMethods',
    right: 'Read', //
  },
];
