import { Checkbox, MenuProps } from 'antd';
import { SelectInfo } from 'rc-menu/lib/interface';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { OwnerDto } from 'entities/owner';

import { StyledButton } from '../../../../../common-styles';

import {
  selectOwners,
  selectOwnerTypes,
  selectStatusTypes,
  selectWithoutTestCp,
  setOwners,
  setOwnerTypes,
  setStatusTypes,
  setWithoutTestCp,
} from '../../../../../redux/slices/filters/filtersSlice';

import { OWNER_TYPE, STATUS_TYPE } from '../../consts';
import {
  CardHeader,
  Cardtitle,
  CheckboxCover,
  CheckboxContainer,
  FiltersCard,
  StyledFiltersMenu,
  FiltersGrid,
  FiltersGridColumn,
  SelectAllButton,
  StyledButtonsContainer,
} from './styles';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group'
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

type Props = {
  ownersList: OwnerDto[];
  closeModal: () => void;
};

export function Filters({ ownersList, closeModal }: Props) {
  const ownerTypes = useSelector(selectOwnerTypes);
  const statusTypes = useSelector(selectStatusTypes);
  const owners = useSelector(selectOwners);
  const withoutTestCp = useSelector(selectWithoutTestCp);

  const [draftOwnerTypes, setDraftOwnerTypes] = useState(ownerTypes);
  const [draftStatusTypes, setDraftStatusTypes] = useState(statusTypes);
  const [draftOwners, setDraftOwners] = useState(owners);
  const [draftWithoutTestCp, setDraftWithoutTestCp] = useState(withoutTestCp);

  const dispatch = useDispatch();

  const ownerTypeItems: MenuProps['items'] = [
    getItem(
      'Тип владения станции',
      'sub1',
      undefined,
      Object.entries(OWNER_TYPE).map(([key, label]) => ({
        key,
        label: (
          <CheckboxContainer>
            <CheckboxCover />
            <Checkbox checked={draftOwnerTypes.includes(key)}>{label}</Checkbox>
          </CheckboxContainer>
        ),
      }))
    ),
  ];

  const statusTypeItems: MenuProps['items'] = [
    getItem(
      'Активность',
      'sub1',
      undefined,
      Object.entries(STATUS_TYPE).map(([key, label]) => ({
        key,
        label: (
          <CheckboxContainer>
            <CheckboxCover />
            <Checkbox checked={draftStatusTypes.includes(key)}>
              {label}
            </Checkbox>
          </CheckboxContainer>
        ),
      }))
    ),
  ];

  const testCpItems: MenuProps['items'] = [
    getItem('Тестовые', 'sub1', undefined, [
      {
        key: 'testCp',
        label: (
          <CheckboxContainer>
            <CheckboxCover />
            <Checkbox checked={Boolean(draftWithoutTestCp.length)}>
              Тестовые
            </Checkbox>
          </CheckboxContainer>
        ),
      },
    ]),
  ];

  const ownerIdsItems: MenuProps['items'] = [
    getItem(
      'Владелец',
      'sub1',
      undefined,
      ownersList.map(({ id, name }) => ({
        key: String(id),
        label: (
          <CheckboxContainer>
            <CheckboxCover />
            <Checkbox checked={draftOwners.includes(String(id))}>
              {name}
            </Checkbox>
          </CheckboxContainer>
        ),
      }))
    ),
  ];

  const checkAll =
    draftOwnerTypes.length === Object.keys(OWNER_TYPE).length &&
    draftStatusTypes.length === Object.keys(STATUS_TYPE).length &&
    draftWithoutTestCp.length === 1 &&
    draftOwners.length == ownersList.length;

  const handleOwnerTypesChange = (args: SelectInfo) => {
    setDraftOwnerTypes(args.selectedKeys);
  };

  const handleStatusTypesChange = (args: SelectInfo) => {
    setDraftStatusTypes(args.selectedKeys);
  };

  const handleOwnersChange = (args: SelectInfo) => {
    setDraftOwners(args.selectedKeys);
  };

  const handleWithoutTestCpChange = (args: SelectInfo) => {
    setDraftWithoutTestCp(args.selectedKeys);
  };

  const handleSubmitFilters = () => {
    dispatch(setOwnerTypes(draftOwnerTypes));
    dispatch(setStatusTypes(draftStatusTypes));
    dispatch(setOwners(draftOwners));
    dispatch(setWithoutTestCp(draftWithoutTestCp));

    closeModal();
  };

  const handleSelectAllClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (checkAll) {
      setDraftOwnerTypes([]);
      setDraftStatusTypes([]);
      setDraftWithoutTestCp([]);
      setDraftOwners([]);
    } else {
      setDraftOwnerTypes(Object.keys(OWNER_TYPE));
      setDraftStatusTypes(Object.keys(STATUS_TYPE));
      setDraftWithoutTestCp(['testCp']);
      setDraftOwners(ownersList.map((owner) => String(owner.id)));
    }
  };

  return (
    <FiltersCard>
      <CardHeader>
        <Cardtitle>Фильтры</Cardtitle>
        <SelectAllButton checkAll={checkAll} onClick={handleSelectAllClick}>
          {checkAll ? 'Очистить всё' : 'Выбрать всё'}
        </SelectAllButton>
      </CardHeader>
      <FiltersGrid>
        <FiltersGridColumn>
          <StyledFiltersMenu
            mode="inline"
            multiple
            items={ownerTypeItems}
            selectedKeys={draftOwnerTypes}
            onSelect={handleOwnerTypesChange}
            onDeselect={handleOwnerTypesChange}
            defaultOpenKeys={['sub1']}
          />
          <StyledFiltersMenu
            mode="inline"
            multiple
            items={statusTypeItems}
            selectedKeys={draftStatusTypes}
            onSelect={handleStatusTypesChange}
            onDeselect={handleStatusTypesChange}
            defaultOpenKeys={['sub1']}
          />
          <StyledFiltersMenu
            mode="inline"
            multiple
            items={testCpItems}
            selectedKeys={draftWithoutTestCp}
            onSelect={handleWithoutTestCpChange}
            onDeselect={handleWithoutTestCpChange}
            defaultOpenKeys={['sub1']}
          />
        </FiltersGridColumn>
        <FiltersGridColumn>
          <StyledFiltersMenu
            mode="inline"
            multiple
            items={ownerIdsItems}
            selectedKeys={draftOwners}
            onSelect={handleOwnersChange}
            onDeselect={handleOwnersChange}
            defaultOpenKeys={['sub1']}
          />
        </FiltersGridColumn>
      </FiltersGrid>
      <StyledButtonsContainer>
        <StyledButton type="primary" onClick={closeModal}>
          Отмена
        </StyledButton>
        <StyledButton type="default" onClick={handleSubmitFilters}>
          Сохранить
        </StyledButton>
      </StyledButtonsContainer>
    </FiltersCard>
  );
}
