// Пересчитываем сумму из subunit в unit
// (пример: из копеек в рубли)

import { toFixedDecimals } from './to-fixed-decimals';

export const toCurrencyUnit = (subunitValue: number) => {
  const unitValue = subunitValue / 100;

  return toFixedDecimals(unitValue, 2);
};
