import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: 20px;

  & > p:not(:last-child) {
    margin-bottom: 5px;
  }
`;

export const Variable = styled.span`
  font-weight: 600;
`;

export const ExamplesList = styled.ol`
  list-style: auto;
  padding-left: 20px;

  & > li:not(:last-child) {
    margin-bottom: 5px;
  }
`;
