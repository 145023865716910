import { Input } from 'antd';
import styled from 'styled-components';

const { TextArea } = Input;

import { COLOR } from 'shared/consts';

export const StyledTextArea = styled(TextArea)`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR.black};
  padding: 7px;
  border: 1px solid ${COLOR.inputGrey};
  border-radius: 2px;
`;
