import { PersonBalanceListItem } from 'entities/balance';
import { BankDTO, BankListItem } from 'entities/bank';
import { BonusProgramAchievementListItem } from 'entities/bonus-program';

import { BonusSummaryByCurrency } from '../bonus-summary-by-currency';
import { BonusInfoContainer } from './styles';

type Props = {
  bonuses: Array<PersonBalanceListItem>;
  achievements: Array<BonusProgramAchievementListItem>;
  banks: Array<BankListItem>;
  personId: string;
};

export function BonusSummary({
  achievements,
  banks,
  personId,
  bonuses,
}: Props) {
  return (
    <BonusInfoContainer>
      {achievements.length === 0 ? (
        <BonusSummaryByCurrency personId={personId} banks={banks} />
      ) : (
        achievements.map((achievement) => {
          const bank = banks.find(
            (bank) => bank.bankId === achievement.bonusProgram.bankId
          ) as BankDTO;

          const bonus =
            bonuses.find((el) => el.bankId === bank.bankId)?.sum || 0;

          return (
            <BonusSummaryByCurrency
              personId={personId}
              banks={banks}
              bank={bank}
              achievement={achievement}
              key={achievement.bonusProgram.bankId}
              bonusSum={bonus}
            />
          );
        })
      )}
    </BonusInfoContainer>
  );

  // return (
  //   <BonusInfoContainer>
  //     {bonuses.length === 0 ? (
  //       <BonusSummaryByCurrency personId={personId} banks={banks} />
  //     ) : (
  //       bonuses.map((bonus) => {
  //         const achievement = achievements.find(
  //           (el) => el.bonusProgram.bankId === bonus.bankId
  //         );

  //         const bank = banks.find(
  //           (el) => el.bankId === bonus.bankId
  //         ) as BankDTO;

  //         return (
  //           <BonusSummaryByCurrency
  //             personId={personId}
  //             banks={banks}
  //             bonus={bonus}
  //             bank={bank}
  //             achievement={achievement}
  //             key={bonus.bankId}
  //           />
  //         );
  //       })
  //     )}
  //   </BonusInfoContainer>
  // );
}
