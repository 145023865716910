import { toCurrencySubunit } from 'shared/lib';

import { TariffFormData } from '../model';
import { getTariffType } from './get-tariff-type';
import { getTriggerFee } from './get-trigger-fee';
import { getTriggerType } from './get-trigger-type';
import { getTriggerValue } from './get-trigger-value';

export const prepareRequestData = (values: TariffFormData) => {
  const {
    id,
    name,
    editProperties,
    bankId,
    startFee,
    DCPerKilowatt,
    costOfQuickCharging,
    costOfReserve,
    // costOfSlowCharging,
    parkFee,
    parkTimeAfter,
    isArchive,
  } = values;

  const triggerType = getTriggerType(values);

  const triggerValue = getTriggerValue(values, triggerType);

  const triggerFee = getTriggerFee(values, triggerType);

  return {
    id: Number(id),
    name,
    editProperties,
    bankId: Number(bankId),
    startFee: toCurrencySubunit(startFee),
    tariffType: getTariffType(DCPerKilowatt),
    costOfQuickCharging: toCurrencySubunit(costOfQuickCharging),
    // costOfSlowCharging: toCurrencySubunit(costOfSlowCharging),
    triggerType,
    triggerValue: Number(triggerValue),
    triggerFee: toCurrencySubunit(triggerFee),
    parkFee: toCurrencySubunit(parkFee),
    parkTimeAfter: Number(parkTimeAfter),
    isArchive,
    costOfReserve: toCurrencySubunit(costOfReserve),
  };
};
