import { PLATFORM_SERVICE } from 'entities/authorization';

import { getEndpointPath } from 'shared/lib';

import {
  GetRoleGroupChargePointRightsResponse,
  GetUserGroupChargePointRightsResponse,
  RoleChargePointRightsResponse,
  SetRoleCpRightsRequest,
  SetRoleGroupCpRightsRequest,
  SetUserCpRightsRequest,
  SetUserGroupCpRightsRequest,
  UserChargePointRightsResponse,
} from './types';

import { apiSlice } from '../../../redux/services/apiSlice';

const KEY = 'ChargePointRights';

const getUrl = getEndpointPath(PLATFORM_SERVICE, KEY);

export const api = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserChargePointRights: builder.query<
      UserChargePointRightsResponse,
      string
    >({
      query: (userName) => ({
        url: getUrl('GetUserChargePointRights'),
        params: { userName },
      }),
      providesTags: (result, error, arg) => [
        { type: 'UserChargePointRights', id: arg },
      ],
    }),
    setUserChargePointRights: builder.mutation<
      ResponseBase,
      SetUserCpRightsRequest
    >({
      query: (rights) => ({
        url: getUrl(`SetUserChargePointRights`),
        method: 'POST',
        body: rights,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'UserChargePointRights', id: arg.userName },
      ],
    }),
    deleteAllUserChargePointRights: builder.mutation<ResponseBase, string>({
      query: (userName) => ({
        url: getUrl(`DeleteAllUserChargePointRights`),
        params: { userName },
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'UserChargePointRights', id: arg },
      ],
    }),
    getRoleChargePointRights: builder.query<
      RoleChargePointRightsResponse,
      string
    >({
      query: (roleName) => ({
        url: getUrl('GetRoleChargePointRights'),
        params: { roleName },
      }),
      providesTags: (result, error, arg) => [
        { type: 'RoleChargePointRights', id: arg },
      ],
    }),
    setRoleChargePointRights: builder.mutation<
      ResponseBase,
      SetRoleCpRightsRequest
    >({
      query: (rights) => ({
        url: getUrl(`SetRoleChargePointRights`),
        method: 'POST',
        body: rights,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'RoleChargePointRights', id: arg.roleName },
      ],
    }),
    deleteAllRoleChargePointRights: builder.mutation<ResponseBase, string>({
      query: (roleName) => ({
        url: getUrl(`DeleteAllRoleChargePointRights`),
        params: { roleName },
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'RoleChargePointRights', id: arg },
      ],
    }),
    getUserGroupChargePointRights: builder.query<
      GetUserGroupChargePointRightsResponse,
      string
    >({
      query: (userName) => ({
        url: getUrl(`GetUserGroupChargePointRights`),
        params: { userName },
      }),
      providesTags: (result, error, arg) => [
        { type: 'UserGroupChargePointRights', id: arg },
      ],
    }),
    setUserGroupChargePointRights: builder.mutation<
      ResponseBase,
      SetUserGroupCpRightsRequest
    >({
      query: (rights) => ({
        url: getUrl(`SetUserGroupChargePointRights`),
        method: 'POST',
        body: rights,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'UserGroupChargePointRights', id: arg.userName },
      ],
    }),
    deleteAllUserGroupChargePointRights: builder.mutation<ResponseBase, string>(
      {
        query: (userName) => ({
          url: getUrl(`DeleteAllUserGroupChargePointRights`),
          params: { userName },
          method: 'DELETE',
        }),
        invalidatesTags: (result, error, arg) => [
          { type: 'UserGroupChargePointRights', id: arg },
        ],
      }
    ),
    getRoleGroupChargePointRights: builder.query<
      GetRoleGroupChargePointRightsResponse,
      string
    >({
      query: (roleName) => ({
        url: getUrl(`GetRoleGroupChargePointRights`),
        params: { roleName },
      }),
      providesTags: (result, error, arg) => [
        { type: 'RoleGroupChargePointRights', id: arg },
      ],
    }),
    setRoleGroupChargePointRights: builder.mutation<
      ResponseBase,
      SetRoleGroupCpRightsRequest
    >({
      query: (rights) => ({
        url: getUrl(`SetRoleGroupChargePointRights`),
        method: 'POST',
        body: rights,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'RoleGroupChargePointRights', id: arg.roleName },
      ],
    }),
    deleteAllRoleGroupChargePointRights: builder.mutation<ResponseBase, string>(
      {
        query: (roleName) => ({
          url: getUrl(`DeleteAllRoleGroupChargePointRights`),
          params: { roleName },
          method: 'DELETE',
        }),
        invalidatesTags: (result, error, arg) => [
          { type: 'RoleGroupChargePointRights', id: arg },
        ],
      }
    ),
  }),
});

export const {
  useGetUserChargePointRightsQuery,
  useSetUserChargePointRightsMutation,
  useDeleteAllUserChargePointRightsMutation,
  useGetUserGroupChargePointRightsQuery,
  useSetUserGroupChargePointRightsMutation,
  useGetRoleChargePointRightsQuery,
  useSetRoleChargePointRightsMutation,
  useGetRoleGroupChargePointRightsQuery,
  useSetRoleGroupChargePointRightsMutation,
} = api;
