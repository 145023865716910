import { createColumnHelper } from '@tanstack/react-table';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';

import { FORMAT_TO_SECONDS, showTimeString } from 'shared/lib';
import { useTableSettings } from 'shared/ui/table/visible-columns/use-table-settings';
import { TColumnDef } from 'shared/ui/table/types';

import { ClientBindingsTableDataItem } from 'entities/person';
import { BankDTO } from 'entities/bank';

import { selectClientBindings } from '../../../../redux/slices/tables/tables-slice';
import { setVisibleColumns, TableColumns, TableColumnsState } from '../store';

import { TableActions } from '../ui/actions';

const columnHelper = createColumnHelper<ClientBindingsTableDataItem>();

type Props = {
  banks: BankDTO[];
};

export const useColumns = ({ banks }: Props) => {
  const dispatch = useDispatch();

  const settings = useSelector(selectClientBindings);

  const DATA_COLUMNS: TColumnDef<ClientBindingsTableDataItem, TableColumns>[] =
    [
      {
        accessorKey: 'isMain',
        id: 'isMain',
        header: 'Основная',
        cell: (props) => {
          const value = props.row.original.isMain;

          if (value) {
            return 'Да';
          }

          return '';
        },
      },
      {
        accessorKey: 'gateId',
        id: 'gateId',
        header: 'Банк',
        cell: (props) => {
          const value = props.row.original.gateId;

          const bankName =
            banks.find((elem) => elem.bankId === value)?.name ?? '';

          return bankName;
        },
      },
      {
        accessorKey: 'expiryDate',
        id: 'expiryDate',
        header: 'Дата окончания действия',
        cell: (props) => {
          const value = props.row.original.expiryDate;

          if (!value) {
            return '';
          }

          const year = value.slice(0, 4);
          const month = value.slice(4);

          return `${year}/${month}`;
        },
      },
      {
        accessorKey: 'maskedPan',
        id: 'maskedPan',
        header: 'Номер карты',
      },
      {
        accessorKey: 'bindingId',
        id: 'bindingId',
        header: 'Идентификатор',
      },
      {
        accessorKey: 'eventQueueDateTime',
        id: 'eventQueueDateTime',
        header: 'Дата привязки',
        cell: (props) => {
          const date = props.row.original.eventQueueDateTime || '';

          return date ? showTimeString(date, FORMAT_TO_SECONDS) : '';
        },
      },
    ];

  const settingsColumn = useTableSettings({
    columnHelper,
    columns: DATA_COLUMNS.map(({ id, header }) => {
      return {
        key: id,
        label: header as string,
        isChecked: settings[id],
      };
    }),
    settings,
    renderCell: (props) => {
      const binding = props.row.original;

      return <TableActions binding={binding} />;
    },
    setVisibleColumns: (cols: TableColumnsState) => {
      dispatch(setVisibleColumns(cols));
    },
  });

  const visibleColumns = useMemo(() => {
    const dataCols = settings
      ? DATA_COLUMNS.filter((el) => settings[el.id])
      : DATA_COLUMNS;

    return [...dataCols, settingsColumn];
  }, [settings]);

  return visibleColumns;
};
