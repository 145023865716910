export function CountriesIcon() {
  return (
    <svg viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.33398 1.19256C5.38891 1.41028 3.02145 3.02288 1.83622 3.91906C1.35 4.2867 1.08398 4.86661 1.08398 5.47618V15.5485C1.08398 16.4375 2.15471 16.953 2.8926 16.4572C4.15248 15.6105 5.86387 14.6491 7.33398 14.4846C10.7906 14.0977 12.2107 18.1945 15.6673 17.8076C17.6124 17.5899 19.9798 15.9773 21.1651 15.0811C21.6513 14.7135 21.9173 14.1335 21.9173 13.524V3.45167C21.9173 2.56266 20.8466 2.04714 20.1087 2.54299C18.8488 3.38963 17.1374 4.35101 15.6673 4.51557C12.2107 4.90247 10.7906 0.805655 7.33398 1.19256Z"
        stroke="black"
        strokeWidth="1.5"
      />
    </svg>
  );
}
