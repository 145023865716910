import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import { getEndpointPath } from 'shared/lib';

import { PLATFORM_SERVICE } from 'entities/authorization';

import { apiSlice } from '../../../redux/services/apiSlice';
import { GetPersonBaseResponse, PersonsBaseResponse } from '../model/api';

interface GetPersonsParams {
  groupId?: string;
  ordering?: string;
  type?: string;
  limit: number;
  page: number;
  phone?: string;
  search?: string;
  searchText?: string;
}

const KEY = 'Person';

const getUrl = getEndpointPath(PLATFORM_SERVICE, KEY);

export const api = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPersons: builder.query<PersonsBaseResponse, GetPersonsParams>({
      query: (params) => ({
        url: getUrl(`GetPersons`),
        params,
      }),
      providesTags: (result, error, arg) => [{ type: 'Persons' }],
    }),
    getPerson: builder.query<GetPersonBaseResponse, string>({
      query: (personLogin) => ({
        url: getUrl(`GetPerson`),
        params: { personLogin },
      }),
      providesTags: (result, error, arg) => [{ type: 'Person', id: arg }],
    }),
  }),
});

export const { useGetPersonsQuery, useGetPersonQuery, useLazyGetPersonsQuery } =
  api;
