import { getEndpointPath } from 'shared/lib';

import { PLATFORM_SERVICE } from 'entities/authorization';

import {
  GetChargedSummaryRequest,
  GraphMetricsResponse,
  MetricsResponse,
} from './types';

import { GraphMetricType, MetricName } from '../model/metrics';

import { apiSlice } from '../../../redux/services/apiSlice';

import { padMetricsData } from '../lib/pad-metrics-data';
import { transformMetricsData } from '../lib/transform-metrics-data';

const KEY = 'Metrics';

const getUrl = getEndpointPath(PLATFORM_SERVICE, KEY);

interface MetricsParams {
  names: Array<MetricName>;
  ownerIds?: Array<string>;
  ownerTypes?: Array<string>; // Будет добавлено на бэке
  powerImportMetricParams?: string[];
}

interface GraphMetricsParams {
  dateFrom: string;
  dateTo: string;
  type: GraphMetricType;
  chargePointName?: string;
  // period: GraphMetricPeriod; // пока не используются
}

export const metricsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMetrics: builder.query<MetricsResponse, MetricsParams>({
      query: ({ names, ownerIds, powerImportMetricParams, ownerTypes }) =>
        getUrl(
          `GetMetrics?names=${names.join(',')}&${
            ownerIds?.length ? `ownerIds=${ownerIds.join(',')}` : ''
          }&${ownerTypes?.length ? `ownerTypes=${ownerTypes.join(',')}` : ''}`
        ),
    }),
    getChargedSummary: builder.mutation<
      MetricsResponse,
      GetChargedSummaryRequest
    >({
      query: ({ dateFrom, dateTo, cpName }) => {
        const requiredParams = [
          {
            name: 'dateFrom',
            value: dateFrom,
          },
          {
            name: 'dateTo',
            value: dateTo,
          },
        ];

        const params =
          cpName === ''
            ? requiredParams
            : [...requiredParams, { name: 'cpName', value: cpName }];

        return {
          url: getUrl('GetMetrics'),
          method: 'POST',
          body: {
            metrics: [
              {
                name: 'powerImport',
                params,
              },
            ],
          },
        };
      },
    }),
    getGraphMetrics: builder.query<GraphMetricsResponse, GraphMetricsParams>({
      query: (params) => ({
        url: getUrl('GetGraphMetrics'),
        params,
      }),
      providesTags: ['GraphMetrics'],
      transformResponse: (response: GraphMetricsResponse, meta, arg) => {
        const rawData = response.graphMetrics;

        const { dateFrom, dateTo, type } = arg;

        const convertedMetrics = transformMetricsData(type, rawData);

        const paddedMetrics = padMetricsData(
          dateFrom,
          dateTo,
          convertedMetrics
        );

        return {
          ...response,
          graphMetrics: paddedMetrics,
        };
      },
    }),
  }),
});

export const {
  useGetChargedSummaryMutation,
  useGetGraphMetricsQuery,
  useGetMetricsQuery,
} = metricsApi;
