import type { ManipulateType } from 'dayjs';

export const DATE_RANGE_OPTION: {
  [k: string]: {
    label: string;
    subtractParams: [number, ManipulateType];
  };
} = {
  week: {
    label: 'Н',
    subtractParams: [1, 'week'],
  },
  month: {
    label: 'М',
    subtractParams: [1, 'month'],
  },
  threeMonths: {
    label: '3М',
    subtractParams: [3, 'months'],
  },
  sixMonths: {
    label: '6М',
    subtractParams: [6, 'months'],
  },
  year: {
    label: 'Г',
    subtractParams: [1, 'year'],
  },
};
