import { useParams } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';

import { NavigationTabButton } from 'shared/ui';

import { AddBonusLevelModal } from '../modal';

export function OpenAddBonusLevelModalButton() {
  const { bonusProgramId } = useParams() as { bonusProgramId: string };

  const onClick = () => {
    NiceModal.show(AddBonusLevelModal, { bonusProgramId });
  };

  return <NavigationTabButton title="Добавить уровень" onClick={onClick} />;
}
