import { useNavigate, useParams } from 'react-router-dom';

import { ErrorMessage, EmptyData } from 'shared/ui';

import {
  getServerErrorText,
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';

import {
  useGetChargePointQuery,
  useUpdateChargePointMutation,
} from '../../../../redux/services/charge-points';

import { ChargePointFormData } from '../../types';
import { ConnectedChargePointForm } from '../../components/ConnectedChargePointForm';

import { getEditError, getEditSuccess } from '../../constants';
import { getOcppConfigFormString, getValidatedFormData } from '../../helpers';
import { ChargePointDTO } from 'entities/charge-point';
import { CHARGE_POINT_ROUTES } from 'shared/consts/routes/charge-point';

const getInitialValues = (chargePoint: ChargePointDTO): ChargePointFormData => {
  const {
    id,
    name,
    owner,
    idGroup,
    city,
    address,
    description,
    manufacturer,
    maxPower,
    type,
    status,
    multiconnectorSupport,
    timeLimit,
    chargePointProtocol,
    ipAddress,
    commentary,
    ocppConfig,
    simNumber,
  } = chargePoint;

  const {
    latitude,
    longitude,
    mapStatus,
    isTestCp,
    hub,
    passUrl,
    videoTranslationUrl,
    userLocationCheckDistance,
    workingTime,
    publicDescription,
    warningText,
  } = chargePoint;

  const { advancedProperty } = chargePoint;

  const hubId = hub?.id || '';

  const ownerId = owner?.id || '';

  return {
    id,
    techInfo: {
      name,
      ownerId: String(ownerId),
      idGroup: String(idGroup),
      city,
      address,
      description,
      manufacturer,
      maxPower: String(maxPower),
      type: String(type),
      status: String(status),
      multiconnectorSupport,
      timeLimit,
      chargePointProtocol: String(chargePointProtocol),
      ipAddress,
      commentary,
      simNumber,
    },
    cardSettings: {
      latitude: String(latitude),
      longitude: String(longitude),
      mapStatus: Boolean(mapStatus),
      isTestCp,
      hubId: String(hubId),
      passUrl,
      videoTranslationUrl,
      userLocationCheckDistance: String(userLocationCheckDistance),
      workingTime,
      publicDescription,
      warningText,
    },
    advancedProperty,
    ocppConfig: {
      ocppConfig: ocppConfig ? getOcppConfigFormString(ocppConfig) : '',
    },
  };
};

export function EditChargePoint() {
  const [trigger, { isLoading: isMutationLoading }] =
    useUpdateChargePointMutation();

  const navigate = useNavigate();

  const { chargePointName } = useParams() as { chargePointName: string };

  const { isFetching, error, data } = useGetChargePointQuery(chargePointName);

  if (isFetching) {
    return <div>Loading...</div>;
  }

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!data?.chargePoint) {
    return <EmptyData />;
  }

  const handleSubmit = async (values: ChargePointFormData) => {
    const req = getValidatedFormData(values);

    try {
      const res = await trigger(req).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);
        return;
      }
      //

      const successText = getEditSuccess(chargePointName);

      openSuccessNotification(successText);

      const idGroup = res.chargePoint.idGroup || null;

      navigate(
        `${CHARGE_POINT_ROUTES.CHARGE_POINTS}/${idGroup}/${chargePointName}`
      );
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : getEditError(chargePointName);

      openErrorNotification(errorText);
    }
  };

  const initialValues = getInitialValues(data.chargePoint);

  return (
    <ConnectedChargePointForm
      handleSubmit={handleSubmit}
      loading={isMutationLoading}
      initialValues={initialValues}
    />
  );
}
