import styled from 'styled-components';

import { COLOR } from 'shared/consts';

import {
  ChargePointConnectorStatus,
  CONNECTOR_STATUS_COLOR,
} from 'entities/connector';

export const ConnectorFormContainer = styled.div`
  display: flex;
  align-items: start;
  gap: 29px;
`;

export const ConnectorImageContainer = styled.div`
  width: 197px;
  padding: 20px;
  background-color: ${COLOR.whiteSmoke};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ConnectorImage = styled.img`
  width: 100%;
`;

export const ConnectorImageText = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR.inputGrey};
  text-align: center;
`;

export const FormContainer = styled.div`
  flex-grow: 1;
`;

export const ConnectorFormWrapper = styled.div`
  width: 570px;
`;

interface ConnectorStatusCircleProps {
  status: ChargePointConnectorStatus;
}

export const ConnectorStatusCircle = styled.div<ConnectorStatusCircleProps>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;
  background-color: ${(props) => CONNECTOR_STATUS_COLOR[props.status]};
`;
