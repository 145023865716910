import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';

import { useGetOwnersQuery } from 'entities/owner';
import { useGetOcpiHubsQuery } from 'entities/ocpi-hub';

import { CpoForm } from '../form';

type Props = Omit<React.ComponentProps<typeof CpoForm>, 'owners' | 'ocpiHubs'>;

export function ConnectedCpoForm(props: Props) {
  const {
    data: owners,
    isFetching: isOwnersLoading,
    error: ownersError,
  } = useGetOwnersQuery();

  const {
    data: ocpiHubs,
    isFetching: isOcpiHubsLoading,
    error: ocpiHubsError,
  } = useGetOcpiHubsQuery();

  if (isOwnersLoading || isOcpiHubsLoading) {
    return <div>Loading...</div>;
  }

  const apiResponseError = [owners, ocpiHubs].find(
    (el) => el && el.statusCode !== 0
  );

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusDescription} />;
  }

  const error = [ownersError, ocpiHubsError].find((err) => err !== undefined);

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!owners || !ocpiHubs) {
    return <EmptyData />;
  }

  return (
    <CpoForm {...props} owners={owners.owners} ocpiHubs={ocpiHubs.ocpiHubs} />
  );
}
