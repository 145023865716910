import React from 'react';

import {
  getDurationString,
  renderDotSeparator,
  toCurrencyUnit,
  toFixedDecimals,
} from 'shared/lib';

import { MongoSessionDTO } from 'entities/session';

import { StyledButton } from '../../../../common-styles';

import {
  TariffDetailsContainer,
  TariffDetailsGroup,
  TariffDetailsParamGropTitle,
  TariffDetailsParamGroupValue,
  TariffDetailsParamGroupData,
  TariffDetailsParamGroupDataItem,
  TariffDetailsParamGroupKey,
  TariffDetailsContent,
  TariffDetailsFooter,
  TariffDetailsSummary,
  TariffDetailsSummaryTitle,
  TariffDetailsSummaryValue,
} from './styles';

const getChargeAmountSummaryForPerMinute = (
  duration: string | null,
  servicePriceValue: string
) => {
  if (duration === null) {
    return 0;
  }

  const [hours, minutes, seconds] = duration.split(':');

  const hoursInt = Number(hours);
  const minutesInt = Number(minutes);
  const secsInt = Number(seconds);

  const mins = hoursInt > 0 ? hoursInt * 60 + minutesInt : minutesInt;

  var x = (mins + secsInt / 60) * Number(servicePriceValue);

  return toFixedDecimals((mins + secsInt / 60) * Number(servicePriceValue), 2);
};

type Props = {
  session: MongoSessionDTO;
  closeModal: () => void;
};

export function TariffDetails({ session, closeModal }: Props) {
  const { calculationResult, operation, tariff } = session;

  const {
    amount,
    startAmount,
    triggerAmount,
    parkAmount,
    consume,
    payableConsume,
    triggerDuration,
    parkingDuration,
    totalSessionDuration,
    payableConsumeAmount,
  } = calculationResult;

  const { servicePrice = 0, calculatedBonus = 0 } = operation || {};

  const {
    tariffType,
    triggerType,
    triggerValue,
    currencySymbol,
    triggerFee,
    parkFee,
  } = tariff;

  const startAmountValue =
    startAmount === null ? 0 : Number(toCurrencyUnit(startAmount));

  const consumeValue = consume / 1000;
  const payableConsumeValue = payableConsume / 1000;
  const servicePriceValue = toCurrencyUnit(servicePrice);
  const parkFeeValue = toCurrencyUnit(parkFee);
  const triggerAmountValue = toCurrencyUnit(triggerFee);
  const bonusValue = toCurrencyUnit(calculatedBonus);

  //
  // amount - не учитывает бонусы
  //

  const amountToPayValue = toCurrencyUnit(amount - (calculatedBonus ?? 0));

  const triggerDurationValue = getDurationString(triggerDuration);
  const parkingDurationValue = getDurationString(parkingDuration);
  const totalSessionDurationValue = getDurationString(totalSessionDuration);

  const chargeAmountSummary =
    tariffType === 0
      ? toCurrencyUnit(payableConsumeAmount)
      : getChargeAmountSummaryForPerMinute(
          totalSessionDuration,
          servicePriceValue
        );

  const triggerAmountSummary =
    triggerAmount === null ? 0 : Number(toCurrencyUnit(triggerAmount));
  const parkAmountSummary =
    parkAmount === null ? 0 : Number(toCurrencyUnit(parkAmount));
  const bonusSummary = toFixedDecimals(Number(bonusValue), 2);

  const renderAmountWithCurrency = (value: number | string) => {
    return `${value} ${currencySymbol}`;
  };

  const renderParamGroupDataItem = (key: string, value: React.ReactNode) => {
    return (
      <TariffDetailsParamGroupDataItem>
        <TariffDetailsParamGroupKey>{key}</TariffDetailsParamGroupKey>
        <TariffDetailsParamGroupValue>{value}</TariffDetailsParamGroupValue>
      </TariffDetailsParamGroupDataItem>
    );
  };

  const renderGroup = (
    title: string,
    dict: Record<string, React.ReactNode>
  ) => {
    return (
      <TariffDetailsGroup>
        <TariffDetailsParamGropTitle>{title}</TariffDetailsParamGropTitle>
        <TariffDetailsParamGroupData>
          {Object.entries(dict).map(([key, value]) =>
            renderParamGroupDataItem(key, value)
          )}
        </TariffDetailsParamGroupData>
      </TariffDetailsGroup>
    );
  };

  const renderStartGroup = () => {
    const title = 'старт';

    const startAmount = renderAmountWithCurrency(startAmountValue);

    const dict = {
      Старт: startAmount,
    };

    return renderGroup(title, dict);
  };

  const renderChargedGroup = () => {
    const title = 'заряд';

    const getChargingTariffValue = () => {
      if (tariffType === 0) {
        return (
          <>
            {renderAmountWithCurrency(servicePriceValue)}
            {'/кВт'}
            {renderDotSeparator()}
            {'ч'}
          </>
        );
      } else {
        return (
          <>
            {renderAmountWithCurrency(servicePriceValue)}
            {'/мин'}
          </>
        );
      }
    };

    const renderConsumeValue = (value: number) => {
      return (
        <>
          {value} {'кВт'}
          {renderDotSeparator()}
          {'ч'}
        </>
      );
    };

    const dict = {
      Заряжено: renderConsumeValue(consumeValue),
      'Заряжено (подлежит оплате)': renderConsumeValue(payableConsumeValue),
      Время: totalSessionDurationValue,
      Тариф: getChargingTariffValue(),
      Итого: renderAmountWithCurrency(chargeAmountSummary),
    };

    return renderGroup(title, dict);
  };

  const renderTriggerGroup = () => {
    const triggerText =
      triggerType === 1
        ? `от ${triggerValue} %`
        : `мощность < ${triggerValue} Вт`;

    const title = `Заряд (${triggerText})`;

    const dict = {
      Время: triggerDurationValue,
      [`Тариф (${triggerText})`]: `${renderAmountWithCurrency(
        triggerAmountValue
      )}/мин`,
      Итого: renderAmountWithCurrency(triggerAmountSummary),
    };

    return renderGroup(title, dict);
  };

  const renderAfterGroup = () => {
    const title = 'после заряда';

    const dict = {
      Простой: parkingDurationValue,
      'Тариф от 100%': `${renderAmountWithCurrency(parkFeeValue)}/мин`,
      Итого: renderAmountWithCurrency(parkAmountSummary),
    };

    return renderGroup(title, dict);
  };

  const renderBonusGroup = () => {
    const title = 'оплачено бонусами';

    const dict = {
      Итого: renderAmountWithCurrency(bonusSummary),
    };

    return renderGroup(title, dict);
  };

  return (
    <TariffDetailsContainer>
      <TariffDetailsContent>
        {renderStartGroup()}
        {renderChargedGroup()}
        {triggerType === 0 ? null : renderTriggerGroup()}
        {renderAfterGroup()}
        {renderBonusGroup()}
      </TariffDetailsContent>

      <TariffDetailsFooter>
        <TariffDetailsSummary>
          <TariffDetailsSummaryTitle>
            Сумма к списанию
          </TariffDetailsSummaryTitle>
          <TariffDetailsSummaryValue>
            {renderAmountWithCurrency(amountToPayValue)}
          </TariffDetailsSummaryValue>
        </TariffDetailsSummary>

        <StyledButton
          type="primary"
          style={{ width: '100%' }}
          onClick={closeModal}
        >
          Закрыть
        </StyledButton>
      </TariffDetailsFooter>
    </TariffDetailsContainer>
  );
}
