import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Leaves } from 'shared/lib/types';

import { UserDto } from 'entities/user';

type ObjectLeaves = Leaves<UserDto>;

export type TableColumns =
  | Extract<ObjectLeaves, 'userName' | 'email' | 'lastName' | 'firstName'>
  | 'groups'
  | 'roles';

export type TableColumnsState = Record<TableColumns, boolean>;

const initialState: TableColumnsState = {
  userName: true,
  email: true,
  lastName: true,
  firstName: true,
  groups: true,
  roles: true,
};

const slice = createSlice({
  name: 'usersTable',
  initialState,
  reducers: {
    setVisibleColumns: (state, action: PayloadAction<TableColumnsState>) => {
      return action.payload;
    },
  },
});

export const { setVisibleColumns } = slice.actions;
export default slice.reducer;
