import { MilestoneContainer } from './styles';

type Props = {
  value: string;
  gained: boolean;
  offset: number;
  isLastMilestone: boolean;
};

export function Milestone({ value, gained, offset, isLastMilestone }: Props) {
  return (
    <MilestoneContainer
      gained={gained}
      offset={offset}
      isLastMilestone={isLastMilestone}
    >
      {value}
    </MilestoneContainer>
  );
}
