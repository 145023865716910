import { useEffect, useRef } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  TimeScale,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

dayjs.locale('ru');

import type { ChartOptions } from 'chart.js';

import { CanvasContainer } from './styles';
import { LineChartDataType } from './types';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  TimeScale
);

type Props = {
  data: LineChartDataType;
  scaleOptions: ChartOptions<'line'>['scales'];
};

export function LineChart({ data, scaleOptions }: Props) {
  const chartRef = useRef<ChartJS<'line', number[], string>>(null);

  useEffect(() => {
    const chart = chartRef.current;

    if (chart && data) {
      chart.stop(); // make sure animations are not running
      chart.update('none');
    }
  }, [data]);

  const options: ChartOptions<'line'> = {
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    responsive: true,
    locale: 'ru-RU',
    scales: scaleOptions,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (tooltilItems) => {
            const formattedDate = dayjs(tooltilItems[0].raw.x).format(
              'DD-MM-YYYY'
            );

            return formattedDate;
          },
        },
      },
    },
    transitions: {
      zoom: {
        animation: {
          duration: 100,
        },
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  return (
    <CanvasContainer>
      <Line options={options} data={data} ref={chartRef} />
    </CanvasContainer>
  );
}
