import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Leaves } from 'shared/lib/types';

import { MongoSessionDTO } from 'entities/session';

type ObjectLeaves = Leaves<MongoSessionDTO>;

export type TableColumns = Extract<
  ObjectLeaves,
  | 'operation.initChargeDateTime'
  | 'chargeEndTime'
  | 'lastUpdateTime'
  | 'userBrowseName'
  | 'operation.cpName'
  | 'operation.calculatedConsume'
  | 'operation.calculatedPayment'
  | 'statusHR'
  | 'session.soc'
  | 'session.startSoc'
  | 'session.powerActiveImport'
  | 'session.maxPower'
  | 'session.reason'
  | 'session.errorCode'
  | 'operation.connectorId'
>;

export type TableColumnsState = Record<TableColumns, boolean>;

const initialState: TableColumnsState = {
  'operation.calculatedConsume': true,
  'operation.calculatedPayment': true,
  'operation.connectorId': true,
  'operation.cpName': true,
  'operation.initChargeDateTime': true,
  'session.errorCode': true,
  'session.maxPower': true,
  'session.powerActiveImport': true,
  'session.reason': true,
  'session.soc': true,
  'session.startSoc': true,
  chargeEndTime: true,
  lastUpdateTime: true,
  statusHR: true,
  userBrowseName: true,
};

const slice = createSlice({
  name: 'sessionsTable',
  initialState,
  reducers: {
    setVisibleColumns: (state, action: PayloadAction<TableColumnsState>) => {
      return action.payload;
    },
  },
});

export const { setVisibleColumns } = slice.actions;
export default slice.reducer;

// export const selectTableSettings = (state: VisibleColumnsState) => state;
