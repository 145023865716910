import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ChargePointDTO } from 'entities/charge-point';
import { ChargePointsMap } from 'entities/charge-points-map';
import { MapProps } from 'entities/charge-points-map/ui/map';

import { useTypedSelector } from '../../../../hooks/store';
import {
  selectMapCenter,
  selectMapZoom,
  setMapCenter,
  setMapZoom,
} from '../../store';

type Props = {
  chargePoints: ChargePointDTO[];
  loading: boolean;
};

export function ChargePointsLocations(props: Props) {
  const mapZoom = useTypedSelector(selectMapZoom);
  const mapCenter = useTypedSelector(selectMapCenter);

  const mapZoomRef = useRef(mapZoom);
  const mapCenterRef = useRef(mapCenter);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      dispatch(setMapZoom(mapZoomRef.current));
      dispatch(setMapCenter(mapCenterRef.current));
    };
  }, []);

  const onPinClick = (chargePoint: ChargePointDTO) => {
    const { idGroup, name } = chargePoint;

    navigate(`charge-points/${idGroup ? idGroup : null}/${name}`);
  };

  const mapProps: MapProps = {
    onBoundsChange: (e) => {
      const { newZoom, oldZoom, newCenter, oldCenter } = e.originalEvent;

      if (newZoom !== oldZoom) {
        mapZoomRef.current = newZoom;
      }

      if (newCenter[0] !== oldCenter[0] || newCenter[1] !== oldCenter[1]) {
        mapCenterRef.current = newCenter;
      }
    },
    defaultState: {
      center: mapCenter,
      zoom: mapZoom,
      controls: ['zoomControl', 'geolocationControl'],
    },
    options: {
      suppressMapOpenBlock: true,
      yandexMapDisablePoiInteractivity: true,
      autoFitToViewport: 'always',
    },
  };

  return (
    <ChargePointsMap {...props} mapProps={mapProps} onPinClick={onPinClick} />
  );
}
