import styled from 'styled-components';
import { Checkbox } from 'antd';

import { COLOR } from 'shared/consts';

export const TariffFormContainer = styled.div`
  max-width: 873px; // TODO
  min-width: 700px;
`;

export const CheckboxWithInputLabel = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR.darkGrey};
  margin-bottom: 10px;
`;

export const CardTitleContainer = styled.div`
  display: flex;
`;

export const TariffBadge = styled.span`
  padding: 4px 10px 5px 10px;
  background: ${COLOR.whiteSmoke};
  border-radius: 4px;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  margin-left: 10px;
`;

interface LabelContainerProps {
  checked: boolean;
  disabled: boolean;
}

export const LabelContainer = styled.label<LabelContainerProps>`
  display: flex;
  align-items: flex-start;
  gap: 10px;

  & > label.label_inner {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

    background-color: ${(props) =>
      props.checked && !props.disabled
        ? COLOR.lightGreenMenu
        : COLOR.whiteSmoke};

    & > span {
      color: ${(props) =>
        props.checked && !props.disabled
          ? COLOR.black
          : COLOR.darkGrey} !important;
    }

    & label.ant-checkbox-wrapper span:not(.ant-checkbox) {
      color: ${(props) =>
        props.checked && !props.disabled
          ? COLOR.black
          : COLOR.darkGrey} !important;
    }
  }

  & > div.div_container {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

    & > label.label_inner {
      background-color: ${(props) =>
        props.checked && !props.disabled
          ? COLOR.lightGreenMenu
          : COLOR.whiteSmoke};
      cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

      & label.ant-checkbox-wrapper span:not(.ant-checkbox) {
        color: ${(props) =>
          props.checked && !props.disabled
            ? COLOR.black
            : COLOR.darkGrey} !important;
      }
    }

    & > div {
      background-color: ${(props) =>
        props.checked && !props.disabled
          ? COLOR.lightGreenMenu
          : COLOR.whiteSmoke};

      & > span {
        color: ${(props) =>
          props.checked && !props.disabled
            ? COLOR.black
            : COLOR.darkGrey} !important;
      }
    }
  }
`;

export const LabelInner = styled.label.attrs((props) => ({
  className: 'label_inner',
}))`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
`;

export const InputLabel = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR.darkGrey};
`;

export const TariffCheckbox = styled(Checkbox)`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  background-color: transparent;

  color: ${COLOR.darkGrey};

  &.ant-checkbox-wrapper-checked {
    color: ${COLOR.black};
  }

  & .ant-checkbox {
    width: 15px;
    height: 15px;
  }

  & > span:not(.ant-checkbox) {
    padding: 0;
    margin-left: 5px;

    font-size: 12px;
    line-height: 15px;
  }

  & .ant-checkbox-inner {
    width: 15px;
    height: 15px;
  }

  & .ant-checkbox-checked .ant-checkbox-inner {
    border: 2px solid ${COLOR.white};
  }

  & .ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner {
    border: 2px solid ${COLOR.white} !important;
    background-color: ${COLOR.inputGrey};
  }

  & .ant-checkbox-checked .ant-checkbox-inner::after {
    display: none;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
`;

export const DivContainer = styled.div.attrs((props) => ({
  className: 'div_container',
}))``;
