import styled from 'styled-components';

import { COLOR } from 'shared/consts';

export const CarBrandLogoTitle = styled.p`
  margin-bottom: 10px;
`;

export const PhotoItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-radius: 5px;
  border: 2px solid ${COLOR.grey};

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const PhotoInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const PhotoPreview = styled.img`
  width: 60px;
  height: 60px;
`;
