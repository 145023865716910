import styled from 'styled-components';

export const StyledButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;

  & button {
    width: 169px;
  }
`;

export const FormContentWrapper = styled.div`
  margin-bottom: 15px;
`;
