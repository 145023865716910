import { EmptyData, NavMenuError } from 'shared/ui';
import { SectionMenu } from 'shared/ui/section-menu';
import { FIRMWARE_ROUTES } from 'shared/consts/routes/firmware';

import { SECTION } from 'entities/navigation';
import { useGetFirmwaresQuery } from 'entities/firmware';

import { AddButton } from './add-button';

const { label, path } = SECTION['firmwares'];

export function ConnectedFirmwaresMenu() {
  const { data, isFetching, error } = useGetFirmwaresQuery();

  if (data && data.statusCode !== 0) {
    return <NavMenuError text={data.statusDescription} />;
  }

  if (error) {
    return <NavMenuError />;
  }

  if (!isFetching && !data) {
    return <EmptyData />;
  }
  const items = data?.firmwares || [];

  return (
    <SectionMenu
      loading={isFetching}
      items={items}
      menuKey={path}
      absolutePath={FIRMWARE_ROUTES.FIRMWARES}
      label={label}
      addButton={<AddButton />}
    />
  );
}
