import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Leaves } from 'shared/lib/types';

import { Transaction } from 'entities/balance/model/api';

type ObjectLeaves = Leaves<Transaction>;

export type TableColumns = Extract<
  ObjectLeaves,
  'lastUpdated' | 'bonusProgram.name' | 'bonusSum' | 'ezsId' | 'description'
>;

export type TableColumnsState = Record<TableColumns, boolean>;

const initialState: TableColumnsState = {
  bonusSum: true,
  description: true,
  ezsId: true,
  lastUpdated: true,
  'bonusProgram.name': true,
};

const slice = createSlice({
  name: 'personBonusTransactionsTable',
  initialState,
  reducers: {
    setVisibleColumns: (state, action: PayloadAction<TableColumnsState>) => {
      return action.payload;
    },
  },
});

export const { setVisibleColumns } = slice.actions;
export default slice.reducer;
