import { BONUS_SERVICE } from 'entities/authorization';
import { baseApi } from 'shared/api';

import { getEndpointPath } from 'shared/lib';

import { apiSlice } from '../../../redux/services/apiSlice';
import {
  AccrualManualRequest,
  BalanceTransactionResponse,
  GetBalancyByCurrencyArgs,
  GetPersonBalanceResponseDto,
  GetTransactionsArgs,
  PayManualRequest,
} from '../model/api';

export const getTransactionsParams = (params: GetTransactionsArgs) => {
  const { personId, bankId, ...rest } = params;

  const queryParams = { personId, bankId };

  Object.keys(rest).forEach((param) => {
    if (rest[param] === null) {
      return;
    }

    queryParams[param] = rest[param];
  });

  return queryParams;
};

const KEY = 'Balance';

const getUrl = getEndpointPath(BONUS_SERVICE, KEY);

// TODO: замена на baseApi

export const balanceApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAll: builder.query<GetPersonBalanceResponseDto, string>({
      query: (personId) => ({
        url: getUrl(`GetAll`),
        params: { personId },
      }),
      providesTags: (result, error, arg) => [{ type: 'BonusBalance', id: arg }],
    }),
    getBalanceByCurrency: builder.query<number, GetBalancyByCurrencyArgs>({
      query: ({ bankId, personId }) => ({
        url: getUrl(`Get`),
        params: { bankId, personId },
      }),
      providesTags: (result, error, arg) => [
        { type: 'BonusBalance', id: arg.bankId },
      ],
    }),
    payManual: builder.mutation<void, PayManualRequest>({
      query: (body) => ({
        url: getUrl('PayManual'),
        method: 'POST',
        body: body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'BonusBalance', id: arg.personId },
        { type: 'Transactions', id: arg.personId },
      ],
    }),
    accrualManual: builder.mutation<void, AccrualManualRequest>({
      query: (body) => ({
        url: getUrl('AccrualManual'),
        method: 'POST',
        body: body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'BonusBalance', id: arg.personId },
        { type: 'Transactions', id: arg.personId },
      ],
    }),
    getTransactions: builder.query<
      BalanceTransactionResponse,
      GetTransactionsArgs
    >({
      query: (args) => ({
        url: getUrl(`GetTransactions`),
        params: getTransactionsParams(args),
      }),
      providesTags: (result, error, arg) => [
        { type: 'Transactions', id: arg.personId },
      ],
    }),
  }),
});

export const {
  useGetAllQuery,
  useAccrualManualMutation,
  usePayManualMutation,
  useGetTransactionsQuery,
  useGetBalanceByCurrencyQuery,
} = balanceApi;
