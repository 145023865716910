import { useSelector } from 'react-redux';

import {
  getServerErrorText,
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';

import { ErrorMessage, EmptyData } from 'shared/ui';

import { selectTokenName } from 'entities/authorization';
import { useGetUserQuery, useUpdateUserMutation } from 'entities/user';

import { UPDATE_ERROR, UPDATE_SUCCESS } from '../../consts';

import { ProfileForm } from '../form';
import { ProfileFormData } from '../../model';
import { getInitialValues } from '../../lib';

type Props = {
  closeModal: () => void;
};

export function ConnectedProfileForm({ closeModal }: Props) {
  const userName = useSelector(selectTokenName);

  const { data, isFetching, error } = useGetUserQuery(userName);

  const [trigger, { isLoading: isUpdateLoading }] = useUpdateUserMutation();

  if (isFetching) {
    return <div>Loading...</div>;
  }

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!data) {
    return <EmptyData />;
  }

  const handleSubmit = async (values: ProfileFormData) => {
    const { newPassword, ...rest } = values;

    const req = {
      ...rest,
      userName,
      id: data.user.id,
      ...(newPassword !== '' ? { newPassword } : {}),
    };

    try {
      await trigger(req).unwrap();

      openSuccessNotification(UPDATE_SUCCESS);

      closeModal();
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : UPDATE_ERROR;

      openErrorNotification(errorText);
    }
  };

  const initialValues = getInitialValues(data.user);

  return (
    <ProfileForm
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      loading={isUpdateLoading}
    />
  );
}
