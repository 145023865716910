import { useNavigate } from 'react-router-dom';

import {
  openErrorNotification,
  openSuccessNotification,
  isErrorWithMessage,
} from 'shared/lib';

import {
  BankFormData,
  ConnectedBankForm,
  useAddBankMutation,
} from 'entities/bank';

import { ADD_SUCCESS, ADD_ERROR } from '../consts';
import { BANK_ROUTES } from 'shared/consts/routes/bank';

const initialValues: BankFormData = {
  id: '',
  name: '',
  bankId: '',
  countryId: '',
  currencyId: '',
};

export function AddBank() {
  const [trigger, { isLoading }] = useAddBankMutation();

  const navigate = useNavigate();

  const handleSubmit = async (values: BankFormData) => {
    const { id, ...rest } = values;

    try {
      const res = await trigger(rest).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);

        return;
      }
      //

      openSuccessNotification(ADD_SUCCESS);

      navigate(`${BANK_ROUTES.BANKS}/${res.bank.id}`);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : ADD_ERROR;

      openErrorNotification(errorText);
    }
  };

  return (
    <ConnectedBankForm
      title="Добавление нового банка"
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      loading={isLoading}
    />
  );
}
