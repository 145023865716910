import { AuthState } from '../models/store';

export const initialState: AuthState = {
  accessToken: '',
  refreshToken: '',
  // firstName: '',
  // lastName: '',
  // middleName: '',
  // email: '',
};
