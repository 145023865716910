import { Route } from 'react-router-dom';

import { MainContentLayout, SectionLayout } from 'shared/ui/layout';
import { DISCOUNT, DISCOUNTS } from 'shared/consts/routes/discount';
import { ADD_PREFIX } from 'shared/consts/routes/common';

import { RequireAuth } from 'entities/authorization';

import { AddDiscount } from 'features/discount/add';
import { EditDiscount } from 'features/discount/edit';
import { ConnectedDiscountsMenu } from 'features/discount/menu';

import { DiscountsLayout } from 'entities/discount/discounts-layout';
import { ConnectedDiscountsTable } from '../../../features/discount/table/ui/connected-table';

export const DiscountRoutes = (
  <Route element={<RequireAuth controllerName="Discount" rightType="Read" />}>
    <Route
      path={DISCOUNTS}
      element={<SectionLayout menu={<ConnectedDiscountsMenu />} />}
    >
      <Route element={<DiscountsLayout />}>
        <Route index element={<ConnectedDiscountsTable />} />
      </Route>

      <Route
        element={<RequireAuth controllerName="Discount" rightType="Write" />}
      >
        <Route
          path={ADD_PREFIX}
          element={<MainContentLayout element={<AddDiscount />} />}
        />
      </Route>
      <Route
        path={DISCOUNT}
        element={<MainContentLayout element={<EditDiscount />} />}
      />
    </Route>
  </Route>
);
