import { SUPERUSER_ROLE } from '../consts';
import { Controller, Right } from '../model/types';
import { selectTokenClaims } from './selectTokenClaims';

export const checkControllerRight = (
  controller: Controller,
  right: Right,
  roles: string[],
  claims: ReturnType<typeof selectTokenClaims>
): boolean => {
  if (roles && roles.includes(SUPERUSER_ROLE)) {
    return true;
  }

  const claimKeysContainRight = Object.keys(claims).filter((key) =>
    key.includes(right)
  );

  return claimKeysContainRight.some((el) => {
    const claim = claims[el];

    return claim && claim.includes(controller);
  });
};
