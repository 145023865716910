import { PaginationButton } from './pagination-button';

import { PaginationContrplsContainer } from './styles';

type Props = {
  prevDisabled: boolean;
  nextDisabled: boolean;
  onPrevClick: () => void;
  onNextClick: () => void;
};

export function PaginationControls({
  prevDisabled,
  nextDisabled,
  onPrevClick,
  onNextClick,
}: Props) {
  return (
    <PaginationContrplsContainer>
      <PaginationButton
        type="prev"
        onClick={onPrevClick}
        disabled={prevDisabled}
      />
      <PaginationButton
        type="next"
        onClick={onNextClick}
        disabled={nextDisabled}
      />
    </PaginationContrplsContainer>
  );
}
