import { useParams } from 'react-router-dom';

import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
  getServerErrorText,
} from 'shared/lib';

import { EmptyData, ErrorMessage } from 'shared/ui';

import {
  Group,
  GroupType,
  useGetGroupQuery,
  useCustomUpdateUsersGroupMutation,
} from 'entities/group';

import { EDIT_SUCCESS, EDIT_ERROR } from '../consts';
import {
  ConnectedPersonsGroupForm,
  PersonsGroupFormData,
} from 'entities/persons-group';

const getInitialValues = (group: Group): PersonsGroupFormData => {
  const { parentId, name, discount } = group;

  return {
    parentId: parentId ? String(parentId) : '',
    name,
    comment: '',
    discountId: discount ? String(discount.id) : '',
  };
};

export function EditPersonsGroup() {
  const [trigger, { isLoading }] = useCustomUpdateUsersGroupMutation();

  const { groupId } = useParams() as { groupId: string };

  const { data, isFetching, error } = useGetGroupQuery(groupId);

  if (isFetching) {
    return <div>Loading...</div>;
  }

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!data?.group) {
    return <EmptyData />;
  }

  const handleSubmit = async (values: PersonsGroupFormData) => {
    const id = String(data.group.id);
    const oldParentId = String(data.group.parentId ? data.group.parentId : '');

    const req = {
      ...values,
      id: Number(id),
      oldParentId,
      groupType: 1 as GroupType,
    };

    try {
      const res = await trigger(req).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);
        return;
      }
      //

      openSuccessNotification(EDIT_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : EDIT_ERROR;

      openErrorNotification(errorText);
    }
  };

  const initialValues = getInitialValues(data.group);

  return (
    <ConnectedPersonsGroupForm
      title={data.group.name}
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      loading={isLoading}
    />
  );
}
