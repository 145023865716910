import { CardBody, CardTitle, DeviceErrorText, StyledCard } from './styles';

type Props = {
  deviceErrors: string;
};

export function DeviceErrorsCard({ deviceErrors }: Props) {
  return (
    <StyledCard>
      <CardTitle>Ошибки</CardTitle>
      <CardBody>
        <DeviceErrorText>{deviceErrors}</DeviceErrorText>
      </CardBody>
    </StyledCard>
  );
}
