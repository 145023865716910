import { getServerErrorText } from 'shared/lib';
import { EmptyData, ErrorMessage } from 'shared/ui';

import { useGetBankQuery } from 'entities/bank';

import { ReserveDetails } from '../reserve-details';

type Props = Omit<React.ComponentProps<typeof ReserveDetails>, 'bank'>;

export function ConnectedReserveDetails(props: Props) {
  const { data, isFetching, error } = useGetBankQuery(
    String(props.session.gateId)
  );

  if (isFetching) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  if (!data) {
    return <EmptyData />;
  }

  return <ReserveDetails {...props} bank={data.bank} />;
}
