import { Link } from 'react-router-dom';

import { NavigationTabButton } from 'shared/ui';

import AddPersonIcon from './add-person.svg';
import { PERSON_ROUTES } from 'shared/consts/routes/person';

export function AddPersonButtonLink() {
  const path = PERSON_ROUTES.ADD_PERSON;

  return (
    <Link to={path}>
      <NavigationTabButton title="Добавить клиента" icon={AddPersonIcon} />
    </Link>
  );
}

export function AddPersonsGroupButtonLink() {
  const path = PERSON_ROUTES.ADD_PERSONS_GROUP;

  return (
    <Link to={path}>
      <NavigationTabButton title="Добавить группу" />
    </Link>
  );
}
