import { Outlet } from 'react-router-dom';

import { MainWrapper, MenuContainer, MenuFiller, MenuWrapper } from './styles';

type Props = {
  menu: JSX.Element;
};

export function SectionLayout({ menu }: Props) {
  return (
    <>
      <MenuWrapper>
        <MenuContainer>{menu}</MenuContainer>
        <MenuFiller />
      </MenuWrapper>
      <MainWrapper>
        <Outlet />
      </MainWrapper>
    </>
  );
}
