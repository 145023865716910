export function HubsIcon() {
  return (
    <svg viewBox="0 0 34 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.91602 18.8751L8.16602 12.1667V17.1251H11.0827L5.83268 23.8334V18.8751H2.91602Z"
        stroke="black"
        strokeLinejoin="round"
      />
      <path
        d="M14.209 14.0624L23.584 2.08325V10.9374H28.7923L19.4173 22.9166V14.0624H14.209Z"
        stroke="black"
        strokeLinejoin="round"
      />
    </svg>
  );
}
