import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';

import { useGetCountriesQuery } from 'entities/country';

import { BankForm } from '../form';

type ConnectedBankFormProps = Omit<
  React.ComponentProps<typeof BankForm>,
  'countries'
>;

export function ConnectedBankForm(props: ConnectedBankFormProps) {
  const { data, isFetching, error } = useGetCountriesQuery();

  if (isFetching) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  if (!data) {
    return <EmptyData />;
  }

  return <BankForm {...props} countries={data.countries} />;
}
