import styled from 'styled-components';
import { Button } from 'antd';

import { COLOR } from 'shared/consts';

export const StyledButton = styled(Button)`
  padding: 7px;
  border: none;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR.black};

  & img {
    width: 15px;
    height: 15px;
    margin-right: 6px;
  }
`;
