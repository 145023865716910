import { combineReducers } from 'redux';

import eventsReducer from 'features/events/table/store';
import ownersReducer from 'features/owner/table/store';
import discountsReducer from 'features/discount/table/store';
import chargePointStatusesReducer from 'features/charge-point/statuses-table/store';
import bonusProgramsReducer from 'features/bonus-program/table/store';
import sessionsReducer from 'widgets/session/sessions-table/store';
import carModelsReducer from 'features/car-model/table/store';
import usersReducer from 'features/user/table/store';
import tariffsReducer from 'features/tariff/table/store';
import usersGroupsReducer from 'features/users-group/table/store';
import clientBankCardSessionsReducer from 'features/bank-card/sessions-table/store';
import clientBindingsReducer from 'features/bank-card/bindings-table/store';
import rolesReducer from 'features/role/table/store';
import personsReducer from 'features/person/table/store';
import usersGroupUsersReducer from 'features/users-group/users-table/store';
import roleUsersReducer from 'features/role/users-table/store';
import groupsReducer from 'features/group/table/store';
import bonusProgramTransactionsReducer from 'features/bonus-program/transactions-table/store';
import manualCommandsReducer from 'features/manual-command/table/store';
import reserveSessionsReducer from 'widgets/session/reserve-sessions-table/store';
import personBonusTransactionsReducer from 'features/person/transactions-table/store';
import chargePointsReducer from 'features/charge-point/table/store';

export const tablesReducer = combineReducers({
  events: eventsReducer,
  sessions: sessionsReducer,
  owners: ownersReducer,
  discounts: discountsReducer,
  chargePointStatuses: chargePointStatusesReducer,
  bonusPrograms: bonusProgramsReducer,
  carModels: carModelsReducer,
  users: usersReducer,
  tariffs: tariffsReducer,
  usersGroups: usersGroupsReducer,
  clientBankCardSessions: clientBankCardSessionsReducer,
  clientBindings: clientBindingsReducer,
  roles: rolesReducer,
  persons: personsReducer,
  usersGroupUsers: usersGroupUsersReducer,
  roleUsers: roleUsersReducer,
  groups: groupsReducer,
  bonusProgramTransactions: bonusProgramTransactionsReducer,
  manualCommands: manualCommandsReducer,
  reserveSessions: reserveSessionsReducer,
  personBonusTransactions: personBonusTransactionsReducer,
  chargePoints: chargePointsReducer,
});

export const selectEventsTable = (state: RootState) => state.tables.events;
export const selectSessionsTable = (state: RootState) => state.tables.sessions;
export const selectOwnersTable = (state: RootState) => state.tables.owners;
export const selectDiscountsTable = (state: RootState) =>
  state.tables.discounts;
export const selectChargePointStatusesTable = (state: RootState) =>
  state.tables.chargePointStatuses;
export const selectBonusProgramsTable = (state: RootState) =>
  state.tables.bonusPrograms;
export const selectCarModelsTable = (state: RootState) =>
  state.tables.carModels;
export const selectUsersTable = (state: RootState) => state.tables.users;
export const selectTariffsTable = (state: RootState) => state.tables.tariffs;
export const selectUsersGroupsTable = (state: RootState) =>
  state.tables.usersGroups;
export const selectClientBankCardSessions = (state: RootState) =>
  state.tables.clientBankCardSessions;
export const selectClientBindings = (state: RootState) =>
  state.tables.clientBindings;
export const selectRolesTable = (state: RootState) => state.tables.roles;
export const selectPersonsTable = (state: RootState) => state.tables.persons;
export const selectUsersGroupUsersTable = (state: RootState) =>
  state.tables.usersGroupUsers;
export const selectRoleUsersTable = (state: RootState) =>
  state.tables.roleUsers;
export const selectGroupsTable = (state: RootState) => state.tables.groups;
export const selectBonusProgramTransactionsTable = (state: RootState) =>
  state.tables.bonusProgramTransactions;
export const selectManualCommandsTable = (state: RootState) =>
  state.tables.manualCommands;
export const selectReserveSessionsTable = (state: RootState) =>
  state.tables.reserveSessions;
export const selectPersonBonusTransactionsTable = (state: RootState) =>
  state.tables.personBonusTransactions;
export const selectChargePointsTable = (state: RootState) =>
  state.tables.chargePoints;
