import { Route } from 'react-router-dom';

import { OWNER, OWNERS } from 'shared/consts/routes/owner';
import { ADD_PREFIX } from 'shared/consts/routes/common';

import { MainContentLayout, SectionLayout } from 'shared/ui/layout';

import { RequireAuth } from 'entities/authorization';
import { OwnersLayout } from 'entities/owner/owners-layout';

import { AddOwner } from 'features/owner/add';
import { EditOwner } from 'features/owner/edit';
import { ConnectedOwnersTable } from 'features/owner/table';
import { ConnectedOwnersMenu } from 'features/owner/menu';
import { OwnerLayout } from 'entities/owner/owner-layout';
import { CHARGE_POINTS } from 'shared/consts/routes/charge-point';
import { OwnerChargePointsListPage } from 'pages/owner';

export const OwnerRoutes = (
  <Route element={<RequireAuth controllerName="Owner" rightType="Read" />}>
    <Route
      path={OWNERS}
      element={<SectionLayout menu={<ConnectedOwnersMenu />} />}
    >
      <Route element={<OwnersLayout />}>
        <Route index element={<ConnectedOwnersTable />} />
      </Route>
      <Route element={<RequireAuth controllerName="Owner" rightType="Write" />}>
        <Route
          path={ADD_PREFIX}
          element={<MainContentLayout element={<AddOwner />} />}
        />
      </Route>
      <Route path={OWNER} element={<OwnerLayout />}>
        <Route index element={<EditOwner />} />
        <Route path={CHARGE_POINTS} element={<OwnerChargePointsListPage />} />
      </Route>
    </Route>
  </Route>
);
