import { COLOR } from 'shared/consts';

import { UiCheckbox } from '../ui-checkbox';

const style: React.CSSProperties = {
  backgroundColor: COLOR.whiteSmoke,
  padding: '7px',
};

type Props = React.ComponentProps<typeof UiCheckbox>;

export function UiFormCheckbox(props: Props) {
  return <UiCheckbox {...props} style={style} />;
}
