import { MoreOutlined } from '@ant-design/icons';
import NiceModal from '@ebay/nice-modal-react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { UiPopover } from 'shared/ui';

import {
  checkControllerRight,
  selectTokenClaims,
  selectTokenRoles,
} from 'entities/authorization';

import { DeleteOwnerModal } from '../../delete';

type Props = {
  id: string;
  name: string;
};

export function OwnersTableActions(props: Props) {
  const roles = useSelector(selectTokenRoles);
  const claims = useSelector(selectTokenClaims);

  const hasWriteRight = checkControllerRight('Owner', 'Write', roles, claims);

  const navigate = useNavigate();

  const showDeleteModal = () => {
    NiceModal.show(DeleteOwnerModal, props);
  };

  const content = (
    <>
      <button onClick={() => navigate(props.id)}>Редактировать</button>
      {hasWriteRight && <button onClick={showDeleteModal}>Удалить</button>}
    </>
  );

  return (
    <UiPopover content={content}>
      <MoreOutlined rotate={90} />
    </UiPopover>
  );
}
