import { createColumnHelper } from '@tanstack/react-table';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';

import { useTableSettings } from 'shared/ui/table/visible-columns/use-table-settings';
import { TColumnDef } from 'shared/ui/table/types';

import { RoleDto } from 'entities/role';

import { selectRolesTable } from '../../../../redux/slices/tables/tables-slice';
import { setVisibleColumns, TableColumns, TableColumnsState } from '../store';

const columnHelper = createColumnHelper<RoleDto>();

const DATA_COLUMNS: TColumnDef<RoleDto, TableColumns>[] = [
  {
    accessorKey: 'name',
    id: 'name',
    header: 'Название',
  },
];

export const useColumns = () => {
  const dispatch = useDispatch();

  const settings = useSelector(selectRolesTable);

  const settingsColumn = useTableSettings({
    columnHelper,
    columns: DATA_COLUMNS.map(({ id, header }) => {
      return {
        key: id,
        label: header as string,
        isChecked: settings[id],
      };
    }),
    settings,
    renderCell: (props) => {
      return '';
    },
    setVisibleColumns: (cols: TableColumnsState) => {
      dispatch(setVisibleColumns(cols));
    },
  });

  const visibleColumns = useMemo(() => {
    const dataCols = settings
      ? DATA_COLUMNS.filter((el) => settings[el.id])
      : DATA_COLUMNS;

    return [...dataCols, settingsColumn];
  }, [settings]);

  return visibleColumns;
};
