import { Row, Col, Switch } from 'antd';
import styled from 'styled-components';

import { COLOR } from 'shared/consts';

import { BaseStyledCard } from '../../../../../../common-styles';

export const AdvancedPropertyFormWrapper = styled.div`
  & .ant-form-item-control-input {
    min-height: unset;
  }
`;

export const StyledCard = styled(BaseStyledCard)`
  padding-bottom: 20px;
`;

export const Title = styled.p`
  font-weight: 500;
  line-height: 17px;
  color: ${COLOR.black};
  padding: 20px 20px 12px 20px;
`;

export const ParameterName = styled(Col)`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR.black};
`;

export const LabelsRow = styled(Row)`
  padding: 10px 25px 10px 20px;
  margin: 0;
  justify-content: space-between;
`;

export const DataRow = styled(Row)`
  padding: 6px 25px 6px 20px;
  margin: 0;
  justify-content: space-between;

  &:nth-child(even) {
    background-color: ${COLOR.tableEvenRow};
  }
`;

export const Label = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR.darkGrey};
`;

export const KeyCol = styled(Col)`
  display: flex;
  align-items: center;
`;

export const ValueCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledSwitch = styled(Switch)`
  &.ant-switch {
    background-image: none;
    background: none;
    height: 17px;
    width: 32px;
    min-width: auto;
    border-radius: 13px;
  }

  &.ant-switch-checked .ant-switch-handle {
    left: calc(100% - 13px - 2px) !important;
  }

  & .ant-switch-handle {
    width: 13px;
    height: 13px;
  }

  &.ant-switch-checked {
    border: 0.3px solid ${COLOR.greenMenu};
  }

  &:not(.ant-switch-checked) {
    border: 0.3px solid ${COLOR.grey};
  }

  &.ant-switch-disabled {
    opacity: 1;
  }

  &.ant-switch-checked .ant-switch-handle::before {
    background-color: ${COLOR.greenMenu};
    bottom: 1px;
    box-shadow: none;
  }

  &:not(.ant-switch-checked) .ant-switch-handle::before {
    background: ${COLOR.grey};
    bottom: 1px;
    box-shadow: none;
  }
`;
