import { useState } from 'react';
import dayjs from 'dayjs';
import React from 'react';

import { RangePickerProps } from 'shared/ui';
import { DATE_RANGE_OPTION } from 'shared/lib/metrics';

import {
  getRequestMetricsDateParam,
  GraphMetricType,
  useGetGraphMetricsQuery,
} from 'entities/metrics';

import { MonitoringMetricsCard } from '../UpdatedMonitoringMetricsCard';

const DEFAULT_DATE_RANGE = 'threeMonths';

type Props = {
  type: GraphMetricType;
};

export function ConnectedMonitoringMetrics({ type }: Props) {
  const [dateFrom, setDateFrom] = useState<dayjs.Dayjs>(
    dayjs().subtract(...DATE_RANGE_OPTION[DEFAULT_DATE_RANGE].subtractParams)
  );
  const [dateTo, setDateTo] = useState<dayjs.Dayjs>(dayjs());

  const [isOpen, setIsOpen] = useState(false);

  const onOpenChange = (open: boolean) => {
    setIsOpen(open);
  };

  const onChange = (values: RangePickerProps['value']) => {
    if (values) {
      setDateFrom(dayjs(values[0]));
      setDateTo(dayjs(values[1]));
    }
  };

  const { data, isFetching, error } = useGetGraphMetricsQuery(
    {
      dateFrom: getRequestMetricsDateParam(dateFrom),
      dateTo: getRequestMetricsDateParam(dateTo),
      type,
    },
    { skip: isOpen }
  );

  return (
    <MonitoringMetricsCard
      dateFrom={dateFrom}
      dateTo={dateTo}
      type={type}
      loading={isFetching}
      data={data}
      error={error}
      onChange={onChange}
      onOpenChange={onOpenChange}
    />
  );
}

// TODO
export default React.memo(ConnectedMonitoringMetrics);
