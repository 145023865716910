import { Link, useNavigate } from 'react-router-dom';

import { CREATE_ENTITY_PREFIX } from 'shared/consts';
import { NavigationTabButton } from 'shared/ui';

export function AddGroupButton() {
  const path = `/charge-points/groups/${CREATE_ENTITY_PREFIX}`;

  return (
    <Link to={path}>
      <NavigationTabButton title="Добавить группу" />
    </Link>
  );
}
