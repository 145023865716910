import { Checkbox, Col, Form, FormProps, Spin } from 'antd';
import { useState } from 'react';

import { UiFloatInput } from 'shared/ui';

import { UserIcon } from '../../images/user';
import { LockIcon } from '../../images/lock';

import { LoginFormData } from '../../model/types';

import {
  ButtonContainer,
  CheckboxContainer,
  FormRow,
  LoginErrorMessage,
  StyledButton,
  StyledFormItem,
} from './styles';

const initialValues: LoginFormData = {
  login: '',
  password: '',
  rememberUser: false,
};

type Props = {
  loading: boolean;
  handleSubmit: (values: LoginFormData) => Promise<void>;
  errorMsg: string;
  clearErrorMsg: () => void;
};

export function LoginForm({
  handleSubmit,
  loading,
  errorMsg,
  clearErrorMsg,
}: Props) {
  const [isPasswordDisabled, setIsPasswordDisabled] = useState(true);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const [form] = Form.useForm<LoginFormData>();

  const onValuesChange: FormProps<LoginFormData>['onValuesChange'] = (
    _changedValues,
    allValues
  ) => {
    if (errorMsg) {
      clearErrorMsg();
    }

    if (allValues.login.length > 0) {
      setIsPasswordDisabled(false);
    } else {
      setIsPasswordDisabled(true);
    }

    if (allValues.login.length > 0 && allValues.password.length > 0) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      autoComplete="off"
      onValuesChange={onValuesChange}
      initialValues={initialValues}
    >
      <FormRow>
        <Col span={24}>
          <StyledFormItem name="login">
            <UiFloatInput
              type="text"
              label="Логин"
              placeholder="Логин"
              hasFocus={true}
              hasError={Boolean(errorMsg)}
              disabled={false}
              suffix={<UserIcon />}
            />
          </StyledFormItem>
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <StyledFormItem name="password">
            <UiFloatInput
              label="Пароль"
              type="password"
              placeholder="Пароль"
              hasFocus={false}
              hasError={Boolean(errorMsg)}
              disabled={isPasswordDisabled}
              suffix={<LockIcon />}
            />
          </StyledFormItem>
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <CheckboxContainer>
            <StyledFormItem name="rememberUser" valuePropName="checked">
              <Checkbox>Запомнить меня</Checkbox>
            </StyledFormItem>
            {errorMsg && <LoginErrorMessage>{errorMsg}</LoginErrorMessage>}
          </CheckboxContainer>
        </Col>
      </FormRow>
      <ButtonContainer>
        <Spin spinning={loading}>
          <StyledButton
            type="primary"
            htmlType="submit"
            disabled={isSubmitDisabled}
          >
            Войти
          </StyledButton>
        </Spin>
      </ButtonContainer>
    </Form>
  );
}
