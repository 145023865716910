import { ProgressBarContainer, ProgressBarFill } from './styles';

type Props = {
  progress: number;
};

export function ProgressBar({ progress }: Props) {
  return (
    <ProgressBarContainer>
      <ProgressBarFill progress={progress} />
    </ProgressBarContainer>
  );
}
