import {
  UnauthorizedCard,
  UnauthorizedContainer,
  UnauthorizedIcon,
  UnauthorizedText,
} from './styles';

import PrivacyIcon from './images/privacy.svg';

export function Unauthorized() {
  return (
    <UnauthorizedContainer>
      <UnauthorizedCard>
        <UnauthorizedIcon src={PrivacyIcon} />
        <UnauthorizedText>У вас недостаточно прав</UnauthorizedText>
      </UnauthorizedCard>
    </UnauthorizedContainer>
  );
}
