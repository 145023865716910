import { Tooltip } from 'antd';
import { StatusLabel, StyledStatusBadge, StatusIcon } from './styles';

type Props = {
  label: string;
  bgColor: string;
  icon: string;
  hint: string;
};

export function StatusBadge({ bgColor, label, icon, hint }: Props) {
  return (
    <Tooltip title={hint}>
      <StyledStatusBadge bgColor={bgColor}>
        <StatusIcon src={icon} />
        <StatusLabel>{label}</StatusLabel>
      </StyledStatusBadge>
    </Tooltip>
  );
}
