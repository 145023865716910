import { useLocation } from 'react-router-dom';

import { NavigationTabs } from 'shared/ui/navigation-tabs';

import { CHARGE_POINTS_GROUP_TABS } from '../../consts';
import { useChargePointsGroupFetchingContext } from '../../context';

export function ChargePointsGroupTabs() {
  const { fetchingTab } = useChargePointsGroupFetchingContext();

  const location = useLocation();

  return (
    <NavigationTabs
      tabs={CHARGE_POINTS_GROUP_TABS}
      fetchingTab={fetchingTab}
      rootPath={location.pathname}
    />
  );
}
