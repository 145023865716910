import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Leaves } from 'shared/lib/types';

import { ChargeTariffBankIdDto } from 'entities/tariff';

type ObjectLeaves = Leaves<ChargeTariffBankIdDto>;

export type TableColumns = Extract<
  ObjectLeaves,
  | 'id'
  | 'name'
  | 'tariffType'
  | 'startFee'
  | 'costOfQuickCharging'
  | 'costOfSlowCharging'
  | 'costOfReserve'
  | 'triggerType'
  | 'parkFee'
>;

export type TableColumnsState = Record<TableColumns, boolean>;

const initialState: TableColumnsState = {
  id: true,
  name: true,
  costOfQuickCharging: true,
  costOfSlowCharging: true,
  costOfReserve: true,
  parkFee: true,
  startFee: true,
  tariffType: true,
  triggerType: true,
};

const slice = createSlice({
  name: 'tariffsTable',
  initialState,
  reducers: {
    setVisibleColumns: (state, action: PayloadAction<TableColumnsState>) => {
      return action.payload;
    },
  },
});

export const { setVisibleColumns } = slice.actions;
export default slice.reducer;
