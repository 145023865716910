import styled from 'styled-components';

import { COLOR } from 'shared/consts';

export const StyledCard = styled.article`
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid ${COLOR.grey};
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`;

export const CardTitle = styled.h4`
  line-height: 17px;
  font-weight: 500;
`;

export const AddIconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const AddIconTitle = styled.h4``;
