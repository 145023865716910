import NiceModal from '@ebay/nice-modal-react';
import { CellContext, ColumnHelper } from '@tanstack/table-core';

import { TableColumnType } from 'shared/model/table';
import { VisibleColumnsModal } from 'shared/ui/table/visible-columns/ui/modal';

import { COLUMN_ID, COLUMN_WIDTH } from '../settings/consts';
import { TableSettings } from '../settings/ui';

// T - модель (Packet, SessionDto, etc.)
// K - ключ для колонки (по нему хранится в редаксе - видима ли колонка)

type Props<T, K extends string> = {
  columnHelper: ColumnHelper<T>; // Модель (типа Packet, Dto..)
  columns: TableColumnType<K>[]; //
  settings: Record<K, boolean>;
  renderCell: (props: CellContext<T, unknown>) => React.ReactNode;
  setVisibleColumns: (cols: Record<K, boolean>) => void;
};

export const useTableSettings = <T, K extends string>({
  columnHelper,
  columns,
  settings,
  renderCell,
  setVisibleColumns,
}: Props<T, K>) => {
  const handleSetVisibleColumns = (columns: TableColumnType<K>[]) => {
    const visibleColumns = columns.reduce(
      (acc, el) => {
        acc[el.key] = el.isChecked;

        return acc;
      },
      { ...settings } // мб нужна проверка на существование || {} ?
    );

    setVisibleColumns(visibleColumns);
  };

  const onHeaderClick = () => {
    NiceModal.show(VisibleColumnsModal, {
      columns,
      onConfirm: handleSetVisibleColumns,
    });
  };

  return columnHelper.display({
    id: COLUMN_ID,
    header: () => <TableSettings onClick={onHeaderClick} />,
    cell: (props) => renderCell(props),
    size: COLUMN_WIDTH,
    enableSorting: false,
  });
};
