import { NavigationTab } from 'shared/model';

export const TABS: Array<NavigationTab> = [
  {
    key: 'edit-user',
    label: 'Профиль',
    path: '',
  },
  {
    key: 'controller-claims',
    label: 'Права',
    path: `controller-claims`,
  },
  {
    key: 'charge-point-rights',
    label: 'ЭЗС',
    path: `charge-point-rights`,
  },
  {
    key: 'roles',
    label: 'Роли',
    path: `roles`,
  },
];
