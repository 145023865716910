import styled from 'styled-components';

export const UiPopoverContainer = styled.div`
  line-height: 1;

  & button {
    display: block;
    width: 100%;
  }
`;
