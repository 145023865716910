import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
  getServerErrorText,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { ErrorMessage, EmptyData } from 'shared/ui';

import { RoleDto, useGetRolesQuery } from 'entities/role';

import {
  useGetUserRolesQuery,
  useUpdateUserRolesMutation,
} from '../../../../../redux/services/identity';

import {
  getEditUserRoleError,
  getEditUserRoleSuccess,
} from '../../ChargePointRights/constants';
import { UserRoles, UserRolesTableItem } from '../../components/UserRoles';

export function ConnectedUserRoles() {
  const { userName } = useParams() as { userName: string };

  const {
    data: userRoles,
    isFetching: isUserRolesFetching,
    error: userRolesError,
  } = useGetUserRolesQuery(userName);
  const {
    data: roles,
    isFetching: isRolesFetching,
    error: rolesError,
  } = useGetRolesQuery();

  const [updateTrigger, { isLoading: isUpdateLoading }] =
    useUpdateUserRolesMutation();

  const getInitialValues = () => {
    if (!userRoles || !roles) {
      return {};
    }

    return roles.roles.reduce((acc, el) => {
      acc[el.name] = Boolean(
        userRoles.roles.find((role) => role.name === el.name)
      );

      return acc;
    }, {} as Record<string, boolean>);
  };

  const initialValues = useMemo(() => getInitialValues(), [roles, userRoles]);

  const getTableData = (
    roles?: RoleDto[],
    userRoles?: RoleDto[]
  ): UserRolesTableItem[] => {
    if (!roles || !userRoles) {
      return [];
    }

    return roles.map((role) => {
      const active = Boolean(userRoles.find((el) => el.name === role.name));
      return { ...role, active };
    });
  };

  const tableData = useMemo(
    () => getTableData(roles?.roles, userRoles?.roles),
    [roles, userRoles]
  );

  if (isRolesFetching || isUserRolesFetching) {
    return <div>Loading...</div>;
  }

  const apiResponseError = [roles, userRoles].find(
    (el) => el && el.statusCode !== 0
  );

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusDescription} />;
  }

  const error = [userRolesError, rolesError].find((err) => err !== undefined);

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!userRoles || !roles) {
    return <EmptyData />;
  }

  const handleSubmit = async (values: Record<string, boolean>) => {
    const addRoles = Object.keys(values).filter(
      (key) =>
        values[key] === true &&
        !userRoles.roles.find((role) => role.name === key)
    );
    const deleteRoles = Object.keys(values).filter(
      (key) =>
        values[key] === false &&
        userRoles.roles.find((role) => role.name === key)
    );

    try {
      const res = await updateTrigger({ userName, addRoles, deleteRoles });

      // TODO: error handling
      res.data.statusCode === 0
        ? openSuccessNotification(getEditUserRoleSuccess(userName))
        : openErrorNotification(getEditUserRoleError(userName));
    } catch (e) {
      openErrorNotification(getEditUserRoleError(userName));
    }
  };

  return (
    <UserRoles
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      tableData={tableData}
      loading={isUpdateLoading}
    />
  );
}
