import { ADD_PREFIX, SETTINGS } from './common';

export const CPOS = 'cpos';
export const CPO = ':cpoId';

export const CPO_ROUTES = {
  CPOS: `/${SETTINGS}/${CPOS}`,
  CPO: `/${SETTINGS}/${CPOS}/${CPO}`,
  ADD_CPO: `/${SETTINGS}/${CPOS}/${ADD_PREFIX}`,
};
