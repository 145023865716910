import { useParams } from 'react-router-dom';

import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
  getServerErrorText,
} from 'shared/lib';

import { EmptyData, ErrorMessage } from 'shared/ui';

import {
  type OcpiHub,
  type OcpiHubFormData,
  useGetOcpiHubQuery,
  useUpdateOcpiHubMutation,
  OcpiHubForm,
} from 'entities/ocpi-hub';

import { EDIT_ERROR, EDIT_SUCCESS } from '../consts';

const getInitialValues = (ocpiHub: OcpiHub): OcpiHubFormData => {
  const { id, isMain, ...rest } = ocpiHub;

  return { ...rest, id: String(id) };
};

export function EditOcpiHub() {
  const [trigger, { isLoading: isMutationLoading }] =
    useUpdateOcpiHubMutation();

  const { ocpiHubId } = useParams() as { ocpiHubId: string };

  const { isFetching, error, data } = useGetOcpiHubQuery(ocpiHubId);

  const handleSubmit = async (values: OcpiHubFormData) => {
    try {
      const res = await trigger(values).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);
        return;
      }
      //

      openSuccessNotification(EDIT_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : EDIT_ERROR;

      openErrorNotification(errorText);
    }
  };

  if (isFetching) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  if (!data?.ocpiHub) {
    return <EmptyData />;
  }

  const initialValues = getInitialValues(data.ocpiHub);

  return (
    <OcpiHubForm
      handleSubmit={handleSubmit}
      loading={isMutationLoading}
      initialValues={initialValues}
      title={data.ocpiHub.name}
      disabled
    />
  );
}
