import { baseApi } from 'shared/api';

import { getEndpointPath } from 'shared/lib';

import {
  OwnersDtoBaseResponse,
  OwnerDtoBaseResponse,
  OwnerBaseResponse,
  AddOwnerRequest,
  UpdateOwnerRequest,
} from '../model';

import { apiSlice } from '../../../redux/services/apiSlice';
import { BONUS_SERVICE } from 'entities/authorization';
import {
  OwnerMaxBonusPay,
  UpdateOwnerMaxBonusPayRequest,
} from '../model/owner-max-bonus-pay-api';

const KEY = 'OwnerMaxBonusPay';

const getUrl = getEndpointPath(BONUS_SERVICE, KEY);

// TODO: замена на baseApi

export const ownerMaxBonusPayApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllOwnerMaxBonusPay: builder.query<Array<OwnerMaxBonusPay>, void>({
      query: () => getUrl('GetAll'),
      providesTags: ['OwnersMaxBonusPay'],
    }),
    getByIdOwnerMaxBonusPay: builder.query<OwnerMaxBonusPay, string>({
      query: (id) => ({
        url: getUrl(`GetById/${id}`),
      }),
    }),
    addOwnerMaxBonusPay: builder.mutation<OwnerMaxBonusPay, OwnerMaxBonusPay>({
      query: (body) => ({
        url: getUrl('Add'),
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Owners'],
    }),
    updateOwnerMaxBonusPay: builder.mutation<
      void,
      UpdateOwnerMaxBonusPayRequest
    >({
      query: (body) => ({
        url: getUrl('Update'),
        method: 'PATCH',
        body,
      }),
    }),
    deleteByIdOwnerMaxBonusPay: builder.mutation<void, string>({
      query: (id) => ({
        url: getUrl(`Delete`),
        params: { id },
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetAllOwnerMaxBonusPayQuery,
  useGetByIdOwnerMaxBonusPayQuery,
} = ownerMaxBonusPayApi;
