import dayjs from 'dayjs';
import { useState } from 'react';
import { RadioChangeEvent } from 'antd/lib/radio/interface';

import { RangePickerProps } from 'shared/ui';
import { DATE_RANGE_OPTION } from 'shared/lib/metrics';

import {
  getRequestMetricsDateParam,
  GraphMetricType,
  useGetGraphMetricsQuery,
} from 'entities/metrics';

import { ChargedTotal } from '../charged-total';

const DEFAULT_DATE_RANGE = 'threeMonths';
const METRIC_TYPE: GraphMetricType = '1';

type Props = {
  chargePointName?: string;
};

export function ConnectedChargedTotal({ chargePointName = '' }: Props) {
  const [dateFrom, setDateFrom] = useState<dayjs.Dayjs>(
    dayjs().subtract(...DATE_RANGE_OPTION[DEFAULT_DATE_RANGE].subtractParams)
  );
  const [dateTo, setDateTo] = useState<dayjs.Dayjs>(dayjs());

  const [dateRangeOption, setDateRangeOption] = useState(DEFAULT_DATE_RANGE);

  const [isOpen, setIsOpen] = useState(false);

  const onOpenChange = (open: boolean) => {
    setIsOpen(open);
  };

  const onChange = (values: RangePickerProps['value']) => {
    if (values) {
      setDateFrom(dayjs(values[0]));
      setDateTo(dayjs(values[1]));
    }
  };

  const onRadioGroupChange = (e: RadioChangeEvent) => {
    const option = e.target.value;

    const [value, type] = DATE_RANGE_OPTION[option].subtractParams;

    setDateTo(dayjs());
    setDateFrom(dayjs().subtract(value, type));

    setDateRangeOption(option);
  };

  const { isFetching, data, error } = useGetGraphMetricsQuery(
    {
      dateFrom: getRequestMetricsDateParam(dateFrom),
      dateTo: getRequestMetricsDateParam(dateTo),
      type: METRIC_TYPE,
      chargePointName,
    },
    {
      skip: isOpen,
    }
  );

  return (
    <ChargedTotal
      dateFrom={dateFrom}
      dateTo={dateTo}
      data={data}
      loading={isFetching}
      error={error}
      onRadioGroupChange={onRadioGroupChange}
      onChange={onChange}
      onOpenChange={onOpenChange}
      dateRangeOption={dateRangeOption}
    />
  );
}
