import { Checkbox, Form } from 'antd';
import styled from 'styled-components';

import { COLOR } from 'shared/consts';

const { Item } = Form;

export const GroupColumnLabel = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

export const AllCpRightsContainer = styled.div`
  display: inline-block;
  background: ${COLOR.white};
  padding: 10px;
  margin-bottom: 10px;
`;

export const RightsFormItem = styled(Item)`
  margin-bottom: 0;

  & .ant-form-item-control-input {
    min-height: unset;
  }
`;
