import { TariffFormData } from '../model';
import { getTriggerType } from './get-trigger-type';

export const getTriggerFee = (
  values: TariffFormData,
  triggerType: ReturnType<typeof getTriggerType>
) => {
  const { batteryPercentageFee, powerDropFee } = values;

  if (triggerType === 0) {
    return '0';
  }

  if (triggerType === 1) {
    return batteryPercentageFee;
  }

  return powerDropFee;
};
