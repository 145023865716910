import NiceModal from '@ebay/nice-modal-react';

import {
  AvatarMock,
  ProfileLink,
  UserFullName,
  UserGroup,
  UserInfoContainer,
} from './styles';

import { ArrowRightIcon } from '../../../images/arrow-right';

import { EditProfileModal } from '../edit-profile/ui/modal';

type Props = {
  firstName: string;
  lastName: string;
};

export function UserInfo({ firstName, lastName }: Props) {
  const avatarMock = `${firstName[0] ?? ''}${lastName[0] ?? ''}`;

  const fullName = `${firstName} ${lastName}`;

  const onClick = () => {
    NiceModal.show(EditProfileModal);
  };

  return (
    <UserInfoContainer>
      <AvatarMock>{avatarMock}</AvatarMock>
      <UserGroup>
        <UserFullName>{fullName}</UserFullName>
        <ProfileLink onClick={onClick}>
          Профиль <ArrowRightIcon />
        </ProfileLink>
      </UserGroup>
    </UserInfoContainer>
  );
}
