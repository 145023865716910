import { EmptyData, NavMenuError } from 'shared/ui';
import { SectionMenu } from 'shared/ui/section-menu';
import { MOBILE_APP_ROUTES } from 'shared/consts/routes/mobile-app';

import { SECTION } from 'entities/navigation';
import { useGetMobileAppsQuery } from 'entities/mobile-app';

import { AddButton } from './add-button';

const { label, path } = SECTION['mobile-apps'];

export function ConnectedMobileAppsMenu() {
  const { data, isFetching, error } = useGetMobileAppsQuery();

  if (data && data.statusCode !== 0) {
    return <NavMenuError text={data.statusDescription} />;
  }

  if (error) {
    return <NavMenuError />;
  }

  if (!isFetching && !data) {
    return <EmptyData />;
  }

  const items = data?.mobileApps || [];

  return (
    <SectionMenu
      loading={isFetching}
      items={items}
      menuKey={path}
      absolutePath={MOBILE_APP_ROUTES.MOBILE_APPS}
      label={label}
      addButton={<AddButton />}
    />
  );
}
