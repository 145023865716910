import { Spin } from 'antd';
import { StyledButton } from '../../../../common-styles';

import { ReportButtonCotainer } from './styles';

interface GetReportButtonProps {
  disabled: boolean;
  spinning: boolean;
}

export function GetReportButton({ disabled, spinning }: GetReportButtonProps) {
  return (
    <ReportButtonCotainer>
      <Spin spinning={spinning}>
        <StyledButton htmlType="submit" type="primary" disabled={disabled}>
          Получить отчет
        </StyledButton>
      </Spin>
    </ReportButtonCotainer>
  );
}
