import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

import { COLOR, DEFAULT_BORDER } from 'shared/consts';

export const GlobalStyles = createGlobalStyle`
  ${reset}

  body {
    background-color: ${COLOR.whiteSmoke};
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    line-height: 17px;
    color: ${COLOR.black}
  }

  button {
    padding: 0;
    border: none;
    background-color: transparent;
  }

  .ant-select-item.ant-select-item-option.ant-select-item-option-selected {
    background-color: ${COLOR.lightGreen};
  }

  .ant-popover-inner-content {
    padding: 0;
    border: ${DEFAULT_BORDER};
    box-shadow: 0px 2px 4px ${COLOR.boxShadow};
    border-radius: 1px;
    display: flex;
    flex-direction: column;

    & button {
      border: none;
      cursor: pointer;
      padding: 7px 10px;
      background-color: ${COLOR.white};
      text-align: start;
    }

    & button:not(:last-child) {
      border-bottom: 0.2px solid ${COLOR.grey};
    }

    & button:hover {
      background-color: ${COLOR.whiteSmoke};
    }
  }

  .scrollbar-measure {
    width: 100px;
    height: 100px;
    overflow: scroll;
    position: absolute;
    top: -9999px;
  }

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${COLOR.whiteSmoke};
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${COLOR.greenMenu};
    border-radius: 5px;
  }

  // tooltip

  & .ant-tooltip {
    max-width: max-content;

    & .ant-tooltip-inner {
      width: max-content;
      max-height: 50vh;
      overflow: auto;
    }

    
  }
`;
