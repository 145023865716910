import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import useMeasure from 'react-use-measure';

import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText, useTablePageSize } from 'shared/lib';

import { useGetTransactionsByProgramQuery } from 'entities/bonus-program';

import { TableInstance } from './table';

export function ConnectedBonusProgramTransactionsTable() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [tableHeadRef, tableHeadBounds] = useMeasure();

  const [tablePageSize, setTablePageSize] = useTablePageSize(tableHeadBounds);

  const { bonusProgramId } = useParams() as { bonusProgramId: string };

  const page = searchParams.get('page');
  const limit = searchParams.get('limit');

  // TODO: проверить в других контейнерах page
  const { data, isFetching, error, isSuccess } =
    useGetTransactionsByProgramQuery(
      {
        bonusProgramId: Number(bonusProgramId),
        limit: limit || String(tablePageSize),
        page: page && page !== '1' ? page : null,
      },
      {
        skip: !limit && !tablePageSize,
      }
    );

  const tableData = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.items;
  }, [data]);

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (isSuccess && !data) {
    return <EmptyData />;
  }

  return (
    <TableInstance
      tableData={tableData}
      loading={isFetching}
      count={data?.count}
      tableHeadRef={tableHeadRef}
      tablePageSize={tablePageSize}
    />
  );
}
