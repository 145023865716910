import { UploadFile } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  isErrorWithMessage,
  getServerErrorText,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { ErrorMessage, EmptyData } from 'shared/ui';

import {
  PushNotificationMessageDto,
  PushLoadPersonsRequest,
  useGetPushNotificationMessageQuery,
  usePushLoadPersonsMutation,
  usePushSendManualMutation,
  useUpdatePushNotificationMessageMutation,
  PushNotificationFormData,
  PushNotificationForm,
} from 'entities/push-notification';

import {
  EDIT_ERRROR,
  EDIT_SUCCESS,
  getLoadPersonsError,
  getLoadPersonsSuccess,
  getSendPushError,
  getSendPushSuccess,
} from '../consts';

const getInitialValues = (
  pushNotification: PushNotificationMessageDto
): PushNotificationFormData => {
  const { name, title, text, browserUrl, deepLink } = pushNotification;

  return {
    name,
    title,
    text,
    browserUrl: browserUrl ?? '',
    deepLink: deepLink ?? '',
  };
};

export function EditPushNotification() {
  const [loadedPersonsCount, setLoadedPersonsCount] = useState<null | number>(
    null
  );

  const [trigger, { isLoading: isUpdatePushNotificationLoading }] =
    useUpdatePushNotificationMessageMutation();
  const [loadPersonsTrigger, { isLoading: isPushLoadPersonsLoading }] =
    usePushLoadPersonsMutation();
  const [sendPushTrigger, { isLoading: isPushSendManualLoading }] =
    usePushSendManualMutation();

  const { pushNotificationMessageId } = useParams() as {
    pushNotificationMessageId: string;
  };

  const { isFetching, error, data } = useGetPushNotificationMessageQuery(
    pushNotificationMessageId
  );

  const handleSetLoadedPersonsCount = (count: number) =>
    setLoadedPersonsCount(count);

  if (isFetching) {
    return <div>Loading...</div>;
  }

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!data) {
    return <EmptyData />;
  }

  const initialValues = getInitialValues(data.pushNotificationMessage);

  const handleSubmit = async (values: PushNotificationFormData) => {
    const req = {
      ...values,
      id: Number(pushNotificationMessageId),
    };

    try {
      const res = await trigger(req).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);
        return;
      }
      //

      openSuccessNotification(EDIT_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : EDIT_ERRROR;

      openErrorNotification(errorText);
    }
  };

  const onLoadPersons = async (files: UploadFile<any>[]) => {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append('file', file as RcFile);
    });

    const req: PushLoadPersonsRequest = {
      pushNotificationMessageId,
      file: formData,
    };

    try {
      const res = await loadPersonsTrigger(req).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);
        return;
      }
      //

      const { personLoaded, totalPersonCountInFile } = res.result;

      const successText = getLoadPersonsSuccess(
        personLoaded,
        totalPersonCountInFile
      );

      handleSetLoadedPersonsCount(personLoaded);

      openSuccessNotification(successText);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : getLoadPersonsError();

      openErrorNotification(errorText);
    }
  };

  const onSendPush = async (id: number) => {
    try {
      const res = await sendPushTrigger(id).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);
        return;
      }
      //

      const successText = getSendPushSuccess(res.result.sendedPerson);

      openSuccessNotification(successText);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : getSendPushError();

      openErrorNotification(errorText);
    }
  };

  return (
    <PushNotificationForm
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      title={data.pushNotificationMessage.name}
      loading={
        isUpdatePushNotificationLoading ||
        isPushLoadPersonsLoading ||
        isPushSendManualLoading
      }
      id={data.pushNotificationMessage.id}
      loadedPersonsCount={loadedPersonsCount}
      onLoadPersons={onLoadPersons}
      onSendPush={onSendPush}
      disabled
    />
  );
}
