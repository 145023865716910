import styled from 'styled-components';

import { COLOR, DEFAULT_BORDER } from 'shared/consts';

interface ButtonProps {
  active: boolean;
}

export const StyledButton = styled.button<ButtonProps>`
  margin: 0;
  border: none;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  background-color: transparent;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;

  color: ${(props) => (props.active ? `${COLOR.black}` : `${COLOR.darkGrey}`)};
  font-weight: ${(props) => (props.active ? 500 : 400)};
  border-bottom: ${(props) =>
    props.active ? `2px solid ${COLOR.greenMenu}` : 'none'};

  &:disabled {
    cursor: not-allowed;
  }
`;

export const TabsMenu = styled.ul`
  display: flex;
  background-color: ${COLOR.white};
  min-width: max-content;
`;

// temp
export const TabPanelsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 42px;
  border-bottom: ${DEFAULT_BORDER};
  background-color: ${COLOR.white};
  padding-right: 20px;

  position: sticky;
  top: 0;
  z-index: 1000;
`;
