import styled from 'styled-components';

import { COLOR } from 'shared/consts';

export const Title = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${COLOR.title};
`;

export const UserLogin = styled.span`
  font-weight: 500;
`;
