import { useSearchParams } from 'react-router-dom';

export const useSortingChange = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const onSortingChange = (newOrdering: string) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());

    const currentOrdering = searchParams.get('ordering');
    const currentType = searchParams.get('type');

    const dateFrom = searchParams.get('dateFrom');
    const dateTo = searchParams.get('dateTo');

    if (newOrdering === currentOrdering && currentType === '0') {
      updatedSearchParams.set('type', '1');

      updatedSearchParams.delete('page');
    } else if (newOrdering === currentOrdering && currentType === '1') {
      updatedSearchParams.delete('type');
      updatedSearchParams.delete('page');

      if (dateFrom === null && dateTo === null) {
        updatedSearchParams.delete('ordering');
      }
    } else if (newOrdering !== currentOrdering) {
      updatedSearchParams.delete('dateFrom');
      updatedSearchParams.delete('dateTo');

      updatedSearchParams.set('ordering', newOrdering);
      updatedSearchParams.set('type', '0');
      updatedSearchParams.delete('page');
    } else {
      // Mb redundant
      updatedSearchParams.set('ordering', newOrdering);
      updatedSearchParams.set('type', '0');

      updatedSearchParams.delete('page');
    }

    setSearchParams(updatedSearchParams.toString());
  };

  return onSortingChange;
};
