import { COLOR } from 'shared/consts';

import {
  SchukoIcon,
  CCS1Icon,
  CCS2Icon,
  CHAdeMOIcon,
  GBTACIcon,
  GBTDCIcon,
  TeslaIcon,
  Type1Icon,
  Type2Icon,
} from '../images';

import {
  ChargePointConnectorOcppStatus,
  ChargePointConnectorStatus,
  ChargePointConnectorType,
  ConnectorFormat,
} from '../model';

export const CONNECTOR_TYPE: Record<ChargePointConnectorType, string> = {
  // 0: 'Не определен',
  1: 'Розетка',
  2: 'Type 2',
  3: 'CCS 2',
  4: 'CHAdeMO',
  5: 'GB/T DC',
  6: 'GB/T AC',
  7: 'CCS 1',
  8: 'Type 1',
  9: 'Tesla',
};

export const CONNECTOR_STATUS: Record<ChargePointConnectorStatus, string> = {
  0: 'Undefined',
  1: 'Available',
  2: 'Charging',
  3: 'Unavailable',
  4: 'Faulted',
  5: 'CoverOpen',
  7: 'Reserved',
};

export const CONNECTOR_OCPP_STATUS: Record<
  ChargePointConnectorOcppStatus,
  string
> = {
  0: 'Undefined',
  1: 'Available',
  2: 'Preparing',
  3: 'Charging',
  4: 'SuspendedEVSE',
  5: 'SuspendedEV',
  6: 'Finishing',
  7: 'Reserved',
  8: 'Unavailable',
  9: 'Faulted',
};

export const CONNECTOR_FORMAT: Record<ConnectorFormat, string> = {
  CABLE: 'Кабель',
  SOCKET: 'Розетка',
};

export const CONNECTOR_LITER = ['A', 'B', 'C', 'D'] as const;

export const CONNECTOR_STATUS_COLOR: Record<
  ChargePointConnectorStatus,
  string
> = {
  0: COLOR.uninitialized,
  1: COLOR.greenMenu,
  2: COLOR.yellow,
  3: COLOR.darkOrange,
  4: COLOR.red,
  5: COLOR.greenMenu,
  7: COLOR.lightBlue,
};

export const CONNECTOR_OCPP_STATUS_COLOR: Record<
  ChargePointConnectorOcppStatus,
  string
> = {
  0: COLOR.uninitialized,
  1: COLOR.greenMenu,
  2: 'rgb(231,231,78)',
  3: COLOR.yellow,
  4: COLOR.purple,
  5: COLOR.purple,
  6: COLOR.darkYellow,
  7: COLOR.lightBlue,
  8: COLOR.darkOrange,
  9: COLOR.red,
};

export const CONNECTOR_TYPE_ICON: Record<ChargePointConnectorType, string> = {
  1: SchukoIcon,
  2: Type2Icon,
  3: CCS2Icon,
  4: CHAdeMOIcon,
  5: GBTDCIcon,
  6: GBTACIcon,
  7: CCS1Icon,
  8: Type1Icon,
  9: TeslaIcon,
};
