import { Link } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';

import {
  ChargePointStatus,
  ChargePointType,
  CHARGE_POINT_STATUS,
  CHARGE_POINT_TYPE_BADGE,
} from 'entities/charge-point';
import { ChargeTariffDto } from 'entities/tariff';
import { ChargepointTariffsDetailsModal } from 'entities/charge-point/charge-point-tariff/ui/modal';

import {
  StyledCard,
  CardHeader,
  StatusBadge,
  ParamLabel,
  Param,
  ParamValue,
  ParamsContainer,
  CardHeaderContent,
  ChargePointName,
  CardContent,
  TypeBadge,
  OwnerInfoContainer,
  OwnerIcon,
  MaxPowerContainer,
  ChargePointTitle,
  TariffsDetailsTitleContainer,
  TariffsDetailsTitle,
  TariffDetailsIcon,
  TariffDetailsContainer,
} from './styles';

import OcpiIcon from './images/ocpi.svg';
import ExclamationIcon from './images/exclamation.svg';
import { TARIFFS_ROUTES } from 'shared/consts/routes/tariff';

type Props = {
  name: string;
  status: ChargePointStatus;
  type: ChargePointType;
  maxPower: number;
  address: string;
  ownerName?: string;
  tariff: ChargeTariffDto;
  actionsSlot: React.ReactNode;
  chargePointId?: string;
};

export function BaseInfoCard({
  name,
  status,
  type,
  maxPower,
  address,
  ownerName,
  actionsSlot,
  tariff,
}: Props) {
  const renderedMaxPower = () => {
    let base = <ParamValue>{`${maxPower} кВт`}</ParamValue>;

    if (type === 0) {
      return base;
    }

    return (
      <>
        {base}
        <span>&#x2022;</span>
        <TypeBadge type={type}>{` ${CHARGE_POINT_TYPE_BADGE[type]}`}</TypeBadge>
      </>
    );
  };

  const showTariffDetailsModal = () => {
    const title = (
      <TariffsDetailsTitleContainer>
        <TariffsDetailsTitle>Тариф ЭЗС</TariffsDetailsTitle>
        <StatusBadge status={status}>{`ЭЗС ${name}`}</StatusBadge>
      </TariffsDetailsTitleContainer>
    );

    NiceModal.show(ChargepointTariffsDetailsModal, { title, tariff });
  };

  const renderTariff = () => {
    if (!tariff) {
      return null;
    }

    const { id, name } = tariff;

    return (
      <Param>
        <ParamLabel>Тариф</ParamLabel>
        <ParamValue>
          <TariffDetailsContainer>
            <Link to={`${TARIFFS_ROUTES.TARIFFS}/${id}`}>{name}</Link>
            <TariffDetailsIcon
              src={ExclamationIcon}
              onClick={showTariffDetailsModal}
            />
          </TariffDetailsContainer>
        </ParamValue>
      </Param>
    );
  };

  return (
    <>
      <StyledCard>
        <CardContent>
          <CardHeader>
            <CardHeaderContent>
              <StatusBadge status={status}>
                {CHARGE_POINT_STATUS[status]}
              </StatusBadge>
              {actionsSlot}
            </CardHeaderContent>
            <ChargePointTitle>
              <ParamLabel>ЭЗС</ParamLabel>
              <ChargePointName>{name}</ChargePointName>
            </ChargePointTitle>
          </CardHeader>
          <ParamsContainer>
            {renderTariff()}
            <Param>
              <ParamLabel>Мощность</ParamLabel>
              <MaxPowerContainer>{renderedMaxPower()}</MaxPowerContainer>
            </Param>
            <Param>
              <ParamLabel>Адрес</ParamLabel>
              <ParamValue>{address}</ParamValue>
            </Param>
            {ownerName && (
              <OwnerInfoContainer>
                <Param>
                  <ParamLabel>Внешнее управление</ParamLabel>
                  <ParamValue>{ownerName}</ParamValue>
                </Param>
                <OwnerIcon src={OcpiIcon} />
              </OwnerInfoContainer>
            )}
          </ParamsContainer>
        </CardContent>
      </StyledCard>
    </>
  );
}
