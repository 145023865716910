import { useGetUsersQuery } from 'entities/user';
import { useMemo } from 'react';

import { getServerErrorText } from 'shared/lib';
import { EmptyData, ErrorMessage } from 'shared/ui';

import { UserListTable } from './table';

export function ConnectedUserListTable() {
  const { data, isFetching, error } = useGetUsersQuery();

  const tableData = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.users;
  }, [data]);

  if (isFetching) {
    return <div>Loading...</div>;
  }

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!data) {
    return <EmptyData />;
  }

  return (
    <UserListTable
      loading={isFetching}
      tableData={tableData}
      rowsCount={data.users.length}
    />
  );
}
