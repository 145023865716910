import styled from 'styled-components';

import { COLOR } from 'shared/consts';

type Props = {
  isAccrual: boolean;
};

export const BonusSumText = styled.p<Props>`
  color: ${(props) => (props.isAccrual ? COLOR.greenMenu : COLOR.red)};
`;
