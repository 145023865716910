import { Menu } from 'antd';
import styled from 'styled-components';

import { COLOR } from 'shared/consts';

export const FiltersCard = styled.div`
  border-radius: 4px;
  background-color: ${COLOR.white};
`;

interface SelectAllButtonProps {
  checkAll: boolean;
}

export const SelectAllButton = styled.button<SelectAllButtonProps>`
  color: ${(props) => (props.checkAll ? COLOR.inputGrey : COLOR.greenMenu)};
  border: none;
  background-color: ${COLOR.white};
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
`;

export const Cardtitle = styled.h3`
  font-size: 18px;
  line-height: 22px;
  font-style: normal;
  font-weight: 700;
`;

// interface StyledFiltersMenuProps {
//   showAsGrid?: boolean;
// }

export const StyledFiltersMenu = styled(Menu)`
  border-right: none;
  display: flex;

  & div.ant-menu-submenu-title {
    margin: 0;
  }

  & li.ant-menu-submenu {
    width: 100%;
  }

  & li {
    margin: 0 !important;
  }

  & li.ant-menu-item-selected::after {
    display: none;
  }

  & div.ant-menu-submenu-title span.ant-menu-title-content {
    font-weight: 600;
  }

  & .ant-menu-title-content {
    color: ${COLOR.black};
    line-height: 17px;
  }

  & ul.ant-menu-sub {
    background-color: ${COLOR.white};

    & li {
      padding-left: 24px !important;
    }
  }
`;

export const CheckboxContainer = styled.div`
  position: relative;
  display: block;

  & div {
    display: block;
  }
`;

export const CheckboxCover = styled.div`
  width: 100%;
  height: 100%;
  background: transparent;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  display: none;
`;

export const FiltersGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* grid-template-columns: repeat(2, max-content); */
  gap: 15px;
  padding: 0 15px 15px 15px;
`;

export const FiltersGridColumn = styled.div``;

export const StyledButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 0 15px 15px;

  & button {
    width: 169px;
  }
`;
