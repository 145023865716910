import { createColumnHelper } from '@tanstack/react-table';
import { useDispatch, useSelector } from 'react-redux';
import NiceModal from '@ebay/nice-modal-react';
import { Link } from 'react-router-dom';
import { useMemo } from 'react';

import { useTableSettings } from 'shared/ui/table/visible-columns/use-table-settings';
import { TableColumnObject } from 'shared/ui/table/visible-columns/types';
import { DateTimeRangePickerFilter, InputFilter } from 'shared/ui/table/filter';
import {
  FORMAT_TO_SECONDS,
  showTimeString,
  toConsumeUnit,
  toCurrencyUnit,
} from 'shared/lib';

import {
  checkControllerRight,
  selectTokenClaims,
  selectTokenRoles,
} from 'entities/authorization';
import { MongoSessionDTO, TariffDetailsModal } from 'entities/session';

import { SessionTableActions } from '../ui/actions';

import { setVisibleColumns, TableColumns, TableColumnsState } from '../store';

import { ClickableSpan } from '../../../../common-styles';

import { selectSessionsTable } from '../../../../redux/slices/tables/tables-slice';
import { PERSON_ROUTES } from 'shared/consts/routes/person';
import { CHARGE_POINT_ROUTES } from 'shared/consts/routes/charge-point';

const columnHelper = createColumnHelper<MongoSessionDTO>();

const COLUMN: TableColumnObject<TableColumns> = {
  cpName: {
    id: 'operation.cpName',
    header: 'ЭЗС',
    size: 80,
  },
  initChargeDateTime: {
    id: 'operation.initChargeDateTime',
    header: 'Дата старта',
  },
  chargeEndTime: {
    id: 'chargeEndTime',
    header: 'Завершена',
  },
  lastUpdateTime: {
    id: 'lastUpdateTime',
    header: 'Дата обновления',
  },
  userBrowseName: {
    id: 'userBrowseName',
    header: 'Клиент',
    size: 120,
  },
  calculatedConsume: {
    id: 'operation.calculatedConsume',
    header: 'Потребление, кВт*ч',
    size: 130,
  },
  calculatedPayment: {
    id: 'operation.calculatedPayment',
    header: 'Цена',
    size: 100,
  },
  statusHR: {
    id: 'statusHR',
    header: 'Состояние процесса заряда',
  },
  startSoc: {
    id: 'session.startSoc',
    header: '% старта',
    size: 85, //
  },
  soc: {
    id: 'session.soc',
    header: '% заряда',
    size: 85,
  },
  powerActiveImport: {
    id: 'session.powerActiveImport',
    header: 'Текущая мощность, кВт',
    size: 140,
  },
  maxPower: {
    id: 'session.maxPower',
    header: 'Максимальная мощность, кВт',
    size: 140,
  },
  reason: {
    id: 'session.reason',
    header: 'Причина завершения',
    size: 120,
  },
  errorCode: {
    id: 'session.errorCode',
    header: 'Код ошибки',
  },
  connectorId: {
    id: 'operation.connectorId',
    header: 'Коннектор',
    size: 120,
  },
};

export const useColumns = () => {
  const dispatch = useDispatch();

  const settings = useSelector(selectSessionsTable);

  const roles = useSelector(selectTokenRoles);
  const claims = useSelector(selectTokenClaims);

  const hasPersonReadRight = checkControllerRight(
    'Person',
    'Read',
    roles,
    claims
  );

  const showTariffDetailsModal = (session: MongoSessionDTO) => {
    NiceModal.show(TariffDetailsModal, { session });
  };

  const dataColumns = [
    columnHelper.accessor('operation', {
      ...COLUMN.cpName,
      cell: (props) => {
        const { chargePointGroupId, operation } = props.row.original;

        const cpName = operation?.cpName ?? null;

        if (!cpName) return;

        return (
          <Link
            to={`${CHARGE_POINT_ROUTES.CHARGE_POINTS}/${
              chargePointGroupId !== 0 ? chargePointGroupId : null
            }/${cpName}`}
          >
            {cpName}
          </Link>
        );
      },
      meta: {
        filterElement: <InputFilter column={COLUMN.cpName} />,
      },
    }),
    columnHelper.accessor('operation', {
      ...COLUMN.initChargeDateTime,
      cell: (props) => {
        const date = props.row.original.operation?.initChargeDateTime || '';

        return date ? showTimeString(date, FORMAT_TO_SECONDS) : '';
      },
      meta: {
        filterElement: (
          <DateTimeRangePickerFilter columnId={COLUMN.initChargeDateTime.id} />
        ),
      },
    }),
    columnHelper.accessor('chargeEndTime', {
      ...COLUMN.chargeEndTime,
      cell: (props) => {
        const date = props.getValue() || '';

        return date ? showTimeString(date, FORMAT_TO_SECONDS) : '';
      },
      meta: {
        filterElement: (
          <DateTimeRangePickerFilter columnId={COLUMN.chargeEndTime.id} />
        ),
      },
    }),
    columnHelper.accessor('lastUpdateTime', {
      ...COLUMN.lastUpdateTime,
      cell: (props) => {
        const date = props.getValue() || '';

        return date ? showTimeString(date, FORMAT_TO_SECONDS) : '';
      },
      meta: {
        filterElement: (
          <DateTimeRangePickerFilter columnId={COLUMN.lastUpdateTime.id} />
        ),
      },
    }),
    columnHelper.accessor('userBrowseName', {
      ...COLUMN.userBrowseName,
      cell: (props) => {
        const { userGroupId } = props.row.original;

        if (hasPersonReadRight) {
          return (
            <Link
              to={`${PERSON_ROUTES.PERSONS_LIST}/${
                userGroupId !== 0 ? userGroupId : null
              }/${props.getValue()}`}
            >
              {props.getValue()}
            </Link>
          );
        }

        return props.getValue();
      },
      meta: {
        filterElement: <InputFilter column={COLUMN.userBrowseName} />,
      },
    }),
    columnHelper.accessor('operation', {
      ...COLUMN.calculatedConsume,
      cell: (props) => {
        const calculatedConsume = (props.row.original.operation
          ?.calculatedConsume ?? '') as number | '';

        const energyActiveImportRegister = (props.row.original.session
          ?.energyActiveImportRegister ?? '') as number | '';
        const meterStart = (props.row.original.session?.meterStart ?? '') as
          | number
          | '';

        if (calculatedConsume !== '') return toConsumeUnit(calculatedConsume);

        if (energyActiveImportRegister === '' || meterStart === '') return '';

        if (energyActiveImportRegister === 0) return 0;

        return toConsumeUnit(energyActiveImportRegister - meterStart);
      },
      meta: {
        filterElement: (
          <InputFilter column={COLUMN.calculatedConsume} disabled />
        ),
      },
    }),
    columnHelper.accessor('operation', {
      ...COLUMN.calculatedPayment,
      cell: (props) => {
        const calculatedPayment = (props.row.original.operation
          ?.calculatedPayment ?? '') as number | '';
        const calculatedBonus = (props.row.original.operation
          ?.calculatedBonus ?? '') as number | '';

        let value;

        if (calculatedPayment === '' && calculatedBonus === '') {
          value = '';
        } else if (calculatedPayment !== '' && calculatedBonus === '') {
          value = toCurrencyUnit(calculatedPayment);
        } else if (calculatedPayment === '' && calculatedBonus !== '') {
          value = toCurrencyUnit(calculatedBonus);
        } else {
          value = toCurrencyUnit(calculatedPayment + calculatedBonus);
        }

        // Какая-нить провека для старых сессий без этих данных?

        if (props.row.original.calculationResult) {
          return (
            <ClickableSpan
              onClick={(e) => {
                showTariffDetailsModal(props.row.original);
              }}
            >
              {value}
            </ClickableSpan>
          );
        }

        return value;
      },
      meta: {
        filterElement: (
          <InputFilter column={COLUMN.calculatedPayment} disabled />
        ),
      },
    }),
    columnHelper.accessor('statusHR', {
      ...COLUMN.statusHR,
      meta: {
        filterElement: <InputFilter column={COLUMN.statusHR} />,
      },
    }),
    columnHelper.accessor('session', {
      ...COLUMN.startSoc,
      cell: (props) => props.row.original.session?.startSoc || '',
      meta: {
        filterElement: <InputFilter column={COLUMN.startSoc} disabled />,
      },
    }),
    columnHelper.accessor('session', {
      ...COLUMN.soc,
      cell: (props) => props.row.original.session?.soc || '',
      meta: {
        filterElement: <InputFilter column={COLUMN.soc} disabled />,
      },
    }),
    columnHelper.accessor('session', {
      ...COLUMN.powerActiveImport,
      cell: (props) => {
        const powerActiveImport =
          props.row.original.session?.powerActiveImport ?? '';

        if (powerActiveImport === '') return '';

        return toConsumeUnit(powerActiveImport);
      },
      meta: {
        filterElement: (
          <InputFilter column={COLUMN.powerActiveImport} disabled />
        ),
      },
    }),
    columnHelper.accessor('session', {
      ...COLUMN.maxPower,
      cell: (props) => {
        const maxPower = props.row.original.session?.maxPower ?? '';

        if (maxPower === '') return '';

        return toConsumeUnit(maxPower);
      },
      meta: {
        filterElement: <InputFilter column={COLUMN.maxPower} disabled />,
      },
    }),
    columnHelper.accessor('session', {
      ...COLUMN.reason,
      cell: (props) => props.row.original.session?.reason || '',
      meta: {
        filterElement: <InputFilter column={COLUMN.reason} />,
      },
    }),
    columnHelper.accessor('session', {
      ...COLUMN.errorCode,
      cell: (props) => props.row.original.session?.errorCode || '',
      meta: {
        filterElement: <InputFilter column={COLUMN.errorCode} />,
      },
    }),
    columnHelper.accessor('operation', {
      ...COLUMN.connectorId,
      cell: (props) => {
        const { operation } = props.row.original;

        if (!operation) {
          return '';
        }

        const { connectorId, connectorName } = operation;

        const connectorIdDefined =
          connectorId !== null && connectorId !== undefined;
        const connectorNameDefined =
          connectorName !== null && connectorName !== undefined;

        if (!connectorNameDefined) return '-';

        if (connectorNameDefined) {
          if (connectorIdDefined) {
            return `${connectorName} (${connectorId})`;
          } else {
            return connectorName;
          }
        }
      },
      meta: {
        // Cортировка работает по connectorId, а фильтрация по connectorName
        filterElement: (
          <InputFilter
            column={{
              ...COLUMN.connectorId,
              queryParamName: 'operation.connectorName',
            }}
          />
        ),
      },
    }),
  ];

  const settingsColumn = useTableSettings({
    columnHelper,
    columns: Object.values(COLUMN).map((el) => {
      return {
        key: el.id,
        label: el.header,
        isChecked: settings[el.id],
      };
    }),
    settings,
    renderCell: (props) => {
      const session = props.row.original;

      return <SessionTableActions session={session} />;
    },
    setVisibleColumns: (cols: TableColumnsState) => {
      dispatch(setVisibleColumns(cols));
    },
  });

  const visibleColumns = useMemo(() => {
    const visibleDataColumns = settings
      ? dataColumns.filter((el) => settings[el.id])
      : dataColumns;

    return [...visibleDataColumns, settingsColumn];
  }, [settings]);

  return visibleColumns;
};
