import { Col, Form, FormProps, Select, Upload } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
import { UploadOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

import {
  formHasErrors,
  isFileLessThan,
  maxFileSizeValidationRule,
  normFile,
  VALIDATION_RULE,
} from 'shared/lib';
import { UiInput, UiSelect } from 'shared/ui/ui-kit';
import { FIRMWARE_ROUTES } from 'shared/consts/routes/firmware';
import { UploadedFile } from 'shared/ui/uploaded-file';

import {
  checkControllerRight,
  selectTokenClaims,
  selectTokenRoles,
} from 'entities/authorization';
import { FirmwareFormData, FORMAT_URL } from 'entities/firmware';

import {
  CardFormWrapper,
  FormItem,
  FormRow,
  StyledButton,
  StyledDraggerFormItem,
} from '../../../../common-styles';

import { FormButtons } from '../../../../components/FormButtons';
import { FIRMWARE_FILE_SIZE_ERROR, MAX_FIRMWARE_FILE_SIZE } from '../../consts';

const getFileValidationRules = (allowEmpty: boolean) => {
  const commonRules = [maxFileSizeValidationRule(MAX_FIRMWARE_FILE_SIZE)];

  if (allowEmpty) {
    return commonRules;
  }

  return [VALIDATION_RULE.REQUIRED, ...commonRules];
};

const { Option } = Select;

type Props = {
  title: string;
  loading: boolean;
  initialValues: FirmwareFormData;
  handleSubmit: (values: FirmwareFormData) => void;
  disabled?: boolean;
  allowEmptyFile?: boolean;
  onDeleteClick?: () => void;
};

export function FirmwareForm({
  title,
  handleSubmit,
  initialValues,
  loading,
  disabled = false,
  allowEmptyFile = false,
  onDeleteClick,
}: Props) {
  const roles = useSelector(selectTokenRoles);
  const claims = useSelector(selectTokenClaims);

  const hasWriteRight = checkControllerRight(
    'Firmware',
    'Write',
    roles,
    claims
  );

  const [isFormDisabled, setIsFormDisabled] = useState(disabled);

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [hasErrors, setHasErrors] = useState(false);

  const [form] = Form.useForm<FirmwareFormData>();

  const deleteFile = async (uid: string) => {
    const files = form.getFieldValue('files') as UploadFile<any>[];

    const updatedFiles = files.filter((file) => file.uid !== uid);

    form.setFieldValue('files', updatedFiles);

    form.validateFields(['files']);

    setHasErrors(formHasErrors(form));

    if (!allowEmptyFile && updatedFiles.length === 0) {
      setIsSubmitDisabled(true);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  const onValuesChange: FormProps<FirmwareFormData>['onValuesChange'] = (
    _changedValues,
    allValues
  ) => {
    if (
      (allowEmptyFile ? true : allValues.files.length !== 0) &&
      allValues.formatUrl !== '' &&
      allValues.description !== ''
    ) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  const onFieldsChange: FormProps<FirmwareFormData>['onFieldsChange'] = (
    _,
    _allFields
  ) => {
    setHasErrors(formHasErrors(form));
  };

  const props: UploadProps = {
    maxCount: 1,
    multiple: false,
    beforeUpload: (file, list) => {
      return false;
    },
    itemRender: (originNode, file, fileList) => {
      let errors: string[] = [];

      if (file.size && !isFileLessThan(file.size, MAX_FIRMWARE_FILE_SIZE)) {
        errors = [...errors, FIRMWARE_FILE_SIZE_ERROR];
      }

      return (
        <UploadedFile
          fileName={file.name}
          errors={errors}
          handleDelete={() => deleteFile(file.uid)}
        />
      );
    },
  };

  return (
    <CardFormWrapper title={title}>
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues}
        onFinish={handleSubmit}
        onValuesChange={onValuesChange}
        onFieldsChange={onFieldsChange}
      >
        <FormRow>
          <Col span={24}>
            <FormItem label="ID" name="id">
              <UiInput disabled />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow>
          <Col span={24}>
            <FormItem
              label="Название"
              name="description"
              rules={[VALIDATION_RULE.REQUIRED]}
            >
              <UiInput disabled={isFormDisabled} />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow>
          <Col span={24}>
            <FormItem
              label="formatUrl"
              name="formatUrl"
              rules={[VALIDATION_RULE.REQUIRED]}
            >
              <UiSelect disabled={isFormDisabled}>
                {Object.entries(FORMAT_URL).map((entry) => {
                  const [key, label] = entry;

                  return (
                    <Option value={key} key={key}>
                      {label}
                    </Option>
                  );
                })}
              </UiSelect>
            </FormItem>
          </Col>
        </FormRow>
        <FormRow>
          <Col span={24}>
            <StyledDraggerFormItem>
              <FormItem
                label="Файл прошивки"
                name="files"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={getFileValidationRules(allowEmptyFile)}
              >
                <Upload.Dragger {...props}>
                  <StyledButton
                    htmlType="button"
                    type="primary"
                    icon={<UploadOutlined />}
                    style={{ margin: '0 auto' }}
                    disabled={isFormDisabled}
                  >
                    Выбрать файл
                  </StyledButton>
                </Upload.Dragger>
              </FormItem>
            </StyledDraggerFormItem>
          </Col>
        </FormRow>
        {hasWriteRight ? (
          <FormButtons
            spinning={loading}
            isFormDisabled={isFormDisabled}
            isSubmitDisabled={isSubmitDisabled || hasErrors}
            onEditClick={() => setIsFormDisabled(false)}
            onCancelClick={() => navigate(FIRMWARE_ROUTES.FIRMWARES)}
            onDeleteClick={onDeleteClick}
          />
        ) : null}
      </Form>
    </CardFormWrapper>
  );
}
