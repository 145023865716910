export function CarModelsIcon() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM10.7627 19.9049C10.1039 18.2879 8.22077 13.9069 7 13.5C6.14617 13.2154 4.96833 13.2548 4.112 13.3416C4.68235 16.7196 7.37002 19.3781 10.7627 19.9049ZM4.32945 9.72049C5.31094 6.41274 8.37371 4 12 4C15.6263 4 18.6891 6.41274 19.6706 9.72049C18.0917 9.42464 15.2582 9 12 9C8.74181 9 5.90825 9.42464 4.32945 9.72049ZM19.888 13.3416C19.0317 13.2548 17.8538 13.2154 17 13.5C15.7792 13.9069 13.8961 18.2879 13.2373 19.9049C16.63 19.3781 19.3176 16.7196 19.888 13.3416Z"
        fill="black"
      />
    </svg>
  );
}
