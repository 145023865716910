import { useParams } from 'react-router-dom';

import { ConnectedTransactionLogTable } from 'features/person/transactions-table';

export function PersonTransactionLogPage() {
  const { userLogin, groupId } = useParams() as {
    groupId: string;
    userLogin: string;
  };

  return <ConnectedTransactionLogTable userLogin={userLogin} />;
}
