import { useParams } from 'react-router-dom';

import { useChargePointFetchingContext } from 'entities/charge-point/charge-point-layout';

import { ConnectedSessionLogTable } from '../../../widgets/session/sessions-table/ui/connected-table';

export function ChargePointSessionLogPage() {
  const { chargePointName, groupId } = useParams() as {
    chargePointName: string;
    groupId: string;
  };

  const { handleSetFetchingTab } = useChargePointFetchingContext();

  return (
    <ConnectedSessionLogTable
      handleSetFetchingTab={handleSetFetchingTab}
      chargePointName={chargePointName}
    />
  );
}
