import { BONUS_SERVICE } from 'entities/authorization';
import { baseApi } from 'shared/api';

import { getEndpointPath } from 'shared/lib';

import { apiSlice } from '../../../redux/services/apiSlice';

import { BonusProgramLevel } from '../model/levels';
import {
  AddBonusLevelRequest,
  UpdateBonusLevelRequest,
} from '../model/level-api';

const KEY = 'BonusLevels';

const getUrl = getEndpointPath(BONUS_SERVICE, KEY);

// TODO: замена на baseApi

export const bonusLevelsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllBonusProgramLevels: builder.query<Array<BonusProgramLevel>, string>({
      query: (bonusProgramId) => ({
        url: getUrl(`GetAllByBonusProgramId`),
        params: { bonusProgramId },
      }),
      providesTags: (result, error, arg) => [{ type: 'BonusLevels', id: arg }],
    }),
    addBonusLevel: builder.mutation<BonusProgramLevel, AddBonusLevelRequest>({
      query: (body) => ({
        url: getUrl('Add'),
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'BonusLevels', id: arg.bonusProgramId },
      ],
    }),
    updateBonusLevel: builder.mutation<void, UpdateBonusLevelRequest>({
      query: (body) => ({
        url: getUrl('Update'),
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'BonusLevels', id: arg.bonusProgramId },
      ],
    }),
    deleteBonusLevel: builder.mutation<void, string>({
      query: (id) => ({
        url: getUrl(`DeleteById/${id}`),
        method: 'DELETE',
      }),
      // Нет аргумента
      // invalidatesTags: (result, error, arg) => [
      //   { type: 'BonusLevels', id: arg.bonusProgramId },
      // ],
    }),
  }),
});

export const {
  useGetAllBonusProgramLevelsQuery,
  useAddBonusLevelMutation,
  useUpdateBonusLevelMutation,
} = bonusLevelsApi;
