import { MongoDebitReportDto } from 'entities/report';

import { DebitReportForm } from 'features/report/debit/form';
import { DebitReportTable } from 'features/report/debit/table';

type Props = {
  allowReportFetching: () => void;
  debits: MongoDebitReportDto[];
  loading: boolean;
};

export function DebitReport({ allowReportFetching, debits, loading }: Props) {
  return (
    <>
      <DebitReportForm
        allowReportFetching={allowReportFetching}
        loading={loading}
      />
      <DebitReportTable debits={debits} loading={loading} />
    </>
  );
}
