import styled from 'styled-components';

import { COLOR } from 'shared/consts';

export const GradientDashboardCard = styled.div`
  border-radius: 10px;
  background-color: ${COLOR.white};
  padding: 15px;
  position: relative;
`;

export const Gradient = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 100%;
`;

export const DashboardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
`;

export const DashboardTitle = styled.h3`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${COLOR.black};
`;

export const DashboardContent = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
`;

export const DashboardValue = styled.p`
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  color: ${COLOR.black};
  margin-right: 3px;
`;

export const DashboardTotalValue = styled.span`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: ${COLOR.lightGrey};
  margin-top: 6px;
`;

export const DashboardSuffix = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR.darkGrey};
`;

// export const ErrorWrapper = styled.div`
//   & .ant-result.ant-result-error {
//     padding: 5px;

//     & .anticon.anticon-close-circle {
//       font-size: 48px;
//     }

//     & .ant-result-icon {
//       margin-bottom: 12px;
//     }
//   }
// `;
