import styled from 'styled-components';

import { COLOR } from 'shared/consts';
import { DayjsDatePicker } from 'shared/ui';

const { RangePicker } = DayjsDatePicker;

export const StyledRangePicker = styled(RangePicker)`
  border: none;
  width: 220px;
  padding: 0;

  &.ant-picker-focused {
    box-shadow: none;
  }

  & .ant-picker-input > input {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: rgba(153, 153, 153, 1);
  }

  & .ant-picker-active-bar {
    display: none;
  }

  & .ant-picker-clear {
    right: 0;
  }
`;

export const StyledDateTimeRangePicker = styled(StyledRangePicker)`
  display: flex;
  flex-direction: column;
  width: auto;
  border: 1px solid ${COLOR.inputGrey};

  & .ant-picker-suffix {
    display: none;
  }

  & .ant-picker-clear {
    right: 3px;
  }
`;
