import { getEndpointPath } from 'shared/lib';

import { PLATFORM_SERVICE } from 'entities/authorization';
import { SessionsBaseResponse } from 'entities/session/api/types';

import { ClientBindingBaseResponse, GetClientBankCardSessions } from './types';

import { apiSlice } from '../../../redux/services/apiSlice';

const KEY = 'Person';

const getUrl = getEndpointPath(PLATFORM_SERVICE, KEY);

export const api = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getClientBindings: builder.query<ClientBindingBaseResponse, string>({
      query: (phone) => ({
        url: getUrl(`GetClientBindings`),
        params: {
          phone,
        },
      }),
      providesTags: (result, error, arg) => [
        { type: 'ClientBindings', id: arg },
      ],
    }),
    getClientBankCardSessions: builder.query<
      SessionsBaseResponse,
      GetClientBankCardSessions
    >({
      query: (params) => ({
        url: getUrl(`GetClientBankCardSessions`),
        params: {
          ...params,
          groupId: params.groupId || 0,
        },
      }),
      providesTags: ['BankCardSessions'],
    }),
  }),
});

export const { useGetClientBindingsQuery, useGetClientBankCardSessionsQuery } =
  api;
