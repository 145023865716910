import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { UiModal } from 'shared/ui';

import { MongoSessionDTO } from 'entities/session';

import { TariffDetails } from '../tariff-details';

type Props = {
  session: MongoSessionDTO;
};

export const TariffDetailsModal = NiceModal.create((props: Props) => {
  const modal = useModal();

  return (
    <UiModal
      title="Информация о детализации"
      visible={modal.visible}
      onCancel={modal.hide}
      afterClose={modal.remove}
    >
      <TariffDetails {...props} closeModal={modal.hide} />
    </UiModal>
  );
});
