import { getCoreRowModel, Row, useReactTable } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { JsonDataViewer, ServerTableLayout } from 'shared/ui';

import { ChargePointDTO } from 'entities/charge-point';
import { Packet } from 'entities/private-methods';

import { useColumns } from '../hooks/use-columns';

import {
  usePaginationState,
  useFiltersState,
  useSortingState,
} from 'shared/ui/table/hooks';

type Props = {
  tableData: Packet[];
  loading: boolean;
  count: number | undefined;
  tablePageSize: number;
  tableHeadRef: (element: HTMLElement | SVGElement | null) => void;
  connectors: ChargePointDTO['connectors'];
};

export function EventsTableInstance({
  tableData,
  loading,
  count,
  tableHeadRef,
  tablePageSize,
  connectors,
}: Props) {
  const [eventToShowInJSON, setEventToShowInJSON] = useState('');

  const [searchParams, setSearchParams] = useSearchParams();

  const columns = useColumns({ connectors });
  const sorting = useSortingState();
  const pagination = usePaginationState();
  const filters = useFiltersState();

  //
  const limit = searchParams.get('limit');

  const data = useMemo(() => tableData, [tableData]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnFilters: filters,
      pagination,
    },
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount: count
      ? Math.ceil(count / (limit ? Number(limit) : tablePageSize))
      : undefined,
  });

  const handleRowDoubleClick = (row: Row<Packet>) => {
    setEventToShowInJSON(row.original._id);
  };

  if (eventToShowInJSON) {
    const eventJSON = tableData.find(
      (session) => session._id === eventToShowInJSON
    ) as Packet;

    // Временный костыль
    const updatedJSON = eventJSON.data.payload
      ? {
          ...eventJSON,
          data: {
            ...eventJSON.data,
            payload: JSON.parse(eventJSON.data.payload),
          },
        }
      : eventJSON;

    return (
      <JsonDataViewer
        data={updatedJSON}
        onClick={() => setEventToShowInJSON('')}
      />
    );
  }

  return (
    <ServerTableLayout
      table={table}
      loading={loading}
      pageSize={tablePageSize}
      tableHeadRef={tableHeadRef}
      handleRowDoubleClick={handleRowDoubleClick}
      rowsCount={count}
    />
  );
}
