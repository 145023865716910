import { FormInstance } from 'antd';
import { useWatch } from 'antd/lib/form/Form';
import { CarFormData } from 'entities/car/model';
import { useEffect } from 'react';

export const useConnectorAdapterFormItems = (
  form: FormInstance<CarFormData>,
  connectorType: string
) => {
  const adapterFormItemName = `${connectorType}/adapter`;

  const connectorFormField = useWatch(connectorType, form);
  const adapterFormField = useWatch(adapterFormItemName, form);

  useEffect(() => {
    if (connectorFormField === true) {
      form.setFieldValue(adapterFormItemName, false);
    }
  }, [connectorFormField]);

  useEffect(() => {
    if (adapterFormField === true) {
      form.setFieldValue(connectorType, false);
    }
  }, [adapterFormField]);

  return [connectorFormField, adapterFormField];
};
