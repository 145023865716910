import { Col, Row } from 'antd';

import { toCurrencyUnit } from 'shared/lib';
import { UiInput } from 'shared/ui/ui-kit';

import { BankListItem } from 'entities/bank';
import { BonusProgramAchievementListItem } from 'entities/bonus-program';

import { UpdateBonusesButton } from 'features/bonus/update-balance';

import { BonusProgramAchievements } from '../bonus-program-achievements';

import { InputLabel } from '../../../../common-styles';
import {
  BonusInfoCurrencyContainer,
  NoBonusesContainer,
  NoBonusesText,
} from './styles';

type Props = {
  personId: string;
  banks: Array<BankListItem>;
  achievement?: BonusProgramAchievementListItem;
  bank?: BankListItem;
  bonusSum?: number;
};

export function BonusSummaryByCurrency({
  achievement,
  bank,
  personId,
  banks,
  bonusSum = 0,
}: Props) {
  const updateBonusesButton = (
    <UpdateBonusesButton
      banks={banks}
      personId={personId}
      bankId={bank?.bankId}
    />
  );

  let content;

  if (!achievement || !bank) {
    content = (
      <Row gutter={10}>
        <NoBonusesContainer>
          <Col span={12}>
            <NoBonusesText>Бонусные баллы отсуствуют</NoBonusesText>
          </Col>
          {updateBonusesButton && <Col span={12}>{updateBonusesButton}</Col>}
        </NoBonusesContainer>
      </Row>
    );
  } else {
    const { currencyText, currencySymbol } = bank.country;

    const { currentSum } = achievement;

    content = (
      <>
        <Row gutter={10}>
          <Col span={12}>
            <InputLabel>{`Бонусов на счете (${currencyText})`}</InputLabel>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={12}>
            <UiInput value={toCurrencyUnit(bonusSum)} disabled />
          </Col>
          {updateBonusesButton && <Col span={12}>{updateBonusesButton}</Col>}
        </Row>

        {achievement ? (
          <Row gutter={10}>
            <Col span={12}>
              <BonusProgramAchievements
                achievement={achievement}
                currencySymbol={currencySymbol}
              />
            </Col>
          </Row>
        ) : null}
      </>
    );
  }

  return <BonusInfoCurrencyContainer>{content}</BonusInfoCurrencyContainer>;
}
