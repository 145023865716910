import { GradientDashboard } from '../GradientDashboard';
import { BlueGradient, RedGradient, GreenGradient } from '../assets';
import { ProblemsDashboard } from '../ProblemsDashboard';
import { Metric, MetricName } from '../../../models/metric';

export const METRICS_POLLING_INTERVAL = 60000;

const EMPTY_METRIC = {
  count: 0,
  totalCount: 0,
  value: 0,
};

export type MetricsDashboard =
  | 'onlinecp'
  | 'onlineport'
  | 'evcharge'
  | 'problems';

export const METRICS_DASHBOARD: Record<
  MetricsDashboard,
  {
    names: Array<MetricName>;
    renderDashboard: (props: {
      loading: boolean;
      metrics: Metric[] | null;
    }) => JSX.Element;
  }
> = {
  onlinecp: {
    names: ['onlinecp'],
    renderDashboard: ({ loading, metrics }) => {
      const data = metrics
        ? metrics.find((metric) => metric.name === 'onlinecp') || EMPTY_METRIC
        : EMPTY_METRIC;

      const value = data.count;
      const totalValue = data.totalCount;

      return (
        <GradientDashboard
          title="Станции"
          suffix="станций в сети"
          gradient={GreenGradient}
          loading={loading}
          value={value}
          totalValue={totalValue}
          key="onlinecp"
        />
      );
    },
  },
  onlineport: {
    names: ['onlineport'],
    renderDashboard: ({ loading, metrics }) => {
      const data = metrics
        ? metrics.find((metric) => metric.name === 'onlineport') || EMPTY_METRIC
        : EMPTY_METRIC;

      const value = data.totalCount - data.count;
      const totalValue = data.totalCount;

      return (
        <GradientDashboard
          title="Порты"
          suffix="портов недоступно"
          gradient={RedGradient}
          loading={loading}
          value={value}
          totalValue={totalValue}
          key="onlineport"
        />
      );
    },
  },
  evcharge: {
    names: ['evcharge'],
    renderDashboard: ({ loading, metrics }) => {
      const data = metrics
        ? metrics.find((metric) => metric.name === 'evcharge') || EMPTY_METRIC
        : EMPTY_METRIC;

      const value = data.count;

      return (
        <GradientDashboard
          title="Электромобили"
          suffix="заряжается"
          gradient={BlueGradient}
          loading={loading}
          value={value}
          key="evcharge"
        />
      );
    },
  },
  problems: {
    names: ['problemcheck', 'blockedcp'],
    renderDashboard: ({ loading, metrics }) => {
      const problemCheckData = metrics
        ? metrics.find((metric) => metric.name === 'problemcheck') ||
          EMPTY_METRIC
        : EMPTY_METRIC;

      const blockedCpData = metrics
        ? metrics.find((metric) => metric.name === 'blockedcp') || EMPTY_METRIC
        : EMPTY_METRIC;

      return (
        <ProblemsDashboard
          loading={Boolean(metrics && loading)}
          blockedcp={blockedCpData.count}
          problemcheck={problemCheckData.count}
          key="problems"
        />
      );
    },
  },
};
