import { RcFile } from 'antd/es/upload/interface';

import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';

import {
  ChargePointPhotoForm,
  ChargePointPhotoFormData,
  useAddChargePointPhotoMutation,
} from 'entities/charge-point-photo';

import { ADD_ERROR, ADD_SUCCESS } from '../consts';

const initialValues: ChargePointPhotoFormData = {
  priority: '0',
  photos: [],
};

type Props = {
  chargePointName: string;
  closeModal: () => void;
};

export function AddChargePointPhoto({ chargePointName, closeModal }: Props) {
  const [trigger, { isLoading }] = useAddChargePointPhotoMutation();

  const handleSubmit = async (values: ChargePointPhotoFormData) => {
    const { priority, photos } = values;

    const formData = new FormData();

    photos.forEach((file) => {
      formData.append('filePhotos', file.originFileObj as RcFile);
    });

    try {
      const res = await trigger({
        cpName: chargePointName,
        firstPriority: Number(priority),
        filePhotos: formData,
      }).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);

        return;
      }
      //

      openSuccessNotification(ADD_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : ADD_ERROR;

      openErrorNotification(errorText);
    } finally {
      closeModal();
    }
  };

  return (
    <ChargePointPhotoForm
      multiple
      loading={isLoading}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      onCancelClick={closeModal}
    />
  );
}
