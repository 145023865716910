import { getServerErrorText } from 'shared/lib';
import { EmptyData, ErrorMessage } from 'shared/ui';

import { useGetBanksQuery } from 'entities/bank';
import { useGetPersonAchievementQuery } from 'entities/bonus-program';

import { BonusSummary } from '../bonus-summary';
import { useGetAllQuery } from 'entities/balance';

type Props = {
  personId: string;
};

export function ConnectedBonusSummary({ personId }: Props) {
  const {
    data: bonuses,
    isFetching: isBonusesLoading,
    error: bonusesError,
  } = useGetAllQuery(personId);

  const {
    data: achievements,
    isFetching: isAchievementsFetching,
    error: achievementsError,
  } = useGetPersonAchievementQuery(personId);

  const {
    data: banks,
    isFetching: isBanksFetching,
    error: banksError,
  } = useGetBanksQuery();

  if (isBonusesLoading || isAchievementsFetching || isBanksFetching) {
    return <div>Loading...</div>;
  }

  const error = [bonusesError, achievementsError, banksError].find(
    (err) => err !== undefined
  );

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!bonuses || !achievements || !banks) {
    return <EmptyData />;
  }

  return (
    <BonusSummary
      achievements={achievements.items}
      bonuses={bonuses.items}
      banks={banks.banks}
      personId={personId}
    />
  );
}
