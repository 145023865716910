export function OwnersIcon() {
  return (
    <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.20825 14.0625L14.5833 2.08331V10.9375H19.7916L10.4166 22.9166V14.0625H5.20825Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
}
