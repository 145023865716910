import { createSlice } from '@reduxjs/toolkit';

import {
  DEFAULT_MAP_CENTER,
  DEFAULT_MAP_ZOOM,
} from '../../../constants/variables';

const initialState = {
  mapZoom: DEFAULT_MAP_ZOOM,
  mapCenter: DEFAULT_MAP_CENTER,
  showCharts: true,
};

const slice = createSlice({
  name: 'monitoringPageSettings',
  initialState,
  reducers: {
    setMapZoom: (state, { payload }) => {
      return {
        ...state,
        mapZoom: payload !== undefined ? payload : DEFAULT_MAP_ZOOM,
      };
    },
    setMapCenter: (state, { payload }) => {
      return {
        ...state,
        mapCenter: payload !== undefined ? payload : DEFAULT_MAP_CENTER,
      };
    },
    setShowCharts: (state, { payload }) => {
      return {
        ...state,
        showCharts: payload,
      };
    },
  },
});

export const { setMapCenter, setMapZoom, setShowCharts } = slice.actions;

export const selectMapZoom = (state: RootState) =>
  state.monitoringPageSettings.mapZoom;
export const selectMapCenter = (state: RootState) =>
  state.monitoringPageSettings.mapCenter;
export const selectShowCharts = (state: RootState) =>
  state.monitoringPageSettings.showCharts;

export default slice.reducer;
