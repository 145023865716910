import { ButtonProps } from 'antd/lib/button';

import { UiButton } from '../ui-button';

type Props = {
  onClick: () => void;
  disabled?: boolean;
} & ButtonProps;

export function UiDefaultButton(props: Props) {
  return (
    <UiButton
      {...props}
      htmlType="button"
      type="default"
      disabled={props.disabled ?? false}
    />
  );
}
