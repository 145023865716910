import Slider from 'react-slick';
import styled from 'styled-components';

export const StyledSlider = styled(Slider)`
  cursor: pointer;

  /* the slides */
  & .slick-slide {
    width: 70px !important;
    margin: 0 5px;
  }
  /* the parent */
  & .slick-list {
    margin: 0 -5px;
  }
`;

export const PhotoContainer = styled.div`
  width: 64px;
  height: 53px;
`;

export const PhotoImage = styled.img`
  width: 64px;
  height: 53px;
  border-radius: 5px;
`;
