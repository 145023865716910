import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { getServerErrorText } from 'shared/lib';
import { ErrorMessage } from 'shared/ui';

import { useGetDebitReportQuery } from 'entities/report';

import { DebitReport } from '../debit-report';

export function ConnectedDebitReport() {
  const [skip, setSkip] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();

  const dateFrom = searchParams.get('dateFrom');
  const dateTo = searchParams.get('dateTo');

  const {
    data,
    isFetching: isSessionsLoading,
    error: dataError,
  } = useGetDebitReportQuery(
    {
      dateFrom,
      dateTo,
    },
    {
      skip,
    }
  );

  // Если нужно получать при загрузке с параметрами, а не клику
  useEffect(() => {
    if (dateFrom && dateTo) {
      allowReportFetching();
    }
  }, []);

  const allowReportFetching = () => {
    setSkip(false);
  };

  const apiResponseError = [data].find((el) => el && el.statusCode !== 0);

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusDescription} />;
  }

  const error = [dataError].find((err) => err !== undefined);

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  return (
    <DebitReport
      debits={data?.debits ?? []}
      allowReportFetching={allowReportFetching}
      loading={isSessionsLoading}
    />
  );
}
