import { Col, Form, FormProps } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useWatch } from 'antd/lib/form/Form';

import { checkMinLengthRule, formHasErrors, VALIDATION_RULE } from 'shared/lib';
import { UiFormCheckbox, UiInput, UiSelect } from 'shared/ui/ui-kit';
import {
  ButtonsContainer,
  UiSubmitButton,
  UiEditFormButton,
  UiCancelFormButton,
  FormControlsContainer,
} from 'shared/ui';

import {
  selectTokenRoles,
  selectTokenClaims,
  checkControllerRight,
} from 'entities/authorization';

import { CardFormWrapper, FormItem, FormRow } from '../../../../common-styles';
import { OwnerFormData, OwnerFormProps } from '../../model';

import { OwnerFormContainer } from './styles';
import { LEGAL_NAME_MIN_LENGTH } from '../../consts';
import { checkOwnersPhoneNumber } from 'shared/lib/validation/rules';

type FormData = OwnerFormData;

export function OwnerForm({
  handleSubmit,
  initialValues,
  loading,
  tariffs,
  title,
  disabled = false,
}: OwnerFormProps) {
  const roles = useSelector(selectTokenRoles);
  const claims = useSelector(selectTokenClaims);

  const hasWriteRight = checkControllerRight('Owner', 'Write', roles, claims);

  const [isFormDisabled, setIsFormDisabled] = useState(disabled);

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [hasErrors, setHasErrors] = useState(false);

  const [form] = Form.useForm<FormData>();

  const agentReceipt = useWatch('agentReceipt', form);

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  const onFieldsChange: FormProps<FormData>['onFieldsChange'] = (
    _,
    _allFields
  ) => {
    setHasErrors(formHasErrors(form));
  };

  const onValuesChange: FormProps<FormData>['onValuesChange'] = (
    _,
    allValues
  ) => {
    const { name, tariffId, agentReceipt, legalName, inn } = allValues;

    if (
      name !== '' &&
      tariffId !== '' &&
      (agentReceipt ? legalName !== '' && inn !== '' : true)
    ) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  const primaryButton = isFormDisabled ? (
    <UiEditFormButton onClick={() => setIsFormDisabled(false)} />
  ) : (
    <UiSubmitButton disabled={isSubmitDisabled || hasErrors} />
  );

  let formControls: JSX.Element | null = null;

  if (hasWriteRight) {
    formControls = (
      <FormControlsContainer>
        <ButtonsContainer spinning={loading}>
          {primaryButton}
          <UiCancelFormButton />
        </ButtonsContainer>
      </FormControlsContainer>
    );
  }

  return (
    <OwnerFormContainer>
      <CardFormWrapper title={title}>
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={handleSubmit}
          onFieldsChange={onFieldsChange}
          onValuesChange={onValuesChange}
          initialValues={initialValues}
        >
          <FormRow gutter={10}>
            <Col span={6}>
              <FormItem
                label="Наименование"
                name="name"
                rules={[VALIDATION_RULE.REQUIRED]}
              >
                <UiInput disabled={isFormDisabled} />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Описание" name="description">
                <UiInput disabled={isFormDisabled} />
              </FormItem>
            </Col>
            {/* <Col span={6}>
              <FormItem
                label="Название в приложении"
                name="TODO"
                rules={[VALIDATION_RULE.REQUIRED]}
              >
                <UiInput disabled={isFormDisabled} />
              </FormItem>
            </Col> */}
          </FormRow>
          <FormRow gutter={10}>
            <Col span={6}>
              <FormItem
                label="Юридическое наименование"
                name="legalName"
                rules={
                  agentReceipt
                    ? [
                        VALIDATION_RULE.REQUIRED,
                        checkMinLengthRule(LEGAL_NAME_MIN_LENGTH),
                      ]
                    : [checkMinLengthRule(LEGAL_NAME_MIN_LENGTH)]
                }
              >
                <UiInput disabled={isFormDisabled} />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                label="ИНН"
                name="inn"
                rules={
                  agentReceipt
                    ? [VALIDATION_RULE.REQUIRED, VALIDATION_RULE.INN]
                    : [VALIDATION_RULE.INN]
                }
              >
                <UiInput disabled={isFormDisabled} />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="КПП" name="kpp">
                <UiInput disabled={isFormDisabled} />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="ФИО  руководителя" name="dirName">
                <UiInput disabled={isFormDisabled} />
              </FormItem>
            </Col>
          </FormRow>
          <FormRow gutter={10}>
            <Col span={6}>
              <FormItem
                label="Телефон"
                name="phoneNumber"
                rules={[checkOwnersPhoneNumber(agentReceipt)]}
              >
                <UiInput disabled={isFormDisabled} />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="Email" name="email">
                <UiInput disabled={isFormDisabled} />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                label="Телеграм"
                name="telegram"
                rules={[VALIDATION_RULE.TELEGRAMM]}
              >
                <UiInput disabled={isFormDisabled} />
              </FormItem>
            </Col>
          </FormRow>
          <FormRow gutter={10}>
            <Col span={6}>
              <FormItem
                label="Тариф"
                name="tariffId"
                rules={[VALIDATION_RULE.REQUIRED]}
              >
                <UiSelect
                  disabled={isFormDisabled}
                  options={tariffs.map(({ id, name }) => ({
                    value: String(id),
                    label: name,
                  }))}
                />
              </FormItem>
            </Col>
            {initialValues.name ? (
              <Col span={6}>
                <FormItem
                  label="Max % оплаты бонусами"
                  name="maxOwnerBonusPay"
                  rules={[
                    VALIDATION_RULE.REQUIRED,
                    VALIDATION_RULE.NO_WHITESPACE,
                    VALIDATION_RULE.PERCENT,
                  ]}
                >
                  <UiInput disabled={isFormDisabled} />
                </FormItem>
              </Col>
            ) : null}
          </FormRow>
          <FormRow gutter={10}>
            <Col>
              <FormItem name="agentReceipt" valuePropName="checked">
                <UiFormCheckbox disabled={isFormDisabled}>
                  Печать агентских чеков
                </UiFormCheckbox>
              </FormItem>
            </Col>
            <Col>
              <FormItem name="isDiscountEnabled" valuePropName="checked">
                <UiFormCheckbox disabled={isFormDisabled}>
                  Применять скидки
                </UiFormCheckbox>
              </FormItem>
            </Col>
          </FormRow>
          {formControls}
        </Form>
      </CardFormWrapper>
    </OwnerFormContainer>
  );
}
