export function TariffsIcon() {
  return (
    <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.8333 12.5V6.16669C20.8333 5.06212 19.9378 4.16669 18.8333 4.16669H4.08325C2.97868 4.16669 2.08325 5.06212 2.08325 6.16669V16.75C2.08325 17.8546 2.97868 18.75 4.08325 18.75H12.4999"
        stroke="black"
        strokeWidth="1.5"
      />
      <rect
        x="12.5"
        y="16.6666"
        width="10.4167"
        height="4.16667"
        rx="2"
        stroke="black"
        strokeWidth="1.5"
      />
      <rect
        x="12.5"
        y="12.5"
        width="10.4167"
        height="4.16667"
        rx="2"
        stroke="black"
        strokeWidth="1.5"
      />
    </svg>
  );
}
