import { Link, useParams } from 'react-router-dom';

import {
  UserHeaderContainer,
  UserHeaderTitle,
  UserHeaderContent,
  UserPhoneNumber,
  UserProfileLinkWrapper,
} from './styles';
import { PERSON_ROUTES } from 'shared/consts/routes/person';

export function PersonHeader() {
  const { groupId, userLogin } = useParams() as {
    userLogin: string;
    groupId: string;
  };

  return (
    <UserHeaderContainer>
      <UserHeaderTitle>Пользователь</UserHeaderTitle>
      <UserHeaderContent>
        <UserPhoneNumber>{userLogin}</UserPhoneNumber>
        <UserProfileLinkWrapper>
          <Link
            to={`${PERSON_ROUTES.PERSONS_LIST}/${groupId}/${userLogin}/profile`}
          >
            Профиль пользователя
          </Link>
        </UserProfileLinkWrapper>
      </UserHeaderContent>
    </UserHeaderContainer>
  );
}
