import { Route } from 'react-router-dom';

import { OCPI_HUB, OCPI_HUBS } from 'shared/consts/routes/ocpi-hub';
import { ADD_PREFIX } from 'shared/consts/routes/common';

import { SectionLayout } from 'shared/ui/layout';

import { RequireAuth } from 'entities/authorization';
import { ConnectedOcpiHubsMenu } from 'entities/ocpi-hub';

import { AddOcpiHubPage, EditOcpiHubPage } from 'pages/ocpi-hub';

export const OcpiHubRoutes = (
  <Route element={<RequireAuth controllerName="OcpiHubs" rightType="Read" />}>
    <Route
      path={OCPI_HUBS}
      element={<SectionLayout menu={<ConnectedOcpiHubsMenu />} />}
    >
      <Route
        element={<RequireAuth controllerName="OcpiHubs" rightType="Write" />}
      >
        <Route path={ADD_PREFIX} element={<AddOcpiHubPage />} />
      </Route>
      <Route path={OCPI_HUB} element={<EditOcpiHubPage />} />
    </Route>
  </Route>
);
