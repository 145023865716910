import { useParams } from 'react-router-dom';

import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
  getServerErrorText,
  toCurrencyUnit,
} from 'shared/lib';
import { EmptyData, ErrorMessage } from 'shared/ui';

import {
  type Company,
  type CompanyFormData,
  useGetCompanyQuery,
  useUpdateCompanyMutation,
  CompanyForm,
  prepareRequestData,
} from 'entities/company';

import { EDIT_ERROR, EDIT_SUCCESS } from '../consts';

const getInitialValues = (company: Company): CompanyFormData => {
  const {
    id,
    name,
    legalName,
    login,
    clientPassword,
    email,
    contractData,
    address,
    phoneNumber,
    chargingLimitBalance,
    chargingLimit,
    inn,
    kpp,
  } = company;

  return {
    id: String(id),
    name,
    legalName,
    login,
    clientPassword,
    email,
    contractData,
    address,
    phoneNumber,
    chargingLimitBalance: String(toCurrencyUnit(chargingLimitBalance)),
    chargingLimit: String(toCurrencyUnit(chargingLimit)),
    inn,
    kpp,
  };
};

export function EditCompany() {
  const [trigger, { isLoading: isMutationLoading }] =
    useUpdateCompanyMutation();

  const { companyId } = useParams() as { companyId: string };

  const { isFetching, error, data } = useGetCompanyQuery(companyId);

  const handleSubmit = async (values: CompanyFormData) => {
    const req = prepareRequestData(values);

    try {
      const res = await trigger(req).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);
        return;
      }
      //

      openSuccessNotification(EDIT_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : EDIT_ERROR;

      openErrorNotification(errorText);
    }
  };

  if (isFetching) {
    return <div>Loading...</div>;
  }

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!data?.company) {
    return <EmptyData />;
  }

  const initialValues = getInitialValues(data.company);

  return (
    <CompanyForm
      handleSubmit={handleSubmit}
      loading={isMutationLoading}
      initialValues={initialValues}
      title={data.company.name}
      disabled
    />
  );
}
