import styled from 'styled-components';

import { COLOR, DEFAULT_BORDER } from 'shared/consts';

export const StyledCard = styled.article`
  background: ${COLOR.white};
  border-radius: 8px;
`;

export const CardTitle = styled.h4`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${COLOR.black};
  padding: 20px;
  border-bottom: ${DEFAULT_BORDER};
`;

export const CardBody = styled.div``;

export const DeviceErrorText = styled.p`
  font-size: 12px;
  font-weight: 400;

  &:nth-child(even) {
    background-color: ${COLOR.tableEvenRow};
  }
`;
