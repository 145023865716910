import {
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import React, { useMemo, useState } from 'react';
import useMeasure from 'react-use-measure';

import { ClientTableLayout, JsonDataViewer } from 'shared/ui';
import { useClientPagination, useTablePageSize } from 'shared/lib';

import { ClientBindingsTableDataItem } from 'entities/person';
import { BankListItem } from 'entities/bank';

import { useColumns } from '../hooks/use-columns';

type Props = {
  tableData: ClientBindingsTableDataItem[];
  loading: boolean;
  banks: Array<BankListItem>;
  rowsCount: number | undefined;
};

export function ClientBindingsTableInstance({
  loading,
  tableData,
  banks,
  rowsCount,
}: Props) {
  const [tableHeadRef, tableHeadBounds] = useMeasure();

  const columns = useColumns({ banks });

  const [bindingToShowInJSON, setBindingToShowInJSON] = useState('');

  const [sorting, setSorting] = useState<SortingState>([]);
  const [tablePageSize, setTablePageSize] = useTablePageSize(tableHeadBounds);
  const [pagination, setPagination] = useClientPagination(tablePageSize);

  const data = useMemo(() => tableData, [tableData]);

  const table = useReactTable({
    data,
    columns,
    state: { sorting, pagination },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const handleRowDoubleClick = (row: Row<ClientBindingsTableDataItem>) => {
    setBindingToShowInJSON(row.original.bindingId);
  };

  if (bindingToShowInJSON) {
    const bindingJSON = tableData.find(
      (binding) => binding.bindingId === bindingToShowInJSON
    ) as ClientBindingsTableDataItem;

    return (
      <JsonDataViewer
        data={bindingJSON}
        onClick={() => setBindingToShowInJSON('')}
      />
    );
  }

  return (
    <ClientTableLayout
      table={table}
      tableHeadRef={tableHeadRef}
      handlePageSizePressEnter={setTablePageSize}
      pageSize={tablePageSize}
      loading={loading}
      handleRowDoubleClick={handleRowDoubleClick}
      rowsCount={rowsCount}
    />
  );
}

export default React.memo(ClientBindingsTableInstance);
