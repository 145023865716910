import { AddChargePointPhotoButton } from 'features/charge-point-photo/add';

import {
  AddIconContainer,
  AddIconTitle,
  CardHeader,
  CardTitle,
  StyledCard,
} from './styles';
import { ConnectedPhotosList } from '../connected-photos-list';

type Props = {
  chargePointName: string;
};

export function EditChargePointsPhotosWidget({ chargePointName }: Props) {
  return (
    <StyledCard>
      <CardHeader>
        <CardTitle>Фотографии ЭЗС</CardTitle>
        <AddIconContainer>
          <AddIconTitle>Добавить фотографии</AddIconTitle>
          <AddChargePointPhotoButton chargePointName={chargePointName} />
        </AddIconContainer>
      </CardHeader>
      <ConnectedPhotosList chargePointName={chargePointName} />
    </StyledCard>
  );
}
