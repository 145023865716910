import { EmptyData, NavMenuError } from 'shared/ui';
import { SectionMenu } from 'shared/ui/section-menu';

import { SECTION } from 'entities/navigation';
import { useGetTariffsQuery } from 'entities/tariff';
import { TARIFFS_ROUTES } from 'shared/consts/routes/tariff';

const { label, path } = SECTION['tariffs'];

export function ConnectedTariffsMenu() {
  const { data, isFetching, error } = useGetTariffsQuery();

  if (data && data.statusCode !== 0) {
    return <NavMenuError text={data.statusDescription} />;
  }

  if (error) {
    return <NavMenuError />;
  }

  if (!isFetching && !data) {
    return <EmptyData />;
  }

  const items = data?.chargeTariffs || [];

  return (
    <SectionMenu
      loading={isFetching}
      items={items}
      menuKey={path}
      absolutePath={TARIFFS_ROUTES.TARIFFS}
      label={label}
    />
  );
}
