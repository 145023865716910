import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';

import {
  CarDto,
  CarFormData,
  ConnectedCarForm,
  getConnectorListFromFormData,
  getTableData,
  useUpdateCarMutation,
} from 'entities/car';
import { CONNECTOR_TYPE } from 'entities/connector';

import { EDIT_ERROR, EDIT_SUCCESS } from '../../consts';

const getInitialValues = (car: CarDto): CarFormData => {
  const {
    connectorList,
    name,
    carModel: { id, carBrand },
  } = car;

  const connectorValues = Object.keys(CONNECTOR_TYPE).reduce((acc, el) => {
    const connector = connectorList.find(
      (con) => con.connectorType === Number(el)
    );

    if (connector) {
      acc[el] = !connector.isAdapterNeeded;
      acc[`${el}/adapter`] = connector.isAdapterNeeded;
    } else {
      acc[el] = false;
      acc[`${el}/adapter`] = false;
    }

    return acc;
  }, {} as Record<string, boolean>);

  return {
    name,
    carModelId: String(id),
    carBrandName: carBrand,
    ...connectorValues,
  };
};

type Props = {
  personLogin: string;
  closeModal: () => void;
  car: CarDto;
};

export function EditCar({ closeModal, personLogin, car }: Props) {
  const [trigger, { isLoading }] = useUpdateCarMutation();

  const handleSubmit = async (values: CarFormData) => {
    const { name, carModelId, carBrandName, ...connectorsValues } = values;

    const connectorList = getConnectorListFromFormData(connectorsValues);

    const req = {
      id: car.id,
      name,
      carModelId: Number(carModelId),
      connectorList,
      personLogin,
    };

    try {
      const res = await trigger(req).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);

        return;
      }
      //

      openSuccessNotification(EDIT_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : EDIT_ERROR;

      openErrorNotification(errorText);
    } finally {
      closeModal();
    }
  };

  const tableData = getTableData();

  const initialValues = getInitialValues(car);

  return (
    <ConnectedCarForm
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      loading={isLoading}
      tableData={tableData}
      closeModal={closeModal}
    />
  );
}
