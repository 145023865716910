import { useParams } from 'react-router-dom';

import {
  getServerErrorText,
  openErrorNotification,
  openSuccessNotification,
  isErrorWithMessage,
} from 'shared/lib';

import { ErrorMessage, EmptyData } from 'shared/ui';

import {
  ConnectorInstructionForm,
  useGetConnectorInstructionQuery,
  useUpdateConnectorInstructionMutation,
  ConnectorInstructionFormData,
  prepareRequestData,
  ConnectorInstruction,
} from 'entities/instruction';

import { EDIT_SUCCESS, EDIT_ERROR } from '../consts';

const getInitialValues = (
  instruction: ConnectorInstruction
): ConnectorInstructionFormData => {
  const { isDeleted, id, instructionLinks, ...rest } = instruction;

  return {
    ...rest,
    instructionLinks: instructionLinks ? instructionLinks.join('\n') : '',
    id: String(id),
  };
};

export function EditConnectorInstruction() {
  const [trigger, { isLoading: isMutationLoading }] =
    useUpdateConnectorInstructionMutation();

  const { connectorType } = useParams() as {
    connectorType: string;
  };

  const { isFetching, error, data } = useGetConnectorInstructionQuery({
    connectorType,
  });

  const handleSubmit = async (values: ConnectorInstructionFormData) => {
    const { id, ...req } = prepareRequestData(values);

    try {
      const res = await trigger(req).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);
        return;
      }
      //

      openSuccessNotification(EDIT_SUCCESS);

      // navigate(`${CONNECTOR_INSTRUCTIONS_PATH}/${res.connectorInstruction.connectorType}`);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : EDIT_ERROR;

      openErrorNotification(errorText);
    }
  };

  if (isFetching) {
    return <div>Loading...</div>;
  }

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!data || !data.connectorInstruction) {
    return <EmptyData />;
  }

  const initialValues = getInitialValues(data.connectorInstruction);

  return (
    <ConnectorInstructionForm
      handleSubmit={handleSubmit}
      loading={isMutationLoading}
      title={data.connectorInstruction.connectorTypeName}
      initialValues={initialValues}
      disabled
    />
  );
}
