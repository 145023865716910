import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { PLATFORM_SERVICE } from 'entities/authorization';
import { getEndpointPath } from 'shared/lib';

import {
  AddRoleClaimRequest,
  AddUserClaimRequest,
  AddUserGroupRequest,
  AddUserRequest,
  AddUserRolesRequest,
  CustomAddUserRequest,
  CustomUpdateUserRequest,
  DeleteRoleClaimRequest,
  DeleteUserClaim,
  DeleteUserGroupRequest,
  DeleteUserRoleRequest,
  GetRightsResponse,
  GetRoleClaimsResponse,
  GetRolesRightsResponse,
  GetUserResponse,
  GetUserRolesResponse,
  GetUsersResponse,
  LoginRequest,
  LoginResponse,
  SetRoleRightsRequest,
  SetUserRightsRequest,
  UpdateUserRequest,
  UpdateUserRolesRequest,
} from '../../models/identity';

import { apiSlice } from './apiSlice';

const KEY = 'Identity';

const getUrl = getEndpointPath(PLATFORM_SERVICE, KEY);

export const identityApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginRequest>({
      query: (credentials) => ({
        url: getUrl('Login'),
        method: 'POST',
        body: credentials,
      }),
    }),
    lockoutUser: builder.mutation<ResponseBase, string>({
      query: (login) => ({
        url: getUrl(`LockoutUser?login=${login}`),
        method: 'POST',
      }),
    }),
    unlockUser: builder.mutation<ResponseBase, string>({
      query: (login) => ({
        url: getUrl(`UnlockUser?login=${login}`),
        method: 'POST',
      }),
    }),
    // getUser: builder.query<GetUserResponse, string>({
    //   query: (userName) => getUrl(`GetUser?userName=${userName}`),
    //   providesTags: (result, error, arg) => [{ type: 'User', id: arg }],
    // }),
    // getUsers: builder.query<GetUsersResponse, void>({
    //   query: () => getUrl('GetUsers'),
    //   providesTags: ['Users'],
    // }),
    addUser: builder.mutation<ResponseBase, AddUserRequest>({
      query: (user) => ({
        url: getUrl(`AddUser`),
        method: 'POST',
        body: user,
      }),
      invalidatesTags: ['Users'],
    }),
    updateUser: builder.mutation<ResponseBase, UpdateUserRequest>({
      query: (user) => ({
        url: getUrl(`UpdateUser`),
        method: 'PATCH',
        body: user,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'User', id: arg.userName },
        'Users',
      ],
    }),
    deleteUser: builder.mutation<ResponseBase, string>({
      query: (userName) => ({
        url: getUrl(`DeleteUser?userName=${userName}`),
        method: 'DELETE',
      }),
      invalidatesTags: ['Users'],
    }),
    getUserRoles: builder.query<GetUserRolesResponse, string>({
      query: (userName) => getUrl(`GetUserRoles?userName=${userName}`),
      providesTags: (result, error, arg) => [{ type: 'UserRoles', id: arg }],
    }),
    addUserRoles: builder.mutation<ResponseBase, AddUserRolesRequest>({
      query: (body) => ({
        url: getUrl(`AddUserRoles`),
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'UserRoles', id: arg.userName },
      ],
    }),
    deleteUserRole: builder.mutation<ResponseBase, DeleteUserRoleRequest>({
      query: (body) => ({
        url: getUrl(`DeleteUserRole`),
        method: 'DELETE',
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'UserRoles', id: arg.userName },
      ],
    }),
    // Изолированно не используется
    addUserGroup: builder.mutation<ResponseBase, AddUserGroupRequest>({
      query: (body) => ({
        url: getUrl(`AddUserGroup`),
        method: 'POST',
        body,
      }),
      // invalidatesTags: (result, error, arg) => [
      //   { type: 'User', id: arg.userName },
      //   { type: 'IdentityGroup', id: arg.groupName },
      // ],
    }),
    // Изолированно не используется
    deleteUserGroup: builder.mutation<ResponseBase, DeleteUserGroupRequest>({
      query: (body) => ({
        url: getUrl('DeleteUserGroup'),
        method: 'DELETE',
        body,
      }),
      // invalidatesTags: (result, error, arg) => [
      //   { type: 'User', id: arg.userName },
      //   { type: 'IdentityGroup', id: arg.groupName },
      // ],
    }),
    getUserClaims: builder.query<GetRoleClaimsResponse, string>({
      query: (userName) => getUrl(`GetUserClaims?userName=${userName}`),
      providesTags: (result, error, arg) => [{ type: 'UserClaims', id: arg }],
    }),
    addUserClaim: builder.mutation<ResponseBase, AddUserClaimRequest>({
      query: (body) => ({
        url: getUrl(`AddUserClaim`),
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'UserClaims', id: arg.userName },
      ],
    }),
    deleteUserClaim: builder.mutation<ResponseBase, DeleteUserClaim>({
      query: (body) => ({
        url: getUrl(`DeleteUserClaim`),
        method: 'DELETE',
        body,
      }),
      invalidatesTags: (result, error, arg) => {
        const tags = [{ type: 'UserClaims', id: arg.userName }];

        if (arg.claimType === 'IsAllCpRights') {
          tags.push({ type: 'ChargePointRights', id: arg.userName });
        }

        return tags;
      },
    }),
    getRoleClaims: builder.query<GetRoleClaimsResponse, string>({
      query: (roleName) => getUrl(`GetRoleClaims?roleName=${roleName}`),
      providesTags: (result, error, arg) => [{ type: 'RoleClaims', id: arg }],
    }),
    addRoleClaim: builder.mutation<ResponseBase, AddRoleClaimRequest>({
      query: (roleClaim) => ({
        url: getUrl(`AddRoleClaim`),
        method: 'POST',
        body: roleClaim,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'RoleClaims', id: arg.roleName },
      ],
    }),
    getUserRights: builder.query<GetRightsResponse, string>({
      query: (userName) => getUrl(`GetUserRights?userName=${userName}`),
      providesTags: (result, error, arg) => [{ type: 'UserRights', id: arg }],
    }),
    getRoleRights: builder.query<GetRightsResponse, string>({
      query: (roleName) => getUrl(`GetRoleRights?roleName=${roleName}`),
      providesTags: (result, error, arg) => [{ type: 'RoleRights', id: arg }],
    }),
    setUserRights: builder.mutation<ResponseBase, SetUserRightsRequest>({
      query: (rights) => ({
        url: getUrl(`SetUserRights`),
        method: 'POST',
        body: rights,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'UserRights', id: arg.userName },
      ],
    }),
    setRoleRights: builder.mutation<ResponseBase, SetRoleRightsRequest>({
      query: (rights) => ({
        url: getUrl(`SetRoleRights`),
        method: 'POST',
        body: rights,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'RoleRights', id: arg.roleName },
      ],
    }),
    deleteRoleClaim: builder.mutation<ResponseBase, DeleteRoleClaimRequest>({
      query: (roleClaim) => ({
        url: getUrl(`DeleteRoleClaim`),
        method: 'DELETE',
        body: roleClaim,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'RoleClaims', id: arg.roleName },
      ],
    }),

    // Custom query
    getRolesRights: builder.query<GetRolesRightsResponse, string[]>({
      async queryFn(roles, _queryApi, _extraOptions, baseQuery) {
        const results = (await Promise.all(
          roles.map((role) =>
            baseQuery(getUrl(`GetRoleRights?roleName=${role}`))
          )
        )) as QueryReturnValue<
          GetRightsResponse,
          FetchBaseQueryError,
          unknown
        >[];

        const errors = results.filter((result) => result.error);

        if (errors.length > 0)
          return {
            error: {
              error: `Не удалось получить права для роли`,
              status: `CUSTOM_ERROR`, // Не менять
            },
          };

        const res = results.reduce((acc, item) => {
          if (!item.data || item.data.statusCode !== 0) return acc;

          item.data.rights.forEach(
            ({ controllerName, execute, read, write }) => {
              const currentObj = acc[controllerName];

              if (currentObj) {
                if (read === true) {
                  currentObj.read = true;
                }

                if (write === true) {
                  currentObj.write = true;
                }

                if (execute === true) {
                  currentObj.execute = true;
                }
              } else {
                acc[controllerName] = {
                  read: false,
                  write: false,
                  execute: false,
                };

                if (read === true) {
                  acc[controllerName].read = true;
                }

                if (write === true) {
                  acc[controllerName].write = true;
                }

                if (execute === true) {
                  acc[controllerName].execute = true;
                }
              }
            }
          );

          return acc;
        }, {} as Record<string, { read: boolean; write: boolean; execute: boolean }>);

        return { data: res };
      },
    }),
    // Custom query
    updateUserRoles: builder.mutation<ResponseBase, UpdateUserRolesRequest>({
      async queryFn(args, _queryApi, _extraOptions, baseQuery) {
        const { addRoles, deleteRoles, userName } = args;

        for (const role of deleteRoles) {
          const deleteRes = await baseQuery({
            url: getUrl(`DeleteUserRole`),
            method: 'DELETE',
            body: { userName, roleName: role },
          });

          if (deleteRes.error) {
            return {
              error: {
                error: `Не удалось удалить роли юзера`,
                status: `CUSTOM_ERROR`, // Не менять
              },
            };
          }
        }

        if (addRoles.length) {
          const addRes = await baseQuery({
            url: getUrl(`AddUserRoles`),
            method: 'POST',
            body: { userName, roles: addRoles },
          });

          if (addRes.error) {
            return {
              error: {
                error: `Не удалось добавить роли юзера`,
                status: `CUSTOM_ERROR`, // Не менять
              },
            };
          }
        }

        return { data: { statusCode: 0, statusDescription: '' } };
      },
      invalidatesTags: ['UserRoles'],
    }),
    // Custom query
    customAddUser: builder.mutation<ResponseBase, CustomAddUserRequest>({
      async queryFn(args, _queryApi, _extraOptions, baseQuery) {
        const { groupName, ...user } = args;

        const addUserRes = await baseQuery({
          url: getUrl(`AddUser`),
          method: 'POST',
          body: user,
        });

        if (addUserRes.error) {
          return {
            error: addUserRes.error as FetchBaseQueryError,
          };
        }

        const addUserResData = addUserRes.data as ResponseBase;

        if (addUserResData.statusCode !== 0) {
          return {
            error: {
              error: addUserResData.statusDescription,
              status: `CUSTOM_ERROR`,
            },
          };
        }

        const addUserGroupRes = await baseQuery({
          url: getUrl(`AddUserGroup`),
          method: 'POST',
          body: {
            userName: user.userName,
            groupName,
          },
        });

        if (addUserGroupRes.error) {
          return {
            error: addUserGroupRes.error as FetchBaseQueryError,
          };
        }

        const addUserGroupResData = addUserGroupRes.data as ResponseBase;

        if (addUserGroupResData.statusCode !== 0) {
          return {
            error: {
              error: addUserGroupResData.statusDescription,
              status: `CUSTOM_ERROR`,
            },
          };
        }

        return { data: { statusCode: 0, statusDescription: '' } };
      },
      invalidatesTags: (result, error, arg) => [
        'Users',
        { type: 'UsersGroup', id: arg.groupName },
      ],
    }),
    // Custom query
    customUpdateUser: builder.mutation<ResponseBase, CustomUpdateUserRequest>({
      async queryFn(args, _queryApi, _extraOptions, baseQuery) {
        const { oldGroupName, newGroupName, ...user } = args;

        const updateUserRes = await baseQuery({
          url: getUrl(`UpdateUser`),
          method: 'PATCH',
          body: user,
        });

        if (updateUserRes.error) {
          return {
            error: updateUserRes.error as FetchBaseQueryError,
          };
        }

        const updateUserResData = updateUserRes.data as ResponseBase;

        if (updateUserResData.statusCode !== 0) {
          return {
            error: {
              error: updateUserResData.statusDescription,
              status: `CUSTOM_ERROR`,
            },
          };
        }

        if (oldGroupName !== newGroupName) {
          if (oldGroupName) {
            const deleteUserGroupRes = await baseQuery({
              url: getUrl('DeleteUserGroup'),
              method: 'DELETE',
              body: {
                userName: user.userName,
                groupName: oldGroupName,
              },
            });

            if (deleteUserGroupRes.error) {
              return {
                error: deleteUserGroupRes.error as FetchBaseQueryError,
              };
            }

            const deleteUserGroupResData =
              deleteUserGroupRes.data as ResponseBase;

            if (deleteUserGroupResData.statusCode !== 0) {
              return {
                error: {
                  error: deleteUserGroupResData.statusDescription,
                  status: `CUSTOM_ERROR`,
                },
              };
            }
          }

          const addUserGroupRes = await baseQuery({
            url: getUrl(`AddUserGroup`),
            method: 'POST',
            body: {
              userName: user.userName,
              groupName: newGroupName,
            },
          });

          if (addUserGroupRes.error) {
            return {
              error: addUserGroupRes.error as FetchBaseQueryError,
            };
          }

          const addUserGroupResData = addUserGroupRes.data as ResponseBase;

          if (addUserGroupResData.statusCode !== 0) {
            return {
              error: {
                error: addUserGroupResData.statusDescription,
                status: `CUSTOM_ERROR`,
              },
            };
          }
        }

        return { data: { statusCode: 0, statusDescription: '' } };
      },
      invalidatesTags: (result, error, arg) => [
        'Users',
        { type: 'User', id: arg.userName },
        { type: 'UsersGroup', id: arg.oldGroupName },
        { type: 'UsersGroup', id: arg.newGroupName },
      ],
    }),
    // TODO: мб еще нужно custom query на удаление юзера (и из группы)
  }),
});

export const {
  useLoginMutation,
  // useRefreshTokensMutation,
  useLockoutUserMutation,
  useUnlockUserMutation,

  useAddUserMutation,

  useDeleteUserMutation,
  useGetUserClaimsQuery,
  useSetUserRightsMutation,
  useSetRoleRightsMutation,
  useGetUserRightsQuery,

  useGetRoleClaimsQuery,
  useGetRoleRightsQuery,
  useLazyGetRoleRightsQuery,
  useGetRolesRightsQuery,
  useGetUserRolesQuery,
  useAddUserRolesMutation,
  useDeleteUserRoleMutation,
  useUpdateUserRolesMutation,

  useDeleteUserClaimMutation,
  useCustomAddUserMutation,
  useCustomUpdateUserMutation,
} = identityApi;
