import { createSelector } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';

import { AccessToken } from '../model/token';
import { selectCurrentToken } from './selectCurrentToken';

export const selectTokenRoles = createSelector(
  [selectCurrentToken],
  (token) => {
    const { Roles } = jwt_decode(token) as AccessToken;

    if (!Roles) {
      return [];
    }

    if (!Array.isArray(Roles)) {
      return [Roles];
    }

    return Roles;
  }
);
