import { pickFromObject } from 'shared/lib';

import { AvailabilityType, ManualCommandType, ResetType } from '../model';

export const MANUAL_COMMAND_TYPE: Record<
  Exclude<ManualCommandType, 4>,
  string
> = {
  0: 'ЭЗС Завершить зарядку',
  1: 'ЭЗС Изменить доступность',
  2: 'ЭЗС Разблокировать коннектор',
  3: 'ЭЗС Перезагрузка ',
  // 4: 'ЭЗС Установить лимит зарядки',
  5: 'ЭЗС Обновить ПО',
  6: 'Отправить сессию на повторную оплату',
  7: 'Принудительно установить сессии статус "Оплачено"',
  8: 'ЭЗС Обновить конфигурацию',
  9: 'ЭЗС Загрузить логи',
};

export const SESSION_MANUAL_COMMAND_TYPE = pickFromObject(
  MANUAL_COMMAND_TYPE,
  0,
  6,
  7
);

export const CHARGE_POINT_MANUAL_COMMAND_TYPE = pickFromObject(
  MANUAL_COMMAND_TYPE,
  0,
  1,
  2,
  3,
  // 4,
  5,
  8
);

export const BASE_INFO_MANUAL_COMMAND_TYPE = pickFromObject(
  MANUAL_COMMAND_TYPE,
  3,
  // 4,
  9,
  5,
  8
);

export const CONNECTOR_MANUAL_COMMAND_TYPE = pickFromObject(
  MANUAL_COMMAND_TYPE,
  0,
  1,
  2
);

export const MANUAL_COMMAND_STATUS: Record<
  Exclude<AvailabilityType, '-1'>,
  string
> = {
  0: 'заблокировано',
  1: 'работает',
};

export const MANUAL_COMMAND_RESET_TYPE: Record<
  Exclude<ResetType, '-1'>,
  string
> = {
  0: 'soft',
  1: 'hard',
};
