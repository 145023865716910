import { Col, Form, FormProps } from 'antd';
import { useEffect, useState } from 'react';
import { useWatch } from 'antd/lib/form/Form';

import { UiCancelButton } from 'shared/ui/buttons';
import { UiInput, UiSelect, UiTextArea } from 'shared/ui/ui-kit';
import { formHasErrors, VALIDATION_RULE } from 'shared/lib';
import {
  ButtonsContainer,
  UiSubmitButton,
  FormControlsContainer,
} from 'shared/ui';

import { BankDTO, BankListItem } from 'entities/bank';

import { FormItem, FormRow } from '../../../../../common-styles';

import { BalanceFormData } from '../../model/form';
import { BALANCE_OPERATION } from '../../consts';

type FormData = BalanceFormData;

type Props = {
  initialValues: FormData;
  loading: boolean;
  handleSubmit: (values: FormData) => void;
  onCancel: () => void;
  banks: Array<BankListItem>;
};

export function BalanceForm({
  initialValues,
  loading,
  handleSubmit,
  onCancel,
  banks,
}: Props) {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [hasErrors, setHasErrors] = useState(false);

  const [form] = Form.useForm<FormData>();

  const bankId = useWatch('bankId', form);

  useEffect(() => {
    if (bankId) {
      const {
        country: { name },
      } = banks.find((bank) => String(bank.bankId) === bankId) as BankDTO;

      form.setFieldValue('countryName', name);
    }
  }, [bankId]);

  const onFieldsChange: FormProps<FormData>['onFieldsChange'] = (
    _,
    _allFields
  ) => {
    setHasErrors(formHasErrors(form));
  };

  const onValuesChange: FormProps<FormData>['onValuesChange'] = (
    _,
    allValues
  ) => {
    if (
      allValues.bonusSum !== '' &&
      allValues.bankId !== '' &&
      allValues.description !== ''
    ) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  const formControls = (
    <FormControlsContainer>
      <ButtonsContainer spinning={loading}>
        <UiSubmitButton disabled={isSubmitDisabled || hasErrors}>
          Применить
        </UiSubmitButton>
        <UiCancelButton onClick={onCancel}>Отмена</UiCancelButton>
      </ButtonsContainer>
    </FormControlsContainer>
  );

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onFinish={handleSubmit}
      onFieldsChange={onFieldsChange}
      onValuesChange={onValuesChange}
      initialValues={initialValues}
    >
      <FormRow>
        <Col span={24}>
          <FormItem
            label="Количество балов"
            name="bonusSum"
            rules={[
              VALIDATION_RULE.NULL_OR_POSITIVE_NUMBER,
              VALIDATION_RULE.NO_WHITESPACE,
              VALIDATION_RULE.REQUIRED,
            ]}
          >
            <UiInput />
          </FormItem>
        </Col>
      </FormRow>

      <FormRow>
        <Col span={24}>
          <FormItem label="Страна" name="countryName">
            <UiInput disabled />
          </FormItem>
        </Col>
      </FormRow>

      <FormRow>
        <Col span={24}>
          <FormItem
            label="Банк"
            name="bankId"
            rules={[VALIDATION_RULE.REQUIRED]}
          >
            <UiSelect
              options={banks.map(({ id, name }) => ({
                value: String(id),
                label: name,
              }))}
            />
          </FormItem>
        </Col>
      </FormRow>

      <FormRow>
        <Col span={24}>
          <FormItem label="Операция" name="operation">
            <UiSelect
              options={Object.entries(BALANCE_OPERATION).map((entry) => {
                const [key, label] = entry;

                return { value: key, label };
              })}
            />
          </FormItem>
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <FormItem
            label="Комменатрий"
            name="description"
            rules={[VALIDATION_RULE.NO_WHITESPACE, VALIDATION_RULE.REQUIRED]}
          >
            <UiTextArea autoSize />
          </FormItem>
        </Col>
      </FormRow>
      {formControls}
    </Form>
  );
}
