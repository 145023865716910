import { Checkbox, Form } from 'antd';
import styled from 'styled-components';

import { COLOR } from 'shared/consts';

const { Item } = Form;

export const TableCheckboxContainer = styled.span`
  display: inline-block;
  padding: 4px;
  border-radius: 4px;
`;

export const TableCheckboxFormItem = styled(Item)`
  margin-bottom: 0;

  & .ant-form-item-control-input {
    min-height: unset;
  }
`;

export const FormButtonsContainer = styled.div`
  margin-top: 50px;
  display: flex;
  gap: 10px;
`;

export const BrandLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const BrandLogo = styled.img`
  height: 50px;
`;

export const NoBrandLogoText = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${COLOR.black};
  padding: 5px;
`;
