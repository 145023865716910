// Костыль
export const getConnectorId = (data: string) => {
  const parsed = JSON.parse(data).ConnectorId;

  if (typeof parsed === 'string') {
    return parsed;
  }

  return parsed.$numberInt;
};
