import { ADD_PREFIX } from './common';

export const PUSH_NOTIFICATIONS = 'push-notifications';
export const PUSH_NOTIFICATION = ':pushNotificationMessageId';

export const PUSH_NOTIFICATION_ROUTES = {
  PUSH_NOTIFICATIONS: `/${PUSH_NOTIFICATIONS}`,
  PUSH_NOTIFICATION: `/${PUSH_NOTIFICATIONS}/${PUSH_NOTIFICATION}`,
  ADD_PUSH_NOTIFICATION: `/${PUSH_NOTIFICATIONS}/${ADD_PREFIX}`,
};
