import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { getServerErrorText } from 'shared/lib';
import { ErrorMessage, EmptyData } from 'shared/ui';

import { useGetUsersGroupQuery } from 'entities/users-group';

import { UsersGroupUsersTableInstance } from './table';

export function ConnectedUsersGroupUsersListTable() {
  const { groupName } = useParams() as { groupName: string };

  const { isFetching, error, data } = useGetUsersGroupQuery(groupName);

  const tableData = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.group.users;
  }, [data]);

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!isFetching && !data) {
    return <EmptyData />;
  }

  return (
    <UsersGroupUsersTableInstance
      loading={isFetching}
      tableData={tableData}
      rowsCount={data?.group.users.length}
    />
  );
}
