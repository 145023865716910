import { useSelector } from 'react-redux';

import { NavigationTab } from 'shared/model';

import {
  checkControllerRight,
  selectTokenClaims,
  selectTokenRoles,
} from 'entities/authorization';

const getVisibleTabs = (
  tabs: NavigationTab[],
  roles: string[],
  claims: ReturnType<typeof selectTokenClaims>
) => {
  return tabs.filter(({ controller, right }) => {
    return controller
      ? checkControllerRight(controller, right ?? 'Read', roles, claims)
      : true;
  });
};

const getVisibleButtons = (
  tabs: NavigationTab[],
  roles: string[],
  claims: ReturnType<typeof selectTokenClaims>,
  rootPath: string
) => {
  const activeTab =
    tabs
      .filter((tab) => tab.path !== '')
      .find((tab) => rootPath.endsWith(tab.path)) ??
    tabs.find((tab) => tab.path === '');

  if (!activeTab?.buttons) {
    return null;
  }

  return activeTab.buttons.filter(({ controller, right }) => {
    return controller
      ? checkControllerRight(controller, right ?? 'Read', roles, claims)
      : true;
  });
};

export const useVisibleNavElements = (
  tabs: NavigationTab[],
  rootPath: string
) => {
  const roles = useSelector(selectTokenRoles);
  const claims = useSelector(selectTokenClaims);

  const visibleTabs = getVisibleTabs(tabs, roles, claims);

  const visibleButtons = getVisibleButtons(tabs, roles, claims, rootPath);

  return { visibleTabs, visibleButtons };
};
