import styled from 'styled-components';
import { Button } from 'antd';

export const StyledNavigationTabButton = styled(Button)`
  border-radius: 24px;
  padding: 5px;
  display: flex;
  align-items: center;

  & img {
    margin-right: 10px;
  }

  & span {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }
`;
