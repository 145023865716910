export function CafeIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2333 14H12.7682C17.1101 14 20.0145 9.53089 18.251 5.56317L16.9314 2.59386C16.7709 2.23273 16.4127 2 16.0175 2L7.98395 2C7.58877 2 7.23064 2.23273 7.07014 2.59386L5.75045 5.56317C3.98702 9.53089 6.89137 14 11.2333 14Z"
        stroke="#28303F"
        stroke-width="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M12 22V14M12 22H15M12 22H9"
        stroke="#28303F"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
