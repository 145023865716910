import dayjs from 'dayjs';

import {
  LineChartColorParams,
  LineChartDataType,
} from 'shared/ui/charts/line/types';

import { METRIC_RESPONSE_DATE_FORMAT } from '../consts';
import { GraphMetric } from '../model/metrics';

export const getMetricsLineChartData = (
  data: GraphMetric[],
  colorParams: LineChartColorParams
): LineChartDataType => {
  return {
    datasets: [
      {
        label: '',
        data: data.map(({ name, value }) => ({
          x: dayjs(name, METRIC_RESPONSE_DATE_FORMAT).toISOString(),
          y: value,
        })),
        tension: 0.5,
        fill: true,
        ...colorParams,
      },
    ],
  };
};
