import { Row } from '@tanstack/react-table';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  isErrorWithMessage,
  getServerErrorText,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';

import { GroupDto } from 'entities/group';
import {
  RoleCpRightDto,
  RoleGroupCpRightDto,
  useSetRoleChargePointRightsMutation,
  useSetRoleGroupChargePointRightsMutation,
} from 'entities/charge-point-rights';

import { useLazyGetChargePointsQuery } from '../../../../../redux/services/charge-points';
import { ChargePointRightsForm } from '../../../components/ChargePointRightsForm';
import {
  getFormRightsArrays,
  getGroupsRightsTableData,
  getInitialValues,
  recursivelyUpdateTable,
} from '../../../helpers';
import { ChargePointRightTableItem } from '../../../types';
import { getEditRightsError, getEditRightsSuccess } from '../../constants';

type Props = {
  isAllCpRights: boolean;
  chargePointRights: RoleCpRightDto[];
  groupChargePointRights: RoleGroupCpRightDto[];
  groups: GroupDto[];
};

export function ConnectedRoleChargePointRightsForm({
  isAllCpRights,
  chargePointRights,
  groupChargePointRights,
  groups,
}: Props) {
  const [tableData, setTableData] = useState<ChargePointRightTableItem[]>([]);

  const [isRowLoading, setIsRowLoading] = useState({});
  const [isRowLoaded, setIsRowLoaded] = useState({});

  const [allChecked, setAllChecked] = useState(false);

  const { roleName } = useParams() as { roleName: string };

  const onAllCheckedChange = (e: CheckboxChangeEvent) => {
    setAllChecked(e.target.checked);
  };

  const [rightsTrigger, { isLoading: isRightsMutationLoading }] =
    useSetRoleChargePointRightsMutation();

  const [groupRightsTrigger, { isLoading: isGroupRightsMutationLoading }] =
    useSetRoleGroupChargePointRightsMutation();

  const [getChargePointsTrigger] = useLazyGetChargePointsQuery();

  const navigate = useNavigate();

  useEffect(() => {
    setAllChecked(isAllCpRights);
  }, [isAllCpRights]);

  useEffect(() => {
    const tableData = getGroupsRightsTableData(groups, groupChargePointRights);

    setTableData(tableData);
  }, [groups, groupChargePointRights]);

  const onClickRow = async (row: Row<ChargePointRightTableItem>) => {
    setIsRowLoading({ [row.id]: true });

    const { data, error } = await getChargePointsTrigger({
      groupId: row.original.id,
      limit: -1,
    });

    if (error) {
      setIsRowLoading({ [row.id]: false });
      return openErrorNotification(getServerErrorText(error));
    }

    if (data && data.statusCode !== 0) {
      setIsRowLoading({ [row.id]: false });
      return openErrorNotification(data.statusDescription);
    }

    if (data) {
      const preparedData = data.chargePoints.map(({ id, name }) => {
        return {
          id,
          name,
          read: false,
          write: false,
          execute: false,
        };
      });

      const updatedTableData = recursivelyUpdateTable({
        tableData,
        childData: preparedData,
        id: row.id,
      });

      setTableData(updatedTableData);
    }

    setIsRowLoading({ [row.id]: false });
    setIsRowLoaded((curr) => ({ ...curr, [row.id]: true }));
  };

  const handleSubmit = async (
    values: Record<string, boolean>,
    allChecked: boolean
  ) => {
    const [groupFormRights, cpFormRights] = getFormRightsArrays(values);

    const groupRightsArray = Object.values(groupFormRights);
    const cpRightsArray = Object.values(cpFormRights);

    try {
      const cpReq = {
        roleName,
        isAllCpRights: allChecked,
        chargePointRights: cpRightsArray,
      };

      const cpRes = await rightsTrigger(cpReq).unwrap();

      if (cpRes.statusCode !== 0) {
        openErrorNotification(cpRes.statusDescription);
        return;
      }

      const groupReq = {
        roleName,
        groupCpRights: groupRightsArray,
      };

      const groupRes = await groupRightsTrigger(groupReq).unwrap();

      if (groupRes.statusCode !== 0) {
        openErrorNotification(groupRes.statusDescription);
        return;
      }

      const successText = getEditRightsSuccess(roleName);

      openSuccessNotification(successText);

      setIsRowLoading({});
      setIsRowLoaded({});
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : getEditRightsError(roleName);

      openErrorNotification(errorText);
    }
  };

  const initialValues = useMemo(
    () => getInitialValues(chargePointRights, groupChargePointRights),
    [chargePointRights, groupChargePointRights]
  );

  return (
    <ChargePointRightsForm
      handleSubmit={handleSubmit}
      onClickRow={onClickRow}
      onAllCheckedChange={onAllCheckedChange}
      allChecked={allChecked}
      isRowLoaded={isRowLoaded}
      isRowLoading={isRowLoading}
      initialValues={initialValues}
      tableData={tableData}
      loading={isRightsMutationLoading || isGroupRightsMutationLoading}
    />
  );
}
