import { Link } from 'react-router-dom';

import { DISCOUNTS_ROUTES } from 'shared/consts/routes/discount';
import { NavigationTabButton } from 'shared/ui';

export function AddDiscountButtonLink() {
  const path = DISCOUNTS_ROUTES.ADD_DISCOUNT;

  return (
    <Link to={path}>
      <NavigationTabButton title="Добавить скидку" />
    </Link>
  );
}
