import {
  PhotoPreview,
  PhotoItemContainer,
  PhotoInfoContainer,
  CarBrandLogoTitle,
} from './styles';

type Props = {
  photoSrc: string;
};

export function CarBrandLogo({ photoSrc }: Props) {
  return (
    <>
      <CarBrandLogoTitle>Текущий логотип</CarBrandLogoTitle>
      <PhotoItemContainer>
        <PhotoInfoContainer>
          <PhotoPreview src={photoSrc} />
        </PhotoInfoContainer>
      </PhotoItemContainer>
    </>
  );
}
