import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useNavigate } from 'react-router-dom';

import { ConfirmDialog, UiModal } from 'shared/ui';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';

import { useDeleteUsersGroupMutation } from 'entities/users-group';

import { DELETE_ERROR, DELETE_SUCCESS } from '../consts';
import { GROUP_ROUTES } from 'shared/consts/routes/group';

type Props = {
  groupName: string;
};

export const DeleteUsersGroupModal = NiceModal.create((props: Props) => {
  const modal = useModal();

  const [trigger, { isLoading }] = useDeleteUsersGroupMutation();

  const navigate = useNavigate();

  const { groupName } = props;

  const handleDelete = async () => {
    try {
      const res = await trigger(groupName).unwrap();

      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);
        return;
      }

      openSuccessNotification(DELETE_SUCCESS);

      navigate(GROUP_ROUTES.GROUPS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : DELETE_ERROR;

      openErrorNotification(errorText);
    } finally {
      modal.hide();
    }
  };

  return (
    <UiModal
      title={`Удалить группу пользователей "${groupName}"?`}
      visible={modal.visible}
      onCancel={modal.hide}
      afterClose={modal.remove}
    >
      <ConfirmDialog
        onCancel={modal.hide}
        onConfirm={() => handleDelete()}
        loading={isLoading}
      />
    </UiModal>
  );
});
