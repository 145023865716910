import { Menu } from 'antd';
import { useParams } from 'react-router-dom';

import { CustomLink } from 'shared/ui';

import { GroupDto } from 'entities/group';

type Props = {
  group: GroupDto;
};

export function PersonsGroupMenuItem({ group }: Props) {
  const { id, name } = group;

  return (
    <CustomLink to={String(id)} key={id}>
      <Menu.Item key={String(id)} eventKey={String(id)}>
        {name}
      </Menu.Item>
    </CustomLink>
  );
}
