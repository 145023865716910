import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Leaves } from 'shared/lib/types';

import { Packet } from 'entities/private-methods';

type ObjectLeaves = Leaves<Packet>;

// payload приходит как JSON и парсится
export type TableColumns =
  | Extract<ObjectLeaves, 'data.eventDateTime' | 'data.eventMessage'>
  | 'data.payload.ErrorCode'
  | 'data.payload.Status'
  | 'data.payload.ConnectorId'
  | 'data.payload.Timestamp'
  | Capitalize<Extract<ObjectLeaves, 'action'>>
  | Capitalize<Extract<ObjectLeaves, 'subsystem'>>;

export type TableColumnsState = Record<TableColumns, boolean>;

const initialState: TableColumnsState = {
  'data.eventDateTime': true,
  'data.eventMessage': true,
  'data.payload.ErrorCode': true,
  'data.payload.ConnectorId': true,
  'data.payload.Status': true,
  'data.payload.Timestamp': true,
  Action: true,
  Subsystem: true,
};

const slice = createSlice({
  name: 'eventsTable',
  initialState,
  reducers: {
    setVisibleColumns: (state, action: PayloadAction<TableColumnsState>) => {
      return action.payload;
    },
  },
});

export const { setVisibleColumns } = slice.actions;
export default slice.reducer;
