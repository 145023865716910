import { Col, Form, FormProps, Spin } from 'antd';
import React, { useState, useEffect } from 'react';
import { useWatch } from 'antd/lib/form/Form';

import { openErrorNotification, openSuccessNotification } from 'shared/lib';
import { UiSelect } from 'shared/ui/ui-kit';

import { ManualCommandType } from 'entities/manual-command';
import { ChargePointsListItem } from 'entities/charge-point';

import { ADD_ERROR, ADD_SUCCESS } from '../../../consts';

import {
  FormButtonsContainer,
  FormItem,
  FormRow,
  StyledButton,
} from '../../../../../common-styles';

import { useSendCommandMutation } from '../../../../../redux/services/private-methods';
import { VALIDATION_RULE } from '../../../../../constants/validation-rules';

interface FormData {
  chargePointName: string;
  ocppConfigLine: string;
}

export interface ChangeConfigurationFormProps {
  commandType: ManualCommandType;
  closeModal: () => void;
  isDisabled?: boolean;
  handleCpNameParamChange: (value: string) => void;
  chargePoints: Array<ChargePointsListItem>;
  ocppConfigOptions: Array<{ label: string; value: string }>;
  initialValues: FormData;
  loading: boolean;
}

export function ChangeConfigurationForm({
  closeModal,
  isDisabled = true,
  commandType,
  handleCpNameParamChange,
  chargePoints,
  ocppConfigOptions,
  initialValues,
  loading,
}: ChangeConfigurationFormProps) {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(isDisabled);

  const [sendCommandTrigger, { isLoading }] = useSendCommandMutation();

  const [form] = Form.useForm<FormData>();

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  useEffect(() => {
    setIsSubmitDisabled(isDisabled);
  }, [isDisabled]);

  const chargePointName = useWatch('chargePointName', form);

  useEffect(() => {
    if (chargePointName) {
      handleCpNameParamChange(chargePointName);
    }
  }, [chargePointName]);

  const onValuesChange: FormProps<FormData>['onValuesChange'] = (
    _,
    allValues
  ) => {
    if (allValues.chargePointName !== '' && allValues.ocppConfigLine !== '') {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  const handleSubmit = async (values: FormData) => {
    const req = {
      command: commandType,
      cpName: values.chargePointName,
      body: {
        changeConfigurationParameterNumber: Number(values.ocppConfigLine),
      },
    };

    try {
      const res = await sendCommandTrigger(req).unwrap();

      res.statusCode === 0
        ? openSuccessNotification(ADD_SUCCESS)
        : openErrorNotification(res.statusDescription);
    } catch (e) {
      openErrorNotification(ADD_ERROR);
    } finally {
      closeModal();
    }
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onFinish={handleSubmit}
      onValuesChange={onValuesChange}
      initialValues={initialValues}
    >
      <FormRow gutter={10}>
        <Col span={12}>
          <Spin spinning={loading}>
            <FormItem
              label="Наименование ЭЗС"
              name="chargePointName"
              rules={[VALIDATION_RULE.REQUIRED]}
            >
              <UiSelect
                showSearch
                placeholder="Select a person"
                optionFilterProp="children"
                filterOption={filterOption}
                options={chargePoints.map(({ name }) => ({
                  value: name,
                  label: name,
                }))}
              />
            </FormItem>
          </Spin>
        </Col>
        <Col span={12}>
          <Spin spinning={loading}>
            <FormItem
              label="Конфигуарция"
              name="ocppConfigLine"
              rules={[VALIDATION_RULE.REQUIRED]}
            >
              <UiSelect options={ocppConfigOptions} />
            </FormItem>
          </Spin>
        </Col>
      </FormRow>
      <FormButtonsContainer>
        <Spin spinning={isLoading}>
          <StyledButton
            htmlType="submit"
            type="primary"
            disabled={isSubmitDisabled}
          >
            Добавить
          </StyledButton>
        </Spin>
        <StyledButton htmlType="button" type="default" onClick={closeModal}>
          Отмена
        </StyledButton>
      </FormButtonsContainer>
    </Form>
  );
}

export default React.memo(ChangeConfigurationForm);
