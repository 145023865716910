import { Col, Form, FormProps, Spin } from 'antd';
import { useState } from 'react';
import { formHasErrors } from 'shared/lib';

import { UiTextArea } from 'shared/ui/ui-kit';

import {
  FormButtonsContainer,
  FormItem,
  FormRow,
  StyledButton,
} from '../../../../../../common-styles';
import { VALIDATION_RULE } from '../../../../../../constants/validation-rules';
import { ChargePointFormData } from '../../../../types';

type FormDataType = ChargePointFormData['ocppConfig'];

interface OcppConfigFormProps {
  initialValues: FormDataType;
  onCancel: () => void;
  handleSubmit: (values: FormDataType) => void;
  loading: boolean;
  editing: boolean;
}

export function OcppConfigForm({
  initialValues,
  onCancel,
  handleSubmit,
  loading,
  editing,
}: OcppConfigFormProps) {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(editing);
  const [hasErrors, setHasErrors] = useState(false);

  const [form] = Form.useForm<FormDataType>();

  const onFieldsChange: FormProps<FormDataType>['onFieldsChange'] = (
    _,
    _allFields
  ) => {
    setHasErrors(formHasErrors(form));
  };

  const onValuesChange: FormProps<FormDataType>['onValuesChange'] = (
    _,
    allValues
  ) => {
    setIsSubmitDisabled(false);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onFinish={handleSubmit}
      onFieldsChange={onFieldsChange}
      onValuesChange={onValuesChange}
      initialValues={initialValues}
    >
      <FormRow gutter={20}>
        <Col span={18}>
          <FormItem
            label="OCPP конфиг"
            name="ocppConfig"
            rules={[VALIDATION_RULE.OCPP_CONFIG]}
          >
            <UiTextArea autoSize />
          </FormItem>
        </Col>
      </FormRow>
      <Spin spinning={loading}>
        <FormButtonsContainer>
          <StyledButton
            htmlType="submit"
            type="primary"
            disabled={isSubmitDisabled || hasErrors}
          >
            {editing ? 'Сохранить' : 'Создать ЭЗС'}
          </StyledButton>

          <StyledButton htmlType="button" type="default" onClick={onCancel}>
            Отмена
          </StyledButton>
        </FormButtonsContainer>
      </Spin>
    </Form>
  );
}
