import {
  ChargePointConnectorStatus,
  ChargePointConnectorType,
  ConnectorFormat,
  ConnectorFormData,
} from 'entities/connector';

export const prepareRequestData = (values: ConnectorFormData) => {
  const {
    connectorId,
    format,
    instructionId,
    liter,
    maxAmperage,
    maxPower,
    maxVoltage,
    status,
    type,
    manualBlock,
  } = values;

  return {
    connectorId: Number(connectorId),
    type: Number(type) as ChargePointConnectorType,
    status: Number(status) as ChargePointConnectorStatus,
    maxAmperage: Number(maxAmperage),
    maxPower: Number(maxPower),
    maxVoltage: Number(maxVoltage),
    instructionId: instructionId !== '' ? Number(instructionId) : null,
    format: format as ConnectorFormat,
    liter,
    manualBlock,
  };
};
