import { CloseIcon } from 'shared/icons/close';

import { StyledUiModal, UiModalTitle } from './styles';

type Props = {
  title?: React.ReactNode;
  visible: boolean;
  onCancel: () => void;
  afterClose?: () => void;
  children: JSX.Element;
};

export function UiModal({
  visible,
  onCancel,
  afterClose,
  title = '',
  children,
}: Props) {
  return (
    <StyledUiModal
      destroyOnClose
      visible={visible}
      centered
      title={title && <UiModalTitle>{title}</UiModalTitle>}
      onCancel={onCancel}
      footer={false}
      closeIcon={<CloseIcon />}
      afterClose={afterClose}
    >
      {children}
    </StyledUiModal>
  );
}
