import { useNavigate } from 'react-router-dom';

import {
  openErrorNotification,
  isErrorWithMessage,
  openSuccessNotification,
} from 'shared/lib';

import {
  ConnectedDiscountForm,
  DiscountFormData,
  useAddDiscountMutation,
} from 'entities/discount';

import { ADD_SUCCESS, ADD_ERROR } from '../consts';
import { DISCOUNTS_ROUTES } from 'shared/consts/routes/discount';

const initialValues: DiscountFormData = {
  name: '',
  consumptionDiscountFormula: '',
  parkingDiscountFormula: '',
  reserveDiscountFormula: '',
  startDiscountFormula: '',
  owners: [],
};

export function AddDiscount() {
  const [trigger, { isLoading }] = useAddDiscountMutation();

  const navigate = useNavigate();

  const handleSubmit = async (values: DiscountFormData) => {
    try {
      const res = await trigger(values).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);

        return;
      }
      //

      openSuccessNotification(ADD_SUCCESS);

      navigate(DISCOUNTS_ROUTES.DISCOUNTS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : ADD_ERROR;

      openErrorNotification(errorText);
    }
  };

  return (
    <ConnectedDiscountForm
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      loading={isLoading}
      title="Добавление новой скидки"
    />
  );
}
