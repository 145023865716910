export function SearchIcon() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_423_5442)">
        <path
          d="M12.0928 11.0322C11.7999 10.7393 11.3251 10.7393 11.0322 11.0322C10.7393 11.3251 10.7393 11.7999 11.0322 12.0928L12.0928 11.0322ZM13.2197 14.2803C13.5126 14.5732 13.9874 14.5732 14.2803 14.2803C14.5732 13.9874 14.5732 13.5126 14.2803 13.2197L13.2197 14.2803ZM13.875 7.1875C13.875 3.4941 10.8809 0.5 7.1875 0.5V2C10.0525 2 12.375 4.32252 12.375 7.1875H13.875ZM7.1875 0.5C3.4941 0.5 0.5 3.4941 0.5 7.1875H2C2 4.32252 4.32252 2 7.1875 2V0.5ZM0.5 7.1875C0.5 10.8809 3.4941 13.875 7.1875 13.875V12.375C4.32252 12.375 2 10.0525 2 7.1875H0.5ZM7.1875 13.875C10.8809 13.875 13.875 10.8809 13.875 7.1875H12.375C12.375 10.0525 10.0525 12.375 7.1875 12.375V13.875ZM11.0322 12.0928L13.2197 14.2803L14.2803 13.2197L12.0928 11.0322L11.0322 12.0928Z"
          fill="#28303F"
        />
      </g>
      <defs>
        <clipPath id="clip0_423_5442">
          <rect width="15" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
