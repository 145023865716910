import { useNavigate } from 'react-router-dom';

import {
  openErrorNotification,
  openSuccessNotification,
  isErrorWithMessage,
} from 'shared/lib';
import { OWNER_ROUTES } from 'shared/consts/routes/owner';

import {
  ConnectedOwnerForm,
  OwnerFormData,
  prepareRequestData,
  useAddOwnerMutation,
} from 'entities/owner';

import { ADD_SUCCESS, ADD_ERROR } from '../consts';

// TODO: Создать тип без maxOwnerBonusPay
const initialValues: OwnerFormData = {
  id: '',
  name: '',
  legalName: '',
  tariffId: '',
  phoneNumber: '',
  telegram: '',
  email: '',
  description: '',
  inn: '',
  kpp: '',
  dirName: '',
  agentReceipt: false,
  isDiscountEnabled: false,
};

export function AddOwner() {
  const [trigger, { isLoading }] = useAddOwnerMutation();

  const navigate = useNavigate();

  const handleSubmit = async (values: OwnerFormData) => {
    const { id, ...req } = prepareRequestData(values);

    try {
      const res = await trigger(req).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);

        return;
      }
      //

      openSuccessNotification(ADD_SUCCESS);

      const entityPath = res.owner.id;

      navigate(OWNER_ROUTES.OWNERS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : ADD_ERROR;

      openErrorNotification(errorText);
    }
  };

  return (
    <ConnectedOwnerForm
      title="Добавление нового владельца ЭЗС"
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      loading={isLoading}
    />
  );
}
