import { configureStore, combineReducers } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';

import authReducer from './slices/auth/authSlice';
import authSessionReducer from './slices/auth/authSessionSlice';

import filtersReducer from './slices/filters/filtersSlice';

import { tablesReducer } from './slices/tables/tables-slice';

import { apiSlice } from './services/apiSlice';
import { monitoringPageSettingsReducer } from 'pages/monitoring';

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'monitoringPageSettings', 'filters', 'tables'],
};
const authSessionPersistConfig = {
  key: 'authSession',
  storage: storageSession,
};

const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  auth: authReducer,
  authSession: persistReducer(authSessionPersistConfig, authSessionReducer),
  monitoringPageSettings: monitoringPageSettingsReducer,
  filters: filtersReducer,
  tables: tablesReducer,
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(apiSlice.middleware),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
