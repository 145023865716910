import styled from 'styled-components';

import { COLOR } from 'shared/consts';

export const UnauthorizedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  grid-area: main;
`;

export const UnauthorizedCard = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding: 20px 44px;
  border-radius: 10px;
  background-color: ${COLOR.white};
`;

export const UnauthorizedIcon = styled.img`
  width: 24px;
  height: 24px;
`;

export const UnauthorizedText = styled.h3`
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
`;
