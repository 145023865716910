import { useLocation, useNavigate } from 'react-router-dom';

import {
  openErrorNotification,
  openSuccessNotification,
  isErrorWithMessage,
} from 'shared/lib';

import { useCustomAddGroupMutation, GroupType } from 'entities/group';

import { ADD_SUCCESS, ADD_ERROR } from '../consts';

import {
  ChargePointsGroupFormData,
  ConnectedChargePointsGroupForm,
} from 'entities/charge-points-group';
import { CHARGE_POINT_ROUTES } from 'shared/consts/routes/charge-point';

const initialValues: ChargePointsGroupFormData = {
  parentId: '',
  name: '',
  comment: '',
  tariffId: '',
};

export function AddChargePointsGroup() {
  const [trigger, { isLoading }] = useCustomAddGroupMutation();

  const navigate = useNavigate();

  const location = useLocation();

  const handleSubmit = async (values: ChargePointsGroupFormData) => {
    const { name, comment, parentId } = values;

    const req = {
      name,
      comment,
      groupType: 2 as GroupType,
      parentId,
    };

    try {
      const res = await trigger(req).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);

        return;
      }
      //

      openSuccessNotification(ADD_SUCCESS);

      // const path = location.pathname.replace(`/${CREATE_ENTITY_PREFIX}`, '');

      navigate(CHARGE_POINT_ROUTES.CHARGE_POINTS_GROUPS_LIST);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : ADD_ERROR;

      openErrorNotification(errorText);
    }
  };

  return (
    <ConnectedChargePointsGroupForm
      title="Добавление новой группы"
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      loading={isLoading}
    />
  );
}
