import { FieldErrorMessage } from './styles';

type FormValidationErrorProps = {
  errors: string[];
};

export function FieldError({ errors }: FormValidationErrorProps) {
  if (errors.length === 0) {
    return null;
  }

  return <FieldErrorMessage>{errors[0]}</FieldErrorMessage>;
}
