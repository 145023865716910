import { baseApi } from 'shared/api';

import { getEndpointPath } from 'shared/lib';

import {
  AddChargePointPhotoRequest,
  ChargePointPhotoBaseResponse,
  ChargePointPhotoUrlsBaseResponse,
  DeleteChargePointPhotoRequest,
  UpdateChargePointPhotoRequest,
} from './types';

import { apiSlice } from '../../../redux/services/apiSlice';

import { PLATFORM_SERVICE } from 'entities/authorization';
import { isOldPhoto } from 'entities/charge-point/lib';

const KEY = 'ChargePoint';

const getUrl = getEndpointPath(PLATFORM_SERVICE, KEY);

// TODO: замена на baseApi

export const api = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getChargePointPhotoUrls: builder.query<
      ChargePointPhotoUrlsBaseResponse,
      string
    >({
      query: (cpName) => ({
        url: getUrl(`GetChargePointPhotoUrls`),
        params: { cpName },
      }),
      providesTags: (result, error, arg) => [
        { type: 'ChargePointPhotoUrls', id: arg },
      ],
    }),
    getChargePointPhoto: builder.query<ChargePointPhotoBaseResponse, string>({
      query: (cpName) => ({
        url: getUrl(`GetChargePointPhoto`),
        params: { cpName },
      }),
      providesTags: (result, error, arg) => [
        { type: 'ChargePointPhoto', id: arg },
      ],
      transformResponse: (
        response: ChargePointPhotoBaseResponse,
        meta,
        arg
      ) => {
        const updatedPhotos = response.photo.filter(
          (photo) => !isOldPhoto(photo.url)
        );

        return {
          ...response,
          photo: updatedPhotos,
        };
      },
    }),
    addChargePointPhoto: builder.mutation<
      ResponseBase,
      AddChargePointPhotoRequest
    >({
      query: ({ filePhotos, cpName, firstPriority }) => ({
        url: getUrl(`AddChargePointPhoto`),
        params: {
          cpName,
          firstPriority,
        },
        method: 'POST',
        body: filePhotos,
        formData: true,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'ChargePointPhoto', id: arg.cpName },
        { type: 'ChargePointPhotoUrls', id: arg.cpName },
      ],
    }),
    updateChargePointPhoto: builder.mutation<
      ResponseBase,
      UpdateChargePointPhotoRequest
    >({
      query: ({ photoId, priority, cpName, filePhoto }) => ({
        url: getUrl(`UpdateChargePointPhoto`),
        params: {
          photoId,
          priority,
        },
        method: 'PATCH',
        body: filePhoto,
        formData: true,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'ChargePointPhoto', id: arg.cpName },
        { type: 'ChargePointPhotoUrls', id: arg.cpName },
      ],
    }),
    deleteChargePointPhoto: builder.mutation<
      ResponseBase,
      DeleteChargePointPhotoRequest
    >({
      query: ({ cpName, photoId }) => ({
        url: getUrl(`DeleteChargePointPhoto`),
        params: {
          cpName,
          photoId,
        },
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'ChargePointPhoto', id: arg.cpName },
        { type: 'ChargePointPhotoUrls', id: arg.cpName },
      ],
    }),
  }),
});

export const {
  useAddChargePointPhotoMutation,
  useUpdateChargePointPhotoMutation,
  useDeleteChargePointPhotoMutation,
  useGetChargePointPhotoQuery,
  useGetChargePointPhotoUrlsQuery,
} = api;
