import { Route } from 'react-router-dom';

import { SectionLayout } from 'shared/ui/layout';
import {
  CONNECTOR_INSTRUCTION,
  CONNECTOR_INSTRUCTIONS,
} from 'shared/consts/routes/connector-instruction';
import { ADD_PREFIX } from 'shared/consts/routes/common';

import { RequireAuth } from 'entities/authorization';
import { ConnectedConnectorInstructionsMenu } from 'entities/instruction';

import {
  AddConnectorInstructionPage,
  EditConnectorInstructionPage,
} from 'pages/instruction';

export const ConnectorInstructionRoutes = (
  <Route
    element={<RequireAuth controllerName="Instruction" rightType="Read" />}
  >
    <Route
      path={CONNECTOR_INSTRUCTIONS}
      element={<SectionLayout menu={<ConnectedConnectorInstructionsMenu />} />}
    >
      <Route
        element={<RequireAuth controllerName="Instruction" rightType="Write" />}
      >
        <Route path={ADD_PREFIX} element={<AddConnectorInstructionPage />} />
      </Route>
      <Route
        path={CONNECTOR_INSTRUCTION}
        element={<EditConnectorInstructionPage />}
      />
    </Route>
  </Route>
);
