import { useNavigate } from 'react-router-dom';
import { useMemo, useState } from 'react';
import useMeasure from 'react-use-measure';
import {
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';

import { ClientTableLayout } from 'shared/ui';
import { useClientPagination, useTablePageSize } from 'shared/lib';
import { USER_ROUTES } from 'shared/consts/routes/user';

import { RoleWithUsersDto, UserReduceInfoDto } from 'entities/role';

import { useColumns } from '../hooks/use-columns';

type Props = {
  tableData: RoleWithUsersDto['users'];
  loading: boolean;
  rowsCount: number | undefined;
};

export function RoleUsersTableInstance({
  loading,
  tableData,
  rowsCount,
}: Props) {
  const [tableHeadRef, tableHeadBounds] = useMeasure();

  const columns = useColumns();

  const [sorting, setSorting] = useState<SortingState>([]);
  const [tablePageSize, setTablePageSize] = useTablePageSize(tableHeadBounds);
  const [pagination, setPagination] = useClientPagination(tablePageSize);

  const navigate = useNavigate();

  const data = useMemo(() => tableData, [tableData]);

  const table = useReactTable({
    data,
    columns,
    state: { sorting, pagination },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const handleRowDoubleClick = (row: Row<UserReduceInfoDto>) => {
    const {
      original: { userName },
    } = row;

    const path = `${USER_ROUTES.USERS}/${userName}`;

    navigate(path);
  };

  return (
    <ClientTableLayout
      table={table}
      tableHeadRef={tableHeadRef}
      handlePageSizePressEnter={setTablePageSize}
      pageSize={tablePageSize}
      loading={loading}
      handleRowDoubleClick={handleRowDoubleClick}
      rowsCount={rowsCount}
    />
  );
}
