import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Col, Form, FormProps, Select, Spin } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useWatch } from 'antd/lib/form/Form';

import { formHasErrors, VALIDATION_RULE } from 'shared/lib';
import { UiCheckbox } from 'shared/ui/ui-kit/checkbox';
import { UiInput, UiSelect } from 'shared/ui/ui-kit';

import { CarConnectorsTableItem } from 'entities/car/model/car';
import { CarFormData, CarFormProps } from 'entities/car/model/form';
import { CarBrand } from 'entities/car-brand/model/car-brand';

import { FormItem, FormRow, StyledButton } from '../../../../common-styles';

import {
  TableCheckboxContainer,
  TableCheckboxFormItem,
  FormButtonsContainer,
  NoBrandLogoText,
  BrandLogoContainer,
  BrandLogo,
} from './styles';

import { useConnectorAdapterFormItems } from './hook';
import { RightsTable } from 'shared/ui/table';

type FormData = CarFormData;

const columnHelper = createColumnHelper<CarConnectorsTableItem>();

const { Option } = Select;

export function CarForm({
  initialValues,
  handleSubmit,
  carModels,
  carBrands,
  loading,
  tableData,
  closeModal,
}: CarFormProps) {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [hasErrors, setHasErrors] = useState(false);

  const [form] = Form.useForm<FormData>();

  const carBrandName = useWatch('carBrandName', form);
  const carModelId = useWatch('carModelId', form);

  const mounted = useRef<boolean>();

  useEffect(() => {
    if (!mounted.current) {
      // do componentDidMount logic
      mounted.current = true;
    } else {
      if (carBrandName && carBrandName !== initialValues.carBrandName) {
        form.setFieldValue('carModelId', '');
      }
    }
  }, [carBrandName]);

  const [Schuko, SchukoAdapter] = useConnectorAdapterFormItems(form, '1');
  const [Type2, Type2Adapter] = useConnectorAdapterFormItems(form, '2');
  const [Ccs2, Ccs2Adapter] = useConnectorAdapterFormItems(form, '3');
  const [Chademo, ChademoAdapter] = useConnectorAdapterFormItems(form, '4');
  const [Gbtdc, GbtdcAdapter] = useConnectorAdapterFormItems(form, '5');
  const [Gbtac, GbtacAdapter] = useConnectorAdapterFormItems(form, '6');
  const [Ccs1, Ccs1Adapter] = useConnectorAdapterFormItems(form, '7');
  const [Type1, Type1Adapter] = useConnectorAdapterFormItems(form, '8');
  const [Tesla, TeslaAdapter] = useConnectorAdapterFormItems(form, '9');

  const onFieldsChange: FormProps<FormData>['onFieldsChange'] = (
    _,
    _allFields
  ) => {
    setHasErrors(formHasErrors(form));
  };

  const onValuesChange: FormProps<FormData>['onValuesChange'] = (
    _,
    allValues
  ) => {
    if (allValues.name !== '' && allValues.carModelId !== '') {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  const columns = [
    columnHelper.accessor('connectorName', {
      header: 'Название',
    }),
    columnHelper.accessor('connectorType', {
      header: 'Коннектор',
      cell: (props) => {
        const { connectorType } = props.row.original;

        return (
          <TableCheckboxContainer>
            <TableCheckboxFormItem
              name={`${connectorType}`}
              valuePropName="checked"
            >
              <UiCheckbox />
            </TableCheckboxFormItem>
          </TableCheckboxContainer>
        );
      },
    }),
    columnHelper.accessor('isAdapterNeeded', {
      header: 'Переходник',
      cell: (props) => {
        const { connectorType } = props.row.original;

        return (
          <TableCheckboxContainer>
            <TableCheckboxFormItem
              name={`${connectorType}/adapter`}
              valuePropName="checked"
            >
              <UiCheckbox />
            </TableCheckboxFormItem>
          </TableCheckboxContainer>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const carModelsOptions = useMemo(() => {
    if (!carBrandName) {
      return carModels;
    }

    const selectedCarBrand = carBrands.find(
      (carBrand) => carBrand.name === carBrandName
    ) as CarBrand;

    return carModels.filter(
      (carModel) => carModel.carBrand === selectedCarBrand.name
    );
  }, [carBrandName]);

  const carBrandLogo = useMemo(() => {
    if (!carBrandName) {
      return null;
    }

    const selectedCarBrand = carBrands.find(
      (carBrand) => carBrand.name === carBrandName
    ) as CarBrand;

    return selectedCarBrand.logo;
  }, [carBrandName]);

  const carBrandLogoContent = (
    <BrandLogoContainer>
      {carBrandLogo !== null ? (
        <BrandLogo src={`${carBrandLogo.baseAddress}${carBrandLogo.url}`} />
      ) : (
        <NoBrandLogoText>Нет логотипа</NoBrandLogoText>
      )}
    </BrandLogoContainer>
  );

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onFinish={handleSubmit}
      onValuesChange={onValuesChange}
      onFieldsChange={onFieldsChange}
      initialValues={initialValues}
    >
      <FormRow gutter={10}>
        <Col span={12}>
          <FormItem label="Марка автомобиля" name="carBrandName">
            <UiSelect>
              {carBrands.map(({ id, name }) => (
                <Option value={name} key={id}>
                  {name}
                </Option>
              ))}
            </UiSelect>
          </FormItem>
        </Col>
        <Col span={12}>{carBrandLogoContent}</Col>
      </FormRow>
      <FormRow gutter={10}>
        <Col span={12}>
          <FormItem
            label="Модель автомобиля"
            name="carModelId"
            rules={[VALIDATION_RULE.REQUIRED]}
          >
            <UiSelect>
              {carModelsOptions.map(({ id, name }) => (
                <Option value={String(id)} key={id}>
                  {name}
                </Option>
              ))}
            </UiSelect>
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            label="Наименование"
            name="name"
            rules={[VALIDATION_RULE.REQUIRED]}
          >
            <UiInput />
          </FormItem>
        </Col>
      </FormRow>

      <RightsTable table={table} />

      <Spin spinning={loading}>
        <FormButtonsContainer>
          <StyledButton
            htmlType="submit"
            type="primary"
            disabled={isSubmitDisabled || hasErrors}
          >
            Сохранить
          </StyledButton>
          <StyledButton htmlType="button" type="default" onClick={closeModal}>
            Отмена
          </StyledButton>
        </FormButtonsContainer>
      </Spin>
    </Form>
  );
}
