import styled from 'styled-components';

type Props = {
  isDesc: boolean;
};

export const SortingIcon = styled.img<Props>`
  transition: 200ms;
  transform: rotate(${({ isDesc }) => (isDesc ? 180 : 0)}deg);
  width: 15px;
  height: 15px;
  margin-left: 5px;
`;
