import { Route } from 'react-router-dom';

import { SectionLayout } from 'shared/ui/layout';
import {
  BONUSES,
  BONUS_PROGRAM,
  TRANSACTIONS,
} from 'shared/consts/routes/bonus';
import { ADD_PREFIX } from 'shared/consts/routes/common';

import { RequireAuth } from 'entities/authorization';
import { BonusProgramsLayout } from 'entities/bonus-programs-layout';
import { BonusProgramLayout } from 'entities/bonus-program/bonus-program-layout';

import { ConnectedBonusProgramsMenu } from 'features/bonus-program/menu';
import { ConnectedBonusProgramTransactionsTable } from 'features/bonus-program/transactions-table/ui/connected-table';

import { AddBonusProgramPage, EditBonusProgramPage } from 'pages/bonus';

import { ConnectedBonusProgramTable } from '../../../features/bonus-program/table/ui/connected-table';

export const BonusRoutes = (
  <Route
    element={<RequireAuth controllerName="BonusService" rightType="Read" />}
  >
    <Route
      path={BONUSES}
      element={<SectionLayout menu={<ConnectedBonusProgramsMenu />} />}
    >
      <Route element={<BonusProgramsLayout />}>
        <Route index element={<ConnectedBonusProgramTable />} />
      </Route>

      <Route
        element={
          <RequireAuth controllerName="BonusService" rightType="Write" />
        }
      >
        <Route path={ADD_PREFIX} element={<AddBonusProgramPage />} />
      </Route>

      <Route path={BONUS_PROGRAM} element={<BonusProgramLayout />}>
        <Route index element={<EditBonusProgramPage />} />
        <Route
          path={TRANSACTIONS}
          element={<ConnectedBonusProgramTransactionsTable />}
        />
      </Route>
    </Route>
  </Route>
);
