import { getEndpointPath } from 'shared/lib';

import { PLATFORM_SERVICE } from 'entities/authorization';

import { apiSlice } from '../../../redux/services/apiSlice';
import {
  AddRoleRequest,
  GetRoleResponse,
  GetRolesResponse,
  UpdateRoleRequest,
  UpdateRoleResponse,
} from '../model/api';

const KEY = 'Identity';

const getUrl = getEndpointPath(PLATFORM_SERVICE, KEY);

export const api = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRole: builder.query<GetRoleResponse, string>({
      query: (roleName) => ({
        url: getUrl('GetRole'),
        params: { roleName },
      }),
      providesTags: (result, error, arg) => [{ type: 'Role', id: arg }],
    }),
    getRoles: builder.query<GetRolesResponse, void>({
      query: () => getUrl('GetRoles'),
      providesTags: ['Roles'],
    }),
    addRole: builder.mutation<ResponseBase, AddRoleRequest>({
      query: (role) => ({
        url: getUrl(`AddRole`),
        method: 'POST',
        body: role,
      }),
      invalidatesTags: ['Roles'],
    }),
    updateRole: builder.mutation<UpdateRoleResponse, UpdateRoleRequest>({
      query: (role) => ({
        url: getUrl(`UpdateRole`),
        method: 'PATCH',
        body: role,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Role', id: arg.name },
        'Roles',
      ],
    }),
    deleteRole: builder.mutation<ResponseBase, string>({
      query: (roleName) => ({
        url: getUrl(`DeleteRole`),
        params: { roleName },
        method: 'DELETE',
      }),
      invalidatesTags: ['Roles'],
    }),
  }),
});

export const {
  useGetRolesQuery,
  useGetRoleQuery,
  useAddRoleMutation,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
} = api;
