import { Space, Spin } from 'antd';

import { StyledButton } from '../../common-styles';
import { FormButtonsContainer } from './styles';

interface FormButtonProps {
  isSubmitDisabled: boolean;
  isFormDisabled: boolean;
  spinning: boolean;
  onEditClick: () => void;
  onCancelClick: () => void;
  onDeleteClick?: () => void;
}

export function FormButtons({
  spinning,
  isSubmitDisabled,
  isFormDisabled,
  onEditClick,
  onCancelClick,
  onDeleteClick,
}: FormButtonProps) {
  return (
    <Spin spinning={spinning}>
      <FormButtonsContainer>
        <Space direction="horizontal" size={10}>
          {isFormDisabled ? (
            <StyledButton
              htmlType="button"
              type="primary"
              onClick={onEditClick}
            >
              Редактировать
            </StyledButton>
          ) : (
            <StyledButton
              htmlType="submit"
              type="primary"
              disabled={isSubmitDisabled}
            >
              Сохранить
            </StyledButton>
          )}
          <StyledButton
            htmlType="button"
            type="default"
            onClick={onCancelClick}
          >
            Отмена
          </StyledButton>
          {onDeleteClick ? (
            <StyledButton
              htmlType="button"
              type="primary"
              danger
              onClick={onDeleteClick}
            >
              Удалить
            </StyledButton>
          ) : null}
        </Space>
      </FormButtonsContainer>
    </Spin>
  );
}
