import { StatConsume } from 'entities/report';
import { ChargePointDTO } from 'entities/charge-point';
import { GroupDto } from 'entities/group';

import { ConsumeReportForm } from 'features/report/consume/form';
import { ConsumeReportTable } from 'features/report/consume/table';

type Props = {
  allowReportFetching: () => void;
  groups: GroupDto[];
  chargePoints: ChargePointDTO[];
  stats: StatConsume[];
  loading: boolean;
};

export function ConsumeReport({
  allowReportFetching,
  loading,
  chargePoints,
  groups,
  stats,
}: Props) {
  return (
    <>
      <ConsumeReportForm
        allowReportFetching={allowReportFetching}
        loading={loading}
        chargePoints={chargePoints}
        groups={groups}
      />
      <ConsumeReportTable loading={loading} groups={groups} stats={stats} />
    </>
  );
}
