import { NavigationTab } from 'shared/model';

export const TABS: Array<NavigationTab> = [
  {
    key: 'session-log',
    path: '',
    label: 'Бонусная программа',
    controller: 'BonusService',
    right: 'Read',
  },
  {
    key: 'transactions',
    path: 'transactions',
    label: 'История баллов',
    controller: 'BonusService',
    right: 'Read',
  },
];
