import { createColumnHelper } from '@tanstack/react-table';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { FORMAT_TO_SECONDS, showTimeString, toCurrencyUnit } from 'shared/lib';
import { useTableSettings } from 'shared/ui/table/visible-columns/use-table-settings';
import { TColumnDef } from 'shared/ui/table/types';
import { DateTimeRangePickerFilter, InputFilter } from 'shared/ui/table/filter';

import { Transaction } from 'entities/balance/model/api';
import { ChargePointDTO } from 'entities/charge-point';

import { selectPersonBonusTransactionsTable } from '../../../../redux/slices/tables/tables-slice';

import { setVisibleColumns, TableColumns, TableColumnsState } from '../store';

import { BonusSumText } from '../ui/styles';
import { CHARGE_POINT_ROUTES } from 'shared/consts/routes/charge-point';

const columnHelper = createColumnHelper<Transaction>();

type Props = {
  chargePoints: ChargePointDTO[];
};

export const useColumns = ({ chargePoints }: Props) => {
  const dispatch = useDispatch();

  const settings = useSelector(selectPersonBonusTransactionsTable);

  const DATA_COLUMNS: TColumnDef<Transaction, TableColumns>[] = [
    {
      accessorKey: 'lastUpdated',
      id: 'lastUpdated',
      header: 'Дата и время',
      cell: (props) => {
        const value = props.row.original.lastUpdated;

        return value ? showTimeString(value, FORMAT_TO_SECONDS) : '';
      },
      meta: {
        filterElement: <DateTimeRangePickerFilter columnId="lastUpdated" />,
      },
      enableSorting: false,
    },
    {
      accessorKey: 'bonusProgram',
      id: 'bonusProgram.name',
      header: 'Бонусная программа',
      cell: (props) => {
        const name = props.row.original.bonusProgram?.name ?? '';

        return name;
      },
      size: 120,
      minSize: 120,
      maxSize: 120,
      meta: {
        filterElement: (
          <InputFilter
            column={{ id: 'bonusProgram.name', header: 'Бонусная программа' }}
            disabled
          />
        ),
      },
      enableSorting: false,
    },
    {
      accessorKey: 'bonusSum',
      id: 'bonusSum',
      header: 'Количество баллов',
      cell: (props) => {
        const value = props.row.original.bonusSum;

        return (
          <BonusSumText isAccrual={value > 0}>
            {value > 0 ? '+' : ''}
            {toCurrencyUnit(value)}
          </BonusSumText>
        );
      },
      meta: {
        filterElement: (
          <InputFilter
            column={{ id: 'bonusSum', header: 'Количество баллов' }}
            disabled
          />
        ),
      },
      enableSorting: false,
    },
    {
      accessorKey: 'ezsId',
      id: 'ezsId',
      header: 'ЭЗС списания',
      cell: (props) => {
        const { ezsId } = props.row.original;

        if (!ezsId) {
          return '';
        }

        const chargePoint = chargePoints.find((cp) => cp.id === ezsId);

        if (!chargePoint) {
          return '';
        }

        const { name, idGroup } = chargePoint;

        return (
          <Link
            to={`${CHARGE_POINT_ROUTES.CHARGE_POINTS}/${
              idGroup !== 0 ? idGroup : null
            }/${name}`}
          >
            {name}
          </Link>
        );
      },
      size: 100,
      minSize: 100,
      maxSize: 100,
      meta: {
        filterElement: (
          <InputFilter
            column={{ id: 'ezsId', header: 'ЭЗС списания' }}
            disabled
          />
        ),
      },
      enableSorting: false,
    },
    {
      accessorKey: 'description',
      id: 'description',
      header: 'Комментарий',
      size: 600,
      minSize: 600,
      maxSize: 600,
      meta: {
        filterElement: (
          <InputFilter
            column={{ id: 'description', header: 'Комментарий' }}
            disabled
          />
        ),
      },
      enableSorting: false,
    },
  ];

  const settingsColumn = useTableSettings({
    columnHelper,
    columns: DATA_COLUMNS.map(({ id, header }) => {
      return {
        key: id,
        label: header as string,
        isChecked: settings[id],
      };
    }),
    settings,
    renderCell: (props) => {
      return '';
    },
    setVisibleColumns: (cols: TableColumnsState) => {
      dispatch(setVisibleColumns(cols));
    },
  });

  const visibleColumns = useMemo(() => {
    const dataCols = settings
      ? DATA_COLUMNS.filter((el) => settings[el.id])
      : DATA_COLUMNS;

    return [...dataCols, settingsColumn];
  }, [settings]);

  return visibleColumns;
};
