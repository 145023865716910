import { ChargePointDTO } from 'entities/charge-point';

type ConnectorsMapping = { [index: string]: { typeName: string } };

const getConnectorsMapping = (connectors: ChargePointDTO['connectors']) =>
  connectors.reduce((acc, el) => {
    return {
      ...acc,
      [el.connectorId]: {
        typeName: el.typeName,
      },
    };
  }, {} as ConnectorsMapping);

export const getConnectorCellContent = (
  connectorId: string,
  connectors: ChargePointDTO['connectors']
): string => {
  if (!connectors.length) {
    return connectorId;
  }

  const mapping = getConnectorsMapping(connectors);

  if (mapping && mapping[connectorId]) {
    return `${mapping[connectorId]?.typeName} (${connectorId})`;
  }

  return connectorId;
};
