import styled from 'styled-components';

import { COLOR } from 'shared/consts';

type Props = {
  isError: boolean;
};

export const UploadedImageContainer = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-radius: 5px;
  border: ${(props) => `2px solid ${props.isError ? COLOR.red : COLOR.grey}`};

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const UploadedImageInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const UploadedImagePreview = styled.img`
  width: 60px;
  height: 60px;
`;

export const UploadedImageFilename = styled.p`
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR.black};
`;

export const UploadedImageControls = styled.div`
  display: flex;
  gap: 10px;
`;

export const UploadedImageErrorMessage = styled.p`
  color: ${COLOR.red};
  font-size: 12px;
  line-height: 15px;
`;
