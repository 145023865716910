import styled from 'styled-components';

export const StyledPersonCarList = styled.div``;

export const CarListHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 15px;
`;

export const CarsListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

export const CarListTitle = styled.p`
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const NoCarsText = styled.p``;
