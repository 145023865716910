import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import useMeasure from 'react-use-measure';

import { getServerErrorText, useTablePageSize } from 'shared/lib';
import { EmptyData, ErrorMessage } from 'shared/ui';

import { useGetCommandListQuery } from 'entities/manual-command';

import { TableInstance } from './table';

type Props = {
  groupId?: string;
  chargePointName?: string;
};

export function ConnectedCommandsTable({ chargePointName, groupId }: Props) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [tableHeadRef, tableHeadBounds] = useMeasure();

  const [tablePageSize, setTablePageSize] = useTablePageSize(tableHeadBounds);

  const ordering = searchParams.get('ordering');
  const type = searchParams.get('type');
  const page = searchParams.get('page');
  const limit = searchParams.get('limit');
  const search = searchParams.get('search');
  const searchText = searchParams.get('searchText');

  const { isFetching, error, data } = useGetCommandListQuery(
    {
      limit: limit || String(tablePageSize),
      ordering,
      page: page && page !== '1' ? String(Number(page) - 1) : null,
      type,
      cpName: chargePointName,
      groupId,
      search,
      searchText,
    },
    {
      skip: !limit && !tablePageSize,
    }
  );

  const tableData = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.commands;
  }, [data]);

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  return (
    <TableInstance
      tableData={tableData}
      loading={isFetching}
      count={data?.count}
      tableHeadRef={tableHeadRef}
      tablePageSize={tablePageSize}
    />
  );
}
