import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { ConfirmDialog, UiModal } from 'shared/ui';
import { openErrorNotification, openSuccessNotification } from 'shared/lib';

import { useDeleteBonusProgramMutation } from 'entities/bonus-program';

import { DELETE_ERROR, DELETE_SUCCESS } from '../consts';

type Props = {
  id: string;
  name: string;
};

export const DeleteBonusProgramModal = NiceModal.create((props: Props) => {
  const modal = useModal();

  const [trigger, { isLoading }] = useDeleteBonusProgramMutation();

  const handleDelete = async (id: string) => {
    try {
      const res = await trigger(id).unwrap();

      openSuccessNotification(DELETE_SUCCESS);

      // res.statusCode === 0
      //   ? openSuccessNotification(DELETE_SUCCESS)
      //   : openErrorNotification(res.statusDescription);
    } catch (e) {
      openErrorNotification(DELETE_ERROR);
    } finally {
      modal.hide();
    }
  };

  return (
    <UiModal
      title={`Удалить бонусную программу "${props.name}"?`}
      visible={modal.visible}
      onCancel={modal.hide}
      afterClose={modal.remove}
    >
      <ConfirmDialog
        onCancel={modal.hide}
        onConfirm={() => handleDelete(props.id)}
        loading={isLoading}
      />
    </UiModal>
  );
});
