export const EDIT_SUCCESS = 'Пуш-уведомление обновлено';
export const EDIT_ERRROR = 'Не удалось обновить пуш-уведомление';

export const getLoadPersonsSuccess = (sended: number, total: number) =>
  `Загружены ${sended} из ${total} номеров телефонов`;
export const getLoadPersonsError = () =>
  `Не удалось загрузить номера телефонов`;

export const getSendPushSuccess = (amount: number) =>
  `Пуш-уведомление отправлено ${amount} клиентам`;
export const getSendPushError = () => `Не удалось отправить пуш-уведомление`;
