import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
  openErrorNotification,
  openSuccessNotification,
  getServerErrorText,
} from 'shared/lib';
import { ErrorMessage } from 'shared/ui';

import {
  SetRoleRightsRequest,
  ControllerRweRight,
} from '../../../../../models/identity';
import {
  useGetRoleRightsQuery,
  useSetRoleRightsMutation,
} from '../../../../../redux/services/identity';

import { CONTROLLERS } from '../../../constants';
import { UserControllerRights } from '../../../User/components/UserControllerRights';
import { getEditRightsError, getEditRightsSuccess } from '../../constants';

export function ConnectedRoleControllerRights() {
  const { roleName } = useParams() as { roleName: string };

  const { data, isFetching, error } = useGetRoleRightsQuery(roleName);

  const [trigger, { isLoading: isMutationLoading }] =
    useSetRoleRightsMutation();

  const handleSubmit = async (values: Record<string, boolean>) => {
    const rightsDict = Object.keys(values).reduce((acc, formItemName) => {
      const formItemNameArr = formItemName.split('/');

      const controllerName = formItemNameArr[0];
      const type = formItemNameArr[1] as 'read' | 'write' | 'execute';

      const obj = acc[controllerName] || {};

      obj[type] = values[formItemName];

      acc[controllerName] = { ...acc[controllerName], ...obj };

      return acc;
    }, {} as Record<string, { read: boolean; write: boolean; execute: boolean }>);

    const controllerRights = Object.keys(rightsDict).map((controller) => ({
      controllerName: controller,
      ...rightsDict[controller],
    }));

    const req: SetRoleRightsRequest = {
      roleName,
      controllerRights,
    };

    try {
      const res = await trigger(req).unwrap();

      const successText = getEditRightsSuccess(roleName);

      openSuccessNotification(successText);
    } catch (e) {
      const errorText =
        err?.data?.statusDescription ?? getEditRightsError(roleName);

      openErrorNotification(errorText);
    }
  };

  const getInitialValues = () => {
    if (!data?.rights) {
      return CONTROLLERS.reduce((acc, controller) => {
        const { controllerName } = controller;

        acc[`${controllerName}/read`] = false;
        acc[`${controllerName}/write`] = false;
        acc[`${controllerName}/execute`] = false;

        return acc;
      }, {} as Record<string, boolean>);
    }

    return CONTROLLERS.reduce((acc, controller) => {
      const { controllerName } = controller;

      const roleRight = data.rights.find(
        (right) => right.controllerName === controllerName
      );

      if (roleRight) {
        const { execute, read, write } = roleRight;

        acc[`${controllerName}/read`] = read;
        acc[`${controllerName}/write`] = write;
        acc[`${controllerName}/execute`] = execute;
      } else {
        acc[`${controllerName}/read`] = false;
        acc[`${controllerName}/write`] = false;
        acc[`${controllerName}/execute`] = false;
      }

      return acc;
    }, {} as Record<string, boolean>);
  };

  const initialValues = useMemo(() => getInitialValues(), [data]);

  const getTableData = (rights?: ControllerRweRight[]) => {
    if (!rights) {
      return [];
    }

    return CONTROLLERS.map((controller) => {
      const roleRight = rights.find(
        (right) => right.controllerName === controller.controllerName
      );

      if (roleRight) {
        return {
          ...controller,
          ...roleRight,
        };
      }

      return controller;
    });
  };

  const tableData = useMemo(() => getTableData(data?.rights), [data]);

  if (isFetching) {
    return <div>Loading...</div>;
  }

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  return (
    <UserControllerRights
      tableData={tableData}
      loading={isMutationLoading}
      handleSubmit={handleSubmit}
      isAdmin={false}
      roleRight={{}}
      initialValues={initialValues}
    />
  );
}
