import { MoreOutlined } from '@ant-design/icons';
import NiceModal from '@ebay/nice-modal-react';
import { useSelector } from 'react-redux';

import { UiPopover } from 'shared/ui';

import {
  checkControllerRight,
  selectTokenClaims,
  selectTokenRoles,
} from 'entities/authorization';
import { ClientBindingsTableDataItem } from 'entities/person';

import { UnbindBankCardRequest } from '../../../../redux/services/manual-command';

import { UnbindCardModal } from '../../../person/unbind-card';

type Props = {
  binding: ClientBindingsTableDataItem;
};

export function TableActions({ binding }: Props) {
  const roles = useSelector(selectTokenRoles);
  const claims = useSelector(selectTokenClaims);

  const hasRight = checkControllerRight(
    'ManualCommand',
    'Execute',
    roles,
    claims
  );

  const showUnbindCardModal = (arg: UnbindBankCardRequest) => {
    NiceModal.show(UnbindCardModal, arg);
  };

  if (!hasRight) {
    return null;
  }

  const content = (
    <>
      <button
        onClick={() => {
          const { bindingId, clientId, userBrowseName, gateId } = binding;

          showUnbindCardModal({
            clientId,
            userBrowseName,
            bindingId,
            bankId: gateId,
          });
        }}
      >
        Отвязать карту
      </button>
    </>
  );

  return (
    <UiPopover content={content} children={<MoreOutlined rotate={90} />} />
  );
}
