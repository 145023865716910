import { baseApi } from 'shared/api';
import { getEndpointPath } from 'shared/lib';

import {
  CountriesBaseResponse,
  CountryBaseResponse,
  AddCountryFormRequest,
  UpdateCountryFormRequest,
} from './types';

import { apiSlice } from '../../../redux/services/apiSlice';
import { PLATFORM_SERVICE } from 'entities/authorization';

const KEY = 'Country';

const getUrl = getEndpointPath(PLATFORM_SERVICE, KEY);

// TODO: замена на baseApi

export const countriesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCountries: builder.query<CountriesBaseResponse, void>({
      query: () => getUrl('GetCountries'),
      providesTags: ['Countries'],
    }),
    getCountry: builder.query<CountryBaseResponse, string>({
      query: (id) => ({
        url: getUrl(`GetCountry`),
        params: { id },
      }),
      providesTags: (result, error, arg) => [{ type: 'Country', id: arg }],
    }),
    addCountry: builder.mutation<CountryBaseResponse, AddCountryFormRequest>({
      query: (country) => ({
        url: getUrl('AddCountry'),
        method: 'POST',
        body: country,
      }),
      invalidatesTags: ['Countries'],
    }),
    updateCountry: builder.mutation<
      CountryBaseResponse,
      UpdateCountryFormRequest
    >({
      query: (country) => ({
        url: getUrl('UpdateCountry'),
        method: 'PATCH',
        body: country,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Country', id: arg.id },
        'Countries',
      ],
    }),
    deleteCountry: builder.mutation<ResponseBase, string>({
      query: (id) => ({
        url: getUrl(`DeleteCountry`),
        params: { id },
        method: 'DELETE',
      }),
      invalidatesTags: ['Countries'],
    }),
  }),
});

export const {
  useGetCountriesQuery,
  useGetCountryQuery,
  useAddCountryMutation,
  useUpdateCountryMutation,
  useDeleteCountryMutation,
} = countriesApi;
