import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../store';

interface FiltersState {
  ownerTypes: string[];
  statusTypes: string[];
  owners: string[];
  withoutTestCp: string[];
}

const initialState: FiltersState = {
  ownerTypes: [],
  statusTypes: [],
  owners: [],
  withoutTestCp: [],
};

const slice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setOwnerTypes: (state, { payload }) => {
      return {
        ...state,
        ownerTypes: payload,
      };
    },
    setStatusTypes: (state, { payload }) => {
      return {
        ...state,
        statusTypes: payload,
      };
    },
    setOwners: (state, { payload }) => {
      return {
        ...state,
        owners: payload,
      };
    },
    setWithoutTestCp: (state, { payload }) => {
      return {
        ...state,
        withoutTestCp: payload,
      };
    },
  },
});

export const { setOwnerTypes, setStatusTypes, setOwners, setWithoutTestCp } =
  slice.actions;

export const selectOwnerTypes = (state: RootState) => state.filters.ownerTypes;
export const selectStatusTypes = (state: RootState) =>
  state.filters.statusTypes;
export const selectOwners = (state: RootState) => state.filters.owners;
export const selectWithoutTestCp = (state: RootState) =>
  state.filters.withoutTestCp;

export default slice.reducer;
