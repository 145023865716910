import NiceModal from '@ebay/nice-modal-react';
import { useSelector } from 'react-redux';

import { PlusInCircleIcon } from 'shared/icons/plus-in-circle';

import {
  checkControllerRight,
  selectTokenClaims,
  selectTokenRoles,
} from 'entities/authorization';

import { AddCarModal } from '../modal';
import { StyledAddButton } from '../../../../../common-styles';

type Props = {
  personLogin: string;
};

export function AddCarButton({ personLogin }: Props) {
  const roles = useSelector(selectTokenRoles);
  const claims = useSelector(selectTokenClaims);

  // Нужно ли проверять клейм Person-Write?
  const hasRight = checkControllerRight('Car', 'Write', roles, claims);

  if (!hasRight) {
    return null;
  }

  const onClick = () => {
    NiceModal.show(AddCarModal, { personLogin });
  };

  return (
    <StyledAddButton onClick={onClick} type="button">
      <PlusInCircleIcon />
    </StyledAddButton>
  );
}
