import { useGetChargePointGroupsQuery } from 'entities/group';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import useMeasure from 'react-use-measure';

import { getServerErrorText, useTablePageSize } from 'shared/lib';
import { ErrorMessage } from 'shared/ui';

import { useGetChargePointsQuery } from '../../../../redux/services/charge-points';

import {
  selectOwners,
  selectOwnerTypes,
  selectStatusTypes,
  selectWithoutTestCp,
} from '../../../../redux/slices/filters/filtersSlice';

import { TableInstance } from './table';

type Props = {
  groupId?: string;
  owner?: string;
};

export function ConnectedChargePointsTable({ groupId, owner }: Props) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [tableHeadRef, tableHeadBounds] = useMeasure();

  const [tablePageSize, setTablePageSize] = useTablePageSize(tableHeadBounds);

  const ownerTypes = useSelector(selectOwnerTypes);
  const statusTypes = useSelector(selectStatusTypes);
  const owners = useSelector(selectOwners);
  const withoutTestCp = useSelector(selectWithoutTestCp);

  const {
    data: groupsData,
    isLoading: isGroupsLoading,
    error: groupsError,
  } = useGetChargePointGroupsQuery();

  const ordering = searchParams.get('ordering');
  const type = searchParams.get('type');
  const cpName = searchParams.get('cpName');
  const page = searchParams.get('page');
  const address = searchParams.get('address');
  const limit = searchParams.get('limit');
  const search = searchParams.get('search');
  const searchText = searchParams.get('searchText');
  const blockedCp = searchParams.get('blockedCp');

  const getOwnersParam = () => {
    if (owner) {
      return owner;
    }

    if (owners.length) {
      return owners.join(',');
    }

    return '';
  };

  const {
    data: chargePointsData,
    isFetching: isChargePointsLoading,
    error: chargePointsError,
  } = useGetChargePointsQuery(
    {
      limit: limit || String(tablePageSize),
      ordering,
      page: page && page !== '1' ? String(Number(page) - 1) : null,
      type,
      cpName,
      address,
      groupId,
      search,
      searchText,
      owners: getOwnersParam(),
      ownerTypes: ownerTypes.length ? ownerTypes.join(',') : '',
      statusType: statusTypes.length ? statusTypes.join(',') : '',
      withoutTestCp: withoutTestCp.length ? false : true,
      blockedCp: blockedCp ? Boolean(blockedCp) : false,
    },
    {
      skip: !limit && !tablePageSize,
    }
  );

  const tableData = useMemo(() => {
    if (!chargePointsData) {
      return [];
    }

    return chargePointsData.chargePoints;
  }, [chargePointsData]);

  const groups = useMemo(() => {
    if (!groupsData) {
      return [];
    }

    return groupsData.groups;
  }, [groupsData]);

  const apiResponseError = [groupsData, chargePointsData].find(
    (el) => el && el.statusCode !== 0
  );

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusDescription} />;
  }

  const error = [groupsError, chargePointsError].find(
    (err) => err !== undefined
  );

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  return (
    <TableInstance
      groups={groups}
      tableData={tableData}
      loading={isChargePointsLoading || isGroupsLoading}
      count={chargePointsData?.count}
      tableHeadRef={tableHeadRef}
      tablePageSize={tablePageSize}
    />
  );
}
