import { IconContainer } from './styles';

type Props = {
  isOpen?: boolean;
};

export function ExpandRowIcon({ isOpen = false }: Props) {
  return (
    <IconContainer isOpen={isOpen}>
      <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.08203 4.95834L9.91536 8.50001L7.08203 12.0417"
          stroke="#28303F"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </IconContainer>
  );
}
