import { NavigationTab } from 'shared/model';

import { AddCarModelButtonLink } from './buttons';

export const TABS: Array<NavigationTab> = [
  {
    key: 'car-models',
    label: 'Список моделей',
    path: '',
    buttons: [
      {
        element: <AddCarModelButtonLink />,
        controller: 'Car',
        right: 'Write',
      },
    ],
  },
];
