import { Menu, Spin } from 'antd';

import { CustomLink } from '../../custom-link';
import { SectionMenuItem } from '../menu-item';
import { StyledNavMenu } from './styles';

type TSectionMenuItem = {
  id: number | string;
  name: string;
};

type Props = {
  loading: boolean;
  label: string;
  menuKey: string;
  absolutePath: string;
  items: Array<TSectionMenuItem>;
  addButton?: React.ReactNode;
};

export function SectionMenu({
  loading,
  items,
  label,
  menuKey,
  absolutePath,
  addButton,
}: Props) {
  return (
    <>
      {addButton}
      <StyledNavMenu mode="inline" inlineIndent={15} openKeys={[menuKey]}>
        <Spin spinning={loading}>
          <CustomLink
            to={absolutePath}
            checkIsActive={(path) => path === absolutePath}
          >
            <Menu.SubMenu
              key={menuKey}
              title={label}
              eventKey={menuKey}
              disabled={loading}
            >
              {items.map(({ id, name }) => (
                <SectionMenuItem id={String(id)} name={name} key={id} />
              ))}
            </Menu.SubMenu>
          </CustomLink>
        </Spin>
      </StyledNavMenu>
    </>
  );
}
