import { useSelector } from 'react-redux';
import NiceModal from '@ebay/nice-modal-react';

import {
  checkControllerRight,
  selectTokenClaims,
  selectTokenRoles,
} from 'entities/authorization';
import { BankListItem } from 'entities/bank';

import { UiUpdateBonusesButton } from './styles';
import { UpdateBonusesModal } from '../modal';

type Props = {
  personId: string;
  banks: Array<BankListItem>;
  bankId?: number;
};

export function UpdateBonusesButton(props: Props) {
  const roles = useSelector(selectTokenRoles);
  const claims = useSelector(selectTokenClaims);

  const hasRight = checkControllerRight(
    'BonusService',
    'Execute',
    roles,
    claims
  );

  const openModal = () => {
    NiceModal.show(UpdateBonusesModal, props);
  };

  if (!hasRight) {
    return null;
  }

  return (
    <UiUpdateBonusesButton type="primary" onClick={() => openModal()}>
      Изменить
    </UiUpdateBonusesButton>
  );
}
