import { StyledNavigationTabButton } from './styles';

import AddIcon from './add-icon.svg';

type Props = {
  onClick?: () => void;
  title?: string;
  icon?: string;
};

export function NavigationTabButton({ onClick, title, icon = AddIcon }: Props) {
  return (
    <StyledNavigationTabButton
      onClick={onClick}
      type="primary"
      key={title}
      icon={<img src={icon} />}
    >
      {title}
    </StyledNavigationTabButton>
  );
}
