import { EmptyData, NavMenuError } from 'shared/ui';
import { SectionMenu } from 'shared/ui/section-menu';
import { CONNECTOR_INSTRUCTION_ROUTES } from 'shared/consts/routes/connector-instruction';

import { SECTION } from 'entities/navigation';
import { useGetConnectorInstructionsQuery } from 'entities/instruction';

import { AddButton } from './add-button';

const { label, path } = SECTION['connector-instructions'];

export function ConnectedConnectorInstructionsMenu() {
  const { data, isFetching, error } = useGetConnectorInstructionsQuery();

  if (data && data.statusCode !== 0) {
    return <NavMenuError text={data.statusDescription} />;
  }

  if (error) {
    return <NavMenuError />;
  }

  if (!isFetching && !data) {
    return <EmptyData />;
  }

  const items = (data?.connectorInstructions || []).map(
    ({ connectorType, connectorTypeName }) => ({
      id: String(connectorType),
      name: connectorTypeName,
    })
  );

  return (
    <SectionMenu
      loading={isFetching}
      items={items}
      menuKey={path}
      absolutePath={CONNECTOR_INSTRUCTION_ROUTES.CONNECTOR_INSTRUCTIONS}
      label={label}
      addButton={<AddButton />}
    />
  );
}
