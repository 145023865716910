import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NiceModal from '@ebay/nice-modal-react';
import { Link } from 'react-router-dom';

import { TColumnDef } from 'shared/ui/table/types';
import { DateTimeRangePickerFilter, InputFilter } from 'shared/ui/table/filter';
import { useTableSettings } from 'shared/ui/table/visible-columns/use-table-settings';
import {
  FORMAT_TO_DAYS,
  FORMAT_TO_SECONDS,
  renderBooleanAsString,
  showTimeString,
  TABLE_PHONE_COLUMN_WIDTH,
  toCurrencyUnit,
} from 'shared/lib';

import {
  checkControllerRight,
  selectTokenClaims,
  selectTokenRoles,
} from 'entities/authorization';
import {
  MongoReserveSessionDto,
  ReserveDetailsModal,
  RESERVE_STATUS,
  STOP_REASON,
} from 'entities/session';

import { setVisibleColumns, TableColumns, TableColumnsState } from '../store';

import { selectReserveSessionsTable } from '../../../../redux/slices/tables/tables-slice';

import { ClickableSpan } from '../../../../common-styles';
import { PERSON_ROUTES } from 'shared/consts/routes/person';
import { CHARGE_POINT_ROUTES } from 'shared/consts/routes/charge-point';

const columnHelper = createColumnHelper<MongoReserveSessionDto>();

export const useColumns = () => {
  const dispatch = useDispatch();

  const settings = useSelector(selectReserveSessionsTable);

  const roles = useSelector(selectTokenRoles);
  const claims = useSelector(selectTokenClaims);

  const hasPersonReadRight = checkControllerRight(
    'Person',
    'Read',
    roles,
    claims
  );

  const showReserveDetailsModal = (session: MongoReserveSessionDto) => {
    NiceModal.show(ReserveDetailsModal, { session });
  };

  const DATA_COLUMNS: TColumnDef<MongoReserveSessionDto, TableColumns>[] = [
    {
      accessorKey: 'details',
      id: 'details.cpName',
      header: 'ЭЗС',
      size: 100,
      minSize: 100,
      maxSize: 100,
      cell: (props) => {
        const { details } = props.row.original;

        if (!details) {
          return;
        }

        const { cpName, cpGroupId } = details;

        return (
          <Link
            to={`${CHARGE_POINT_ROUTES.CHARGE_POINTS}/${
              cpGroupId !== 0 ? cpGroupId : null
            }/${cpName}`}
          >
            {cpName}
          </Link>
        );
      },
      meta: {
        filterElement: (
          <InputFilter column={{ id: 'details.cpName', header: 'ЭЗС' }} />
        ),
      },
    },
    {
      accessorKey: 'creationTime',
      id: 'creationTime',
      header: 'Дата старта',
      cell: (props) => {
        const value = props.row.original.creationTime;

        return value ? showTimeString(value, FORMAT_TO_SECONDS) : '';
      },
      meta: {
        filterElement: <DateTimeRangePickerFilter columnId="creationTime" />,
      },
    },
    {
      accessorKey: 'endTime',
      id: 'endTime',
      header: 'Завершена',
      cell: (props) => {
        const value = props.row.original.endTime;

        return value ? showTimeString(value, FORMAT_TO_SECONDS) : '';
      },
      meta: {
        filterElement: <DateTimeRangePickerFilter columnId="endTime" />,
      },
    },
    {
      accessorKey: 'details',
      id: 'details.clientLogin',
      header: 'Клиент',
      cell: (props) => {
        const { details } = props.row.original;

        if (!details) {
          return;
        }

        const { clientLogin, clientGroupId } = details;

        if (hasPersonReadRight) {
          return (
            <Link
              to={`${PERSON_ROUTES.PERSONS_LIST}/${
                clientGroupId !== 0 ? clientGroupId : null
              }/${clientLogin}`}
            >
              {clientLogin}
            </Link>
          );
        }

        return clientLogin;
      },
      meta: {
        filterElement: (
          <InputFilter
            column={{ id: 'details.clientLogin', header: 'Клиент' }}
          />
        ),
      },
    },
    {
      accessorKey: 'details',
      id: 'details.usedAmount',
      header: 'Цена',
      cell: (props) => {
        const { details } = props.row.original;

        if (!details) {
          return;
        }

        const { usedAmount } = details;

        return (
          <ClickableSpan
            onClick={(e) => {
              showReserveDetailsModal(props.row.original);
            }}
          >
            {toCurrencyUnit(usedAmount)}
          </ClickableSpan>
        );
      },
      meta: {
        filterElement: (
          <InputFilter column={{ id: 'details.usedAmount', header: 'Цена' }} />
        ),
      },
    },
    {
      accessorKey: 'status',
      id: 'status',
      header: 'Статус',
      cell: (props) => {
        const value = props.row.original.status;

        return RESERVE_STATUS[value];
      },
      meta: {
        filterElement: (
          <InputFilter column={{ id: 'status', header: 'Статус' }} />
        ),
      },
    },
    {
      accessorKey: 'stopReason',
      id: 'stopReason',
      header: 'Причина завершения',
      cell: (props) => {
        const value = props.row.original.stopReason;

        return STOP_REASON[value];
      },
      meta: {
        filterElement: (
          <InputFilter
            column={{ id: 'stopReason', header: 'Причина завершения' }}
          />
        ),
      },
    },
    {
      accessorKey: 'details',
      id: 'details.connectorId',
      header: 'Коннектор',
      cell: (props) => {
        const { details } = props.row.original;

        const connectorId = details?.connectorId ?? '';

        return connectorId;
      },
      meta: {
        filterElement: (
          <InputFilter
            column={{ id: 'details.connectorId', header: 'Коннектор' }}
          />
        ),
      },
    },
  ];

  const settingsColumn = useTableSettings({
    columnHelper,
    columns: DATA_COLUMNS.map(({ id, header }) => {
      return {
        key: id,
        label: header as string,
        isChecked: settings[id],
      };
    }),
    settings,
    renderCell: (props) => {
      return '';
    },
    setVisibleColumns: (cols: TableColumnsState) => {
      dispatch(setVisibleColumns(cols));
    },
  });

  const visibleColumns = useMemo(() => {
    const dataCols = settings
      ? DATA_COLUMNS.filter((el) => settings[el.id])
      : DATA_COLUMNS;

    return [...dataCols, settingsColumn];
  }, [settings]);

  return visibleColumns;
};
