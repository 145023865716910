import { Link } from 'react-router-dom';

import { NavigationTabButton } from 'shared/ui';
import { CHARGE_POINT_ROUTES } from 'shared/consts/routes/charge-point';

export function AddChargePointButtonLink() {
  return (
    <Link to={CHARGE_POINT_ROUTES.ADD_CHARGE_POINT}>
      <NavigationTabButton title="Добавить ЭЗС" />
    </Link>
  );
}
