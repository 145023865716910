import { createColumnHelper } from '@tanstack/react-table';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';

import {
  renderBooleanAsString,
  toCurrencyUnit,
  tryParseJSONObject,
} from 'shared/lib';
import { useTableSettings } from 'shared/ui/table/visible-columns/use-table-settings';
import { TColumnDef } from 'shared/ui/table/types';

import {
  ChargeTariffBankIdDto,
  TARIFF_TYPE,
  TRIGGER_TYPE,
} from 'entities/tariff';

import {
  selectOwnersTable,
  selectTariffsTable,
} from '../../../../redux/slices/tables/tables-slice';
import { setVisibleColumns, TableColumns, TableColumnsState } from '../store';

import { TableActions } from '../ui/actions';

const columnHelper = createColumnHelper<ChargeTariffBankIdDto>();

const DATA_COLUMNS: TColumnDef<ChargeTariffBankIdDto, TableColumns>[] = [
  {
    accessorKey: 'name',
    id: 'name',
    header: 'Наименование',
  },
  {
    accessorKey: 'tariffType',
    id: 'tariffType',
    header: 'Вид тарификации',
    cell: (props) => {
      const type = props.row.original.tariffType;
      return TARIFF_TYPE[type];
    },
    size: 120,
    minSize: 120,
    maxSize: 120,
  },
  {
    accessorKey: 'startFee',
    id: 'startFee',
    header: 'Плата за старт',
    cell: (props) => {
      return toCurrencyUnit(props.row.original.startFee);
    },
    size: 120,
    minSize: 120,
    maxSize: 120,
  },
  {
    accessorKey: 'costOfQuickCharging',
    id: 'costOfQuickCharging',
    header: 'Тариф за кВт*ч',
    cell: (props) => {
      return toCurrencyUnit(props.row.original.costOfQuickCharging);
    },
    size: 120,
    minSize: 120,
    maxSize: 120,
  },
  {
    accessorKey: 'costOfSlowCharging',
    id: 'costOfSlowCharging',
    header: 'Тариф за мин',
    cell: (props) => {
      return toCurrencyUnit(props.row.original.costOfSlowCharging);
    },
    size: 120,
    minSize: 120,
    maxSize: 120,
  },
  {
    accessorKey: 'triggerType',
    id: 'triggerType',
    header: 'Вид триггера',
    cell: (props) => {
      const type = props.row.original.triggerType;
      return TRIGGER_TYPE[type];
    },
    size: 175,
    minSize: 175,
    maxSize: 175,
  },
  {
    accessorKey: 'parkFee',
    id: 'parkFee',
    header: 'Плата за простой',
    cell: (props) => toCurrencyUnit(props.row.original.parkFee),
    size: 120,
    minSize: 120,
    maxSize: 120,
  },
  {
    accessorKey: 'id',
    id: 'id',
    header: 'id тарифа',
    size: 100,
    minSize: 100,
    maxSize: 100,
  },
  {
    accessorKey: 'costOfReserve',
    id: 'costOfReserve',
    header: 'Стоимость бронирования',
    cell: (props) => props.row.original.costOfReserve,
    size: 120,
    minSize: 120,
    maxSize: 120,
  },
];

export const useColumns = () => {
  const dispatch = useDispatch();

  const settings = useSelector(selectTariffsTable);

  const settingsColumn = useTableSettings({
    columnHelper,
    columns: DATA_COLUMNS.map(({ id, header }) => {
      return {
        key: id,
        label: header as string,
        isChecked: settings[id],
      };
    }),
    settings,
    renderCell: (props) => {
      const { id, name } = props.row.original;

      return <TableActions id={String(id)} name={name} />;
    },
    setVisibleColumns: (cols: TableColumnsState) => {
      dispatch(setVisibleColumns(cols));
    },
  });

  const visibleColumns = useMemo(() => {
    const dataCols = settings
      ? DATA_COLUMNS.filter((el) => settings[el.id])
      : DATA_COLUMNS;

    return [...dataCols, settingsColumn];
  }, [settings]);

  return visibleColumns;
};
