import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { UiModal } from 'shared/ui';

import { EditChargePointPhoto } from './edit-cp-photo';

type Props = Omit<
  React.ComponentProps<typeof EditChargePointPhoto>,
  'closeModal'
>;

export const EditChargePointPhotoModal = NiceModal.create((props: Props) => {
  const modal = useModal();

  return (
    <UiModal
      visible={modal.visible}
      onCancel={modal.hide}
      afterClose={modal.remove}
      title="Загрузка фотографий ЭЗС"
    >
      <EditChargePointPhoto {...props} closeModal={modal.hide} />
    </UiModal>
  );
});
