import { useParams } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';

import { NavigationTabButton } from 'shared/ui';

import { ManualCommandModal } from 'features/manual-command';

export function OpenManualCommandButton() {
  const { chargePointName, groupId } = useParams() as {
    chargePointName?: string;
    groupId: string;
  };

  const onClick = () => {
    NiceModal.show(ManualCommandModal, { chargePointName });
  };

  return <NavigationTabButton title="Добавить действие" onClick={onClick} />;
}
