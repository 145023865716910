import styled from 'styled-components';
import { Card, Input, Button, Form, Select, Row, Checkbox } from 'antd';

import { COLOR } from 'shared/consts';

export const BaseStyledCard = styled(Card)`
  background: ${COLOR.white};
  border-radius: 8px;
  border: none;
  width: 100%;

  & .ant-card-body {
    padding: 0;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const StyledButton = styled(Button)`
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  // border-radius: 4px;
  // font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR.white};
  height: 40px;
  min-width: 169px;

  &:hover,
  &:focus {
    background-color: ${COLOR.white};
    color: ${COLOR.greenMenu};
    border-color: ${COLOR.greenMenu};
  }

  &.ant-btn-default {
    background-color: ${COLOR.cancelGrey};
    border-color: ${COLOR.cancelGrey};
    color: ${COLOR.darkGrey};

    &:hover {
      background-color: ${COLOR.white};
      border-color: ${COLOR.greenMenu};
      color: ${COLOR.greenMenu};
    }
  }
`;

// из формы коннектора
export const StyledInput = styled(Input)`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR.black};
  padding: 7px;
  border: 0.5px solid ${COLOR.inputGrey};
  border-radius: 2px;
  // height: 29px;
`;

//
export const InputContainer = styled.div`
  margin-bottom: 10px;
`;

export const InputLabel = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR.darkGrey};
  margin-bottom: 5px;
`;

//

export const PopoverButtonsContainer = styled.div`
  &:not(:last-child) {
    border-bottom: 2px solid ${COLOR.greenMenu};
  }

  & button {
    display: block;
    width: 100%;
    padding: 7px 10px;
    line-height: 22px;
  }

  & button:last-child {
    border-bottom: none;
  }
`;

// Карточки с формами
export const CardFormWrapper = styled(Card)`
  border-radius: 8px;

  & .ant-card-head-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: ${COLOR.title};
  }
`;

export const FormRow = styled(Row)`
  margin-bottom: 15px;
`;

export const FormItem = styled(Form.Item)`
  line-height: 1;

  // В либе чуть кривые стили (лишние 0.5 пикселя, если для label 12px)
  &
    .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    font-size: 14px;
  }
`;

export const FormButtonsContainer = styled(ButtonsContainer)`
  margin-top: 50px;
`;

export const StyledDraggerFormItem = styled.div`
  & .ant-upload.ant-upload-drag {
    margin-bottom: 10px;
  }

  & .ant-upload-list.ant-upload-list-text > *:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const LabelWithHint = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const ClickableSpan = styled.span`
  color: ${COLOR.greenMenu};
  cursor: pointer;
`;

export const StyledAddButton = styled.button`
  cursor: pointer;
  width: 19px;
`;
