import styled from 'styled-components';

export const AppGridLayout = styled.div`
  height: 100vh;
  display: grid;
  grid-template-columns: 93px 224px auto;
  /* grid-template-rows: 70px auto; */
  grid-template-rows: 70px calc(100vh - 70px);
  grid-template-areas:
    'filler logo header'
    'sider menu main';
`;

export const CompanyLogoContainer = styled.div`
  grid-area: logo;
`;

export const HeaderContainer = styled.div`
  grid-area: header;
`;

export const Filler = styled.div`
  background-color: transparent;
  grid-area: filler;
`;
