import { MoreOutlined } from '@ant-design/icons';
import NiceModal from '@ebay/nice-modal-react';
import { useSelector } from 'react-redux';

import { UiPopover } from 'shared/ui';

import {
  checkControllerRight,
  selectTokenClaims,
  selectTokenRoles,
} from 'entities/authorization';
import { CarDto } from 'entities/car';

import { DeleteCarModal } from './delete-modal';
import { EditCarModal } from './edit-modal';

type Props = {
  car: CarDto;
  personLogin: string;
};

export function CarMutations(props: Props) {
  const roles = useSelector(selectTokenRoles);
  const claims = useSelector(selectTokenClaims);

  const hasRight = checkControllerRight('Car', 'Write', roles, claims);

  const showDeleteModal = () => {
    NiceModal.show(DeleteCarModal, props);
  };

  const showEditModal = () => {
    NiceModal.show(EditCarModal, props);
  };

  const content = (
    <>
      <button onClick={showEditModal}>Редактировать</button>
      {hasRight && <button onClick={showDeleteModal}>Удалить</button>}
    </>
  );

  return (
    <UiPopover content={content}>
      <MoreOutlined rotate={90} />
    </UiPopover>
  );
}
