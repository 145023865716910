import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { UiModal } from 'shared/ui';

import { BankListItem } from 'entities/bank';

import { ConnectedBalanceForm } from '../connected-form';

type Props = {
  personId: string;
  banks: Array<BankListItem>;
  bankId?: number;
};

export const UpdateBonusesModal = NiceModal.create((props: Props) => {
  const modal = useModal();

  return (
    <UiModal
      title={'Изменить бонусный счет'}
      visible={modal.visible}
      onCancel={modal.hide}
      afterClose={modal.remove}
    >
      <ConnectedBalanceForm onCancel={modal.hide} {...props} />
    </UiModal>
  );
});
