import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { PhotoContainer, PhotoImage, StyledSlider } from './styles';

type Props = {
  photoUrls: string[];
};

export function PhotosCarousel({ photoUrls }: Props) {
  const photos = photoUrls.map((photo) => (
    <PhotoContainer key={photo}>
      <PhotoImage src={photo} />
    </PhotoContainer>
  ));

  const settings = {
    infinite: false,
    slidesToShow: Math.min(photoUrls.length, 4),
    slidesToScroll: 1,
    arrows: false,
  };

  return <StyledSlider {...settings}>{photos}</StyledSlider>;
}
