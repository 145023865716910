import { Link } from 'react-router-dom';

import { NavigationTabButton } from 'shared/ui';
import { TARIFFS_ROUTES } from 'shared/consts/routes/tariff';

export function AddTariffButtonLink() {
  const path = TARIFFS_ROUTES.ADD_TARIFF;

  return (
    <Link to={path}>
      <NavigationTabButton title="Добавить тариф" />
    </Link>
  );
}
