import { useParams } from 'react-router-dom';

import { ConnectedChargePointInfo } from '../../../components/ChargePoint/components/ConnectedChargePointInfo';

export function ChargePointInfoPage() {
  const { chargePointName } = useParams() as {
    chargePointName: string;
  };

  return <ConnectedChargePointInfo chargePointName={chargePointName} />;
}
