import { Space, Spin } from 'antd';

import { Wrapper } from './styles';

type Props = {
  spinning?: boolean;
  children: React.ReactNode | React.ReactNode[];
};

export function ButtonsContainer({ spinning = false, children }: Props) {
  return (
    <Spin spinning={spinning}>
      <Wrapper>
        <Space direction="horizontal" size={10}>
          {children}
        </Space>
      </Wrapper>
    </Spin>
  );
}
