import { MainLayout } from 'shared/ui/layout/main';

import { PersonFetchingContextProvider } from '../../context';
import { PersonTabs } from '../tabs';

export function PersonLayout() {
  return (
    <PersonFetchingContextProvider>
      <MainLayout tabs={<PersonTabs />} />
    </PersonFetchingContextProvider>
  );
}
