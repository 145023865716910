import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { UiModal } from 'shared/ui';

import { AddConnector } from '../add';

type Props = {
  chargePointId: string;
  chargePointName: string;
};

export const AddConnectorModal = NiceModal.create((props: Props) => {
  const modal = useModal();

  return (
    <UiModal
      visible={modal.visible}
      onCancel={modal.hide}
      afterClose={modal.remove}
      title="Добавление нового коннектора"
    >
      <AddConnector {...props} closeModal={modal.hide} />
    </UiModal>
  );
});
