import { CustomLink } from 'shared/ui';

import { SiderbarMenuItem } from '../types';
import { useVisibleItems } from '../hooks/use-visible-items';
import { Label, IconContainer, MenuItemWrapper, StyledMenu } from './styles';

type Props = {
  items: SiderbarMenuItem[];
};

export function SiderbarMenu({ items }: Props) {
  const visibleItems = useVisibleItems(items);

  return (
    <StyledMenu>
      {visibleItems.map(({ icon, label, path }) => (
        <CustomLink to={path} key={path}>
          <MenuItemWrapper>
            <IconContainer>{icon}</IconContainer>
            <Label>{label}</Label>
          </MenuItemWrapper>
        </CustomLink>
      ))}
    </StyledMenu>
  );
}
