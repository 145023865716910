import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { getServerErrorText } from 'shared/lib';
import { ErrorMessage, EmptyData } from 'shared/ui';

import { useGetBanksQuery } from 'entities/bank';
import { useGetAcquiringReportQuery } from 'entities/report';

import { AcquiringReport } from '../acquiring-report';

export function ConnectedAcquiringReport() {
  const [skip, setSkip] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();

  const dateFrom = searchParams.get('dateFrom');
  const dateTo = searchParams.get('dateTo');
  const bankId = searchParams.get('bankId');

  const {
    data: banks,
    isLoading: isBanksLoading,
    error: banksError,
  } = useGetBanksQuery();

  const {
    data,
    isFetching: isSessionsLoading,
    error: dataError,
  } = useGetAcquiringReportQuery(
    {
      dateFrom,
      dateTo,
      bankId,
      limit: 10000, // костыль
    },
    {
      skip,
    }
  );

  // Если нужно получать при загрузке с параметрами, а не клику
  useEffect(() => {
    if (dateFrom && dateTo) {
      allowReportFetching();
    }
  }, []);

  const allowReportFetching = () => {
    setSkip(false);
  };

  const apiResponseError = [banks, data].find(
    (el) => el && el.statusCode !== 0
  );

  if (isBanksLoading) {
    return <div>Loading...</div>;
  }

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusDescription} />;
  }

  const error = [banksError, dataError].find((err) => err !== undefined);

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!banks) {
    return <EmptyData />;
  }

  return (
    <AcquiringReport
      banks={banks.banks}
      sessions={data?.sessions ?? []}
      allowReportFetching={allowReportFetching}
      loading={isSessionsLoading}
    />
  );
}
