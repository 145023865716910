import { useNavigate } from 'react-router';

import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { PUSH_NOTIFICATION_ROUTES } from 'shared/consts/routes/push-notification';

import {
  PushNotificationForm,
  PushNotificationFormData,
  useAddPushNotificationMessageMutation,
} from 'entities/push-notification';

import { ADD_ERROR, ADD_SUCCESS } from '../consts';

const initialValues: PushNotificationFormData = {
  name: '',
  title: '',
  text: '',
  browserUrl: '',
  deepLink: '',
};

export function AddPushNotification() {
  const [trigger, { isLoading }] = useAddPushNotificationMessageMutation();

  const navigate = useNavigate();

  const handleSubmit = async (values: PushNotificationFormData) => {
    try {
      const res = await trigger(values).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);

        return;
      }
      //
      openSuccessNotification(ADD_SUCCESS);

      navigate(
        `${PUSH_NOTIFICATION_ROUTES.PUSH_NOTIFICATIONS}/${res.pushNotificationMessage.id}`
      );
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : ADD_ERROR;

      openErrorNotification(errorText);
    }
  };

  return (
    <PushNotificationForm
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      title="Новая рассылка"
      loading={isLoading}
      loadedPersonsCount={null}
      disableFileUpload
    />
  );
}
