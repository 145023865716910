import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';

import {
  ChargePointConnectorDto,
  ConnectedConnectorForm,
  ConnectorFormData,
  prepareRequestData,
  useUpdateConnectorMutation,
} from 'entities/connector';

import { EDIT_ERROR, EDIT_SUCCESS } from '../../consts';

const getInitialValues = (
  connector: ChargePointConnectorDto
): ConnectorFormData => {
  const {
    maxPower,
    maxAmperage,
    maxVoltage,
    type,
    status,
    connectorId,
    liter,
    format,
    instruction,
    manualBlock,
  } = connector;

  const instructionId = instruction?.id ?? '';

  return {
    maxPower: String(maxPower),
    type: String(type),
    status: String(status),
    connectorId: String(connectorId),
    liter,
    format,
    maxVoltage: String(maxVoltage),
    maxAmperage: String(maxAmperage),
    instructionId: String(instructionId),
    manualBlock: Boolean(manualBlock),
  };
};

type Props = {
  connector: ChargePointConnectorDto;
  chargePointId: string;
  chargePointName: string;
  closeModal: () => void;
};

export function EditConnector({
  connector,
  chargePointId,
  chargePointName,
  closeModal,
}: Props) {
  const [trigger, { isLoading }] = useUpdateConnectorMutation();

  const handleSubmit = async (values: ConnectorFormData) => {
    const formData = prepareRequestData(values);

    const req = {
      ...formData,
      id: connector.id,
      chargePointId,
      chargePointName,
    };

    try {
      const res = await trigger(req).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);

        return;
      }
      //

      openSuccessNotification(EDIT_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : EDIT_ERROR;

      openErrorNotification(errorText);
    } finally {
      closeModal();
    }
  };

  const initialValues = getInitialValues(connector);

  return (
    <ConnectedConnectorForm
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      loading={isLoading}
    />
  );
}
