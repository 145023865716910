import { getCoreRowModel, Row, useReactTable } from '@tanstack/react-table';
import { useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import React from 'react';

import { ExportButton, JsonDataViewer, ServerTableLayout } from 'shared/ui';
import {
  useFiltersState,
  usePaginationState,
  useSortingState,
} from 'shared/ui/table/hooks';

import { exportInExcel, MongoReserveSessionDto } from 'entities/session';

import { useColumns } from '../hooks/use-columns';

import { EXPORT_TABLE_TITLE } from '../consts';

type Props = {
  tableData: MongoReserveSessionDto[];
  loading: boolean;
  count: number | undefined;
  tablePageSize: number;
  tableHeadRef: (element: HTMLElement | SVGElement | null) => void;
};

export function ReservesTableInstance({
  tableData,
  loading,
  count,
  tableHeadRef,
  tablePageSize,
}: Props) {
  const [reserveToShowInJSON, setReserveToShowInJSON] = useState('');

  const [searchParams, setSearchParams] = useSearchParams();

  const columns = useColumns();

  const tableRef = useRef(null);

  const ordering = searchParams.get('ordering');
  const type = searchParams.get('type');
  const cpName = searchParams.get('cpName');
  const page = searchParams.get('page');
  const limit = searchParams.get('limit');
  const search = searchParams.get('search');
  const searchText = searchParams.get('searchText');

  const data = useMemo(() => tableData, [tableData]);

  const sorting = useSortingState();
  const pagination = usePaginationState();
  const filters = useFiltersState();

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnFilters: filters,
      pagination,
    },
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount: count
      ? Math.ceil(count / (limit ? Number(limit) : tablePageSize))
      : undefined,
  });

  const handleRowDoubleClick = (row: Row<MongoReserveSessionDto>) => {
    setReserveToShowInJSON(row.original.id);
  };

  if (reserveToShowInJSON) {
    const sessionJSON = tableData.find(
      (session) => session.id === reserveToShowInJSON
    ) as MongoReserveSessionDto;

    return (
      <JsonDataViewer
        data={sessionJSON}
        onClick={() => setReserveToShowInJSON('')}
      />
    );
  }

  return (
    <ServerTableLayout
      table={table}
      loading={loading}
      pageSize={tablePageSize}
      tableHeadRef={tableHeadRef}
      handleRowDoubleClick={handleRowDoubleClick}
      tableRef={tableRef}
      exportButton={
        <ExportButton
          onClick={() =>
            exportInExcel({
              tableRef,
              title: EXPORT_TABLE_TITLE,
              columns,
            })
          }
          disabled={loading || !tableData.length}
        />
      }
      rowsCount={count}
    />
  );
}

export default React.memo(ReservesTableInstance);
