import { Col, Form, FormProps, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { QuestionCircleFilled } from '@ant-design/icons';

import { formHasErrors, VALIDATION_RULE } from 'shared/lib';
import {
  ButtonsContainer,
  UiSubmitButton,
  UiEditFormButton,
  UiCancelFormButton,
  FormControlsContainer,
} from 'shared/ui';
import { UiDivider, UiInput, UiSelect } from 'shared/ui/ui-kit';

import {
  selectTokenRoles,
  selectTokenClaims,
  checkControllerRight,
} from 'entities/authorization';

import {
  CardFormWrapper,
  FormItem,
  FormRow,
  LabelWithHint,
} from '../../../../common-styles';

import {
  BonusProgramFormData,
  BonusProgramFormProps,
  BONUS_PROGRAM_TYPE,
  FREQUENCY_TYPE,
} from 'entities/bonus-program';

type FormData = BonusProgramFormData;

export function BonusProgramForm({
  title,
  handleSubmit,
  initialValues,
  loading,
  banks,
  disabled = false,
  bonusLevels,
}: BonusProgramFormProps) {
  const roles = useSelector(selectTokenRoles);
  const claims = useSelector(selectTokenClaims);

  const hasWriteRight = checkControllerRight(
    'BonusService',
    'Write',
    roles,
    claims
  );

  const [isFormDisabled, setIsFormDisabled] = useState(disabled);

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [hasErrors, setHasErrors] = useState(false);

  const [form] = Form.useForm<FormData>();

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  const onFieldsChange: FormProps<FormData>['onFieldsChange'] = (
    _,
    _allFields
  ) => {
    setHasErrors(formHasErrors(form));
  };

  const onValuesChange: FormProps<FormData>['onValuesChange'] = (
    _,
    allValues
  ) => {
    if (allValues.name !== '') {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  const primaryButton = isFormDisabled ? (
    <UiEditFormButton onClick={() => setIsFormDisabled(false)} />
  ) : (
    <UiSubmitButton disabled={isSubmitDisabled || hasErrors} />
  );

  let formControls: JSX.Element | null = null;

  if (hasWriteRight) {
    formControls = (
      <FormControlsContainer>
        <ButtonsContainer spinning={loading}>
          {primaryButton}
          <UiCancelFormButton />
        </ButtonsContainer>
      </FormControlsContainer>
    );
  }

  return (
    <div style={{ minWidth: '1005px' }}>
      <CardFormWrapper title={title}>
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={handleSubmit}
          onFieldsChange={onFieldsChange}
          onValuesChange={onValuesChange}
          initialValues={initialValues}
        >
          <FormRow gutter={20}>
            <Col span={8}>
              <FormItem
                label="Наименование"
                name="name"
                rules={[VALIDATION_RULE.REQUIRED]}
              >
                <UiInput disabled={isFormDisabled} />
              </FormItem>
            </Col>
          </FormRow>
          <FormRow gutter={20}>
            <Col span={8}>
              <FormItem
                label="Дата начала"
                name="dateStart"
                rules={[
                  VALIDATION_RULE.REQUIRED,
                  VALIDATION_RULE.NO_WHITESPACE,
                  VALIDATION_RULE.FULL_DATE,
                ]}
              >
                <UiInput disabled={isFormDisabled} />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="Дата окончания"
                name="dateStop"
                rules={[
                  VALIDATION_RULE.REQUIRED,
                  VALIDATION_RULE.NO_WHITESPACE,
                  VALIDATION_RULE.FULL_DATE,
                ]}
              >
                <UiInput disabled={isFormDisabled} />
              </FormItem>
            </Col>
          </FormRow>
          <FormRow gutter={20}>
            <Col span={8}>
              <FormItem
                label="Тип бонусной программы"
                name="bonusProgramType"
                rules={[VALIDATION_RULE.REQUIRED]}
              >
                <UiSelect
                  disabled={isFormDisabled}
                  options={Object.entries(BONUS_PROGRAM_TYPE).map(
                    ([value, label]) => ({
                      value,
                      label,
                    })
                  )}
                />
              </FormItem>
            </Col>
          </FormRow>
          <FormRow gutter={20}>
            <Col span={16}>
              <FormItem
                label="Описание"
                name="description"
                rules={[VALIDATION_RULE.REQUIRED]}
              >
                <UiInput disabled={isFormDisabled} />
              </FormItem>
            </Col>
          </FormRow>
          <FormRow gutter={20}>
            <Col span={8}>
              <FormItem
                label="Искать сессии зарядки за последние"
                name="frequencyValue"
                rules={[
                  VALIDATION_RULE.REQUIRED,
                  VALIDATION_RULE.POSITIVE_INTEGER,
                ]}
              >
                <UiInput disabled={isFormDisabled} />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label=" "
                name="frequencyType"
                rules={[VALIDATION_RULE.REQUIRED]}
              >
                <UiSelect
                  disabled={isFormDisabled}
                  options={Object.entries(FREQUENCY_TYPE).map(
                    ([value, label]) => ({
                      value,
                      label,
                    })
                  )}
                />
              </FormItem>
            </Col>
          </FormRow>
          <FormRow gutter={20}>
            <Col span={8}>
              <FormItem
                label={
                  <LabelWithHint>
                    Запуск проверки условий (по формату CRON)
                    <Tooltip
                      title={
                        <>
                          <p>
                            cron-формат состоит из пяти полей, разделенных
                            пробелами:
                          </p>
                          <p>Минуты Часы Дни_месяца Месяцы Дни_недели Годы</p>
                        </>
                      }
                    >
                      <QuestionCircleFilled />
                    </Tooltip>
                  </LabelWithHint>
                }
                name="executionCron"
              >
                <UiInput disabled={isFormDisabled} />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="Банк"
                name="bankId"
                rules={[VALIDATION_RULE.REQUIRED]}
              >
                <UiSelect
                  disabled={isFormDisabled}
                  options={banks.map(({ id, name }) => ({
                    value: String(id),
                    label: name,
                  }))}
                />
              </FormItem>
            </Col>
          </FormRow>

          {bonusLevels ? (
            <>
              <UiDivider />
              {bonusLevels}
            </>
          ) : null}

          {formControls}
        </Form>
      </CardFormWrapper>
    </div>
  );
}
