import { getFileExtension } from './get-file-extension';

export const hasAcceptedExtension = (
  extensions: string[],
  fileName: string
) => {
  const fileExtension = getFileExtension(fileName);

  const isMatched = extensions.some((extension) => extension === fileExtension);

  return isMatched;
};
