import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';

import { useGetChargePointGroupsQuery } from 'entities/group';
import { useGetTariffsQuery } from 'entities/tariff';

import { ChargePointsGroupForm } from '../form';

type Props = Omit<
  React.ComponentProps<typeof ChargePointsGroupForm>,
  'groups' | 'tariffs'
>;

export function ConnectedChargePointsGroupForm({ ...props }: Props) {
  const {
    data: groups,
    isFetching: isGroupsFetching,
    error: groupsError,
  } = useGetChargePointGroupsQuery();

  const {
    data: tariffs,
    isFetching: isTariffsFetching,
    error: tariffsError,
  } = useGetTariffsQuery();

  if (isGroupsFetching || isTariffsFetching) {
    return <div>Loading...</div>;
  }

  const apiResponseError = [groups, tariffs].find(
    (el) => el && el.statusCode !== 0
  );

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusDescription} />;
  }

  const error = [groupsError, tariffsError].find((err) => err !== undefined);

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!groups || !tariffs) {
    return <EmptyData />;
  }

  return (
    <ChargePointsGroupForm
      {...props}
      groups={groups.groups}
      tariffs={tariffs.chargeTariffs}
    />
  );
}
