import { Col, Form, FormProps } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  checkMaxLengthRule,
  formHasErrors,
  TELEGRAMM_MAX_LENGTH,
  VALIDATION_RULE,
} from 'shared/lib';
import { UiFormCheckbox, UiInput, UiSelect } from 'shared/ui/ui-kit';

import {
  ButtonsContainer,
  UiSubmitButton,
  UiEditFormButton,
  UiCancelFormButton,
  FormControlsContainer,
} from 'shared/ui';

import {
  selectTokenRoles,
  selectTokenClaims,
  checkControllerRight,
} from 'entities/authorization';

import { CardFormWrapper, FormItem, FormRow } from '../../../../common-styles';

import type { CpoFormData, CpoFormProps } from '../../model';

export function CpoForm({
  title,
  handleSubmit,
  initialValues,
  loading,
  disabled = false,
  ocpiHubs,
  owners,
}: CpoFormProps) {
  const roles = useSelector(selectTokenRoles);
  const claims = useSelector(selectTokenClaims);

  const hasWriteRight = checkControllerRight('Cpo', 'Write', roles, claims);

  const [isFormDisabled, setIsFormDisabled] = useState(disabled);

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [hasErrors, setHasErrors] = useState(false);

  const [form] = Form.useForm<CpoFormData>();

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  const onFieldsChange: FormProps<CpoFormData>['onFieldsChange'] = (
    _,
    _allFields
  ) => {
    setHasErrors(formHasErrors(form));
  };

  const onValuesChange: FormProps<CpoFormData>['onValuesChange'] = (
    _,
    allValues
  ) => {
    if (
      allValues.name !== '' &&
      allValues.ownerId !== '' &&
      allValues.ocpiHubId !== ''
    ) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  const primaryButton = isFormDisabled ? (
    <UiEditFormButton onClick={() => setIsFormDisabled(false)} />
  ) : (
    <UiSubmitButton disabled={isSubmitDisabled || hasErrors} />
  );

  let formControls: JSX.Element | null = null;

  if (hasWriteRight) {
    formControls = (
      <FormControlsContainer>
        <ButtonsContainer spinning={loading}>
          {primaryButton}
          <UiCancelFormButton />
        </ButtonsContainer>
      </FormControlsContainer>
    );
  }

  return (
    <CardFormWrapper title={title}>
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={handleSubmit}
        onFieldsChange={onFieldsChange}
        onValuesChange={onValuesChange}
        initialValues={initialValues}
      >
        <FormRow>
          <Col span={24}>
            <FormItem label="ID" name="id">
              <UiInput disabled />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow>
          <Col span={24}>
            <FormItem
              label="Наименование"
              name="name"
              rules={[VALIDATION_RULE.REQUIRED]}
            >
              <UiInput disabled={isFormDisabled} />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow>
          <Col span={24}>
            <FormItem
              label="OCPI Хаб"
              name="ocpiHubId"
              rules={[VALIDATION_RULE.REQUIRED]}
            >
              <UiSelect
                disabled={isFormDisabled}
                options={ocpiHubs.map(({ id, name }) => ({
                  value: String(id),
                  label: name,
                }))}
              />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow>
          <Col span={24}>
            <FormItem
              label="Владелец ЭЗС"
              name="ownerId"
              rules={[VALIDATION_RULE.REQUIRED]}
            >
              <UiSelect
                disabled={isFormDisabled}
                options={owners.map(({ id, name }) => ({
                  value: String(id),
                  label: name,
                }))}
              />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow>
          <Col span={24}>
            <FormItem label="partyId" name="partyId">
              <UiInput disabled={isFormDisabled} />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow>
          <Col span={24}>
            <FormItem label="Юридическое лицо" name="legalEntity">
              <UiInput disabled={isFormDisabled} />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow>
          <Col span={24}>
            <FormItem label="Контакты" name="contacts">
              <UiInput disabled={isFormDisabled} />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow>
          <Col span={24}>
            <FormItem label="Описание" name="description">
              <UiInput disabled={isFormDisabled} />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow>
          <Col span={24}>
            <FormItem label="ИНН" name="inn">
              <UiInput disabled={isFormDisabled} />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow>
          <Col>
            <FormItem name="mapShow" valuePropName="checked">
              <UiFormCheckbox disabled={isFormDisabled}>
                Показывать на карте
              </UiFormCheckbox>
            </FormItem>
          </Col>
        </FormRow>
        <FormRow>
          <Col span={24}>
            <FormItem label="diplink" name="diplink">
              <UiInput disabled={isFormDisabled} />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow>
          <Col span={24}>
            <FormItem label="icon" name="icon">
              <UiInput disabled={isFormDisabled} />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow>
          <Col span={24}>
            <FormItem label="tariff" name="tariff">
              <UiInput disabled={isFormDisabled} />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow>
          <Col span={24}>
            <FormItem
              label="Телефон"
              name="phone"
              rules={[VALIDATION_RULE.PHONE_NUMBER]}
            >
              <UiInput disabled={isFormDisabled} />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow>
          <Col span={24}>
            <FormItem
              label="Телеграм"
              name="telegramm"
              rules={[
                VALIDATION_RULE.TELEGRAMM,
                checkMaxLengthRule(TELEGRAMM_MAX_LENGTH),
              ]}
            >
              <UiInput disabled={isFormDisabled} />
            </FormItem>
          </Col>
        </FormRow>
        {formControls}
      </Form>
    </CardFormWrapper>
  );
}
