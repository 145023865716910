import { OwnerFormData } from '../model';

export const prepareRequestData = (values: OwnerFormData) => {
  const { tariffId, ...rest } = values;

  return {
    ...rest,
    tariffId: Number(tariffId),
  };
};
