import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { getServerErrorText } from 'shared/lib';
import { EmptyData, ErrorMessage } from 'shared/ui';

import { useGetRoleQuery } from 'entities/role';

import { RoleUsersTableInstance } from './table';

export function ConnectedRoleUsersTable() {
  const { roleName } = useParams() as { roleName: string };

  const { isFetching, error, data } = useGetRoleQuery(roleName);

  const tableData = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.role.users;
  }, [data]);

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!isFetching && !data) {
    return <EmptyData />;
  }

  return (
    <RoleUsersTableInstance
      loading={isFetching}
      tableData={tableData}
      rowsCount={data?.role.users.length}
    />
  );
}
