import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import useMeasure from 'react-use-measure';

import { ErrorMessage } from 'shared/ui';
import { getServerErrorText, useTablePageSize } from 'shared/lib';

import { useGetPersonsQuery } from 'entities/person';

import { PersonsListTableInstance } from './table';

type Props = {
  groupId?: string;
};

export function ConnectedPersonsListTable({ groupId }: Props) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [tableHeadRef, tableHeadBounds] = useMeasure();

  const [tablePageSize, setTablePageSize] = useTablePageSize(tableHeadBounds);

  const ordering = searchParams.get('ordering');
  const type = searchParams.get('type');
  const phone = searchParams.get('phone');
  const page = searchParams.get('page');
  const limit = searchParams.get('limit');
  const search = searchParams.get('search');
  const searchText = searchParams.get('searchText');

  const { isFetching, error, data } = useGetPersonsQuery(
    {
      limit: limit || String(tablePageSize),
      ordering,
      page: page && page !== '1' ? String(Number(page) - 1) : null,
      // page: page || String(1), // во всех остальных
      type,
      // phone: phone !== null ? phone : '',
      phone,
      groupId,
      search,
      searchText,
    },
    {
      skip: !limit && !tablePageSize,
    }
  );

  const tableData = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.result;
  }, [data]);

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  return (
    <PersonsListTableInstance
      tableData={tableData}
      loading={isFetching}
      count={data?.count}
      tableHeadRef={tableHeadRef}
      tablePageSize={tablePageSize}
      groupId={groupId}
    />
  );
}
