import { Sidebar } from 'shared/ui/sidebar';
import { SiderbarMenuItem } from 'shared/ui/sidebar/types';

import { RolesIcon } from 'shared/ui/sidebar/images/roles';
import { UsersIcon } from 'shared/ui/sidebar/images/users';
import { GroupsIcon } from 'shared/ui/sidebar/images/groups';
import { ROLES } from 'shared/consts/routes/role';
import { USERS } from 'shared/consts/routes/user';
import { GROUPS } from 'shared/consts/routes/group';

import { LogoutButton } from 'features/authorization/logout';

const ITEMS: SiderbarMenuItem[] = [
  {
    label: 'Пользователи',
    path: USERS,
    icon: <UsersIcon />,
    controller: 'Identity',
  },
  {
    label: 'Группы',
    path: GROUPS,
    icon: <GroupsIcon />,
    controller: 'Identity',
  },
  {
    label: 'Роли',
    path: ROLES,
    icon: <RolesIcon />,
    controller: 'Identity',
  },
];

export function SecuritySidebar() {
  return <Sidebar logoutButton={<LogoutButton />} items={ITEMS} />;
}
