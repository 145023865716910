import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { UiModal } from 'shared/ui';

import { BonusProgramLevel } from 'entities/bonus-program';

import { EditBonusLevel } from '../edit';

type Props = {
  level: BonusProgramLevel;
};

export const EditBonusLevelModal = NiceModal.create((props: Props) => {
  const modal = useModal();

  return (
    <UiModal
      visible={modal.visible}
      onCancel={modal.hide}
      afterClose={modal.remove}
      title={props.level.name}
    >
      <EditBonusLevel level={props.level} closeModal={modal.hide} />
    </UiModal>
  );
});
