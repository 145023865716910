import {
  ConsumeReportGroupByRequestArg,
  PaymentsReportGroupByRequestArg,
} from '../api/types';

export const CONSUME_REPORT_GROUP_BY_TYPE: Record<
  ConsumeReportGroupByRequestArg,
  string
> = {
  0: 'по дням',
  1: 'по месяцам',
  2: 'за период',
};

export const PAYMENTS_REPORT_GROUP_BY_TYPE: Record<
  PaymentsReportGroupByRequestArg,
  string
> = {
  1: 'по дням',
  2: 'по месяцам',
  3: 'за период',
};

export const REPORTS = [
  { id: 'acquiring', name: 'Эквайринг' },
  { id: 'consume', name: 'Потребление' },
  { id: 'payments', name: 'Выручка' },
  { id: 'debit', name: 'Задолженность' },
];

export const DEFAULT_REPORT_COLUMN_WIDTH = 23;
