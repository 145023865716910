import { useNavigate } from 'react-router-dom';

import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';

import {
  ConnectedPersonForm,
  PersonFormData,
  prepareRequestData,
} from 'entities/person';

import { useCustomAddPersonMutation } from '../../../../redux/services/persons';

import { ADD_ERROR, ADD_SUCCESS } from '../consts';
import { PERSON_ROUTES } from 'shared/consts/routes/person';

const initialValues: PersonFormData = {
  id: '',
  registrationDate: '',
  login: '',
  email: '',
  chargingClientState: '',
  clientBalance: '0',
  clientsDebt: '0',
  idGroup: '',
  commentary: '',
  countryId: '',
  userAgreementFlag: false,
  isBankCardAttached: false,
  isPushAvailable: false,
  name: '',
  chargingLimitBalance: '0',
  companyId: '',
  chargingClientType: '',
  isDebug: false,
  firstName: '',
  lastName: '',
  patronymic: '',
  dateOfBirth: '',
  sex: '0',
  spendBonuses: false,
};

export function AddPerson() {
  const [addTrigger, { isLoading }] = useCustomAddPersonMutation();

  const navigate = useNavigate();

  const handleSubmit = async (values: PersonFormData) => {
    const { id, ...req } = prepareRequestData(values);

    try {
      const res = await addTrigger(req).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);

        return;
      }
      //

      openSuccessNotification(ADD_SUCCESS);

      navigate(PERSON_ROUTES.PERSONS_LIST);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : ADD_ERROR;

      openErrorNotification(errorText);
    }
  };

  return (
    <ConnectedPersonForm
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      loading={isLoading}
      title={'Добавление нового пользователя'}
    />
  );
}
