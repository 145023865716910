import React from 'react';

import { ErrorMessage, EmptyData } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';

import { useGetChargePointPhotoUrlsQuery } from 'entities/charge-point-photo';

import { ChargePointPhotosCard } from '../photos-card';

type Props = {
  chargePointName: string;
};

export function ConnectedChargePointPhotos({ chargePointName }: Props) {
  const { data, isFetching, error } =
    useGetChargePointPhotoUrlsQuery(chargePointName);

  if (isFetching) {
    return <div>Loading...</div>;
  }

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!data) {
    return <EmptyData />;
  }

  return (
    <ChargePointPhotosCard
      photoUrls={data.photoUrls}
      chargePointName={chargePointName}
    />
  );
}

export default React.memo(ConnectedChargePointPhotos);
