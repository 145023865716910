import { ErrorMessage, EmptyData } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';

import { useGetChargePointPhotoQuery } from 'entities/charge-point-photo';

import { ChargePointPhotoList } from '../photos-list';

type Props = {
  chargePointName: string;
};

export function ConnectedPhotosList({ chargePointName }: Props) {
  const { data, isFetching, error } =
    useGetChargePointPhotoQuery(chargePointName);

  if (isFetching) {
    return <div>Loading...</div>;
  }

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!data) {
    return <EmptyData />;
  }

  return (
    <>
      <ChargePointPhotoList
        photos={data.photo}
        chargePointName={chargePointName}
      />
    </>
  );
}
