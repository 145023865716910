import { Form, FormProps, Spin } from 'antd';

import { useState } from 'react';

import { AdvancedProperty } from 'entities/charge-point';

import {
  BaseStyledCard,
  FormButtonsContainer,
  FormItem,
  StyledButton,
} from '../../../../../../common-styles';

import { ChargePointFormData } from '../../../../types';
import {
  LabelsRow,
  DataRow,
  KeyCol,
  Label,
  ValueCol,
  Title,
  ParameterName,
  StyledSwitch,
  AdvancedPropertyFormWrapper,
} from './styles';

const ADVANCED_PROPERTY: Record<keyof AdvancedProperty, string> = {
  hour24: 'Наличие круглосуточного режима',
  hotel: 'Наличие отеля',
  parking: 'Наличие парковки',
  shop: 'Наличие магазина',
  cafe: 'Наличие кафе',
  closeAccess: 'Наличие закрытого доступа',
  park: 'Наличие парка',
  wc: 'Наличие туалета',
  shoppingMall: 'Наличие торгового центра',
  wifi: 'Наличие Wi-Fi', // Уточнить про QR для въезда
};

interface ExtraInfoFormProps {
  initialValues: ChargePointFormData['advancedProperty'];
  onCancel: () => void;
  handleSubmit: (values: ChargePointFormData['advancedProperty']) => void;
  loading: boolean;
  editing: boolean;
}

export function AdvancedPropertyForm({
  initialValues,
  onCancel,
  handleSubmit,
  loading = false,
  editing,
}: ExtraInfoFormProps) {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(editing);

  const onValuesChange: FormProps<AdvancedProperty>['onValuesChange'] = (
    _,
    allValues
  ) => {
    setIsSubmitDisabled(false);
  };

  return (
    <BaseStyledCard>
      <Title>Дополнительная информация</Title>
      <LabelsRow>
        <KeyCol>
          <Label>Параметр</Label>
        </KeyCol>
        <ValueCol>
          <Label>Значение</Label>
        </ValueCol>
      </LabelsRow>
      <AdvancedPropertyFormWrapper>
        <Form
          layout="vertical"
          autoComplete="off"
          onFinish={handleSubmit}
          onValuesChange={onValuesChange}
          initialValues={initialValues}
        >
          {Object.keys(ADVANCED_PROPERTY).map((prop) => (
            <DataRow key={prop}>
              <KeyCol>
                <ParameterName>
                  {ADVANCED_PROPERTY[prop as keyof AdvancedProperty]}
                </ParameterName>
              </KeyCol>
              <ValueCol>
                <FormItem name={prop} valuePropName="checked">
                  <StyledSwitch />
                </FormItem>
              </ValueCol>
            </DataRow>
          ))}
          <Spin spinning={loading}>
            <FormButtonsContainer>
              <StyledButton
                htmlType="submit"
                type="primary"
                disabled={isSubmitDisabled}
              >
                {editing ? 'Сохранить' : 'Далее'}
              </StyledButton>
              <StyledButton htmlType="button" type="default" onClick={onCancel}>
                Отмена
              </StyledButton>
            </FormButtonsContainer>
          </Spin>
        </Form>
      </AdvancedPropertyFormWrapper>
    </BaseStyledCard>
  );
}
