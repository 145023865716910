import { Route } from 'react-router-dom';

import { SectionLayout } from 'shared/ui/layout';
import { HUB, HUBS } from 'shared/consts/routes/hub';
import { ADD_PREFIX } from 'shared/consts/routes/common';

import { RequireAuth } from 'entities/authorization';
import { ConnectedHubsMenu } from 'entities/hub';

import { AddHubPage, EditHubPage } from 'pages/hub';

export const HubRoutes = (
  <Route element={<RequireAuth controllerName="Hub" rightType="Read" />}>
    <Route path={HUBS} element={<SectionLayout menu={<ConnectedHubsMenu />} />}>
      <Route element={<RequireAuth controllerName="Hub" rightType="Write" />}>
        <Route path={ADD_PREFIX} element={<AddHubPage />} />
      </Route>
      <Route path={HUB} element={<EditHubPage />} />
    </Route>
  </Route>
);
