import { Col, Form, FormProps } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import 'dayjs/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';
import dayjs from 'dayjs';

import { DATE_FORMAT_REVERSE } from 'shared/lib/date';

import {
  GetReportButton,
  ReportFormWrapper,
  ReportRangePicker,
} from 'entities/report';

import { FormItem, FormRow } from '../../../../common-styles';
import { VALIDATION_RULE } from '../../../../constants/validation-rules';
import { dayjsDatePickerFormat } from 'shared/ui/dayjs-date-picker';
import { ArrowRightIcon } from 'entities/report/images/arrow-right-icon';

interface DebitReportFormData {
  dateRange: [dayjs.Dayjs, dayjs.Dayjs];
}

type Props = {
  allowReportFetching: () => void;
  loading: boolean;
};

export function DebitReportForm({ allowReportFetching, loading }: Props) {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();

  const dateFromParam = searchParams.get('dateFrom');
  const dateToParam = searchParams.get('dateTo');

  const [form] = Form.useForm<DebitReportFormData>();

  useEffect(() => {
    if (dateFromParam && dateToParam) {
      setIsSubmitDisabled(false);
    }
  }, [dateFromParam, dateToParam]);

  useEffect(() => {
    const initialValues = getInitialValues();

    form.setFieldsValue(initialValues);
  }, [dateFromParam, dateToParam]);

  const getInitialValues = () => {
    const dateRange =
      dateFromParam && dateToParam
        ? [dayjs(dateFromParam), dayjs(dateToParam)]
        : undefined;

    return {
      dateRange,
    };
  };

  const onValuesChange: FormProps<DebitReportFormData>['onValuesChange'] = (
    _,
    allValues
  ) => {
    if (allValues.dateRange !== undefined) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  const handleSubmit = (values: DebitReportFormData) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());

    const { dateRange } = values;

    updatedSearchParams.set(
      'dateFrom',
      dateRange[0].format(DATE_FORMAT_REVERSE)
    );
    updatedSearchParams.set('dateTo', dateRange[1].format(DATE_FORMAT_REVERSE));

    setSearchParams(updatedSearchParams.toString());

    allowReportFetching();
  };

  return (
    <ReportFormWrapper>
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={handleSubmit}
        onValuesChange={onValuesChange}
      >
        <FormRow gutter={15}>
          <Col>
            <FormItem
              label="Период от и до"
              name="dateRange"
              rules={[VALIDATION_RULE.REQUIRED]}
            >
              <ReportRangePicker
                locale={locale}
                separator={'-'}
                allowClear={false}
                suffixIcon={<ArrowRightIcon />}
                placeholder={['Дата начала', 'Дата конца']}
                format={dayjsDatePickerFormat}
              />
            </FormItem>
          </Col>
        </FormRow>
        <GetReportButton disabled={isSubmitDisabled} spinning={loading} />
      </Form>
    </ReportFormWrapper>
  );
}
