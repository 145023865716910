import { Input } from 'antd';

import styled from 'styled-components';

import { COLOR } from 'shared/consts';

// TODO:
export const StyledInput = styled(Input)`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR.black};
  padding: 7px;
  border: 0.5px solid ${COLOR.inputGrey};
  border-radius: 2px;
  height: 29px;
`;
//

export const StyledPaginationJumper = styled(StyledInput)`
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
`;
