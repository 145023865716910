import React, { useMemo } from 'react';

import {
  ChargePointDTO,
  DeviceErrorsCard,
  TechInfoCard,
} from 'entities/charge-point';
import { AdvancedPropertiesCard } from 'entities/charge-point/ui/advanced-properties';

import { ConnectedChargedTotal } from 'features/charge-point/charged-total';

import { BaseInfoWidget } from 'widgets/charge-point/base-info';
import { ConnectorsListWidget } from 'widgets/connector/connectors-list';
import { ConnectedChargePointPhotos } from 'widgets/charge-point/photos';
import { LocationCard } from 'widgets/charge-point/location/ui';

import {
  ChargedSummaryContainer,
  ChargePointInfoGridLayout,
  GridColumnContainer,
} from './styles';

type Props = {
  chargePointName: string;
  chargePoint: ChargePointDTO;
};

export function ChargePointInfo({ chargePointName, chargePoint }: Props) {
  const {
    id,
    name,
    type,
    maxPower,
    address,
    connectors,
    chargePointProtocol,
    status,
    owner,
    transactionId,
    advancedProperty,
  } = chargePoint;

  const tariff = owner.tariff ?? null;

  const deviceErrors = useMemo(() => chargePoint.deviceErrors, [chargePoint]);

  return (
    <>
      <ChargePointInfoGridLayout>
        <BaseInfoWidget
          name={name}
          status={status}
          type={type}
          maxPower={maxPower}
          address={address}
          ownerName={chargePointProtocol === 5 ? owner.name : ''}
          tariff={tariff}
          chargePointId={id}
        />
        <ConnectorsListWidget
          chargePointName={chargePointName}
          chargePointId={id}
          transactionId={transactionId}
          connectors={connectors}
          tariff={tariff}
        />
        <ChargedSummaryContainer>
          <ConnectedChargedTotal
            key={connectors.length}
            chargePointName={chargePointName}
          />
        </ChargedSummaryContainer>
        <GridColumnContainer className="grid-col-span-2">
          <TechInfoCard chargePoint={chargePoint} />
          <DeviceErrorsCard deviceErrors={deviceErrors} />
        </GridColumnContainer>
        <GridColumnContainer>
          <LocationCard chargePoint={chargePoint} address={address} />
          <AdvancedPropertiesCard advancedProperty={advancedProperty} />
          <ConnectedChargePointPhotos chargePointName={chargePointName} />
        </GridColumnContainer>
      </ChargePointInfoGridLayout>
    </>
  );
}

export default React.memo(ChargePointInfo);
