import styled from 'styled-components';
import { Button } from 'antd';

export const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  height: auto;

  ${({ styles }) => ({ ...styles })}
`;
