import { Select } from 'antd';
import { useSearchParams } from 'react-router-dom';

import { toCurrencyUnit } from 'shared/lib';

import { BalanceResponseItemDto } from 'entities/balance/model/bonus';
import { BankDTO } from 'entities/bank';

import { InfoBlock, InfoValue, StyledTransactionLogHeader } from './styles';

type Props = {
  banks: BankDTO[];
  bankId: string;
  balances: Array<BalanceResponseItemDto> | undefined;
  personLogin: string;
};

export function TransactionLogHeader({
  banks,
  bankId,
  balances,
  personLogin,
}: Props) {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSelect = (value: string) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());

    updatedSearchParams.set('bankId', value);

    setSearchParams(updatedSearchParams.toString());
  };

  const balance = balances?.find((el) => String(el.bankId) === bankId);

  return (
    <StyledTransactionLogHeader>
      <InfoBlock>
        Пользователь <InfoValue>{personLogin}</InfoValue>
      </InfoBlock>
      {balance ? (
        <InfoBlock>
          Баланс: <InfoValue>{toCurrencyUnit(balance.sum)}</InfoValue>
        </InfoBlock>
      ) : null}
      <InfoBlock>
        Банк:{' '}
        <Select
          value={bankId}
          onChange={handleSelect}
          options={banks.map(({ bankId, name }) => ({
            value: String(bankId),
            label: name,
          }))}
        />
      </InfoBlock>
    </StyledTransactionLogHeader>
  );
}
