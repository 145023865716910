import { Col, Form, FormProps, Spin } from 'antd';
import { useState } from 'react';

import { openErrorNotification, openSuccessNotification } from 'shared/lib';
import { UiInput, UiSelect } from 'shared/ui/ui-kit';

import {
  AvailabilityType,
  ManualCommandType,
  MANUAL_COMMAND_STATUS,
} from 'entities/manual-command';

import { ADD_ERROR, ADD_SUCCESS } from '../../../consts';

import {
  FormButtonsContainer,
  FormItem,
  FormRow,
  StyledButton,
} from '../../../../../common-styles';

import { useSendCommandMutation } from '../../../../../redux/services/private-methods';
import { VALIDATION_RULE } from '../../../../../constants/validation-rules';

interface FormData {
  chargePointName: string;
  connectorId: string;
  status: AvailabilityType | '';
}

type Props = {
  commandType: ManualCommandType;
  cpName: string;
  connectorId?: string;
  closeModal: () => void;
  isDisabled?: boolean;
};

export function ChangeAvailabilityForm({
  closeModal,
  isDisabled = true,
  commandType,
  cpName,
  connectorId,
}: Props) {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(isDisabled);

  const [sendCommandTrigger, { isLoading }] = useSendCommandMutation();

  const [form] = Form.useForm<FormData>();

  const onValuesChange: FormProps<FormData>['onValuesChange'] = (
    _,
    allValues
  ) => {
    if (
      allValues.chargePointName !== '' &&
      allValues.connectorId !== '' &&
      allValues.status !== ''
    ) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  const handleSubmit = async (values: FormData) => {
    const req = {
      command: commandType,
      cpName: values.chargePointName,
      body: { connectorId: values.connectorId, status: values.status },
    };

    try {
      const res = await sendCommandTrigger(req).unwrap();

      res.statusCode === 0
        ? openSuccessNotification(ADD_SUCCESS)
        : openErrorNotification(res.statusDescription);
    } catch (e) {
      openErrorNotification(ADD_ERROR);
    } finally {
      closeModal();
    }
  };

  const getInitialValues = () => {
    return {
      chargePointName: cpName,
      status: '',
      connectorId,
    };
  };

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onFinish={handleSubmit}
      onValuesChange={onValuesChange}
      initialValues={getInitialValues()}
    >
      <FormRow gutter={10}>
        <Col span={12}>
          <FormItem
            label="Наименование ЭЗС"
            name="chargePointName"
            rules={[VALIDATION_RULE.REQUIRED]}
          >
            <UiInput />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            label="ID коннектора"
            name="connectorId"
            rules={[VALIDATION_RULE.REQUIRED]}
          >
            <UiInput />
          </FormItem>
        </Col>
      </FormRow>
      <FormRow gutter={10}>
        <Col span={24}>
          <FormItem
            label="Статус"
            name="status"
            rules={[VALIDATION_RULE.REQUIRED]}
          >
            <UiSelect
              options={Object.entries(MANUAL_COMMAND_STATUS).map(
                ([value, label]) => ({
                  value,
                  label,
                })
              )}
            />
          </FormItem>
        </Col>
      </FormRow>
      <FormButtonsContainer>
        <Spin spinning={isLoading}>
          <StyledButton
            htmlType="submit"
            type="primary"
            disabled={isSubmitDisabled}
          >
            Добавить
          </StyledButton>
        </Spin>
        <StyledButton htmlType="button" type="default" onClick={closeModal}>
          Отмена
        </StyledButton>
      </FormButtonsContainer>
    </Form>
  );
}
