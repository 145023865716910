import { UploadOutlined } from '@ant-design/icons';
import { Col, Form, FormProps, Space, Spin, Upload } from 'antd';
import { UploadProps, UploadFile } from 'antd/es/upload/interface';
import { useState } from 'react';

import {
  fileExtensionsValidationRule,
  formHasErrors,
  VALIDATION_RULE,
  getExtensionsString,
  hasAcceptedExtension,
  isFileLessThan,
  maxFileSizeValidationRule,
  normFile,
} from 'shared/lib';
import { UploadedImage } from 'shared/ui';
import { UiInput } from 'shared/ui/ui-kit';

import {
  FormButtonsContainer,
  FormItem,
  FormRow,
  StyledButton,
  StyledDraggerFormItem,
} from '../../../../common-styles';

import { ChargePointPhotoFormData } from '../../model/form';
import { DraggerText, DraggerTextWrapper } from './styles';

import {
  ACCEPTED_IMAGE_EXTENSIONS,
  IMAGE_EXTENSION_ERROR,
  IMAGE_MAX_SIZE,
  IMAGE_SIZE_ERROR,
} from '../../consts';

const getFileValidationRules = (allowEmpty: boolean) => {
  const commonRules = [
    fileExtensionsValidationRule(ACCEPTED_IMAGE_EXTENSIONS),
    maxFileSizeValidationRule(IMAGE_MAX_SIZE),
  ];

  if (allowEmpty) {
    return commonRules;
  }

  return [VALIDATION_RULE.REQUIRED, ...commonRules];
};

type Props = {
  handleSubmit: (values: ChargePointPhotoFormData) => void;
  onCancelClick: () => void;
  loading: boolean;
  multiple?: boolean;
  allowEmptyFile?: boolean;
  initialValues: ChargePointPhotoFormData;
};

const accepted = getExtensionsString(ACCEPTED_IMAGE_EXTENSIONS);

export function ChargePointPhotoForm({
  loading,
  initialValues,
  onCancelClick,
  handleSubmit,
  multiple = false,
  allowEmptyFile = false,
}: Props) {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [hasErrors, setHasErrors] = useState(false);

  const [form] = Form.useForm<ChargePointPhotoFormData>();

  const deleteFile = async (uid: string) => {
    const files = form.getFieldValue('photos') as UploadFile<any>[];

    const updatedFiles = files.filter((file) => file.uid !== uid);

    form.setFieldValue('photos', updatedFiles);

    form.validateFields(['photos']);

    setHasErrors(formHasErrors(form));

    if (!allowEmptyFile && updatedFiles.length === 0) {
      setIsSubmitDisabled(true);
    }
  };

  const props: UploadProps = {
    multiple,
    accept: accepted,
    beforeUpload: (file, list) => {
      return false;
    },
    itemRender: (originNode, file, fileList) => {
      let errors: string[] = [];

      if (!hasAcceptedExtension(ACCEPTED_IMAGE_EXTENSIONS, file.name)) {
        errors = [...errors, IMAGE_EXTENSION_ERROR];
      }

      if (file.size && !isFileLessThan(file.size, IMAGE_MAX_SIZE)) {
        errors = [...errors, IMAGE_SIZE_ERROR];
      }

      return (
        <UploadedImage
          imageName={file.name}
          imageSrc={URL.createObjectURL(file.originFileObj)}
          errors={errors}
          handleDelete={() => deleteFile(file.uid)}
        />
      );
    },
  };

  const onValuesChange: FormProps<ChargePointPhotoFormData>['onValuesChange'] =
    (_, allValues) => {
      if (
        (allowEmptyFile ? true : allValues.photos.length !== 0) &&
        allValues.priority !== ''
      ) {
        setIsSubmitDisabled(false);
      } else {
        setIsSubmitDisabled(true);
      }
    };

  const onFieldsChange: FormProps<ChargePointPhotoFormData>['onFieldsChange'] =
    (_, _allFields) => {
      setHasErrors(formHasErrors(form));
    };

  const priorityLabel = !allowEmptyFile
    ? 'Приоритет первой фотографии'
    : 'Приоритет';

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      initialValues={initialValues}
      onFinish={handleSubmit}
      onValuesChange={onValuesChange}
      onFieldsChange={onFieldsChange}
    >
      <FormRow>
        <Col span={24}>
          <FormItem
            label={priorityLabel}
            name="priority"
            rules={[
              VALIDATION_RULE.REQUIRED,
              VALIDATION_RULE.NULL_OR_POSITIVE_NUMBER,
            ]}
          >
            <UiInput />
          </FormItem>
        </Col>
      </FormRow>
      <FormRow>
        <Col span={24}>
          <StyledDraggerFormItem>
            <FormItem
              label="Фото"
              name="photos"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              // getValueFromEvent={(event) => event.fileList}
              rules={getFileValidationRules(allowEmptyFile)}
            >
              <Upload.Dragger {...props}>
                <DraggerTextWrapper>
                  <DraggerText>Поддерживаемые форматы:</DraggerText>
                  <DraggerText>{accepted}</DraggerText>
                </DraggerTextWrapper>
                <StyledButton
                  htmlType="button"
                  type="primary"
                  icon={<UploadOutlined />}
                  style={{ margin: '0 auto' }}
                >
                  Выбрать файл(ы)
                </StyledButton>
              </Upload.Dragger>
            </FormItem>
          </StyledDraggerFormItem>
        </Col>
      </FormRow>

      <Spin spinning={loading}>
        <FormButtonsContainer>
          <Space direction="horizontal" size={10}>
            <StyledButton
              htmlType="submit"
              type="primary"
              disabled={isSubmitDisabled || hasErrors}
            >
              Сохранить
            </StyledButton>
            <StyledButton
              htmlType="button"
              type="default"
              onClick={onCancelClick}
            >
              Отмена
            </StyledButton>
          </Space>
        </FormButtonsContainer>
      </Spin>
    </Form>
  );
}
