import { Form, Button, Layout } from 'antd';
import styled from 'styled-components';

import { COLOR } from 'shared/consts';

export const LoginFormInner = styled.div``;

export const LoginFormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const LoginFormHeader = styled.div`
  margin-bottom: 37px;
  text-align: center;

  @media (max-width: 375px) {
    margin-bottom: 12px;
    text-align: unset;
  }
`;

export const LoginFormLogoContainer = styled.div`
  background-color: #3b3838;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 29px auto;

  @media (max-width: 375px) {
    display: none;
  }
`;

export const LoginFormTitle = styled.h1`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: ${COLOR.black};
  margin-bottom: 10px;

  @media (max-width: 375px) {
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 8px;
  }
`;

export const LoginFormSubtitle = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR.darkGrey};

  @media (max-width: 375px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const StyledButton = styled(Button)`
  width: 100%;
  height: auto;
  text-align: center;
  padding-top: 13px;
  padding-bottom: 12px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 33px;
`;

export const StyledLayout = styled.main`
  background-color: ${COLOR.white};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 20px;
  min-height: 100vh;

  @media (max-width: 375px) {
    padding: 12px 24px 24px;
    display: block;
  }
`;
