import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { Spin } from 'antd';
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/ru_RU';

import { ErrorMessage, EmptyData, RangePickerProps } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';
import { ArrowDownIcon } from 'shared/icons/arrow-down';

import {
  getMetricsLineChartData,
  getValueSummaryInfo,
  GraphMetricType,
} from 'entities/metrics';
import { GraphMetricsResponse } from 'entities/metrics/api/types';

import { LineChart } from '../../../../shared/ui/charts/line';

import { colorParams, scalesOptions } from '../../constants';
import {
  CardHeader,
  CardContent,
  SelectStylesContainer,
  ValueSummary,
  MonitoringMetricsContainer,
  RangePickerContainer,
  StyledRangePicker,
  MonitoringMetricsCardContainer,
  LoaderContainer,
} from './styles';

import { customFormat } from '../../../../constants/picker';

type Props = {
  loading: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
  data: GraphMetricsResponse | undefined;
  dateFrom: dayjs.Dayjs;
  dateTo: dayjs.Dayjs;
  type: GraphMetricType;
  onChange: (values: RangePickerProps['value']) => void;
  onOpenChange: (open: boolean) => void;
};

export function MonitoringMetricsCard({
  dateFrom,
  dateTo,
  loading,
  error,
  data,
  type,
  onChange,
  onOpenChange,
}: Props) {
  let content;

  if (loading) {
    content = (
      <LoaderContainer>
        <Spin spinning>Загрузка данных...</Spin>
      </LoaderContainer>
    );
  } else if (error) {
    content = <ErrorMessage text={getServerErrorText(error)} />;
  } else if (data && data.statusCode !== 0) {
    content = <ErrorMessage text={data.statusDescription} />;
  } else if (!data) {
    content = <EmptyData />;
  } else {
    const chartData = getMetricsLineChartData(data.graphMetrics, colorParams);

    const valueSummaryInfo = getValueSummaryInfo(type, data.graphMetrics);

    content = (
      <>
        <RangePickerContainer>
          <StyledRangePicker
            locale={locale}
            separator={'-'}
            allowClear={false}
            suffixIcon={<ArrowDownIcon />}
            value={[dateFrom, dateTo]}
            format={customFormat}
            onChange={onChange}
            onOpenChange={onOpenChange}
          />
        </RangePickerContainer>
        <MonitoringMetricsCardContainer>
          <CardHeader>
            <ValueSummary>{valueSummaryInfo}</ValueSummary>
          </CardHeader>
          <CardContent>
            <LineChart data={chartData} scaleOptions={scalesOptions} />
          </CardContent>
        </MonitoringMetricsCardContainer>
      </>
    );
  }

  return <MonitoringMetricsContainer>{content}</MonitoringMetricsContainer>;
}
