import { useMemo } from 'react';

import { ErrorMessage, EmptyData } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';

import { useGetOwnersQuery } from 'entities/owner';

import { OwnersTableInstance } from './table';

export function ConnectedOwnersTable() {
  const { data, isFetching, error } = useGetOwnersQuery();

  const tableData = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.owners;
  }, [data]);

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!isFetching && !data) {
    return <EmptyData />;
  }

  return (
    <OwnersTableInstance
      tableData={tableData}
      loading={isFetching}
      rowsCount={data?.owners.length}
    />
  );
}
