import styled from 'styled-components';
import { Menu } from 'antd';

import { COLOR } from 'shared/consts';

export const StyledMenu = styled(Menu)`
  border-right: none;
  flex-grow: 1;

  & .active-link {
    background-color: ${COLOR.lightGreen};
  }
`;

export const MenuItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 76px;
`;

export const IconContainer = styled.div`
  width: 25px;
  height: 25px;
  margin-bottom: 7px;

  & img {
    width: 100%;
    height: 100%;
  }
`;

export const Label = styled.p`
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  color: ${COLOR.black};
`;
