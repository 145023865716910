import Excel from 'exceljs';

import { openErrorNotification } from 'shared/lib';

const REPORT_EXPORT_ERROR = 'Не удалось экспортировать отчет';

export const downloadReport = async (
  wb: Excel.Workbook,
  reportFileName: string
) => {
  try {
    const buffer = await wb.xlsx.writeBuffer();
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_FILE_EXTENSION = '.xlsx';
    const blob = new Blob([buffer], { type: fileType });
    const fileName = `${reportFileName}${EXCEL_FILE_EXTENSION}`;

    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  } catch (err) {
    openErrorNotification(REPORT_EXPORT_ERROR);
  }
};
