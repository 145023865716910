import { NavigationTab } from 'shared/model';
import {
  MANUAL_COMMANDS,
  RESERVES,
  SESSION_LOG,
} from 'shared/consts/routes/common';

import { AddChargePointButtonLink } from 'entities/charge-point';

import { OpenManualCommandButton } from 'features/manual-command/ui/open-manual-command-modal-button';

export const CHARGE_POINTS_GROUP_TABS: Array<NavigationTab> = [
  {
    key: 'monitoring',
    path: '',
    label: 'Мониторинг',
    buttons: [
      {
        element: <AddChargePointButtonLink />,
        controller: 'ChargePoint',
        right: 'Write',
      },
    ],
  },
  {
    key: SESSION_LOG,
    path: SESSION_LOG,
    label: 'Журнал сессий',
    controller: 'PrivateMethods',
    right: 'Read', //
    hasLoader: true,
  },
  {
    key: RESERVES,
    path: RESERVES,
    label: 'Журнал брони',
    controller: 'PrivateMethods',
    right: 'Read', //
  },
  {
    key: MANUAL_COMMANDS,
    path: MANUAL_COMMANDS,
    label: 'Управление',
    controller: 'PrivateMethods',
    right: 'Read', //
    buttons: [
      {
        element: <OpenManualCommandButton />,
        controller: 'PrivateMethods',
        right: 'Execute',
      },
    ],
  },
];
