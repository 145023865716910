import { RcFile } from 'antd/lib/upload/interface';
import { useNavigate, useParams } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';

import { ErrorMessage, EmptyData } from 'shared/ui';
import {
  getServerErrorText,
  openErrorNotification,
  openSuccessNotification,
  isErrorWithMessage,
} from 'shared/lib';

import { DeleteFirmwareModal } from 'features/firmware/delete';

import {
  Firmware,
  FirmwareForm,
  FirmwareFormData,
  prepareRequestData,
  useGetFirmwaresQuery,
  useUpdateFirmwareMutation,
} from 'entities/firmware';

import { EDIT_SUCCESS, EDIT_ERROR } from '../consts';

const getInitialValues = (firmware: Firmware) => {
  const { id, description, formatUrl } = firmware;

  return {
    id: String(id),
    description,
    formatUrl: String(formatUrl),
    files: [],
  };
};

export function EditFirmware() {
  const { isFetching, error, data } = useGetFirmwaresQuery();

  const [trigger, { isLoading: isUpdateLoading }] = useUpdateFirmwareMutation();

  const { firmwareId } = useParams() as { firmwareId: string };

  const handleSubmit = async (values: FirmwareFormData) => {
    const { files, id, ...rest } = prepareRequestData(values);

    const formData = new FormData();

    files.forEach((file) => {
      formData.append('fileFirmware', file.originFileObj as RcFile);
    });

    try {
      const res = await trigger({
        ...rest,
        id,
        fileFirmware: formData,
      }).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);
        return;
      }
      //

      openSuccessNotification(EDIT_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : EDIT_ERROR;

      openErrorNotification(errorText);
    }
  };

  if (isFetching) {
    return <div>Loading...</div>;
  }

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!data || !data.firmwares) {
    return <EmptyData />;
  }

  const firmware = data.firmwares.find(
    (el) => String(el.id) === firmwareId
  ) as Firmware;

  const initialValues = getInitialValues(firmware);

  const showDeleteModal = () => {
    NiceModal.show(DeleteFirmwareModal, {
      id: String(firmware.id),
      name: firmware.name,
    });
  };

  return (
    <>
      <FirmwareForm
        disabled
        allowEmptyFile
        title={firmware.name}
        handleSubmit={handleSubmit}
        loading={isUpdateLoading}
        initialValues={initialValues}
        onDeleteClick={showDeleteModal}
      />
    </>
  );
}
