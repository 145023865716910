import { Col } from 'antd';
import NiceModal from '@ebay/nice-modal-react';

import { toCurrencyUnit } from 'shared/lib';
import { UiInput } from 'shared/ui/ui-kit';

import { BonusProgramLevel } from 'entities/bonus-program/model/levels';

import { EditBonusLevelModal } from 'features/bonus/edit-bonus-level/ui/modal';

import { EditLevelIcon, LevelContainer } from './styles';

import { FormItem, FormRow } from '../../../../../common-styles';

import EditIcon from './edit.svg';

type Props = {
  level: BonusProgramLevel;
};

export function Level({ level }: Props) {
  const { name, awardSum, awardPercent, condition, level: levelNumber } = level;

  const isPercentCashback = awardPercent !== 0;

  const showEditBonusLevelModal = () => {
    NiceModal.show(EditBonusLevelModal, {
      level,
    });
  };

  const renderAwardLabel = () => {
    const baseLabel = 'Размер кешбека';

    if (levelNumber === 0) {
      return baseLabel;
    }

    return `${baseLabel} ${isPercentCashback ? 'в %' : 'в руб.'}`;
  };

  return (
    <LevelContainer>
      <FormRow gutter={20}>
        <Col span={6}>
          <FormItem label="Название уровня">
            <UiInput disabled value={name} />
          </FormItem>
        </Col>
        <Col span={3}>
          <FormItem label="№ уровня">
            <UiInput disabled value={levelNumber} />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem label="Необходимая сумма">
            <UiInput disabled value={toCurrencyUnit(condition)} />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem label={renderAwardLabel()}>
            <UiInput
              disabled
              value={
                isPercentCashback ? awardPercent : toCurrencyUnit(awardSum)
              }
            />
          </FormItem>
        </Col>
        <EditLevelIcon src={EditIcon} onClick={showEditBonusLevelModal} />
      </FormRow>
    </LevelContainer>
  );
}
