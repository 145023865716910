import styled from 'styled-components';

import {
  ChargePointStatus,
  CHARGE_POINT_STATUS_COLOR,
} from 'entities/charge-point';

export const CellContainer = styled.div`
  display: flex;
  align-items: center;
`;

type ChargePointStatusBarProps = {
  status: ChargePointStatus;
};

export const ChargePointStatusBar = styled.div<ChargePointStatusBarProps>`
  width: 5px;
  height: 15px;
  border-radius: 1px;
  display: inline-block;
  margin-right: 8px;
  background-color: ${(props) => CHARGE_POINT_STATUS_COLOR[props.status]};
`;
