import { useNavigate, useParams } from 'react-router-dom';

import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
  getServerErrorText,
  toCurrencyUnit,
} from 'shared/lib';

import { EmptyData, ErrorMessage } from 'shared/ui';

import {
  type AppSettingsFormData,
  type Settings,
  useGetSettingsQuery,
  useUpdateSettingsMutation,
  ConnectedAppSettingsForm,
  prepareRequestData,
} from 'entities/settings';

import { EDIT_ERROR, EDIT_SUCCESS } from '../consts';

const getInitialValues = (settings: Settings): AppSettingsFormData => {
  const {
    id,
    country: { id: countryId },
    userAgreementUrl,
    urlFaq,
    supportPhone,
    telegrammBot,
    statusTimeOut,
    stopChargingRequestTimeout,
  } = settings;

  return {
    id: String(id),
    countryId: String(countryId),
    userAgreementUrl,
    urlFaq,
    supportPhone,
    telegrammBot,
    statusTimeOut: String(statusTimeOut),
    stopChargingRequestTimeout: String(stopChargingRequestTimeout),
  };
};

// После бэка поменять countryId на id
export function EditAppSettings() {
  const [trigger, { isLoading: isMutationLoading }] =
    useUpdateSettingsMutation();

  const { countryId } = useParams() as { countryId: string };

  const { isFetching, error, data } = useGetSettingsQuery(countryId);

  const handleSubmit = async (values: AppSettingsFormData) => {
    const req = prepareRequestData(values);

    try {
      const res = await trigger(req).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);
        return;
      }
      //

      openSuccessNotification(EDIT_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : EDIT_ERROR;

      openErrorNotification(errorText);
    }
  };

  if (isFetching) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  if (!data?.settings) {
    return <EmptyData />;
  }

  const initialValues = getInitialValues(data.settings);

  return (
    <ConnectedAppSettingsForm
      handleSubmit={handleSubmit}
      loading={isMutationLoading}
      initialValues={initialValues}
      title={data.settings.country.name}
      disabled
    />
  );
}
