import { useParams } from 'react-router-dom';

import { EmptyData, ErrorMessage } from 'shared/ui';
import {
  FORMAT_TO_SECONDS,
  getServerErrorText,
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
  showTimeString,
} from 'shared/lib';

import {
  prepareProgramRequestData,
  useUpdateBonusProgramMutation,
  useGetBonusProgramByIdQuery,
  BonusProgram,
  ConnectedBonusProgramForm,
  BonusProgramFormData,
} from 'entities/bonus-program';

import { EDIT_ERROR, EDIT_SUCCESS } from '../consts';
import { ConnectedBonusProgramLevels } from 'features/bonus/bonus-program-levels/ui/connected-levels';

const getInitialValues = (bonusProgram: BonusProgram) => {
  const { bankId, frequencyValue, dateStart, dateStop, ...rest } = bonusProgram;

  return {
    dateStart: showTimeString(dateStart, FORMAT_TO_SECONDS),
    dateStop: showTimeString(dateStop, FORMAT_TO_SECONDS),
    bankId: String(bankId),
    frequencyValue: String(frequencyValue),
    ...rest,
  };
};

export function EditBonusProgram() {
  const [trigger, { isLoading: isMutationLoading }] =
    useUpdateBonusProgramMutation();

  const { bonusProgramId } = useParams() as { bonusProgramId: string };

  const { data, isFetching, error } =
    useGetBonusProgramByIdQuery(bonusProgramId);

  if (isFetching) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!data) {
    return <EmptyData />;
  }

  const initialValues = getInitialValues(data);

  const handleSubmit = async (values: BonusProgramFormData) => {
    const req = { ...prepareProgramRequestData(values), id: data.id };
    try {
      const res = await trigger(req).unwrap();

      openSuccessNotification(EDIT_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : EDIT_ERROR;

      openErrorNotification(errorText);
    }
  };

  return (
    <ConnectedBonusProgramForm
      handleSubmit={handleSubmit}
      loading={isMutationLoading}
      title={data.name}
      initialValues={initialValues}
      disabled
      bonusLevels={<ConnectedBonusProgramLevels />}
    />
  );
}
