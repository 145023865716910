import { ADD_PREFIX, SETTINGS } from './common';

export const CONNECTOR_INSTRUCTIONS = 'connector-instructions';
export const CONNECTOR_INSTRUCTION = ':connectorType';

export const CONNECTOR_INSTRUCTION_ROUTES = {
  CONNECTOR_INSTRUCTIONS: `/${SETTINGS}/${CONNECTOR_INSTRUCTIONS}`,
  CONNECTOR_INSTRUCTION: `/${SETTINGS}/${CONNECTOR_INSTRUCTIONS}/${CONNECTOR_INSTRUCTION}`,
  ADD_CONNECTOR_INSTRUCTION: `/${SETTINGS}/${CONNECTOR_INSTRUCTIONS}/${ADD_PREFIX}`,
};
