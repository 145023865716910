import styled from 'styled-components';

import { COLOR } from 'shared/consts';

export const ProgressBarContainer = styled.div`
  width: 100%;
  background-color: ${COLOR.whiteSmoke};
`;

type Props = {
  progress: number;
};

export const ProgressBarFill = styled.div<Props>`
  height: 5px;
  width: ${(props) => `${props.progress}%`};
  max-width: 100%;
  background-color: ${COLOR.greenMenu};
  border-radius: 25px; // check

  transition: width 0.5s ease-out;
`;
