import { TariffFormData } from '../model';
import { getTriggerType } from './get-trigger-type';

export const getTriggerValue = (
  values: TariffFormData,
  triggerType: ReturnType<typeof getTriggerType>
) => {
  const { batteryPercentage, powerDrop } = values;

  if (triggerType === 0) {
    return 0;
  }

  if (triggerType === 1) {
    return batteryPercentage;
  }

  return powerDrop;
};
