import { useSearchParams } from 'react-router-dom';
import { useMemo, useState } from 'react';
import {
  createColumnHelper,
  getCoreRowModel,
  getGroupedRowModel,
  useReactTable,
  GroupingState,
  ColumnDef,
} from '@tanstack/react-table';
import Excel from 'exceljs';

import { ExportButton } from 'shared/ui';
import {
  getRuFormatDateString,
  replaceSubstring,
  toConsumeUnit,
  toCurrencyUnit,
  toFixedDecimals,
} from 'shared/lib';

import {
  ReportInfo,
  ReportTableLayout,
  StyledReportCard,
  ReportHeader,
  applyWorksheetStyles,
  downloadReport,
  setColumnsAutoWidth,
  setReportMetadata,
  DEFAULT_REPORT_COLUMN_WIDTH,
  type StatConsume,
  getSelectedGroupsNames,
  highlightLastRow,
} from 'entities/report';
import { GroupDto } from 'entities/group';

const getReportTitle = (groupBy: string): string => {
  const commonPart = 'Отчет по потреблению ЭЗС';

  if (groupBy === '0') {
    return `${commonPart} (дни)`;
  }

  if (groupBy === '1') {
    return `${commonPart} (месяцы)`;
  }

  return 'Сводный отчет потребления по станциям';
};

const COLUMN = {
  chargeEndTimeDaily: {
    id: 'chargeEndTime',
    header: 'Завершение зарядной сессии',
  },
  chargeEndTimeMonthly: {
    id: 'chargeEndTime',
    header: 'Месяц зарядной сессии',
  },
  cpName: { id: 'cpName', header: 'Номер ЭЗС' },
  manufacturer: { id: 'manufacturer', header: 'Производитель' },
  sessionCount: {
    id: 'sessionCount',
    header: 'Кол-во зарядок',
  },
  calculatedPayment: {
    id: 'calculatedPayment',
    header: 'Сумма операций (руб.)',
  },
  calculatedConsume: { id: 'calculatedConsume', header: 'Потреблено (кВт*ч)' },
  cpAddress: { id: 'cpAddress', header: 'Адрес' },
};

const REPORT_TITLE = 'Отчет по потреблению';
const COLUMNS_ROW = 6;

const columnHelper = createColumnHelper<StatConsume>();

const TABLE_COLUMN = {
  chargeEndTimeDaily: columnHelper.accessor('chargeEndTime', {
    ...COLUMN.chargeEndTimeDaily,
    cell: (props) => props.getValue().replaceAll('-', '.'),
    size: 155,
  }),
  chargeEndTimeMonthly: columnHelper.accessor('chargeEndTime', {
    ...COLUMN.chargeEndTimeMonthly,
    cell: (props) => props.getValue().replaceAll('-', '.'),
    size: 155,
  }),
  cpName: columnHelper.accessor('cpName', {
    ...COLUMN.cpName,
  }),
  manufacturer: columnHelper.accessor('manufacturer', {
    ...COLUMN.manufacturer,
  }),
  sessionCount: columnHelper.accessor('sessionCount', {
    ...COLUMN.sessionCount,
  }),
  calculatedPayment: columnHelper.accessor('calculatedPayment', {
    ...COLUMN.calculatedPayment,
    cell: (props) => toFixedDecimals(props.getValue(), 2),
  }),
  calculatedConsume: columnHelper.accessor('calculatedConsume', {
    ...COLUMN.calculatedConsume,
    cell: (props) => toFixedDecimals(props.getValue(), 2),
  }),
  cpAddress: columnHelper.accessor('cpAddress', {
    ...COLUMN.cpAddress,
  }),
};

const emptyArr: StatConsume[] = [];

type Props = {
  stats: StatConsume[];
  loading: boolean;
  groups: GroupDto[];
};

export function ConsumeReportTable({ stats, loading, groups }: Props) {
  const [searchParams, setSearchParams] = useSearchParams();

  const dateFromParam = searchParams.get('dateFrom');
  const dateToParam = searchParams.get('dateTo');
  const chargePointsParam = searchParams.get('chargePoints');
  const groupsParam = searchParams.get('groups');
  const groupByParam = searchParams.get('groupBy') ?? '0';

  const [grouping, setGrouping] = useState<GroupingState>([]);

  const selectedGroupsNames = useMemo(
    () =>
      groupsParam !== null ? getSelectedGroupsNames(groupsParam, groups) : '',
    [groupsParam]
  );

  const setReportHeader = (ws: Excel.Worksheet, lastColumn: string) => {
    ws.mergeCells('A1', `${lastColumn}1`);

    ws.getCell('A2').value = 'Отчетный период:';
    ws.mergeCells('B2', `${lastColumn}2`);
    ws.getCell('B2').value = `${getRuFormatDateString(
      dateFromParam as string
    )}-${getRuFormatDateString(dateToParam as string)}`;

    ws.getCell('A3').value = 'Список ЭЗС:';
    ws.mergeCells('B3', `${lastColumn}3`);
    ws.getCell('B3').value = chargePointsParam;

    ws.getCell('A4').value = 'Группы ЭЗС:';
    ws.mergeCells('B4', `${lastColumn}4`);
    ws.getCell('B4').value = selectedGroupsNames;
  };

  const buildTableByDaysOrMonths = (ws: Excel.Worksheet, type: '0' | '1') => {
    setReportHeader(ws, 'G');

    ws.getRow(COLUMNS_ROW).values = [
      type === '0'
        ? COLUMN.chargeEndTimeDaily.header
        : COLUMN.chargeEndTimeMonthly.header,
      COLUMN.cpName.header,
      COLUMN.manufacturer.header,
      COLUMN.sessionCount.header,
      COLUMN.calculatedPayment.header,
      COLUMN.calculatedConsume.header,
      COLUMN.cpAddress.header,
    ];

    ws.columns = [
      {
        key:
          type === '0'
            ? COLUMN.chargeEndTimeDaily.id
            : COLUMN.chargeEndTimeMonthly.id,
        width: DEFAULT_REPORT_COLUMN_WIDTH,
      },
      { key: COLUMN.cpName.id, width: 15 },
      { key: COLUMN.manufacturer.id, width: DEFAULT_REPORT_COLUMN_WIDTH },
      { key: COLUMN.sessionCount.id, width: 15 },
      { key: COLUMN.calculatedPayment.id, width: DEFAULT_REPORT_COLUMN_WIDTH },
      { key: COLUMN.calculatedConsume.id, width: DEFAULT_REPORT_COLUMN_WIDTH },
      { key: COLUMN.cpAddress.id, width: DEFAULT_REPORT_COLUMN_WIDTH },
    ];

    stats.forEach(
      ({
        chargeEndTime,
        cpName,
        manufacturer,
        sessionCount,
        calculatedPayment,
        calculatedConsume,
        cpAddress,
      }) => {
        ws.addRow({
          chargeEndTime: replaceSubstring(chargeEndTime, '-', '.'),
          cpName: cpName ?? '',
          manufacturer: manufacturer ?? '',
          sessionCount,
          calculatedPayment: replaceSubstring(
            toFixedDecimals(calculatedPayment, 2),
            '.',
            ','
          ),
          calculatedConsume: replaceSubstring(
            toFixedDecimals(calculatedConsume, 2),
            '.',
            ','
          ),
          cpAddress: cpAddress ?? '',
        });
      }
    );

    ws.addRow({
      chargeEndTime: 'Итого:',
      cpName: '',
      manufacturer: '',
      sessionCount: sessionCountSummary,
      calculatedPayment: replaceSubstring(paymentSummary, '.', ','),
      calculatedConsume: replaceSubstring(consumeSummary, '.', ','),
      cpAddress: '',
    });

    applyWorksheetStyles(ws, COLUMNS_ROW);
    highlightLastRow(ws);

    const lastRowIndex = stats.length + COLUMNS_ROW + 1;

    ws.mergeCells(`A${lastRowIndex}:C${lastRowIndex}`);

    ws.getCell('A1').value = getReportTitle(type);
    ws.getCell('B2').alignment = {
      horizontal: 'left',
    };
    ws.getCell('B3').alignment = {
      horizontal: 'left',
    };
    ws.getCell('B4').alignment = {
      horizontal: 'left',
    };

    setColumnsAutoWidth(ws, COLUMNS_ROW);
  };

  const buildTableByDays = (ws: Excel.Worksheet) =>
    buildTableByDaysOrMonths(ws, '0');

  const buildTableByMonths = (ws: Excel.Worksheet) =>
    buildTableByDaysOrMonths(ws, '1');

  const buildTableByPeriod = (ws: Excel.Worksheet) => {
    setReportHeader(ws, 'D');

    ws.getRow(COLUMNS_ROW).values = [
      COLUMN.cpName.header,
      COLUMN.manufacturer.header,
      COLUMN.sessionCount.header,
      COLUMN.calculatedConsume.header,
    ];

    ws.columns = [
      { key: COLUMN.cpName.id, width: DEFAULT_REPORT_COLUMN_WIDTH },
      { key: COLUMN.manufacturer.id, width: DEFAULT_REPORT_COLUMN_WIDTH },
      { key: COLUMN.sessionCount.id, width: 15 },
      { key: COLUMN.calculatedConsume.id, width: DEFAULT_REPORT_COLUMN_WIDTH },
    ];

    stats.forEach(
      ({ cpName, manufacturer, sessionCount, calculatedConsume }) => {
        ws.addRow({
          cpName,
          manufacturer: manufacturer ?? '',
          sessionCount,
          calculatedConsume: replaceSubstring(
            toFixedDecimals(calculatedConsume, 2),
            '.',
            ','
          ),
        });
      }
    );

    ws.addRow({
      cpName: 'Итого:',
      manufacturer: '',
      sessionCount: sessionCountSummary,
      calculatedConsume: consumeSummary.replace('.', ','),
    });

    applyWorksheetStyles(ws, COLUMNS_ROW);
    highlightLastRow(ws);

    const lastRowIndex = stats.length + COLUMNS_ROW + 1;

    ws.mergeCells(`A${lastRowIndex}:B${lastRowIndex}`);

    ws.getCell('A1').value = getReportTitle('2');
    ws.getCell('B2').alignment = {
      horizontal: 'left',
    };
    ws.getCell('B3').alignment = {
      horizontal: 'left',
    };
    ws.getCell('B4').alignment = {
      horizontal: 'left',
    };

    setColumnsAutoWidth(ws, COLUMNS_ROW);
  };

  const generateTable = async () => {
    const workbook = new Excel.Workbook();

    setReportMetadata(workbook);

    const worksheet = workbook.addWorksheet(REPORT_TITLE);

    if (groupByParam === '0') {
      buildTableByDays(worksheet);
    } else if (groupByParam === '1') {
      buildTableByMonths(worksheet);
    } else if (groupByParam === '2') {
      buildTableByPeriod(worksheet);
    }

    const reportFileName = `${getReportTitle(groupByParam).replace(
      ' ',
      '_'
    )}${dateFromParam}-${dateToParam}`;

    downloadReport(workbook, reportFileName);
  };

  const paymentSummary = toFixedDecimals(
    stats.reduce((acc, el) => acc + el.calculatedPayment, 0),
    2
  );

  const consumeSummary = toFixedDecimals(
    stats.reduce((acc, el) => acc + el.calculatedConsume, 0),
    2
  );

  const sessionCountSummary = stats.reduce(
    (acc, el) => acc + el.sessionCount,
    0
  );

  const operationsSummary = stats.length;

  const groupByDayColumns = [
    columnHelper.group({
      header: 'Итого',
      footer: (props) => {
        return 'Итого';
      },
      columns: [
        TABLE_COLUMN.chargeEndTimeDaily,
        TABLE_COLUMN.cpName,
        TABLE_COLUMN.manufacturer,
      ],
    }),
    columnHelper.group({
      header: 'sessionCount',
      footer: String(sessionCountSummary),
      columns: [TABLE_COLUMN.sessionCount],
    }),
    columnHelper.group({
      header: 'calculatedPayment',
      footer: String(paymentSummary),
      columns: [TABLE_COLUMN.calculatedPayment],
    }),
    columnHelper.group({
      header: 'calculatedConsume',
      footer: String(consumeSummary),
      columns: [TABLE_COLUMN.calculatedConsume],
    }),
    columnHelper.group({
      header: 'cpAddress',
      footer: (props) => '',
      columns: [TABLE_COLUMN.cpAddress],
    }),
  ];

  const groupByMonthColumns = [
    columnHelper.group({
      header: 'Итого',
      footer: 'Итого',
      columns: [
        TABLE_COLUMN.chargeEndTimeMonthly,
        TABLE_COLUMN.cpName,
        TABLE_COLUMN.manufacturer,
      ],
    }),
    columnHelper.group({
      header: 'sessionCount',
      footer: String(sessionCountSummary),
      columns: [TABLE_COLUMN.sessionCount],
    }),
    columnHelper.group({
      header: 'calculatedPayment',
      footer: String(paymentSummary),
      columns: [TABLE_COLUMN.calculatedPayment],
    }),
    columnHelper.group({
      header: 'calculatedConsume',
      footer: String(consumeSummary),
      columns: [TABLE_COLUMN.calculatedConsume],
    }),
    columnHelper.group({
      header: 'cpAddress',
      footer: (props) => '',
      columns: [TABLE_COLUMN.cpAddress],
    }),
  ];

  const groupByPeriodColumns = [
    columnHelper.group({
      header: 'Итого',
      footer: 'Итого',
      columns: [TABLE_COLUMN.cpName, TABLE_COLUMN.manufacturer],
    }),
    columnHelper.group({
      header: 'sessionCount',
      footer: String(sessionCountSummary),
      columns: [TABLE_COLUMN.sessionCount],
    }),
    columnHelper.group({
      header: 'calculatedConsume',
      footer: String(consumeSummary),
      columns: [TABLE_COLUMN.calculatedConsume],
    }),
  ];

  const defaultColumnsMapping: Record<
    string,
    ColumnDef<StatConsume, unknown>[]
  > = {
    0: groupByDayColumns,
    1: groupByMonthColumns,
    2: groupByPeriodColumns,
  };

  const columns = useMemo(
    () => defaultColumnsMapping[groupByParam],
    [paymentSummary, consumeSummary, operationsSummary, groupByParam]
  );

  const data = useMemo(() => {
    return stats ?? emptyArr;
  }, [stats]);

  const table = useReactTable({
    data,
    columns,
    state: {
      grouping,
    },
    onGroupingChange: setGrouping,
    getGroupedRowModel: getGroupedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <StyledReportCard>
      <ReportHeader>
        <ReportInfo
          title={REPORT_TITLE}
          dateFromParam={dateFromParam}
          dateToParam={dateToParam}
        />
        <ExportButton
          onClick={generateTable}
          disabled={loading || !stats.length}
        />
      </ReportHeader>
      <ReportTableLayout
        table={table}
        loading={loading}
        ignoreHeaderGroups={['0']}
        includeFooterGroups={['0']}
      />
    </StyledReportCard>
  );
}
