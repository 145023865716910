import { RcFile } from 'antd/es/upload/interface';

import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';

import {
  ChargePointPhotoForm,
  ChargePointPhotoFormData,
  useUpdateChargePointPhotoMutation,
} from 'entities/charge-point-photo';

import { EDIT_ERROR, EDIT_SUCCESS } from '../consts';

type Props = {
  chargePointName: string;
  photoId: string;
  priority: string;
  closeModal: () => void;
};

export function EditChargePointPhoto({
  chargePointName,
  photoId,
  priority,
  closeModal,
}: Props) {
  const [trigger, { isLoading }] = useUpdateChargePointPhotoMutation();

  const handleSubmit = async (values: ChargePointPhotoFormData) => {
    const { priority, photos } = values;

    const formData = new FormData();

    photos.forEach((file) => {
      formData.append('filePhoto', file.originFileObj as RcFile);
    });

    try {
      const res = await trigger({
        cpName: chargePointName,
        priority: Number(priority),
        filePhoto: formData,
        photoId: Number(photoId),
      }).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);

        return;
      }
      //

      openSuccessNotification(EDIT_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : EDIT_ERROR;

      openErrorNotification(errorText);
    }
  };

  const initialValues: ChargePointPhotoFormData = {
    priority,
    photos: [],
  };

  return (
    <ChargePointPhotoForm
      handleSubmit={handleSubmit}
      loading={isLoading}
      initialValues={initialValues}
      onCancelClick={closeModal}
      allowEmptyFile
    />
  );
}
