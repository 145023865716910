import React from 'react';

import { renderDotSeparator, toConsumeUnit, toCurrencyUnit } from 'shared/lib';

import { ChargePointConnectorDto } from 'entities/connector';
import { ChargeTariffDto } from 'entities/tariff';

import {
  SpanContainer,
  TariffDetailsContent,
  TariffParam,
  TariffParamLabel,
  TariffParamValue,
  TariffParamValuePostfix,
} from './styles';

// Логика для тарифов коннекторов пока не добавлена (позже на бэке будет)

const getChargingCostPostfix = (
  tariffType: ChargePointConnectorDto['tariff']['tariffType']
): React.ReactNode => {
  if (tariffType === 0) {
    return (
      <SpanContainer>
        <SpanContainer>кВт</SpanContainer>
        <SpanContainer>{renderDotSeparator()}</SpanContainer>
        <SpanContainer>ч</SpanContainer>
      </SpanContainer>
    );
  }

  if (tariffType === 1) {
    return <SpanContainer>мин</SpanContainer>;
  }

  return '';
};

const getTriggerName = (
  triggerType: ChargePointConnectorDto['tariff']['triggerType'],
  triggerValue: ChargePointConnectorDto['tariff']['triggerValue']
) => {
  if (triggerType === 0) {
    return '';
  }

  if (triggerType === 1) {
    return `От ${triggerValue}%`;
  }

  if (triggerType === 2) {
    return (
      <>
        Ниже
        <br />
        {toConsumeUnit(triggerValue)} кВт
      </>
    );
  }
};

type Props = {
  tariff: ChargeTariffDto;
  connectors: Array<ChargePointConnectorDto>;
};

export function ChargePointTariffsDetails({ connectors, tariff }: Props) {
  //
  if (!tariff) {
    return null;
  }

  const {
    startFee,
    parkFee,
    tariffType,
    costOfQuickCharging,
    triggerType,
    triggerFee,
    triggerValue,
    bank,
    costOfReserve,
  } = tariff;

  const { country } = bank;
  const { currencySymbol } = country;

  const chargingCostPostfix = getChargingCostPostfix(tariffType);

  const triggerName = getTriggerName(triggerType, triggerValue);

  const currency = <SpanContainer>{currencySymbol}</SpanContainer>;

  const renderParam = (label: React.ReactNode, value: React.ReactNode) => {
    return (
      <TariffParam>
        <TariffParamLabel>{label}</TariffParamLabel>
        <TariffParamValue>{value}</TariffParamValue>
      </TariffParam>
    );
  };

  const renderStartParam = () => {
    const title = 'Старт';

    const value = (
      <>
        <TariffParamValue>{toCurrencyUnit(startFee)}</TariffParamValue>
        <TariffParamValuePostfix>{currency}</TariffParamValuePostfix>
      </>
    );

    return renderParam(title, value);
  };

  const renderChargedParam = () => {
    const title = 'Заряд';

    const value = (
      <>
        <TariffParamValue>
          {toCurrencyUnit(costOfQuickCharging)}
        </TariffParamValue>
        <TariffParamValuePostfix>
          {currency}
          {'/'}
          {chargingCostPostfix}
        </TariffParamValuePostfix>
      </>
    );

    return renderParam(title, value);
  };

  const renderTrigger = () => {
    if (triggerType === 0) {
      return;
    }

    const title = triggerName;

    const value = (
      <>
        <TariffParamValue>{toCurrencyUnit(triggerFee)}</TariffParamValue>
        <TariffParamValuePostfix>
          {currency}
          <SpanContainer>/мин</SpanContainer>
        </TariffParamValuePostfix>
      </>
    );

    return renderParam(title, value);
  };

  const renderAfterCharging = () => {
    const title = 'После заряда';

    const value = (
      <>
        <TariffParamValue>{toCurrencyUnit(parkFee)}</TariffParamValue>
        <TariffParamValuePostfix>
          {currency}
          <SpanContainer>/мин</SpanContainer>
        </TariffParamValuePostfix>
      </>
    );

    return renderParam(title, value);
  };

  const renderReserve = () => {
    const title = 'Бронирование';

    const value = (
      <>
        <TariffParamValue>{toCurrencyUnit(costOfReserve)}</TariffParamValue>
        <TariffParamValuePostfix>
          {currency}
          <SpanContainer>/мин</SpanContainer>
        </TariffParamValuePostfix>
      </>
    );

    return renderParam(title, value);
  };

  return (
    <TariffDetailsContent>
      {renderStartParam()}
      {renderChargedParam()}
      {renderTrigger()}
      {renderAfterCharging()}
      {renderReserve()}
    </TariffDetailsContent>
  );
}
