import { getServerErrorText } from 'shared/lib';
import { ErrorMessage, EmptyData } from 'shared/ui';

import { useGetUsersGroupsQuery } from 'entities/users-group';

import { UserForm } from '../form';

type Props = Omit<React.ComponentProps<typeof UserForm>, 'groups'>;

export function ConnectedUserForm(props: Props) {
  const { data, isFetching, error } = useGetUsersGroupsQuery();

  if (isFetching) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  if (!data) {
    return <EmptyData />;
  }

  return <UserForm {...props} groups={data.groups} />;
}
