import { Table } from '@tanstack/react-table';

import { Pagination } from '../pagination';

type Props = {
  table: Table<any>;
  pageSize: number;
  handlePageSizePressEnter: (val: number) => void;
  rowsCount: number | undefined;
};

export function ClientPagination({
  table,
  pageSize,
  handlePageSizePressEnter,
  rowsCount,
}: Props) {
  const prevDisabled = !table.getCanPreviousPage();
  const nextDisabled = !table.getCanNextPage();

  const onPrevClick = () => table.previousPage();
  const onNextClick = () => table.nextPage();

  const handlePressEnter = (val: number) => {
    table.setPageIndex(val - 1);
  };

  const onPageSizePressEnter = (val: number) => {
    handlePageSizePressEnter(val);

    table.setPageIndex(0);
  };

  const pagesCount = table.getPageCount();

  const jumperValue = String(table.getState().pagination.pageIndex + 1);

  return (
    <Pagination
      prevDisabled={prevDisabled}
      nextDisabled={nextDisabled}
      pagesCount={pagesCount}
      jumperValue={jumperValue}
      onPrevClick={onPrevClick}
      onNextClick={onNextClick}
      handlePressEnter={handlePressEnter}
      pageSize={pageSize}
      handlePageSizePressEnter={onPageSizePressEnter}
      rowsCount={rowsCount}
    />
  );
}
