import React from 'react';

import { toCurrencyUnit, toFixedDecimals } from 'shared/lib';

import { BankDTO } from 'entities/bank';
import { MongoReserveSessionDto } from 'entities/session';

import { StyledButton } from '../../../../common-styles';

import {
  TariffDetailsContainer,
  TariffDetailsGroup,
  TariffDetailsParamGropTitle,
  TariffDetailsParamGroupValue,
  TariffDetailsParamGroupData,
  TariffDetailsParamGroupDataItem,
  TariffDetailsParamGroupKey,
  TariffDetailsContent,
  TariffDetailsFooter,
  TariffDetailsSummary,
  TariffDetailsSummaryTitle,
  TariffDetailsSummaryValue,
} from './styles';

type Props = {
  session: MongoReserveSessionDto;
  bank: BankDTO;
  closeModal: () => void;
};

export function ReserveDetails({ session, closeModal, bank }: Props) {
  const { reservePeriod, details } = session;

  const { finalReserveTime, servicePrice, reserveAmount, usedAmount } = details;

  const servicePriceValue = toCurrencyUnit(servicePrice);
  const reserveAmountValue = toCurrencyUnit(reserveAmount);
  const usedAmountValue = toCurrencyUnit(usedAmount);

  // Выяснить про округление finalReserveTime

  const { currencySymbol } = bank.country;

  const renderAmountWithCurrency = (value: number | string) => {
    return `${value} ${currencySymbol}`;
  };

  const renderParamGroupDataItem = (key: string, value: React.ReactNode) => {
    return (
      <TariffDetailsParamGroupDataItem>
        <TariffDetailsParamGroupKey>{key}</TariffDetailsParamGroupKey>
        <TariffDetailsParamGroupValue>{value}</TariffDetailsParamGroupValue>
      </TariffDetailsParamGroupDataItem>
    );
  };

  const renderGroup = (dict: Record<string, React.ReactNode>) => {
    return (
      <TariffDetailsGroup>
        <TariffDetailsParamGroupData>
          {Object.entries(dict).map(([key, value]) =>
            renderParamGroupDataItem(key, value)
          )}
        </TariffDetailsParamGroupData>
      </TariffDetailsGroup>
    );
  };

  const renderTime = (value: number) => {
    const [mins, rawSecs] = String(value).split('.');

    if (!rawSecs) {
      return `${mins} мин`;
    }

    const secs = Math.floor(Number(`0.${rawSecs}`) * 60);

    return `${mins} мин ${secs} сек`;
  };

  const renderDetails = () => {
    const dict = {
      'Запрошенный период': renderTime(reservePeriod),
      'Фактический период': renderTime(finalReserveTime),
      'Стоимость за 1 мин.': renderAmountWithCurrency(servicePriceValue),
      'Было зарезервировано:': renderAmountWithCurrency(reserveAmountValue),
      'Итого списано:': renderAmountWithCurrency(usedAmountValue),
    };

    return renderGroup(dict);
  };

  return (
    <TariffDetailsContainer>
      <TariffDetailsContent>{renderDetails()}</TariffDetailsContent>

      <TariffDetailsFooter>
        {/* <TariffDetailsSummary>
          <TariffDetailsSummaryTitle>Итого списано</TariffDetailsSummaryTitle>
          <TariffDetailsSummaryValue>
            {renderAmountWithCurrency(amountToPayValue)}
          </TariffDetailsSummaryValue>
        </TariffDetailsSummary> */}

        <StyledButton
          type="primary"
          style={{ width: '100%' }}
          onClick={closeModal}
        >
          Закрыть
        </StyledButton>
      </TariffDetailsFooter>
    </TariffDetailsContainer>
  );
}
