import { Col, Form, FormInstance, FormProps, Select, Spin } from 'antd';
import { useEffect, useState } from 'react';

import { checkMinLengthRule, formHasErrors } from 'shared/lib';
import { UiDivider, UiFormCheckbox, UiInput, UiSelect } from 'shared/ui/ui-kit';

import { OwnerDto } from 'entities/owner';
import { GroupDto } from 'entities/group';
import {
  ADDRESS_MAX_LENGTH,
  CHARGE_POINT_PROTOCOL,
  CHARGE_POINT_STATUS,
  CHARGE_POINT_TYPE,
  CITY_MIN_LENGTH,
} from 'entities/charge-point';

import {
  FormButtonsContainer,
  FormItem,
  FormRow,
  StyledButton,
} from '../../../../../../common-styles';

import {
  maxLengthValidationRule,
  VALIDATION_RULE,
} from '../../../../../../constants/validation-rules';
import { ChargePointFormData } from '../../../../types';

type FormDataType = ChargePointFormData['techInfo'];

interface TechInfoFormProps {
  initialValues: FormDataType;
  owners: OwnerDto[];
  groups: GroupDto[];
  onCancel: () => void;
  handleSubmit: (values: FormDataType) => void;
  loading: boolean;
  editing: boolean;
}

export function TechInfoForm({
  initialValues,
  owners,
  groups,
  onCancel,
  handleSubmit,
  loading,
  editing,
}: TechInfoFormProps) {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [hasErrors, setHasErrors] = useState(false);

  const [form] = Form.useForm<FormDataType>();

  const chargePointProtocol = Form.useWatch('chargePointProtocol', form);

  useEffect(() => {
    if (chargePointProtocol === '4') {
      form.setFieldValue('ipAddress', '');
    }
  }, [chargePointProtocol]);

  const onFieldsChange: FormProps<FormDataType>['onFieldsChange'] = (
    _,
    _allFields
  ) => {
    setHasErrors(formHasErrors(form));
  };

  const onValuesChange: FormProps<FormDataType>['onValuesChange'] = (
    _,
    allValues
  ) => {
    if (
      allValues.name !== '' &&
      allValues.ownerId !== '' &&
      allValues.idGroup !== '' &&
      allValues.address &&
      allValues.maxPower !== '' &&
      allValues.type !== '' &&
      allValues.status !== '' &&
      allValues.chargePointProtocol !== ''
    ) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onFinish={handleSubmit}
      onFieldsChange={onFieldsChange}
      onValuesChange={onValuesChange}
      initialValues={initialValues}
    >
      <FormRow gutter={20}>
        <Col span={12}>
          <FormItem
            label="Имя / Charge Point identity"
            name="name"
            rules={[VALIDATION_RULE.REQUIRED, VALIDATION_RULE.NO_WHITESPACE]}
          >
            <UiInput disabled={editing} />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            label="Владелец"
            name="ownerId"
            rules={[VALIDATION_RULE.REQUIRED]}
          >
            <UiSelect>
              {owners.map(({ id, name }) => (
                <Select.Option value={String(id)} key={id}>
                  {name}
                </Select.Option>
              ))}
            </UiSelect>
          </FormItem>
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={12}>
          <FormItem
            label="Группа ЭЗС"
            name="idGroup"
            rules={[VALIDATION_RULE.REQUIRED]}
          >
            <UiSelect>
              {groups.map(({ id, name }) => (
                <Select.Option value={String(id)} key={id}>
                  {name}
                </Select.Option>
              ))}
            </UiSelect>
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            label="Город"
            name="city"
            rules={[
              checkMinLengthRule(CITY_MIN_LENGTH),
              VALIDATION_RULE.REQUIRED,
              VALIDATION_RULE.NO_WHITESPACE,
            ]}
          >
            <UiInput />
          </FormItem>
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={24}>
          <FormItem
            label="Адрес"
            name="address"
            rules={[
              maxLengthValidationRule(ADDRESS_MAX_LENGTH),
              VALIDATION_RULE.REQUIRED,
              VALIDATION_RULE.NO_WHITESPACE,
            ]}
          >
            <UiInput />
          </FormItem>
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={24}>
          <FormItem label="Описание" name="description">
            <UiInput />
          </FormItem>
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={12}>
          <FormItem label="Производитель" name="manufacturer">
            <UiInput />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            label="Максимальная мощность"
            name="maxPower"
            rules={[
              VALIDATION_RULE.NULL_OR_POSITIVE_NUMBER,
              VALIDATION_RULE.REQUIRED,
              VALIDATION_RULE.NO_WHITESPACE,
            ]}
          >
            <UiInput />
          </FormItem>
        </Col>
      </FormRow>
      <UiDivider />
      <FormRow gutter={20}>
        <Col span={12}>
          <FormItem
            label="Тип ЭЗС"
            name="type"
            rules={[VALIDATION_RULE.REQUIRED]}
          >
            <UiSelect>
              {Object.entries(CHARGE_POINT_TYPE).map((entry) => {
                const [key, label] = entry;

                return (
                  <Select.Option value={key} key={key}>
                    {label}
                  </Select.Option>
                );
              })}
            </UiSelect>
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            label="Статус"
            name="status"
            rules={[VALIDATION_RULE.REQUIRED]}
          >
            <UiSelect>
              {Object.entries(CHARGE_POINT_STATUS).map((entry) => {
                const [key, label] = entry;

                return (
                  <Select.Option value={key} key={key}>
                    {label}
                  </Select.Option>
                );
              })}
            </UiSelect>
          </FormItem>
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={12}>
          <FormItem name="multiconnectorSupport" valuePropName="checked">
            <UiFormCheckbox>Поддержка мультиконнектора</UiFormCheckbox>
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem name="timeLimit" valuePropName="checked">
            <UiFormCheckbox>Поддерживает ограничение времени</UiFormCheckbox>
          </FormItem>
        </Col>
      </FormRow>
      <UiDivider />
      <FormRow gutter={20}>
        <Col span={12}>
          <FormItem
            label="Протокол ЭЗС"
            name="chargePointProtocol"
            rules={[VALIDATION_RULE.REQUIRED]}
          >
            <UiSelect>
              {Object.entries(CHARGE_POINT_PROTOCOL).map((entry) => {
                const [key, label] = entry;

                return (
                  <Select.Option value={key} key={key}>
                    {label}
                  </Select.Option>
                );
              })}
            </UiSelect>
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem label="IP-адрес" name="ipAddress">
            <UiInput disabled={chargePointProtocol === '4'} />
          </FormItem>
        </Col>
      </FormRow>
      <FormRow gutter={20}>
        <Col span={12}>
          <FormItem label="Номер SIM" name="simNumber">
            <UiInput />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem label="Комментарий" name="commentary">
            <UiInput />
          </FormItem>
        </Col>
      </FormRow>
      <Spin spinning={loading}>
        <FormButtonsContainer>
          <StyledButton
            htmlType="submit"
            type="primary"
            disabled={isSubmitDisabled || hasErrors}
          >
            {editing ? 'Сохранить' : 'Далее'}
          </StyledButton>
          <StyledButton htmlType="button" type="default" onClick={onCancel}>
            Отмена
          </StyledButton>
        </FormButtonsContainer>
      </Spin>
    </Form>
  );
}
