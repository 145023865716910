import { baseApi } from 'shared/api';

import { getEndpointPath } from 'shared/lib';

import {
  CpoDtoBaseResponse,
  CpoListBaseResponse,
  AddCpoRequest,
  UpdateCpoRequest,
  GetCpoParams,
} from './types';

import { apiSlice } from '../../../redux/services/apiSlice';
import { prepareGetCpoParams } from '../lib';
import { PLATFORM_SERVICE } from 'entities/authorization';

const KEY = 'Cpo';

const getUrl = getEndpointPath(PLATFORM_SERVICE, KEY);

// TODO: замена на baseApi

export const api = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCpos: builder.query<CpoListBaseResponse, void>({
      query: () => getUrl('GetCpoList'),
      providesTags: ['Cpos'],
    }),
    getCpo: builder.query<CpoDtoBaseResponse, GetCpoParams>({
      query: (params) => ({
        url: getUrl(`GetCpo`),
        params: prepareGetCpoParams(params),
      }),
      providesTags: (result, error, arg) => [{ type: 'Cpo', id: arg.id }],
    }),
    addCpo: builder.mutation<CpoDtoBaseResponse, AddCpoRequest>({
      query: (cpo) => ({
        url: getUrl('AddCpo'),
        method: 'POST',
        body: cpo,
      }),
      invalidatesTags: ['Cpos'],
    }),
    updateCpo: builder.mutation<ResponseBase, UpdateCpoRequest>({
      query: (cpo) => ({
        url: getUrl('UpdateCpo'),
        method: 'PATCH',
        body: cpo,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Cpo', id: arg.id },
        'Cpos',
      ],
    }),
    deleteCpo: builder.mutation<ResponseBase, string>({
      query: (id) => ({
        url: getUrl(`DeleteCpo`),
        params: { id },
        method: 'DELETE',
      }),
      invalidatesTags: ['Cpos'],
    }),
  }),
});

export const {
  useGetCposQuery,
  useGetCpoQuery,
  useAddCpoMutation,
  useUpdateCpoMutation,
  useDeleteCpoMutation,
} = api;
