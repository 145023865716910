import styled from 'styled-components';

import { COLOR } from 'shared/consts';

export const StyledPaginationTotalCount = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR.black};
  opacity: 0.5;
  display: flex;
  align-items: center;
  white-space: nowrap;
`;
