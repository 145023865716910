import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { COLOR } from 'shared/consts';

import {
  UploadedImageErrorMessage,
  UploadedImageContainer,
  UploadedImageInfo,
  UploadedImagePreview,
  UploadedImageFilename,
  UploadedImageControls,
} from './styles';

type Props = {
  handleDelete: () => void;
  handleEdit?: () => void;
  errors?: string[];
  imageSrc: string;
  imageName: string;
};

export function UploadedImage({
  handleDelete,
  handleEdit,
  errors = [],
  imageSrc,
  imageName,
}: Props) {
  const isError = errors.length > 0;

  const deleteIconColor = isError ? COLOR.red : COLOR.black;

  return (
    <>
      {errors.length > 0
        ? errors.map((error) => (
            <UploadedImageErrorMessage key={error}>
              {error}
            </UploadedImageErrorMessage>
          ))
        : null}
      <UploadedImageContainer isError={isError}>
        <UploadedImageInfo>
          <UploadedImagePreview src={imageSrc} />
          <UploadedImageFilename>{imageName}</UploadedImageFilename>
        </UploadedImageInfo>
        <UploadedImageControls>
          {handleEdit ? <EditOutlined onClick={handleEdit} /> : null}
          <DeleteOutlined
            onClick={handleDelete}
            style={{ color: deleteIconColor }}
          />
        </UploadedImageControls>
      </UploadedImageContainer>
    </>
  );
}
