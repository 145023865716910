import { MainLayout } from 'shared/ui/layout/main';

import { PersonsFetchingContextProvider } from '../../context';
import { PersonsTabs } from '../tabs';

export function PersonsLayout() {
  return (
    <PersonsFetchingContextProvider>
      <MainLayout tabs={<PersonsTabs />} />
    </PersonsFetchingContextProvider>
  );
}
