import { NavigationTab } from 'shared/model';

import { AddDiscountButtonLink } from './buttons';

export const TABS: Array<NavigationTab> = [
  {
    key: 'discounts',
    path: '',
    label: 'Список скидок',
    buttons: [
      {
        element: <AddDiscountButtonLink />,
        controller: 'Discount',
        right: 'Write',
      },
    ],
  },
];
