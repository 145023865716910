import { getEndpointPath } from 'shared/lib';

import { baseApi } from 'shared/api/base-api';

import {
  AddCarModelRequest,
  CarModelResponseBase,
  CarModelsResponseBase,
  UpdateCarModelRequest,
} from '../model/api';

import { apiSlice } from '../../../redux/services/apiSlice';
import { PLATFORM_SERVICE } from 'entities/authorization';

const KEY = 'Car';

const getUrl = getEndpointPath(PLATFORM_SERVICE, KEY);

// TODO: замена на baseApi

export const carModelsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCarModels: builder.query<CarModelsResponseBase, string | undefined>({
      query: (carBrandId) => ({
        url: getUrl(`GetCarModels`),
        params: carBrandId !== undefined ? { carBrandId } : {},
      }),
      providesTags: ['CarModels'],
    }),
    getCarModel: builder.query<CarModelResponseBase, string>({
      query: (id) => ({
        url: getUrl(`GetCarModel`),
        params: { id },
      }),
      providesTags: (result, error, arg) => [{ type: 'CarModel', id: arg }],
    }),
    addCarModel: builder.mutation<CarModelResponseBase, AddCarModelRequest>({
      query: (carModel) => ({
        url: getUrl('AddCarModel'),
        method: 'POST',
        body: carModel,
      }),
      invalidatesTags: ['CarModels', 'CarBrands'],
    }),
    updateCarModel: builder.mutation<
      CarModelResponseBase,
      UpdateCarModelRequest
    >({
      query: (carModel) => ({
        url: getUrl('UpdateCarModel'),
        method: 'PATCH',
        body: carModel,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'CarModel', id: arg.id },
        'CarModels',
        'CarBrands',
      ],
    }),
    deleteCarModel: builder.mutation<ResponseBase, string>({
      query: (id) => ({
        url: getUrl(`DeleteCarModel`),
        params: { id },
        method: 'DELETE',
      }),
      invalidatesTags: ['CarModels', 'CarBrands'],
    }),
  }),
});

export const {
  useGetCarModelsQuery,
  useGetCarModelQuery,
  useAddCarModelMutation,
  useUpdateCarModelMutation,
  useDeleteCarModelMutation,
} = carModelsApi;
