import { Col, Form, FormProps } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import 'dayjs/locale/ru';
import dayjs from 'dayjs';

import { FormItem, FormRow } from '../../../../common-styles';
import { VALIDATION_RULE } from '../../../../constants/validation-rules';

import { DATE_FORMAT_REVERSE } from 'shared/lib';

import {
  GetReportButton,
  ReportFormWrapper,
  ReportRangePicker,
} from 'entities/report';
import { UiSelect } from 'shared/ui/ui-kit';
import { BankDTO } from 'entities/bank';

interface AcquiringReportFormData {
  dateRange: [dayjs.Dayjs, dayjs.Dayjs];
  bankId: string;
}

type Props = {
  allowReportFetching: () => void;
  banks: BankDTO[];
  loading: boolean;
};

export function AcquiringReportForm({
  allowReportFetching,
  banks,
  loading,
}: Props) {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();

  const dateFromParam = searchParams.get('dateFrom');
  const dateToParam = searchParams.get('dateTo');
  const bankIdParam = searchParams.get('bankId');

  const [form] = Form.useForm<AcquiringReportFormData>();

  useEffect(() => {
    if (dateFromParam && dateToParam) {
      setIsSubmitDisabled(false);
    }
  }, [dateFromParam, dateToParam]);

  useEffect(() => {
    const initialValues = getInitialValues();

    form.setFieldsValue(initialValues);
  }, [dateFromParam, dateToParam]);

  const getInitialValues = () => {
    const dateRange =
      dateFromParam && dateToParam
        ? [dayjs(dateFromParam), dayjs(dateToParam)]
        : undefined;

    const bankId =
      bankIdParam ??
      String(
        Math.min.apply(
          Math,
          banks.map((bank) => bank.bankId)
        )
      );

    return {
      dateRange,
      bankId,
    };
  };

  const onValuesChange: FormProps<AcquiringReportFormData>['onValuesChange'] = (
    _,
    allValues
  ) => {
    if (allValues.dateRange !== undefined) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  const handleSubmit = (values: AcquiringReportFormData) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());

    const { dateRange, bankId } = values;

    updatedSearchParams.set(
      'dateFrom',
      dateRange[0].format(DATE_FORMAT_REVERSE)
    );
    updatedSearchParams.set('dateTo', dateRange[1].format(DATE_FORMAT_REVERSE));

    if (bankId === undefined) {
      updatedSearchParams.delete('bankId');
    } else {
      updatedSearchParams.set('bankId', bankId);
    }

    setSearchParams(updatedSearchParams.toString());

    allowReportFetching();
  };

  return (
    <ReportFormWrapper>
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={handleSubmit}
        onValuesChange={onValuesChange}
      >
        <FormRow gutter={15}>
          <Col>
            <FormItem
              label="Период от и до"
              name="dateRange"
              rules={[VALIDATION_RULE.REQUIRED]}
            >
              <ReportRangePicker />
            </FormItem>
          </Col>
          <Col span={6}>
            <FormItem label="Банк" name="bankId">
              <UiSelect
                placeholder="Выберите банк"
                options={banks.map(({ name, bankId }) => ({
                  label: name,
                  value: String(bankId),
                }))}
              />
            </FormItem>
          </Col>
        </FormRow>
        <GetReportButton disabled={isSubmitDisabled} spinning={loading} />
      </Form>
    </ReportFormWrapper>
  );
}
