import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import { getEndpointPath } from 'shared/lib';

import { baseApi } from 'shared/api/base-api';

import {
  AddCarBrandLogoRequest,
  AddCarBrandRequest,
  CarBrandResponseBase,
  CarBrandsResponseBase,
  CustomAddCarBrandRequest,
  CustomUpdateCarBrandRequest,
  UpdateCarBrandLogoRequest,
  UpdateCarBrandRequest,
} from '../model/api';

import { apiSlice } from '../../../redux/services/apiSlice';

import { PLATFORM_SERVICE } from 'entities/authorization';

const KEY = 'Car';

const getUrl = getEndpointPath(PLATFORM_SERVICE, KEY);

// TODO: замена на baseApi

export const carBrandsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCarBrands: builder.query<CarBrandsResponseBase, void>({
      query: () => getUrl(`GetCarBrands`),
      providesTags: ['CarBrands'],
    }),
    getCarBrand: builder.query<CarBrandResponseBase, string>({
      query: (id) => ({
        url: getUrl(`GetCarBrand`),
        params: { id },
      }),
      providesTags: (result, error, arg) => [{ type: 'CarBrand', id: arg }],
    }),
    addCarBrand: builder.mutation<CarBrandResponseBase, AddCarBrandRequest>({
      query: (carBrand) => ({
        url: getUrl('AddCarBrand'),
        method: 'POST',
        body: carBrand,
      }),
      invalidatesTags: ['CarBrands'],
    }),
    updateCarBrand: builder.mutation<
      CarBrandResponseBase,
      UpdateCarBrandRequest
    >({
      query: (carBrand) => ({
        url: getUrl('UpdateCarBrand'),
        method: 'PATCH',
        body: carBrand,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'CarBrand', id: arg.id },
        'CarBrands',
      ],
    }),
    deleteCarBrand: builder.mutation<ResponseBase, string>({
      query: (id) => ({
        url: getUrl(`DeleteCarBrand`),
        params: { id },
        method: 'DELETE',
      }),
      invalidatesTags: ['CarBrands'],
    }),
    //
    // Car Brand Logo
    //
    addCarBrandLogo: builder.mutation<ResponseBase, AddCarBrandLogoRequest>({
      query: ({ carBrandId, fileLogo }) => ({
        url: getUrl(`AddCarBrandLogo`),
        params: {
          carBrandId,
        },
        method: 'POST',
        body: fileLogo,
        formData: true,
      }),
    }),
    updateCarBrandLogo: builder.mutation<
      ResponseBase,
      UpdateCarBrandLogoRequest
    >({
      query: ({ logoId, fileLogo }) => ({
        url: getUrl(`UpdateCarBrandLogo`),
        params: {
          logoId,
        },
        method: 'PATCH',
        body: fileLogo,
        formData: true,
      }),
    }),
    deleteCarBrandLogo: builder.mutation<ResponseBase, string>({
      query: (logoId) => ({
        url: getUrl(`DeleteCarBrandLogo`),
        params: { logoId },
        method: 'DELETE',
      }),
    }),
    //
    // Custom mutations
    //
    customAddCarBrand: builder.mutation<ResponseBase, CustomAddCarBrandRequest>(
      {
        async queryFn(args, _queryApi, _extraOptions, baseQuery) {
          const { name, fileLogo } = args;

          const addCarBrandRes = await baseQuery({
            url: getUrl(`AddCarBrand`),
            method: 'POST',
            body: {
              name,
            },
          });

          if (addCarBrandRes.error) {
            return {
              error: addCarBrandRes.error as FetchBaseQueryError,
            };
          }

          const addCarBrandResData =
            addCarBrandRes.data as CarBrandResponseBase;

          if (addCarBrandResData.statusCode !== 0) {
            return {
              error: {
                error: addCarBrandResData.statusDescription,
                status: `CUSTOM_ERROR`,
              },
            };
          }

          const carBrandId = addCarBrandResData.carBrand.id;

          const addCarBrandLogoRes = await baseQuery({
            url: getUrl(`AddCarBrandLogo`),
            params: { carBrandId },
            method: 'POST',
            body: fileLogo,
            formData: true,
          });

          if (addCarBrandLogoRes.error) {
            return {
              error: addCarBrandLogoRes.error as FetchBaseQueryError,
            };
          }

          const addCarBrandLogoResData =
            addCarBrandLogoRes.data as ResponseBase;

          if (addCarBrandLogoResData.statusCode !== 0) {
            return {
              error: {
                error: addCarBrandLogoResData.statusDescription,
                status: `CUSTOM_ERROR`,
              },
            };
          }

          return { data: { statusCode: 0, statusDescription: '' } };
        },
        invalidatesTags: ['CarBrands'],
      }
    ),
    customUpdateCarBrand: builder.mutation<
      ResponseBase,
      CustomUpdateCarBrandRequest
    >({
      async queryFn(args, _queryApi, _extraOptions, baseQuery) {
        const { name, id, logoId, fileLogo, oldName } = args;

        if (name !== oldName) {
          const updateCarBrandReq: UpdateCarBrandRequest = {
            id,
            name,
          };

          const updateCarBrandRes = await baseQuery({
            url: getUrl(`UpdateCarBrand`),
            method: 'PATCH',
            body: {
              ...updateCarBrandReq,
            },
          });

          if (updateCarBrandRes.error) {
            return {
              error: updateCarBrandRes.error as FetchBaseQueryError,
            };
          }

          const updateCarBrandResData =
            updateCarBrandRes.data as CarBrandResponseBase;

          if (updateCarBrandResData.statusCode !== 0) {
            return {
              error: {
                error: updateCarBrandResData.statusDescription,
                status: `CUSTOM_ERROR`,
              },
            };
          }
        }

        if (logoId === null && fileLogo) {
          const addCarBrandLogoRes = await baseQuery({
            url: getUrl(`AddCarBrandLogo`),
            params: { carBrandId: id },
            method: 'POST',
            body: fileLogo,
            formData: true,
          });

          if (addCarBrandLogoRes.error) {
            return {
              error: addCarBrandLogoRes.error as FetchBaseQueryError,
            };
          }

          const addCarBrandLogoResData =
            addCarBrandLogoRes.data as ResponseBase;

          if (addCarBrandLogoResData.statusCode !== 0) {
            return {
              error: {
                error: addCarBrandLogoResData.statusDescription,
                status: `CUSTOM_ERROR`,
              },
            };
          }
        }

        if (logoId !== null && fileLogo) {
          const updateCarBrandLogoRes = await baseQuery({
            url: getUrl(`UpdateCarBrandLogo`),
            params: {
              logoId,
            },
            method: 'PATCH',
            body: fileLogo,
            formData: true,
          });

          if (updateCarBrandLogoRes.error) {
            return {
              error: updateCarBrandLogoRes.error as FetchBaseQueryError,
            };
          }

          const updateCarBrandLogoResData =
            updateCarBrandLogoRes.data as ResponseBase;

          if (updateCarBrandLogoResData.statusCode !== 0) {
            return {
              error: {
                error: updateCarBrandLogoResData.statusDescription,
                status: `CUSTOM_ERROR`,
              },
            };
          }
        }

        return { data: { statusCode: 0, statusDescription: '' } };
      },
      invalidatesTags: (result, error, arg) => [
        { type: 'CarBrand', id: arg.id },
        'CarBrands',
      ],
    }),
  }),
});

export const {
  useGetCarBrandsQuery,
  useGetCarBrandQuery,
  useCustomAddCarBrandMutation,
  useCustomUpdateCarBrandMutation,
} = carBrandsApi;
