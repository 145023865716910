import { UploadOutlined } from '@ant-design/icons';
import { Col, Form, FormProps, Upload } from 'antd';
import { UploadFile, UploadProps } from 'antd/es/upload/interface';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  fileExtensionsValidationRule,
  formHasErrors,
  getExtensionsString,
  hasAcceptedExtension,
  isFileLessThan,
  maxFileSizeValidationRule,
  normFile,
  VALIDATION_RULE,
} from 'shared/lib';
import { UiInput } from 'shared/ui/ui-kit';
import {
  ACCEPTED_IMAGE_EXTENSIONS,
  IMAGE_EXTENSION_ERROR,
  IMAGE_MAX_SIZE,
  IMAGE_SIZE_ERROR,
} from 'shared/consts';
import {
  ButtonsContainer,
  UiSubmitButton,
  UiEditFormButton,
  UiCancelFormButton,
  FormControlsContainer,
  UploadedImage,
} from 'shared/ui';

import {
  selectTokenRoles,
  selectTokenClaims,
  checkControllerRight,
} from 'entities/authorization';

import {
  CardFormWrapper,
  FormItem,
  FormRow,
  StyledButton,
  StyledDraggerFormItem,
} from '../../../../common-styles';

import { CarBrandFormData, CarBrandFormProps } from '../../../car/model';

const getFileValidationRules = (allowEmpty: boolean) => {
  const commonRules = [
    fileExtensionsValidationRule(ACCEPTED_IMAGE_EXTENSIONS),
    maxFileSizeValidationRule(IMAGE_MAX_SIZE),
  ];

  if (allowEmpty) {
    return commonRules;
  }

  return [VALIDATION_RULE.REQUIRED, ...commonRules];
};

const accepted = getExtensionsString(ACCEPTED_IMAGE_EXTENSIONS);

type FormData = CarBrandFormData;

export function CarBrandForm({
  title,
  handleSubmit,
  initialValues,
  loading,
  disabled = false,
  allowEmptyFile = false,
  currentLogo,
}: CarBrandFormProps) {
  const roles = useSelector(selectTokenRoles);
  const claims = useSelector(selectTokenClaims);

  const hasWriteRight = checkControllerRight('Car', 'Write', roles, claims);

  const [isFormDisabled, setIsFormDisabled] = useState(disabled);

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [hasErrors, setHasErrors] = useState(false);

  const [form] = Form.useForm<FormData>();

  const deleteFile = async (uid: string) => {
    const files = form.getFieldValue('files') as UploadFile<any>[];

    const updatedFiles = files.filter((file) => file.uid !== uid);

    form.setFieldValue('files', updatedFiles);

    form.validateFields(['files']);

    setHasErrors(formHasErrors(form));

    if (!allowEmptyFile && updatedFiles.length === 0) {
      setIsSubmitDisabled(true);
    }
  };

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  const onValuesChange: FormProps<FormData>['onValuesChange'] = (
    _changedValues,
    allValues
  ) => {
    if (
      (allowEmptyFile ? true : allValues.files.length !== 0) &&
      allValues.name !== ''
    ) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  const onFieldsChange: FormProps<FormData>['onFieldsChange'] = (
    _,
    _allFields
  ) => {
    setHasErrors(formHasErrors(form));
  };

  const props: UploadProps = {
    maxCount: 1,
    multiple: false,
    accept: accepted,
    beforeUpload: (file, list) => {
      return false;
    },
    itemRender: (originNode, file, fileList) => {
      let errors: string[] = [];

      if (!hasAcceptedExtension(ACCEPTED_IMAGE_EXTENSIONS, file.name)) {
        errors = [...errors, IMAGE_EXTENSION_ERROR];
      }

      if (file.size && !isFileLessThan(file.size, IMAGE_MAX_SIZE)) {
        errors = [...errors, IMAGE_SIZE_ERROR];
      }

      return (
        <UploadedImage
          imageName={file.name}
          imageSrc={URL.createObjectURL(file.originFileObj)}
          errors={errors}
          handleDelete={() => deleteFile(file.uid)}
        />
      );
    },
  };

  const primaryButton = isFormDisabled ? (
    <UiEditFormButton onClick={() => setIsFormDisabled(false)} />
  ) : (
    <UiSubmitButton disabled={isSubmitDisabled || hasErrors} />
  );

  let formControls: JSX.Element | null = null;

  if (hasWriteRight) {
    formControls = (
      <FormControlsContainer>
        <ButtonsContainer spinning={loading}>
          {primaryButton}
          <UiCancelFormButton />
        </ButtonsContainer>
      </FormControlsContainer>
    );
  }

  return (
    <CardFormWrapper title={title}>
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues}
        onFinish={handleSubmit}
        onValuesChange={onValuesChange}
        onFieldsChange={onFieldsChange}
      >
        <FormRow>
          <Col span={24}>
            <FormItem
              label="Наименование"
              name="name"
              rules={[VALIDATION_RULE.REQUIRED]}
            >
              <UiInput disabled={isFormDisabled} />
            </FormItem>
          </Col>
        </FormRow>

        {currentLogo ? currentLogo : null}

        <FormRow>
          <Col span={24}>
            <StyledDraggerFormItem>
              <FormItem
                label="Файл логотипа"
                name="files"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={getFileValidationRules(allowEmptyFile)}
              >
                <Upload.Dragger {...props}>
                  <StyledButton
                    htmlType="button"
                    type="primary"
                    icon={<UploadOutlined />}
                    style={{ margin: '0 auto' }}
                    disabled={isFormDisabled}
                  >
                    Выбрать файл
                  </StyledButton>
                </Upload.Dragger>
              </FormItem>
            </StyledDraggerFormItem>
          </Col>
        </FormRow>
        {formControls}
      </Form>
    </CardFormWrapper>
  );
}
