export function WifiIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="19" r="1" fill="#28303F" />
      <path
        d="M21.4448 7.66592C18.955 5.38903 15.6396 4 11.9998 4C8.35992 4 5.04451 5.38903 2.55469 7.66592M18.2386 11.5134C16.6208 9.95692 14.422 9 11.9998 9C9.57749 9 7.37873 9.95692 5.76088 11.5134M15.0193 15.3765C14.286 14.5332 13.2051 14 11.9998 14C10.7944 14 9.71354 14.5332 8.98018 15.3765"
        stroke="#28303F"
        stroke-width="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
