import { Link } from 'react-router-dom';

import BlackLogo from './images/black.svg';
import BlackSmallLogo from './images/black-small.svg';
import WhiteLogo from './images/white.svg';
import WhiteSmallLogo from './images/white-small.svg';

import { CompanyLogoContainer } from './styles';

type CompanyLogoType = 'black' | 'black-small' | 'white' | 'white-small';

type Props = {
  type: CompanyLogoType;
};

const LOGO: Record<CompanyLogoType, string> = {
  black: BlackLogo,
  'black-small': BlackSmallLogo,
  white: WhiteLogo,
  'white-small': WhiteSmallLogo,
};

export function CompanyLogo({ type }: Props) {
  return (
    <CompanyLogoContainer>
      <Link to="/">
        <img src={LOGO[type]} alt="company logo" />
      </Link>
    </CompanyLogoContainer>
  );
}
