import { createColumnHelper } from '@tanstack/react-table';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';

import { FORMAT_TO_SECONDS, showTimeString } from 'shared/lib';
import { useTableSettings } from 'shared/ui/table/visible-columns/use-table-settings';
import { TColumnDef } from 'shared/ui/table/types';

import { MongoSessionDTO } from 'entities/session';
import { TABLE_TIME_COLUMN_WIDTH } from 'shared/ui/table/consts';

import { selectClientBankCardSessions } from '../../../../redux/slices/tables/tables-slice';
import { setVisibleColumns, TableColumns, TableColumnsState } from '../store';

const columnHelper = createColumnHelper<MongoSessionDTO>();

const DATA_COLUMNS: TColumnDef<MongoSessionDTO, TableColumns>[] = [
  {
    accessorKey: 'psLastUpdateTime',
    id: 'psLastUpdateTime',
    header: 'Дата операции',
    cell: (props) => {
      const date = props.row.original.psLastUpdateTime || '';

      return date ? showTimeString(date, FORMAT_TO_SECONDS) : '';
    },
    size: TABLE_TIME_COLUMN_WIDTH,
    minSize: TABLE_TIME_COLUMN_WIDTH,
    maxSize: TABLE_TIME_COLUMN_WIDTH,
  },
  {
    accessorKey: 'orderNumber',
    id: 'orderNumber',
    header: 'Номер заказа',
  },
  {
    accessorKey: 'status',
    id: 'status',
    header: 'Статус',
  },
  {
    accessorKey: 'statusHR',
    id: 'statusHR',
    header: 'Детализация',
  },
];

export const useColumns = () => {
  const dispatch = useDispatch();

  const settings = useSelector(selectClientBankCardSessions);

  const settingsColumn = useTableSettings({
    columnHelper,
    columns: DATA_COLUMNS.map(({ id, header }) => {
      return {
        key: id,
        label: header as string,
        isChecked: settings[id],
      };
    }),
    settings,
    renderCell: (props) => {
      return '';
    },
    setVisibleColumns: (cols: TableColumnsState) => {
      dispatch(setVisibleColumns(cols));
    },
  });

  const visibleColumns = useMemo(() => {
    const dataCols = settings
      ? DATA_COLUMNS.filter((el) => settings[el.id])
      : DATA_COLUMNS;

    return [...dataCols, settingsColumn];
  }, [settings]);

  return visibleColumns;
};
