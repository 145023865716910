import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import React from 'react';

import { ServerTableLayout } from 'shared/ui';

import { Transaction } from 'entities/balance/model/api';
import { BankDTO } from 'entities/bank';
import { ChargePointDTO } from 'entities/charge-point';

import { useColumns } from '../hooks/use-columns';
import {
  useFiltersState,
  usePaginationState,
  useSortingState,
} from 'shared/ui/table/hooks';

type Props = {
  tableData: Array<Transaction>;
  loading: boolean;
  count: number | undefined;
  tablePageSize: number;
  tableHeadRef: (element: HTMLElement | SVGElement | null) => void;
  banks: Array<BankDTO>;
  chargePoints: Array<ChargePointDTO>;
};

export function TableInstance({
  tableData,
  loading,
  count,
  tableHeadRef,
  tablePageSize,
  banks,
  chargePoints,
}: Props) {
  const columns = useColumns({ chargePoints });

  const [searchParams, setSearchParams] = useSearchParams();

  const ordering = searchParams.get('ordering');
  const type = searchParams.get('type');
  const page = searchParams.get('page');
  const limit = searchParams.get('limit');
  const search = searchParams.get('search');
  const searchText = searchParams.get('searchText');

  const data = useMemo(() => tableData, [tableData]);

  const sorting = useSortingState();
  const pagination = usePaginationState();
  const filters = useFiltersState();

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnFilters: filters,
      pagination,
    },
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount: count
      ? Math.ceil(count / (limit ? Number(limit) : tablePageSize))
      : undefined,
  });

  return (
    <ServerTableLayout
      table={table}
      loading={loading}
      pageSize={tablePageSize}
      tableHeadRef={tableHeadRef}
      rowsCount={count}
    />
  );
}

export default React.memo(TableInstance);
