import { createContext, useState, useContext } from 'react';

type TFetchingContext = {
  fetchingTab: string;
  handleSetFetchingTab: (val: string) => void;
};

const FetchingContext = createContext<TFetchingContext>({
  fetchingTab: '',
  handleSetFetchingTab: () => {},
});

type Props = {
  children: React.ReactNode;
};

export const ChargePointsFetchingContextProvider = ({ children }: Props) => {
  const [fetchingTab, setFetchingTab] = useState('');

  const handleSetFetchingTab = (val: string) => {
    setFetchingTab(val);
  };

  return (
    <FetchingContext.Provider value={{ fetchingTab, handleSetFetchingTab }}>
      {children}
    </FetchingContext.Provider>
  );
};

export const useChargePointsFetchingContext = () => useContext(FetchingContext);
