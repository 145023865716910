import { ChargingClientState, ChargingClientType, Sex } from '../model';
import { GateId } from '../model/person'; //

export const CHARGING_CLIENT_STATE: Record<ChargingClientState, string> = {
  0: 'Новый',
  1: 'Активный',
  2: 'Заблокированный',
  3: 'Удаленный',
};

export const SEX: Record<Sex, string> = {
  0: 'Неизвестно',
  1: 'Мужской',
  2: 'Женский',
};

// export const CHARGING_CLIENT_TYPE: Record<ChargingClientType, string> = {
//   0: 'Физическое лицо',
//   1: 'Представитель Юр.лица',
//   2: 'Не определен',
//   3: 'Юридическое лицо',
// };

export const CHARGING_CLIENT_TYPE: Record<
  Extract<ChargingClientType, 0 | 1>,
  string
> = {
  0: 'Физ. лицо',
  1: 'Представитель Юр.лица',
};

// Используется в binding
export const GATE_ID: Record<GateId, string> = {
  0: 'Не указано',
  1: 'Sber',
  2: 'Ameria',
  3: 'ArcaIPay',
};
