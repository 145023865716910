export function ReportsIcon() {
  return (
    <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.875 18.75L13.3461 22.5406C12.8074 22.78 12.1926 22.78 11.6539 22.5406L3.125 18.75M21.875 13.5417L13.3461 17.3323C12.8074 17.5717 12.1926 17.5717 11.6539 17.3323L3.125 13.5417M3.94672 8.22335L11.5683 12.0341C12.1548 12.3274 12.8452 12.3274 13.4317 12.0341L21.0533 8.22335C21.821 7.83947 21.821 6.74384 21.0533 6.35996L13.4317 2.54917C12.8452 2.25591 12.1548 2.25591 11.5683 2.54917L3.94673 6.35996C3.17897 6.74384 3.17896 7.83947 3.94672 8.22335Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
