import NiceModal from '@ebay/nice-modal-react';
import { useSelector } from 'react-redux';

import { PlusInCircleIcon } from 'shared/icons/plus-in-circle';

import {
  checkControllerRight,
  selectTokenClaims,
  selectTokenRoles,
} from 'entities/authorization';

import { AddConnectorModal } from '../modal';
import { StyledAddButton } from '../../../../../common-styles';

type Props = {
  chargePointId: string;
  chargePointName: string;
};

export function AddConnectorButton({ chargePointId, chargePointName }: Props) {
  const roles = useSelector(selectTokenRoles);
  const claims = useSelector(selectTokenClaims);

  const hasWriteRight = checkControllerRight(
    'Connector',
    'Write',
    roles,
    claims
  );
  const hasInstructionReadRight = checkControllerRight(
    'Instruction',
    'Read',
    roles,
    claims
  );

  if (!hasWriteRight || !hasInstructionReadRight) {
    return null;
  }

  const onClick = () => {
    NiceModal.show(AddConnectorModal, { chargePointId, chargePointName });
  };

  return (
    <StyledAddButton onClick={onClick} type="button">
      <PlusInCircleIcon />
    </StyledAddButton>
  );
}
