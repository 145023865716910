import { Radio } from 'antd';
import styled from 'styled-components';

import { COLOR } from 'shared/consts';

const { Group } = Radio;

export const LanguageRadioGroup = styled(Group)`
  display: flex;
  gap: 10px;
  padding-left: 22px;
  height: 32px;
  align-items: center;
  border-left: 1px solid ${COLOR.headerBorder};

  & .ant-radio-button-wrapper:focus-within {
    box-shadow: none;
  }

  & .ant-radio-button-wrapper {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    padding: 0;
    border: none !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: ${COLOR.grey};
  }

  & .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }

  & .ant-radio-button-wrapper-checked {
    background-color: ${COLOR.whiteSmoke};
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: ${COLOR.black};
  }
`;
