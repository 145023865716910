import { EmptyData, NavMenuError } from 'shared/ui';

import { useGetPersonsGroupsQuery } from 'entities/group';

import { PersonGroupsMenu } from '../menu';

export function ConnectedPersonGroupsMenu() {
  const { data, isFetching, error } = useGetPersonsGroupsQuery();

  if (data && data.statusCode !== 0) {
    return <NavMenuError text={data.statusDescription} />;
  }

  if (error) {
    return <NavMenuError />;
  }

  if (!isFetching && !data) {
    return <EmptyData />;
  }

  const items = data?.groups || [];

  return <PersonGroupsMenu loading={isFetching} items={items} />;
}
