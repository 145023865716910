import { Metric } from '../../../models/metric';
import { MetricsDashboard, METRICS_DASHBOARD } from '../consts';

type Props = {
  loading: boolean;
  metrics: Array<Metric> | null;
  dashboards: Array<MetricsDashboard>;
};

export function DashboardMetrics({ loading, metrics, dashboards }: Props) {
  return (
    <>
      {dashboards.map((el) => {
        return METRICS_DASHBOARD[el].renderDashboard({ loading, metrics });
      })}
    </>
  );
}
