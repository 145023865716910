import dayjs, { Dayjs } from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker, {
  PickerDateProps,
  RangePickerDateProps,
} from 'antd/es/date-picker/generatePicker';

export interface DatePickerProps
  extends Omit<PickerDateProps<Dayjs>, 'picker'> {}
export interface RangePickerProps
  extends Omit<RangePickerDateProps<Dayjs>, 'picker'> {}

export const DayjsDatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);

export const dayjsDatePickerFormat: DatePickerProps['format'] = (value) =>
  `${dayjs(value).locale('ru').format('DD MMM YYYY')}`;
