import { MainLayout } from 'shared/ui/layout/main';

import { ChargePointsFetchingContextProvider } from '../../context';
import { ChargePointsTabs } from '../tabs';

export function ChargePointsLayout() {
  return (
    <ChargePointsFetchingContextProvider>
      <MainLayout tabs={<ChargePointsTabs />} />
    </ChargePointsFetchingContextProvider>
  );
}
