import { useParams } from 'react-router-dom';

import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';

import { useGetAllBonusProgramLevelsQuery } from 'entities/bonus-program';

import { BonusProgramLevels } from '../levels';

export function ConnectedBonusProgramLevels() {
  const { bonusProgramId } = useParams() as { bonusProgramId: string };

  const { data, isFetching, error } =
    useGetAllBonusProgramLevelsQuery(bonusProgramId);

  if (isFetching) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!data) {
    return <EmptyData />;
  }

  return <BonusProgramLevels levels={data} />;
}
