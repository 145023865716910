import { CardTitle, IconsContainer, StyledCard } from './styles';

import { AdvancedProperty } from 'entities/charge-point';
import { CafeIcon } from '../assets/cafe';
import { CloseAccessIcon } from '../assets/close-access';
import { HotelIcon } from '../assets/hotel';
import { Hour24Icon } from '../assets/hour-24';
import { ParkIcon } from '../assets/park';
import { ParkingIcon } from '../assets/parking';
import { ShopIcon } from '../assets/shop';
import { ShoppingMallIcon } from '../assets/shopping-mall';
import { WcIcon } from '../assets/wc';
import { WifiIcon } from '../assets/wifi';
import { Fragment } from 'react';

const PROPERTY_ICON: Record<keyof AdvancedProperty, JSX.Element> = {
  cafe: <CafeIcon />,
  closeAccess: <CloseAccessIcon />,
  hotel: <HotelIcon />,
  hour24: <Hour24Icon />,
  park: <ParkIcon />,
  parking: <ParkingIcon />,
  shop: <ShopIcon />,
  shoppingMall: <ShoppingMallIcon />,
  wc: <WcIcon />,
  wifi: <WifiIcon />,
};

type Props = {
  advancedProperty: AdvancedProperty;
};

export function AdvancedPropertiesCard({ advancedProperty }: Props) {
  return (
    <StyledCard>
      <CardTitle>Дополнительная информация</CardTitle>
      <IconsContainer>
        {(
          Object.keys(advancedProperty) as unknown as Array<
            keyof typeof advancedProperty
          >
        )
          .filter((el) => Boolean(advancedProperty[el]))
          .map((el) => {
            return <Fragment key={el}>{PROPERTY_ICON[el]}</Fragment>;
          })}
      </IconsContainer>
    </StyledCard>
  );
}
