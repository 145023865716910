import { StyledTabSpinner, TabSpinnerContainer } from './styles';

type Props = {
  isLoading: boolean;
};

export function NavitationTabSpinner({ isLoading }: Props) {
  return (
    <TabSpinnerContainer>
      {isLoading ? <StyledTabSpinner /> : null}
    </TabSpinnerContainer>
  );
}
