import { useParams } from 'react-router-dom';

import { ConnectedCommandsTable } from 'features/manual-command/table';

export function ChargePointsGroupCommandsPage() {
  const { chargePointName, groupId } = useParams() as {
    chargePointName: string;
    groupId: string;
  };

  return <ConnectedCommandsTable groupId={groupId} />;
}
