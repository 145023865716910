import { Outlet } from 'react-router';

import { MainContentLayout } from '../main-content';

type Props = {
  tabs?: JSX.Element;
};

export function MainLayout({ tabs }: Props) {
  return (
    <>
      {tabs}
      <MainContentLayout element={<Outlet />} />
    </>
  );
}
