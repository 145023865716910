import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { openErrorNotification, isErrorWithMessage } from 'shared/lib';

import { useLoginMutation } from '../../../../../redux/services/identity';
import { setSessionCredentials } from '../../../../../redux/slices/auth/authSessionSlice';
import { setCredentials } from '../../../../../redux/slices/auth/authSlice';

import { LOGIN_ERROR } from '../../consts';
import { getLoginErrorMessage } from '../../lib/get-error-message';
import { LoginFormData } from '../../model/types';
import { LoginForm } from '../form';

export function ConnectedLoginForm() {
  const [errorMsg, setErrorMsg] = useState('');

  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  const [trigger, { isLoading }] = useLoginMutation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const clearErrorMsg = () => setErrorMsg('');

  const handleSubmit = async (values: LoginFormData) => {
    const { login, password, rememberUser } = values;

    try {
      const res = await trigger({
        login,
        password,
      }).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        const msg = getLoginErrorMessage(res.statusDescription);
        setErrorMsg(msg);

        return;
      }
      //

      const { accessToken, refreshToken } = res;

      const payload = {
        accessToken,
        refreshToken,
      };

      if (rememberUser) {
        dispatch(setCredentials(payload));
      } else {
        dispatch(setSessionCredentials(payload));
      }

      navigate(from);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : LOGIN_ERROR;

      openErrorNotification(errorText);
    }
  };

  return (
    <LoginForm
      loading={isLoading}
      handleSubmit={handleSubmit}
      errorMsg={errorMsg}
      clearErrorMsg={clearErrorMsg}
    />
  );
}
