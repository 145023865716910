import { Rule } from 'antd/lib/form';

import { hasAcceptedExtension, isFileLessThan } from 'shared/lib';

import {
  DATE_OF_BIRTH_PATTERN,
  FULL_DATE_PATTERN,
  INN_PATTERN,
  LATITUDE_PATTERN,
  LONGITUDE_PATTERN,
  PERCENT_PATTERN,
  PHONE_NUMBER_PATTERN,
  RUSSIAN_PHONE_NUMBER_PATTERN,
  TELEGRAMM_PATTERN,
} from './regex';

export const TELEGRAMM_MAX_LENGTH = 20;

export const VALIDATION_RULE: Record<string, Rule> = {
  REQUIRED: {
    required: true,
    message: 'Обязательное поле',
  },
  NO_WHITESPACE: {
    validator: (_, value: string) => {
      if (value === '') {
        return Promise.resolve();
      }

      if (value.startsWith(' '))
        return Promise.reject('Не может начинаться с пробела');

      return value.trim() !== ''
        ? Promise.resolve()
        : Promise.reject('Не может содержать только пробел(ы)');
    },
  },
  URL: {
    type: 'url',
    message: 'Введите валидный URL',
  },
  NULL_OR_POSITIVE_INTEGER: {
    validator: (_, value) => {
      if (value === '') return Promise.resolve();

      return /^(0|[1-9][0-9]*)$/.test(value)
        ? Promise.resolve()
        : Promise.reject('0 или целое положительное число');
    },
  },
  NULL_OR_POSITIVE_NUMBER: {
    validator: (_, value) => {
      if (value === '') return Promise.resolve();

      return Number(value) >= 0 && value !== '-0'
        ? Promise.resolve()
        : Promise.reject('0 или положительное число');
    },
  },
  POSITIVE_INTEGER: {
    validator: (_, value) => {
      if (value === '') return Promise.resolve();

      return /^([1-9][0-9]*)$/.test(value)
        ? Promise.resolve()
        : Promise.reject('целое положительное число');
    },
  },
  POSITIVE_NUMBER: {
    validator: (_, value) => {
      if (value === '') return Promise.resolve();

      return Number(value) > 0
        ? Promise.resolve()
        : Promise.reject('положительное число');
    },
  },
  FULL_DATE: {
    validator: (_, value) => {
      if (value === '') return Promise.resolve();

      return FULL_DATE_PATTERN.test(value)
        ? Promise.resolve()
        : Promise.reject('Формат даты: DD-MM-YYYY HH:mm:ss');
    },
  },
  DATE_OF_BIRTH: {
    validator: (_, value) => {
      if (value === '') return Promise.resolve();

      return DATE_OF_BIRTH_PATTERN.test(value)
        ? Promise.resolve()
        : Promise.reject('Формат даты: DD.MM.YYYY или DD-MM-YYYY');
    },
  },
  PHONE_NUMBER: {
    validator: (_, value) => {
      if (value === '') return Promise.resolve();

      return PHONE_NUMBER_PATTERN.test(value)
        ? Promise.resolve()
        : Promise.reject('Невалидный номер');
    },
  },
  RUSSIAN_PHONE_NUMBER: {
    validator: (_, value) => {
      if (value === '') return Promise.resolve();

      return RUSSIAN_PHONE_NUMBER_PATTERN.test(value)
        ? Promise.resolve()
        : Promise.reject('Формат: +7 и 10 цифр');
    },
  },
  EMAIL: {
    type: 'email',
    message: 'Введите валидный email',
  },
  NUMERIC: {
    validator: (_, value) => {
      if (value === '') return Promise.resolve();

      return /^[0-9]*$/.test(value)
        ? Promise.resolve()
        : Promise.reject('Имя должно содержать только цифры');
    },
  },
  LATITUDE: {
    validator: (_, value) => {
      if (value === '') return Promise.resolve();

      return LATITUDE_PATTERN.test(value)
        ? Promise.resolve()
        : Promise.reject('Широта должна быть от -90 до 90');
    },
  },
  LONGITUDE: {
    validator: (_, value) => {
      if (value === '') return Promise.resolve();

      return LONGITUDE_PATTERN.test(value)
        ? Promise.resolve()
        : Promise.reject('Широта должна быть от -180 до 180');
    },
  },
  PERCENT: {
    validator: (_, value) => {
      if (value === '') return Promise.resolve();

      return PERCENT_PATTERN.test(value)
        ? Promise.resolve()
        : Promise.reject('Целое число от 0 до 100');
    },
  },
  TELEGRAMM: {
    validator: (_, value) => {
      if (value === '') return Promise.resolve();

      return TELEGRAMM_PATTERN.test(value)
        ? Promise.resolve()
        : Promise.reject('Начинается с @. Допустимые спец. символы: +_-');
    },
  },
  OCPP_CONFIG: {
    validator: (_, value) => {
      if (value === '') return Promise.resolve();

      return /^[\w]+=[\w\"]+(\s[\w]+=[\w\"]+)*$/.test(value)
        ? Promise.resolve()
        : Promise.reject(
            'Формат данных: пара ключ=значение на отдельной строке'
          );
    },
  },
  INSTRUCTION_URL: {
    validator: (_, value) => {
      if (value === '') return Promise.resolve();

      return /^[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)+(\s[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)+)*$/.test(
        value
      )
        ? Promise.resolve()
        : Promise.reject('Формат данных: url на отдельной строке');
    },
  },
  MAX_INSTRUCTION_LINKS: {
    validator: (_, value) => {
      if (value === '') return Promise.resolve();

      return value.split('\n').length === 3
        ? Promise.resolve()
        : Promise.reject('Необходимо 3 url');
    },
  },
  INN: {
    validator: (_, value) => {
      if (value === '') return Promise.resolve();

      return INN_PATTERN.test(value)
        ? Promise.resolve()
        : Promise.reject('От 10 до 12 цифр');
    },
  },
};

export const maxLengthValidationRule = (len: number): Rule => ({
  validator: (_, value) => {
    if (value === '') return Promise.resolve();

    return value.length <= len
      ? Promise.resolve()
      : Promise.reject(`Максимальная длина - ${len} символа(-ов)`);
  },
});

export const minLengthValidationRule = (len: number): Rule => ({
  validator: (_, value) => {
    if (value === '') return Promise.resolve();

    return value.length >= len
      ? Promise.resolve()
      : Promise.reject(`Не менее ${len} символа(-ов)`);
  },
});

export const requiredFileValidationRule = (allowEmpty: boolean): Rule => ({
  validator: (_, fileList) => {
    if (allowEmpty) {
      return Promise.resolve();
    }

    return fileList.length !== 0
      ? Promise.resolve()
      : Promise.reject('Обязательное поле');
  },
});
