import { ChargePointProtocol, ChargePointStatus } from 'entities/charge-point';

export const ADD_SUCCESS = 'Новая ЭЗС добавлена';
export const ADD_ERROR = 'Не удалось добавить ЭЗС';

export const getEditSuccess = (name: string): string =>
  `ЭЗС "${name}" обновлена`;
export const getEditError = (name: string): string =>
  `Не удалось обновить ЭЗС "${name}"`;

export const DEFAULT_CHARGE_POINT_PROTOCOL: ChargePointProtocol = 4;
export const DEFAULT_CHARGE_POINT_STATUS: ChargePointStatus = 0;
