import { useState, useEffect } from 'react';
import { RectReadOnly } from 'react-use-measure';

import { TBODY_TR_HEIGHT } from 'shared/consts';
import { getScrollbarWidth } from 'shared/lib';

export function useTablePageSize(tableHeadBounds: RectReadOnly) {
  const [tablePageSize, setTablePageSize] = useState(0);

  const clientHeight = document.documentElement.clientHeight;

  useEffect(() => {
    if (tableHeadBounds.height == 0) {
      return;
    }

    const scrollbarWidth = getScrollbarWidth();

    const availableTableBodyHeght =
      clientHeight -
      tableHeadBounds.top -
      tableHeadBounds.height -
      scrollbarWidth -
      39 - // пагинация таблицы
      20; // нижний паддинг контейнера

    const pageSize = Math.floor(availableTableBodyHeght / TBODY_TR_HEIGHT);

    setTablePageSize(pageSize);
  }, [tableHeadBounds.height]);

  return [tablePageSize, setTablePageSize] as const;
}
