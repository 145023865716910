import styled from 'styled-components';

import { COLOR } from 'shared/consts';

export const FloatLabelWrapper = styled.div`
  position: relative;
  height: 40px;
  border-radius: 8px;

  & .ant-input-affix-wrapper-focused + label {
    color: ${COLOR.greenMenu};
  }

  &.success .ant-input-affix-wrapper {
    border-color: ${COLOR.greenMenu};
    &:focus {
      box-shadow: none;
    }

    & + label {
      color: ${COLOR.greenMenu};
    }
  }

  &.error .ant-input-affix-wrapper {
    border-color: ${COLOR.red};

    & + label {
      color: ${COLOR.red};
    }
  }

  & input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  input[type='password'] {
    font-family: Verdana;
    letter-spacing: 0.125em;
  }

  & .ant-input-affix-wrapper {
    height: 100%;
    border-radius: 2px;
  }

  & .label {
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 12px;
    top: 12px;
    transition: 0.2s ease all;
    z-index: 100;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }

  & .as-placeholder {
    color: ${COLOR.black};
  }

  & .ant-input-affix-wrapper-disabled + .as-placeholder {
    color: ${COLOR.inputGrey};
  }

  & .as-label {
    top: -8px;
    font-size: 12px !important;
    background: white;
    padding: 0 4px;
    margin-left: -4px;
  }

  & .ant-input {
    padding: 9px 10px;

    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: ${COLOR.black};
  }

  & .ant-select .ant-select-selector {
    padding: 16px 10px 4px 11px;
  }

  & .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 16px 10px 4px 11px;
    height: 48px;
  }

  & .ant-select-single .ant-select-selector .ant-select-selection-search {
    top: 16px;
  }
`;

type Props = {
  status: 'success' | 'error' | 'undefined';
};

export const IconContainer = styled.div<Props>`
  color: ${(props) => {
    const { status } = props;

    if (status === 'success') {
      return '#23C990';
    } else if (status === 'error') {
      return '#F86767';
    } else {
      return '#acacac';
    }
  }};
`;
