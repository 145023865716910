import { ADD_PREFIX, SETTINGS } from './common';

export const OCPI_HUBS = 'ocpi-hubs';
export const OCPI_HUB = ':ocpiHubId';

export const OCPI_HUB_ROUTES = {
  OCPI_HUBS: `/${SETTINGS}/${OCPI_HUBS}`,
  OCPI_HUB: `/${SETTINGS}/${OCPI_HUBS}/${OCPI_HUB}`,
  ADD_OCPI_HUB: `/${SETTINGS}/${OCPI_HUBS}/${ADD_PREFIX}`,
};
