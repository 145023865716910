import { FormatUrl } from '../model/firmware';

export const FORMAT_URL: Record<FormatUrl, string> = {
  0: 'Soap',
  1: 'Json',
};

export const MAX_FIRMWARE_FILE_SIZE = 30;

export const FIRMWARE_FILE_SIZE_ERROR = 'Максимальный размер файла - 30 Мб';
