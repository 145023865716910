export const NO_LETTERS_PATTERN =
  /^[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~\d\s]*$/;

export const PHONE_NUMBER_PATTERN = /^[\d+]{11}$/;

export const INN_PATTERN = /^[\d+]{10,12}$/;

export const RUSSIAN_PHONE_NUMBER_PATTERN = /^\+7[\d+]{10}$/;

export const LATITUDE_PATTERN =
  /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/;
export const LONGITUDE_PATTERN =
  /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/;

// От 0 до 100
export const PERCENT_PATTERN = /^[0-9]$|^[1-9][0-9]$|^(100)$/;

// DD-MM-YYYY HH:mm:ss
export const FULL_DATE_PATTERN =
  /^(0[1-9]|1\d|2[0-9]|29(?=-\d\d-(?!1[01345789]00|2[1235679]00)\d\d(?:[02468][048]|[13579][26]))|30(?!-02)|31(?=-0[13578]|-1[02]))-(0[1-9]|1[0-2])-([12]\d{3}) ([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/;

export const TELEGRAMM_PATTERN = /^@[a-z0-9\+_-]{2,}$/;

export const DATE_OF_BIRTH_PATTERN =
  /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{4})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{4})$/;
