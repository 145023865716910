import { createColumnHelper } from '@tanstack/react-table';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';

import { renderBooleanAsString, tryParseJSONObject } from 'shared/lib';
import { useTableSettings } from 'shared/ui/table/visible-columns/use-table-settings';
import { TColumnDef } from 'shared/ui/table/types';

import { OwnerDto } from 'entities/owner';

import { selectOwnersTable } from '../../../../redux/slices/tables/tables-slice';
import { setVisibleColumns, TableColumns, TableColumnsState } from '../store';

import { OwnersTableActions } from '../ui/actions';

const columnHelper = createColumnHelper<OwnerDto>();

const DATA_COLUMNS: TColumnDef<OwnerDto, TableColumns>[] = [
  {
    accessorKey: 'id',
    id: 'id',
    header: 'Id',
    size: 100,
    // minSize: 100,
    // maxSize: 100,
  },
  {
    accessorKey: 'name',
    id: 'name',
    header: 'Наименование',
    size: 200,
    // minSize: 200,
    // maxSize: 200,
  },
  {
    accessorKey: 'legalName',
    id: 'legalName',
    header: 'Юридическое наименование',
    size: 200,
    // minSize: 200,
    // maxSize: 200,
  },
  {
    accessorKey: 'description',
    id: 'description',
    header: 'Описание',
    size: 225,
    // minSize: 225,
    // maxSize: 225,
  },
  {
    accessorKey: 'tariff',
    id: 'tariff.name',
    header: 'Тариф',
    cell: (props) => {
      return props.row.original.tariff?.name || '';
    },
    sortingFn: (rowA, rowB, columnId) => {
      const tariffNameRowA = rowA.original.tariff?.name ?? '';
      const tariffNameRowB = rowB.original.tariff?.name ?? '';

      if (tariffNameRowA > tariffNameRowB) return 1;

      if (tariffNameRowA < tariffNameRowB) return -1;

      return 0;
    },
    size: 170,
    // minSize: 170,
    // maxSize: 170,
  },
  {
    accessorKey: 'phoneNumber',
    id: 'phoneNumber',
    header: 'Телефон',
    size: 120,
    // minSize: 120,
    // maxSize: 120,
  },
  {
    accessorKey: 'email',
    id: 'email',
    header: 'Email',
    cell: (props) => {
      const email = props.row.original.email;

      if (!email) return '';

      const parsedEmail = tryParseJSONObject(email);

      return parsedEmail ? parsedEmail.join(', ') : '';
    },
    size: 150,
    // minSize: 150,
    // maxSize: 150,
  },
  {
    accessorKey: 'inn',
    id: 'inn',
    header: 'ИНН',
    size: 120,
    // minSize: 120,
    // maxSize: 120,
  },
  {
    accessorKey: 'kpp',
    id: 'kpp',
    header: 'КПП',
    size: 120,
    // minSize: 120,
    // maxSize: 120,
  },
  {
    accessorKey: 'agentReceipt',
    id: 'agentReceipt',
    header: 'Печать агентских чеков',
    cell: (props) => {
      const value = props.row.original.agentReceipt;
      renderBooleanAsString(value);
    },
    // cell: (props) => renderBooleanAsString(props.getValue()),
    size: 100,
    // minSize: 100,
    // maxSize: 100,
  },
];

export const useColumns = () => {
  const dispatch = useDispatch();

  const settings = useSelector(selectOwnersTable);

  const settingsColumn = useTableSettings({
    columnHelper,
    columns: DATA_COLUMNS.map(({ id, header }) => {
      return {
        key: id,
        label: header as string,
        isChecked: settings[id],
      };
    }),
    settings,
    renderCell: (props) => {
      const { id, name } = props.row.original;

      return <OwnersTableActions id={String(id)} name={name} />;
    },
    setVisibleColumns: (cols: TableColumnsState) => {
      dispatch(setVisibleColumns(cols));
    },
  });

  const visibleColumns = useMemo(() => {
    const dataCols = settings
      ? DATA_COLUMNS.filter((el) => settings[el.id])
      : DATA_COLUMNS;

    return [...dataCols, settingsColumn];
  }, [settings]);

  return visibleColumns;
};
