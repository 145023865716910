import { StyledPaginationTotalCount } from './styles';

type Props = {
  count: number | undefined;
};

export function PaginationTotalCount({ count }: Props) {
  const value = count ?? '0';

  return (
    <StyledPaginationTotalCount>{`из ${value}`}</StyledPaginationTotalCount>
  );
}
