import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { UiModal } from 'shared/ui';

import { AddChargePointPhoto } from './add-cp-photo';

type Props = {
  chargePointName: string;
};

export const AddChargePointPhotoModal = NiceModal.create((props: Props) => {
  const modal = useModal();

  return (
    <UiModal
      visible={modal.visible}
      onCancel={modal.hide}
      afterClose={modal.remove}
      title="Загрузка фотографий ЭЗС"
    >
      <AddChargePointPhoto {...props} closeModal={modal.hide} />
    </UiModal>
  );
});
