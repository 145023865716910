import { createColumnHelper } from '@tanstack/react-table';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';

import { useTableSettings } from 'shared/ui/table/visible-columns/use-table-settings';
import { TColumnDef } from 'shared/ui/table/types';

import { CarBrand } from 'entities/car-brand';
import { CarModelDto } from 'entities/car-model';

import { selectCarModelsTable } from '../../../../redux/slices/tables/tables-slice';
import { setVisibleColumns, TableColumns, TableColumnsState } from '../store';

import { TableActions } from '../ui/actions';

const columnHelper = createColumnHelper<CarModelDto>();

const DATA_COLUMNS: TColumnDef<CarModelDto, TableColumns>[] = [
  {
    accessorKey: 'id',
    id: 'id',
    header: 'Id',
    size: 100,
    minSize: 100,
    maxSize: 100,
  },
  {
    accessorKey: 'name',
    id: 'name',
    header: 'Наименование',
    size: 200,
    minSize: 200,
    maxSize: 200,
  },
  {
    accessorKey: 'carBrand',
    id: 'carBrand.name',
    header: 'Марка',
    size: 200,
    minSize: 200,
    maxSize: 200,
  },
];

type Props = {
  carBrands: CarBrand[];
};

export const useColumns = ({ carBrands }: Props) => {
  const dispatch = useDispatch();

  const settings = useSelector(selectCarModelsTable);

  const settingsColumn = useTableSettings({
    columnHelper,
    columns: DATA_COLUMNS.map(({ id, header }) => {
      return {
        key: id,
        label: header as string,
        isChecked: settings[id],
      };
    }),
    settings,
    renderCell: (props) => {
      const { id, name, carBrand } = props.row.original;

      const brand = carBrands.find((brand) => {
        return brand.name === carBrand;
      });

      return <TableActions id={String(id)} name={name} brand={brand} />;
    },
    setVisibleColumns: (cols: TableColumnsState) => {
      dispatch(setVisibleColumns(cols));
    },
  });

  const visibleColumns = useMemo(() => {
    const dataCols = settings
      ? DATA_COLUMNS.filter((el) => settings[el.id])
      : DATA_COLUMNS;

    return [...dataCols, settingsColumn];
  }, [settings]);

  return visibleColumns;
};
