export function BonusesIcon() {
  return (
    <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.16406 12.4166C4.16406 11.3121 5.05949 10.4166 6.16406 10.4166H15.7057C16.8103 10.4166 17.7057 11.3121 17.7057 12.4166V12.5833C17.7057 13.6879 16.8103 14.5833 15.7057 14.5833H6.16406C5.0595 14.5833 4.16406 13.6879 4.16406 12.5833V12.4166Z"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        d="M4.16406 8C4.16406 6.89543 5.05949 6 6.16406 6H15.7057C16.8103 6 17.7057 6.89543 17.7057 8V8.16667C17.7057 9.27124 16.8103 10.1667 15.7057 10.1667H6.16406C5.0595 10.1667 4.16406 9.27124 4.16406 8.16667V8Z"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        d="M12.4974 18.75C12.4974 21.0512 14.3629 22.9166 16.6641 22.9166C18.9652 22.9166 20.8307 21.0512 20.8307 18.75C20.8307 16.4488 18.9652 14.5833 16.6641 14.5833C16.4564 14.5833 16.2523 14.5985 16.0528 14.6278M12.4974 18.75C12.4974 16.6564 14.0414 14.9235 16.0528 14.6278M12.4974 18.75H6.2474C5.0968 18.75 4.16406 17.8172 4.16406 16.6666C4.16406 15.5161 5.0968 14.5833 6.2474 14.5833H15.6224C15.77 14.5833 15.9139 14.5987 16.0528 14.6278"
        stroke="black"
        strokeWidth="1.5"
      />
    </svg>
  );
}
