import { useParams } from 'react-router-dom';

import {
  isErrorWithMessage,
  getServerErrorText,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';

import { ErrorMessage, EmptyData } from 'shared/ui';

import { useGetGroupQuery } from 'entities/group';
import {
  useGetMobileAppsQuery,
  useSetMobileAppEZSMutation,
} from 'entities/mobile-app';

import { getEditBindingsError, getEditBindingsSuccess } from '../../constants';
import { ChargePointGroupAppBindings } from '../ChargePointGroupAppBindings';

export function ConnectedChargePointGroupAppBindings() {
  const { groupId } = useParams() as { groupId: string };

  const {
    data: mobileApps,
    isFetching: isMobileAppsFetching,
    error: mobileAppsError,
  } = useGetMobileAppsQuery();

  const {
    data: group,
    isFetching: isGroupFetching,
    error: groupError,
  } = useGetGroupQuery(groupId);

  const [trigger, { isLoading: isUpdateLoading }] =
    useSetMobileAppEZSMutation();

  if (isMobileAppsFetching || isGroupFetching) {
    return <div>Loading...</div>;
  }

  const apiResponseError = [group, mobileApps].find(
    (el) => el && el.statusCode !== 0
  );

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusDescription} />;
  }

  const error = [groupError, mobileAppsError].find((err) => err !== undefined);

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!group || !mobileApps) {
    return <EmptyData />;
  }

  const handleSubmit = async (values: Record<string, boolean>) => {
    const groupName = group.group.name;

    const mobileAppIds = Object.keys(values).reduce((acc, value) => {
      if (values[value] === true) {
        return [...acc, Number(value)];
      }

      return acc;
    }, [] as number[]);

    try {
      const res = await trigger({ groupId, appIds: mobileAppIds }).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);
        return;
      }
      //

      const successText = getEditBindingsSuccess(groupName);

      openSuccessNotification(successText);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : getEditBindingsError(groupName);

      openErrorNotification(errorText);
    }
  };

  return (
    <ChargePointGroupAppBindings
      handleSubmit={handleSubmit}
      group={group.group}
      mobileApps={mobileApps.mobileApps}
      loading={isUpdateLoading}
    />
  );
}
