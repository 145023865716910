import { COUNTRY_ROUTES } from 'shared/consts/routes/country';
import { MenuAddButton } from 'shared/ui/section-menu';

export function AddButton() {
  return (
    <MenuAddButton
      path={COUNTRY_ROUTES.ADD_COUNTRY}
      requiredRights={[
        {
          controller: 'Country',
          right: 'Write',
        },
      ]}
    />
  );
}
