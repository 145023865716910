type ErrorWithMessage = {
  status: number;
  data: ResponseBase;
};

export const isErrorWithMessage = (
  error: unknown
): error is ErrorWithMessage => {
  return (
    typeof error === 'object' &&
    error !== null &&
    'data' in error &&
    (error as Record<string, unknown>).data !== null &&
    typeof (error as Record<string, unknown>).data === 'object'
  );
};
