import { useLocation } from 'react-router-dom';

import { NavigationTabs } from 'shared/ui/navigation-tabs';

import { TABS } from '../../consts';

export function RoleTabs() {
  const location = useLocation();

  return <NavigationTabs tabs={TABS} rootPath={location.pathname} />;
}
