import { PregPageIcon } from './images/PrevPageIcon';
import { NextPageIcon } from './images/NextPageIcon';

import { StyledPaginationButton } from './styles';

type Props = {
  type: 'prev' | 'next';
  disabled: boolean;
  onClick: () => void;
};

export function PaginationButton({ type, disabled, onClick }: Props) {
  return (
    <StyledPaginationButton
      onClick={onClick}
      disabled={disabled}
      icon={type === 'prev' ? <PregPageIcon /> : <NextPageIcon />}
    />
  );
}
