import { MainLayout } from 'shared/ui/layout/main';

import { PersonsGroupFetchingContextProvider } from '../../context';
import { PersonsGroupTabs } from '../tabs';

export function PersonsGroupLayout() {
  return (
    <PersonsGroupFetchingContextProvider>
      <MainLayout tabs={<PersonsGroupTabs />} />
    </PersonsGroupFetchingContextProvider>
  );
}
