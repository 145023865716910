import { apiSlice } from '../../../redux/services/apiSlice';

import {
  AddPushNotificationMessageFormRequest,
  PushLoadPersonsRequest,
  PushLoadPersonsResponse,
  PushNotificationMessageGetResponse,
  PushNotificationMessagesGetResponse,
  PushSendManualPushResponse,
  UpdatePushNotificationMessageRequest,
} from './types';

import { PLATFORM_SERVICE } from 'entities/authorization';

import { getEndpointPath } from 'shared/lib';

const KEY = 'PushNotification';

const getUrl = getEndpointPath(PLATFORM_SERVICE, KEY);

export const pushNotificationsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPushNotificationMessages: builder.query<
      PushNotificationMessagesGetResponse,
      void
    >({
      query: () => getUrl('GetPushNotificationMessages'),
      providesTags: ['PushNotifications'],
    }),
    getPushNotificationMessage: builder.query<
      PushNotificationMessageGetResponse,
      string
    >({
      query: (id) => ({
        url: getUrl(`GetPushNotificationMessage`),
        params: { id },
      }),
      providesTags: (result, error, arg) => [
        { type: 'PushNotification', id: arg },
      ],
    }),
    addPushNotificationMessage: builder.mutation<
      PushNotificationMessageGetResponse,
      AddPushNotificationMessageFormRequest
    >({
      query: (message) => ({
        url: getUrl('AddPushNotificationMessage'),
        method: 'POST',
        body: message,
      }),
      invalidatesTags: ['PushNotifications'],
    }),
    updatePushNotificationMessage: builder.mutation<
      PushNotificationMessageGetResponse,
      UpdatePushNotificationMessageRequest
    >({
      query: (message) => ({
        url: getUrl('UpdatePushNotificationMessage'),
        method: 'PATCH',
        body: message,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'PushNotification', id: arg.id },
        'PushNotifications',
      ],
    }),
    deletePushNotificationMessage: builder.mutation<ResponseBase, string>({
      query: (id) => ({
        url: getUrl(`DeletePushNotificationMessage`),
        params: { id },
        method: 'DELETE',
      }),
      invalidatesTags: ['PushNotifications'],
    }),
    pushLoadPersons: builder.mutation<
      PushLoadPersonsResponse,
      PushLoadPersonsRequest
    >({
      query: ({ pushNotificationMessageId, file }) => ({
        url: getUrl(`PushLoadPersons`),
        params: { pushNotificationMessageId },
        method: 'POST',
        body: file,
        formData: true,
      }),
      // invalidatesTags: ['PushNotifications'],
    }),
    pushSendManual: builder.mutation<PushSendManualPushResponse, number>({
      query: (pushNotificationMessageId) => ({
        url: getUrl(`PushSendManual`),
        params: { pushNotificationMessageId },
        method: 'POST',
      }),
      // invalidatesTags: ['PushNotifications'],
    }),
  }),
});

export const {
  useGetPushNotificationMessagesQuery,
  useGetPushNotificationMessageQuery,
  useAddPushNotificationMessageMutation,
  useUpdatePushNotificationMessageMutation,
  usePushLoadPersonsMutation,
  usePushSendManualMutation,
} = pushNotificationsApi;
