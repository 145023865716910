import { Controller } from '../model';

export const SUPERUSER_ROLE = 'Admin';

export const PLATFORM_SERVICE = 'platform';
export const BONUS_SERVICE = 'bonus';

// Список контроллеров, для которых права на чтение есть у всех юзеров
export const READ_FOR_ALL_CONTROLLERS: Controller[] = [
  'Bank',
  'Country',
  'MobileApp',
];
