import { useDispatch } from 'react-redux';

import { VersionBadge } from 'shared/ui';

import { clearCredentials } from '../../../../redux/slices/auth/authSlice';
import { clearSessionCredentials } from '../../../../redux/slices/auth/authSessionSlice';

import { LogoutButtonContainer, StyledButton } from './styles';

import LogoutIcon from './logout.svg';

export function LogoutButton() {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(clearCredentials());
    dispatch(clearSessionCredentials());
  };

  return (
    <LogoutButtonContainer>
      <VersionBadge />
      <StyledButton type="default" onClick={handleClick}>
        <img src={LogoutIcon} />
        <p>выход</p>
      </StyledButton>
    </LogoutButtonContainer>
  );
}
