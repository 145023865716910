export const identity = <T,>(value: T): T => value;

export const getChargedSummary = (value: number) => {
  const str = (value / 1000).toString();

  const dotIndex = str.indexOf('.');

  if (dotIndex === -1) {
    return str;
  }

  return str.slice(0, dotIndex + 3).replace('.', ',');
};

// export const roundTwoDecimals = (n: number) =>
//   Math.round((n + Number.EPSILON) * 100) / 100;

export const PERCENT_INPUT_HTML_SIZE = 2;
const DEFAULT_INPUT_HTML_SIZE = 5;

export const EMPTY_SELECT = {
  value: '',
  label: 'Нет',
};

interface SelectOption {
  value: string;
  label: string;
}

export const getSelectOptionsWithEmpty = (options: SelectOption[]) => [
  EMPTY_SELECT,
  ...options,
];

export const renderDot = () => <span>&#x2022;</span>;

export const getDurationInMinutes = (duration: string | null): number => {
  if (duration === null) {
    return 0;
  }

  const [hours, minutes, seconds] = duration.split(':');

  return Number(minutes);
};

export const getDurationString = (duration: string | null): string => {
  if (duration === null) {
    return `0 мин 0 сек`;
  }

  const [hours, minutes, seconds] = duration.split(':');

  const hoursInt = Number(hours);
  const minutesInt = Number(minutes);

  const mins = hoursInt > 0 ? hoursInt * 60 + minutesInt : minutesInt;
  // const secs = Number(seconds);
  const secs = Math.ceil(Number(seconds));

  return `${mins} мин ${secs} сек`;
};
