import { ButtonProps } from 'antd/lib/button';
import { useNavigate } from 'react-router-dom';

import { UiButton } from '../ui-button';

type Props = ButtonProps;

export function UiCancelFormButton(props: Props) {
  const navigate = useNavigate();

  const onClick = () => navigate(-1);

  return (
    <UiButton {...props} htmlType="button" type="default" onClick={onClick}>
      Отмена
    </UiButton>
  );
}
