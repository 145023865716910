import { CarsList } from 'entities/car';

import { AddCarButton } from 'features/car/add-car';
import { CarMutations } from 'features/car/mutations';

type Props = Omit<
  React.ComponentProps<typeof CarsList>,
  'addCarSlot' | 'renderActions'
>;

export function CarsListWidget(props: Props) {
  const { personLogin } = props;

  return (
    <CarsList
      {...props}
      addCarSlot={<AddCarButton personLogin={personLogin} />}
      renderActions={(car) => (
        <CarMutations personLogin={personLogin} car={car} />
      )}
    />
  );
}
