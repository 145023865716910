import styled from 'styled-components';

import { COLOR } from 'shared/consts';

type Props = {
  gained: boolean;
  offset: number;
  isLastMilestone?: boolean;
};

export const MilestoneContainer = styled.div<Props>`
  border-radius: 17px;
  border: 2px solid ${COLOR.white};
  background-color: ${(props) =>
    props.gained ? `${COLOR.greenMenu}` : `${COLOR.whiteSmoke}`};
  color: ${(props) => (props.gained ? `${COLOR.white}` : `${COLOR.black}`)};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  text-align: center;
  padding: 0 8px;

  position: absolute;
  left: ${(props) => (props.isLastMilestone ? 'auto' : `${props.offset}%`)};
  right: ${(props) => (props.isLastMilestone ? '0' : 'auto')};
`;
