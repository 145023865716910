import { useLocation } from 'react-router-dom';

import { NavigationTabs } from 'shared/ui/navigation-tabs';

import { TABS } from '../../consts';
import { usePersonsFetchingContext } from '../../context';

export function PersonsTabs() {
  const { fetchingTab } = usePersonsFetchingContext();

  const location = useLocation();

  return (
    <NavigationTabs
      tabs={TABS}
      fetchingTab={fetchingTab}
      rootPath={location.pathname}
    />
  );
}
