import styled from 'styled-components';
import { Radio } from 'antd';

import { COLOR } from 'shared/consts';
import { DayjsDatePicker } from 'shared/ui';

const { Group, Button } = Radio;

const { RangePicker } = DayjsDatePicker;

export const StyledChargedSummaryCard = styled.article`
  background: ${COLOR.white};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const ChargedSummaryCardHeader = styled.div`
  padding: 17px 15px 13px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ChargedSummaryCardContent = styled.div`
  flex-grow: 1;
`;

export const ChargedSummaryTitle = styled.h4`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #171725;
  width: max-content;
`;

export const StyleDateRangeGroup = styled(Group)`
  display: flex;
  gap: 5px;
`;

export const StyledRadioButton = styled(Button)`
  font-weight: 400;
  border-radius: 4px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border: none !important;
  background-color: ${COLOR.whiteSmoke};
  color: #191d23;

  &::before {
    content: none !important;
  }

  &.ant-radio-button-wrapper {
    height: 25px;
  }

  &.ant-radio-button-wrapper-checked {
    background-color: ${COLOR.greenMenu};
    color: ${COLOR.white};

    &:focus-within {
      box-shadow: none;
    }

    &:hover {
      color: ${COLOR.white};
    }
  }
`;

export const StyledRangePicker = styled(RangePicker)`
  border: none;
  width: 220px;
  padding: 0;
  background-color: transparent;

  &.ant-picker-focused {
    box-shadow: none;
  }

  & .ant-picker-input > input {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #414141;
  }

  & .ant-picker-active-bar {
    display: none;
  }

  & .ant-picker-clear {
    right: 0;
  }
`;

export const LineChartContainer = styled.div`
  flex-grow: 1;
`;

export const ChargedInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0 15px 0 20px;
`;

export const ValueSummary = styled.div`
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  color: #191d23;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
