import styled from 'styled-components';

import {
  ChargePointStatus,
  CHARGE_POINT_STATUS_COLOR,
} from 'entities/charge-point';

type Props = {
  status: ChargePointStatus;
};

export const StatusCircle = styled.div<Props>`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
  background-color: ${(props) => CHARGE_POINT_STATUS_COLOR[props.status]};
`;
