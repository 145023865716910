import { Link } from 'react-router-dom';

import { NavigationTabButton } from 'shared/ui';
import { USER_ROUTES } from 'shared/consts/routes/user';

export function AddUserButtonLink() {
  return (
    <Link to={USER_ROUTES.ADD_USER}>
      <NavigationTabButton title="Добавить пользователя" />
    </Link>
  );
}
