import { getEndpointPath } from 'shared/lib';

import { PLATFORM_SERVICE } from 'entities/authorization';

import { apiSlice } from '../../../redux/services/apiSlice';
import { GetCommandListParams, ManualCommandsResponse } from './types';

const KEY = 'PrivateMethods';

const getUrl = getEndpointPath(PLATFORM_SERVICE, KEY);

export const api = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCommandList: builder.query<ManualCommandsResponse, GetCommandListParams>(
      {
        query: (params) => ({
          url: getUrl(`GetManualCommandList`),
          params,
        }),

        providesTags: (result, error, arg) => [{ type: 'Commands' }],
      }
    ),
  }),
});

export const { useGetCommandListQuery } = api;
