export function RolesIcon() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 3H8C6.89543 3 6 3.89543 6 5V8C6 8.55228 5.55228 9 5 9C3.34315 9 2 10.3431 2 12C2 13.6569 3.34315 15 5 15C5.55228 15 6 15.4477 6 16V19C6 20.1046 6.89543 21 8 21H10C10.5523 21 11 20.5523 11 20C11 18.3431 12.3431 17 14 17C15.6569 17 17 18.3431 17 20C17 20.5523 17.4477 21 18 21H20C21.1046 21 22 20.1046 22 19V16C22 15.4477 21.5523 15 21 15C19.3431 15 18 13.6569 18 12C18 10.3431 19.3431 9 21 9C21.5523 9 22 8.55228 22 8V5C22 3.89543 21.1046 3 20 3Z"
        stroke="#28303F"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
}
