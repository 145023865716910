import Excel from 'exceljs';

const TABLE_HEAD_BG_COLOR = '99ccff';

export const applyWorksheetStyles = (
  worksheet: Excel.Worksheet,
  dataStartRow: number,
  reportTitleRow: number = 1
) => {
  worksheet.columns.forEach((sheetColumn) => {
    sheetColumn.font = {
      size: 12,
    };
    sheetColumn.alignment = {
      horizontal: 'center',
      vertical: 'middle',
      wrapText: true,
    };
  });

  worksheet.getRow(reportTitleRow).font = {
    bold: true,
    size: 20,
  };
  worksheet.getRow(reportTitleRow).alignment = {
    horizontal: 'center',
    vertical: 'middle',
    wrapText: true,
  };
  worksheet.getRow(reportTitleRow).height = 40;

  worksheet.getRow(dataStartRow).font = {
    bold: true,
    size: 13,
  };
  worksheet.getRow(dataStartRow).eachCell((cell) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: TABLE_HEAD_BG_COLOR },
    };
  });
  worksheet.getRow(dataStartRow).height = 40;

  worksheet.eachRow((row) => {
    row.eachCell((cell) => {
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true,
      };
    });
  });
};

export const highlightLastRow = (ws: Excel.Worksheet) => {
  const lastRow = ws.lastRow;

  if (lastRow) {
    lastRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: TABLE_HEAD_BG_COLOR },
      };
    });

    lastRow.font = {
      bold: true,
      size: 13,
    };

    lastRow.alignment = {
      horizontal: 'center',
      vertical: 'middle',
      wrapText: true,
    };
  }
};
