import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { UiModal } from 'shared/ui';

import { AddBonusLevel } from '../add';

type Props = {
  bonusProgramId: string;
};

export const AddBonusLevelModal = NiceModal.create((props: Props) => {
  const modal = useModal();

  return (
    <UiModal
      visible={modal.visible}
      onCancel={modal.hide}
      afterClose={modal.remove}
      title="Добавление нового уровня"
    >
      <AddBonusLevel
        bonusProgramId={props.bonusProgramId}
        closeModal={modal.hide}
      />
    </UiModal>
  );
});
