import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import { getEndpointPath } from 'shared/lib';

import {
  AddDiscountRequest,
  AddDiscountResponse,
  DiscountDtoResponse,
  DiscountResponse,
  DiscountsDtoResponse,
  SetOwnersBannedDiscountsRequest,
  UpdateDiscountRequest,
} from 'entities/discount';
import {
  CustomUpdateDiscountRequest,
  SetDiscountsBannedOwnersRequest,
} from 'entities/discount/model/discount';

import { apiSlice } from '../../../redux/services/apiSlice';
import { PLATFORM_SERVICE } from 'entities/authorization';

const KEY = 'Discount';

const getUrl = getEndpointPath(PLATFORM_SERVICE, KEY);

// TODO: замена на baseApi

export const discountsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDiscounts: builder.query<DiscountsDtoResponse, void>({
      query: () => getUrl('GetDiscounts'),
      providesTags: ['Discounts'],
    }),
    getDiscount: builder.query<DiscountDtoResponse, string>({
      query: (id) => ({
        url: getUrl(`GetDiscount`),
        params: { id },
      }),
      providesTags: (result, error, arg) => [{ type: 'Discount', id: arg }],
    }),
    addDiscount: builder.mutation<AddDiscountResponse, AddDiscountRequest>({
      query: (discount) => ({
        url: getUrl('AddDiscount'),
        method: 'POST',
        body: discount,
      }),
      invalidatesTags: ['Discounts'],
    }),
    updateDiscount: builder.mutation<DiscountResponse, UpdateDiscountRequest>({
      query: (discount) => ({
        url: getUrl('UpdateDiscount'),
        method: 'PATCH',
        body: discount,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Discount', id: arg.id },
        'Discounts',
      ],
    }),
    deleteDiscount: builder.mutation<ResponseBase, string>({
      query: (id) => ({
        url: getUrl(`DeleteDiscount`),
        params: { id },
        method: 'DELETE',
      }),
      invalidatesTags: ['Discounts'],
    }),
    // setOwnersBannedDiscounts: builder.mutation<
    //   ResponseBase,
    //   SetOwnersBannedDiscountsRequest
    // >({
    //   query: (body) => ({
    //     url: getUrl('SetOwnersBannedDiscounts'),
    //     method: 'PATCH',
    //     body: body,
    //   }),
    //   invalidatesTags: (result, error, arg) => [
    //     // { type: 'Discount', id: arg.bannedDiscounts[0].bannedDiscountIds[0] }, // TODO: add later
    //     // mb для owner тоже
    //   ],
    // }),
    setDiscountsBannedOwners: builder.mutation<
      ResponseBase,
      SetDiscountsBannedOwnersRequest
    >({
      query: (body) => ({
        url: getUrl('SetDiscountsBannedOwners'),
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: (result, error, arg) => [
        // { type: 'Discount', id: arg.bannedDiscounts[0].bannedDiscountIds[0] }, // TODO: add later
        // mb для owner тоже
      ],
    }),
    customUpdateDiscount: builder.mutation<
      ResponseBase,
      CustomUpdateDiscountRequest
    >({
      async queryFn(args, _queryApi, _extraOptions, baseQuery) {
        const { discount, discountBannedOwners } = args;

        const updateDiscountReq: UpdateDiscountRequest = discount;

        const updateDiscountRes = await baseQuery({
          url: getUrl(`UpdateDiscount`),
          method: 'PATCH',
          body: updateDiscountReq,
        });

        if (updateDiscountRes.error) {
          return {
            error: updateDiscountRes.error as FetchBaseQueryError,
          };
        }

        const updateDiscountResData =
          updateDiscountRes.data as DiscountResponse;

        if (updateDiscountResData.statusCode !== 0) {
          return {
            error: {
              error: updateDiscountResData.statusDescription,
              status: `CUSTOM_ERROR`,
            },
          };
        }

        const setDiscountBannedOwnersReq: SetDiscountsBannedOwnersRequest =
          discountBannedOwners;

        const setDiscountBannedOwnersRes = await baseQuery({
          url: getUrl(`SetDiscountsBannedOwners`),
          method: 'PATCH',
          body: setDiscountBannedOwnersReq,
        });

        if (setDiscountBannedOwnersRes.error) {
          return {
            error: setDiscountBannedOwnersRes.error as FetchBaseQueryError,
          };
        }

        const setDiscountBannedOwnersResData =
          setDiscountBannedOwnersRes.data as ResponseBase;

        if (setDiscountBannedOwnersResData.statusCode !== 0) {
          return {
            error: {
              error: setDiscountBannedOwnersResData.statusDescription,
              status: `CUSTOM_ERROR`,
            },
          };
        }

        return { data: { statusCode: 0, statusDescription: '' } };
      },
      invalidatesTags: (result, error, arg) => [
        { type: 'Discount', id: arg.discount.id },
        'Discounts', //
        // mb для owner тоже
      ],
    }),
  }),
});

export const {
  useGetDiscountsQuery,
  useGetDiscountQuery,
  useAddDiscountMutation,
  useUpdateDiscountMutation,
  useDeleteDiscountMutation,
  useCustomUpdateDiscountMutation,
} = discountsApi;
