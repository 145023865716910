import { useParams } from 'react-router-dom';

import { useChargePointFetchingContext } from 'entities/charge-point/charge-point-layout';

import { ConnectedEventsTable } from 'features/events/table';

export function ChargePointEventsPage() {
  const { chargePointName, groupId } = useParams() as {
    chargePointName: string;
    groupId: string;
  };

  const { handleSetFetchingTab } = useChargePointFetchingContext();

  return (
    <ConnectedEventsTable
      handleSetFetchingTab={handleSetFetchingTab}
      chargePointName={chargePointName}
    />
  );
}
