import { Link } from 'react-router-dom';
import { Spin } from 'antd';

import {
  DashboardTitle,
  ProblemBrickIcon,
  ProblemIcon,
  ProblemIconContainer,
  ProblemLabel,
  ProblemRow,
  ProblemsContainer,
  ProblemValue,
  StyledProblemsDashboard,
} from './styles';

import { BrickIcon, InvoiceIcon, FlashIcon } from './images';

import { useSelector } from 'react-redux';

import {
  checkControllerRight,
  selectTokenClaims,
  selectTokenRoles,
} from 'entities/authorization';
import { SESSION_LOG } from 'shared/consts/routes/common';
import { CHARGE_POINT_ROUTES } from 'shared/consts/routes/charge-point';

const PROBLEM = {
  problemcheck: {
    label: 'Чеков в ошибке',
    icon: (
      <ProblemIconContainer>
        <ProblemIcon src={InvoiceIcon} />
        <ProblemBrickIcon src={BrickIcon} />
      </ProblemIconContainer>
    ),
    path: `${CHARGE_POINT_ROUTES.CHARGE_POINTS}/${SESSION_LOG}?isProblemFiscal=true`,
  },
  blockedcp: {
    label: 'Заблокированно станций',
    icon: (
      <ProblemIconContainer>
        <ProblemIcon src={FlashIcon} />
        <ProblemBrickIcon src={BrickIcon} />
      </ProblemIconContainer>
    ),
    path: `${CHARGE_POINT_ROUTES.CHARGE_POINTS}?blockedCp=true`,
  },
};

type Props = {
  problemcheck: number;
  blockedcp: number;
  loading: boolean;
};

export function ProblemsDashboard({ problemcheck, blockedcp, loading }: Props) {
  const roles = useSelector(selectTokenRoles);
  const claims = useSelector(selectTokenClaims);

  const hasRight = checkControllerRight(
    'ChargePoint',
    'Execute',
    roles,
    claims
  );

  if (!hasRight) {
    return null;
  }

  const renderProblem = (problem: keyof typeof PROBLEM, value: number) => {
    const { icon, label, path } = PROBLEM[problem];

    return (
      <Link to={path}>
        <ProblemRow>
          {icon}
          <ProblemLabel>{label}</ProblemLabel>
          <ProblemValue>{value}</ProblemValue>
        </ProblemRow>
      </Link>
    );
  };

  if (problemcheck === 0 && blockedcp === 0) {
    return null;
  }

  return (
    <StyledProblemsDashboard>
      <DashboardTitle>Проблемы</DashboardTitle>
      <Spin spinning={loading}>
        <ProblemsContainer>
          {problemcheck ? renderProblem('problemcheck', problemcheck) : null}
          {blockedcp ? renderProblem('blockedcp', blockedcp) : null}
        </ProblemsContainer>
      </Spin>
    </StyledProblemsDashboard>
  );
}
