import { Col, Form, FormProps } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { formHasErrors, VALIDATION_RULE } from 'shared/lib';
import { UiInput, UiSelect } from 'shared/ui/ui-kit';
import {
  ButtonsContainer,
  UiSubmitButton,
  UiEditFormButton,
  UiCancelFormButton,
  FormControlsContainer,
  UiDeleteFormButton,
} from 'shared/ui';

import {
  selectTokenRoles,
  selectTokenClaims,
  checkControllerRight,
} from 'entities/authorization';

import { CardFormWrapper, FormItem, FormRow } from '../../../../common-styles';

import { CarModelFormData, CarModelFormProps } from '../../../car/model';

type FormData = CarModelFormData;

export function CarModelForm({
  title,
  handleSubmit,
  initialValues,
  loading,
  carBrands,
  disabled = false,
  onDeleteClick,
}: CarModelFormProps) {
  const roles = useSelector(selectTokenRoles);
  const claims = useSelector(selectTokenClaims);

  const hasWriteRight = checkControllerRight('Car', 'Write', roles, claims);

  const [isFormDisabled, setIsFormDisabled] = useState(disabled);

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [hasErrors, setHasErrors] = useState(false);

  const navigate = useNavigate();

  const [form] = Form.useForm<FormData>();

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  const onFieldsChange: FormProps<FormData>['onFieldsChange'] = (
    _,
    _allFields
  ) => {
    setHasErrors(formHasErrors(form));
  };

  const onValuesChange: FormProps<FormData>['onValuesChange'] = (
    _,
    allValues
  ) => {
    if (allValues.name !== '' && allValues.carBrandId !== '') {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  const primaryButton = isFormDisabled ? (
    <UiEditFormButton onClick={() => setIsFormDisabled(false)} />
  ) : (
    <UiSubmitButton disabled={isSubmitDisabled || hasErrors} />
  );

  let formControls: JSX.Element | null = null;

  if (hasWriteRight) {
    formControls = (
      <FormControlsContainer>
        <ButtonsContainer spinning={loading}>
          {primaryButton}
          <UiCancelFormButton />
          {onDeleteClick ? (
            <UiDeleteFormButton onClick={onDeleteClick} />
          ) : null}
        </ButtonsContainer>
      </FormControlsContainer>
    );
  }

  return (
    <CardFormWrapper title={title}>
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={handleSubmit}
        onFieldsChange={onFieldsChange}
        onValuesChange={onValuesChange}
        initialValues={initialValues}
      >
        <FormRow>
          <Col span={24}>
            <FormItem
              label="Наименование"
              name="name"
              rules={[VALIDATION_RULE.REQUIRED]}
            >
              <UiInput disabled={isFormDisabled} />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow>
          <Col span={24}>
            <FormItem
              label="Марка"
              name="carBrandId"
              rules={[VALIDATION_RULE.REQUIRED]}
            >
              <UiSelect
                disabled={isFormDisabled}
                options={carBrands.map(({ id, name }) => ({
                  value: String(id),
                  label: name,
                }))}
              />
            </FormItem>
          </Col>
        </FormRow>
        {formControls}
      </Form>
    </CardFormWrapper>
  );
}
