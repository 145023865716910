import { StyledButton } from './styles';

import ExportIcon from './images/export.svg';

interface ExportButtonProps {
  onClick: () => void;
  disabled?: boolean;
}

export function ExportButton({ onClick, disabled = false }: ExportButtonProps) {
  return (
    <StyledButton type="default" onClick={onClick} disabled={disabled}>
      <img src={ExportIcon} />
      Экспортировать
    </StyledButton>
  );
}
