import { COLOR } from 'shared/consts';
import styled from 'styled-components';

export const AchievementsContainter = styled.div`
  height: 17px;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

export const NextLevelHint = styled.p`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  color: ${COLOR.black};
  opacity: 0.5;
  margin-bottom: 10px;
`;
