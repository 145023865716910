import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Leaves } from 'shared/lib/types';

import { ClientBindingsTableDataItem } from 'entities/person';

type ObjectLeaves = Leaves<ClientBindingsTableDataItem>;

export type TableColumns = Extract<
  ObjectLeaves,
  | 'isMain'
  | 'gateId'
  | 'expiryDate'
  | 'maskedPan'
  | 'bindingId'
  | 'eventQueueDateTime'
>;

export type TableColumnsState = Record<TableColumns, boolean>;

const initialState: TableColumnsState = {
  isMain: true,
  bindingId: true,
  eventQueueDateTime: true,
  expiryDate: true,
  gateId: true,
  maskedPan: true,
};

const slice = createSlice({
  name: 'clientBindingsTable',
  initialState,
  reducers: {
    setVisibleColumns: (state, action: PayloadAction<TableColumnsState>) => {
      return action.payload;
    },
  },
});

export const { setVisibleColumns } = slice.actions;
export default slice.reducer;
