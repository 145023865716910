import styled from 'styled-components';

import { BaseStyledCard } from '../../../common-styles';
import { DayjsDatePicker } from 'shared/ui';
import { COLOR } from 'shared/consts';

export const ReportPaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

export const ReportHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 24px;
`;

export const StyledReportCard = styled(BaseStyledCard)`
  padding: 30px;
`;

export const ReportFormWrapper = styled.div`
  margin-bottom: 15px;
`;

const { RangePicker } = DayjsDatePicker;

export const ReportRangePicker = styled(RangePicker)`
  border: none;
  width: 220px;
  padding: 7px 6px 6px 10px;
  border-radius: 4px;
  background-color: ${COLOR.white};

  &.ant-picker-focused {
    box-shadow: none;
  }

  & .ant-picker-input > input {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: ${COLOR.black};
  }

  & .ant-picker-active-bar {
    display: none;
  }

  & .ant-picker-clear {
    right: 0;
  }
`;
