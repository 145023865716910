import { JsonViewer } from '@textea/json-viewer';

import { GoBackButton } from './styles';

interface JsonDataViewerProps {
  data: Object;
  onClick: () => void;
}

export function JsonDataViewer({ data, onClick }: JsonDataViewerProps) {
  return (
    <>
      <GoBackButton onClick={onClick}>К списку</GoBackButton>
      <JsonViewer
        value={data}
        onCopy={(_path, val) => {
          const container = document.createElement('textarea');

          container.innerHTML =
            typeof val === 'string' ? val : JSON.stringify(val, null, '  ');

          document.body.appendChild(container);

          container.select();

          // copy the same quote-less value back to the clipboard
          document.execCommand('copy');
          document.body.removeChild(container);
        }}
      />
    </>
  );
}
