import { Route } from 'react-router-dom';

import { SectionLayout } from 'shared/ui/layout';
import { TARIFF, TARIFFS } from 'shared/consts/routes/tariff';
import { ADD_PREFIX } from 'shared/consts/routes/common';

import { RequireAuth } from 'entities/authorization';
import { TariffsLayout } from 'entities/tariff/tariffs-layout';

import { ConnectedTariffsMenu } from 'features/tariff/menu';

import { AddTariffPage, EditTariffPage } from 'pages/tariff';

import { ConnectedTariffsTable } from '../../../features/tariff/table/ui/connected-table';

export const TariffRoutes = (
  <Route
    element={<RequireAuth controllerName="ChargeTariff" rightType="Read" />}
  >
    <Route
      path={TARIFFS}
      element={<SectionLayout menu={<ConnectedTariffsMenu />} />}
    >
      <Route element={<TariffsLayout />}>
        <Route index element={<ConnectedTariffsTable />} />
      </Route>

      <Route
        element={
          <RequireAuth controllerName="ChargeTariff" rightType="Write" />
        }
      >
        <Route path={ADD_PREFIX} element={<AddTariffPage />} />
      </Route>
      <Route path={TARIFF} element={<EditTariffPage />} />
    </Route>
  </Route>
);
