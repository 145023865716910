import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

export const getServerErrorText = (
  error: FetchBaseQueryError | SerializedError
) => {
  if ('status' in error) {
    const errMsg = 'error' in error ? error.error : JSON.stringify(error.data);

    return `${error.status}${errMsg}`;
  } else {
    return error.message ?? '';
  }

  // Old logic
  // `${error.status}${error.data ? `: ${error.data}` : ''}`;
};
