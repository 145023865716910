import dayjs from 'dayjs';

import {
  METRIC_REQUEST_DATE_FORMAT,
  METRIC_RESPONSE_DATE_FORMAT,
} from '../consts';

export const getResponseMetricsDateParam = (date: dayjs.Dayjs): string =>
  date.format(METRIC_RESPONSE_DATE_FORMAT);

export const getRequestMetricsDateParam = (date: dayjs.Dayjs): string =>
  date.format(METRIC_REQUEST_DATE_FORMAT);
