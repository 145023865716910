import { useSelector } from 'react-redux';

import {
  checkControllerRight,
  READ_FOR_ALL_CONTROLLERS,
  selectTokenClaims,
  selectTokenRoles,
} from 'entities/authorization';

import { SiderbarMenuItem } from '../types';

export const useVisibleItems = (items: SiderbarMenuItem[]) => {
  const roles = useSelector(selectTokenRoles);
  const claims = useSelector(selectTokenClaims);

  const visibleItems = items.filter(({ controller }) => {
    if (READ_FOR_ALL_CONTROLLERS.includes(controller)) {
      return checkControllerRight(controller, 'Write', roles, claims);
    }

    return checkControllerRight(controller, 'Read', roles, claims);
  });

  return visibleItems;
};
