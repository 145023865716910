import { ConnectedCommandsTable } from 'features/manual-command/table';
import { useParams } from 'react-router-dom';

export function ChargePointCommandsPage() {
  const { chargePointName, groupId } = useParams() as {
    chargePointName: string;
    groupId: string;
  };

  return <ConnectedCommandsTable chargePointName={chargePointName} />;
}
