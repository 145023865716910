import { Col, Form } from 'antd';
import { useEffect, useState } from 'react';
import { FormProps } from 'antd/es/form/Form';
import { useSelector } from 'react-redux';

import { formHasErrors, VALIDATION_RULE } from 'shared/lib';
import {
  ButtonsContainer,
  UiSubmitButton,
  UiEditFormButton,
  UiCancelFormButton,
  FormControlsContainer,
} from 'shared/ui';
import { UiDivider, UiFormCheckbox, UiInput, UiSelect } from 'shared/ui/ui-kit';

import {
  selectTokenRoles,
  selectTokenClaims,
  checkControllerRight,
} from 'entities/authorization';
import {
  CHARGING_CLIENT_STATE,
  CHARGING_CLIENT_TYPE,
  PersonFormData,
  PersonFormProps,
  SEX,
} from 'entities/person';

import { SessionToken, UserFormContainer } from './styles';

import { CardFormWrapper, FormItem, FormRow } from '../../../../common-styles';

type FormData = PersonFormData;

export function PersonForm({
  handleSubmit,
  initialValues,
  loading,
  title,
  companies,
  countries,
  groups,
  disabled = false,
  sessionToken,
  carListElement,
  bonusInfo,
}: PersonFormProps) {
  const roles = useSelector(selectTokenRoles);
  const claims = useSelector(selectTokenClaims);

  const hasWriteRight = checkControllerRight('Person', 'Write', roles, claims);

  const [isFormDisabled, setIsFormDisabled] = useState(disabled);

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [hasErrors, setHasErrors] = useState(false);

  const [form] = Form.useForm<FormData>();

  const companyId = Form.useWatch('companyId', form);
  const chargingClientType = Form.useWatch('chargingClientType', form);

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  useEffect(() => {
    if (!companyId) return;

    if (companyId === '6') {
      form.setFieldValue('chargingClientType', '0');
    } else {
      form.setFieldValue('chargingClientType', '1');
    }
  }, [companyId]);

  const onFieldsChange: FormProps<FormData>['onFieldsChange'] = (
    _,
    _allFields
  ) => {
    setHasErrors(formHasErrors(form));
  };

  const onValuesChange: FormProps<FormData>['onValuesChange'] = (
    _,
    allValues
  ) => {
    if (
      allValues.login !== '' &&
      allValues.chargingClientState !== '' &&
      allValues.clientsDebt !== '' &&
      allValues.countryId !== '' &&
      allValues.idGroup !== ''
    ) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  const primaryButton = isFormDisabled ? (
    <UiEditFormButton onClick={() => setIsFormDisabled(false)} />
  ) : (
    <UiSubmitButton disabled={isSubmitDisabled || hasErrors} />
  );

  let formControls: JSX.Element | null = null;

  if (hasWriteRight) {
    formControls = (
      <FormControlsContainer>
        <ButtonsContainer spinning={loading}>
          {primaryButton}
          <UiCancelFormButton />
        </ButtonsContainer>
      </FormControlsContainer>
    );
  }

  return (
    <UserFormContainer>
      <CardFormWrapper title={title}>
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={handleSubmit}
          onFieldsChange={onFieldsChange}
          onValuesChange={onValuesChange}
          initialValues={initialValues}
        >
          <FormRow gutter={10}>
            <Col span={6}>
              <FormItem
                label="Дата регистрации"
                name="registrationDate"
                rules={[VALIDATION_RULE.FULL_DATE]}
              >
                <UiInput disabled={isFormDisabled} />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                label="Номер телефона"
                name="login"
                rules={[VALIDATION_RULE.REQUIRED]}
              >
                <UiInput disabled={Boolean(initialValues.login)} />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                label="Email"
                name="email"
                rules={[VALIDATION_RULE.EMAIL]}
              >
                <UiInput disabled={isFormDisabled} />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                label="Статус"
                name="chargingClientState"
                rules={[VALIDATION_RULE.REQUIRED]}
              >
                <UiSelect
                  disabled={isFormDisabled}
                  options={Object.entries(CHARGING_CLIENT_STATE).map(
                    (entry) => {
                      const [key, label] = entry;

                      return { value: key, label };
                    }
                  )}
                />
              </FormItem>
            </Col>
          </FormRow>

          <FormRow gutter={10}>
            <Col span={6}>
              <FormItem label="Фамилия" name="lastName">
                <UiInput disabled={isFormDisabled} />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="Имя" name="firstName">
                <UiInput disabled={isFormDisabled} />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="Отчество" name="patronymic">
                <UiInput disabled={isFormDisabled} />
              </FormItem>
            </Col>
          </FormRow>

          <FormRow gutter={10}>
            <Col span={6}>
              <FormItem
                label="Дата рождения"
                name="dateOfBirth"
                rules={[VALIDATION_RULE.DATE_OF_BIRTH]}
              >
                <UiInput disabled={isFormDisabled} />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="Пол" name="sex">
                <UiSelect
                  disabled={isFormDisabled}
                  options={Object.entries(SEX).map((entry) => {
                    const [key, label] = entry;

                    return { value: key, label };
                  })}
                />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                label="Страна"
                name="countryId"
                rules={[VALIDATION_RULE.REQUIRED]}
              >
                <UiSelect
                  disabled={isFormDisabled}
                  options={countries.map(({ id, name }) => ({
                    value: String(id),
                    label: name,
                  }))}
                />
              </FormItem>
            </Col>
          </FormRow>

          <FormRow gutter={10}>
            <Col span={6}>
              <FormItem
                label="Тип пользователя"
                name="chargingClientType"
                rules={[VALIDATION_RULE.REQUIRED]}
              >
                <UiSelect
                  disabled
                  options={Object.entries(CHARGING_CLIENT_TYPE).map((entry) => {
                    const [key, label] = entry;

                    return { value: key, label };
                  })}
                />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                label="Добавить в группу"
                name="idGroup"
                rules={[VALIDATION_RULE.REQUIRED]}
              >
                <UiSelect
                  disabled={isFormDisabled}
                  options={groups.map(({ id, name }) => ({
                    value: String(id),
                    label: name,
                  }))}
                />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                label="Компания"
                name="companyId"
                rules={[VALIDATION_RULE.REQUIRED]}
              >
                <UiSelect
                  disabled={isFormDisabled}
                  options={companies.map(({ id, name }) => ({
                    value: String(id),
                    label: name,
                  }))}
                />
              </FormItem>
            </Col>
          </FormRow>

          {bonusInfo ? (
            <>
              <UiDivider />
              {bonusInfo}
            </>
          ) : null}

          <UiDivider />

          <FormRow gutter={10}>
            <Col span={6}>
              <FormItem
                label="Задолженность"
                name="clientsDebt"
                rules={[
                  VALIDATION_RULE.NULL_OR_POSITIVE_NUMBER,
                  VALIDATION_RULE.REQUIRED,
                ]}
              >
                <UiInput disabled={isFormDisabled} />
              </FormItem>
            </Col>
          </FormRow>

          <UiDivider />

          <FormRow gutter={10}>
            <Col span={12}>
              <FormItem label="Комментарий" name="commentary">
                <UiInput disabled={isFormDisabled} />
              </FormItem>
            </Col>
          </FormRow>

          <UiDivider />

          {carListElement}

          <FormRow style={{ gap: '10px' }}>
            <Col>
              <FormItem name="userAgreementFlag" valuePropName="checked">
                <UiFormCheckbox disabled={isFormDisabled}>
                  Флаг принятия условий ПС
                </UiFormCheckbox>
              </FormItem>
            </Col>
            <Col>
              <FormItem name="isBankCardAttached" valuePropName="checked">
                <UiFormCheckbox disabled={isFormDisabled}>
                  Флаг привязки банковской карты
                </UiFormCheckbox>
              </FormItem>
            </Col>
            <Col>
              <FormItem name="isPushAvailable" valuePropName="checked">
                <UiFormCheckbox disabled={isFormDisabled}>
                  Отправка PUSH уведомлений
                </UiFormCheckbox>
              </FormItem>
            </Col>
            <Col>
              <FormItem name="isDebug" valuePropName="checked">
                <UiFormCheckbox disabled={isFormDisabled}>
                  Режим отладки МП
                </UiFormCheckbox>
              </FormItem>
            </Col>
            <Col>
              <FormItem name="spendBonuses" valuePropName="checked">
                <UiFormCheckbox disabled={isFormDisabled}>
                  Списывать бонусы
                </UiFormCheckbox>
              </FormItem>
            </Col>
          </FormRow>

          {chargingClientType === '1' ? (
            <FormRow gutter={10}>
              <Col span={12}>
                <FormItem label="Идентификатор представителя" name="name">
                  <UiInput disabled={isFormDisabled} />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem
                  label="Баланс лимита зарядки"
                  name="chargingLimitBalance"
                  rules={[VALIDATION_RULE.NULL_OR_POSITIVE_NUMBER]}
                >
                  <UiInput disabled={isFormDisabled} />
                </FormItem>
              </Col>
            </FormRow>
          ) : null}
          {sessionToken ? (
            <SessionToken>sessionToken: {sessionToken}</SessionToken>
          ) : null}
          {formControls}
        </Form>
      </CardFormWrapper>
    </UserFormContainer>
  );
}
