import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { TableColumn, TableColumnType } from 'shared/model/table';
import { UiModal } from 'shared/ui';

import { VisibleColumnsForm } from '../form';
import { ModalSubTitle, ModalTitle, TitleContainer } from './styles';

// type Props<T, K> = {
//   columns: TableColumnType<K>[];
//   onConfirm: (columns: TableColumnType<K>[]) => void;
// };

// export const getSelectColumnsModal = <T, K extends keyof T>(settings: T) => {
//   return NiceModal.create((props: Props<T, K>) => {
//     const modal = useModal();

//     return (
//       <UiModal
//         title={
//           <TitleContainer>
//             <ModalTitle>Настройка вида таблицы</ModalTitle>
//             <ModalSubTitle>Выберите колонки для отображения</ModalSubTitle>
//           </TitleContainer>
//         }
//         visible={modal.visible}
//         onCancel={modal.hide}
//         afterClose={modal.remove}
//       >
//         <VisibleColumnsForm closeModal={modal.hide} {...props} />
//       </UiModal>
//     );
//   });
// };

type Props<T, K extends string> = {
  columns: TableColumn[];
  onConfirm: (columns: TableColumnType<K>[]) => Record<K, boolean>;
};

export const VisibleColumnsModal = NiceModal.create((props: Props) => {
  const modal = useModal();

  return (
    <UiModal
      title={
        <TitleContainer>
          <ModalTitle>Настройка вида таблицы</ModalTitle>
          <ModalSubTitle>Выберите колонки для отображения</ModalSubTitle>
        </TitleContainer>
      }
      visible={modal.visible}
      onCancel={modal.hide}
      afterClose={modal.remove}
    >
      <VisibleColumnsForm closeModal={modal.hide} {...props} />
    </UiModal>
  );
});
