import {
  AppSettings,
  Banks,
  Bonuses,
  CarModels,
  ChargePoints,
  Companies,
  ConnectorInstructions,
  Countries,
  Cpos,
  Discounts,
  Firmwares,
  Groups,
  Hubs,
  MobileApps,
  OcpiHubs,
  Owners,
  Persons,
  PushNotirications,
  Reports,
  Roles,
  Tariffs,
  Users,
} from '../images';

import { Section, SectionMenuItem } from '../model';

export const SECTION: Record<Section, SectionMenuItem> = {
  owners: {
    label: 'Владельцы ЭЗС',
    path: 'owners',
    icon: Owners,
    controller: 'Owner',
  },
  tariffs: {
    label: 'Тарифы',
    path: 'tariffs',
    icon: Tariffs,
    controller: 'ChargeTariff',
  },
  discounts: {
    label: 'Скидки',
    path: 'discounts',
    icon: Discounts,
    controller: 'Discount',
  },
  'charge-points': {
    label: 'Список ЭЗС',
    path: 'charge-points',
    icon: ChargePoints,
    controller: 'ChargePoint',
  },
  persons: {
    label: 'Клиенты',
    path: 'persons',
    icon: Persons,
    controller: 'Person',
  },
  reports: {
    label: 'Отчеты',
    path: 'reports',
    icon: Reports,
    controller: 'Report',
  },
  'push-notifications': {
    label: 'Пуш уведомления',
    path: 'push-notifications',
    icon: PushNotirications,
    controller: 'PushNotification',
  },
  bonuses: {
    label: 'Кешбэк и награды',
    path: 'bonuses',
    icon: Bonuses,
    controller: 'BonusService', //
  },
  users: {
    label: 'Пользователи',
    path: 'users',
    icon: Users,
    controller: 'Identity',
  },
  groups: {
    label: 'Группы',
    path: 'groups',
    icon: Groups,
    controller: 'Identity',
  },
  roles: {
    label: 'Роли',
    path: 'roles',
    icon: Roles,
    controller: 'Identity',
  },
  countries: {
    label: 'Страны',
    path: 'countries',
    icon: Countries,
    controller: 'Country',
  },
  banks: {
    label: 'Банки',
    path: 'banks',
    icon: Banks,
    controller: 'Bank',
  },
  'connector-instructions': {
    label: 'Инструкции коннекторов',
    path: 'connector-instructions',
    icon: ConnectorInstructions,
    controller: 'Connector',
  },
  'app-settings': {
    label: 'Настройки приложения',
    path: 'app-settings',
    icon: AppSettings,
    controller: 'Settings',
  },
  companies: {
    label: 'Контрагенты',
    path: 'companies',
    icon: Companies,
    controller: 'Person',
  },
  hubs: {
    label: 'Зарядные Хабы',
    path: 'hubs',
    icon: Hubs,
    controller: 'Hub',
  },
  firmwares: {
    label: 'Прошивки',
    path: 'firmwares',
    icon: Firmwares,
    controller: 'Firmware',
  },
  'ocpi-hubs': {
    label: 'OCPI Хабы',
    path: 'ocpi-hubs',
    icon: OcpiHubs,
    controller: 'OcpiHubs',
  },
  cpos: {
    label: 'Операторы ЭЗС',
    path: 'cpos',
    icon: Cpos,
    controller: 'Cpo',
  },
  'mobile-apps': {
    label: 'Мобильные приложения',
    path: 'mobile-apps',
    icon: MobileApps,
    controller: 'MobileApp',
  },
  'car-brands': {
    label: 'Марки EV',
    path: 'car-brands',
    icon: CarModels,
    controller: 'Car',
  },
};
