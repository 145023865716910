import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';

import {
  ConnectedConnectorForm,
  ConnectorFormData,
  prepareRequestData,
  useAddConnectorMutation,
} from 'entities/connector';

import { ADD_ERROR, ADD_SUCCESS } from '../../consts';

const initialValues: ConnectorFormData = {
  maxPower: '0',
  type: '',
  status: '',
  connectorId: '',
  liter: '',
  maxAmperage: '0',
  maxVoltage: '0',
  format: '',
  instructionId: '',
  manualBlock: false,
};

type Props = {
  chargePointId: string;
  chargePointName: string;
  closeModal: () => void;
};

export function AddConnector({
  chargePointId,
  chargePointName,
  closeModal,
}: Props) {
  const [trigger, { isLoading }] = useAddConnectorMutation();

  const handleSubmit = async (values: ConnectorFormData) => {
    const formData = prepareRequestData(values);

    const req = {
      ...formData,
      chargePointId,
      chargePointName,
    };

    try {
      const res = await trigger(req).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);

        return;
      }
      //

      openSuccessNotification(ADD_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : ADD_ERROR;

      openErrorNotification(errorText);
    } finally {
      closeModal();
    }
  };

  return (
    <ConnectedConnectorForm
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      loading={isLoading}
    />
  );
}
