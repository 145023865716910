import { useMemo } from 'react';

import { getServerErrorText } from 'shared/lib';
import { EmptyData, ErrorMessage } from 'shared/ui';

import { useGetCarBrandsQuery } from 'entities/car-brand';
import { useGetCarModelsQuery } from 'entities/car-model';

import { CarModelsTableInstance } from './table';

type Props = {
  carBrandId?: string;
};

export function ConnectedCarModelsTable({ carBrandId }: Props) {
  const {
    data: carModels,
    isFetching: isCarModelsFetching,
    error: carModelsError,
  } = useGetCarModelsQuery(carBrandId);

  const {
    data: carBrands,
    isFetching: isCarBrandsFetching,
    error: carBrandsError,
  } = useGetCarBrandsQuery();

  const tableData = useMemo(() => {
    if (!carModels) {
      return [];
    }

    return carModels.carModelList;
  }, [carModels]);

  const apiResponseError = [carModels, carBrands].find(
    (el) => el && el.statusCode !== 0
  );

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusDescription} />;
  }

  const error = [carModelsError, carBrandsError].find(
    (err) => err !== undefined
  );

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  return (
    <CarModelsTableInstance
      tableData={tableData}
      loading={isCarModelsFetching || isCarBrandsFetching}
      carBrands={carBrands?.carBrandList ?? []}
      rowsCount={carModels?.carModelList.length}
    />
  );
}
