import { Route } from 'react-router-dom';

import { ADD_PREFIX } from 'shared/consts/routes/common';
import { APP_SETTING, APP_SETTINGS } from 'shared/consts/routes/app-settings';
import { SectionLayout } from 'shared/ui/layout';

import { RequireAuth } from 'entities/authorization';
import { ConnectedAppSettingsMenu } from 'entities/settings';

import { AddSettingsPage, EditSettingsPage } from 'pages/settings';

export const AppSettingsRoutes = (
  <Route element={<RequireAuth controllerName="Settings" rightType="Read" />}>
    <Route
      path={APP_SETTINGS}
      element={<SectionLayout menu={<ConnectedAppSettingsMenu />} />}
    >
      <Route
        element={<RequireAuth controllerName="Settings" rightType="Write" />}
      >
        <Route path={ADD_PREFIX} element={<AddSettingsPage />} />
      </Route>
      <Route path={APP_SETTING} element={<EditSettingsPage />} />
    </Route>
  </Route>
);
