import { BaseInfoCard } from 'entities/charge-point';

import { BaseInfoCardActions } from 'features/charge-point/base-info-card-actions';

type Props = Omit<React.ComponentProps<typeof BaseInfoCard>, 'actionsSlot'>;

export function BaseInfoWidget(props: Props) {
  return (
    <BaseInfoCard
      actionsSlot={
        <BaseInfoCardActions
          chargePointId={props.chargePointId}
          chargePointName={props.name}
        />
      }
      {...props}
    />
  );
}
