import { useParams } from 'react-router-dom';

import {
  isErrorWithMessage,
  getServerErrorText,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';

import { ErrorMessage, EmptyData } from 'shared/ui';

import { Controller } from 'entities/authorization';
import { useGetBanksQuery } from 'entities/bank';
import { useGetCountriesQuery } from 'entities/country';
import {
  useGetMobileAppQuery,
  useSetMobileAppMutation,
} from 'entities/mobile-app';

import { getEditRightsError, getEditRightsSuccess } from '../../constants';
import { MobileAppRights } from '../MobileAppRights';

export function ConnectedMobileAppRights() {
  const { mobileAppId } = useParams() as { mobileAppId: string };

  const {
    isFetching: isMobileAppLoading,
    error: mobileAppError,
    data: mobileApp,
  } = useGetMobileAppQuery(mobileAppId);

  const {
    data: banks,
    isFetching: isBanksLoading,
    error: banksError,
  } = useGetBanksQuery();

  const {
    data: countries,
    isFetching: isCountriesLoading,
    error: countriesError,
  } = useGetCountriesQuery();

  const [trigger, { isLoading: isMutationLoading }] = useSetMobileAppMutation();

  const handleSubmit = async (values: Record<string, boolean>) => {
    const bankIds: number[] = [];
    const countryIds: number[] = [];

    Object.keys(values).forEach((value) => {
      const formItemNameArr = value.split('/');

      const controller = formItemNameArr[0] as Extract<
        Controller,
        'Bank' | 'Country'
      >;
      const id = formItemNameArr[1];

      if (values[value] === true && controller === 'Bank') {
        bankIds.push(Number(id));
      }

      if (values[value] === true && controller === 'Country') {
        countryIds.push(Number(id));
      }
    });

    const req = {
      bankIds,
      countryIds,
      id: Number(mobileAppId),
    };

    try {
      const res = await trigger(req).unwrap();

      const successText = getEditRightsSuccess(mobileAppId);

      openSuccessNotification(successText);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : getEditRightsError(mobileAppId);

      openErrorNotification(errorText);
    }
  };

  if (isBanksLoading || isCountriesLoading || isMobileAppLoading) {
    return <div>Loading...</div>;
  }

  const apiResponseError = [banks, countries, mobileApp].find(
    (el) => el && el.statusCode !== 0
  );

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusDescription} />;
  }

  const error = [banksError, countriesError, mobileAppError].find(
    (err) => err !== undefined
  );

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!banks || !countries || !mobileApp) {
    return <EmptyData />;
  }

  return (
    <MobileAppRights
      banks={banks.banks}
      countries={countries.countries}
      mobileApp={mobileApp.mobileApp}
      loading={isMutationLoading}
      handleSubmit={handleSubmit}
    />
  );
}
