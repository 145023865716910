import { useMemo } from 'react';

import { getServerErrorText } from 'shared/lib';
import { ErrorMessage, EmptyData } from 'shared/ui';

import {
  GroupType,
  useGetChargePointGroupsQuery,
  useGetPersonsGroupsQuery,
} from 'entities/group';

import { GroupsTableInstance } from './table';

type Props = {
  type: GroupType;
};

export function ConnectedGroupsTable({ type }: Props) {
  const { data, isFetching, error } =
    type === 1 ? useGetPersonsGroupsQuery() : useGetChargePointGroupsQuery();

  const tableData = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.groups;
  }, [data]);

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!isFetching && !data) {
    return <EmptyData />;
  }

  return (
    <GroupsTableInstance
      tableData={tableData}
      loading={isFetching}
      rowsCount={data?.groups.length}
    />
  );
}
