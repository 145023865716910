import { CarModelFormData } from '../model/form';

export const prepareRequestData = (values: CarModelFormData) => {
  const { carBrandId, name } = values;

  return {
    name,
    carBrandId: Number(carBrandId),
  };
};
