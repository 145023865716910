import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { ConfirmDialog, UiModal } from 'shared/ui';
import { openErrorNotification, openSuccessNotification } from 'shared/lib';

import {
  Group,
  GroupDto,
  GroupType,
  useCustomDeleteGroupMutation,
} from 'entities/group';

import { DELETE_ERROR, DELETE_SUCCESS } from '../consts';

type Props = {
  group: GroupDto;
  // id: string;
  // groupType: GroupType;
  // parentId: string;
  // childrenGroups: Group[];
  // name: string;
};

export const DeleteGroupModal = NiceModal.create(({ group }: Props) => {
  const modal = useModal();

  const [trigger, { isLoading }] = useCustomDeleteGroupMutation();

  const handleDelete = async () => {
    const { childrenGroups, id, groupType, parentId } = group;

    const req = { childrenGroups, id, groupType, parentId };

    try {
      const res = await trigger(req).unwrap();

      res.statusCode === 0
        ? openSuccessNotification(DELETE_SUCCESS)
        : openErrorNotification(res.statusDescription);
    } catch (e) {
      openErrorNotification(DELETE_ERROR);
    } finally {
      modal.hide();
    }
  };

  return (
    <UiModal
      title={`Удалить группу "${group.name}"?`}
      visible={modal.visible}
      onCancel={modal.hide}
      afterClose={modal.remove}
    >
      <ConfirmDialog
        onCancel={modal.hide}
        onConfirm={() => handleDelete()}
        loading={isLoading}
      />
    </UiModal>
  );
});
