import { LanguageRadioGroup } from './styles';

const LANGUAGE_OPTIONS = [
  { label: 'RU', value: 'ru' },
  { label: 'EN', value: 'en' },
];

export function ToggleLanguage() {
  return (
    <LanguageRadioGroup
      options={LANGUAGE_OPTIONS}
      optionType="button"
      value={LANGUAGE_OPTIONS[0].value}
    />
  );
}
