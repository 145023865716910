import {
  LoginFormInner,
  LoginFormHeader,
  LoginFormSubtitle,
  LoginFormTitle,
  LoginFormContainer,
  StyledLayout,
  LoginFormLogoContainer,
} from './styles';

import { LogoSitronicsElectroSmall } from '../../components/UI/LogoSitronicsElectro';

import {
  ConnectedLoginForm,
  LoginCarousel,
} from 'features/authorization/login';

export function LoginPage() {
  return (
    <StyledLayout>
      <LoginCarousel />
      <LoginFormContainer>
        <LoginFormInner>
          <LoginFormHeader>
            <LoginFormLogoContainer>
              <LogoSitronicsElectroSmall type="black" />
            </LoginFormLogoContainer>
            <LoginFormTitle>Вход Sitronics Electro</LoginFormTitle>
            <LoginFormSubtitle>Система управления ЭЗС</LoginFormSubtitle>
          </LoginFormHeader>
          <ConnectedLoginForm />
        </LoginFormInner>
      </LoginFormContainer>
    </StyledLayout>
  );
}
