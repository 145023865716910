import { baseApi } from 'shared/api';

import { getEndpointPath } from 'shared/lib';

import {
  ChargeTariffsBankIdDtoBaseResponse,
  ChargeTariffBankIdDtoBaseResponse,
  AddChargeTariffFormRequest,
  UpdateChargeTariffFormRequest,
} from './types';

import { apiSlice } from '../../../redux/services/apiSlice';
import { PLATFORM_SERVICE } from 'entities/authorization';

const KEY = 'ChargeTariff';

const getUrl = getEndpointPath(PLATFORM_SERVICE, KEY);

// TODO: замена на baseApi

export const tariffsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTariffs: builder.query<ChargeTariffsBankIdDtoBaseResponse, void>({
      query: () => getUrl('GetChargeTariffs'),
      providesTags: ['Tariffs'],
    }),
    getTariff: builder.query<ChargeTariffBankIdDtoBaseResponse, string>({
      query: (id) => ({
        url: getUrl(`GetChargeTariff`),
        params: { id },
      }),
      providesTags: (result, error, arg) => [{ type: 'Tariff', id: arg }],
    }),
    addTariff: builder.mutation<
      ChargeTariffBankIdDtoBaseResponse,
      AddChargeTariffFormRequest
    >({
      query: (tariff) => ({
        url: getUrl('AddChargeTariff'),
        method: 'POST',
        body: tariff,
      }),
      invalidatesTags: ['Tariffs'],
    }),
    updateTariff: builder.mutation<
      ChargeTariffBankIdDtoBaseResponse,
      UpdateChargeTariffFormRequest
    >({
      query: (tariff) => ({
        url: getUrl('UpdateChargeTariff'),
        method: 'PATCH',
        body: tariff,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Tariff', id: arg.id },
        'Tariffs',
      ],
    }),
    deleteTariff: builder.mutation<ResponseBase, string>({
      query: (id) => ({
        url: getUrl(`DeleteChargeTariff`),
        params: { id },
        method: 'DELETE',
      }),
      invalidatesTags: ['Tariffs'],
    }),
  }),
});

export const {
  useGetTariffsQuery,
  useGetTariffQuery,
  useAddTariffMutation,
  useUpdateTariffMutation,
  useDeleteTariffMutation,
} = tariffsApi;
