import { CONNECTOR_INSTRUCTION_ROUTES } from 'shared/consts/routes/connector-instruction';
import { MenuAddButton } from 'shared/ui/section-menu';

export function AddButton() {
  return (
    <MenuAddButton
      path={CONNECTOR_INSTRUCTION_ROUTES.ADD_CONNECTOR_INSTRUCTION}
      requiredRights={[
        {
          controller: 'Instruction',
          right: 'Write',
        },
      ]}
    />
  );
}
