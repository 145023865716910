import { useParams } from 'react-router-dom';

import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
  getServerErrorText,
} from 'shared/lib';

import { EmptyData, ErrorMessage } from 'shared/ui';

import {
  ConnectedDiscountForm,
  DiscountDto,
  DiscountFormData,
  prepareRequestData,
  useGetDiscountQuery,
  useCustomUpdateDiscountMutation,
} from 'entities/discount';

import { EDIT_ERROR, EDIT_SUCCESS } from '../consts';

const getInitialValues = (discount: DiscountDto): DiscountFormData => {
  const { id, bannedByOwnersIds, ...rest } = discount;

  const owners = bannedByOwnersIds.map((id) => String(id));

  return {
    ...rest,
    owners,
  };
};

export function EditDiscount() {
  const [trigger, { isLoading: isMutationLoading }] =
    useCustomUpdateDiscountMutation();

  const { discountId } = useParams() as { discountId: string };

  const { isFetching, error, data } = useGetDiscountQuery(discountId);

  const handleSubmit = async (values: DiscountFormData) => {
    const req = prepareRequestData(values, discountId);

    try {
      const res = await trigger(req).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);
        return;
      }
      //

      openSuccessNotification(EDIT_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : EDIT_ERROR;

      openErrorNotification(errorText);
    }
  };

  if (isFetching) {
    return <div>Loading...</div>;
  }

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!data?.discount) {
    return <EmptyData />;
  }

  const initialValues = getInitialValues(data.discount);

  return (
    <ConnectedDiscountForm
      handleSubmit={handleSubmit}
      loading={isMutationLoading}
      initialValues={initialValues}
      title={data.discount.name}
      disabled
    />
  );
}
