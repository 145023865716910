import { COLOR } from 'shared/consts';
import styled from 'styled-components';

export const StyledTransactionLogHeader = styled.div`
  display: flex;
  gap: 80px;
  padding: 20px;
  align-items: center;
`;

export const InfoBlock = styled.div`
  color: rgba(0, 0, 0, 0.5);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
`;

export const InfoValue = styled.span`
  color: ${COLOR.black};
`;
