export function DiscountsIcon() {
  return (
    <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="2.83333"
        cy="2.83333"
        r="2.83333"
        transform="matrix(1 0 0 -1 15.3359 21)"
        stroke="black"
        strokeWidth="1.5"
      />
      <circle
        cx="2.83333"
        cy="2.83333"
        r="2.83333"
        transform="matrix(1 0 0 -1 4 9.66666)"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        d="M21 4L4 21"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
