import styled from 'styled-components';

import { COLOR, DEFAULT_BORDER } from 'shared/consts';

export const TableWrapper = styled.div``;

export const TableContainer = styled.div`
  overflow: auto;
`;

export const StyledTable = styled.table`
  min-width: 100%;
  width: max-content;
  border-spacing: 0;
  background-color: ${COLOR.white};
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR.black};

  &,
  th,
  td {
    border: 1px solid ${COLOR.headerBorder};
    border-collapse: collapse;
  }

  & thead {
    // position: sticky;
    top: 0;
    color: ${COLOR.darkGrey};
    border-bottom: ${DEFAULT_BORDER};
  }

  & thead tr {
    // display: block;
  }

  & th {
    background-color: ${COLOR.whiteSmoke};
    text-align: center;
    vertical-align: middle;

    padding: 17px 19px;

    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: ${COLOR.black};
  }

  & td {
    // width: max-content;
    padding: 7px 19px;
    background-color: ${COLOR.reportTd};
    text-align: center;
  }

  & tfoot td {
    background-color: ${COLOR.whiteSmoke};
    text-align: center;
    vertical-align: middle;

    padding: 17px 19px;

    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: ${COLOR.black};
  }
`;

export const HeaderCellContainer = styled.div``;
