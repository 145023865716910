import styled from 'styled-components';
import { Menu } from 'antd';

import { COLOR } from 'shared/consts';

export const StyledBasicMenu = styled(Menu)`
  /* width: 223px; */
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1;
  background-color: ${COLOR.white};
  border-right: none;

  // Добавлено для с Y-скроллов в меню
  & .ant-menu-submenu-title {
    width: 100% !important;
  }

  & .ant-menu-item-selected,
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent;
  }

  & .ant-menu-item-selected::after {
    display: none;
  }

  & .link.ant-menu-item {
    background-color: ${COLOR.white};

    & .link.ant-menu-item :hover {
      background-color: ${COLOR.whiteSmoke};
    }
  }

  & .active-link {
    background-color: ${COLOR.lightGreenMenu};

    & .link.ant-menu-item {
      background-color: transparent;

      & .link.ant-menu-item :hover {
        background-color: ${COLOR.whiteSmoke};
      }
    }
  }

  & .link.ant-menu-submenu-title,
  .link.ant-menu-item.ant-menu-item-only-child,
  .ant-menu-submenu-title {
    margin: 0;
  }

  & .ant-menu-submenu-open img {
    transform: rotate(90deg) translate(-7px, 0px);
  }

  & .ant-menu-title-content {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: ${COLOR.title};
  }

  & .ant-menu-sub.ant-menu-inline {
    background-color: ${COLOR.white};
  }

  & .ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child::after {
    display: none;
  }

  & .ant-menu-inline .ant-menu-item,
  .ant-menu-inline .ant-menu-submenu-title {
    width: 100%;
  }

  & .ant-menu-sub li.ant-menu-item {
    margin: 0;
  }
`;

export const StyledIcon = styled.img`
  width: 17px;
  height: 17px;
`;
