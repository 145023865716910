import styled from 'styled-components';

import { COLOR } from 'shared/consts';

export const MapContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

export const MapTitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${COLOR.black};
  margin-left: 10px;
  margin-bottom: 15px;
  flex-grow: 0;
`;
