import styled from 'styled-components';

import { COLOR } from 'shared/consts';

export const TitleContainer = styled.div``;

export const ModalTitle = styled.p`
  margin-bottom: 4px;
`;

export const ModalSubTitle = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR.darkGrey};
`;
