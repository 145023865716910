import { Route } from 'react-router-dom';

import { MainContentLayout, SectionLayout } from 'shared/ui/layout';
import { ADD_PREFIX } from 'shared/consts/routes/common';
import {
  PUSH_NOTIFICATION,
  PUSH_NOTIFICATIONS,
} from 'shared/consts/routes/push-notification';

import { RequireAuth } from 'entities/authorization';

import { ConnectedPushNotificationsMenu } from 'features/push-notification/menu';
import { AddPushNotification } from 'features/push-notification/add';
import { EditPushNotification } from 'features/push-notification/edit';

export const PushNotificationRoutes = (
  <Route
    element={<RequireAuth controllerName="PushNotification" rightType="Read" />}
  >
    <Route
      path={PUSH_NOTIFICATIONS}
      element={<SectionLayout menu={<ConnectedPushNotificationsMenu />} />}
    >
      <Route
        element={
          <RequireAuth controllerName="PushNotification" rightType="Write" />
        }
      >
        <Route
          path={ADD_PREFIX}
          element={<MainContentLayout element={<AddPushNotification />} />}
        />
      </Route>
      <Route
        path={PUSH_NOTIFICATION}
        element={<MainContentLayout element={<EditPushNotification />} />}
      />
    </Route>
  </Route>
);
