import { Checkbox, Form } from 'antd';
import styled from 'styled-components';

import { COLOR } from 'shared/consts';

const { Item } = Form;

interface CheckboxContainerProps {
  isRoleRight: boolean;
}

export const RightCheckboxContainer = styled.span<CheckboxContainerProps>`
  display: inline-block;
  padding: 4px;
  border: ${(props) =>
    props.isRoleRight ? `3px solid ${COLOR.greenMenu}` : 'none'};
  border-radius: 4px;
`;

export const AdminInfoPanel = styled.div`
  padding: 10px;
  background-color: ${COLOR.white};
  display: inline-block;
  margin-bottom: 15px;
`;

export const RightsFormItem = styled(Item)`
  margin-bottom: 0;

  & .ant-form-item-control-input {
    min-height: unset;
  }
`;
