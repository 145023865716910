import { getEndpointPath } from 'shared/lib';

import { PLATFORM_SERVICE } from 'entities/authorization';

import { apiSlice } from '../../../redux/services/apiSlice';
import { PacketsBaseResponse } from './types';

export interface GetPacketsParams {
  page: string;
  limit: string;
  cpName: string;
  type?: string;
  dateFrom?: string;
  dateTo?: string;
  search?: string;
  searchText?: string;
  ordering?: string;
}

const KEY = 'PrivateMethods';

const getUrl = getEndpointPath(PLATFORM_SERVICE, KEY);

export const api = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPackets: builder.query<PacketsBaseResponse, GetPacketsParams>({
      query: (params) => ({
        url: getUrl(`GetPackets`),
        params,
      }),

      providesTags: (result, error, arg) => [{ type: 'Packets' }],
    }),
  }),
});

export const { useGetPacketsQuery } = api;
