import { useParams } from 'react-router-dom';

import { usePersonsGroupFetchingContext } from 'entities/person/persons-group-layout';

import { ConnectedReservesTable } from '../../../widgets/session/reserve-sessions-table/ui/connected-table';

export function PersonsGroupReservesPage() {
  const { groupId } = useParams() as {
    groupId: string;
  };

  const { handleSetFetchingTab } = usePersonsGroupFetchingContext();

  return (
    <ConnectedReservesTable
      handleSetFetchingTab={handleSetFetchingTab}
      groupId={groupId}
    />
  );
}
