import { baseApi } from 'shared/api';

import { getEndpointPath } from 'shared/lib';

import {
  AddHubRequest,
  HubBaseResponse,
  HubsBaseResponse,
  UpdateHubRequest,
} from './types';

import { apiSlice } from '../../../redux/services/apiSlice';
import { PLATFORM_SERVICE } from 'entities/authorization';

const KEY = 'Hub';

const getUrl = getEndpointPath(PLATFORM_SERVICE, KEY);

// TODO: замена на baseApi

export const hubsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getHubs: builder.query<HubsBaseResponse, void>({
      query: () => getUrl('GetHubs'),
      providesTags: ['Hubs'],
    }),
    getHub: builder.query<HubBaseResponse, string>({
      query: (id) => ({
        url: getUrl(`GetHub`),
        params: { id },
      }),
      providesTags: (result, error, arg) => [{ type: 'Hub', id: arg }],
    }),
    addHub: builder.mutation<HubBaseResponse, AddHubRequest>({
      query: (hub) => ({
        url: getUrl('AddHub'),
        method: 'POST',
        body: hub,
      }),
      invalidatesTags: ['Hubs'],
    }),
    updateHub: builder.mutation<HubBaseResponse, UpdateHubRequest>({
      query: (hub) => ({
        url: getUrl('UpdateHub'),
        method: 'PATCH',
        body: hub,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Hub', id: arg.id },
        'Hubs',
      ],
    }),
    deleteHub: builder.mutation<ResponseBase, string>({
      query: (id) => ({
        url: getUrl(`DeleteHub`),
        params: { id },
        method: 'DELETE',
      }),
      invalidatesTags: ['Hubs'],
    }),
  }),
});

export const {
  useGetHubsQuery,
  useGetHubQuery,
  useAddHubMutation,
  useUpdateHubMutation,
  useDeleteHubMutation,
} = hubsApi;
