export function ParkIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 6C2 3.79086 3.79086 2 6 2H18C20.2091 2 22 3.79086 22 6V18C22 20.2091 20.2091 22 18 22H6C3.79086 22 2 20.2091 2 18V6Z"
        stroke="#28303F"
        stroke-width="1.5"
      />
      <path
        d="M13.5381 18.3728L13.5379 18.3731C13.3375 18.6181 13.0415 18.75 12.7385 18.75H11.2722C10.9693 18.75 10.6731 18.6182 10.4725 18.3728L13.5381 18.3728ZM13.5381 18.3728C13.7175 18.1533 13.7897 17.8835 13.7687 17.6241M13.5381 18.3728L13.7687 17.6241M15.5175 9.60086L15.9672 9.20316C15.9672 9.20319 15.9673 9.20321 15.9673 9.20323C15.9722 9.2088 15.9771 9.21439 15.982 9.22M15.5175 9.60086L15.4054 9.69999M15.5175 9.60086L15.1707 9.4705M15.5175 9.60086L15.1707 9.4705M15.982 9.22C15.9713 9.40001 15.9334 9.57303 15.8727 9.73437L15.5175 9.60086L15.4054 9.69999M15.982 9.22C16.4803 9.79176 16.75 10.5276 16.75 11.2905C16.75 12.9599 15.4485 14.38 13.7716 14.38C13.7707 14.38 13.7697 14.38 13.7687 14.38M15.982 9.22C15.9841 9.18522 15.9851 9.15018 15.9851 9.11491C15.9851 8.38647 15.5309 7.72688 14.8622 7.48797C14.5089 6.21588 13.3818 5.25 12 5.25C11.0952 5.25 10.2953 5.66889 9.75509 6.31166C8.49218 6.36886 7.5386 7.45442 7.5386 8.70898C7.5386 9.03386 7.60161 9.34538 7.71638 9.6301C7.41185 10.1249 7.25 10.6995 7.25 11.2905C7.25 12.96 8.55149 14.38 10.2284 14.38C10.2324 14.38 10.2369 14.38 10.2418 14.3801V14.9825V17.6243C10.2209 17.8836 10.2931 18.1533 10.4724 18.3727L13.7687 17.6241M15.4054 9.69999C15.332 9.61701 15.2534 9.54069 15.1707 9.4705M15.4054 9.69999C15.7888 10.1336 16 10.6985 16 11.2905C16 12.5805 15.0003 13.63 13.7716 13.63C13.7707 13.63 13.7697 13.63 13.7687 13.63V14.38M15.1707 9.4705L13.0187 17.6631V14.9825V14.3615C13.131 14.369 13.2775 14.3723 13.3778 14.3745C13.5412 14.378 13.708 14.3799 13.7687 14.38M13.7687 14.38V14.9825V17.6241"
        stroke="#28303F"
        stroke-width="1.5"
      />
    </svg>
  );
}
