import styled from 'styled-components';

export const UserHeaderContainer = styled.div`
  margin-bottom: 15px;
`;

export const UserHeaderTitle = styled.h3`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: '#3A3838';
  margin-bottom: 3px;
`;

export const UserHeaderContent = styled.div`
  display: flex;
  align-items: center;
  color: rgba(58, 56, 56, 0.5);
`;

export const UserPhoneNumber = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  padding-right: 4px;
  border-right: 0.5px solid rgba(58, 56, 56, 0.7);
`;

export const UserProfileLinkWrapper = styled.div`
  & a {
    margin-left: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-decoration-line: underline;
    color: rgba(58, 56, 56, 0.7);
  }
`;
