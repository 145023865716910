import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import useMeasure from 'react-use-measure';

import { ErrorMessage, EmptyData } from 'shared/ui';
import { getServerErrorText, useTablePageSize } from 'shared/lib';

import { useGetBanksQuery } from 'entities/bank';
import { useGetAllQuery, useGetTransactionsQuery } from 'entities/balance/api';
import { useGetPersonQuery } from 'entities/person';

import { TransactionLogHeader } from './header';
import { TableInstance } from './table';

import { useGetChargePointsQuery } from '../../../../redux/services/charge-points';

type Props = {
  userLogin: string;
};

export function ConnectedTransactionLogTable({ userLogin }: Props) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [tableHeadRef, tableHeadBounds] = useMeasure();

  const [tablePageSize, setTablePageSize] = useTablePageSize(tableHeadBounds);

  const dateFrom = searchParams.get('dateFrom');
  const dateTo = searchParams.get('dateTo');
  const page = searchParams.get('page');
  const limit = searchParams.get('limit');
  const bankId = searchParams.get('bankId');

  const {
    data: personData,
    isFetching: isPersonLoading,
    error: personError,
  } = useGetPersonQuery(userLogin);

  const {
    data: banks,
    isFetching: isBanksLoading,
    error: banksError,
  } = useGetBanksQuery();

  const bankIdParam = useMemo(() => {
    if (bankId) {
      return bankId;
    }

    if (!banks || !personData) {
      return null;
    }

    return banks.banks.find(
      (bank) => bank.country.id === personData?.person.country.id
    )?.bankId;
  }, [banks, personData, bankId]);

  const {
    data: transactions,
    isFetching: isTransactionsFetching,
    error: transactionsError,
    currentData,
  } = useGetTransactionsQuery(
    {
      dateFrom,
      dateTo,
      page: page ?? 1,
      limit,
      personId: personData?.person.clientNodeId,
      bankId: bankIdParam,
    },
    {
      skip: !Boolean(personData?.person.clientNodeId) || !Boolean(bankIdParam),
    }
  );

  const {
    data: chargePoints,
    isFetching: isChargePointsFetching,
    error: chargePointsError,
  } = useGetChargePointsQuery({
    limit: -1,
    page: 0,
  });

  const {
    data: balances,
    isFetching: isBalanceFetching,
    error: balanceError,
  } = useGetAllQuery(personData?.person.clientNodeId as string, {
    skip: !Boolean(personData?.person.clientNodeId),
  });

  // какой банк по умолчанию? Мб нет смысла вообще?
  // const {} = useGetBalanceByCurrencyQuery({});

  const tableData = useMemo(() => {
    if (!transactions) {
      return [];
    }

    return transactions.items;
  }, [transactions]);

  if (
    isBanksLoading ||
    isTransactionsFetching ||
    isChargePointsFetching ||
    isPersonLoading ||
    isBalanceFetching
  ) {
    return <div>Loading...</div>;
  }

  const apiResponseError = [personData, banks, chargePoints].find(
    (el) => el && el.statusCode !== 0
  );

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusDescription} />;
  }

  const error = [
    personError,
    banksError,
    chargePointsError,
    transactionsError,
    balanceError,
  ].find((err) => err !== undefined);

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!personData || !banks || !transactions || !chargePoints) {
    return <EmptyData />;
  }

  return (
    <>
      <TransactionLogHeader
        banks={banks?.banks || []}
        bankId={String(bankIdParam)}
        balances={balances?.items}
        personLogin={userLogin}
      />
      <TableInstance
        banks={banks?.banks || []}
        chargePoints={chargePoints?.chargePoints || []}
        tableData={tableData}
        count={transactions?.count}
        tableHeadRef={tableHeadRef}
        tablePageSize={tablePageSize}
        loading={isTransactionsFetching && !currentData}
      />
    </>
  );
}
