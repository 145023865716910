import styled from 'styled-components';
import { Tabs } from 'antd';

import { COLOR } from 'shared/consts';

export const StyledTabs = styled(Tabs)`
  width: 100%;

  & .ant-tabs-nav {
    background-color: ${COLOR.white};
  }

  & .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    text-shadow: unset;
  }

  & .ant-tabs-tab {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const ChargePointFormContainer = styled.div`
  min-width: 650px;
  max-width: 1400px;
`;
