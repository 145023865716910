import { UiDivider } from 'shared/ui/ui-kit';

import { CarDto } from 'entities/car';
import { PersonDto } from 'entities/person';

import { CarCard } from '../card';

import {
  CarListHeader,
  CarListTitle,
  CarsListContainer,
  NoCarsText,
  StyledPersonCarList,
} from './styles';

type Props = {
  cars: PersonDto['carList'];
  personLogin: string;
  addCarSlot: React.ReactNode;
  renderActions: (car: CarDto) => React.ReactNode;
};

export function CarsList({
  cars,
  personLogin,
  addCarSlot,
  renderActions,
}: Props) {
  let content: JSX.Element;

  if (cars.length === 0) {
    content = <NoCarsText>Электромобиль не добавлен</NoCarsText>;
  } else {
    content = (
      <CarsListContainer>
        {cars.map((car) => (
          <CarCard
            key={car.id}
            car={car}
            personLogin={personLogin}
            actionsSlot={renderActions(car)}
          />
        ))}
      </CarsListContainer>
    );
  }

  return (
    <StyledPersonCarList>
      <CarListHeader>
        <CarListTitle>{`Электромобиль(-и)`}</CarListTitle>
        {addCarSlot}
      </CarListHeader>
      {content}
      <UiDivider />
    </StyledPersonCarList>
  );
}
