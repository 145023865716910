import { useSelector } from 'react-redux';
import NiceModal from '@ebay/nice-modal-react';

import { UiPopover } from 'shared/ui';

import {
  checkControllerRight,
  selectTokenClaims,
  selectTokenRoles,
} from 'entities/authorization';
import {
  ConnectorManualCommandType,
  CONNECTOR_MANUAL_COMMAND_TYPE,
} from 'entities/manual-command';
import { ChargePointConnectorDto } from 'entities/connector';

import { ManualCommandModal } from 'features/manual-command';
import { EditConnectorModal } from 'features/connector/edit-connector';
import { DeleteConnectorModal } from 'features/connector/delete-connector';

import { PopoverButtonsContainer } from '../../../../common-styles';
import { EditConnectorIcon } from './styles';

import EditIcon from '../images/edit.svg';

type Props = {
  chargePointId: string;
  transactionId: string;
  chargePointName: string;
  connector: ChargePointConnectorDto;
};

export function ConnectorCardActions({
  chargePointId,
  transactionId,
  chargePointName,
  connector,
}: Props) {
  const roles = useSelector(selectTokenRoles);
  const claims = useSelector(selectTokenClaims);

  const hasWriteRight = checkControllerRight(
    'Connector',
    'Write',
    roles,
    claims
  );

  const hasInstructionReadRight = checkControllerRight(
    'Instruction',
    'Read',
    roles,
    claims
  );

  const hasExecuteRight = checkControllerRight(
    'PrivateMethods',
    'Execute',
    roles,
    claims
  );

  const showManualCommandModal = (
    commandType: ConnectorManualCommandType,
    chargePointName: string,
    transactionId: string,
    connectorId: string
  ) => {
    NiceModal.show(ManualCommandModal, {
      commandType,
      chargePointName,
      transactionId,
      connectorId,
    });
  };

  const showEditConnectorModal = () => {
    NiceModal.show(EditConnectorModal, {
      chargePointId,
      chargePointName,
      connector,
    });
  };

  const showDeleteConnectorModal = () => {
    NiceModal.show(DeleteConnectorModal, {
      chargePointName,
      typeName: connector.typeName,
      id: String(connector.id),
    });
  };

  const manualCommands = (
    Object.keys(CONNECTOR_MANUAL_COMMAND_TYPE) as unknown as Array<
      keyof typeof CONNECTOR_MANUAL_COMMAND_TYPE
    >
  ).map((command) => {
    return (
      <button
        key={command}
        onClick={() => {
          showManualCommandModal(
            command,
            chargePointName,
            transactionId,
            String(connector.connectorId)
          );
        }}
      >
        {CONNECTOR_MANUAL_COMMAND_TYPE[command]}
      </button>
    );
  });

  const connectorMutations = (
    <>
      <button onClick={showEditConnectorModal}>Редактировать</button>
      <button onClick={showDeleteConnectorModal}>Удалить</button>
    </>
  );

  const content = (
    <>
      {hasExecuteRight ? (
        <PopoverButtonsContainer>{manualCommands}</PopoverButtonsContainer>
      ) : null}
      {hasWriteRight && hasInstructionReadRight ? (
        <PopoverButtonsContainer>{connectorMutations}</PopoverButtonsContainer>
      ) : null}
    </>
  );

  if (!hasExecuteRight && !hasWriteRight) {
    return null;
  }

  return (
    <UiPopover content={content}>
      <EditConnectorIcon src={EditIcon} />
    </UiPopover>
  );
}
