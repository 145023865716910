import { getEndpointPath } from 'shared/lib';

import { PLATFORM_SERVICE } from 'entities/authorization';
import {
  AddChargePointFormRequest,
  UpdateChargePointFormRequest,
} from 'entities/charge-point';
import {
  ChargePointBaseResponse,
  GetChargePointsBaseResponse,
} from 'entities/charge-point/model/charge-point-api';

import { apiSlice } from './apiSlice';

interface GetChargePointsParams {
  dateFrom?: string;
  dateTo?: string;
  ordering?: string;
  type?: string;
  ownerTypes?: string;
  owners?: string;
  statusType?: string;
  limit: number;
  page?: number;
  cpName?: string;
  address?: string;
  groupId?: string;
  withoutTestCp?: boolean;
  cpoId?: string;
  search?: string;
  searchText?: string;
  mobId?: number;
  blockedCp?: boolean;
  hubId?: number;
}

const KEY = 'ChargePoint';

const getUrl = getEndpointPath(PLATFORM_SERVICE, KEY);

export const chargePointsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getChargePoints: builder.query<
      GetChargePointsBaseResponse,
      GetChargePointsParams
    >({
      query: ({
        page,
        limit,
        type,
        ordering,
        groupId,
        address,
        cpName,
        cpoId,
        search,
        searchText,
        ownerTypes,
        owners,
        statusType,
        withoutTestCp,
        blockedCp,
        hubId,
      }) =>
        getUrl(
          `GetChargePoints?groupId=${groupId ? groupId : 0}&${
            limit ? `limit=${limit}&` : ''
          }${type ? `type=${type}&` : ''}${cpName ? `cpName=${cpName}&` : ''}${
            address ? `address=${address}&` : ''
          }${ordering ? `ordering=${ordering}&` : ''}${
            page ? `page=${page}&` : ''
          }${search ? `search=${search}&` : ''}${
            searchText ? `searchText=${searchText}&` : ''
          }${ownerTypes ? `ownerTypes=${ownerTypes}&` : ''}${
            statusType ? `statusType=${statusType}&` : ''
          }${owners ? `owners=${owners}&` : ''}${`withoutTestCp=${Boolean(
            withoutTestCp
          )}&`}${`cpoId=-1&`}${blockedCp ? `blockedCp=${blockedCp}&` : ''}${
            hubId ? `hubId=${hubId}&` : ''
          }`
        ),
      // providesTags: (result, error, arg) => [
      //   { type: 'ChargePoints', id: arg.groupId },
      // ],
      providesTags: ['ChargePoints'],
    }),
    getChargePoint: builder.query<ChargePointBaseResponse, string>({
      query: (chargePointName) => ({
        url: getUrl(`GetChargePoint`),
        params: { chargePointName },
      }),
      providesTags: (result, error, arg) => [{ type: 'ChargePoint', id: arg }],
    }),
    addChargePoint: builder.mutation<
      ChargePointBaseResponse,
      AddChargePointFormRequest
    >({
      query: (chargePoint) => ({
        url: getUrl(`AddChargePoint`),
        method: 'POST',
        body: chargePoint,
      }),
      invalidatesTags: ['ChargePoints', 'ChargePointGroups'],
    }),
    updateChargePoint: builder.mutation<
      ChargePointBaseResponse,
      UpdateChargePointFormRequest
    >({
      query: (chargePoint) => ({
        url: getUrl(`UpdateChargePoint`),
        method: 'PUT',
        body: chargePoint,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'ChargePoint', id: arg.name },
        'ChargePoints',
        'ChargePointGroups', // TODO: optimize
      ],
    }),
    deleteChargePoint: builder.mutation<ResponseBase, string>({
      query: (id) => ({
        url: getUrl(`DeleteChargePoint?id=${id}`),
        method: 'DELETE',
      }),
      invalidatesTags: ['ChargePoints', 'ChargePointGroups'],
    }),
  }),
});

export const {
  useGetChargePointsQuery,
  useGetChargePointQuery,
  useLazyGetChargePointsQuery,
  useUpdateChargePointMutation,
  useAddChargePointMutation,
  useDeleteChargePointMutation,
} = chargePointsApi;
