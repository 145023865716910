import { NavigationTab } from 'shared/model';

import { AddUsersGroupButtonLink } from './buttons';

export const TABS: Array<NavigationTab> = [
  {
    key: 'groups',
    path: '',
    label: 'Управление группами',
    buttons: [
      {
        element: <AddUsersGroupButtonLink />,
        controller: 'Identity',
        right: 'Write',
      },
    ],
  },
];
