import { CAR_BRAND_ROUTES } from 'shared/consts/routes/car-brands';
import { MenuAddButton } from 'shared/ui/section-menu';

export function AddButton() {
  return (
    <MenuAddButton
      path={CAR_BRAND_ROUTES.ADD_CAR_BRAND}
      requiredRights={[
        {
          controller: 'Car', // раньше был Bank ?
          right: 'Write',
        },
      ]}
    />
  );
}
