import { getServerErrorText } from 'shared/lib';
import { ErrorMessage, EmptyData } from 'shared/ui';

import { useGetCarBrandsQuery } from 'entities/car-brand';
import { useGetCarModelsQuery } from 'entities/car-model';

import { CarForm } from '../form';

type Props = Omit<
  React.ComponentProps<typeof CarForm>,
  'carModels' | 'carBrands'
>;

export function ConnectedCarForm(props: Props) {
  const {
    data: carModels,
    isFetching: isCarModelsFetching,
    error: carModelsError,
  } = useGetCarModelsQuery(); // TODO

  const {
    data: carBrands,
    isFetching: isCarBrandsFetching,
    error: carBrandsError,
  } = useGetCarBrandsQuery();

  if (isCarBrandsFetching || isCarModelsFetching) {
    return <div>Loading...</div>;
  }

  const apiResponseError = [carModels, carBrands].find(
    (el) => el && el.statusCode !== 0
  );

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusDescription} />;
  }

  const error = [carBrandsError, carModelsError].find(
    (err) => err !== undefined
  );

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!carBrands || !carModels) {
    return <EmptyData />;
  }

  return (
    <CarForm
      {...props}
      carModels={carModels.carModelList}
      carBrands={carBrands.carBrandList}
    />
  );
}
