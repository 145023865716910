import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';

import { useGetDiscountsQuery } from 'entities/discount';
import { useGetPersonsGroupsQuery } from 'entities/group/api/api';

import { PersonsGroupForm } from '../form';

type Props = Omit<
  React.ComponentProps<typeof PersonsGroupForm>,
  'groups' | 'discounts'
>;

export function ConnectedPersonsGroupForm({ ...props }: Props) {
  const {
    data: groups,
    isFetching: isGroupsFetching,
    error: groupsError,
  } = useGetPersonsGroupsQuery();

  const {
    data: discounts,
    isFetching: isDiscountsFetching,
    error: discountsError,
  } = useGetDiscountsQuery();

  if (isGroupsFetching || isDiscountsFetching) {
    return <div>Loading...</div>;
  }

  const apiResponseError = [groups, discounts].find(
    (el) => el && el.statusCode !== 0
  );

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusDescription} />;
  }

  const error = [groupsError, discountsError].find((err) => err !== undefined);

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!groups || !discounts) {
    return <EmptyData />;
  }

  return (
    <PersonsGroupForm
      {...props}
      groups={groups.groups}
      discounts={discounts.discounts}
    />
  );
}
