import { useNavigate } from 'react-router-dom';
import { CHARGE_POINT_ROUTES } from 'shared/consts/routes/charge-point';

import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';

import { useAddChargePointMutation } from '../../../../redux/services/charge-points';
import { ConnectedChargePointForm } from '../../components/ConnectedChargePointForm';
import {
  ADD_ERROR,
  ADD_SUCCESS,
  DEFAULT_CHARGE_POINT_PROTOCOL,
  DEFAULT_CHARGE_POINT_STATUS,
} from '../../constants';
import { getValidatedFormData } from '../../helpers';
import { ChargePointFormData } from '../../types';

const initialValues: ChargePointFormData = {
  id: '',
  techInfo: {
    name: '',
    ownerId: '',
    idGroup: '',
    city: '',
    address: '',
    description: '',
    manufacturer: '',
    maxPower: '0',
    type: '',
    status: String(DEFAULT_CHARGE_POINT_STATUS),
    multiconnectorSupport: true,
    timeLimit: false,
    chargePointProtocol: String(DEFAULT_CHARGE_POINT_PROTOCOL),
    ipAddress: '',
    commentary: '',
    simNumber: '',
  },
  advancedProperty: {
    cafe: false,
    closeAccess: false,
    hotel: false,
    hour24: false,
    park: false,
    parking: false,
    shop: false,
    shoppingMall: false,
    wc: false,
    wifi: false,
  },
  cardSettings: {
    latitude: '',
    longitude: '',
    mapStatus: false,
    isTestCp: false,
    hubId: '',
    passUrl: '',
    videoTranslationUrl: '',
    userLocationCheckDistance: '0',
    workingTime: '',
    publicDescription: '',
    warningText: '',
  },
  ocppConfig: {
    ocppConfig: '',
  },
};

export function AddChargePoint() {
  const [trigger, { isLoading }] = useAddChargePointMutation();

  const navigate = useNavigate();

  const handleSubmit = async (values: ChargePointFormData) => {
    const { id, ...req } = getValidatedFormData(values);

    try {
      const res = await trigger(req).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);

        return;
      }
      //

      openSuccessNotification(ADD_SUCCESS);

      navigate(CHARGE_POINT_ROUTES.CHARGE_POINTS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : ADD_ERROR;

      openErrorNotification(errorText);
    }
  };

  return (
    <ConnectedChargePointForm
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      loading={isLoading}
    />
  );
}
