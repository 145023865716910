import styled from 'styled-components';

import { COLOR, DEFAULT_BORDER } from 'shared/consts';

export const CompanyLogoContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLOR.white};
  border-right: ${DEFAULT_BORDER};
  border-bottom: ${DEFAULT_BORDER};
  border-top: none;
`;
