import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import useMeasure from 'react-use-measure';

import { ErrorMessage } from 'shared/ui';
import { getServerErrorText, useTablePageSize } from 'shared/lib';

import { useGetStatusHistoryQuery } from '../../../../redux/services/private-methods';

import { ChargePointStatusesTableInstance } from './table';

type Props = {
  chargePointName: string;
};

export function ConnectedChargePointStatuses({ chargePointName }: Props) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [tableHeadRef, tableHeadBounds] = useMeasure();

  const [tablePageSize, setTablePageSize] = useTablePageSize(tableHeadBounds);

  const page = searchParams.get('page');
  const limit = searchParams.get('limit');
  const type = searchParams.get('type');
  const dateFrom = searchParams.get('dateFrom');
  const dateTo = searchParams.get('dateTo');
  const ordering = searchParams.get('ordering');

  const { data, isFetching, error } = useGetStatusHistoryQuery({
    ordering: ordering !== null ? ordering : '',
    limit: limit || String(tablePageSize),
    page: page && page !== '1' ? page : null,
    type: type ? type : '',
    cpName: chargePointName,
    dateFrom: dateFrom ? dateFrom : '',
    dateTo: dateTo ? dateTo : '',
  });

  const tableData = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.statuses;
  }, [data]);

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  return (
    <ChargePointStatusesTableInstance
      tableData={tableData}
      loading={isFetching}
      count={data?.count}
      tableHeadRef={tableHeadRef}
      tablePageSize={tablePageSize}
    />
  );
}
