import { NavigationTab } from 'shared/model';

export const TABS: Array<NavigationTab> = [
  {
    key: 'mobile-app',
    path: '',
    label: 'Редактирование мобильного приложения',
  },
  {
    key: 'rights',
    label: 'Права',
    path: `rights`,
  },
];
