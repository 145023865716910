import { toCurrencySubunit } from 'shared/lib';
import { BonusLevelFormData } from '../model/levels-form';

export const prepareLevelRequestData = (values: BonusLevelFormData) => {
  const { awardPercent, awardSum, awardType, condition, level, name } = values;

  return {
    name,
    level: Number(level),
    condition: toCurrencySubunit(Number(condition)),
    awardPercent: Number(awardPercent),
    awardSum: toCurrencySubunit(Number(awardSum)),
  };
};
