import { SectionMenu } from 'shared/ui/section-menu';

import { REPORTS } from 'entities/report';
import { SECTION } from 'entities/navigation';
import { REPORT_ROUTES } from 'shared/consts/routes/report';

const { label, path } = SECTION['reports'];

export function ReportsMenu() {
  return (
    <SectionMenu
      loading={false}
      items={REPORTS}
      menuKey={path}
      absolutePath={REPORT_ROUTES.REPORTS}
      label={label}
    />
  );
}
