import { getEndpointPath } from 'shared/lib';

import { baseApi } from 'shared/api/base-api';

import {
  AddCarRequest,
  CarBaseResponse,
  CarsBaseResponse,
  DeleteCarRequest,
  UpdateCarRequest,
} from '../model/api';

import { apiSlice } from '../../../redux/services/apiSlice';
import { PLATFORM_SERVICE } from 'entities/authorization';

const KEY = 'Car';

const getUrl = getEndpointPath(PLATFORM_SERVICE, KEY);

// TODO: замена на baseApi

export const personCarsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCars: builder.query<CarsBaseResponse, string>({
      query: (personLogin) => ({
        url: getUrl(`GetCars`),
        params: { personLogin },
      }),
      providesTags: ['Cars'], // add personLogin as id ?
    }),
    getCar: builder.query<CarBaseResponse, string>({
      query: (id) => ({
        url: getUrl(`GetCar`),
        params: { id },
      }),
      providesTags: (result, error, arg) => [{ type: 'Car', id: arg }],
    }),
    addCar: builder.mutation<CarBaseResponse, AddCarRequest>({
      query: (car) => ({
        url: getUrl('AddCar'),
        method: 'POST',
        body: car,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Person', id: arg.personLogin },
      ],
    }),
    updateCar: builder.mutation<CarBaseResponse, UpdateCarRequest>({
      query: (car) => ({
        url: getUrl('UpdateCar'),
        method: 'PATCH',
        body: car,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Car', id: arg.id },
        'Cars',
        { type: 'Person', id: arg.personLogin },
      ],
    }),
    deleteCar: builder.mutation<ResponseBase, DeleteCarRequest>({
      query: ({ id, personLogin }) => ({
        url: getUrl(`DeleteCar`),
        params: {
          id,
        },
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        'Cars',
        { type: 'Person', id: arg.personLogin },
      ],
    }),
  }),
});

export const {
  useGetCarsQuery,
  useGetCarQuery,
  useAddCarMutation,
  useUpdateCarMutation,
  useDeleteCarMutation,
} = personCarsApi;
