import dayjs from 'dayjs';

export const FORMAT_TO_SECONDS = 'DD-MM-YYYY HH:mm:ss';
export const FORMAT_TO_MINUTES = 'DD-MM-YYYY HH:mm';
export const FORMAT_TO_DAYS = 'DD-MM-YYYY';

type Format =
  | typeof FORMAT_TO_DAYS
  | typeof FORMAT_TO_MINUTES
  | typeof FORMAT_TO_SECONDS;

export const showTimeString = (item: string, format: Format): string =>
  dayjs(item).format(format);
