import { Sidebar } from 'shared/ui/sidebar';
import { SiderbarMenuItem } from 'shared/ui/sidebar/types';

import { CountriesIcon } from 'shared/ui/sidebar/images/countries';
import { BanksIcon } from 'shared/ui/sidebar/images/banks';
import { ConnectorInstructionsIcon } from 'shared/ui/sidebar/images/connector-instructions';
import { AppSettingsIcon } from 'shared/ui/sidebar/images/app-settings';
import { CompaniesIcon } from 'shared/ui/sidebar/images/companies';
import { OcpiHubsIcon } from 'shared/ui/sidebar/images/ocpi-hubs';
import { CposIcon } from 'shared/ui/sidebar/images/cpos';
import { CarModelsIcon } from 'shared/ui/sidebar/images/car-models';

import FirmwaresIcon from 'shared/ui/sidebar/images/firmwares.png';
import MobileAppsIcon from 'shared/ui/sidebar/images/mobile-apps.png';

import { LogoutButton } from 'features/authorization/logout';

const ITEMS: SiderbarMenuItem[] = [
  {
    label: 'Страны',
    path: 'countries',
    icon: <CountriesIcon />,
    controller: 'Country',
  },
  {
    label: 'Банки',
    path: 'banks',
    icon: <BanksIcon />,
    controller: 'Bank',
  },
  {
    label: 'Инструкции коннекторов',
    path: 'connector-instructions',
    icon: <ConnectorInstructionsIcon />,
    controller: 'Connector',
  },
  {
    label: 'Настройки приложения',
    path: 'app-settings',
    icon: <AppSettingsIcon />,
    controller: 'Settings',
  },
  {
    label: 'Контрагенты',
    path: 'companies',
    icon: <CompaniesIcon />,
    controller: 'Person',
  },
  {
    label: 'Прошивки',
    path: 'firmwares',
    icon: <img src={FirmwaresIcon} />,
    controller: 'Firmware',
  },
  {
    label: 'OCPI Хабы',
    path: 'ocpi-hubs',
    icon: <OcpiHubsIcon />,
    controller: 'OcpiHubs',
  },
  {
    label: 'Операторы ЭЗС',
    path: 'cpos',
    icon: <CposIcon />,
    controller: 'Cpo',
  },
  {
    label: 'Мобильные приложения',
    path: 'mobile-apps',
    icon: <img src={MobileAppsIcon} />,
    controller: 'MobileApp',
  },
  {
    label: 'Марки EV',
    path: 'car-brands',
    icon: <CarModelsIcon />, // ?
    controller: 'Car',
  },
];

export function SettingsSidebar() {
  return <Sidebar logoutButton={<LogoutButton />} items={ITEMS} />;
}
