import { baseApi } from 'shared/api';
import { getEndpointPath } from 'shared/lib';

import { PLATFORM_SERVICE } from 'entities/authorization';

import {
  CompaniesBaseResponse,
  CompanyBaseResponse,
  AddCompanyFormRequest,
  UpdateCompanyFormRequest,
} from './types';

import { apiSlice } from '../../../redux/services/apiSlice';

const KEY = 'Person';

const getUrl = getEndpointPath(PLATFORM_SERVICE, KEY);

// TODO: замена на baseApi

export const companiesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCompanies: builder.query<CompaniesBaseResponse, void>({
      query: () => getUrl('GetCompanies'),
      providesTags: ['Companies'],
    }),
    // getCompanyByName: builder.query<CompanyResponse, string>({
    //   query: (name) => getUrl(`GetCompanyByName?name=${name}`),
    //   providesTags: (result, error, arg) => [
    //     { type: 'Company', id: result?.company.name },
    //   ],
    // }),
    getCompany: builder.query<CompanyBaseResponse, string>({
      query: (id) => ({
        url: getUrl(`GetCompany`),
        params: { id },
      }),
      providesTags: (result, error, arg) => [{ type: 'Company', id: arg }],
    }),
    addCompany: builder.mutation<CompanyBaseResponse, AddCompanyFormRequest>({
      query: (company) => ({
        url: getUrl('AddCompany'),
        method: 'POST',
        body: company,
      }),
      invalidatesTags: ['Companies'],
    }),
    updateCompany: builder.mutation<
      CompanyBaseResponse,
      UpdateCompanyFormRequest
    >({
      query: (company) => ({
        url: getUrl('UpdateCompany'),
        method: 'PATCH',
        body: company,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Company', id: arg.id },
        'Companies',
      ],
    }),
    deleteCompany: builder.mutation<ResponseBase, string>({
      query: (id) => ({
        url: getUrl(`DeleteCompany`),
        params: { id },
        method: 'DELETE',
      }),
      invalidatesTags: ['Companies'],
    }),
  }),
});

export const {
  useGetCompaniesQuery,
  useGetCompanyQuery,
  useAddCompanyMutation,
  useUpdateCompanyMutation,
  useDeleteCompanyMutation,
} = companiesApi;
