import { Modal } from 'antd';
import styled from 'styled-components';

import { COLOR } from 'shared/consts';

export const UiModalTitle = styled.h3`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: ${COLOR.black};
  padding-right: 40px;
`;

export const StyledUiModal = styled(Modal)`
  width: auto !important;

  //
  max-height: 90vh;
  overflow: auto;
  //

  & .ant-modal-content {
    border-radius: 8px;
  }

  & .ant-modal-header {
    border-radius: 8px 8px 0 0;
    padding: 20px;
  }

  & .ant-modal-body {
    padding: 20px;
    line-height: 1; //
  }

  & .ant-form-item-label {
    padding-bottom: 5px;
  }

  & .ant-form-item-label label {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: ${COLOR.darkGrey};
  }
`;
