import styled from 'styled-components';

type Props = {
  isOpen: boolean;
};

export const IconContainer = styled.span<Props>`
  transition: 200ms;
  transform: rotate(${({ isOpen }) => (isOpen ? 90 : 0)}deg);
  width: 17px;
  height: 17px;
`;
