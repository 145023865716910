import styled from 'styled-components';
import { Button } from 'antd';

import { COLOR } from 'shared/consts';

export const StyledPaginationButton = styled(Button)`
  padding: 7px;
  display: flex;
  width: 29px;
  height: 29px;
  border-color: ${COLOR.white};

  &:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;
