import { createColumnHelper } from '@tanstack/react-table';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';

import { FORMAT_TO_SECONDS, showTimeString } from 'shared/lib';
import { useTableSettings } from 'shared/ui/table/visible-columns/use-table-settings';
import { TColumnDef } from 'shared/ui/table/types';

import { BonusProgram, BONUS_PROGRAM_TYPE } from 'entities/bonus-program';
import { BonusProgramType } from 'entities/bonus-program/model/program';

import { selectBonusProgramsTable } from '../../../../redux/slices/tables/tables-slice';
import { setVisibleColumns, TableColumns, TableColumnsState } from '../store';
import { BonusProgramTableActions } from 'features/bonus/table-actions';

const columnHelper = createColumnHelper<BonusProgram>();

const DATA_COLUMNS: TColumnDef<BonusProgram, TableColumns>[] = [
  {
    accessorKey: 'name',
    id: 'name',
    header: 'Название',
    size: 100,
    minSize: 100,
    maxSize: 100,
  },
  {
    accessorKey: 'bonusProgramType',
    id: 'bonusProgramType',
    header: 'Тип',
    cell: (props) => {
      const value = props.getValue() || '';

      return value ? BONUS_PROGRAM_TYPE[value as BonusProgramType] : '';
    },
    size: 150,
    minSize: 150,
    maxSize: 150,
  },
  {
    accessorKey: 'dateStart',
    id: 'dateStart',
    header: 'Дата старта',
    cell: (props) => {
      const date = props.getValue() || '';

      return date ? showTimeString(date, FORMAT_TO_SECONDS) : '';
    },
    size: 200,
    minSize: 200,
    maxSize: 200,
  },
  {
    accessorKey: 'dateStop',
    id: 'dateStop',
    header: 'Дата завершения',
    cell: (props) => {
      const date = props.getValue() || '';

      return date ? showTimeString(date, FORMAT_TO_SECONDS) : '';
    },
    size: 200,
    minSize: 200,
    maxSize: 200,
  },
];

export const useColumns = () => {
  const dispatch = useDispatch();

  const settings = useSelector(selectBonusProgramsTable);

  const settingsColumn = useTableSettings({
    columnHelper,
    columns: DATA_COLUMNS.map(({ id, header }) => {
      return {
        key: id,
        label: header as string,
        isChecked: settings[id],
      };
    }),
    settings,
    renderCell: (props) => {
      const { id, name } = props.row.original;

      return <BonusProgramTableActions id={String(id)} name={name} />;
    },
    setVisibleColumns: (cols: TableColumnsState) => {
      dispatch(setVisibleColumns(cols));
    },
  });

  const visibleColumns = useMemo(() => {
    const dataCols = settings
      ? DATA_COLUMNS.filter((el) => settings[el.id])
      : DATA_COLUMNS;

    return [...dataCols, settingsColumn];
  }, [settings]);

  return visibleColumns;
};
