import { COLOR } from 'shared/consts';
import { getRGBAColor } from 'shared/lib';
import { LineChartColorParams } from 'shared/ui/charts/line/types';

const color = COLOR.greenMenu;

export const colorParams: LineChartColorParams = {
  borderColor: color,
  backgroundColor: getRGBAColor(color, '0.25'),
};

// Чарт без сетки, осей и лейблов
export const scalesOptions = {
  x: {
    type: 'time',
    time: {
      displayFormats: {
        month: 'MMM/YYYY',
        day: 'D MMM',
      },
    },
    ticks: {
      display: false,
      beginAtZero: true,
    },
    grid: {
      drawBorder: false,
      display: false,
    },
    border: {
      display: false,
    },
  },
  y: {
    ticks: {
      display: false,
      beginAtZero: true,
    },
    grid: {
      drawBorder: false,
      display: false,
    },
    border: {
      display: false,
    },
  },
};
