import { ChargePointConnectorDto, ConnectorsList } from 'entities/connector';

import { AddConnectorButton } from 'features/connector/add-connector';
import { ConnectorCardActions } from 'features/connector/connector-card-actions';

type Props = Omit<
  React.ComponentProps<typeof ConnectorsList>,
  'addConnectorSlot' | 'renderConnectorActions'
> & {
  chargePointId: string;
  chargePointName: string;
  transactionId: string;
};

export function ConnectorsListWidget(props: Props) {
  const { chargePointId, chargePointName, transactionId } = props;

  return (
    <ConnectorsList
      {...props}
      addConnectorSlot={
        <AddConnectorButton
          chargePointId={chargePointId}
          chargePointName={chargePointName}
        />
      }
      renderConnectorActions={(connector: ChargePointConnectorDto) => (
        <ConnectorCardActions
          chargePointId={chargePointId}
          chargePointName={chargePointName}
          transactionId={transactionId}
          connector={connector}
        />
      )}
    />
  );
}
