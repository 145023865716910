import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CardFormWrapper } from '../../../../common-styles';
import { ChargePointFormContainer } from './styles';

import { TechInfoForm } from './components/TechInfoForm';
import { CardSettingsForm } from './components/CardSettingsForm';
import { AdvancedPropertyForm } from './components/AdvancedPropertyForm';
import { ChargePointFormData, ChargePointFormProps } from '../../types';
import { OcppConfigForm } from './components/OcppConfigForm';
import { MainContentLayout } from 'shared/ui/layout';
import { ChargePointFormTabs } from 'entities/charge-point/ui/form/tabs';

export function ChargePointForm({
  groups,
  hubs,
  owners,
  handleSubmit,
  initialValues,
  loading,
}: ChargePointFormProps) {
  const [data, setData] = useState(initialValues);
  const [step, setStep] = useState(0);

  const navigate = useNavigate();

  const handleTabClick = (newStep: number) => setStep(newStep);

  const editing = Boolean(initialValues.techInfo.name);

  const handleTechInfoNextStep = (
    formData: ChargePointFormData['techInfo']
  ) => {
    setData((current) => {
      return { ...current, techInfo: formData };
    });
    setStep(step + 1);
  };

  const handleTechInfoSubmit = (formData: ChargePointFormData['techInfo']) => {
    handleSubmit({ ...data, techInfo: formData });
  };

  const handleAdvancedPropertyNextStep = (
    formData: ChargePointFormData['advancedProperty']
  ) => {
    setData((current) => {
      return { ...current, advancedProperty: formData };
    });
    setStep(step + 1);
  };

  const handleAdvancedPropertySubmit = (
    formData: ChargePointFormData['advancedProperty']
  ) => {
    handleSubmit({ ...data, advancedProperty: formData });
  };

  const handleCardSettingsNextStep = (
    formData: ChargePointFormData['cardSettings']
  ) => {
    setData((current) => {
      return { ...current, cardSettings: formData };
    });
    setStep(step + 1);
  };

  const handleCardSettingsSubmit = (
    formData: ChargePointFormData['cardSettings']
  ) => handleSubmit({ ...data, cardSettings: formData });

  const handleOcppConfigSubmit = (
    formData: ChargePointFormData['ocppConfig']
  ) => handleSubmit({ ...data, ocppConfig: formData });

  const onCancel = () => navigate(-1);

  const { advancedProperty, cardSettings, techInfo, ocppConfig } = data;

  const disabledTabs = useMemo(() => {
    let arr: number[] = [];

    if (editing) {
      return arr;
    }

    if (techInfo.name === '') {
      arr.push(1, 2, 3);

      return arr;
    }

    if (cardSettings.latitude === '') {
      arr.push(3);

      return arr;
    }

    return [];
  }, [data, editing]);

  const forms = [
    <TechInfoForm
      initialValues={techInfo}
      loading={loading}
      onCancel={onCancel}
      handleSubmit={editing ? handleTechInfoSubmit : handleTechInfoNextStep}
      editing={editing}
      groups={groups}
      owners={owners}
    />,
    <AdvancedPropertyForm
      initialValues={advancedProperty}
      loading={loading}
      onCancel={onCancel}
      handleSubmit={
        editing ? handleAdvancedPropertySubmit : handleAdvancedPropertyNextStep
      }
      editing={editing}
    />,
    <CardSettingsForm
      initialValues={cardSettings}
      loading={loading}
      onCancel={onCancel}
      handleSubmit={
        editing ? handleCardSettingsSubmit : handleCardSettingsNextStep
      }
      hubs={hubs}
      editing={editing}
    />,
    <OcppConfigForm
      initialValues={ocppConfig}
      loading={loading}
      onCancel={onCancel}
      editing={editing}
      handleSubmit={handleOcppConfigSubmit}
    />,
  ];

  return (
    <>
      <ChargePointFormTabs
        activeKey={step}
        onClick={handleTabClick}
        disabledTabs={disabledTabs}
      />
      <MainContentLayout
        element={
          <ChargePointFormContainer>
            <CardFormWrapper>{forms[step]}</CardFormWrapper>
          </ChargePointFormContainer>
        }
      />
    </>
  );
}
