import { useParams } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';

import {
  getServerErrorText,
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { ErrorMessage, EmptyData } from 'shared/ui';

import {
  useGetUsersGroupQuery,
  UsersGroup,
  UsersGroupForm,
  UsersGroupFormData,
  useUpdateIdentityGroupMutation,
} from 'entities/users-group';

import { DeleteUsersGroupModal } from 'features/users-group/delete';

import { EDIT_ERROR, EDIT_SUCCESS } from '../consts';

const getInitialValues = (group: UsersGroup): UsersGroupFormData => group;

export function EditUsersGroup() {
  const [updateTrigger, { isLoading: isUpdateLoading }] =
    useUpdateIdentityGroupMutation();

  const { groupName } = useParams() as { groupName: string };

  const { isFetching, error, data } = useGetUsersGroupQuery(groupName);

  const handleSubmit = async (values: UsersGroupFormData) => {
    try {
      const res = await updateTrigger(values).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);
        return;
      }
      //

      openSuccessNotification(EDIT_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : EDIT_ERROR;

      openErrorNotification(errorText);
    }
  };

  if (isFetching) {
    return <div>Loading...</div>;
  }

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!data || !data.group) {
    return <EmptyData />;
  }

  const initialValues = getInitialValues(data.group);

  const showDeleteModal = () => {
    NiceModal.show(DeleteUsersGroupModal, {
      groupName,
    });
  };

  return (
    <UsersGroupForm
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      loading={isUpdateLoading}
      title={data.group.name}
      handleDelete={showDeleteModal}
      disabled
    />
  );
}
