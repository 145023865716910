import jwt_decode from 'jwt-decode';

import { selectCurrentToken } from './selectCurrentToken';

export const selectTokenName = (state: RootState) => {
  const token = selectCurrentToken(state);

  const decoded = jwt_decode(token) as { name: string };

  return decoded.name;
};
