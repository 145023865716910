import { EmptyData, NavMenuError } from 'shared/ui';

import { useGetCarBrandsQuery } from 'entities/car-brand';

import { CarModelsMenu } from './menu';

export function ConnectedCarModelsMenu() {
  const {
    data: carBrands,
    isFetching: isCarBrandsFetching,
    error: carBrandsError,
  } = useGetCarBrandsQuery();

  if (carBrands && carBrands.statusCode !== 0) {
    return <NavMenuError text={carBrands.statusDescription} />;
  }

  if (carBrandsError) {
    return <NavMenuError />;
  }

  if (!isCarBrandsFetching && !carBrands) {
    return <EmptyData />;
  }

  const brands = carBrands?.carBrandList || [];

  return <CarModelsMenu loading={isCarBrandsFetching} brands={brands} />;
}
