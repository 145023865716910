import { PhotosCarousel } from 'entities/charge-point';

import { AddChargePointPhotoButton } from 'features/charge-point-photo/add';

import { CardContent, CardHeader, StyledCard, CardTitle } from './styles';

type Props = {
  photoUrls: string[];
  chargePointName: string;
};

export function ChargePointPhotosCard({ photoUrls, chargePointName }: Props) {
  return (
    <>
      <StyledCard>
        <CardHeader>
          <CardTitle>Фото</CardTitle>
          <AddChargePointPhotoButton chargePointName={chargePointName} />
        </CardHeader>
        <CardContent>
          <PhotosCarousel photoUrls={photoUrls} />
        </CardContent>
      </StyledCard>
    </>
  );
}
