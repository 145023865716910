import { ChargePointConnectorType, CONNECTOR_TYPE } from 'entities/connector';
import { AvailableConnectionDto } from '../model/car';

export const getTableData = () => {
  return Object.entries(CONNECTOR_TYPE).map((entry) => {
    const [connectorType, connectorName] = entry;

    return {
      connectorType,
      connectorName,
      isAdapterNeeded: false,
    };
  });
};

export const getConnectorListFromFormData = (
  connectorsFormData: Record<string, boolean>
) => {
  const connectorList = Object.keys(connectorsFormData)
    .filter((key) => !key.includes('adapter'))
    .reduce((acc, el) => {
      if (!connectorsFormData[el] && !connectorsFormData[`${el}/adapter`]) {
        return acc;
      }

      const connectorListItem = {
        connectorType: Number(el) as ChargePointConnectorType,
        isAdapterNeeded: Boolean(connectorsFormData[`${el}/adapter`]),
      };

      return [...acc, connectorListItem];
    }, [] as AvailableConnectionDto[]);

  return connectorList;
};
