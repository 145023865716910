import { GetConnectorInstructionParams } from '../model';

export const prepareGetConnectorInstructionParams = (
  params: GetConnectorInstructionParams
) => {
  const requestParams: { [key: string]: string } = {};

  requestParams.connectorType = params.connectorType;

  return requestParams;
};
