import LogoBlack from './images/logo-black.svg';
import LogoWhite from './images/logo-white.svg';
import LogoBlackSmall from './images/logo-small-black.svg';
import LogoWhiteSmall from './images/logo-small-white.svg';

interface LogoSitronicsElectroProps {
  type: 'black' | 'white';
}

export function LogoSitronicsElectro({ type }: LogoSitronicsElectroProps) {
  return (
    <img
      src={type === 'black' ? LogoBlack : LogoWhite}
      alt="Sitronics electro logo"
    />
  );
}

export function LogoSitronicsElectroSmall({ type }: LogoSitronicsElectroProps) {
  return (
    <img
      src={type === 'black' ? LogoBlackSmall : LogoWhiteSmall}
      alt="Sitronics electro logo small"
    />
  );
}
