import React from 'react';

import { ErrorMessage, EmptyData } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';

import { useGetChargePointGroupsQuery } from 'entities/group';
import { useGetHubsQuery } from 'entities/hub';
import { useGetOwnersQuery } from 'entities/owner';

import { ChargePointForm } from '../ChargePointForm';

type Props = Omit<
  React.ComponentProps<typeof ChargePointForm>,
  'owners' | 'hubs' | 'groups'
>;

export function ConnectedChargePointForm(props: Props) {
  const {
    data: owners,
    isFetching: isOwnersLoading,
    error: ownersError,
  } = useGetOwnersQuery();

  const {
    data: hubs,
    isFetching: isHubsLoading,
    error: hubsError,
  } = useGetHubsQuery();

  const {
    data: groups,
    isFetching: isGroupsLoading,
    error: groupsError,
    currentData,
  } = useGetChargePointGroupsQuery();

  if (isOwnersLoading || isHubsLoading || isGroupsLoading) {
    return <div>Loading...</div>;
  }

  // TODO: mb need ContentWrapper

  const apiResponseError = [owners, hubs, groups].find(
    (el) => el && el.statusCode !== 0
  );

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusDescription} />;
  }

  const error = [ownersError, hubsError, groupsError].find(
    (err) => err !== undefined
  );

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!hubs || !owners || !groups) {
    return <EmptyData />;
  }

  return (
    <ChargePointForm
      {...props}
      hubs={hubs.hubs}
      owners={owners.owners}
      groups={groups.groups}
    />
  );
}
