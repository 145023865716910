import {
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import useMeasure from 'react-use-measure';

import { useClientPagination, useTablePageSize } from 'shared/lib';
import { ClientTableLayout } from 'shared/ui';

import { GroupDto } from 'entities/group';

import { useColumns } from '../hooks/use-columns';

type Props = {
  tableData: GroupDto[];
  loading: boolean;
  rowsCount: number | undefined;
};

export function GroupsTableInstance({ tableData, loading, rowsCount }: Props) {
  const [tableHeadRef, tableHeadBounds] = useMeasure();

  const columns = useColumns();

  const [sorting, setSorting] = useState<SortingState>([]);
  const [tablePageSize, setTablePageSize] = useTablePageSize(tableHeadBounds);
  const [pagination, setPagination] = useClientPagination(tablePageSize);

  const data = useMemo(() => tableData, [tableData]);

  const table = useReactTable({
    data,
    columns,
    state: { sorting, pagination },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <ClientTableLayout
      table={table}
      tableHeadRef={tableHeadRef}
      handlePageSizePressEnter={setTablePageSize}
      pageSize={tablePageSize}
      loading={loading}
      rowsCount={rowsCount}
    />
  );
}
