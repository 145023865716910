export const OWNER_TYPE: Record<string, string> = {
  // '-1': 'Все',
  1: 'Собственные',
  2: 'Агентские',
  3: 'Партнерские',
};

export const STATUS_TYPE = {
  // '-1': 'Все',
  0: 'Нет связи',
  1: 'Онлайн',
  2: 'Ошибка',
  3: 'Не определено',
};
