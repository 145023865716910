import { COLOR } from 'shared/consts';

import {
  ChargePointProtocol,
  ChargePointStatus,
  ChargePointType,
} from '../model';

export const CHARGE_POINT_STATUS: Record<ChargePointStatus, string> = {
  0: 'Нет связи',
  1: 'Свободна, Доступна',
  2: 'Неисправна',
  3: 'Не доступна',
};

export const CHARGE_POINT_TYPE: Record<ChargePointType, string> = {
  1: 'Медленная',
  2: 'Быстрая',
};

export const CHARGE_POINT_TYPE_BADGE: Record<
  Exclude<ChargePointType, 0>,
  string
> = {
  1: 'AC',
  2: 'DC',
};

export const CHARGE_POINT_PROTOCOL: Record<ChargePointProtocol, string> = {
  // 0: 'Не задан',
  1: 'OCPP 1.5',
  2: 'OCPP 1.6S',
  3: 'ModBus 1.1',
  4: 'OCPP 1.6J',
  5: 'OCPI',
  6: 'OCPP 2.0.1',
};

export const ADDRESS_MAX_LENGTH = 45;

export const CITY_MIN_LENGTH = 3;

export const CHARGE_POINT_STATUS_COLOR: Record<ChargePointStatus, string> = {
  0: COLOR.uninitialized,
  1: COLOR.greenMenu,
  2: COLOR.red,
  3: COLOR.red,
};

export const CHARGE_POINT_TYPE_COLOR: Record<
  Exclude<ChargePointType, 0>,
  string
> = {
  1: COLOR.yellow,
  2: COLOR.greenMenu,
};
