import { NavigationTab } from 'shared/model';

export const TABS: Array<NavigationTab> = [
  {
    key: 'edit-group',
    label: 'Редактирование группы',
    path: '',
  },
  {
    key: 'users',
    label: 'Пользователи',
    path: `users`,
  },
];
