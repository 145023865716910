import { Route } from 'react-router-dom';

import { FIRMWARE, FIRMWARES } from 'shared/consts/routes/firmware';
import { ADD_PREFIX } from 'shared/consts/routes/common';
import { MainContentLayout, SectionLayout } from 'shared/ui/layout';

import { RequireAuth } from 'entities/authorization';
import { ConnectedFirmwaresMenu } from 'entities/firmware';

import { AddFirmware } from 'features/firmware/add';
import { EditFirmware } from 'features/firmware/edit';

export const FirmwareRoutes = (
  <Route element={<RequireAuth controllerName="Firmware" rightType="Read" />}>
    <Route
      path={FIRMWARES}
      element={<SectionLayout menu={<ConnectedFirmwaresMenu />} />}
    >
      <Route
        element={<RequireAuth controllerName="Firmware" rightType="Write" />}
      >
        <Route
          path={ADD_PREFIX}
          element={<MainContentLayout element={<AddFirmware />} />}
        />
      </Route>
      <Route
        path={FIRMWARE}
        element={<MainContentLayout element={<EditFirmware />} />}
      />
    </Route>
  </Route>
);
