import { createColumnHelper } from '@tanstack/react-table';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';

import { useTableSettings } from 'shared/ui/table/visible-columns/use-table-settings';
import { TColumnDef } from 'shared/ui/table/types';

import { GroupDto } from 'entities/group';

import { selectGroupsTable } from '../../../../redux/slices/tables/tables-slice';

import { setVisibleColumns, TableColumns, TableColumnsState } from '../store';
import { GroupTableActions } from '../ui/actions';

const columnHelper = createColumnHelper<GroupDto>();

const DATA_COLUMNS: TColumnDef<GroupDto, TableColumns>[] = [
  {
    accessorKey: 'id',
    id: 'id',
    header: 'ID группы',
  },
  {
    accessorKey: 'name',
    id: 'name',
    header: 'Наименование',
  },
  {
    accessorKey: 'comment',
    id: 'comment',
    header: 'Описание',
  },
];

export const useColumns = () => {
  const dispatch = useDispatch();

  const settings = useSelector(selectGroupsTable);

  const settingsColumn = useTableSettings({
    columnHelper,
    columns: DATA_COLUMNS.map(({ id, header }) => {
      return {
        key: id,
        label: header as string,
        isChecked: settings[id],
      };
    }),
    settings,
    renderCell: (props) => {
      const group = props.row.original;

      return <GroupTableActions group={group} />;
    },
    setVisibleColumns: (cols: TableColumnsState) => {
      dispatch(setVisibleColumns(cols));
    },
  });

  const visibleColumns = useMemo(() => {
    const dataCols = settings
      ? DATA_COLUMNS.filter((el) => settings[el.id])
      : DATA_COLUMNS;

    return [...dataCols, settingsColumn];
  }, [settings]);

  return visibleColumns;
};
