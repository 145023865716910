import { baseApi } from 'shared/api';

import { getEndpointPath } from 'shared/lib';

import {
  BanksBaseResponse,
  BankBaseResponse,
  AddBankFormRequest,
  UpdateBankFormRequest,
} from './types';

import { apiSlice } from '../../../redux/services/apiSlice';
import { PLATFORM_SERVICE } from 'entities/authorization';

const KEY = 'Bank';

const getUrl = getEndpointPath(PLATFORM_SERVICE, KEY);

// TODO: замена на baseApi

export const api = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBanks: builder.query<BanksBaseResponse, void>({
      query: () => getUrl('GetBanks'),
      providesTags: ['Banks'],
    }),
    getBank: builder.query<BankBaseResponse, string>({
      query: (id) => ({
        url: getUrl(`GetBank`),
        params: { id },
      }),
      providesTags: (result, error, arg) => [{ type: 'Bank', id: arg }],
    }),
    addBank: builder.mutation<BankBaseResponse, AddBankFormRequest>({
      query: (bank) => ({
        url: getUrl('AddBank'),
        method: 'POST',
        body: bank,
      }),
      invalidatesTags: ['Banks'],
    }),
    updateBank: builder.mutation<BankBaseResponse, UpdateBankFormRequest>({
      query: (bank) => ({
        url: getUrl('UpdateBank'),
        method: 'PATCH',
        body: bank,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Bank', id: arg.id },
        'Banks',
      ],
    }),
    deleteBank: builder.mutation<ResponseBase, string>({
      query: (id) => ({
        url: getUrl(`DeleteBank`),
        params: { id },
        method: 'DELETE',
      }),
      invalidatesTags: ['Banks'],
    }),
  }),
});

export const {
  useGetBanksQuery,
  useGetBankQuery,
  useAddBankMutation,
  useUpdateBankMutation,
  useDeleteBankMutation,
} = api;
