import { useNavigate } from 'react-router-dom';

import {
  openErrorNotification,
  openSuccessNotification,
  isErrorWithMessage,
} from 'shared/lib';
import { TARIFFS_ROUTES } from 'shared/consts/routes/tariff';

import {
  ConnectedTariffForm,
  prepareRequestData,
  TariffFormData,
  useAddTariffMutation,
} from 'entities/tariff';

import { ADD_SUCCESS, ADD_ERROR } from '../consts';

const initialValues: TariffFormData = {
  name: '',
  editProperties: '',
  id: '',
  bankId: '',
  startFee: '0',
  DCPerKilowatt: false,
  costOfQuickCharging: '0',
  DCPerMinute: false,
  costOfReserve: '0',
  // ACPerKilowatt: false,
  // costOfSlowCharging: '0',
  batteryPercentageTrigger: false,
  batteryPercentage: '0',
  batteryPercentageFee: '0',
  powerDropTrigger: false,
  powerDrop: '',
  powerDropFee: '0',
  parkFee: '0',
  parkTimeAfter: '0',
  isArchive: false,
};

export function AddTariff() {
  const [trigger, { isLoading }] = useAddTariffMutation();

  const navigate = useNavigate();

  const handleSubmit = async (values: TariffFormData) => {
    const { id, ...rest } = prepareRequestData(values);

    try {
      const res = await trigger(rest).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);

        return;
      }
      //

      openSuccessNotification(ADD_SUCCESS);

      // const entityPath = res.chargeTariff.id;

      navigate(TARIFFS_ROUTES.TARIFFS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : ADD_ERROR;

      openErrorNotification(errorText);
    }
  };

  return (
    <ConnectedTariffForm
      initialValues={initialValues}
      title="Добавление нового тарифа"
      handleSubmit={handleSubmit}
      loading={isLoading}
    />
  );
}
