import { GetCpoParams } from '../api/types';

export const prepareGetCpoParams = (params: GetCpoParams) => {
  const requestParams: { [key: string]: string } = {};

  // partyId сейчас не используется

  requestParams.id = params.id;

  return requestParams;
};
