import { Cell, Header, Row, Table } from '@tanstack/react-table';
import { useSearchParams } from 'react-router-dom';

import { ServerPagination } from '../../pagination';
import { useSortingChange } from '../../sorting';
import { TableLayout } from '../table-layout';

type Props = {
  table: Table<any>;
  loading: boolean;
  pageSize: number;
  tableHeadRef: (element: HTMLElement | SVGElement | null) => void;
  handleRowDoubleClick?: (row: Row<any>) => void;
  tableRef?: React.MutableRefObject<null>;
  exportButton?: JSX.Element;
  rowsCount: number | undefined;
};

export function ServerTableLayout({
  table,
  loading,
  pageSize,
  tableHeadRef,
  handleRowDoubleClick,
  tableRef,
  exportButton,
  rowsCount,
}: Props) {
  const onSortingChange = useSortingChange();

  //
  const [searchParams, setSearchParams] = useSearchParams();

  const ordering = searchParams.get('ordering');
  const type = searchParams.get('type');
  const page = searchParams.get('page');
  const limit = searchParams.get('limit');
  //

  const getTdClassName = (cell: Cell<any, unknown>) => {
    return ordering === cell.column.id && type !== null ? 'sorted' : '';
  };

  const handleSortChange = (header: Header<any, unknown>) => {
    return () => onSortingChange(header.id);
  };

  return (
    <TableLayout
      table={table}
      loading={loading}
      getTdClassName={getTdClassName}
      handleSortChange={handleSortChange}
      tableHeadRef={tableHeadRef}
      tableRef={tableRef}
      handleRowDoubleClick={handleRowDoubleClick}
      tableControls={
        <>
          <ServerPagination
            table={table}
            jumperValue={page === null ? String(1) : page}
            pageSize={limit ? Number(limit) : pageSize}
            rowsCount={rowsCount}
          />
          {exportButton}
        </>
      }
    />
  );
}
