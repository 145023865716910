import { useState, useEffect } from 'react';

import { StyledDebouncedInput } from './styles';

type Props = {
  value: string;
  onChange: (searchText: string) => void;
  disabled: boolean;
  debounce?: number;
};

export function DebouncedInput({
  value: initialValue,
  onChange,
  disabled,
  debounce = 500,
}: Props) {
  const [value, setValue] = useState('');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  useEffect(() => setValue(initialValue), [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <StyledDebouncedInput
      value={value}
      onChange={handleInputChange}
      type="text"
      disabled={disabled}
    />
  );
}
