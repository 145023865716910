import styled from 'styled-components';

import { DayjsDatePicker } from 'shared/ui';
import { COLOR } from 'shared/consts';

const { RangePicker } = DayjsDatePicker;

export const StyledReportRangePicker = styled(RangePicker)`
  border: none;
  width: 220px;
  padding: 7px 6px 6px 10px;
  border-radius: 4px;
  background-color: ${COLOR.white};

  &.ant-picker-focused {
    box-shadow: none;
  }

  & .ant-picker-input > input {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: ${COLOR.black};
  }

  & .ant-picker-active-bar {
    display: none;
  }

  & .ant-picker-clear {
    right: 0;
  }
`;
