import { AppSettingsFormData } from '../model/form';

export const prepareRequestData = (values: AppSettingsFormData) => {
  const { id, statusTimeOut, stopChargingRequestTimeout, countryId, ...rest } =
    values;

  return {
    ...rest,
    id: String(id),
    countryId: Number(countryId),
    stopChargingRequestTimeout: Number(stopChargingRequestTimeout),
    statusTimeOut: Number(statusTimeOut),
  };
};
