import { EmptyData, ErrorMessage } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';

import { useGetTariffsQuery } from 'entities/tariff';

import { OwnerForm } from '../form';

type Props = Omit<React.ComponentProps<typeof OwnerForm>, 'tariffs'>;

export function ConnectedOwnerForm(props: Props) {
  const { data, isFetching, error } = useGetTariffsQuery();

  if (isFetching) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  if (!data) {
    return <EmptyData />;
  }

  return <OwnerForm {...props} tariffs={data.chargeTariffs} />;
}
