import { baseApi } from 'shared/api';
import { getEndpointPath } from 'shared/lib';

import {
  SettingsListBaseResponse,
  SettingsBaseResponse,
  AddSettingsFormRequest,
  UpdateSettingsFormRequest,
} from './types';

import { apiSlice } from '../../../redux/services/apiSlice';
import { PLATFORM_SERVICE } from 'entities/authorization';

const KEY = 'Settings';

const getUrl = getEndpointPath(PLATFORM_SERVICE, KEY);

// TODO: замена на baseApi

export const api = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSettingsList: builder.query<SettingsListBaseResponse, void>({
      query: () => getUrl('GetSettingsList'),
      providesTags: ['SettingsList'],
    }),
    getSettings: builder.query<SettingsBaseResponse, string>({
      query: (countryId) => ({
        url: getUrl(`GetSettings`),
        params: { countryId },
      }),
      providesTags: (result, error, arg) => [{ type: 'Settings', id: arg }],
    }),
    addSettings: builder.mutation<SettingsBaseResponse, AddSettingsFormRequest>(
      {
        query: (settings) => ({
          url: getUrl('AddSettings'),
          method: 'POST',
          body: settings,
        }),
        invalidatesTags: ['SettingsList'],
      }
    ),
    updateSettings: builder.mutation<
      SettingsBaseResponse,
      UpdateSettingsFormRequest
    >({
      query: (settings) => ({
        url: getUrl('UpdateSettings'),
        method: 'PATCH',
        body: settings,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Settings', id: arg.countryId },
        'SettingsList',
      ],
    }),
    deleteSettings: builder.mutation<ResponseBase, string>({
      query: (id) => ({
        url: getUrl(`DeleteSettings`),
        params: { id },
        method: 'DELETE',
      }),
      invalidatesTags: ['SettingsList'],
    }),
  }),
});

export const {
  useGetSettingsQuery,
  useGetSettingsListQuery,
  useAddSettingsMutation,
  useUpdateSettingsMutation,
  useDeleteSettingsMutation,
} = api;
