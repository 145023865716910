import styled from 'styled-components';

import { COLOR } from 'shared/consts';

interface FirmwareItemContainerProps {
  isError: boolean;
}

export const FileContainer = styled.div<FirmwareItemContainerProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-radius: 5px;
  border: ${(props) => `2px solid ${props.isError ? COLOR.red : COLOR.grey}`};

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const ErrorText = styled.p`
  color: ${COLOR.red};
  font-size: 12px;
  line-height: 15px;
`;

export const Filename = styled.p`
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR.black};
`;

export const ControlsContainer = styled.div`
  display: flex;
  gap: 10px;
`;
