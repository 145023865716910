import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Leaves } from 'shared/lib/types';

import { ChargePointStatusDto } from '../../../../models/status';

type ObjectLeaves = Leaves<ChargePointStatusDto>;

export type TableColumns = Extract<ObjectLeaves, 'date' | 'status'>;

export type TableColumnsState = Record<TableColumns, boolean>;

const initialState: TableColumnsState = {
  date: true,
  status: true,
};

const slice = createSlice({
  name: 'chargePointStatusesTable',
  initialState,
  reducers: {
    setVisibleColumns: (state, action: PayloadAction<TableColumnsState>) => {
      return action.payload;
    },
  },
});

export const { setVisibleColumns } = slice.actions;
export default slice.reducer;
