import { createColumnHelper } from '@tanstack/react-table';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';

import { useTableSettings } from 'shared/ui/table/visible-columns/use-table-settings';
import { TColumnDef } from 'shared/ui/table/types';

import { selectDiscountsTable } from '../../../../redux/slices/tables/tables-slice';
import { setVisibleColumns, TableColumns, TableColumnsState } from '../store';

import { TableActions } from '../ui/actions';
import { DiscountDto } from 'entities/discount';

const columnHelper = createColumnHelper<DiscountDto>();

const DATA_COLUMNS: TColumnDef<DiscountDto, TableColumns>[] = [
  {
    accessorKey: 'name',
    id: 'name',
    header: 'Наименование',
  },
  {
    id: 'startDiscountFormula',
    header: 'Скидка на старт',
  },
  {
    accessorKey: 'consumptionDiscountFormula',
    id: 'consumptionDiscountFormula',
    header: 'Скидка на основной тариф потребления (за кВт*ч или минуты)',
  },
  {
    accessorKey: 'parkingDiscountFormula',
    id: 'parkingDiscountFormula',
    header: 'Скидка на простой',
  },
  {
    accessorKey: 'reserveDiscountFormula',
    id: 'reserveDiscountFormula',
    header: 'Скидка на бронь',
  },
];

export const useColumns = () => {
  const dispatch = useDispatch();

  const settings = useSelector(selectDiscountsTable);

  const settingsColumn = useTableSettings({
    columnHelper,
    columns: DATA_COLUMNS.map(({ id, header }) => {
      return {
        key: id,
        label: header as string,
        isChecked: settings[id],
      };
    }),
    settings,
    renderCell: (props) => {
      const { id, name } = props.row.original;

      return <TableActions id={String(id)} name={name} />;
    },
    setVisibleColumns: (cols: TableColumnsState) => {
      dispatch(setVisibleColumns(cols));
    },
  });

  const visibleColumns = useMemo(() => {
    const dataCols = settings
      ? DATA_COLUMNS.filter((el) => settings[el.id])
      : DATA_COLUMNS;

    return [...dataCols, settingsColumn];
  }, [settings]);

  return visibleColumns;
};
