import type { CompanyFormData } from '../model';

export const prepareRequestData = (values: CompanyFormData) => {
  const { id, chargingLimitBalance, chargingLimit, ...rest } = values;

  return {
    ...rest,
    id: Number(id),
    chargingLimitBalance: Number(chargingLimitBalance) * 100,
    chargingLimit: Number(chargingLimit) * 100,
  };
};
