import { useSelector } from 'react-redux';

import { selectTokenClaims, selectTokenRoles } from 'entities/authorization';

import { NavigationTab } from 'shared/model';

import {
  TabPanelsMenu,
  TabPanelsContainer,
  ButtonsWrapper,
  TabPanelItem,
  TabPanelContainer,
} from './styles';

import { NavitationTabSpinner } from '../navigation-tab-spinner';
import { CustomLink } from '../custom-link';

import { useVisibleNavElements } from 'shared/hooks/use-visible-navigation-elements';

type Props = {
  tabs: Array<NavigationTab>;
  fetchingTab?: string;
  rootPath: string;
};

export function NavigationTabs({ tabs, fetchingTab, rootPath }: Props) {
  const { visibleButtons, visibleTabs } = useVisibleNavElements(tabs, rootPath);

  return (
    <TabPanelsContainer>
      <TabPanelsMenu>
        {visibleTabs.map(({ key, label, path, hasLoader }) => (
          <TabPanelItem key={key}>
            <CustomLink to={path} key={key}>
              <TabPanelContainer>
                {label}
                {hasLoader && (
                  <NavitationTabSpinner isLoading={fetchingTab === key} />
                )}
              </TabPanelContainer>
            </CustomLink>
          </TabPanelItem>
        ))}
      </TabPanelsMenu>
      {visibleButtons?.length && (
        <ButtonsWrapper>
          {visibleButtons.map((el) => el.element)}
        </ButtonsWrapper>
      )}
    </TabPanelsContainer>
  );
}
