import { useSelector } from 'react-redux';
import useWindowFocus from 'use-window-focus';

import { ErrorMessage } from 'shared/ui';

import { getServerErrorText } from 'shared/lib';

import { selectOwnerTypes } from '../../../redux/slices/filters/filtersSlice';
import { DashboardMetrics } from '../DashboardMetrics';
import {
  MetricsDashboard,
  METRICS_DASHBOARD,
  METRICS_POLLING_INTERVAL,
} from '../consts';

import { useGetMetricsQuery } from 'entities/metrics';
import { MetricName } from 'entities/metrics/model/metrics';
// import { MetricsErrorMessage } from '../MetricsErrorMessage';

type Props = {
  dashboards: Array<MetricsDashboard>;
};

export function ConnectedDashboardMetrics({ dashboards }: Props) {
  const isWindowFocused = useWindowFocus();

  const ownerTypes = useSelector(selectOwnerTypes);

  const names = [
    ...new Set(
      dashboards.reduce((acc, el) => {
        return [...acc, ...METRICS_DASHBOARD[el].names];
      }, [] as MetricName[])
    ),
  ];

  const { data, isFetching, error, currentData } = useGetMetricsQuery(
    {
      names,
      ownerTypes,
    },
    {
      pollingInterval: isWindowFocused ? METRICS_POLLING_INTERVAL : undefined,
    }
  );

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  return (
    <DashboardMetrics
      loading={isFetching && !currentData}
      dashboards={dashboards}
      metrics={data?.metrics || null}
    />
  );
}
