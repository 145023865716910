import { useParams } from 'react-router-dom';

import { PersonHeader } from 'entities/person';

import { ConnectedClientBankCardSessions } from 'features/bank-card/sessions-table';

export function PersonBankCardSessionsPage() {
  const { userLogin, groupId } = useParams() as {
    groupId: string;
    userLogin: string;
  };

  return (
    <>
      <PersonHeader />
      <ConnectedClientBankCardSessions />
    </>
  );
}
