import { useLocation } from 'react-router-dom';

import { NavigationTabs } from 'shared/ui/navigation-tabs';

import { TABS } from '../../consts';
import { usePersonFetchingContext } from '../../context';

export function PersonTabs() {
  const { fetchingTab } = usePersonFetchingContext();

  const location = useLocation();

  return (
    <NavigationTabs
      tabs={TABS}
      fetchingTab={fetchingTab}
      rootPath={location.pathname}
    />
  );
}
