import { Route } from 'react-router-dom';

import { MainContentLayout, SectionLayout } from 'shared/ui/layout';
import { ROLES, USER, USERS } from 'shared/consts/routes/user';
import {
  ADD_PREFIX,
  CHARGE_POINTS_RIGHTS,
  CONTROLLER_CLAIMS,
} from 'shared/consts/routes/common';

import { RequireAuth } from 'entities/authorization';
import { UserLayout } from 'entities/user/user-layout';
import { UsersLayout } from 'entities/user/users-layout';

import { AddUser } from 'features/user/add';
import { EditUser } from 'features/user/edit';
import { ConnectedUsersMenu } from 'features/user/menu';
import { ConnectedUserListTable } from 'features/user/table';

// TODO
import { ConnectedUserChargePointRights } from '../../../components/Security/User/pages/ConnectedUserChargePointRights';
import { ConnectedUserControllerRights } from '../../../components/Security/User/pages/ConnectedUserControllerRights';
import { ConnectedUserRoles } from '../../../components/Security/User/pages/ConnectedUserRoles';

export const UserRoutes = (
  <Route element={<RequireAuth controllerName="Identity" rightType="Read" />}>
    <Route
      path={USERS}
      element={<SectionLayout menu={<ConnectedUsersMenu />} />}
    >
      <Route element={<UsersLayout />}>
        <Route index element={<ConnectedUserListTable />} />
      </Route>
      <Route
        element={<RequireAuth controllerName="Identity" rightType="Write" />}
      >
        <Route
          path={ADD_PREFIX}
          element={<MainContentLayout element={<AddUser />} />}
        />
      </Route>
      <Route path={USER} element={<UserLayout />}>
        <Route index element={<EditUser />} />
        <Route
          path={CONTROLLER_CLAIMS}
          element={<ConnectedUserControllerRights />}
        />
        <Route
          path={CHARGE_POINTS_RIGHTS}
          element={<ConnectedUserChargePointRights />}
        />
        <Route path={ROLES} element={<ConnectedUserRoles />} />
      </Route>
    </Route>
  </Route>
);
