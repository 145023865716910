import { EmptyData, NavMenuError } from 'shared/ui';
import { SectionMenu } from 'shared/ui/section-menu';

import { SECTION } from 'entities/navigation';
import { useGetUsersGroupsQuery } from 'entities/users-group';
import { GROUP_ROUTES } from 'shared/consts/routes/group';

const { label, path } = SECTION['groups'];

export function ConnectedUsersGroupMenu() {
  const { data, isFetching, error } = useGetUsersGroupsQuery();

  if (data && data.statusCode !== 0) {
    return <NavMenuError text={data.statusDescription} />;
  }

  if (error) {
    return <NavMenuError />;
  }

  if (!isFetching && !data) {
    return <EmptyData />;
  }

  const items = (data?.groups || []).map(({ id, name }) => ({
    id: encodeURIComponent(name),
    name,
  }));

  return (
    <SectionMenu
      loading={isFetching}
      items={items}
      menuKey={path}
      absolutePath={GROUP_ROUTES.GROUPS}
      label={label}
    />
  );
}
