import styled from 'styled-components';

import { COLOR, DEFAULT_BORDER } from 'shared/consts';

export const MainWrapper = styled.div`
  grid-area: main;
  overflow: auto;
`;

export const MenuWrapper = styled.div`
  grid-area: menu;
  display: flex;
  flex-direction: column;
  border-right: ${DEFAULT_BORDER};
  background-color: ${COLOR.white};
`;

export const MenuFiller = styled.div`
  min-height: 82px;
  border-top: ${DEFAULT_BORDER};
`;

export const MenuContainer = styled.div`
  overflow-y: auto;
  flex-grow: 1;
`;
