import styled from 'styled-components';

export const StyledCard = styled.article`
  min-width: 367px;
  max-width: 367px;
  background: #ffffff;
  border-radius: 8px;
  border: none;
  align-self: start;
`;

export const CardHeader = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CardTitle = styled.h4`
  line-height: 17px;
  font-weight: 500;
`;

export const CardContent = styled.div`
  padding: 0 10px 20px 10px;
`;
