export function ShoppingMallIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 6L9 7C9 8.65685 10.3431 10 12 10C13.6569 10 15 8.65685 15 7V6"
        stroke="#28303F"
        stroke-width="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6133 3H8.39031C6.43496 3 4.7662 4.41365 4.44474 6.3424L2.77807 16.3424C2.37172 18.7805 4.25189 21 6.72365 21H17.28C19.7517 21 21.6319 18.7805 21.2255 16.3424L19.5589 6.3424C19.2374 4.41365 17.5687 3 15.6133 3Z"
        stroke="#28303F"
        stroke-width="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
}
