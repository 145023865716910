export function ConnectorInstructionsIcon() {
  return (
    <svg viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.33333 8.37492H10.5M6.33333 12.5416H14.6667M6.33333 16.7083H14.6667M14.6662 1.08325V4.20825M6.33282 1.08325V4.20825M5.29167 2.64575H15.7083C18.0095 2.64575 19.875 4.51123 19.875 6.81242V17.7499C19.875 20.0511 18.0095 21.9166 15.7083 21.9166H5.29167C2.99048 21.9166 1.125 20.0511 1.125 17.7499V6.81242C1.125 4.51123 2.99048 2.64575 5.29167 2.64575Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
