import { ButtonProps } from 'antd/lib/button';

import { UiButton } from '../ui-button';

type Props = {
  onClick: () => void;
} & ButtonProps;

export function UiEditFormButton(props: Props) {
  return (
    <UiButton
      {...props}
      htmlType="button"
      type="primary"
      onClick={props.onClick}
    >
      Редактировать
    </UiButton>
  );
}
