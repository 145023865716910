import styled from 'styled-components';

import { COLOR } from 'shared/consts';

export const ReportInfoContainer = styled.div``;

export const ReportTitle = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: ${COLOR.black};
  margin-bottom: 12px;
`;

export const ParamsContainer = styled.div`
  display: flex;
  gap: 20px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${COLOR.black};
`;

export const ReportParam = styled.div`
  position: relative;

  &:not(:last-child)::after {
    content: '';
    position: absolute;
    background-color: #d9d9d9;
    width: 1px;
    height: 10px;
    bottom: 3px;
    right: -10px;
  }
`;
