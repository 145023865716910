import styled from 'styled-components';

import { COLOR } from 'shared/consts';

export const StyledCarCard = styled.div`
  border-radius: 8px;
  border: 0.5px solid rgba(0, 0, 0, 0.15);
  background-color: ${COLOR.white};
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const CarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 10px;
`;

export const CarHeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

export const CarTitle = styled.p`
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
`;

export const CarModel = styled.p`
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
`;

export const CarInfoGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const CarInfoSubTitle = styled.p`
  color: rgba(0, 0, 0, 0.5);
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
`;

export const CarInfoValue = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
`;
