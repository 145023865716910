import { baseApi } from 'shared/api';

import { getEndpointPath } from 'shared/lib';

import {
  AddConnectorInstructionFormRequest,
  ConnectorInstructionBaseResponse,
  ConnectorInstructionsBaseResponse,
  GetConnectorInstructionParams,
  UpdateConnectorInstructionFormRequest,
} from '../model';

import { apiSlice } from '../../../redux/services/apiSlice';
import { prepareGetConnectorInstructionParams } from '../lib';
import { PLATFORM_SERVICE } from 'entities/authorization';

const KEY = 'Instruction';

const getUrl = getEndpointPath(PLATFORM_SERVICE, KEY);

// TODO: замена на baseApi

export const instructionsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getConnectorInstructions: builder.query<
      ConnectorInstructionsBaseResponse,
      void
    >({
      query: () => getUrl('GetConnectorInstructions'),
      providesTags: ['ConnectorInstructions'],
    }),
    getConnectorInstruction: builder.query<
      ConnectorInstructionBaseResponse,
      GetConnectorInstructionParams
    >({
      query: (params) => ({
        url: getUrl(`GetConnectorInstruction`),
        params: prepareGetConnectorInstructionParams(params),
      }),
      providesTags: (result, error, arg) => [
        { type: 'ConnectorInstruction', id: arg.connectorType },
      ],
    }),
    addConnectorInstruction: builder.mutation<
      ConnectorInstructionBaseResponse,
      AddConnectorInstructionFormRequest
    >({
      query: (connectorInstruction) => ({
        url: getUrl('AddConnectorInstruction'),
        method: 'POST',
        body: connectorInstruction,
      }),
      invalidatesTags: ['ConnectorInstructions'],
    }),
    updateConnectorInstruction: builder.mutation<
      ConnectorInstructionBaseResponse,
      UpdateConnectorInstructionFormRequest
    >({
      query: (connectorInstruction) => ({
        url: getUrl('UpdateConnectorInstruction'),
        method: 'PATCH',
        body: connectorInstruction,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'ConnectorInstruction', id: arg.connectorType },
        'ConnectorInstructions',
      ],
    }),
    deleteConnectorInstruction: builder.mutation<ResponseBase, string>({
      query: (connectorInstructionId) => ({
        url: getUrl(`DeleteConnectorInstruction`),
        params: { connectorInstructionId },
        method: 'DELETE',
      }),
      invalidatesTags: ['ConnectorInstructions'],
    }),
  }),
});

export const {
  useGetConnectorInstructionQuery,
  useGetConnectorInstructionsQuery,
  useAddConnectorInstructionMutation,
  useUpdateConnectorInstructionMutation,
  useDeleteConnectorInstructionMutation,
} = instructionsApi;
