import { Route } from 'react-router-dom';

import { SETTINGS } from 'shared/consts/routes/common';

import { SettingsSidebar } from 'widgets/sidebar/settings';
import { ChapterLayout } from 'widgets/layout/chapter';

import { AppSettingsRoutes } from './app-settings';
import { OcpiHubRoutes } from './ocpi-hub';
import { CpoRoutes } from './cpo';
import { FirmwareRoutes } from './firmware';
import { CountryRoutes } from './country';
import { CompanyRoutes } from './company';
import { BankRoutes } from './bank';
import { ConnectorInstructionRoutes } from './connector-instruction';
import { MobileAppRoutes } from './mobile-app';
import { CarModelRoutes } from './car-model';

export const SettingsRoutes = (
  <Route
    path={SETTINGS}
    element={<ChapterLayout siderbar={<SettingsSidebar />} />}
  >
    {CountryRoutes}
    {BankRoutes}
    {CompanyRoutes}
    {ConnectorInstructionRoutes}
    {AppSettingsRoutes}
    {FirmwareRoutes}
    {OcpiHubRoutes}
    {CpoRoutes}
    {MobileAppRoutes}
    {CarModelRoutes}
  </Route>
);
