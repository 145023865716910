import {
  openErrorNotification,
  openSuccessNotification,
  isErrorWithMessage,
} from 'shared/lib';

import {
  prepareLevelRequestData,
  useAddBonusLevelMutation,
  BonusLevelFormData,
  BonusLevelForm,
} from 'entities/bonus-program';

import { ADD_SUCCESS, ADD_ERROR } from '../../consts';

const initialValues: BonusLevelFormData = {
  name: '',
  level: '',
  condition: '',
  awardType: '',
  awardPercent: '0',
  awardSum: '0',
};

type Props = {
  bonusProgramId: string;
  closeModal: () => void;
};

export function AddBonusLevel({ closeModal, bonusProgramId }: Props) {
  const [trigger, { isLoading }] = useAddBonusLevelMutation();

  const handleSubmit = async (values: BonusLevelFormData) => {
    const req = {
      ...prepareLevelRequestData(values),
      bonusProgramId: Number(bonusProgramId),
    };

    try {
      const res = await trigger(req).unwrap();

      openSuccessNotification(ADD_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : ADD_ERROR;

      openErrorNotification(errorText);
    } finally {
      closeModal();
    }
  };

  return (
    <BonusLevelForm
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      loading={isLoading}
      onCancel={closeModal}
    />
  );
}
