import { useMemo } from 'react';

import { getServerErrorText } from 'shared/lib';
import { ErrorMessage, EmptyData } from 'shared/ui';

import { useGetRolesQuery } from 'entities/role';

import { RolesTableInstance } from './table';

export function ConnectedRolesTable() {
  const { data, isFetching, error } = useGetRolesQuery();

  const tableData = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.roles;
  }, [data]);

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!isFetching && !data) {
    return <EmptyData />;
  }

  return (
    <RolesTableInstance
      tableData={tableData}
      loading={isFetching}
      rowsCount={data?.roles.length}
    />
  );
}
