import { ErrorMessage, EmptyData } from 'shared/ui';

import { getServerErrorText } from 'shared/lib';

import { useGetOwnersQuery } from 'entities/owner';

import { Filters } from '../full-filters';

type Props = {
  closeModal: () => void;
};

export function ConnectedChargePointFilters({ closeModal }: Props) {
  const { data, isFetching, error } = useGetOwnersQuery();

  if (isFetching) {
    return <div style={{ padding: '10px' }}>Loading...</div>;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  if (!data) {
    return <EmptyData />;
  }

  return <Filters ownersList={data.owners} closeModal={closeModal} />;
}
