import { Checkbox } from 'antd';
import styled from 'styled-components';

import { COLOR } from 'shared/consts';

import { UiFormCheckbox } from 'shared/ui/ui-kit';

const CheckboxGroup = Checkbox.Group;

// TODO
export const FilterCheckbox = styled(UiFormCheckbox)`
  background-color: ${COLOR.white};
  height: 29px;
  display: flex;
  align-items: center;

  & .ant-checkbox {
    top: 0;
  }
`;

export const FiltersContainer = styled.div`
  display: flex;
  align-items: start; // temp
  gap: 20px;
`;

export const FiltersCheckboxGroup = styled(CheckboxGroup)`
  display: flex;
  gap: 20px;

  & .ant-checkbox-group-item {
    margin-right: 0;
  }

  & .ant-checkbox-wrapper {
    background-color: ${COLOR.white};
    border-radius: 4px;
    padding: 7px;
    font-weight: 400;
    font-size: 12px !important;
    line-height: 15px;
    color: ${COLOR.black};
    height: 29px;
    display: flex;
    align-items: center;

    & .ant-checkbox {
      top: 0;
    }

    & .ant-checkbox-inner {
      width: 15px;
      height: 15px;
    }
  }
`;

export const RedPoint = styled.div`
  width: 9px;
  height: 9px;
  background-color: ${COLOR.red};
  border: 1px solid ${COLOR.whiteSmoke};
  border-radius: 50%;
  position: absolute;
  top: -3px;
  right: -3px;
`;

export const FiltersButton = styled.button`
  width: 29px;
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: ${COLOR.white};
  border-radius: 2px;
  cursor: pointer;
  border: none;
`;
