import { Menu } from 'antd';
import { useParams } from 'react-router-dom';

import { CustomLink } from 'shared/ui';

import { ChargePointDTO } from 'entities/charge-point';

import { StatusCircle } from './styles';

type Props = {
  groupId: number;
  chargePoint: ChargePointDTO;
};

export function ChargePointMenuItem({ chargePoint, groupId }: Props) {
  const { chargePointName } = useParams() as {
    chargePointName: string;
  };

  const { name, status } = chargePoint;

  const encodedName = encodeURIComponent(name);

  const chargePointPath = `${groupId}/${encodedName}`;

  const path =
    chargePointName === undefined
      ? chargePointPath
      : location.pathname.replace(
          encodeURIComponent(chargePointName),
          encodedName
        );

  return (
    <CustomLink
      to={path}
      key={chargePointPath}
      checkIsActive={(path) => {
        const pathArr = path.split('/');

        return pathArr.includes(encodedName);
      }}
    >
      <Menu.Item key={chargePointPath} eventKey={chargePointPath}>
        <StatusCircle status={status} />
        {name}
      </Menu.Item>
    </CustomLink>
  );
}
