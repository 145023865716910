import { Tooltip } from 'antd';

import {
  StyledCard,
  ConnectorInfo,
  ConnectorIcon,
  ConnectorTypename,
  LiterBadge,
  StatusesContainer,
  ConnectorInfoHeader,
  ConnectorControls,
  ConnectorParams,
  ConnectorControlIcon,
  ConnectorMainContent,
  TariffInfoContainer,
  TariffParamGroup,
  TariffParamName,
  TariffParamValue,
  TariffParamValuePostfix,
  TariffParamValueContainer,
  ConnectorIconContainer,
  Gradient,
  SpanContainer,
} from './styles';

import {
  InformationIcon,
  LockIcon,
  StatusIcon,
  StatusOcppIcon,
} from './images';

import { renderDotSeparator, toConsumeUnit, toCurrencyUnit } from 'shared/lib';
import {
  ChargePointConnectorDto,
  CONNECTOR_FORMAT,
  CONNECTOR_OCPP_STATUS,
  CONNECTOR_OCPP_STATUS_COLOR,
  CONNECTOR_STATUS,
  CONNECTOR_STATUS_COLOR,
  CONNECTOR_TYPE_ICON,
} from 'entities/connector';
import { ChargeTariffDto } from 'entities/tariff';

import { StatusBadge } from '../status-badge';

const getChargingCostPostfix = (
  tariffType: ChargePointConnectorDto['tariff']['tariffType']
): React.ReactNode => {
  if (tariffType === 0) {
    return (
      <SpanContainer>
        <SpanContainer>кВт</SpanContainer>
        <SpanContainer>{renderDotSeparator()}</SpanContainer>
        <SpanContainer>ч</SpanContainer>
      </SpanContainer>
    );
  }

  if (tariffType === 1) {
    return <SpanContainer>мин</SpanContainer>;
  }

  return '';
};

const getTriggerName = (
  triggerType: ChargePointConnectorDto['tariff']['triggerType'],
  triggerValue: ChargePointConnectorDto['tariff']['triggerValue']
) => {
  if (triggerType === 0) {
    return '';
  }

  if (triggerType === 1) {
    return `От ${triggerValue}%`;
  }

  if (triggerType === 2) {
    return (
      <>
        Ниже
        <br />
        {toConsumeUnit(triggerValue)} кВт
      </>
    );
  }
};

type Props = {
  connector: ChargePointConnectorDto;
  actionsSlot: React.ReactNode;
  tariff: ChargeTariffDto | null;
};

export function ConnectorCard(props: Props) {
  const {
    connector: {
      type,
      typeName,
      format,
      maxPower,
      status,
      statusOcpp,
      connectorId,
      liter,
      manualBlock,
      tariff: connectorTariff,
    },
    tariff,
    actionsSlot,
  } = props;

  // const renderTariffInfo = () => {
  //   if (!tariff) {
  //     return null;
  //   }

  //   const {
  //     startFee,
  //     parkFee,
  //     tariffType,
  //     costOfQuickCharging,
  //     triggerType,
  //     triggerFee,
  //     triggerValue,
  //     bank,
  //   } = tariff;

  //   const { country } = bank;
  //   const { currencySymbol } = country;

  //   const chargingCostPostfix = getChargingCostPostfix(tariffType);

  //   const triggerName = getTriggerName(triggerType, triggerValue);

  //   const currency = <SpanContainer>{currencySymbol}</SpanContainer>;

  //   return (
  //     <TariffInfoContainer>
  //       <TariffParamGroup>
  //         <TariffParamName>Старт</TariffParamName>
  //         <TariffParamValueContainer>
  //           <TariffParamValue>{toCurrencyUnit(startFee)}</TariffParamValue>
  //           <TariffParamValuePostfix>{currency}</TariffParamValuePostfix>
  //         </TariffParamValueContainer>
  //       </TariffParamGroup>

  //       <TariffParamGroup>
  //         <TariffParamName>Заряд</TariffParamName>
  //         <TariffParamValueContainer>
  //           <TariffParamValue>
  //             {toCurrencyUnit(costOfQuickCharging)}
  //           </TariffParamValue>
  //           <TariffParamValuePostfix>
  //             {currency}
  //             {'/'}
  //             {chargingCostPostfix}
  //           </TariffParamValuePostfix>
  //         </TariffParamValueContainer>
  //       </TariffParamGroup>

  //       {triggerType !== 0 ? (
  //         <TariffParamGroup>
  //           <TariffParamName>{triggerName}</TariffParamName>
  //           <TariffParamValueContainer>
  //             <TariffParamValue>{toCurrencyUnit(triggerFee)}</TariffParamValue>
  //             <TariffParamValuePostfix>
  //               {currency}
  //               <SpanContainer>/мин</SpanContainer>
  //             </TariffParamValuePostfix>
  //           </TariffParamValueContainer>
  //         </TariffParamGroup>
  //       ) : null}

  //       <TariffParamGroup>
  //         <TariffParamName>
  //           После <br /> заряда
  //         </TariffParamName>
  //         <TariffParamValueContainer>
  //           <TariffParamValue>{toCurrencyUnit(parkFee)}</TariffParamValue>
  //           <TariffParamValuePostfix>
  //             {currency}
  //             <SpanContainer>/мин</SpanContainer>
  //           </TariffParamValuePostfix>
  //         </TariffParamValueContainer>
  //       </TariffParamGroup>
  //     </TariffInfoContainer>
  //   );
  // };

  const renderConnectorParams = () => {
    return (
      <>
        {`${maxPower} кВт`}
        {renderDotSeparator()}
        {CONNECTOR_FORMAT[format]}
        {renderDotSeparator()}
        {`ID ${connectorId}`}
      </>
    );
  };

  const informationIcon =
    connectorTariff && connectorTariff.id !== tariff?.id ? (
      <Tooltip
        title={
          <>
            <p>Тариф отличается от стандартного.</p>
            <p>Подробнее в настройках тарифа</p>
          </>
        }
      >
        <ConnectorControlIcon src={InformationIcon} />
      </Tooltip>
    ) : null;

  const manualBlockIcon = manualBlock ? (
    <Tooltip title="Коннектор заблокирован вручную">
      <ConnectorControlIcon src={LockIcon} />
    </Tooltip>
  ) : null;

  return (
    <StyledCard>
      <ConnectorInfo>
        <ConnectorInfoHeader>
          <ConnectorTypename>{typeName}</ConnectorTypename>
          <ConnectorControls>
            {informationIcon}
            {manualBlockIcon}
            {actionsSlot}
          </ConnectorControls>
        </ConnectorInfoHeader>
        <ConnectorParams>{renderConnectorParams()}</ConnectorParams>
      </ConnectorInfo>

      <ConnectorMainContent>
        <ConnectorIconContainer>
          <ConnectorIcon src={CONNECTOR_TYPE_ICON[type]} />
          <Gradient />
          {liter ? <LiterBadge>{liter}</LiterBadge> : null}
        </ConnectorIconContainer>

        {/* {renderTariffInfo()} */}
      </ConnectorMainContent>

      <StatusesContainer>
        <StatusBadge
          label={CONNECTOR_OCPP_STATUS[statusOcpp]}
          bgColor={CONNECTOR_OCPP_STATUS_COLOR[statusOcpp]}
          icon={StatusOcppIcon}
          hint={`System ${CONNECTOR_OCPP_STATUS[statusOcpp]}`}
        />
        <StatusBadge
          label={CONNECTOR_STATUS[status]}
          bgColor={CONNECTOR_STATUS_COLOR[status]}
          icon={StatusIcon}
          hint={`Mobile-app ${CONNECTOR_STATUS[status]}`}
        />
      </StatusesContainer>
    </StyledCard>
  );
}
