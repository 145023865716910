import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Leaves } from 'shared/lib/types';

import { MongoReserveSessionDto } from 'entities/session';

type ObjectLeaves = Leaves<MongoReserveSessionDto>;

export type TableColumns = Extract<
  ObjectLeaves,
  | 'details.cpName'
  | 'creationTime'
  | 'endTime'
  | 'details.clientLogin'
  | 'details.usedAmount'
  | 'status'
  | 'stopReason'
  | 'details.connectorId'
>;

export type TableColumnsState = Record<TableColumns, boolean>;

const initialState: TableColumnsState = {
  'details.clientLogin': true,
  'details.connectorId': true,
  'details.cpName': true,
  'details.usedAmount': true,
  creationTime: true,
  endTime: true,
  status: true,
  stopReason: true,
};

const slice = createSlice({
  name: 'reserveSessionsTable',
  initialState,
  reducers: {
    setVisibleColumns: (state, action: PayloadAction<TableColumnsState>) => {
      return action.payload;
    },
  },
});

export const { setVisibleColumns } = slice.actions;
export default slice.reducer;
