export const ACCEPTED_IMAGE_EXTENSIONS = [
  'jpg',
  'jpeg',
  'jpe',
  'bmp',
  'gif',
  'png',
];

export const IMAGE_MAX_SIZE = 1;

export const IMAGE_EXTENSION_ERROR = 'Недопустимый формат файла';
export const IMAGE_SIZE_ERROR = 'Максимальный размер файла - 1 Мб';
