import styled from 'styled-components';

import { COLOR } from 'shared/consts';

export const TariffDetailsContainer = styled.div``;

export const TariffDetailsContent = styled.div`
  padding: 20px 20px 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  border-bottom: 1px dashed ${COLOR.grey};
`;

export const TariffDetailsParamGropTitle = styled.h4`
  color: rgba(0, 0, 0, 0.35);
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  text-transform: uppercase;
`;

export const TariffDetailsGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const TariffDetailsParamGroupData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  color: ${COLOR.black};
`;

export const TariffDetailsParamGroupDataItem = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TariffDetailsParamGroupKey = styled.div``;

export const TariffDetailsParamGroupValue = styled.div``;

export const TariffDetailsFooter = styled.div`
  padding: 30px 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const TariffDetailsSummary = styled.div`
  display: flex;
  justify-content: space-between;
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
`;

export const TariffDetailsSummaryTitle = styled.p``;

export const TariffDetailsSummaryValue = styled.p``;
