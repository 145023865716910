import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Form, Space, Spin } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { UiCheckbox } from 'shared/ui/ui-kit/checkbox';

import {
  checkControllerRight,
  selectTokenClaims,
  selectTokenRoles,
} from 'entities/authorization';

import { StyledButton } from '../../../../../common-styles';

import { FormButtonsContainer } from '../../../../FormButtons/styles';
import {
  HeaderCellContainer,
  HeaderCellRow,
  HeaderCellText,
  StyledTable,
  TableContainer,
} from '../../../../ReactTable/styles';
import { RightsFormItem } from '../UserControllerRights/styles';

export interface UserRolesTableItem {
  id: string;
  name: string;
  active: boolean;
}

const columnHelper = createColumnHelper<UserRolesTableItem>();

type Props = {
  tableData: UserRolesTableItem[];
  loading: boolean;
  handleSubmit: (values: Record<string, boolean>) => Promise<void>;
  initialValues: Record<string, boolean>;
};

export function UserRoles({
  handleSubmit,
  initialValues,
  loading,
  tableData,
}: Props) {
  const roles = useSelector(selectTokenRoles);
  const claims = useSelector(selectTokenClaims);

  const hasWriteRight = checkControllerRight(
    'Identity',
    'Write',
    roles,
    claims
  );

  const navigate = useNavigate();

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  const [form] = Form.useForm<Record<string, boolean>>();

  const columns = [
    columnHelper.accessor('name', {
      header: 'Роль',
    }),
    columnHelper.accessor('active', {
      cell: (props) => {
        const { name } = props.row.original;

        return (
          <RightsFormItem name={name} valuePropName="checked">
            <UiCheckbox />
          </RightsFormItem>
        );
      },
      header: 'Добавить',
    }),
  ];

  const table = useReactTable({
    columns,
    data: tableData,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Form form={form} onFinish={handleSubmit} initialValues={initialValues}>
      <TableContainer>
        <StyledTable>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      {...{
                        key: header.id,
                        style: {
                          width: header.getSize(),
                        },
                      }}
                    >
                      <HeaderCellContainer>
                        <HeaderCellRow>
                          <HeaderCellText>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </HeaderCellText>
                        </HeaderCellRow>
                      </HeaderCellContainer>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.original.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </TableContainer>

      {hasWriteRight ? (
        <Spin spinning={loading}>
          <FormButtonsContainer>
            <Space direction="horizontal" size={10}>
              <StyledButton htmlType="submit" type="primary">
                Сохранить
              </StyledButton>
              <StyledButton
                htmlType="button"
                type="default"
                onClick={() => navigate(-1)}
              >
                Отмена
              </StyledButton>
            </Space>
          </FormButtonsContainer>
        </Spin>
      ) : null}
    </Form>
  );
}
