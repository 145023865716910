import styled from 'styled-components';

import { COLOR } from 'shared/consts';

export const UserFormContainer = styled.div`
  min-width: 777px;
  max-width: 1024px;
`;

export const Title = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${COLOR.title};
`;

export const UserLogin = styled.span`
  font-weight: 500;
`;

export const SessionToken = styled.div`
  display: inline-block;
  padding: 10px;
  /* background-color: ${COLOR.white};
  border-radius: 4px; */
`;
