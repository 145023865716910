import { UserDto } from 'entities/user';

import { ProfileFormData } from '../model';

export const getInitialValues = (user: UserDto): ProfileFormData => {
  const { email, firstName, middleName, lastName } = user;

  return {
    email,
    firstName: firstName ?? '',
    middleName: middleName ?? '',
    lastName: lastName ?? '',
    newPassword: '',
  };
};
