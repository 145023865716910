import { useNavigate } from 'react-router-dom';

import {
  openErrorNotification,
  openSuccessNotification,
  isErrorWithMessage,
} from 'shared/lib';

import {
  CompanyForm,
  CompanyFormData,
  prepareRequestData,
  useAddCompanyMutation,
} from 'entities/company';

import { ADD_SUCCESS, ADD_ERROR } from '../consts';
import { COMPANY_ROUTES } from 'shared/consts/routes/company';

const initialValues: CompanyFormData = {
  id: '',
  name: '',
  legalName: '',
  login: '',
  clientPassword: '',
  email: '',
  contractData: '',
  address: '',
  phoneNumber: '',
  chargingLimitBalance: '0',
  chargingLimit: '0',
  inn: '',
  kpp: '',
};

export function AddCompany() {
  const [trigger, { isLoading }] = useAddCompanyMutation();

  const navigate = useNavigate();

  const handleSubmit = async (values: CompanyFormData) => {
    const { id, ...req } = prepareRequestData(values);

    try {
      const res = await trigger(req).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);

        return;
      }
      //

      openSuccessNotification(ADD_SUCCESS);

      navigate(`${COMPANY_ROUTES.COMPANIES}/${res.company.id}`);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : ADD_ERROR;

      openErrorNotification(errorText);
    }
  };

  return (
    <CompanyForm
      title="Добавление нового контрагента"
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      loading={isLoading}
    />
  );
}
