import {
  createColumnHelper,
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  Row,
  useReactTable,
} from '@tanstack/react-table';
import { Form, Space, Spin } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { UiCheckbox } from 'shared/ui/ui-kit/checkbox';
import { ExpandRowIcon } from 'shared/ui/expand-row-icon';
import { RightsTable } from 'shared/ui/table';

import {
  checkControllerRight,
  selectTokenClaims,
  selectTokenRoles,
} from 'entities/authorization';

import { StyledButton } from '../../../../common-styles';

import { FormButtonsContainer } from '../../../FormButtons/styles';

import { DELIMETER, CP_PREFIX, GROUP_PREFIX } from '../../constants';
import { ChargePointRightTableItem } from '../../types';
import {
  AllCpRightsContainer,
  GroupColumnLabel,
  RightsFormItem,
} from './styles';

const columnHelper = createColumnHelper<ChargePointRightTableItem>();

interface ChargePointRightsFormProps {
  allChecked: boolean;
  onAllCheckedChange: (e: CheckboxChangeEvent) => void;
  onClickRow: (row: Row<ChargePointRightTableItem>) => Promise<void>;
  handleSubmit: (
    values: Record<string, boolean>,
    allChecked: boolean
  ) => Promise<void>;
  isRowLoading: Record<string, boolean>;
  isRowLoaded: {};
  initialValues: Record<string, boolean>;
  tableData: ChargePointRightTableItem[];
  loading: boolean;
}

export function ChargePointRightsForm({
  allChecked,
  onAllCheckedChange,
  onClickRow,
  handleSubmit,
  isRowLoaded,
  isRowLoading,
  initialValues,
  tableData,
  loading,
}: ChargePointRightsFormProps) {
  const roles = useSelector(selectTokenRoles);
  const claims = useSelector(selectTokenClaims);

  const hasWriteRight = checkControllerRight(
    'Identity',
    'Write',
    roles,
    claims
  );

  const [expanded, setExpanded] = useState<ExpandedState>({});

  const navigate = useNavigate();

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  useEffect(() => {
    table.toggleAllRowsExpanded(false);
  }, [initialValues]);

  const [form] = Form.useForm<Record<string, boolean>>();

  const columns = [
    columnHelper.accessor('name', {
      header: 'ЭЗС',
      cell: (props) => {
        const { row } = props;

        const isLoading = Boolean(isRowLoading[row.id]);
        const isExpanded = Boolean(expanded[row.id]);

        const onClick = async (_event: React.MouseEvent<HTMLDivElement>) => {
          if (!isLoading) {
            const shouldFetch =
              !isExpanded &&
              !(row.id in isRowLoading) &&
              !(row.id in isRowLoaded);

            if (shouldFetch) {
              await onClickRow(row);
            }

            row.toggleExpanded();
          }
        };

        return (
          <div
            {...{
              style: {
                paddingLeft: `${row.depth * 2 * 10}px`,
              },
            }}
            {...(row.original.isGroup ? { onClick } : {})}
          >
            <Spin spinning={isLoading}>
              <GroupColumnLabel>
                {props.row.original.name}
                {props.row.original.isGroup ? (
                  row.getIsExpanded() ? (
                    <ExpandRowIcon isOpen />
                  ) : (
                    <ExpandRowIcon />
                  )
                ) : null}
              </GroupColumnLabel>
            </Spin>
          </div>
        );
      },
    }),
    columnHelper.accessor('read', {
      header: 'Чтение',
      cell: (props) => {
        if (props.row.original.isGroup) {
          return (
            <RightsFormItem
              name={`${GROUP_PREFIX}${DELIMETER}${props.row.original.name}${DELIMETER}read`}
              valuePropName="checked"
            >
              <UiCheckbox disabled={allChecked} />
            </RightsFormItem>
          );
        }

        return (
          <RightsFormItem
            name={`${CP_PREFIX}${DELIMETER}${props.row.original.id}${DELIMETER}read`}
            valuePropName="checked"
          >
            <UiCheckbox disabled={allChecked} />
          </RightsFormItem>
        );
      },
    }),
    columnHelper.accessor('write', {
      header: 'Запись',
      cell: (props) => {
        if (props.row.original.isGroup) {
          return (
            <RightsFormItem
              name={`${GROUP_PREFIX}${DELIMETER}${props.row.original.name}${DELIMETER}write`}
              valuePropName="checked"
            >
              <UiCheckbox disabled={allChecked} />
            </RightsFormItem>
          );
        }

        return (
          <RightsFormItem
            name={`${CP_PREFIX}${DELIMETER}${props.row.original.id}${DELIMETER}write`}
            valuePropName="checked"
          >
            <UiCheckbox disabled={allChecked} />
          </RightsFormItem>
        );
      },
    }),
    columnHelper.accessor('execute', {
      header: 'Выполнение',
      cell: (props) => {
        if (props.row.original.isGroup) {
          return (
            <RightsFormItem
              name={`${GROUP_PREFIX}${DELIMETER}${props.row.original.name}${DELIMETER}execute`}
              valuePropName="checked"
            >
              <UiCheckbox disabled={allChecked} />
            </RightsFormItem>
          );
        }

        return (
          <RightsFormItem
            name={`${CP_PREFIX}${DELIMETER}${props.row.original.id}${DELIMETER}execute`}
            valuePropName="checked"
          >
            <UiCheckbox disabled={allChecked} />
          </RightsFormItem>
        );
      },
    }),
  ];

  const table = useReactTable({
    columns,
    data: tableData,
    state: {
      expanded,
    },
    getSubRows: (row) => row.subRows,
    onExpandedChange: setExpanded,
    autoResetExpanded: false,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  const onFinish = (_values: Record<string, boolean>) => {
    const formValues = form.getFieldsValue(true);

    handleSubmit(formValues, allChecked);
  };

  return (
    <>
      <AllCpRightsContainer>
        <UiCheckbox checked={allChecked} onChange={onAllCheckedChange}>
          Доступ ко всем ЭЗС
        </UiCheckbox>
      </AllCpRightsContainer>
      <Form form={form} onFinish={onFinish} initialValues={initialValues}>
        <RightsTable table={table} />
        {hasWriteRight ? (
          <Spin spinning={loading}>
            <FormButtonsContainer>
              <Space direction="horizontal" size={10}>
                <StyledButton htmlType="submit" type="primary">
                  Сохранить
                </StyledButton>
                <StyledButton
                  htmlType="button"
                  type="default"
                  onClick={() => navigate(-1)}
                >
                  Отмена
                </StyledButton>
              </Space>
            </FormButtonsContainer>
          </Spin>
        ) : null}
      </Form>
    </>
  );
}
