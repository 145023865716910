import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Leaves } from 'shared/lib/types';

import { PersonDto } from 'entities/person';

type ObjectLeaves = Leaves<PersonDto>;

export type TableColumns = Extract<
  ObjectLeaves,
  | 'registrationDate'
  | 'login'
  | 'email'
  | 'userAgreementFlag'
  | 'isBankCardAttached'
  | 'clientBalance'
  | 'clientsDebt'
  | 'commentary'
>;

export type TableColumnsState = Record<TableColumns, boolean>;

const initialState: TableColumnsState = {
  registrationDate: true,
  login: true,
  email: true,
  userAgreementFlag: true,
  isBankCardAttached: true,
  clientBalance: true,
  clientsDebt: true,
  commentary: true,
};

const slice = createSlice({
  name: 'personsTable',
  initialState,
  reducers: {
    setVisibleColumns: (state, action: PayloadAction<TableColumnsState>) => {
      return action.payload;
    },
  },
});

export const { setVisibleColumns } = slice.actions;
export default slice.reducer;
