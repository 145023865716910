import { NavigationTab } from 'shared/model';

export const TABS: Array<NavigationTab> = [
  {
    key: '',
    path: '',
    label: 'Редактирование группы',
  },
  {
    key: 'mobile-apps-bindings',
    label: 'Привязка приложения',
    path: `mobile-apps-bindings`,
    controller: 'MobileApp',
    right: 'Execute',
  },
];
