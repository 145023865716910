import NiceModal from '@ebay/nice-modal-react';

import { UploadedImage } from 'shared/ui';

import { ChargePointPhotoBaseResponse } from 'entities/charge-point/model/charge-point-api';
import { getPhotoUrl } from 'entities/charge-point-photo';

import { DeleteChargePointPhotoModal } from 'features/charge-point-photo/delete';
import { EditChargePointPhotoModal } from 'features/charge-point-photo/edit/ui/modal';

import { PhotoListContainer } from './styles';

type Props = {
  photos: ChargePointPhotoBaseResponse['photo'];
  chargePointName: string;
};

export function ChargePointPhotoList({ photos, chargePointName }: Props) {
  const getEditPhotoPriority = (photoId: string) => {
    const photoToEdit = photos.find((photo) => String(photo.id) === photoId);

    return photoToEdit ? String(photoToEdit.priority) : '';
  };

  const showDeleteModal = (photoId: number) => {
    NiceModal.show(DeleteChargePointPhotoModal, {
      cpName: chargePointName,
      photoId,
    });
  };

  const showEditModal = (photoId: string) => {
    NiceModal.show(EditChargePointPhotoModal, {
      chargePointName,
      photoId,
      priority: getEditPhotoPriority(photoId),
    });
  };

  return (
    <>
      {photos.length === 0 ? 'Нет фотографий' : ''}
      <PhotoListContainer>
        {photos.map(({ baseAddress, url, fileName, id }) => (
          <UploadedImage
            key={id}
            imageSrc={getPhotoUrl(baseAddress, url)}
            imageName={fileName}
            handleEdit={() => showEditModal(String(id))}
            handleDelete={() => showDeleteModal(id)}
          />
        ))}
      </PhotoListContainer>
    </>
  );
}
