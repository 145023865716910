import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { UiModal } from 'shared/ui';

import { ChargePointConnectorDto } from 'entities/connector';

import { EditConnector } from '../edit';

type Props = {
  connector: ChargePointConnectorDto;
  chargePointId: string;
  chargePointName: string;
};

export const EditConnectorModal = NiceModal.create((props: Props) => {
  const modal = useModal();

  return (
    <UiModal
      title="Редактировать коннектор"
      visible={modal.visible}
      onCancel={modal.hide}
      afterClose={modal.remove}
    >
      <EditConnector {...props} closeModal={modal.hide} />
    </UiModal>
  );
});
