import { useParams } from 'react-router-dom';

import { usePersonFetchingContext } from 'entities/person/person-layout';

import { ConnectedReservesTable } from '../../../widgets/session/reserve-sessions-table/ui/connected-table';

export function PersonReservesPage() {
  const { userLogin, groupId } = useParams() as {
    groupId: string;
    userLogin: string;
  };

  const { handleSetFetchingTab } = usePersonFetchingContext();

  return (
    <ConnectedReservesTable
      userLogin={userLogin}
      handleSetFetchingTab={handleSetFetchingTab}
    />
  );
}
