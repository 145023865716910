import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/ru_RU';
import { RadioChangeEvent } from 'antd/lib/radio/interface';
import { Spin } from 'antd';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import { EmptyData, ErrorMessage, RangePickerProps } from 'shared/ui';
import { addSpacesToNumber, getServerErrorText } from 'shared/lib';
import { DATE_RANGE_OPTION } from 'shared/lib/metrics';

import { getMetricsLineChartData } from 'entities/metrics';
import { GraphMetricsResponse } from 'entities/metrics/api/types';

import {
  StyledChargedSummaryCard,
  ChargedSummaryCardHeader,
  ChargedSummaryTitle,
  LineChartContainer,
  StyleDateRangeGroup,
  StyledRadioButton,
  StyledRangePicker,
  ChargedInfoContainer,
  ValueSummary,
  ChargedSummaryCardContent,
  LoaderContainer,
} from './styles';

import { LineChart } from '../../../../../shared/ui/charts/line';

import { customFormat } from '../../../../../constants/picker';
import {
  colorParams,
  scalesOptions,
} from '../../../../../components/ChargePoint/components/ChargedSummary/constants';
import { ArrowDownIcon } from 'shared/icons/arrow-down';

type Props = {
  loading: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
  data: GraphMetricsResponse | undefined;
  dateFrom: dayjs.Dayjs;
  dateTo: dayjs.Dayjs;
  dateRangeOption: string;
  onRadioGroupChange: (e: RadioChangeEvent) => void;
  onChange: (values: RangePickerProps['value']) => void;
  onOpenChange: (open: boolean) => void;
};

export function ChargedTotal({
  data,
  error,
  loading,
  dateFrom,
  dateTo,
  dateRangeOption,
  onRadioGroupChange,
  onChange,
  onOpenChange,
}: Props) {
  const renderDateRangeOptions = () =>
    Object.keys(DATE_RANGE_OPTION).map((el) => {
      return (
        <StyledRadioButton key={el} value={el}>
          {DATE_RANGE_OPTION[el].label}
        </StyledRadioButton>
      );
    });

  let content;

  if (loading) {
    content = (
      <LoaderContainer>
        <Spin spinning>Загрузка данных...</Spin>
      </LoaderContainer>
    );
  } else if (error) {
    content = <ErrorMessage text={getServerErrorText(error)} />;
  } else if (data && data.statusCode !== 0) {
    content = <ErrorMessage text={data.statusDescription} />;
  } else if (!data) {
    content = <EmptyData />;
  } else {
    const chartData = getMetricsLineChartData(data.graphMetrics, colorParams);

    const valueSummaryInfo = addSpacesToNumber(
      Math.round(data.graphMetrics.reduce((acc, el) => acc + el.value, 0))
    );

    content = (
      <>
        <ChargedSummaryCardHeader>
          <ChargedSummaryTitle>Заряжено в кВт*ч</ChargedSummaryTitle>
          <StyleDateRangeGroup
            value={dateRangeOption}
            onChange={onRadioGroupChange}
          >
            {renderDateRangeOptions()}
          </StyleDateRangeGroup>
        </ChargedSummaryCardHeader>

        <ChargedInfoContainer>
          <ValueSummary>{valueSummaryInfo}</ValueSummary>
          <StyledRangePicker
            locale={locale}
            separator={'-'}
            allowClear={false}
            suffixIcon={<ArrowDownIcon />}
            value={[dateFrom, dateTo]}
            format={customFormat}
            onChange={onChange}
            onOpenChange={onOpenChange}
          />
        </ChargedInfoContainer>
        <LineChartContainer>
          <LineChart data={chartData} scaleOptions={scalesOptions} />
        </LineChartContainer>

        {/* <ChargedSummaryCardContent>
          <ChargedInfoContainer>
            <ValueSummary>{valueSummaryInfo}</ValueSummary>
            <StyledRangePicker
              locale={locale}
              separator={'-'}
              allowClear={false}
            suffixIcon={<ArrowDownIcon />}
              value={[dateFrom, dateTo]}
              format={customFormat}
              onChange={onChange}
              onOpenChange={onOpenChange}
            />
          </ChargedInfoContainer>
          <LineChartContainer>
            <LineChart data={chartData} scaleOptions={scalesOptions} />
          </LineChartContainer>
        </ChargedSummaryCardContent> */}
      </>
    );
  }

  return <StyledChargedSummaryCard>{content}</StyledChargedSummaryCard>;
}
