import { Route } from 'react-router-dom';

import {
  CHARGE_POINTS,
  EVENTS,
  STATUSES,
} from 'shared/consts/routes/charge-point';
import {
  ADD_PREFIX,
  GROUP,
  GROUPS,
  MANUAL_COMMANDS,
  RESERVES,
  SESSION_LOG,
} from 'shared/consts/routes/common';

import { MainContentLayout, SectionLayout } from 'shared/ui/layout';

import { RequireAuth } from 'entities/authorization';

import { AddChargePoint, EditChargePoint } from '.';
import { ConnectedChargePointGroupAppBindings } from '../GroupUpdated';

import { EditChargePointsGroupPage } from 'pages/group';
import {
  ChargePointCommandsPage,
  ChargePointEventsPage,
  ChargePointInfoPage,
  ChargePointListPage,
  ChargePointReservesPage,
  ChargePointsCommandsPage,
  ChargePointSessionLogPage,
  ChargePointsGroupCommandsPage,
  ChargePointsGroupListPage,
  ChargePointsGroupReservesPage,
  ChargePointsGroupSessionLogPage,
  ChargePointsGroupsListPage,
  ChargePointsReservesPage,
  ChargePointsSessionLogPage,
  ChargePointStatusesPage,
} from 'pages/charge-point';

import { ChargePointsLayout } from 'entities/charge-point/charge-points-layout';
import { ChargePointsGroupLayout } from 'entities/charge-point/charge-points-group-layout';
import { ChargePointLayout } from 'entities/charge-point/charge-point-layout/ui/layout';
import { ChargePointGroupLayout } from 'entities/charge-point/group-layout';
import { ConnectedChargePointsMenu } from 'features/charge-point/menu';
import { AddChargePointsGroup } from 'features/charge-points-group/add';

export const ChargePointRoutes = (
  <Route
    element={<RequireAuth controllerName="ChargePoint" rightType="Read" />}
  >
    <Route
      path={CHARGE_POINTS}
      element={<SectionLayout menu={<ConnectedChargePointsMenu />} />}
    >
      <Route element={<ChargePointsLayout />}>
        <Route index element={<ChargePointListPage />} />
        <Route
          element={<RequireAuth controllerName="Group" rightType="Write" />}
        >
          <Route path={GROUPS} element={<ChargePointsGroupsListPage />} />
        </Route>
        <Route
          element={
            <RequireAuth controllerName="PrivateMethods" rightType="Read" />
          }
        >
          <Route path={SESSION_LOG} element={<ChargePointsSessionLogPage />} />
        </Route>
        <Route
          element={
            <RequireAuth controllerName="PrivateMethods" rightType="Read" />
          }
        >
          <Route path={RESERVES} element={<ChargePointsReservesPage />} />
        </Route>
        <Route
          element={
            <RequireAuth controllerName="PrivateMethods" rightType="Read" />
          }
        >
          <Route
            path={MANUAL_COMMANDS}
            element={<ChargePointsCommandsPage />}
          />
        </Route>
      </Route>

      <Route
        element={<RequireAuth controllerName="ChargePoint" rightType="Write" />}
      >
        <Route path={ADD_PREFIX} element={<AddChargePoint />} />
      </Route>

      <Route element={<RequireAuth controllerName="Group" rightType="Write" />}>
        <Route path={`groups/:groupId`} element={<ChargePointGroupLayout />}>
          <Route index element={<EditChargePointsGroupPage />} />

          <Route
            element={
              <RequireAuth controllerName="MobileApp" rightType="Execute" />
            }
          >
            <Route
              path={'mobile-apps-bindings'}
              element={<ConnectedChargePointGroupAppBindings />}
            />
          </Route>
        </Route>
      </Route>

      <Route element={<RequireAuth controllerName="Group" rightType="Write" />}>
        <Route
          path={`${GROUPS}/${ADD_PREFIX}`}
          element={<MainContentLayout element={<AddChargePointsGroup />} />}
        />
      </Route>

      <Route element={<RequireAuth controllerName="Group" rightType="Read" />}>
        <Route path={GROUP} element={<ChargePointsGroupLayout />}>
          <Route index element={<ChargePointsGroupListPage />} />
          <Route
            element={
              <RequireAuth controllerName="PrivateMethods" rightType="Read" />
            }
          >
            <Route
              path={SESSION_LOG}
              element={<ChargePointsGroupSessionLogPage />}
            />
          </Route>
          <Route
            element={
              <RequireAuth controllerName="PrivateMethods" rightType="Read" />
            }
          >
            <Route
              path={RESERVES}
              element={<ChargePointsGroupReservesPage />}
            />
          </Route>
          <Route
            element={
              <RequireAuth controllerName="PrivateMethods" rightType="Read" />
            }
          >
            <Route
              path={MANUAL_COMMANDS}
              element={<ChargePointsGroupCommandsPage />}
            />
          </Route>
        </Route>
      </Route>

      {/* Рауты отдельной ЭЗС */}
      <Route path={':groupId/:chargePointName'} element={<ChargePointLayout />}>
        <Route index element={<ChargePointInfoPage />} />
        <Route
          element={
            <RequireAuth controllerName="PrivateMethods" rightType="Read" />
          }
        >
          <Route path={SESSION_LOG} element={<ChargePointSessionLogPage />} />
        </Route>
        <Route
          element={
            <RequireAuth controllerName="PrivateMethods" rightType="Read" />
          }
        >
          <Route path={RESERVES} element={<ChargePointReservesPage />} />
        </Route>
        <Route
          element={
            <RequireAuth controllerName="PrivateMethods" rightType="Read" />
          }
        >
          <Route path={EVENTS} element={<ChargePointEventsPage />} />
        </Route>
        <Route
          element={
            <RequireAuth controllerName="PrivateMethods" rightType="Read" />
          }
        >
          <Route path={MANUAL_COMMANDS} element={<ChargePointCommandsPage />} />
        </Route>
        <Route
          element={
            <RequireAuth controllerName="PrivateMethods" rightType="Read" />
          }
        >
          <Route path={STATUSES} element={<ChargePointStatusesPage />} />
        </Route>
      </Route>

      <Route
        element={<RequireAuth controllerName="ChargePoint" rightType="Write" />}
      >
        <Route
          path=":groupId/:chargePointName/edit"
          element={<EditChargePoint />}
        />
      </Route>
    </Route>
  </Route>
);
