import { TariffType, TriggerType } from '../model/tariff';

export const TARIFF_TYPE: Record<TariffType, string> = {
  0: 'По киловаттам',
  1: 'По минутам',
};

export const TRIGGER_TYPE: Record<TriggerType, string> = {
  0: 'Без переключения',
  1: '% заряда батареи',
  2: 'Падение мощности до Х',
};
