import { PageSize } from '../page-size';
import { PageSizeLabel } from '../page-size/styles';
import { PaginationControls } from '../pagination-controls';
import { PaginationJumper } from '../pagination-jumper';
import { PaginationTotalCount } from '../pagination-total-count';

import { PageControlsContainer, PaginationContainer } from './styles';

type Props = {
  prevDisabled: boolean;
  nextDisabled: boolean;
  onPrevClick: () => void;
  onNextClick: () => void;
  handlePressEnter: (val: number) => void;
  pagesCount: number;
  rowsCount: number | undefined;
  jumperValue: string;
  pageSize: number;
  handlePageSizePressEnter: (val: number) => void;
};

export function Pagination({
  prevDisabled,
  nextDisabled,
  onPrevClick,
  onNextClick,
  handlePressEnter,
  pagesCount,
  rowsCount,
  jumperValue,
  pageSize,
  handlePageSizePressEnter,
}: Props) {
  return (
    <PaginationContainer>
      <PageSize
        size={pageSize}
        handleEnterPress={handlePageSizePressEnter}
        count={rowsCount}
      />
      <PageControlsContainer>
        <PageSizeLabel>Страница</PageSizeLabel>
        <PaginationJumper
          jumperValue={jumperValue}
          handlePressEnter={handlePressEnter}
        />
        <PaginationTotalCount count={pagesCount} />
        <PaginationControls
          prevDisabled={prevDisabled}
          nextDisabled={nextDisabled}
          onPrevClick={onPrevClick}
          onNextClick={onNextClick}
        />
      </PageControlsContainer>
    </PaginationContainer>
  );
}
