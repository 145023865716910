import Excel from 'exceljs';

const MINIMAL_WIDTH = 10;
const ADDED_VALUE = 5;

export const setColumnsAutoWidth = (
  worksheet: Excel.Worksheet,
  columnsStartIdx: number,
  minimalWidth = MINIMAL_WIDTH
) => {
  worksheet.columns.forEach((column) => {
    let maxColumnLength = 0;

    if (column.eachCell) {
      column.eachCell({ includeEmpty: true }, (cell) => {
        if (Number(cell.row) > columnsStartIdx) {
          maxColumnLength = Math.max(
            maxColumnLength,
            minimalWidth,
            cell.value ? cell.value.toString().length : 0
          );
        }
      });
    }

    const calculatedWIdth = maxColumnLength + ADDED_VALUE;

    if (!column.width || (column.width && calculatedWIdth > column.width)) {
      column.width = calculatedWIdth;
    }
  });
};
