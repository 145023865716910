import { v4 as uuidv4 } from 'uuid';

import {
  openSuccessNotification,
  openErrorNotification,
  toCurrencySubunit,
} from 'shared/lib';

import {
  useAccrualManualMutation,
  usePayManualMutation,
} from 'entities/balance';
import { UpdateBonusBalance } from 'entities/balance';
import { BankListItem } from 'entities/bank';

import { BalanceForm } from '../form';
import { BalanceFormData } from '../../model';
import { UPDATE_ERROR, UPDATE_SUCCESS } from '../../consts';

type Props = {
  onCancel: () => void;
  personId: string;
  banks: Array<BankListItem>;
  bankId?: number;
};

export function ConnectedBalanceForm({
  onCancel,
  personId,
  banks,
  bankId,
}: Props) {
  const [payTrigger, { isLoading: isPayLoading }] = usePayManualMutation();

  const [accrualTrigger, { isLoading: isAccrualLoading }] =
    useAccrualManualMutation();

  const handleSubmit = async (values: BalanceFormData) => {
    const { bonusSum, description, operation, bankId } = values;

    const req: UpdateBonusBalance = {
      personId,
      bonusSum: toCurrencySubunit(Number(bonusSum)),
      description,
      bankId: Number(bankId),
      transactionId: uuidv4(),
    };

    const trigger = operation === '0' ? payTrigger : accrualTrigger;

    try {
      const res = await trigger(req).unwrap();

      openSuccessNotification(UPDATE_SUCCESS);

      onCancel();
    } catch (err) {
      openErrorNotification(UPDATE_ERROR);
    }
  };

  const initialValues = {
    bonusSum: '0',
    description: '',
    operation: '1',
    bankId: bankId !== undefined ? String(bankId) : '',
  };

  return (
    <BalanceForm
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      loading={isPayLoading || isAccrualLoading}
      onCancel={onCancel}
      banks={banks}
    />
  );
}
