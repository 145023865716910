import { DeleteOutlined } from '@ant-design/icons';

import { COLOR } from 'shared/consts';

import {
  ErrorText,
  FileContainer,
  Filename,
  ControlsContainer,
} from './styles';

type Props = {
  fileName: string;
  handleDelete: () => void;
  errors?: string[];
};

export function UploadedFile({ fileName, handleDelete, errors = [] }: Props) {
  const isError = errors.length > 0;

  const deleteIconColor = isError ? COLOR.red : COLOR.black;

  return (
    <>
      {errors.length > 0
        ? errors.map((error) => <ErrorText key={error}>{error}</ErrorText>)
        : null}
      <FileContainer isError={isError}>
        <Filename>{fileName}</Filename>
        <ControlsContainer>
          <DeleteOutlined
            onClick={handleDelete}
            style={{ color: deleteIconColor }}
          />
        </ControlsContainer>
      </FileContainer>
    </>
  );
}
