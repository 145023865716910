import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useState } from 'react';

import { TableColumn } from 'shared/model/table';
import {
  ButtonsContainer,
  UiSaveButton,
  UiCancelButton,
} from 'shared/ui/buttons';
import { UiDivider } from 'shared/ui/ui-kit';
import { UiFormCheckbox } from 'shared/ui/ui-kit/checkbox';

import { VisibleColumnsGrid, Wrapper } from './styles';

type Props = {
  columns: TableColumn[];
  onConfirm: (columns: TableColumn[]) => void;
  closeModal: () => void;
};

export function VisibleColumnsForm({ columns, onConfirm, closeModal }: Props) {
  const [cols, setCols] = useState(columns);

  const onChange = (e: CheckboxChangeEvent) =>
    setCols((cols) =>
      cols.map((el) =>
        el.key === e.target.name ? { ...el, isChecked: !el.isChecked } : el
      )
    );

  const handleConfirm = () => {
    onConfirm(cols);

    closeModal();
  };

  return (
    <Wrapper>
      <VisibleColumnsGrid>
        {cols.map(({ label, key, isChecked }) => (
          <UiFormCheckbox
            name={key}
            checked={isChecked}
            onChange={onChange}
            key={key}
          >
            {label}
          </UiFormCheckbox>
        ))}
      </VisibleColumnsGrid>
      <UiDivider />
      <ButtonsContainer>
        <UiSaveButton onClick={handleConfirm} />
        <UiCancelButton onClick={closeModal} />
      </ButtonsContainer>
    </Wrapper>
  );
}
