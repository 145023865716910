import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Form, Space, Spin } from 'antd';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormButtonsContainer, StyledButton } from '../../../../common-styles';

import { Group } from 'entities/group';
import { MobileAppDto } from 'entities/mobile-app';

// import { RightsFormItem } from '../../../../Security/components/ChargePointRightsForm/styles';

import { RightsFormItem } from '../../../Security/components/ChargePointRightsForm/styles';

import { RightsTablesContainer } from './styles';
import { UiCheckbox } from 'shared/ui/ui-kit/checkbox';
import { RightsTable } from 'shared/ui/table';

export interface ChargePointGroupAppBindingsPropsTableItem {
  id: string;
  name: string;
}

const columnHelper =
  createColumnHelper<ChargePointGroupAppBindingsPropsTableItem>();

type Props = {
  handleSubmit: (values: Record<string, boolean>) => Promise<void>;
  mobileApps: MobileAppDto[];
  group: Group;
  loading: boolean;
};

export function ChargePointGroupAppBindings({
  group,
  mobileApps,
  loading,
  handleSubmit,
}: Props) {
  const navigate = useNavigate();

  const [form] = Form.useForm<Record<string, boolean>>();

  const getInitialValues = () => {
    return mobileApps.reduce((acc, el) => {
      acc[el.id] = Boolean(group.mobileApps.find((app) => app.id === el.id));

      return acc;
    }, {} as Record<string, boolean>);
  };

  const getTableData = () => {
    return mobileApps.map(({ id, name }) => ({
      name,
      id: String(id),
    }));
  };

  const tableData = useMemo(() => getTableData(), [mobileApps]);

  const initialValues = useMemo(() => getInitialValues(), [group]);

  const columns = [
    columnHelper.accessor('name', {
      header: 'Мобильное приложение',
    }),
    columnHelper.accessor('id', {
      cell: (props) => {
        const { id } = props.row.original;

        return (
          <RightsFormItem name={id} valuePropName="checked">
            <UiCheckbox />
          </RightsFormItem>
        );
      },
      header: 'Добавить',
    }),
  ];

  const table = useReactTable({
    data: tableData,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Form form={form} onFinish={handleSubmit} initialValues={initialValues}>
      <RightsTablesContainer>
        <RightsTable table={table} />
      </RightsTablesContainer>

      <Spin spinning={loading}>
        <FormButtonsContainer>
          <Space direction="horizontal" size={10}>
            <StyledButton htmlType="submit" type="primary">
              Сохранить
            </StyledButton>
            <StyledButton
              htmlType="button"
              type="default"
              onClick={() => navigate(-1)}
            >
              Отмена
            </StyledButton>
          </Space>
        </FormButtonsContainer>
      </Spin>
    </Form>
  );
}
