import { ReactNode } from 'react';
import { Link, useMatch, useResolvedPath, useLocation } from 'react-router-dom';

import { LinkWrapper } from './styles';

type Props = {
  to: string;
  checkIsActive?: (path: string) => boolean;
  children: ReactNode;
};

export function CustomLink({ to, children, checkIsActive, ...props }: Props) {
  const location = useLocation();

  const resolvedPath = useResolvedPath(to);

  const match = useMatch({ path: resolvedPath.pathname, end: to.length === 0 });

  const getClassName = () => {
    if (checkIsActive) {
      return checkIsActive(location.pathname) ? 'link active-link' : 'link';
    }

    return match ? 'link active-link' : 'link';
  };

  return (
    <LinkWrapper className={getClassName()}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </LinkWrapper>
  );
}
