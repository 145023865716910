import ConnectedChargePointsLocations from 'pages/monitoring/ui/connected-charge-points-locations';
import { PageSettingsButton } from 'pages/monitoring/ui/page-settings-button';

import {
  Mapcontainer,
  MapContainerHeader,
  MapTitle,
  MapWrapper,
  MonitroingMapContainer,
} from './styles';

type Props = {
  isFullWidth: boolean;
};

export function MonitoringChargePointsMap({ isFullWidth }: Props) {
  return (
    <MonitroingMapContainer isFullWidth={isFullWidth}>
      <MapContainerHeader>
        <MapTitle>Карта ЭЗС</MapTitle>
        <PageSettingsButton />
      </MapContainerHeader>
      <Mapcontainer>
        <MapWrapper>
          <ConnectedChargePointsLocations />
        </MapWrapper>
      </Mapcontainer>
    </MonitroingMapContainer>
  );
}
