import styled from 'styled-components';

export const FormControlsContainer = styled.div`
  display: flex;
  margin-top: 50px;
`;

export const RequiredLabel = styled.p`
  &::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    // font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
`;
