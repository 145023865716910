import styled from 'styled-components';

import { COLOR } from 'shared/consts';

export const StyledCard = styled.article`
  background: ${COLOR.white};
  border-radius: 8px;
  padding: 20px;
  flex-grow: 1;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
`;

export const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${COLOR.black};
`;

export const CardContent = styled.div`
  overflow: auto;
  display: flex;
  gap: 10px;
  padding: 10px;
`;
