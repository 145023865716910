import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ServerTableLayout } from 'shared/ui';

import { GroupDto } from 'entities/group';
import { ChargePointDTO } from 'entities/charge-point';

import { useColumns } from '../hooks/use-columns';
import {
  useFiltersState,
  usePaginationState,
  useSortingState,
} from 'shared/ui/table/hooks';

type Props = {
  groups: GroupDto[];
  tableData: ChargePointDTO[];
  loading: boolean;
  count: number | undefined;
  tablePageSize: number;
  tableHeadRef: (element: HTMLElement | SVGElement | null) => void;
};

export function TableInstance({
  groups,
  tableData,
  loading,
  count,
  tableHeadRef,
  tablePageSize,
}: Props) {
  const columns = useColumns({ groups });

  const [searchParams, setSearchParams] = useSearchParams();

  const ordering = searchParams.get('ordering');
  const type = searchParams.get('type');
  const cpName = searchParams.get('cpName');
  const page = searchParams.get('page');
  const address = searchParams.get('address');
  const limit = searchParams.get('limit');
  const search = searchParams.get('search');
  const searchText = searchParams.get('searchText');

  const data = useMemo(() => tableData, [tableData]);

  const sorting = useSortingState();
  const pagination = usePaginationState();
  const filters = useFiltersState();

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnFilters: filters,
      pagination,
    },
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount: count
      ? Math.ceil(count / (limit ? Number(limit) : tablePageSize))
      : undefined,
  });

  return (
    <ServerTableLayout
      table={table}
      loading={loading}
      pageSize={tablePageSize}
      tableHeadRef={tableHeadRef}
      rowsCount={count}
    />
  );
}
