import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Leaves } from 'shared/lib/types';

import { UsersGroup } from 'entities/users-group';

type ObjectLeaves = Leaves<UsersGroup>;

export type TableColumns = Extract<ObjectLeaves, 'name'>;

export type TableColumnsState = Record<TableColumns, boolean>;

const initialState: TableColumnsState = {
  name: true,
};

const slice = createSlice({
  name: 'usersGroupsTable',
  initialState,
  reducers: {
    setVisibleColumns: (state, action: PayloadAction<TableColumnsState>) => {
      return action.payload;
    },
  },
});

export const { setVisibleColumns } = slice.actions;
export default slice.reducer;
