import { Link } from 'react-router-dom';

import { BONUS_ROUTES } from 'shared/consts/routes/bonus';
import { NavigationTabButton } from 'shared/ui';

export function AddBonusProgramButton() {
  return (
    <Link to={BONUS_ROUTES.ADD_BONUS_PROGRAM}>
      <NavigationTabButton title="Добавить программу" />
    </Link>
  );
}
