export function BanksIcon() {
  return (
    <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.834 12.5001V6.16675C20.834 5.06218 19.9386 4.16675 18.834 4.16675H4.08398C2.97941 4.16675 2.08398 5.06218 2.08398 6.16675V16.7501C2.08398 17.8547 2.97941 18.7501 4.08398 18.7501H12.5007"
        stroke="black"
        strokeWidth="1.5"
      />
      <rect
        x="12.5"
        y="16.6667"
        width="10.4167"
        height="4.16667"
        rx="2"
        stroke="black"
        strokeWidth="1.5"
      />
      <rect
        x="12.5"
        y="12.5"
        width="10.4167"
        height="4.16667"
        rx="2"
        stroke="black"
        strokeWidth="1.5"
      />
    </svg>
  );
}
