import { ErrorMessage, EmptyData } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';

import { useGetTariffsQuery } from 'entities/tariff';
import { useGetConnectorInstructionsQuery } from 'entities/instruction';

import { ConnectorForm } from '../form';

type Props = Omit<
  React.ComponentProps<typeof ConnectorForm>,
  'connectorInstructions' | 'tariffs'
>;

export function ConnectedConnectorForm(props: Props) {
  const {
    data: instructions,
    isFetching: isInstructionsFetching,
    error: instructionsError,
  } = useGetConnectorInstructionsQuery();

  const {
    data: tariffs,
    isFetching: isTariffsFetching,
    error: tariffsError,
  } = useGetTariffsQuery();

  if (isInstructionsFetching || isTariffsFetching) {
    return <div>Loading...</div>;
  }

  const apiResponseError = [instructions, tariffs].find(
    (el) => el && el.statusCode !== 0
  );

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusDescription} />;
  }

  const error = [instructionsError, tariffsError].find(
    (err) => err !== undefined
  );

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!instructions || !tariffs) {
    return <EmptyData />;
  }

  return (
    <ConnectorForm
      {...props}
      connectorInstructions={instructions.connectorInstructions}
      tariffs={tariffs.chargeTariffs}
    />
  );
}
