import { Cell, Header, Row, Table } from '@tanstack/react-table';

import { ClientPagination } from '../../pagination';
import { TableLayout } from '../table-layout';

type Props = {
  table: Table<any>;
  loading: boolean;
  pageSize: number;
  tableHeadRef: (element: HTMLElement | SVGElement | null) => void;
  handlePageSizePressEnter: (val: number) => void;
  handleRowDoubleClick?: (row: Row<any>) => void;
  rowsCount: number | undefined;
};

export function ClientTableLayout({
  table,
  loading,
  pageSize,
  tableHeadRef,
  handlePageSizePressEnter,
  handleRowDoubleClick,
  rowsCount,
}: Props) {
  const getTdClassName = (cell: Cell<any, unknown>) => {
    return cell.column.getIsSorted() !== false ? 'sorted' : '';
  };

  const handleSortChange = (header: Header<any, unknown>) => {
    return header.column.getToggleSortingHandler();
  };

  return (
    <TableLayout
      table={table}
      loading={loading}
      getTdClassName={getTdClassName}
      handleSortChange={handleSortChange}
      tableHeadRef={tableHeadRef}
      handleRowDoubleClick={handleRowDoubleClick}
      tableControls={
        <ClientPagination
          table={table}
          pageSize={pageSize}
          handlePageSizePressEnter={handlePageSizePressEnter}
          rowsCount={rowsCount}
        />
      }
    />
  );
}
