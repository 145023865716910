import { useMemo } from 'react';

import { getServerErrorText } from 'shared/lib';
import { ErrorMessage, EmptyData } from 'shared/ui';

import { useGetUsersGroupsQuery } from 'entities/users-group';

import { UsersGroupsListTableInstance } from './table';

export function ConnectedUsersGroupsListTable() {
  const { data, isFetching, error } = useGetUsersGroupsQuery();

  const tableData = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.groups;
  }, [data]);

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!isFetching && !data) {
    return <EmptyData />;
  }

  return (
    <UsersGroupsListTableInstance
      tableData={tableData}
      loading={isFetching}
      rowsCount={data?.groups.length}
    />
  );
}
