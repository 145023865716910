import { type ChargePointPaymentDto } from 'entities/report';
import { ChargePointDTO } from 'entities/charge-point';
import { GroupDto } from 'entities/group';

import { PaymentsReportForm } from 'features/report/payment/form';
import { PaymentsReportTable } from 'features/report/payment/payments-report-table';

type Props = {
  allowReportFetching: () => void;
  payments: ChargePointPaymentDto[];
  loading: boolean;
  groups: GroupDto[];
  chargePoints: ChargePointDTO[];
};

export function PaymentsReport({
  allowReportFetching,
  chargePoints,
  groups,
  loading,
  payments,
}: Props) {
  return (
    <>
      <PaymentsReportForm
        allowReportFetching={allowReportFetching}
        chargePoints={chargePoints}
        groups={groups}
        loading={loading}
      />
      <PaymentsReportTable
        groups={groups}
        loading={loading}
        payments={payments}
      />
    </>
  );
}
