import { Col, Form, FormProps } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { formHasErrors, VALIDATION_RULE } from 'shared/lib';
import { UiInput, UiSelect, UiTextArea } from 'shared/ui/ui-kit';
import {
  ButtonsContainer,
  UiSubmitButton,
  UiEditFormButton,
  UiCancelFormButton,
  FormControlsContainer,
} from 'shared/ui';

import {
  selectTokenRoles,
  selectTokenClaims,
  checkControllerRight,
} from 'entities/authorization';
import { CONNECTOR_TYPE } from 'entities/connector';

import { CardFormWrapper, FormItem, FormRow } from '../../../../common-styles';

import {
  ConnectorInstructionFormData,
  ConnectorInstructionFormProps,
} from '../../model';

type FormData = ConnectorInstructionFormData;

export function ConnectorInstructionForm({
  title,
  handleSubmit,
  initialValues,
  loading,
  disabled = false,
}: ConnectorInstructionFormProps) {
  const roles = useSelector(selectTokenRoles);
  const claims = useSelector(selectTokenClaims);

  const hasWriteRight = checkControllerRight(
    'Connector',
    'Write',
    roles,
    claims
  );

  const [isFormDisabled, setIsFormDisabled] = useState(disabled);

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [hasErrors, setHasErrors] = useState(false);

  const [form] = Form.useForm<FormData>();

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  const onFieldsChange: FormProps<FormData>['onFieldsChange'] = (
    _,
    _allFields
  ) => {
    setHasErrors(formHasErrors(form));
  };

  const onValuesChange: FormProps<FormData>['onValuesChange'] = (
    _,
    allValues
  ) => {
    if (
      allValues.name !== '' &&
      allValues.description !== '' &&
      allValues.connectorType !== ''
    ) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  const primaryButton = isFormDisabled ? (
    <UiEditFormButton onClick={() => setIsFormDisabled(false)} />
  ) : (
    <UiSubmitButton disabled={isSubmitDisabled || hasErrors} />
  );

  let formControls: JSX.Element | null = null;

  if (hasWriteRight) {
    formControls = (
      <FormControlsContainer>
        <ButtonsContainer spinning={loading}>
          {primaryButton}
          <UiCancelFormButton />
        </ButtonsContainer>
      </FormControlsContainer>
    );
  }

  return (
    <CardFormWrapper title={title}>
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={handleSubmit}
        onFieldsChange={onFieldsChange}
        onValuesChange={onValuesChange}
        initialValues={initialValues}
      >
        <FormRow>
          <Col span={24}>
            <FormItem
              label="Тип коннектора"
              name="connectorType"
              rules={[VALIDATION_RULE.REQUIRED]}
            >
              <UiSelect
                disabled={isFormDisabled || Boolean(initialValues.id)}
                options={Object.entries(CONNECTOR_TYPE).map((entry) => {
                  const [key, label] = entry;

                  return { value: key, label };
                })}
              />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow>
          <Col span={24}>
            <FormItem
              label="Наименование"
              name="name"
              rules={[VALIDATION_RULE.REQUIRED]}
            >
              <UiInput disabled={isFormDisabled} />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow>
          <Col span={24}>
            <FormItem
              label="Описание"
              name="description"
              rules={[VALIDATION_RULE.REQUIRED]}
            >
              <UiTextArea disabled={isFormDisabled} rows={3} />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow>
          <Col span={24}>
            <FormItem
              label="Ссылки на инструкции"
              name="instructionLinks"
              rules={[
                // VALIDATION_RULE.INSTRUCTION_URL,
                VALIDATION_RULE.MAX_INSTRUCTION_LINKS,
              ]}
            >
              <UiTextArea disabled={isFormDisabled} autoSize />
            </FormItem>
          </Col>
        </FormRow>
        {formControls}
      </Form>
    </CardFormWrapper>
  );
}
