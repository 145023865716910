import { usePersonsFetchingContext } from 'entities/person/persons-layout';

import { ConnectedSessionLogTable } from '../../../widgets/session/sessions-table/ui/connected-table';

export function PersonsSessionLogPage() {
  const { handleSetFetchingTab } = usePersonsFetchingContext();

  return (
    <ConnectedSessionLogTable handleSetFetchingTab={handleSetFetchingTab} />
  );
}
