import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Form, Space, Spin } from 'antd';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { UiCheckbox } from 'shared/ui/ui-kit/checkbox';

import { CountryDTO } from 'entities/country';
import { Controller } from 'entities/authorization';
import { BankDTO } from 'entities/bank';
import { MobileAppDto } from 'entities/mobile-app';

import { StyledButton } from '../../../../../common-styles';

import { FormButtonsContainer } from '../../../../FormButtons/styles';
import { RightsFormItem } from '../../../../Security/components/ChargePointRightsForm/styles';
import { RightsTablesContainer } from './styles';
import { RightsTable } from 'shared/ui/table';

const getControllerPrefix = (controller: Controller, id: number): string =>
  `${controller}/${id}`;

export interface MobileAppRightsTableItem {
  id: string;
  name: string;
}

const columnHelper = createColumnHelper<MobileAppRightsTableItem>();

type Props = {
  handleSubmit: (values: Record<string, boolean>) => Promise<void>;
  banks: BankDTO[];
  countries: CountryDTO[];
  mobileApp: MobileAppDto;
  loading: boolean;
};

export function MobileAppRights({
  banks,
  countries,
  mobileApp,
  loading,
  handleSubmit,
}: Props) {
  const navigate = useNavigate();

  const [form] = Form.useForm<Record<string, boolean>>();

  const getInitialValues = () => {
    const acc: Record<string, boolean> = {};

    banks.forEach(({ id }) => {
      acc[getControllerPrefix('Bank', id)] = mobileApp.bankIds.includes(id)
        ? true
        : false;
    });

    countries.forEach(({ id }) => {
      acc[getControllerPrefix('Country', id)] = mobileApp.countryIds.includes(
        id
      )
        ? true
        : false;
    });

    return acc;
  };

  const getCountriesTableData = () => {
    return countries.map(({ id, name }) => ({
      name,
      id: getControllerPrefix('Country', id),
    }));
  };

  const getBanksTableData = () => {
    return banks.map(({ id, name }) => ({
      name,
      id: getControllerPrefix('Bank', id),
    }));
  };

  const countriesTableData = useMemo(
    () => getCountriesTableData(),
    [countries]
  );

  const banksTableData = useMemo(() => getBanksTableData(), [banks]);

  const initialValues = useMemo(() => getInitialValues(), [mobileApp]);

  const banksColumns = [
    columnHelper.accessor('name', {
      header: 'Банк',
    }),
    columnHelper.accessor('id', {
      cell: (props) => {
        const { id } = props.row.original;

        return (
          <RightsFormItem name={id} valuePropName="checked">
            <UiCheckbox />
          </RightsFormItem>
        );
      },
      header: 'Добавить',
    }),
  ];

  const countriesColumns = [
    columnHelper.accessor('name', {
      header: 'Страна',
    }),
    columnHelper.accessor('id', {
      cell: (props) => {
        const { id } = props.row.original;

        return (
          <RightsFormItem name={id} valuePropName="checked">
            <UiCheckbox />
          </RightsFormItem>
        );
      },
      header: 'Добавить',
    }),
  ];

  const banksTable = useReactTable({
    data: banksTableData,
    columns: banksColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  const countriesTable = useReactTable({
    data: countriesTableData,
    columns: countriesColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Form form={form} onFinish={handleSubmit} initialValues={initialValues}>
      <RightsTablesContainer>
        <RightsTable table={banksTable} />
        <RightsTable table={countriesTable} />
      </RightsTablesContainer>

      <Spin spinning={loading}>
        <FormButtonsContainer>
          <Space direction="horizontal" size={10}>
            <StyledButton htmlType="submit" type="primary">
              Сохранить
            </StyledButton>
            <StyledButton
              htmlType="button"
              type="default"
              onClick={() => navigate(-1)}
            >
              Отмена
            </StyledButton>
          </Space>
        </FormButtonsContainer>
      </Spin>
    </Form>
  );
}
