import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ServerTableLayout } from 'shared/ui';

import { Transaction } from 'entities/balance/model/api';

import { useColumns } from '../hooks/use-columns';
import { usePaginationState } from 'shared/ui/table/hooks';

type Props = {
  tableData: Transaction[];
  loading: boolean;
  count: number | undefined;
  tablePageSize: number;
  tableHeadRef: (element: HTMLElement | SVGElement | null) => void;
};

export function TableInstance({
  tableData,
  loading,
  count,
  tableHeadRef,
  tablePageSize,
}: Props) {
  const columns = useColumns();

  const [searchParams, setSearchParams] = useSearchParams();

  const page = searchParams.get('page');
  const limit = searchParams.get('limit');

  const data = useMemo(() => tableData, [tableData]);

  const pagination = usePaginationState();

  const table = useReactTable({
    data,
    columns,
    state: {
      pagination,
    },
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount: count
      ? Math.ceil(count / (limit ? Number(limit) : tablePageSize))
      : undefined,
  });

  return (
    <ServerTableLayout
      table={table}
      loading={loading}
      pageSize={tablePageSize}
      tableHeadRef={tableHeadRef}
      rowsCount={count}
    />
  );
}
