import { Route } from 'react-router-dom';

import { MainContentLayout, SectionLayout } from 'shared/ui/layout';
import { GROUP, GROUPS, USERS } from 'shared/consts/routes/group';
import { ADD_PREFIX } from 'shared/consts/routes/common';

import { RequireAuth } from 'entities/authorization';
import { UsersGroupsLayout } from 'entities/user/users-groups-layout';
import { UsersGroupLayout } from 'entities/users-group/users-group-layout';

import { ConnectedUsersGroupMenu } from 'features/users-group/menu';
import { ConnectedUsersGroupsListTable } from 'features/users-group/table';

import { ConnectedUsersGroupUsersListTable } from 'features/users-group/users-table/ui/connected-table';
import { AddUsersGroup } from 'features/users-group/add';
import { EditUsersGroup } from 'features/users-group/edit';

export const UsersGroupRoutes = (
  <Route element={<RequireAuth controllerName="Identity" rightType="Read" />}>
    <Route
      path={GROUPS}
      element={<SectionLayout menu={<ConnectedUsersGroupMenu />} />}
    >
      <Route element={<UsersGroupsLayout />}>
        <Route index element={<ConnectedUsersGroupsListTable />} />
      </Route>

      <Route
        element={<RequireAuth controllerName="Identity" rightType="Write" />}
      >
        <Route
          path={ADD_PREFIX}
          element={<MainContentLayout element={<AddUsersGroup />} />}
        />
      </Route>
      <Route path={GROUP} element={<UsersGroupLayout />}>
        <Route index element={<EditUsersGroup />} />
        <Route path={USERS} element={<ConnectedUsersGroupUsersListTable />} />
      </Route>
    </Route>
  </Route>
);
