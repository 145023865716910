import { flexRender, Table } from '@tanstack/react-table';
import { Spin } from 'antd';

import {
  TableWrapper,
  TableContainer,
  StyledTable,
  HeaderCellContainer,
} from './styles';

interface ReportTableLayoutProps {
  table: Table<any>;
  ignoreHeaderGroups: string[];
  includeFooterGroups: string[];
  loading: boolean;
}

export function ReportTableLayout({
  table,
  includeFooterGroups,
  ignoreHeaderGroups,
  loading,
}: ReportTableLayoutProps) {
  return (
    <TableWrapper>
      <TableContainer>
        <Spin spinning={loading}>
          <StyledTable>
            <thead>
              {table
                .getHeaderGroups()
                .filter((el) => !ignoreHeaderGroups.includes(el.id))
                .map((headerGroup) => {
                  return (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        return (
                          <th
                            {...{
                              key: header.id,
                              style: {
                                width: header.getSize(),
                              },
                              className: ``,
                              colSpan: header.colSpan,
                            }}
                          >
                            <HeaderCellContainer>
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                            </HeaderCellContainer>
                          </th>
                        );
                      })}
                    </tr>
                  );
                })}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id} className={``}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
            <tfoot>
              {table
                .getFooterGroups()
                .filter((el) => includeFooterGroups.includes(el.id))
                .map((group) => (
                  <tr key={group.id}>
                    {group.headers.map((column) => (
                      <td
                        {...{
                          key: column.id,
                          style: {
                            width: column.getSize(),
                          },
                          className: ``,
                          colSpan: column.colSpan,
                        }}
                      >
                        {flexRender(
                          column.column.columnDef.footer,
                          column.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
            </tfoot>
          </StyledTable>
        </Spin>
      </TableContainer>
    </TableWrapper>
  );
}
