import { Table } from '@tanstack/react-table';
import { useSearchParams } from 'react-router-dom';

import { usePaginationQueryParams } from '../../hooks';

import { Pagination } from '../pagination';

type Props = {
  table: Table<any>;
  jumperValue: string;
  pageSize: number;
  rowsCount: number | undefined;
};

export function ServerPagination({
  table,
  jumperValue,
  pageSize,
  rowsCount,
}: Props) {
  const [searchParams, setSearchParams] = useSearchParams();

  const { setPageParamFromControls, setPageParamFromJumper } =
    usePaginationQueryParams();

  const prevDisabled = !table.getCanPreviousPage();
  const nextDisabled = !table.getCanNextPage();

  const onPrevClick = () => setPageParamFromControls('prev');
  const onNextClick = () => setPageParamFromControls('next');

  const handlePressEnter = (val: number) => {
    setPageParamFromJumper(String(val));
  };

  const pagesCount = table.getPageCount();

  const handlePageSizePressEnter = (val: number) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());

    updatedSearchParams.set('limit', String(val));
    updatedSearchParams.set('page', String(1));

    setSearchParams(updatedSearchParams.toString());
  };

  return (
    <Pagination
      prevDisabled={prevDisabled}
      nextDisabled={nextDisabled}
      pagesCount={pagesCount}
      jumperValue={jumperValue}
      onPrevClick={onPrevClick}
      onNextClick={onNextClick}
      handlePressEnter={handlePressEnter}
      pageSize={pageSize}
      handlePageSizePressEnter={handlePageSizePressEnter}
      rowsCount={rowsCount}
    />
  );
}
