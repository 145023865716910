import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { UiModal } from 'shared/ui';

import { ManualCommandType } from 'entities/manual-command';
import { ManualCommandForm } from './manual-command-form';

type Props = {
  commandType?: ManualCommandType;
  chargePointName?: string;
  transactionId?: string;
  connectorId?: string;
  chargePointId?: string;
};

export const ManualCommandModal = NiceModal.create((props: Props) => {
  const modal = useModal();

  return (
    <UiModal
      title="Добавление действия управления"
      visible={modal.visible}
      onCancel={modal.hide}
      afterClose={modal.remove}
    >
      <ManualCommandForm {...props} closeModal={modal.hide} />
    </UiModal>
  );
});
