import { useParams } from 'react-router-dom';

import { usePersonFetchingContext } from 'entities/person/person-layout';

import { ConnectedSessionLogTable } from '../../../widgets/session/sessions-table/ui/connected-table';

export function PersonSessionLogPage() {
  const { userLogin, groupId } = useParams() as {
    groupId: string;
    userLogin: string;
  };

  const { handleSetFetchingTab } = usePersonFetchingContext();

  return (
    <ConnectedSessionLogTable
      userLogin={userLogin}
      handleSetFetchingTab={handleSetFetchingTab}
    />
  );
}
