import { Col, Form, FormProps } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  ButtonsContainer,
  UiSubmitButton,
  UiEditFormButton,
  UiCancelFormButton,
  FormControlsContainer,
} from 'shared/ui';
import { UiInput, UiSelect } from 'shared/ui/ui-kit';
import { formHasErrors } from 'shared/lib';

import {
  selectTokenRoles,
  selectTokenClaims,
  checkControllerRight,
} from 'entities/authorization';

import {
  DiscountDescription,
  DiscountFormData,
  DiscountFormProps,
} from 'entities/discount';

import { CardFormWrapper, FormItem, FormRow } from '../../../../common-styles';

import { VALIDATION_RULE } from '../../../../constants/validation-rules';

type FormData = DiscountFormData;

export function DiscountForm({
  title,
  handleSubmit,
  initialValues,
  loading,
  disabled = false,
  owners,
}: DiscountFormProps) {
  const roles = useSelector(selectTokenRoles);
  const claims = useSelector(selectTokenClaims);

  const hasWriteRight = checkControllerRight(
    'Discount',
    'Write',
    roles,
    claims
  );

  const [isFormDisabled, setIsFormDisabled] = useState(disabled);

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [hasErrors, setHasErrors] = useState(false);

  const navigate = useNavigate();

  const [form] = Form.useForm<FormData>();

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  const onFieldsChange: FormProps<FormData>['onFieldsChange'] = (
    _,
    _allFields
  ) => {
    setHasErrors(formHasErrors(form));
  };

  const onValuesChange: FormProps<FormData>['onValuesChange'] = (
    _,
    allValues
  ) => {
    if (allValues.name !== '') {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  const primaryButton = isFormDisabled ? (
    <UiEditFormButton onClick={() => setIsFormDisabled(false)} />
  ) : (
    <UiSubmitButton disabled={isSubmitDisabled || hasErrors} />
  );

  let formControls: JSX.Element | null = null;

  if (hasWriteRight) {
    formControls = (
      <FormControlsContainer>
        <ButtonsContainer spinning={loading}>
          {primaryButton}
          <UiCancelFormButton />
        </ButtonsContainer>
      </FormControlsContainer>
    );
  }

  return (
    <>
      <DiscountDescription />
      <CardFormWrapper title={title}>
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={handleSubmit}
          onFieldsChange={onFieldsChange}
          onValuesChange={onValuesChange}
          initialValues={initialValues}
        >
          <FormRow>
            <Col span={24}>
              <FormItem
                label="Наименование"
                name="name"
                rules={[VALIDATION_RULE.REQUIRED]}
              >
                <UiInput disabled={isFormDisabled} />
              </FormItem>
            </Col>
          </FormRow>
          <FormRow>
            <Col span={24}>
              <FormItem label="Скидка на старт" name="startDiscountFormula">
                <UiInput disabled={isFormDisabled} />
              </FormItem>
            </Col>
          </FormRow>
          <FormRow>
            <Col span={24}>
              <FormItem
                label="Скидка на основной тариф потребления (за кВт*ч или минуты)"
                name="consumptionDiscountFormula"
              >
                <UiInput disabled={isFormDisabled} />
              </FormItem>
            </Col>
          </FormRow>
          <FormRow>
            <Col span={24}>
              <FormItem label="Скидка на простой" name="parkingDiscountFormula">
                <UiInput disabled={isFormDisabled} />
              </FormItem>
            </Col>
          </FormRow>
          <FormRow>
            <Col span={24}>
              <FormItem label="Скидка на бронь" name="reserveDiscountFormula">
                <UiInput disabled={isFormDisabled} />
              </FormItem>
            </Col>
          </FormRow>
          {initialValues.name !== '' ? (
            <FormRow gutter={15}>
              <Col span={24}>
                <FormItem
                  label="Владельцы, к которым скидка не применяется!"
                  name="owners"
                >
                  <UiSelect
                    mode="multiple"
                    placeholder="Выберите владельцев ЭЗС"
                    options={owners.map(({ name, id }) => ({
                      label: name,
                      value: String(id),
                    }))}
                    style={{ width: '100%' }}
                    disabled={isFormDisabled}
                  />
                </FormItem>
              </Col>
            </FormRow>
          ) : null}
          {formControls}
        </Form>
      </CardFormWrapper>
    </>
  );
}
