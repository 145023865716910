export function SecurityIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 30 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="12.1667"
        cy="16.9792"
        rx="6.25"
        ry="2.60417"
        stroke="black"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <circle
        cx="12.1667"
        cy="8.125"
        r="3.125"
        stroke="black"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.31869 13.6397C5.05588 13.6982 3.89799 13.9284 2.99694 14.2888C2.47339 14.4983 1.99937 14.7664 1.64397 15.1025C1.28698 15.4401 1 15.898 1 16.4583C1 17.0187 1.28698 17.4766 1.64397 17.8142C1.99937 18.1503 2.47339 18.4184 2.99694 18.6278C3.50486 18.831 4.09438 18.9928 4.7383 19.1058C4.25307 18.5706 3.94528 17.9759 3.85854 17.3465C3.75228 17.3109 3.65067 17.2738 3.55403 17.2351C3.13506 17.0675 2.84634 16.8867 2.67463 16.7243C2.5045 16.5634 2.5 16.4733 2.5 16.4583C2.5 16.4434 2.5045 16.3532 2.67463 16.1923C2.84634 16.0299 3.13506 15.8491 3.55403 15.6815C3.7753 15.593 4.02259 15.5123 4.29207 15.4415C4.71619 14.7542 5.41666 14.1396 6.31869 13.6397Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.26857 6.35016C7.16668 6.33907 7.06317 6.33337 6.95833 6.33337C5.39353 6.33337 4.125 7.6019 4.125 9.16671C4.125 10.7315 5.39353 12 6.95833 12C7.45571 12 7.92315 11.8719 8.32943 11.6468C7.98823 11.2752 7.7003 10.8539 7.47774 10.3951C7.31811 10.4627 7.14259 10.5 6.95833 10.5C6.22195 10.5 5.625 9.90309 5.625 9.16671C5.625 8.43033 6.22195 7.83337 6.95833 7.83337C6.96101 7.83337 6.96369 7.83338 6.96636 7.8334C6.99497 7.31516 7.09933 6.81712 7.26857 6.35016Z"
        fill="black"
      />
      <path
        d="M19.6667 12.25C19.2525 12.25 18.9167 12.5858 18.9167 13C18.9167 13.4142 19.2525 13.75 19.6667 13.75V12.25ZM28.0001 13.75C28.4143 13.75 28.7501 13.4142 28.7501 13C28.7501 12.5858 28.4143 12.25 28.0001 12.25V13.75ZM19.6667 7.04169C19.2525 7.04169 18.9167 7.37747 18.9167 7.79169C18.9167 8.2059 19.2525 8.54169 19.6667 8.54169V7.04169ZM28.0001 8.54169C28.4143 8.54169 28.7501 8.2059 28.7501 7.79169C28.7501 7.37747 28.4143 7.04169 28.0001 7.04169V8.54169ZM21.7501 17.4584C21.3359 17.4584 21.0001 17.7941 21.0001 18.2084C21.0001 18.6226 21.3359 18.9584 21.7501 18.9584V17.4584ZM28.0001 18.9584C28.4143 18.9584 28.7501 18.6226 28.7501 18.2084C28.7501 17.7941 28.4143 17.4584 28.0001 17.4584V18.9584ZM19.6667 13.75H28.0001V12.25H19.6667V13.75ZM19.6667 8.54169H28.0001V7.04169H19.6667V8.54169ZM21.7501 18.9584H28.0001V17.4584H21.7501V18.9584Z"
        fill="black"
      />
    </svg>
  );
}
