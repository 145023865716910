import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Leaves } from 'shared/lib/types';

import { UserReduceInfoDto } from 'entities/role';

type ObjectLeaves = Leaves<UserReduceInfoDto>;

export type TableColumns = Extract<ObjectLeaves, 'id' | 'userName'>;

export type TableColumnsState = Record<TableColumns, boolean>;

const initialState: TableColumnsState = {
  id: true,
  userName: true,
};

const slice = createSlice({
  name: 'usersGroupUsersTable',
  initialState,
  reducers: {
    setVisibleColumns: (state, action: PayloadAction<TableColumnsState>) => {
      return action.payload;
    },
  },
});

export const { setVisibleColumns } = slice.actions;
export default slice.reducer;
