import { baseApi } from 'shared/api';

import { getEndpointPath } from 'shared/lib';

import {
  OwnersDtoBaseResponse,
  OwnerDtoBaseResponse,
  OwnerBaseResponse,
  AddOwnerRequest,
  UpdateOwnerRequest,
  CustomUpdateOwnerRequest,
} from '../model';

import { apiSlice } from '../../../redux/services/apiSlice';
import { BONUS_SERVICE, PLATFORM_SERVICE } from 'entities/authorization';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { UpdateOwnerMaxBonusPayRequest } from '../model/owner-max-bonus-pay-api';

const KEY = 'Owner';

const getUrl = getEndpointPath(PLATFORM_SERVICE, KEY);

// TODO: замена на baseApi

export const ownersApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOwners: builder.query<OwnersDtoBaseResponse, void>({
      query: () => getUrl('GetOwners'),
      providesTags: ['Owners'],
    }),
    getOwner: builder.query<OwnerDtoBaseResponse, string>({
      query: (id) => ({
        url: getUrl(`GetOwner`),
        params: { id },
      }),
      providesTags: (result, error, arg) => [{ type: 'Owner', id: arg }],
    }),
    addOwner: builder.mutation<OwnerBaseResponse, AddOwnerRequest>({
      query: (owner) => ({
        url: getUrl('AddOwner'),
        method: 'POST',
        body: owner,
      }),
      invalidatesTags: ['Owners'],
    }),
    updateOwner: builder.mutation<OwnerBaseResponse, UpdateOwnerRequest>({
      query: (owner) => ({
        url: getUrl('UpdateOwner'),
        method: 'PATCH',
        body: owner,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Owner', id: arg.id },
        'Owners',
      ],
    }),
    deleteOwner: builder.mutation<ResponseBase, string>({
      query: (ownerId) => ({
        url: getUrl(`DeleteOwner`),
        params: { ownerId },
        method: 'DELETE',
      }),
      invalidatesTags: ['Owners'],
    }),
    customUpdateOwner: builder.mutation<ResponseBase, CustomUpdateOwnerRequest>(
      {
        async queryFn(args, _queryApi, _extraOptions, baseQuery) {
          const {
            maxOwnerBonusPay,
            oldMaxOwnerBonusPay,
            maxOwnerBonusPayId,
            ...rest
          } = args;

          const updateOwnerReq: UpdateOwnerRequest = rest;

          const updateOwnerRes = await baseQuery({
            url: getUrl(`UpdateOwner`),
            method: 'PATCH',
            body: updateOwnerReq,
          });

          if (updateOwnerRes.error) {
            return {
              error: updateOwnerRes.error as FetchBaseQueryError,
            };
          }

          const updateOwnerResData = updateOwnerRes.data as OwnerBaseResponse;

          if (updateOwnerResData.statusCode !== 0) {
            return {
              error: {
                error: updateOwnerResData.statusDescription,
                status: `CUSTOM_ERROR`,
              },
            };
          }

          if (String(oldMaxOwnerBonusPay) === String(maxOwnerBonusPay)) {
            return { data: { statusCode: 0, statusDescription: '' } };
          }

          const req = {
            ownerId: rest.id,
            maxBonusPayPercentages: maxOwnerBonusPay,
          };

          if (oldMaxOwnerBonusPay === undefined) {
            const res = await baseQuery({
              url: getEndpointPath(BONUS_SERVICE, 'OwnerMaxBonusPay')(`Add`),
              method: 'POST',
              body: req,
            });

            if (res.error) {
              return {
                error: res.error as FetchBaseQueryError,
              };
            }
          } else {
            const res = await baseQuery({
              url: getEndpointPath(BONUS_SERVICE, 'OwnerMaxBonusPay')(`Update`),
              method: 'PATCH',
              body: { ...req, id: maxOwnerBonusPayId },
            });

            if (res.error) {
              return {
                error: res.error as FetchBaseQueryError,
              };
            }
          }

          return { data: { statusCode: 0, statusDescription: '' } };
        },
        invalidatesTags: (result, error, arg) => [
          { type: 'Owner', id: arg.id },
          'OwnersMaxBonusPay',
        ],
      }
    ),
  }),
});

export const {
  useGetOwnersQuery,
  useGetOwnerQuery,
  useAddOwnerMutation,
  useUpdateOwnerMutation,
  useDeleteOwnerMutation,
  useCustomUpdateOwnerMutation,
} = ownersApi;
