import { MoreOutlined } from '@ant-design/icons';
import NiceModal from '@ebay/nice-modal-react';
import { useSelector } from 'react-redux';

import { UiPopover } from 'shared/ui';

import {
  checkControllerRight,
  selectTokenClaims,
  selectTokenRoles,
} from 'entities/authorization';
import {
  SessionManualCommandType,
  SESSION_MANUAL_COMMAND_TYPE,
} from 'entities/manual-command';
import { MongoSessionDTO } from 'entities/session';

import { ManualCommandModal } from 'features/manual-command';

import { PopoverButtonsContainer } from '../../../../common-styles';

type Props = {
  session: MongoSessionDTO;
};

export function SessionTableActions({ session }: Props) {
  const roles = useSelector(selectTokenRoles);
  const claims = useSelector(selectTokenClaims);

  const hasRight = checkControllerRight(
    'PrivateMethods',
    'Execute',
    roles,
    claims
  );

  if (!hasRight) {
    return null;
  }

  if (!session.operation) {
    return null;
  }

  const { transactionId, operation } = session;

  const showManualCommandModal = (type: SessionManualCommandType) => {
    NiceModal.show(ManualCommandModal, {
      commandType: type,
      transactionId,
      chargePointName: operation.cpName,
    });
  };

  const manualCommands = (
    Object.keys(SESSION_MANUAL_COMMAND_TYPE) as unknown as Array<
      keyof typeof SESSION_MANUAL_COMMAND_TYPE
    >
  ).map((type) => {
    return (
      <button
        key={SESSION_MANUAL_COMMAND_TYPE[type]}
        onClick={() => {
          showManualCommandModal(type);
        }}
      >
        {SESSION_MANUAL_COMMAND_TYPE[type]}
      </button>
    );
  });

  const content = (
    <>
      <PopoverButtonsContainer>{manualCommands}</PopoverButtonsContainer>
    </>
  );

  return (
    <UiPopover content={content} children={<MoreOutlined rotate={90} />} />
  );
}
