import { Checkbox } from 'antd';
import styled from 'styled-components';

import { COLOR } from 'shared/consts';

export const StyledCheckbox = styled(Checkbox)`
  & span:last-child {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    color: ${COLOR.black};
  }
`;
