import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TColumnDef } from 'shared/ui/table/types';
import { InputFilter } from 'shared/ui/table/filter';
import { useTableSettings } from 'shared/ui/table/visible-columns/use-table-settings';

import { PersonDto } from 'entities/person';

import { setVisibleColumns, TableColumns, TableColumnsState } from '../store';

import {
  FORMAT_TO_DAYS,
  renderBooleanAsString,
  showTimeString,
  TABLE_PHONE_COLUMN_WIDTH,
  toCurrencyUnit,
} from 'shared/lib';

import { selectPersonsTable } from '../../../../redux/slices/tables/tables-slice';

const columnHelper = createColumnHelper<PersonDto>();

const DATA_COLUMNS: TColumnDef<PersonDto, TableColumns>[] = [
  {
    accessorKey: 'registrationDate',
    id: 'registrationDate',
    header: 'Дата регистрации',
    size: 120,
    minSize: 120,
    maxSize: 120,
    cell: (props) => {
      const date = props.row.original.registrationDate || '';

      return date ? showTimeString(date, FORMAT_TO_DAYS) : '';
    },
    meta: {
      filterElement: (
        <InputFilter
          column={{ id: 'registrationDate', header: 'Дата регистрации' }}
        />
      ),
    },
  },
  {
    accessorKey: 'login',
    id: 'login',
    header: 'Номер телефона',
    size: TABLE_PHONE_COLUMN_WIDTH,
    minSize: TABLE_PHONE_COLUMN_WIDTH,
    maxSize: TABLE_PHONE_COLUMN_WIDTH,
    meta: {
      filterElement: (
        <InputFilter column={{ id: 'login', header: 'Номер телефона' }} />
      ),
    },
  },
  {
    accessorKey: 'email',
    id: 'email',
    header: 'Email',
    size: 250,
    minSize: 250,
    maxSize: 250,
    meta: {
      filterElement: <InputFilter column={{ id: 'email', header: 'Email' }} />,
    },
  },
  {
    accessorKey: 'userAgreementFlag',
    id: 'userAgreementFlag',
    header: 'Пользоват. соглашение',
    size: 115,
    minSize: 115,
    maxSize: 115,
    cell: (props) => {
      const value = props.row.original.userAgreementFlag;

      renderBooleanAsString(value);
    },
    meta: {
      filterElement: (
        <InputFilter
          column={{ id: 'userAgreementFlag', header: 'Пользоват. соглашение' }}
          disabled
        />
      ),
    },
  },
  {
    accessorKey: 'isBankCardAttached',
    id: 'isBankCardAttached',
    header: 'Банковская карта',
    size: 115,
    minSize: 115,
    maxSize: 115,
    cell: (props) => {
      const value = props.row.original.userAgreementFlag;

      renderBooleanAsString(value);
    },
    meta: {
      filterElement: (
        <InputFilter
          column={{ id: 'isBankCardAttached', header: 'Банковская карта' }}
          disabled
        />
      ),
    },
  },
  {
    accessorKey: 'clientBalance',
    id: 'clientBalance',
    header: 'Сумма бонусов',
    cell: (props) => {
      const value = props.row.original.clientBalance;

      toCurrencyUnit(value);
    },
    meta: {
      filterElement: (
        <InputFilter
          column={{ id: 'clientBalance', header: 'Сумма бонусов' }}
          disabled
        />
      ),
    },
  },
  {
    accessorKey: 'clientsDebt',
    id: 'clientsDebt',
    header: 'Сумма задолженности',
    cell: (props) => {
      const value = props.row.original.clientsDebt;

      toCurrencyUnit(value);
    },
    meta: {
      filterElement: (
        <InputFilter
          column={{ id: 'clientsDebt', header: 'Сумма задолженности' }}
          disabled
        />
      ),
    },
  },
  {
    accessorKey: 'commentary',
    id: 'commentary',
    header: 'Комментарий',
    size: 250,
    minSize: 250,
    maxSize: 250,
    cell: (props) => {
      const value = props.row.original.clientsDebt;

      toCurrencyUnit(value);
    },
    meta: {
      filterElement: (
        <InputFilter column={{ id: 'commentary', header: 'Комментарий' }} />
      ),
    },
  },
];

export const useColumns = () => {
  const dispatch = useDispatch();

  const settings = useSelector(selectPersonsTable);

  const settingsColumn = useTableSettings({
    columnHelper,
    columns: DATA_COLUMNS.map(({ id, header }) => {
      return {
        key: id,
        label: header as string,
        isChecked: settings[id],
      };
    }),
    settings,
    renderCell: (props) => {
      return '';
    },
    setVisibleColumns: (cols: TableColumnsState) => {
      dispatch(setVisibleColumns(cols));
    },
  });

  const visibleColumns = useMemo(() => {
    const dataCols = settings
      ? DATA_COLUMNS.filter((el) => settings[el.id])
      : DATA_COLUMNS;

    return [...dataCols, settingsColumn];
  }, [settings]);

  return visibleColumns;
};
