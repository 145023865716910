import { useNavigate } from 'react-router-dom';

import {
  openErrorNotification,
  openSuccessNotification,
  isErrorWithMessage,
} from 'shared/lib';

import { ConnectedCarModelForm, prepareRequestData } from 'entities/car';
import { CarModelFormData, useAddCarModelMutation } from 'entities/car-model';

import { ADD_SUCCESS, ADD_ERROR } from '../consts';
import { CAR_BRAND_ROUTES } from 'shared/consts/routes/car-brands';

const initialValues: CarModelFormData = {
  name: '',
  carBrandId: '',
};

export function AddCarModel() {
  const [trigger, { isLoading }] = useAddCarModelMutation();

  const navigate = useNavigate();

  const handleSubmit = async (values: CarModelFormData) => {
    const req = prepareRequestData(values);

    try {
      const res = await trigger(req).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);

        return;
      }
      //

      openSuccessNotification(ADD_SUCCESS);

      navigate(CAR_BRAND_ROUTES.CAR_BRANDS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : ADD_ERROR;

      openErrorNotification(errorText);
    }
  };

  return (
    <ConnectedCarModelForm
      title="Добавление новой модели авто"
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      loading={isLoading}
    />
  );
}
