import { baseApi } from 'shared/api';

import { getEndpointPath } from 'shared/lib';

import {
  MobileAppBaseResponse,
  MobileAppsBaseResponse,
  AddMobileAppFormRequest,
  UpdateMobileAppFormRequest,
  SetMobileAppEZSRequest,
  SetMobileAppRequest,
} from '../model/api';

import { apiSlice } from '../../../redux/services/apiSlice';
import { PLATFORM_SERVICE } from 'entities/authorization';

const KEY = 'MobileApp';

const getUrl = getEndpointPath(PLATFORM_SERVICE, KEY);

// TODO: замена на baseApi

export const mobileAppsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMobileApps: builder.query<MobileAppsBaseResponse, void>({
      query: () => getUrl('GetMobileApps'),
      providesTags: ['MobileApps'],
    }),
    getMobileApp: builder.query<MobileAppBaseResponse, string>({
      query: (id) => ({
        url: getUrl(`GetMobileApp`),
        params: { id },
      }),
      providesTags: (result, error, arg) => [{ type: 'MobileApp', id: arg }],
    }),
    addMobileApp: builder.mutation<
      MobileAppBaseResponse,
      AddMobileAppFormRequest
    >({
      query: (mobileApp) => ({
        url: getUrl('AddMobileApp'),
        method: 'POST',
        body: mobileApp,
      }),
      invalidatesTags: ['MobileApps'],
    }),
    updateMobileApp: builder.mutation<
      MobileAppBaseResponse,
      UpdateMobileAppFormRequest
    >({
      query: (mobileApp) => ({
        url: getUrl('UpdateMobileApp'),
        method: 'PATCH',
        body: mobileApp,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'MobileApp', id: arg.id },
        'MobileApps',
      ],
    }),
    deleteMobileApp: builder.mutation<ResponseBase, string>({
      query: (id) => ({
        url: getUrl(`DeleteMobileApp`),
        params: { id },
        method: 'DELETE',
      }),
      invalidatesTags: ['MobileApps'],
    }),
    setMobileApp: builder.mutation<MobileAppBaseResponse, SetMobileAppRequest>({
      query: (mobileApp) => ({
        url: getUrl('SetMobileApp'),
        method: 'POST',
        body: mobileApp,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'MobileApp', id: arg.id },
        'MobileApps',
      ],
    }),
    setMobileAppEZS: builder.mutation<ResponseBase, SetMobileAppEZSRequest>({
      query: (mobileApp) => ({
        url: getUrl('SetMobileAppEZS'),
        method: 'PATCH',
        body: mobileApp,
      }),
      invalidatesTags: (result, error, arg) => [
        // { type: 'MobileApp', id: arg.id },
        { type: 'Group', id: arg.groupId },
        'MobileApps',
      ],
    }),
  }),
});

export const {
  useGetMobileAppsQuery,
  useGetMobileAppQuery,
  useAddMobileAppMutation,
  useUpdateMobileAppMutation,
  useDeleteMobileAppMutation,
  useSetMobileAppMutation,
  useSetMobileAppEZSMutation,
} = mobileAppsApi;
