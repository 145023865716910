import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { UiModal } from 'shared/ui';

import { ConnectedProfileForm } from '../connected-form';

export const EditProfileModal = NiceModal.create(() => {
  const modal = useModal();

  return (
    <UiModal
      visible={modal.visible}
      onCancel={modal.hide}
      afterClose={modal.remove}
      title="Редактирование профиля"
    >
      <ConnectedProfileForm closeModal={modal.hide} />
    </UiModal>
  );
});
