import styled from 'styled-components';

import { COLOR, DEFAULT_BORDER } from 'shared/consts';

export const StyledButton = styled.button`
  border: none;
  cursor: pointer;
  background: none;

  background: ${COLOR.white};
  padding: 11px 13px 10px 13px;
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: ${DEFAULT_BORDER};
  gap: 14px;

  & span {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: ${COLOR.title};
    text-transform: uppercase;
  }
`;

export const IconContainer = styled.div`
  width: 21px;
  height: 21px;
`;
