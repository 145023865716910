import { ChargeTariffDto } from 'entities/tariff';
import { ChargePointConnectorDto } from 'entities/connector';

import { StyledCard, CardHeader, Title, CardContent } from './styles';

import { ConnectorCard } from '../card';

type Props = {
  connectors: ChargePointConnectorDto[];
  tariff: ChargeTariffDto | null;
  addConnectorSlot: React.ReactNode;
  renderConnectorActions: (
    connector: ChargePointConnectorDto
  ) => React.ReactNode;
};

const sortConnectorsByConnectorId = (
  connectors: ChargePointConnectorDto[]
): ChargePointConnectorDto[] =>
  [...connectors].sort((a, b) => a.connectorId - b.connectorId);

export function ConnectorsList({
  connectors,
  tariff,
  addConnectorSlot,
  renderConnectorActions,
}: Props) {
  return (
    <>
      <StyledCard>
        <CardHeader>
          <Title>Коннекторы</Title>
          {addConnectorSlot}
        </CardHeader>
        <CardContent>
          {connectors.length === 0 ? (
            <div>Нет доступных коннекторов</div>
          ) : (
            sortConnectorsByConnectorId(connectors).map((connector) => (
              <ConnectorCard
                actionsSlot={renderConnectorActions(connector)}
                connector={connector}
                tariff={tariff}
                key={connector.id}
              />
            ))
          )}
        </CardContent>
      </StyledCard>
    </>
  );
}
