import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getCoreRowModel, Row, useReactTable } from '@tanstack/react-table';
import { useDispatch } from 'react-redux';

import { JsonDataViewer, ServerTableLayout } from 'shared/ui';

import { ChargePointStatusDto } from '../../../../models/status';

import { useColumns } from '../hooks/use-columns';
import { usePaginationState, useSortingState } from 'shared/ui/table/hooks';

type Props = {
  tableData: ChargePointStatusDto[];
  loading: boolean;
  count: number | undefined;
  tablePageSize: number;
  tableHeadRef: (element: HTMLElement | SVGElement | null) => void;
};

export function ChargePointStatusesTableInstance({
  tableData,
  loading,
  count,
  tableHeadRef,
  tablePageSize,
}: Props) {
  const [statusToShowInJSON, setStatusToShowInJSON] = useState('');

  const columns = useColumns();

  const dispatch = useDispatch();

  const sorting = useSortingState();

  const [searchParams, setSearchParams] = useSearchParams();

  const page = searchParams.get('page');
  const limit = searchParams.get('limit');
  const type = searchParams.get('type');
  const dateFrom = searchParams.get('dateFrom');
  const dateTo = searchParams.get('dateTo');
  const ordering = searchParams.get('ordering');

  const data = useMemo(() => tableData, [tableData]);

  const pagination = usePaginationState();

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      pagination,
    },
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount: count
      ? Math.ceil(count / (limit ? Number(limit) : tablePageSize))
      : undefined,
  });

  const handleRowDoubleClick = (row: Row<ChargePointStatusDto>) => {
    setStatusToShowInJSON(row.original._id);
  };

  if (statusToShowInJSON) {
    const statusJSON = tableData.find(
      (status) => status._id === statusToShowInJSON
    ) as ChargePointStatusDto;

    return (
      <JsonDataViewer
        data={statusJSON}
        onClick={() => setStatusToShowInJSON('')}
      />
    );
  }

  return (
    <ServerTableLayout
      table={table}
      loading={loading}
      pageSize={tablePageSize}
      tableHeadRef={tableHeadRef}
      handleRowDoubleClick={handleRowDoubleClick}
      rowsCount={count}
    />
  );
}
