import { CpoFormData } from '../model';

export const prepareRequestData = (values: CpoFormData) => {
  const { ocpiHubId, ownerId, ...rest } = values;

  return {
    ...rest,
    ocpiHubId: Number(ocpiHubId),
    ownerId: Number(ownerId),
  };
};
