import { Col, Form, FormProps, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { formHasErrors, VALIDATION_RULE } from 'shared/lib';
import { UiInput, UiSelect } from 'shared/ui/ui-kit';

import {
  selectTokenRoles,
  selectTokenClaims,
  checkControllerRight,
  selectTokenName,
} from 'entities/authorization';
import { UserFormData, UserFormProps } from 'entities/user';

import { CardFormWrapper, FormItem, FormRow } from '../../../../common-styles';
import { FormButtons } from '../../../../components/FormButtons';
import { USER_ROUTES } from 'shared/consts/routes/user';

type FormData = UserFormData;

export function UserForm({
  handleSubmit,
  initialValues,
  loading,
  title,
  handleDelete,
  groups,
  disabled = false,
}: UserFormProps) {
  const roles = useSelector(selectTokenRoles);
  const claims = useSelector(selectTokenClaims);

  const hasWriteRight = checkControllerRight(
    'Identity',
    'Write',
    roles,
    claims
  );

  const [isFormDisabled, setIsFormDisabled] = useState(disabled);

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [hasErrors, setHasErrors] = useState(false);

  const username = useSelector(selectTokenName);

  const navigate = useNavigate();

  const [form] = Form.useForm<FormData>();

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  const onValuesChange: FormProps<FormData>['onValuesChange'] = (
    _,
    allValues
  ) => {
    if (
      allValues.userName !== '' &&
      allValues.firstName !== '' &&
      allValues.lastName !== '' &&
      (initialValues.id ? true : allValues.userPass !== '') &&
      allValues.email !== '' &&
      allValues.groupName !== ''
    ) {
      setIsSubmitDisabled(false);
    }
  };

  const onFieldsChange: FormProps<FormData>['onFieldsChange'] = (
    _,
    _allFields
  ) => {
    setHasErrors(formHasErrors(form));
  };

  const passwordLabel = initialValues.id ? 'Сменить пароль' : 'Пароль';

  return (
    <CardFormWrapper title={title}>
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={handleSubmit}
        onFieldsChange={onFieldsChange}
        onValuesChange={onValuesChange}
        initialValues={initialValues}
      >
        <FormRow>
          <Col span={24}>
            <FormItem label="ID" name="id">
              <UiInput disabled />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow>
          <Col span={24}>
            <FormItem
              label="Логин"
              name="userName"
              rules={[VALIDATION_RULE.REQUIRED]}
            >
              <UiInput
                disabled={isFormDisabled || Boolean(initialValues.id)}
                // autoComplete="new-password"
              />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow>
          <Col span={24}>
            <FormItem
              label="Фамилия"
              name="lastName"
              rules={[VALIDATION_RULE.REQUIRED]}
            >
              <UiInput disabled={isFormDisabled} />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow>
          <Col span={24}>
            <FormItem
              label="Имя"
              name="firstName"
              rules={[VALIDATION_RULE.REQUIRED]}
            >
              <UiInput disabled={isFormDisabled} />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow>
          <Col span={24}>
            <FormItem label="Отчество" name="middleName">
              <UiInput disabled={isFormDisabled} />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow>
          <Col span={24}>
            <FormItem
              label="E-mail"
              name="email"
              rules={[VALIDATION_RULE.REQUIRED, VALIDATION_RULE.EMAIL]}
            >
              <UiInput disabled={isFormDisabled} />
            </FormItem>
          </Col>
        </FormRow>
        {/* <FormRow>
                      <Col span={24}>
                        <FormItem label="Статус пользователя" name="status">
                          <UiSelect disabled={isFormDisabled}>
                            {Object.entries(SECURITY_USER_STATUS).map(
                              (entry) => {
                                const [key, label] = entry;

                                return (
                                  <Option value={key} key={key}>
                                    {label}
                                  </Option>
                                );
                              }
                            )}
                          </UiSelect>
                        </FormItem>
                      </Col>
                    </FormRow> */}
        <FormRow>
          <Col span={24}>
            <FormItem
              label={passwordLabel}
              name="userPass"
              rules={initialValues.id ? [] : [VALIDATION_RULE.REQUIRED]}
            >
              <UiInput
                disabled={isFormDisabled}
                type="password"
                autoComplete="new-password"
              />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow>
          <Col span={24}>
            <FormItem
              label="Группа"
              name="groupName"
              rules={[VALIDATION_RULE.REQUIRED]}
            >
              <UiSelect
                options={groups.map(({ name }) => ({
                  value: name,
                  label: name,
                }))}
                disabled={isFormDisabled}
              />
            </FormItem>
          </Col>
        </FormRow>
        {hasWriteRight ? (
          <FormButtons
            spinning={loading}
            isFormDisabled={isFormDisabled}
            isSubmitDisabled={isSubmitDisabled || hasErrors}
            onEditClick={() => setIsFormDisabled(false)}
            onCancelClick={() => navigate(USER_ROUTES.USERS)}
            onDeleteClick={
              handleDelete && username !== initialValues.userName
                ? handleDelete
                : undefined
            }
          />
        ) : null}
      </Form>
    </CardFormWrapper>
  );
}
