import { useDispatch, useSelector } from 'react-redux';
import { createColumnHelper } from '@tanstack/table-core';
import { useMemo } from 'react';
import { JsonViewer } from '@textea/json-viewer';
import { Tooltip } from 'antd';

import {
  DateTimeRangePickerFilter,
  InputFilter,
  SelectFilter,
} from 'shared/ui/table/filter';
import { tryParseJSONObject } from 'shared/lib/format';
import { COLOR } from 'shared/consts';
import { FORMAT_TO_SECONDS, showTimeString } from 'shared/lib/date';

import { ChargePointDTO } from 'entities/charge-point';
import { Packet } from 'entities/private-methods';

import { useTableSettings } from '../../../../shared/ui/table/visible-columns/use-table-settings';

import { TableColumns, setVisibleColumns, TableColumnsState } from '../store';
import {
  TABLE_EVENT_MESSAGE_COLUMN_WIDTH,
  TABLE_INT_VALUE_COLUMN_WIDTH,
} from '../../../../constants/variables';

import { getConnectorId } from '../lib/get-connector-id';
import { getConnectorCellContent } from '../lib/get-connector-id-table-view';
import { TableColumnObject } from 'shared/ui/table/visible-columns/types';

import { selectEventsTable } from '../../../../redux/slices/tables/tables-slice';

type Props = {
  connectors: ChargePointDTO['connectors'];
};

const columnHelper = createColumnHelper<Packet>();

const COLUMN: TableColumnObject<TableColumns> = {
  eventDateTime: {
    id: 'data.eventDateTime',
    header: 'Время получения пакета от ЭЗС',
    size: 165,
  },
  eventQueueDateTime: {
    id: 'data.payload.Timestamp', // possible conflict
    header: 'Время пакета на ЭЗС',
  },
  subsystem: {
    id: 'Subsystem',
    header: 'Подсистема',
  },
  action: {
    id: 'Action',
    header: 'Тип',
    size: 160,
  },
  eventMessage: {
    id: 'data.eventMessage',
    header: 'Событие',
    size: TABLE_EVENT_MESSAGE_COLUMN_WIDTH,
  },
  errorCode: {
    id: 'data.payload.ErrorCode',
    header: 'Код ошибки',
  },
  status: {
    id: 'data.payload.Status',
    header: 'Статус',
    size: TABLE_INT_VALUE_COLUMN_WIDTH,
  },
  connectorId: {
    id: 'data.payload.ConnectorId',
    header: 'Коннектор',
    size: 120,
  },
};

export const useColumns = ({ connectors }: Props) => {
  const dispatch = useDispatch();

  const settings = useSelector(selectEventsTable);

  const dataColumns = [
    columnHelper.accessor('data', {
      ...COLUMN.eventDateTime,
      cell: (props) => {
        const date = props.row.original.data?.eventDateTime || '';

        // Добавляем Z, т.к. бэк отдает без таймзоны
        return date ? showTimeString(`${date}Z`, FORMAT_TO_SECONDS) : '';
      },
      meta: {
        filterElement: (
          <DateTimeRangePickerFilter columnId={COLUMN.eventDateTime.id} />
        ),
      },
    }),
    columnHelper.accessor('data', {
      ...COLUMN.eventQueueDateTime,
      cell: (props) => {
        const { data, eventQueueDateTime } = props.row.original;

        if (!data) {
          return '';
        }

        const { payload } = data;

        let date: string;

        if (!payload) {
          date = eventQueueDateTime;
        } else {
          const parsedPayload = tryParseJSONObject(payload);

          if (parsedPayload) {
            const { Timestamp } = parsedPayload;

            if (Timestamp) {
              const stamp = Timestamp['$date']['$numberLong'];

              date = new Date(Number(stamp)).toISOString();
            } else {
              date = eventQueueDateTime;
            }
          } else {
            date = eventQueueDateTime;
          }
        }

        return date ? showTimeString(date, FORMAT_TO_SECONDS) : '';
      },
      meta: {
        filterElement: (
          <DateTimeRangePickerFilter columnId={COLUMN.eventQueueDateTime.id} />
        ),
      },
    }),
    columnHelper.accessor('subsystem', {
      ...COLUMN.subsystem,
      meta: {
        filterElement: (
          <SelectFilter
            options={[
              {
                label: 'Все',
                value: '',
              },
              {
                label: 'system',
                value: 'system',
              },
              {
                label: 'transactions',
                value: 'transactions',
              },
              {
                label: 'mobile gateway',
                value: 'mobile gateway',
              },
            ]}
            column={COLUMN.subsystem}
          />
        ),
      },
    }),
    columnHelper.accessor('action', {
      ...COLUMN.action,
      cell: (props) => {
        const value = props.getValue();

        const { data } = props.row.original;

        if (!data) {
          return '';
        }

        const { payload } = data;

        if (payload) {
          const parsedPayload = tryParseJSONObject(payload);

          return (
            <Tooltip
              placement="right"
              color={COLOR.whiteSmoke}
              autoAdjustOverflow
              trigger="hover"
              title={
                parsedPayload ? (
                  <JsonViewer value={parsedPayload} enableClipboard={false} />
                ) : (
                  'Невалидный JSON'
                )
              }
            >
              {value}
            </Tooltip>
          );
        }

        return value;
      },
      meta: {
        filterElement: (
          <SelectFilter
            options={[
              {
                label: 'Все',
                value: '',
              },
              {
                label: 'bootnotification',
                value: 'bootnotification',
              },
              {
                label: 'statusnotification',
                value: 'statusnotification',
              },
              {
                label: 'heartbeat',
                value: 'heartbeat',
              },
              {
                label: 'remotestarttransaction',
                value: 'remotestarttransaction',
              },
              {
                label: 'starttransaction',
                value: 'starttransaction',
              },
              {
                label: 'metervalues',
                value: 'metervalues',
              },
              {
                label: 'stoptransaction',
                value: 'stoptransaction',
              },
              {
                label: 'remotestoptransaction',
                value: 'remotestoptransaction',
              },
              {
                label: 'reset',
                value: 'reset',
              },
              {
                label: 'changeavailability',
                value: 'changeavailability',
              },
            ]}
            column={COLUMN.action}
          />
        ),
      },
    }),
    columnHelper.accessor('data', {
      ...COLUMN.eventMessage,
      cell: (props) => {
        return props.row.original.data?.eventMessage || '';
      },
      meta: {
        filterElement: <InputFilter column={COLUMN.eventMessage} />,
      },
    }),
    columnHelper.accessor('data', {
      ...COLUMN.errorCode,
      cell: (props) => {
        const payload = props.row.original.data?.payload;

        return payload ? JSON.parse(payload).ErrorCode : '';
      },
      meta: {
        filterElement: <InputFilter column={COLUMN.errorCode} />,
      },
    }),
    columnHelper.accessor('data', {
      ...COLUMN.status,
      cell: (props) => {
        const payload = props.row.original.data?.payload;

        return payload ? JSON.parse(payload).Status : '';
      },
      meta: {
        filterElement: <InputFilter column={COLUMN.status} />,
      },
    }),

    columnHelper.accessor('data', {
      ...COLUMN.connectorId,
      cell: (props) => {
        const payload = props.row.original.data?.payload;

        const ConnectorId =
          payload && JSON.parse(payload).ConnectorId
            ? getConnectorId(payload)
            : '';

        return ConnectorId !== ''
          ? getConnectorCellContent(ConnectorId, connectors)
          : '';
      },
      // TODO: уточнить
      meta: {
        filterElement: <InputFilter column={COLUMN.connectorId} disabled />,
      },
    }),
  ];

  const settingsColumn = useTableSettings({
    columnHelper,
    columns: Object.values(COLUMN).map((el) => {
      return {
        key: el.id,
        label: el.header,
        isChecked: settings[el.id],
      };
    }),
    settings,
    renderCell: () => '',
    setVisibleColumns: (cols: TableColumnsState) => {
      dispatch(setVisibleColumns(cols));
    },
  });

  const visibleColumns = useMemo(() => {
    const visibleDataColumns = settings
      ? dataColumns.filter((el) => settings[el.id])
      : dataColumns;

    return [...visibleDataColumns, settingsColumn];
  }, [settings, connectors]); //  connectors?

  return visibleColumns;
};
