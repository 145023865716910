import { createSelector } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';

import { AccessToken } from '../model/token';
import { selectCurrentToken } from './selectCurrentToken';

export const selectTokenClaims = createSelector(
  [selectCurrentToken],
  (token) => {
    const {
      Read,
      Execute,
      ReadExecute,
      ReadWrite,
      ReadWriteExecute,
      Write,
      WriteExecute,
    } = jwt_decode(token) as AccessToken;

    return {
      Read,
      Execute,
      ReadExecute,
      ReadWrite,
      ReadWriteExecute,
      Write,
      WriteExecute,
    };
  }
);
