import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Leaves } from 'shared/lib/types';

import { ChargePointDTO } from 'entities/charge-point';

type ObjectLeaves = Leaves<ChargePointDTO>;

export type TableColumns = Extract<
  ObjectLeaves,
  | 'idGroup'
  | 'warningText'
  | 'name'
  | 'type'
  | 'manufacturer'
  | 'softwareRevision'
  | 'address'
  | 'owner.name'
  | 'owner.tariff.name'
  | 'deviceErrors'
>;

export type TableColumnsState = Record<TableColumns, boolean>;

const initialState: TableColumnsState = {
  'owner.name': true,
  'owner.tariff.name': true,
  address: true,
  deviceErrors: true,
  idGroup: true,
  manufacturer: true,
  name: true,
  softwareRevision: true,
  type: true,
  warningText: true,
};

const slice = createSlice({
  name: 'chargePointsTable',
  initialState,
  reducers: {
    setVisibleColumns: (state, action: PayloadAction<TableColumnsState>) => {
      return action.payload;
    },
  },
});

export const { setVisibleColumns } = slice.actions;
export default slice.reducer;
