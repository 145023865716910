import styled from 'styled-components';

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  gap: 30px;
`;

export const PageControlsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
