import { getEndpointPath } from 'shared/lib';

import { PLATFORM_SERVICE } from 'entities/authorization';

import { apiSlice } from '../../../redux/services/apiSlice';
import {
  AddUsersGroupRequest,
  GetGroupResponse,
  GetGroupsResponse,
  UpdateUsersGroupRequest,
  UpdateUsersGroupResponse,
} from './types';

const KEY = 'Identity';

const getUrl = getEndpointPath(PLATFORM_SERVICE, KEY);

export const api = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsersGroups: builder.query<GetGroupsResponse, void>({
      query: () => getUrl('GetGroups'),
      providesTags: ['UsersGroups'],
    }),
    getUsersGroup: builder.query<GetGroupResponse, string>({
      query: (groupName) => ({
        url: getUrl('GetGroup'),
        params: { groupName },
      }),
      providesTags: (result, error, arg) => [{ type: 'UsersGroup', id: arg }],
    }),
    addUsersGroup: builder.mutation<ResponseBase, AddUsersGroupRequest>({
      query: (group) => ({
        url: getUrl(`AddGroup`),
        method: 'POST',
        body: group,
      }),
      invalidatesTags: ['UsersGroups'],
    }),
    updateIdentityGroup: builder.mutation<
      UpdateUsersGroupResponse,
      UpdateUsersGroupRequest
    >({
      query: (group) => ({
        url: getUrl(`UpdateGroup`),
        method: 'PATCH',
        body: group,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'UsersGroup', id: arg.name },
        'UsersGroups',
      ],
    }),
    deleteUsersGroup: builder.mutation<ResponseBase, string>({
      query: (groupName) => ({
        url: getUrl(`DeleteGroup`),
        params: { groupName },
        method: 'DELETE',
      }),
      invalidatesTags: ['UsersGroups'],
    }),
  }),
});

export const {
  useGetUsersGroupsQuery,
  useGetUsersGroupQuery,
  useAddUsersGroupMutation,
  useUpdateIdentityGroupMutation,
  useDeleteUsersGroupMutation,
} = api;
