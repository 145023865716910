import { StopReason } from '../../../models/session';
import { ReserveStatus } from '../model';

export const RESERVE_STATUS: Record<ReserveStatus, string> = {
  0: 'Новая',
  1: 'Забронировано',
  2: 'Таймаут',
  3: 'К оплате',
  4: 'Ошибка оплаты',
  5: 'Оплачено',
  6: 'Ошибка',
};

export const STOP_REASON: Record<StopReason, string> = {
  0: 'Не известно',
  1: 'Истекла',
  2: 'Из приложения',
  3: 'Другое',
};
