// TODO:

export const QUERY_PARAM = {
  ordering: 'ordering',
  type: 'type',
  dateFrom: 'dateFrom',
  dateTo: 'dateTo',
  page: 'page',
  limit: 'limit',
  search: 'search',
  searchText: 'searchText',
};

export const POLLING_INTERVAL = 5000;
