import { getEndpointPath } from 'shared/lib';

import { PLATFORM_SERVICE } from 'entities/authorization';

import { apiSlice } from '../../../redux/services/apiSlice';
import { GetUserResponse, GetUsersResponse, UpdateUserRequest } from './types';

const KEY = 'Identity';

const getUrl = getEndpointPath(PLATFORM_SERVICE, KEY);

export const api = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<GetUserResponse, string>({
      query: (userName) => ({
        url: getUrl('GetUser'),
        params: { userName },
      }),

      providesTags: (result, error, arg) => [{ type: 'User', id: arg }],
    }),
    getUsers: builder.query<GetUsersResponse, void>({
      query: () => getUrl('GetUsers'),
      providesTags: ['Users'],
    }),
    updateUser: builder.mutation<ResponseBase, UpdateUserRequest>({
      query: (user) => ({
        url: getUrl(`UpdateUser`),
        method: 'PATCH',
        body: user,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'User', id: arg.userName },
        'Users',
      ],
    }),
  }),
});

export const { useGetUsersQuery, useGetUserQuery, useUpdateUserMutation } = api;
