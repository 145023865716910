import { MainLayout } from 'shared/ui/layout/main';

import { ChargePointsGroupFetchingContextProvider } from '../../context';
import { ChargePointsGroupTabs } from '../tabs';

export function ChargePointsGroupLayout() {
  return (
    <ChargePointsGroupFetchingContextProvider>
      <MainLayout tabs={<ChargePointsGroupTabs />} />
    </ChargePointsGroupFetchingContextProvider>
  );
}
