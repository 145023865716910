import { MoreOutlined } from '@ant-design/icons';
import NiceModal from '@ebay/nice-modal-react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { UiPopover } from 'shared/ui';

import {
  checkControllerRight,
  selectTokenClaims,
  selectTokenRoles,
} from 'entities/authorization';
import { CarBrand } from 'entities/car-brand';

import { DeleteCarModelModal } from 'features/car/delete-car-model';
import { CAR_BRAND_ROUTES } from 'shared/consts/routes/car-brands';

type Props = {
  id: string;
  name: string;
  brand: CarBrand | undefined;
};

export function TableActions(props: Props) {
  const roles = useSelector(selectTokenRoles);
  const claims = useSelector(selectTokenClaims);

  const hasWriteRight = checkControllerRight('Car', 'Write', roles, claims);

  const navigate = useNavigate();

  const { brand, id, name } = props;

  const showDeleteModal = () => {
    NiceModal.show(DeleteCarModelModal, { id, name });
  };

  if (!brand) {
    return null;
  }

  const content = (
    <>
      <button
        onClick={() =>
          navigate(`${CAR_BRAND_ROUTES.CAR_BRANDS}/${brand.id}/${id}`)
        }
      >
        Редактировать
      </button>
      {hasWriteRight && <button onClick={showDeleteModal}>Удалить</button>}
    </>
  );

  return (
    <UiPopover content={content}>
      <MoreOutlined rotate={90} />
    </UiPopover>
  );
}
