import { flexRender, Table } from '@tanstack/react-table';

import {
  HeaderCellContainer,
  HeaderCellRow,
  HeaderCellText,
  StyledRightsTable,
  TableContainer,
} from './styles';

type Props = {
  table: Table<any>;
};

export function RightsTable({ table }: Props) {
  return (
    <TableContainer>
      <StyledRightsTable>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    {...{
                      key: header.id,
                      style: {
                        width: header.getSize(),
                      },
                    }}
                  >
                    <HeaderCellContainer>
                      <HeaderCellRow>
                        <HeaderCellText>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </HeaderCellText>
                      </HeaderCellRow>
                    </HeaderCellContainer>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </StyledRightsTable>
    </TableContainer>
  );
}
