import { PLATFORM_SERVICE } from 'entities/authorization';
import { getEndpointPath } from 'shared/lib';

import { apiSlice } from './apiSlice';

export interface UnbindBankCardRequest {
  bindingId: string;
  userBrowseName: string;
  clientId: string;
  bankId: number;
}

export interface GetDiagnosticsRequest {
  chargePointId: string;
  startTime: string;
  stopTime: string;
  retries: number;
  retryInterval: number;
}

export interface UpdateFirmwareRequest {
  chargePointId: string;
  firmwareId: number;
  retrieveDate: string;
  retries: number;
  retryInterval: number;
}

const KEY = 'ManualCommand';

const getUrl = getEndpointPath(PLATFORM_SERVICE, KEY);

export const manualCommandApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    unbindBankCard: builder.mutation<ResponseBase, UnbindBankCardRequest>({
      query: (req) => ({
        url: getUrl('UnbindBankCard'),
        method: 'POST',
        body: req,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'ClientBindings', id: arg.userBrowseName },
      ],
    }),
    getDiagnostics: builder.mutation<ResponseBase, GetDiagnosticsRequest>({
      query: (req) => ({
        url: getUrl('GetDiagnostics'),
        method: 'POST',
        body: req,
      }),
    }),
    updateFirmware: builder.mutation<ResponseBase, UpdateFirmwareRequest>({
      query: (req) => ({
        url: getUrl('UpdateFirmware'),
        method: 'POST',
        body: req,
      }),
    }),
  }),
});

export const {
  useUnbindBankCardMutation,
  useGetDiagnosticsMutation,
  useUpdateFirmwareMutation,
} = manualCommandApi;
