import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { UiModal } from 'shared/ui';
import { AddCar } from '../add';

type Props = {
  personLogin: string;
};

export const AddCarModal = NiceModal.create((props: Props) => {
  const modal = useModal();

  return (
    <UiModal
      visible={modal.visible}
      onCancel={modal.hide}
      afterClose={modal.remove}
      title="Добавить новый автомобиль"
    >
      <AddCar {...props} closeModal={modal.hide} />
    </UiModal>
  );
});
