import { ADD_PREFIX, SETTINGS } from './common';

export const COMPANIES = 'companies';
export const COMPANY = ':companyId';

export const COMPANY_ROUTES = {
  COMPANIES: `/${SETTINGS}/${COMPANIES}`,
  COMPANY: `/${SETTINGS}/${COMPANIES}/${COMPANY}`,
  ADD_COMPANY: `/${SETTINGS}/${COMPANIES}/${ADD_PREFIX}`,
};
