import styled from 'styled-components';

import { COLOR } from 'shared/consts';

export const NavMenuErrorText = styled.div`
  background-color: ${COLOR.white};
  padding: 16px 18px 10px 20px;
  font-size: 14px;
  line-height: 17px;
  color: ${COLOR.black};
  height: 100%;
`;
