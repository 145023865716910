import { ADD_PREFIX, SETTINGS } from './common';

export const APP_SETTINGS = 'app-settings';
export const APP_SETTING = ':countryId';

export const APP_SETTING_ROUTES = {
  APP_SETTINGS: `/${SETTINGS}/${APP_SETTINGS}`,
  APP_SETTING: `/${SETTINGS}/${APP_SETTINGS}/${APP_SETTING}`,
  ADD_APP_SETTING: `/${SETTINGS}/${APP_SETTINGS}/${ADD_PREFIX}`,
};
