import { Col, Form, FormProps } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import 'dayjs/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';
import dayjs from 'dayjs';

import { DATE_FORMAT_REVERSE } from 'shared/lib';
import { UiSelect } from 'shared/ui/ui-kit';
import { dayjsDatePickerFormat } from 'shared/ui/dayjs-date-picker';

import { GroupDto } from 'entities/group';
import {
  GetReportButton,
  PAYMENTS_REPORT_GROUP_BY_TYPE,
  ReportFormWrapper,
  ReportRangePicker,
} from 'entities/report';
import { ChargePointDTO } from 'entities/charge-point';

import { FormItem, FormRow } from '../../../../common-styles';
import { VALIDATION_RULE } from '../../../../constants/validation-rules';

import { ArrowRightIcon } from 'entities/report/images/arrow-right-icon';

interface PaymentsReportFormData {
  dateRange: [dayjs.Dayjs, dayjs.Dayjs];
  groupBy: string;
  groups: string[];
  chargePoints: string[];
}

type Props = {
  allowReportFetching: () => void;
  loading: boolean;
  groups: GroupDto[];
  chargePoints: ChargePointDTO[];
};

export function PaymentsReportForm({
  allowReportFetching,
  loading,
  groups,
  chargePoints,
}: Props) {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();

  const dateFromParam = searchParams.get('dateFrom');
  const dateToParam = searchParams.get('dateTo');
  const chargePointsParam = searchParams.get('chargePoints');
  const groupsParam = searchParams.get('groups');
  const groupByParam = searchParams.get('groupBy');

  const [form] = Form.useForm<PaymentsReportFormData>();

  useEffect(() => {
    if (dateFromParam && dateToParam) {
      setIsSubmitDisabled(false);
    }
  }, [dateFromParam, dateToParam]);

  useEffect(() => {
    const initialValues = getInitialValues();

    form.setFieldsValue(initialValues);
  }, [dateFromParam, dateToParam]);

  const getInitialValues = () => {
    const dateRange =
      dateFromParam && dateToParam
        ? [dayjs(dateFromParam), dayjs(dateToParam)]
        : undefined;

    const groups = groupsParam ? groupsParam.split(',').map(Number) : undefined;

    const chargePoints = chargePointsParam
      ? chargePointsParam.split(',')
      : undefined;

    return {
      groupBy: groupByParam ?? '1',
      dateRange,
      groups,
      chargePoints,
    };
  };

  const onValuesChange: FormProps<PaymentsReportFormData>['onValuesChange'] = (
    _,
    allValues
  ) => {
    if (allValues.dateRange !== undefined) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  const handleSubmit = (values: PaymentsReportFormData) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());

    const { groups, chargePoints, dateRange, groupBy } = values;

    updatedSearchParams.set(
      'dateFrom',
      dateRange[0].format(DATE_FORMAT_REVERSE)
    );
    updatedSearchParams.set('dateTo', dateRange[1].format(DATE_FORMAT_REVERSE));

    if (chargePoints === undefined) {
      updatedSearchParams.delete('chargePoints');
    } else {
      updatedSearchParams.set('chargePoints', chargePoints.join(','));
    }

    if (groups === undefined) {
      updatedSearchParams.delete('groups');
    } else {
      updatedSearchParams.set('groups', groups.join(','));
    }

    updatedSearchParams.set('groupBy', groupBy);

    setSearchParams(updatedSearchParams.toString());

    allowReportFetching();
  };

  return (
    <ReportFormWrapper>
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={handleSubmit}
        onValuesChange={onValuesChange}
        initialValues={getInitialValues()}
      >
        <FormRow gutter={15}>
          <Col>
            <FormItem
              label="Период от и до"
              name="dateRange"
              rules={[VALIDATION_RULE.REQUIRED]}
            >
              <ReportRangePicker
                locale={locale}
                separator={'-'}
                allowClear={false}
                suffixIcon={<ArrowRightIcon />}
                placeholder={['Дата начала', 'Дата конца']}
                format={dayjsDatePickerFormat}
              />
            </FormItem>
          </Col>
          <Col>
            <FormItem label="Тип группировки" name="groupBy">
              <UiSelect
                options={Object.entries(PAYMENTS_REPORT_GROUP_BY_TYPE).map(
                  ([key, label]) => ({ value: key, label })
                )}
              />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow gutter={15}>
          <Col span={9}>
            <FormItem label="Группы ЭЗС" name="groups">
              <UiSelect
                mode="multiple"
                placeholder="Выберите группы"
                options={groups.map(({ name, id }) => ({
                  label: name,
                  value: id,
                }))}
                style={{ width: '100%' }}
              />
            </FormItem>
          </Col>
          <Col span={9}>
            <FormItem label="ЭЗС" name="chargePoints">
              <UiSelect
                mode="multiple"
                placeholder="Выберите ЭЗС"
                options={chargePoints.map(({ name }) => ({
                  label: name,
                  value: name,
                }))}
                style={{ width: '100%' }}
              />
            </FormItem>
          </Col>
        </FormRow>
        <GetReportButton disabled={isSubmitDisabled} spinning={loading} />
      </Form>
    </ReportFormWrapper>
  );
}
