import { Popover } from 'antd';
import { useState, cloneElement } from 'react';

import { UiPopoverContainer } from './styles';

type Props = {
  content: JSX.Element;
  children: JSX.Element;
};

export function UiPopover({ content, children }: Props) {
  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  // Отлов клика по контенту поповера
  const onCatchClickEvent = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setOpen(false);
  };

  const handleIconClick = (e) => e.stopPropagation();

  return (
    <UiPopoverContainer onClick={onCatchClickEvent}>
      <Popover
        visible={open}
        content={content}
        trigger="click"
        placement="bottomLeft"
        onVisibleChange={handleOpenChange}
      >
        {cloneElement(children, { onClick: handleIconClick })}
      </Popover>
    </UiPopoverContainer>
  );
}
