import { useParams } from 'react-router-dom';

import { usePersonsGroupFetchingContext } from 'entities/person/persons-group-layout';

import { ConnectedSessionLogTable } from '../../../widgets/session/sessions-table/ui/connected-table';

export function PersonsGroupSessionLogPage() {
  const { groupId } = useParams() as {
    groupId: string;
  };

  const { handleSetFetchingTab } = usePersonsGroupFetchingContext();

  return (
    <ConnectedSessionLogTable
      handleSetFetchingTab={handleSetFetchingTab}
      groupId={groupId}
    />
  );
}
