import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useNavigate } from 'react-router-dom';

import { ConfirmDialog, UiModal } from 'shared/ui';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';

import { DELETE_ERROR, DELETE_SUCCESS } from '../consts';

import { FIRMWARE_ROUTES } from 'shared/consts/routes/firmware';
import { useDeleteFirmwareMutation } from 'entities/firmware';

type Props = {
  id: string;
  name: string;
};

export const DeleteFirmwareModal = NiceModal.create((props: Props) => {
  const modal = useModal();

  const [trigger, { isLoading }] = useDeleteFirmwareMutation();

  const navigate = useNavigate();

  const { id, name } = props;

  const handleDelete = async () => {
    try {
      const res = await trigger(id).unwrap();

      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);
        return;
      }

      openSuccessNotification(DELETE_SUCCESS);

      navigate(FIRMWARE_ROUTES.FIRMWARES);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : DELETE_ERROR;

      openErrorNotification(errorText);
    } finally {
      modal.hide();
    }
  };

  return (
    <UiModal
      title={`Удалить прошивку ${name}?`}
      visible={modal.visible}
      onCancel={modal.hide}
      afterClose={modal.remove}
    >
      <ConfirmDialog
        onCancel={modal.hide}
        onConfirm={() => handleDelete()}
        loading={isLoading}
      />
    </UiModal>
  );
});
