import { useLocation } from 'react-router-dom';

import { NavigationTabs } from 'shared/ui/navigation-tabs';

import { CHARGE_POINTS_TABS } from '../../consts';
import { useChargePointsFetchingContext } from '../../context';

export function ChargePointsTabs() {
  const { fetchingTab } = useChargePointsFetchingContext();

  const location = useLocation();

  return (
    <NavigationTabs
      tabs={CHARGE_POINTS_TABS}
      fetchingTab={fetchingTab}
      rootPath={location.pathname}
    />
  );
}
