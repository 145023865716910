import { FirmwareFormData, FormatUrl } from 'entities/firmware';

export const prepareRequestData = (values: FirmwareFormData) => {
  const { formatUrl, ...rest } = values;

  return {
    ...rest,
    formatUrl: formatUrl as unknown as FormatUrl,
  };
};
