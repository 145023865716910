import styled from 'styled-components';

import { COLOR } from 'shared/consts';

export const StyledHeaderNavMenu = styled.ul`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 0 22px;
  height: 32px;

  & .link {
    height: 25px;
    padding: 3px;

    & span {
      height: 19px;
    }
  }

  & .active-link {
    padding: 5px;
    background-color: ${COLOR.lightGreenMenu};
    border-radius: 2px;

    & span {
      height: 15px;
    }
  }
`;

export const HeaderNavMenuItem = styled.li``;
