export function HotelIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 22V6C4 3.79086 5.79086 2 8 2H16C18.2091 2 20 3.79086 20 6V22M9 22V17C9 15.3431 10.3431 14 12 14C13.6569 14 15 15.3431 15 17V22"
        stroke="#28303F"
        stroke-width="1.5"
        strokeLinecap="round"
      />
      <path
        d="M10 5V11"
        stroke="#28303F"
        stroke-width="1.5"
        strokeLinecap="round"
      />
      <path
        d="M14 5V11"
        stroke="#28303F"
        stroke-width="1.5"
        strokeLinecap="round"
      />
      <path
        d="M14 8H10"
        stroke="#28303F"
        stroke-width="1.5"
        strokeLinecap="round"
      />
      <path
        d="M2 22H22"
        stroke="#28303F"
        stroke-width="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
