import { NavigationTab } from 'shared/model';

export const TABS: Array<NavigationTab> = [
  {
    key: 'session-log',
    path: '',
    label: 'Журнал сессий',
    controller: 'PrivateMethods',
    right: 'Read', //
    hasLoader: true,
  },
  {
    key: 'reserves',
    path: 'reserves',
    label: 'Журнал брони',
    controller: 'PrivateMethods',
    right: 'Read', //
  },
  {
    key: 'bindings',
    path: 'bindings',
    label: 'Статус привязки БК',
  },
  {
    key: 'bank-card-sessions',
    path: 'bank-card-sessions',
    label: 'Журнал привязки БК',
  },
  {
    key: 'profile',
    path: 'profile',
    label: 'Профиль пользователя',
  },
  {
    key: 'transactions',
    path: 'transactions',
    label: 'Бонусные баллы',
  },
];
