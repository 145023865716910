import { useParams } from 'react-router-dom';

import { useChargePointsGroupFetchingContext } from 'entities/charge-point/charge-points-group-layout';

import { ConnectedSessionLogTable } from '../../../widgets/session/sessions-table/ui/connected-table';

export function ChargePointsGroupSessionLogPage() {
  const { chargePointName, groupId } = useParams() as {
    chargePointName: string;
    groupId: string;
  };

  const { handleSetFetchingTab } = useChargePointsGroupFetchingContext();

  return (
    <ConnectedSessionLogTable
      handleSetFetchingTab={handleSetFetchingTab}
      groupId={groupId}
    />
  );
}
