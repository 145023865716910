import { Route } from 'react-router-dom';

import { SectionLayout } from 'shared/ui/layout';
import { ADD_PREFIX } from 'shared/consts/routes/common';
import { COMPANIES, COMPANY } from 'shared/consts/routes/company';

import { RequireAuth } from 'entities/authorization';
import { ConnectedCompaniesMenu } from 'entities/company';

import { AddCompanyPage, EditCompanyPage } from 'pages/company';

export const CompanyRoutes = (
  <Route element={<RequireAuth controllerName="Person" rightType="Read" />}>
    <Route
      path={COMPANIES}
      element={<SectionLayout menu={<ConnectedCompaniesMenu />} />}
    >
      <Route
        element={<RequireAuth controllerName="Person" rightType="Write" />}
      >
        <Route path={ADD_PREFIX} element={<AddCompanyPage />} />
      </Route>
      <Route path={COMPANY} element={<EditCompanyPage />} />
    </Route>
  </Route>
);
