import NiceModal from '@ebay/nice-modal-react';
import { useSelector } from 'react-redux';

import { PlusInCircleIcon } from 'shared/icons/plus-in-circle';

import {
  checkControllerRight,
  selectTokenClaims,
  selectTokenRoles,
} from 'entities/authorization';

import { AddChargePointPhotoModal } from './modal';
import { StyledAddButton } from '../../../../common-styles';

type Props = {
  chargePointName: string;
};

export function AddChargePointPhotoButton({ chargePointName }: Props) {
  const roles = useSelector(selectTokenRoles);
  const claims = useSelector(selectTokenClaims);

  const hasWriteRight = checkControllerRight(
    'ChargePoint',
    'Write',
    roles,
    claims
  );

  if (!hasWriteRight) {
    return null;
  }

  const onClick = () => {
    NiceModal.show(AddChargePointPhotoModal, { chargePointName });
  };

  return (
    <StyledAddButton onClick={onClick} type="button">
      <PlusInCircleIcon />
    </StyledAddButton>
  );
}
