import { Spin } from 'antd';

import {
  DashboardContent,
  Gradient,
  DashboardHeader,
  DashboardTotalValue,
  DashboardSuffix,
  DashboardTitle,
  DashboardValue,
  GradientDashboardCard,
} from './styles';

type Props = {
  title: string;
  value: number;
  totalValue?: number;
  suffix: string;
  gradient: string;
  loading: boolean;
};

export function GradientDashboard({
  loading,
  title,
  value,
  totalValue,
  gradient,
  suffix,
}: Props) {
  return (
    <GradientDashboardCard>
      <Gradient src={gradient} />
      <DashboardHeader>
        <DashboardTitle>{title}</DashboardTitle>
      </DashboardHeader>
      <Spin spinning={loading}>
        <DashboardContent>
          <DashboardValue>{value}</DashboardValue>
          {totalValue !== undefined ? (
            <DashboardTotalValue>{`/${totalValue}`}</DashboardTotalValue>
          ) : null}
        </DashboardContent>
      </Spin>
      <DashboardSuffix>{suffix}</DashboardSuffix>
    </GradientDashboardCard>
  );
}
