import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { UiModal } from 'shared/ui';

import { ChargePointTariffsDetails } from '../details';

type Props = React.ComponentProps<typeof ChargePointTariffsDetails> & {
  title: JSX.Element;
};

export const ChargepointTariffsDetailsModal = NiceModal.create(
  (props: Props) => {
    const modal = useModal();

    return (
      <UiModal
        title={props.title}
        visible={modal.visible}
        onCancel={modal.hide}
        afterClose={modal.remove}
      >
        <ChargePointTariffsDetails {...props} />
      </UiModal>
    );
  }
);
