import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { QUERY_PARAM } from 'shared/consts/server';
import { TableColumnData } from 'shared/ui/table/visible-columns/types';
import { UiSelect } from 'shared/ui/ui-kit';

import { SelectFilterContainer } from './styles';

type Props = {
  options: Array<{
    label: string;
    value: string;
  }>;
  column: TableColumnData<string>;
};

// Если у опции value = '' - удаляем query параметры

const { search, searchText } = QUERY_PARAM;

export function SelectFilter({ options, column }: Props) {
  const [value, setValue] = useState('');

  const [searchParams, setSearchParams] = useSearchParams();

  const searchParam = searchParams.get(search);
  const searchTextParam = searchParams.get(searchText);

  const { id, queryParamName } = column;

  const columnQueryParam = queryParamName ?? id;

  useEffect(() => {
    if (searchParam === null && searchTextParam === null) {
      setValue('');
    }

    if (columnQueryParam !== searchParam) {
      setValue('');
    }

    if (searchParam && searchTextParam && searchParam === columnQueryParam) {
      setValue(searchTextParam);
    }
  }, [searchParam, searchTextParam]);

  const handleChange = (value: string) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());

    if (value === '') {
      updatedSearchParams.delete(search);
      updatedSearchParams.delete(searchText);
    } else {
      updatedSearchParams.set(search, columnQueryParam);
      updatedSearchParams.set(searchText, value);
    }

    setSearchParams(updatedSearchParams.toString());
  };

  return (
    <SelectFilterContainer>
      <UiSelect onChange={handleChange} value={value} options={options} />
    </SelectFilterContainer>
  );
}
