import {
  ADD_PREFIX,
  CHARGE_POINTS_RIGHTS,
  CONTROLLER_CLAIMS,
  SECURITY,
} from './common';

export const ROLES = 'roles';
export const ROLE = ':roleName';
export const USERS = 'users';

export const ROLE_ROUTES = {
  ROLES: `/${SECURITY}/${ROLES}`,
  ROLE: `/${SECURITY}/${ROLES}/${ROLE}`,
  CONTROLLER_CLAIMS: `/${SECURITY}/${ROLES}/${ROLE}/${CONTROLLER_CLAIMS}`,
  CHARGE_POINTS_RIGHTS: `/${SECURITY}/${ROLES}/${ROLE}/${CHARGE_POINTS_RIGHTS}`,
  USERS_LIST: `/${SECURITY}/${ROLES}/${ROLE}/${USERS}`,
  ADD_ROLE: `/${SECURITY}/${ROLES}/${ADD_PREFIX}`,
};
