import { baseApi } from 'shared/api';

import { getEndpointPath } from 'shared/lib';

import {
  AddOcpiHubRequest,
  OcpiHubBaseResponse,
  OcpiHubsBaseResponse,
  UpdateOcpiHubRequest,
} from './types';

import { apiSlice } from '../../../redux/services/apiSlice';
import { PLATFORM_SERVICE } from 'entities/authorization';

const KEY = 'OcpiHubs';

const getUrl = getEndpointPath(PLATFORM_SERVICE, KEY);

// TODO: замена на baseApi

export const ocpiHubsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOcpiHubs: builder.query<OcpiHubsBaseResponse, void>({
      query: () => getUrl('GetOcpiHubs'),
      providesTags: ['OcpiHubs'],
    }),
    getOcpiHub: builder.query<OcpiHubBaseResponse, string>({
      query: (id) => ({
        url: getUrl(`GetOcpiHub`),
        params: { id },
      }),
      providesTags: (result, error, arg) => [{ type: 'OcpiHub', id: arg }],
    }),
    addOcpiHub: builder.mutation<OcpiHubBaseResponse, AddOcpiHubRequest>({
      query: (ocpiHub) => ({
        url: getUrl('AddOcpiHub'),
        method: 'POST',
        body: ocpiHub,
      }),
      invalidatesTags: ['OcpiHubs'],
    }),
    updateOcpiHub: builder.mutation<OcpiHubBaseResponse, UpdateOcpiHubRequest>({
      query: (ocpiHub) => ({
        url: getUrl('UpdateOcpiHub'),
        method: 'PATCH',
        body: ocpiHub,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'OcpiHub', id: arg.id },
        'OcpiHubs',
      ],
    }),
    deleteOcpiHub: builder.mutation<ResponseBase, string>({
      query: (id) => ({
        url: getUrl(`DeleteOcpiHub`),
        params: { id },
        method: 'DELETE',
      }),
      invalidatesTags: ['OcpiHubs'],
    }),
  }),
});

export const {
  useGetOcpiHubsQuery,
  useGetOcpiHubQuery,
  useAddOcpiHubMutation,
  useUpdateOcpiHubMutation,
  useDeleteOcpiHubMutation,
} = ocpiHubsApi;
