import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  checkControllerRight,
  Right,
  selectTokenClaims,
  selectTokenRoles,
} from 'entities/authorization';
import { Controller } from 'entities/authorization';
import { PlusInCircleIcon } from 'shared/icons/plus-in-circle';

import { StyledButton, IconContainer } from './styles';

const DEFAULT_LABEL = 'добавить';

type RequiredRight = {
  controller: Controller;
  right: Right;
};

type Props = {
  path: string;
  requiredRights: Array<RequiredRight>;
  label?: string;
};

export function MenuAddButton({
  path,
  requiredRights,
  label = DEFAULT_LABEL,
}: Props) {
  const roles = useSelector(selectTokenRoles);
  const claims = useSelector(selectTokenClaims);

  const hasRights = requiredRights.every(({ controller, right }) =>
    checkControllerRight(controller, right, roles, claims)
  );

  if (!hasRights) {
    return null;
  }

  return (
    <Link to={path}>
      <StyledButton>
        <span>{label}</span>
        <IconContainer>
          <PlusInCircleIcon />
        </IconContainer>
      </StyledButton>
    </Link>
  );
}
