import NiceModal from '@ebay/nice-modal-react';

import { SettingsIcon } from 'shared/ui/settings-icon';

import { PageSettingsModal } from '../page-settings-modal';
import { SettingsButtonContainer } from './styles';

export function PageSettingsButton() {
  const onClick = () => {
    NiceModal.show(PageSettingsModal);
  };

  return (
    <SettingsButtonContainer onClick={onClick}>
      <SettingsIcon />
    </SettingsButtonContainer>
  );
}
