import { useSearchParams } from 'react-router-dom';

import { QUERY_PARAM } from 'shared/consts/server';
import { DebouncedInput } from 'shared/ui/debounced-input';
import { TableColumnData } from 'shared/ui/table/visible-columns/types';

const { search, searchText } = QUERY_PARAM;

type Props = {
  column: TableColumnData<string>;
  disabled?: boolean;
};

export function InputFilter({ column, disabled = false }: Props) {
  const [searchParams, setSearchParams] = useSearchParams();

  const searchParam = searchParams.get(search);
  const searchTextParam = searchParams.get(searchText);

  const { id, queryParamName } = column;

  const columnQueryParam = queryParamName ?? id;

  const inputValue =
    searchParam !== columnQueryParam ? '' : (searchTextParam as string);

  const handleChange = (val: string) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());

    if (val !== '') {
      updatedSearchParams.set(search, columnQueryParam);
      updatedSearchParams.set(searchText, val);
    }

    // if (value !== '') {
    //   // Костыль, т.к. сортировка работает по connectorId, а фильтрация по connectorName
    //   if (columnId === 'operation.connectorId') {
    //     updatedSearchParams.set(search, 'operation.connectorName');
    //   } else {
    //     updatedSearchParams.set(search, columnId);
    //   }
    //   updatedSearchParams.set(searchText, value);
    // }

    if (val === '' && searchParam === columnQueryParam) {
      updatedSearchParams.delete(search);
      updatedSearchParams.delete(searchText);
    }

    setSearchParams(updatedSearchParams.toString());
  };

  return (
    <DebouncedInput
      value={inputValue}
      onChange={handleChange}
      disabled={disabled}
    />
  );
}
