import { useNavigate } from 'react-router-dom';

import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';

import {
  BonusProgramFormData,
  prepareProgramRequestData,
  useAddBonusProgramMutation,
  ConnectedBonusProgramForm,
} from 'entities/bonus-program';

import { ADD_ERROR, ADD_SUCCESS } from '../consts';

const initialValues: BonusProgramFormData = {
  bankId: '',
  bonusProgramType: '',
  dateStart: '',
  dateStop: '',
  description: '',
  executionCron: '',
  frequencyType: '',
  frequencyValue: '',
  name: '',
};

export function AddBonusProgram() {
  const navigate = useNavigate();

  const [trigger, { isLoading }] = useAddBonusProgramMutation();

  const handleSubmit = async (values: BonusProgramFormData) => {
    const req = prepareProgramRequestData(values);

    try {
      const res = await trigger(req).unwrap();

      openSuccessNotification(ADD_SUCCESS);

      navigate('/bonuses');
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : ADD_ERROR;

      openErrorNotification(errorText);
    }
  };

  return (
    <ConnectedBonusProgramForm
      title="Добавление новой бонусной программы"
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      loading={isLoading}
    />
  );
}
