import { baseApi } from 'shared/api';
import { getEndpointPath } from 'shared/lib';

import { PLATFORM_SERVICE } from 'entities/authorization';

import { apiSlice } from '../../../redux/services/apiSlice';
import {
  AddConnectorFormRequest,
  ConnectorBaseResponse,
  ConnectorsBaseResponse,
  DeleteConnectorRequest,
  UpdateConnectorFormRequest,
} from '../model/api';

const KEY = 'Connector';

const getUrl = getEndpointPath(PLATFORM_SERVICE, KEY);

// TODO: замена на baseApi

export const api = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Не используется
    getConnectors: builder.query<ConnectorsBaseResponse, string>({
      query: (chargePointId) =>
        getUrl(
          `GetConnectors${
            chargePointId ? `?chargePointId=${chargePointId}` : ''
          }`
        ),
      providesTags: (result, error, arg) => [{ type: 'Connectors', id: arg }],
    }),
    getConnector: builder.query<ConnectorBaseResponse, number>({
      query: (id) => ({
        url: getUrl('GetConnector'),
        params: { id },
      }),
      providesTags: (result, error, arg) => [{ type: 'Connector', id: arg }],
    }),
    //
    addConnector: builder.mutation<
      ConnectorBaseResponse,
      AddConnectorFormRequest
    >({
      query: (connector) => ({
        url: getUrl(`AddConnector`),
        method: 'POST',
        body: connector,
      }),
      invalidatesTags: (result, error, arg) => [
        // { type: 'Connectors', id: arg.chargePointId },
        { type: 'ChargePoint', id: arg.chargePointName },
      ],
    }),
    updateConnector: builder.mutation<
      ConnectorBaseResponse,
      UpdateConnectorFormRequest
    >({
      query: (connector) => ({
        url: getUrl(`UpdateConnector`),
        method: 'PATCH',
        body: connector,
      }),
      invalidatesTags: (result, error, arg) => [
        // { type: 'Connectors', id: arg.chargePointId },
        { type: 'ChargePoint', id: arg.chargePointName },
      ],
    }),
    deleteConnector: builder.mutation<ResponseBase, DeleteConnectorRequest>({
      query: ({ id }) => ({
        url: getUrl(`DeleteConnector`),
        params: { id },
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'ChargePoint', id: arg.chargePointName },
      ],
    }),
  }),
});

export const {
  useAddConnectorMutation,
  useUpdateConnectorMutation,
  useDeleteConnectorMutation,
} = api;
