import { useChargePointsFetchingContext } from 'entities/charge-point/charge-points-layout';

import { ConnectedSessionLogTable } from '../../../widgets/session/sessions-table/ui/connected-table';

export function ChargePointsSessionLogPage() {
  const { handleSetFetchingTab } = useChargePointsFetchingContext();

  return (
    <ConnectedSessionLogTable handleSetFetchingTab={handleSetFetchingTab} />
  );
}
