import { ChargePointConnectorType, CONNECTOR_TYPE } from 'entities/connector';

import { ConnectorInstructionFormData } from '../model';

export const prepareRequestData = (values: ConnectorInstructionFormData) => {
  const { instructionLinks, id, connectorType, ...rest } = values;

  return {
    ...rest,
    id,
    instructionLinks: instructionLinks ? instructionLinks.split('\n') : [],
    connectorType,
    connectorTypeName:
      CONNECTOR_TYPE[connectorType as unknown as ChargePointConnectorType],
  };
};
