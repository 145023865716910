import { useNavigate } from 'react-router-dom';

import { RcFile } from 'antd/lib/upload';

import {
  openErrorNotification,
  openSuccessNotification,
  isErrorWithMessage,
} from 'shared/lib';

import { CarBrandForm } from 'entities/car';
import {
  CarBrandFormData,
  useCustomAddCarBrandMutation,
} from 'entities/car-brand';

import { ADD_SUCCESS, ADD_ERROR } from '../consts';
import { CAR_BRAND_ROUTES } from 'shared/consts/routes/car-brands';

const initialValues: CarBrandFormData = {
  name: '',
  files: [],
};

export function AddCarBrand() {
  const [trigger, { isLoading }] = useCustomAddCarBrandMutation();

  const navigate = useNavigate();

  const handleSubmit = async (values: CarBrandFormData) => {
    const { name, files } = values;

    const formData = new FormData();

    files.forEach((file) => {
      formData.append('fileLogo', file.originFileObj as RcFile);
    });

    try {
      const res = await trigger({
        name,
        fileLogo: formData,
      }).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);

        return;
      }
      //

      openSuccessNotification(ADD_SUCCESS);

      navigate(CAR_BRAND_ROUTES.CAR_BRANDS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : ADD_ERROR;

      openErrorNotification(errorText);
    }
  };

  return (
    <CarBrandForm
      title="Добавление новой марки авто"
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      loading={isLoading}
    />
  );
}
