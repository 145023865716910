import { Menu } from 'antd';

import { CustomLink } from 'shared/ui/custom-link';

type Props = {
  id: string;
  name: string;
};

export function SectionMenuItem({ id, name }: Props) {
  return (
    <CustomLink to={id} key={id}>
      <Menu.Item key={id} eventKey={id}>
        {name}
      </Menu.Item>
    </CustomLink>
  );
}
