import { CarDto } from 'entities/car';
import { CONNECTOR_TYPE } from 'entities/connector';

import {
  CarHeader,
  CarHeaderInfo,
  CarInfoGroup,
  CarInfoSubTitle,
  CarInfoValue,
  CarModel,
  CarTitle,
  StyledCarCard,
} from './styles';

type Props = {
  car: CarDto;
  personLogin: string;
  actionsSlot: React.ReactNode;
};

export function CarCard({ car, actionsSlot }: Props) {
  const { connectorList: conList, carModel, name: carName } = car;

  const { carBrand, name } = carModel || {};

  const connectorList = conList ?? [];

  const connectorListNames = connectorList
    .filter((el) => !el.isAdapterNeeded)
    .map((connector) => CONNECTOR_TYPE[connector.connectorType])
    .join(', ');

  const adapterListNames = connectorList
    .filter((el) => el.isAdapterNeeded)
    .map((connector) => CONNECTOR_TYPE[connector.connectorType])
    .join(', ');

  return (
    <StyledCarCard>
      <CarHeader>
        <CarHeaderInfo>
          <CarTitle>{`Электромобиль ${carName}`}</CarTitle>
          <CarModel>{`${carBrand} ${name}`}</CarModel>
        </CarHeaderInfo>
        {actionsSlot}
      </CarHeader>
      <CarInfoGroup>
        <CarInfoSubTitle>Коннекторы</CarInfoSubTitle>
        <CarInfoValue>{connectorListNames}</CarInfoValue>
      </CarInfoGroup>
      <CarInfoGroup>
        <CarInfoSubTitle>Переходники</CarInfoSubTitle>
        <CarInfoValue>{adapterListNames}</CarInfoValue>
      </CarInfoGroup>
    </StyledCarCard>
  );
}
