import { COLOR } from 'shared/consts';
import { getRGBAColor } from 'shared/lib';
import { LineChartColorParams } from 'shared/ui/charts/line/types';

const color = COLOR.orange;

export const colorParams: LineChartColorParams = {
  borderColor: color,
  backgroundColor: getRGBAColor(color, '0.25'),
};

export const scalesOptions = {
  x: {
    // min: dateFrom.toISOString(),
    // max: dateTo.toISOString(),
    position: 'bottom',
    type: 'time',
    ticks: {
      autoSkip: true,
      autoSkipPadding: 50,
    },
    time: {
      displayFormats: {
        month: 'MMM/YYYY',
        day: 'D MMM',
      },
    },
  },
  y: {
    type: 'linear',
    position: 'left',
  },
};
