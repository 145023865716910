import { useParams } from 'react-router-dom';

import { EmptyData, NavMenuError } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';

import { useGetHubsQuery } from 'entities/hub';

import { useGetChargePointsQuery } from '../../../../redux/services/charge-points';

import { HubsMenu } from '../menu';

export function ConnectedHubsMenu() {
  const { hubId } = useParams() as {
    hubId: string;
  };

  const {
    data: hubs,
    isFetching: isHubsFetching,
    error: hubsError,
  } = useGetHubsQuery();

  const {
    data: chargePoints,
    isFetching: isChargePointsFetching,
    error: chargePointsError,
  } = useGetChargePointsQuery(
    {
      limit: -1,
      hubId: Number(hubId),
    },
    {
      skip: hubId === undefined,
    }
  );

  const apiResponseError = [hubs, chargePoints].find(
    (el) => el && el.statusCode !== 0
  );

  if (apiResponseError) {
    return <NavMenuError text={apiResponseError.statusDescription} />;
  }

  const error = [hubsError, chargePointsError].find((err) => err !== undefined);

  if (error) {
    return <NavMenuError text={getServerErrorText(error)} />;
  }

  if (!isHubsFetching && !isChargePointsFetching && !hubs) {
    return <EmptyData />;
  }

  const items = hubs?.hubs || [];
  const chargePointsItems = chargePoints?.chargePoints || [];

  return (
    <HubsMenu
      isHubsLoading={isHubsFetching}
      items={items}
      isChargePointsLoading={isChargePointsFetching}
      chargePoints={chargePointsItems}
    />
  );
}
