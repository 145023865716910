import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';

import {
  ConnectedCarForm,
  useAddCarMutation,
  getConnectorListFromFormData,
  getTableData,
  CarFormData,
} from 'entities/car';
import { CONNECTOR_TYPE } from 'entities/connector';

import { ADD_ERROR, ADD_SUCCESS } from '../../consts';

const getInitialValues = () => {
  const connectorValues = Object.keys(CONNECTOR_TYPE).reduce((acc, el) => {
    acc[el] = false;
    acc[`${el}/adapter`] = false;

    return acc;
  }, {} as Record<string, boolean>);

  return {
    name: '',
    carModelId: '',
    carBrandName: '',
    ...connectorValues,
  };
};

type Props = {
  personLogin: string;
  closeModal: () => void;
};

export function AddCar({ closeModal, personLogin }: Props) {
  const [trigger, { isLoading }] = useAddCarMutation();

  const handleSubmit = async (values: CarFormData) => {
    const { name, carModelId, carBrandName, ...connectorsValues } = values;

    const connectorList = getConnectorListFromFormData(connectorsValues);

    const req = {
      name,
      carModelId: Number(carModelId),
      connectorList,
      personLogin,
    };

    try {
      const res = await trigger(req).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);

        return;
      }
      //

      openSuccessNotification(ADD_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : ADD_ERROR;

      openErrorNotification(errorText);
    } finally {
      closeModal();
    }
  };

  const tableData = getTableData();

  const initialValues = getInitialValues();

  return (
    <ConnectedCarForm
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      loading={isLoading}
      tableData={tableData}
      closeModal={closeModal}
    />
  );
}
