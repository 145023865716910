import { Menu, Spin } from 'antd';
import { useLocation, useParams } from 'react-router-dom';

import { CustomLink } from 'shared/ui';
import { CAR_BRAND_ROUTES } from 'shared/consts/routes/car-brands';

import { CarBrandListItem } from 'entities/car';
import { CarBrand } from 'entities/car-brand';
import { CarModel } from 'entities/car-model';

import { StyledBasicMenu } from '../../../../common-styles/basic-menu';
import { AddButton } from './add-button';

const keyPath = CAR_BRAND_ROUTES.CAR_BRANDS;
const label = 'Марки EV';

type Props = {
  loading: boolean;
  brands: Array<CarBrandListItem>;
};

export function CarModelsMenu({ loading, brands }: Props) {
  const { carBrandId, carModelId } = useParams() as {
    carBrandId: string;
    carModelId: string;
  };

  let location = useLocation();

  return (
    <>
      <AddButton />
      <StyledBasicMenu
        openKeys={carBrandId && !loading ? ['root', carBrandId] : ['root']}
        mode="inline"
        inlineIndent={15}
      >
        <Spin spinning={loading}>
          <CustomLink to={keyPath} checkIsActive={(path) => path === keyPath}>
            <Menu.SubMenu key="root" title={label} eventKey="root">
              {brands.map((el) => buildMenuItems(el))}
            </Menu.SubMenu>
          </CustomLink>
        </Spin>
      </StyledBasicMenu>
    </>
  );

  function renderCarModelMenuItem(model: CarModel, brandId: number) {
    const { name, id } = model;

    const modelPath = `${brandId}/${id}`;

    return (
      <CustomLink
        to={modelPath}
        key={modelPath}
        checkIsActive={(path) => {
          return path.endsWith(modelPath);
        }}
      >
        <Menu.Item key={modelPath} eventKey={modelPath}>
          {name}
        </Menu.Item>
      </CustomLink>
    );
  }

  function buildMenuItems(brand: CarBrand) {
    const { id, name, carModels } = brand;

    const content = carModels.map((model) => renderCarModelMenuItem(model, id));

    const path = String(id) === carBrandId ? keyPath : String(id);

    return (
      <CustomLink to={path} key={path}>
        <Menu.SubMenu key={String(id)} title={name} eventKey={String(id)}>
          {content}
        </Menu.SubMenu>
      </CustomLink>
    );
  }
}
