import { ExamplesList, Variable, Wrapper } from './styles';

export function DiscountDescription() {
  return (
    <Wrapper>
      <p>
        Скидка задается в виде формулы, которая применяется к общей стоимости
        зарядки.
      </p>
      <p>Абсолютные значения стоимости задаются в копейках.</p>
      <p>Переменные, которые можно использовать в формуле:</p>
      <p>
        <Variable>InitialCost</Variable> - финальная стоимость
      </p>
      <p>
        <Variable>ConsumptionKwt</Variable> - количество потребленных кВт*ч
      </p>
      <p>
        <Variable>SessionLengthMin</Variable> - продолжительность сессии в
        минутах
      </p>
      <br />
      <p>Примеры:</p>
      <ExamplesList>
        <li>
          Скидка на тариф в размере 10% и так же минус 1 руб. с каждого
          киловатт*часа:<p>InitialCost*0.9 - 100*ConsumptionKwt</p>
        </li>
        <li>
          Установить фиксированную стоимость за кВт*ч в 12 рублей:
          <p>1200*ConsumptionKwt</p>
        </li>
        <li>
          Сделать скидку в 100 рублей к общей сумме:<p>InitialCost - 10000</p>
        </li>
      </ExamplesList>
    </Wrapper>
  );
}
