import { MainLayout } from 'shared/ui/layout/main';

import { ChargePointFetchingContextProvider } from '../../context';
import { ChargePointTabs } from '../tabs';

export function ChargePointLayout() {
  return (
    <ChargePointFetchingContextProvider>
      <MainLayout tabs={<ChargePointTabs />} />
    </ChargePointFetchingContextProvider>
  );
}
