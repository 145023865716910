import { Col, Form, FormProps, Select, Spin } from 'antd';
import { useState } from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';

import { openErrorNotification, openSuccessNotification } from 'shared/lib';
import { UiInput, UiSelect } from 'shared/ui/ui-kit';

import { ManualCommandType } from 'entities/manual-command';

import { ADD_ERROR, ADD_SUCCESS } from '../../../consts';

import {
  FormButtonsContainer,
  FormItem,
  FormRow,
  StyledButton,
} from '../../../../../common-styles';

import { useGetDiagnosticsMutation } from '../../../../../redux/services/manual-command';
import { VALIDATION_RULE } from '../../../../../constants/validation-rules';
import { useGetFirmwaresQuery } from 'entities/firmware';

const { Option } = Select;

interface FormData {
  chargePointName: string;
  chargePointId: string;
  interval: string;
  retries: string;
  startTime: string;
  stopTime: string;
}

type Props = {
  commandType: ManualCommandType;
  cpName: string;
  closeModal: () => void;
  isDisabled?: boolean;
  chargePointId?: string;
};

export function GetDiagnosticsForm({
  closeModal,
  isDisabled = false,
  commandType,
  cpName,
  chargePointId,
}: Props) {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(isDisabled);

  const [getDiagnosticsTrigger, { isLoading }] = useGetDiagnosticsMutation();

  const { data, isLoading: isFirmwaresLoading } = useGetFirmwaresQuery();

  const [form] = Form.useForm<FormData>();

  const onValuesChange: FormProps<FormData>['onValuesChange'] = (
    _,
    allValues
  ) => {
    if (
      allValues.chargePointName !== '' &&
      allValues.interval !== '' &&
      allValues.retries !== '' &&
      allValues.startTime !== '' &&
      allValues.stopTime !== ''
    ) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  const handleSubmit = async (values: FormData) => {
    const req = {
      chargePointId,
      startTime: dayjs(values.startTime).toISOString(),
      stopTime: dayjs(values.stopTime).toISOString(),
      retries: Number(values.retries),
      retryInterval: Number(values.interval),
    };

    try {
      const res = await getDiagnosticsTrigger(req).unwrap();
      res.statusCode === 0
        ? openSuccessNotification(ADD_SUCCESS)
        : openErrorNotification(res.statusDescription);
    } catch (e) {
      openErrorNotification(ADD_ERROR);
    } finally {
      closeModal();
    }
  };

  const getInitialValues = () => {
    return {
      chargePointName: cpName,
      interval: 3,
      retries: 3,
      startTime: dayjs().subtract(1, 'day'),
      stopTime: dayjs(),
    };
  };

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onFinish={handleSubmit}
      onValuesChange={onValuesChange}
      initialValues={getInitialValues()}
    >
      <FormRow gutter={10}>
        <Col span={24}>
          <FormItem
            label="Наименование ЭЗС"
            name="chargePointName"
            rules={[VALIDATION_RULE.REQUIRED]}
          >
            <UiInput disabled />
          </FormItem>
        </Col>
      </FormRow>
      <FormRow gutter={10}>
        <Col span={12}>
          <FormItem
            label="Количество попыток"
            name="retries"
            rules={[VALIDATION_RULE.REQUIRED]}
          >
            <UiInput />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            label="Интервалы"
            name="interval"
            rules={[VALIDATION_RULE.REQUIRED]}
          >
            <UiInput />
          </FormItem>
        </Col>
      </FormRow>
      <FormRow gutter={10}>
        <Col span={12}>
          <FormItem
            label="C:"
            name="startTime"
            rules={[VALIDATION_RULE.REQUIRED]}
          >
            <DatePicker showTime />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            label="По:"
            name="stopTime"
            rules={[VALIDATION_RULE.REQUIRED]}
          >
            <DatePicker showTime />
          </FormItem>
        </Col>
      </FormRow>
      <FormButtonsContainer>
        <Spin spinning={isLoading || isFirmwaresLoading}>
          <StyledButton
            htmlType="submit"
            type="primary"
            disabled={isSubmitDisabled}
          >
            Добавить
          </StyledButton>
        </Spin>
        <StyledButton htmlType="button" type="default" onClick={closeModal}>
          Отмена
        </StyledButton>
      </FormButtonsContainer>
    </Form>
  );
}
