import { useNavigate } from 'react-router-dom';

import {
  openErrorNotification,
  openSuccessNotification,
  isErrorWithMessage,
} from 'shared/lib';

import {
  OcpiHubFormData,
  OcpiHubForm,
  useAddOcpiHubMutation,
} from 'entities/ocpi-hub';

import { ADD_ERROR, ADD_SUCCESS } from '../consts';
import { OCPI_HUB_ROUTES } from 'shared/consts/routes/ocpi-hub';

const initialValues: OcpiHubFormData = {
  id: '',
  name: '',
  legalEntity: '',
  urlOcpiApi: '',
  accessTokenSitronics: '',
  accessTokenParty: '',
  firstAccessTokenSitronics: '',
  firstAccessTokenParty: '',
  contractNumber: '',
  description: '',
  inn: '',
};

export function AddOcpiHub() {
  const [trigger, { isLoading }] = useAddOcpiHubMutation();

  const navigate = useNavigate();

  const handleSubmit = async (values: OcpiHubFormData) => {
    const { id, ...rest } = values;

    try {
      const res = await trigger(rest).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);

        return;
      }
      //

      openSuccessNotification(ADD_SUCCESS);

      navigate(`${OCPI_HUB_ROUTES.OCPI_HUBS}/${res.ocpiHub.id}`);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : ADD_ERROR;

      openErrorNotification(errorText);
    }
  };

  return (
    <OcpiHubForm
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      loading={isLoading}
      title="Добавление нового OCPI Хаба"
    />
  );
}
