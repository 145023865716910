import { ControllerClaim } from './types';

export const CP_PREFIX = 'cp';
export const GROUP_PREFIX = 'group';
export const DELIMETER = '/';

export const SUPERUSER_ROLE = 'Admin';

const BASE_RIGHT = {
  read: false,
  write: false,
  execute: false,
};

export const CONTROLLERS: ControllerClaim[] = [
  { controllerName: 'Bank', label: 'Банки', ...BASE_RIGHT },
  { controllerName: 'Car', label: 'Марки и модели', ...BASE_RIGHT },
  { controllerName: 'ChargePoint', label: 'ЭЗС', ...BASE_RIGHT },
  { controllerName: 'ChargeTariff', label: 'Тарифы', ...BASE_RIGHT },
  { controllerName: 'Connector', label: 'Коннекторы', ...BASE_RIGHT },
  { controllerName: 'Country', label: 'Страны', ...BASE_RIGHT },
  { controllerName: 'Cpo', label: 'Операторы ЭЗС', ...BASE_RIGHT },
  { controllerName: 'Firmware', label: 'Прошивки', ...BASE_RIGHT },
  { controllerName: 'Group', label: 'Группы', ...BASE_RIGHT },
  { controllerName: 'Hub', label: 'Хабы', ...BASE_RIGHT },
  {
    controllerName: 'Instruction',
    label: 'Инструкции коннекторов',
    ...BASE_RIGHT,
  },
  {
    controllerName: 'Identity',
    label: 'Управление пользователями',
    ...BASE_RIGHT,
  },
  {
    controllerName: 'MobileApp',
    label: 'Мобильные приложения',
    ...BASE_RIGHT,
  },
  // { controllerName: 'Metrics', label: 'Метрики', ...BASE_RIGHT },
  { controllerName: 'OcpiHubs', label: 'OCPI Хабы', ...BASE_RIGHT },
  { controllerName: 'Owner', label: 'Владельцы ЭЗС', ...BASE_RIGHT },
  { controllerName: 'Person', label: 'Клиенты', ...BASE_RIGHT },
  {
    controllerName: 'PrivateMethods',
    label: 'Управление ЭЗС',
    ...BASE_RIGHT,
  },
  {
    controllerName: 'PushNotification',
    label: 'Пуш уведомления',
    ...BASE_RIGHT,
  },
  { controllerName: 'Report', label: 'Отчеты', ...BASE_RIGHT },
  { controllerName: 'Settings', label: 'Настройки приложения', ...BASE_RIGHT },
  { controllerName: 'ManualCommand', label: 'Ручные команды', ...BASE_RIGHT },
  { controllerName: 'Discount', label: 'Скидки', ...BASE_RIGHT },
  {
    controllerName: 'BonusService',
    label: 'Бонусные программы',
    ...BASE_RIGHT,
  },
];
