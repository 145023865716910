import { Form, Button, Row } from 'antd';
import styled from 'styled-components';

import { COLOR } from 'shared/consts';

export const FormRow = styled(Row)`
  margin-bottom: 10px;
  width: 100%;

  @media (max-width: 375px) {
    margin-bottom: 15px;
  }
`;

export const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0;
`;

export const ButtonContainer = styled.div`
  margin-top: 33px;
`;

export const StyledButton = styled(Button)`
  width: 100%;
  height: auto;
  text-align: center;
  padding-top: 13px;
  padding-bottom: 12px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;

  & .ant-checkbox + span {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: ${COLOR.inputGrey};
  }

  & .ant-checkbox.ant-checkbox-checked + span {
    color: ${COLOR.black};
  }
`;

export const LoginErrorMessage = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR.red};
`;
