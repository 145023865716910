import {
  ChargePointProtocol,
  ChargePointStatus,
  ChargePointType,
  MapStatus,
} from 'entities/charge-point';

import { ChargePointFormData } from './types';

const PARAM_DELIMETER = '\n';
const KEY_VALUE_DELIMETER = '=';

const getOcppConfigObject = (configString: string): Record<string, string> => {
  const arr = configString.split(PARAM_DELIMETER);

  return arr.reduce((acc, el) => {
    const [key, val] = el.trim().split(KEY_VALUE_DELIMETER);

    acc[key] = val;

    return acc;
  }, {} as Record<string, string>);
};

export const getOcppConfigFormString = (jsonOcppConfig: string) => {
  const configObject = JSON.parse(jsonOcppConfig);

  return Object.entries(configObject)
    .map(([key, value]) => `${key}${KEY_VALUE_DELIMETER}${value}`)
    .join(PARAM_DELIMETER);
};

export const getValidatedFormData = (values: ChargePointFormData) => {
  const { id, advancedProperty, cardSettings, techInfo, ocppConfig } = values;

  const config = ocppConfig.ocppConfig;

  const techInfoVals = {
    ...techInfo,
    type: Number(techInfo.type) as ChargePointType,
    status: Number(techInfo.status) as ChargePointStatus,
    ownerId: Number(techInfo.ownerId),
    idGroup: Number(techInfo.idGroup),
    maxPower: Number(techInfo.maxPower),
    chargePointProtocol: Number(
      techInfo.chargePointProtocol
    ) as ChargePointProtocol,
  };

  const cardSettingsVals = {
    ...cardSettings,
    latitude: Number(cardSettings.latitude),
    longitude: Number(cardSettings.longitude),
    hubId: cardSettings.hubId !== '' ? Number(cardSettings.hubId) : null,
    mapStatus: Number(cardSettings.mapStatus) as MapStatus,
    userLocationCheckDistance: Number(cardSettings.userLocationCheckDistance),
  };

  return {
    id,
    ...techInfoVals,
    advancedProperty,
    ...cardSettingsVals,
    ocppConfig:
      config === ''
        ? ''
        : JSON.stringify(getOcppConfigObject(ocppConfig.ocppConfig)),
  };
};
