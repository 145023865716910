export const selectCurrentToken = (state: RootState) => {
  const sessionAccessToken = state.authSession.accessToken;
  const accessToken = state.auth.accessToken;

  if (accessToken) return accessToken;

  if (sessionAccessToken) return sessionAccessToken;

  return '';
};
