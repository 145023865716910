import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Leaves } from 'shared/lib/types';

import { ManualCommand } from 'entities/manual-command';

type ObjectLeaves = Leaves<ManualCommand>;

export type TableColumns = Extract<
  ObjectLeaves,
  | 'sendTime'
  | 'sender'
  | 'commandType'
  | 'status'
  | 'chargePointName'
  | 'connectorId'
>;

export type TableColumnsState = Record<TableColumns, boolean>;

const initialState: TableColumnsState = {
  chargePointName: true,
  commandType: true,
  connectorId: true,
  sender: true,
  sendTime: true,
  status: true,
};

const slice = createSlice({
  name: 'manualCommandsTable',
  initialState,
  reducers: {
    setVisibleColumns: (state, action: PayloadAction<TableColumnsState>) => {
      return action.payload;
    },
  },
});

export const { setVisibleColumns } = slice.actions;
export default slice.reducer;
