import { ChargePointListFiltersContainer, MetricsContainer } from './styles';

import { ConnectedChargePointsTable } from 'features/charge-point/table';
import { ConnectedChargedTotal } from 'features/charge-point/charged-total';

import { ShorthandedFilters } from 'widgets/charge-point/filters';

import { ChargePointListDashboards } from '../../../components/MetricsCard/ChargePointListDashboards';

export function ChargePointListPage() {
  return (
    <>
      <ChargePointListFiltersContainer>
        <ShorthandedFilters />
      </ChargePointListFiltersContainer>
      <MetricsContainer>
        <ChargePointListDashboards />
        <ConnectedChargedTotal />
      </MetricsContainer>
      <ConnectedChargePointsTable />
    </>
  );
}
