import { Col, Form, FormProps } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useWatch } from 'antd/lib/form/Form';

import { formHasErrors, VALIDATION_RULE } from 'shared/lib';
import {
  ButtonsContainer,
  UiSubmitButton,
  UiEditFormButton,
  UiCancelFormButton,
  FormControlsContainer,
} from 'shared/ui';
import { UiCancelButton } from 'shared/ui/buttons';
import { UiInput, UiSelect } from 'shared/ui/ui-kit';

import {
  selectTokenRoles,
  selectTokenClaims,
  checkControllerRight,
} from 'entities/authorization';
import {
  BonusLevelFormData,
  BonusLevelFormProps,
} from 'entities/bonus-program/model/levels-form';
import { AWARD_TYPE } from 'entities/bonus-program';

import { CardFormWrapper, FormItem, FormRow } from '../../../../common-styles';

type FormData = BonusLevelFormData;

export function BonusLevelForm({
  handleSubmit,
  initialValues,
  loading,
  disabled = false,
  onCancel,
}: BonusLevelFormProps) {
  const roles = useSelector(selectTokenRoles);
  const claims = useSelector(selectTokenClaims);

  const hasWriteRight = checkControllerRight(
    'BonusService',
    'Write',
    roles,
    claims
  );

  const [isFormDisabled, setIsFormDisabled] = useState(disabled);

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [hasErrors, setHasErrors] = useState(false);

  const [form] = Form.useForm<FormData>();

  const awardType = useWatch('awardType', form);

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  useEffect(() => {
    if (awardType === undefined) {
      return;
    }

    if (awardType === '0') {
      form.setFieldValue('awardSum', '0');
    } else {
      form.setFieldValue('awardPercent', '0');
    }
  }, [awardType]);

  const onFieldsChange: FormProps<FormData>['onFieldsChange'] = (
    _,
    _allFields
  ) => {
    setHasErrors(formHasErrors(form));
  };

  const onValuesChange: FormProps<FormData>['onValuesChange'] = (
    _,
    allValues
  ) => {
    if (
      allValues.name !== '' &&
      allValues.condition !== '' &&
      allValues.awardSum !== '' &&
      allValues.awardPercent !== ''
    ) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  const primaryButton = isFormDisabled ? (
    <UiEditFormButton onClick={() => setIsFormDisabled(false)} />
  ) : (
    <UiSubmitButton disabled={isSubmitDisabled || hasErrors} />
  );

  let formControls: JSX.Element | null = null;

  if (hasWriteRight) {
    formControls = (
      <FormControlsContainer>
        <ButtonsContainer spinning={loading}>
          {primaryButton}
          <UiCancelButton onClick={onCancel} />
        </ButtonsContainer>
      </FormControlsContainer>
    );
  }

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={handleSubmit}
        onFieldsChange={onFieldsChange}
        onValuesChange={onValuesChange}
        initialValues={initialValues}
      >
        <FormRow gutter={20}>
          <Col span={24}>
            <FormItem
              label="Название"
              name="name"
              rules={[VALIDATION_RULE.REQUIRED]}
            >
              <UiInput disabled={isFormDisabled} />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow gutter={20}>
          <Col span={24}>
            <FormItem
              label="Уровень"
              name="level"
              rules={[
                VALIDATION_RULE.REQUIRED,
                VALIDATION_RULE.NO_WHITESPACE,
                VALIDATION_RULE.POSITIVE_INTEGER,
              ]}
            >
              <UiInput disabled={isFormDisabled} />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow gutter={20}>
          <Col span={24}>
            <FormItem
              label="Условие"
              name="condition"
              rules={[
                VALIDATION_RULE.REQUIRED,
                VALIDATION_RULE.NO_WHITESPACE,
                VALIDATION_RULE.NULL_OR_POSITIVE_NUMBER,
              ]}
            >
              <UiInput disabled={isFormDisabled} />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow gutter={20}>
          <Col span={24}>
            <FormItem
              label="Тип вознаграждения"
              name="awardType"
              rules={[VALIDATION_RULE.REQUIRED]}
            >
              <UiSelect
                disabled={isFormDisabled}
                options={Object.entries(AWARD_TYPE).map((entry) => {
                  const [key, label] = entry;

                  return { value: key, label };
                })}
              />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow gutter={20}>
          <Col span={24}>
            <FormItem
              label="Размер кешбека в %"
              name="awardPercent"
              rules={[
                VALIDATION_RULE.REQUIRED,
                VALIDATION_RULE.NO_WHITESPACE,
                VALIDATION_RULE.NULL_OR_POSITIVE_NUMBER,
              ]}
            >
              <UiInput disabled={awardType === '1'} />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow gutter={20}>
          <Col span={24}>
            <FormItem
              label="Размер кешбека в руб."
              name="awardSum"
              rules={[
                VALIDATION_RULE.REQUIRED,
                VALIDATION_RULE.NO_WHITESPACE,
                VALIDATION_RULE.NULL_OR_POSITIVE_NUMBER,
              ]}
            >
              <UiInput disabled={awardType === '0'} />
            </FormItem>
          </Col>
        </FormRow>
        {formControls}
      </Form>
    </div>
  );
}
