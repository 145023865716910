import { TariffFormData, TriggerType } from '../model';

export const getTriggerType = (values: TariffFormData): TriggerType => {
  const { batteryPercentageTrigger, powerDropTrigger } = values;

  if (batteryPercentageTrigger) {
    return 1;
  }

  if (powerDropTrigger) {
    return 2;
  }

  return 0;
};
