import { toConsumeUnit, toCurrencyUnit } from 'shared/lib';

import { GraphMetric, GraphMetricType } from '../model/metrics';

export const transformMetricsData = (
  type: GraphMetricType,
  metricsData: GraphMetric[]
) => {
  if (type === '0') {
    return metricsData;
  }

  if (type === '1') {
    return metricsData.map(({ name, value }) => ({
      name,
      value: Number(toConsumeUnit(value)),
    }));
  }

  if (type === '2') {
    return metricsData.map(({ name, value }) => ({
      name,
      value: Number(toCurrencyUnit(value)),
    }));
  }

  return metricsData;
};
