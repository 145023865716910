import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Leaves } from 'shared/lib/types';

import { OwnerDto } from 'entities/owner';

type ObjectLeaves = Leaves<OwnerDto>;

export type TableColumns = Extract<
  ObjectLeaves,
  | 'id'
  | 'name'
  | 'legalName'
  | 'description'
  | 'tariff.name'
  | 'phoneNumber'
  | 'email'
  | 'inn'
  | 'kpp'
  | 'agentReceipt'
>;

export type TableColumnsState = Record<TableColumns, boolean>;

const initialState: TableColumnsState = {
  'tariff.name': true,
  agentReceipt: true,
  description: true,
  email: true,
  id: true,
  inn: true,
  kpp: true,
  legalName: true,
  name: true,
  phoneNumber: true,
};

const slice = createSlice({
  name: 'ownersTable',
  initialState,
  reducers: {
    setVisibleColumns: (state, action: PayloadAction<TableColumnsState>) => {
      return action.payload;
    },
  },
});

export const { setVisibleColumns } = slice.actions;
export default slice.reducer;
