import { NavMenuErrorText } from './styles';

type Props = {
  text?: string;
};

const DEFAULT_ERROR = 'Ошибка получения данных...';

export function NavMenuError({ text = DEFAULT_ERROR }: Props) {
  return <NavMenuErrorText>{text}</NavMenuErrorText>;
}
