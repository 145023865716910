import { ADD_PREFIX } from './common';

export const HUBS = 'hubs';
export const HUB = ':hubId';

export const HUB_ROUTES = {
  HUBS: `/${HUBS}`,
  HUB: `/${HUBS}/${HUB}`,
  ADD_HUB: `/${HUBS}/${ADD_PREFIX}`,
};
