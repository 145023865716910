import { Route } from 'react-router-dom';

import { SECURITY } from 'shared/consts/routes/common';

import { SecuritySidebar } from 'widgets/sidebar/security';
import { ChapterLayout } from 'widgets/layout/chapter';

import { UserRoutes } from './user';
import { RoleRoutes } from './role';
import { UsersGroupRoutes } from './users-group';

export const SecurityRoutes = (
  <Route
    path={SECURITY}
    element={<ChapterLayout siderbar={<SecuritySidebar />} />}
  >
    {UserRoutes}
    {RoleRoutes}
    {UsersGroupRoutes}
  </Route>
);
