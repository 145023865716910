import { EmptyData, NavMenuError } from 'shared/ui';
import { SectionMenu } from 'shared/ui/section-menu';

import { SECTION } from 'entities/navigation';
import { useGetUsersQuery } from 'entities/user';
import { USER_ROUTES } from 'shared/consts/routes/user';

const { label, path } = SECTION['users'];

export function ConnectedUsersMenu() {
  const { data, isFetching, error } = useGetUsersQuery();

  if (data && data.statusCode !== 0) {
    return <NavMenuError text={data.statusDescription} />;
  }

  if (error) {
    return <NavMenuError />;
  }

  if (!isFetching && !data) {
    return <EmptyData />;
  }

  const items = (data?.users || []).map(({ userName }) => ({
    id: encodeURIComponent(userName),
    name: userName,
  }));

  return (
    <SectionMenu
      loading={isFetching}
      items={items}
      menuKey={path}
      absolutePath={USER_ROUTES.USERS}
      label={label}
    />
  );
}
