import { useNavigate, useParams } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';

import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
  getServerErrorText,
} from 'shared/lib';
import { EmptyData, ErrorMessage } from 'shared/ui';

import { ConnectedCarModelForm, prepareRequestData } from 'entities/car';

import {
  CarModelDto,
  CarModelFormData,
  useGetCarModelQuery,
  useUpdateCarModelMutation,
} from 'entities/car-model';

import { DeleteCarModelModal } from 'features/car/delete-car-model';

import { EDIT_SUCCESS, EDIT_ERROR } from '../consts';
import { CAR_BRAND_ROUTES } from 'shared/consts/routes/car-brands';

const getInitialValues = (carModel: CarModelDto): CarModelFormData => {
  const { carBrand, name } = carModel;

  return {
    name,
    carBrandId: String(carBrand),
  };
};

export function EditCarModel() {
  const [trigger, { isLoading: isMutationLoading }] =
    useUpdateCarModelMutation();

  const navigate = useNavigate();

  const { carModelId } = useParams() as { carModelId: string };

  const { isFetching, error, data } = useGetCarModelQuery(carModelId);

  const handleSubmit = async (values: CarModelFormData) => {
    const req = { ...prepareRequestData(values), id: Number(carModelId) };

    try {
      const res = await trigger(req).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);
        return;
      }
      //

      openSuccessNotification(EDIT_SUCCESS);

      navigate(CAR_BRAND_ROUTES.CAR_BRANDS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : EDIT_ERROR;

      openErrorNotification(errorText);
    }
  };

  if (isFetching) {
    return <div>Loading...</div>;
  }

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!data?.carModel) {
    return <EmptyData />;
  }

  const initialValues = getInitialValues(data.carModel);

  const showDeleteModal = () => {
    NiceModal.show(DeleteCarModelModal, {
      id: String(data.carModel.id),
      name: data.carModel.name,
    });
  };

  return (
    <ConnectedCarModelForm
      handleSubmit={handleSubmit}
      loading={isMutationLoading}
      title={data.carModel.name}
      initialValues={initialValues}
      disabled
      onDeleteClick={showDeleteModal}
    />
  );
}
