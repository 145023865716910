import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import useMeasure from 'react-use-measure';
import useWindowFocus from 'use-window-focus';

import { ErrorMessage } from 'shared/ui';
import { getServerErrorText, useTablePageSize } from 'shared/lib';
import { POLLING_INTERVAL } from 'shared/consts';

import { useGetPacketsQuery } from 'entities/private-methods';

import { useGetChargePointQuery } from '../../../../redux/services/charge-points';
import { EventsTableInstance } from './table';

type Props = {
  chargePointName: string;
  handleSetFetchingTab: (val: string) => void;
};

export function ConnectedEventsTable({
  chargePointName,
  handleSetFetchingTab,
}: Props) {
  const isWindowFocused = useWindowFocus();

  const [searchParams, setSearchParams] = useSearchParams();

  const [tableHeadRef, tableHeadBounds] = useMeasure();

  const [tablePageSize, setTablePageSize] = useTablePageSize(tableHeadBounds);

  const ordering = searchParams.get('ordering');
  const type = searchParams.get('type');
  // const cpName = searchParams.get('cpName');
  const page = searchParams.get('page');
  const limit = searchParams.get('limit');
  const search = searchParams.get('search');
  const searchText = searchParams.get('searchText');
  const dateFrom = searchParams.get('dateFrom');
  const dateTo = searchParams.get('dateTo');

  const {
    data: chargePointData,
    isLoading: isChargePointLoading,
    error: chargePointError,
  } = useGetChargePointQuery(chargePointName);

  const {
    data,
    isFetching,
    error: packetsError,
    currentData,
    isSuccess,
  } = useGetPacketsQuery(
    {
      limit: limit || String(tablePageSize),
      ordering,
      page: page || String(1),
      type,
      cpName: chargePointName,
      search,
      searchText,
      dateFrom,
      dateTo,
    },
    {
      skip: !limit && !tablePageSize,
      pollingInterval: isWindowFocused ? POLLING_INTERVAL : undefined,
    }
  );

  useEffect(() => {
    if (isFetching) {
      handleSetFetchingTab('events');
    } else {
      handleSetFetchingTab('');
    }

    return () => {
      handleSetFetchingTab('');
    };
  }, [isFetching]);

  const tableData = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.packets;
  }, [data]);

  const connectors = useMemo(() => {
    if (!chargePointData) {
      return [];
    }

    return chargePointData.chargePoint.connectors;
  }, [chargePointData]);

  const apiResponseError = [data, chargePointData].find(
    (el) => el && el.statusCode !== 0
  );

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusDescription} />;
  }

  const error = [packetsError, chargePointError].find(
    (err) => err !== undefined
  );

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  return (
    <EventsTableInstance
      tableData={tableData}
      loading={isChargePointLoading || (isFetching && !currentData)}
      count={data?.count}
      tableHeadRef={tableHeadRef}
      tablePageSize={tablePageSize}
      connectors={connectors}
    />
  );
}
