import { useNavigate } from 'react-router-dom';

import {
  openErrorNotification,
  openSuccessNotification,
  isErrorWithMessage,
} from 'shared/lib';

import {
  CountryForm,
  CountryFormData,
  useAddCountryMutation,
  // useAddCountryMutation,
} from 'entities/country';

//

import { ADD_SUCCESS, ADD_ERROR } from '../consts';
import { COUNTRY_ROUTES } from 'shared/consts/routes/country';

const initialValues: CountryFormData = {
  id: '',
  name: '',
  code: '',
  currencySymbol: '',
  currencyText: '',
  phonePrefix: '',
  phoneMask: '',
  urlFlag: '',
  description: '',
  language: '',
  privacyPolicyUrl: '',
};

export function AddCountry() {
  const [trigger, { isLoading }] = useAddCountryMutation();

  const navigate = useNavigate();

  const handleSubmit = async (values: CountryFormData) => {
    const { id, ...rest } = values;

    try {
      const res = await trigger(rest).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);

        return;
      }
      //

      openSuccessNotification(ADD_SUCCESS);

      navigate(`${COUNTRY_ROUTES.COUNTRIES}/${res.country.id}`);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : ADD_ERROR;

      openErrorNotification(errorText);
    }
  };

  return (
    <CountryForm
      title="Добавление новой страны"
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      loading={isLoading}
    />
  );
}
