import { useNavigate, useParams } from 'react-router-dom';

import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
  getServerErrorText,
} from 'shared/lib';

import { EmptyData, ErrorMessage } from 'shared/ui';

import {
  MobileAppForm,
  MobileAppFormData,
  useUpdateMobileAppMutation,
  useGetMobileAppQuery,
  MobileAppDto,
} from 'entities/mobile-app';

import { EDIT_SUCCESS, EDIT_ERROR } from '../consts';

const getInitialValues = (mobileApp: MobileAppDto): MobileAppFormData => {
  const { id, name, description } = mobileApp;

  return { id: String(id), name, description };
};

export function EditMobileApp() {
  const [trigger, { isLoading: isMutationLoading }] =
    useUpdateMobileAppMutation();

  const navigate = useNavigate();

  const { mobileAppId } = useParams() as { mobileAppId: string };

  const { isFetching, error, data } = useGetMobileAppQuery(mobileAppId);

  const handleSubmit = async (values: MobileAppFormData) => {
    const req = { ...values, id: Number(values.id) };

    try {
      const res = await trigger(req).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);
        return;
      }
      //

      openSuccessNotification(EDIT_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : EDIT_ERROR;

      openErrorNotification(errorText);
    }
  };

  if (isFetching) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  if (!data?.mobileApp) {
    return <EmptyData />;
  }

  const initialValues = getInitialValues(data.mobileApp);

  return (
    <MobileAppForm
      handleSubmit={handleSubmit}
      loading={isMutationLoading}
      initialValues={initialValues}
      title={data.mobileApp.name}
      disabled
    />
  );
}
