import { ADD_PREFIX } from './common';

export const TARIFFS = 'tariffs';
export const TARIFF = ':tariffId';

export const TARIFFS_ROUTES = {
  TARIFFS: `/${TARIFFS}`,
  TARIFF: `/${TARIFFS}/${TARIFF}`,
  ADD_TARIFF: `/${TARIFFS}/${ADD_PREFIX}`,
};
