import { useParams, useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';
import useMeasure from 'react-use-measure';

import { ErrorMessage } from 'shared/ui';
import { getServerErrorText, useTablePageSize } from 'shared/lib';

import { useGetClientBankCardSessionsQuery } from 'entities/bank-card';

import { ClientBankCardSessionsTableInstance } from './table';

export function ConnectedClientBankCardSessions() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [tableHeadRef, tableHeadBounds] = useMeasure();

  const [tablePageSize, setTablePageSize] = useTablePageSize(tableHeadBounds);

  const { userLogin } = useParams() as { userLogin: string };

  const ordering = searchParams.get('ordering');
  const type = searchParams.get('type');
  const page = searchParams.get('page');
  const limit = searchParams.get('limit');
  const search = searchParams.get('search');
  const searchText = searchParams.get('searchText');
  const dateFrom = searchParams.get('dateFrom');
  const dateTo = searchParams.get('dateTo');

  const { data, isFetching, error } = useGetClientBankCardSessionsQuery({
    limit: limit || String(tablePageSize),
    ordering,
    page: page || String(1),
    type,
    phone: userLogin,
    search,
    searchText,
    dateFrom,
    dateTo,
  });

  const tableData = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.sessions;
  }, [data]);

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  return (
    <ClientBankCardSessionsTableInstance
      tableData={tableData}
      loading={isFetching}
      count={data?.count}
      tableHeadRef={tableHeadRef}
      tablePageSize={tablePageSize}
    />
  );
}
