import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Leaves } from 'shared/lib/types';

import { CarModelDto } from 'entities/car-model';

type ObjectLeaves = Leaves<CarModelDto>;

export type TableColumns =
  | Extract<ObjectLeaves, 'id' | 'name'>
  | 'carBrand.name';

export type TableColumnsState = Record<TableColumns, boolean>;

const initialState: TableColumnsState = {
  id: true,
  name: true,
  'carBrand.name': true,
};

const slice = createSlice({
  name: 'carModelsTable',
  initialState,
  reducers: {
    setVisibleColumns: (state, action: PayloadAction<TableColumnsState>) => {
      return action.payload;
    },
  },
});

export const { setVisibleColumns } = slice.actions;
export default slice.reducer;
