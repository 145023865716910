import { useGetBanksQuery } from 'entities/bank';
import { useMemo } from 'react';

import { ErrorMessage, EmptyData } from 'shared/ui';
import { getServerErrorText } from 'shared/lib';

import { ClientBindingsTableDataItem } from 'entities/person';
import { useGetClientBindingsQuery } from 'entities/bank-card';

import ClientBindingsTableInstance from './table';

type Props = {
  userLogin: string;
};

export function ConnectedClientBindingsTable({ userLogin }: Props) {
  const {
    data: banks,
    isLoading: isBanksLoading,
    error: banksError,
  } = useGetBanksQuery();

  const {
    data: bindings,
    isLoading: isBindingsLoading,
    isFetching: isBindingsFetching,
    error: bindingsError,
  } = useGetClientBindingsQuery(userLogin);

  const tableData = useMemo(() => {
    if (!bindings?.clientBindings) {
      return [];
    }

    return bindings.clientBindings.reduce((acc, clientBinding) => {
      const { eventQueueDateTime, bindings, userBrowseName, clientId } =
        clientBinding;

      const spread = bindings.map((binding) => {
        return {
          ...binding,
          eventQueueDateTime,
          userBrowseName,
          clientId,
        };
      });

      return [...acc, ...spread];
    }, [] as Array<ClientBindingsTableDataItem>);
  }, [bindings]);

  if (isBanksLoading || isBindingsLoading) {
    return <div>Загрузка данных...</div>;
  }

  const apiResponseError = [bindings, banks].find(
    (el) => el && el.statusCode !== 0
  );

  if (apiResponseError) {
    return <ErrorMessage text={apiResponseError.statusDescription} />;
  }

  const error = [bindingsError, banksError].find((err) => err !== undefined);

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!bindings || !banks) {
    return <EmptyData />;
  }

  return (
    <ClientBindingsTableInstance
      loading={isBindingsFetching}
      tableData={tableData}
      banks={banks.banks}
      rowsCount={bindings.count}
    />
  );
}
