import { Route } from 'react-router-dom';

import { MainContentLayout, SectionLayout } from 'shared/ui/layout';
import { ROLE, ROLES, USERS } from 'shared/consts/routes/role';
import {
  ADD_PREFIX,
  CHARGE_POINTS_RIGHTS,
  CONTROLLER_CLAIMS,
} from 'shared/consts/routes/common';

import { RequireAuth } from 'entities/authorization';
import { RoleLayout } from 'entities/role/role-layout';
import { RolesLayout } from 'entities/role/roles-layout';

import { AddRole } from 'features/role/add';
import { EditRole } from 'features/role/edit';
import { ConnectedRolesMenu } from 'features/role/menu';
import { ConnectedRolesTable } from 'features/role/table';
import { ConnectedRoleUsersTable } from 'features/role/users-table/ui/connected-table';

import { ConnectedRoleChargePointRights } from '../../../components/Security/Role/components/ConnectedRoleChargePointRights';
import { ConnectedRoleControllerRights } from '../../../components/Security/Role/components//ConnectedRoleControllerRights';

export const RoleRoutes = (
  <Route element={<RequireAuth controllerName="Identity" rightType="Read" />}>
    <Route
      path={ROLES}
      element={<SectionLayout menu={<ConnectedRolesMenu />} />}
    >
      <Route element={<RolesLayout />}>
        <Route index element={<ConnectedRolesTable />} />
      </Route>

      <Route
        element={<RequireAuth controllerName="Identity" rightType="Write" />}
      >
        <Route
          path={ADD_PREFIX}
          element={<MainContentLayout element={<AddRole />} />}
        />
      </Route>
      <Route path={ROLE} element={<RoleLayout />}>
        <Route index element={<EditRole />} />
        <Route
          path={CONTROLLER_CLAIMS}
          element={<ConnectedRoleControllerRights />}
        />
        <Route
          path={CHARGE_POINTS_RIGHTS}
          element={<ConnectedRoleChargePointRights />}
        />
        <Route path={USERS} element={<ConnectedRoleUsersTable />} />
      </Route>
    </Route>
  </Route>
);
