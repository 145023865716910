import { Col, Form, FormProps, Select, Spin } from 'antd';
import { useState } from 'react';
import { useWatch } from 'antd/lib/form/Form';

import { UiFormCheckbox, UiInput, UiSelect } from 'shared/ui/ui-kit';

import {
  ChargePointConnectorStatus,
  ChargePointConnectorType,
  ConnectorFormData,
  ConnectorFormProps,
  CONNECTOR_FORMAT,
  CONNECTOR_LITER,
  CONNECTOR_STATUS,
  CONNECTOR_TYPE,
  CONNECTOR_TYPE_ICON,
} from 'entities/connector';

import {
  ConnectorImageContainer,
  ConnectorImageText,
  ConnectorFormContainer,
  ConnectorImage,
  FormContainer,
  ConnectorStatusCircle,
} from './styles';
import {
  FormButtonsContainer,
  FormItem,
  FormRow,
  StyledButton,
} from '../../../../common-styles';

import { VALIDATION_RULE } from '../../../../constants/validation-rules';
import { getSelectOptionsWithEmpty } from '../../../../utils/helpers';
import { formHasErrors } from 'shared/lib';

const { Option } = Select;

type FormData = ConnectorFormData;

export function ConnectorForm({
  initialValues,
  handleSubmit,
  connectorInstructions,
  tariffs,
  loading,
}: ConnectorFormProps) {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [hasErrors, setHasErrors] = useState(false);

  const [form] = Form.useForm<FormData>();

  const type = useWatch('type', form);

  const onFieldsChange: FormProps<FormData>['onFieldsChange'] = (
    _,
    _allFields
  ) => {
    setHasErrors(formHasErrors(form));
  };

  const onValuesChange: FormProps<FormData>['onValuesChange'] = (
    _,
    allValues
  ) => {
    if (
      allValues.maxPower !== '' &&
      allValues.type !== '' &&
      allValues.status !== '' &&
      allValues.connectorId !== '' &&
      allValues.format
    ) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  const getConnectorIcon = () => {
    if (type === '')
      return <ConnectorImageText>Выбрать тип коннектора</ConnectorImageText>;

    return (
      <ConnectorImage
        src={CONNECTOR_TYPE_ICON[type as unknown as ChargePointConnectorType]}
      />
    );
  };

  const literOptions = getSelectOptionsWithEmpty(
    CONNECTOR_LITER.map((el) => ({ value: el, label: el }))
  );

  return (
    <ConnectorFormContainer>
      <ConnectorImageContainer>{getConnectorIcon()}</ConnectorImageContainer>
      <FormContainer>
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={handleSubmit}
          onValuesChange={onValuesChange}
          onFieldsChange={onFieldsChange}
          initialValues={initialValues}
        >
          <FormRow gutter={10}>
            <Col span={12}>
              <FormItem
                label="Мощность"
                name="maxPower"
                rules={[
                  VALIDATION_RULE.REQUIRED,
                  VALIDATION_RULE.NULL_OR_POSITIVE_NUMBER,
                ]}
              >
                <UiInput />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Тип"
                name="type"
                rules={[VALIDATION_RULE.REQUIRED]}
              >
                <UiSelect
                  options={Object.entries(CONNECTOR_TYPE).map((entry) => {
                    const [key, label] = entry;

                    return { value: key, label };
                  })}
                />
              </FormItem>
            </Col>
          </FormRow>
          <FormRow gutter={10}>
            <Col span={12}>
              <FormItem
                label="Текущий статус"
                name="status"
                rules={[VALIDATION_RULE.REQUIRED]}
              >
                <UiSelect>
                  {Object.entries(CONNECTOR_STATUS).map((entry) => {
                    const [key, label] = entry;

                    return (
                      <Option value={String(key)} key={key}>
                        <ConnectorStatusCircle
                          status={key as unknown as ChargePointConnectorStatus}
                        />
                        {label}
                      </Option>
                    );
                  })}
                </UiSelect>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="ID"
                name="connectorId"
                rules={[VALIDATION_RULE.REQUIRED]}
              >
                <UiInput />
              </FormItem>
            </Col>
          </FormRow>
          <FormRow gutter={10}>
            <Col span={12}>
              <FormItem label="Литер" name="liter">
                <UiSelect
                  options={literOptions.map(({ label, value }) => ({
                    value,
                    label,
                  }))}
                ></UiSelect>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Формат"
                name="format"
                rules={[VALIDATION_RULE.REQUIRED]}
              >
                <UiSelect
                  options={Object.entries(CONNECTOR_FORMAT).map((entry) => {
                    const [key, label] = entry;

                    return { value: key, label };
                  })}
                />
              </FormItem>
            </Col>
          </FormRow>
          <FormRow gutter={10}>
            <Col span={12}>
              <FormItem
                label="Max V"
                name="maxVoltage"
                rules={[VALIDATION_RULE.NULL_OR_POSITIVE_NUMBER]}
              >
                <UiInput />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Max A"
                name="maxAmperage"
                rules={[VALIDATION_RULE.NULL_OR_POSITIVE_NUMBER]}
              >
                <UiInput />
              </FormItem>
            </Col>
          </FormRow>
          <FormRow gutter={10}>
            <Col span={24}>
              <FormItem label="Инструкция" name="instructionId">
                <UiSelect
                  options={[
                    {
                      value: '',
                      label: 'Нет',
                    },
                    ...connectorInstructions.map(
                      ({ id, connectorTypeName }) => ({
                        value: String(id),
                        label: connectorTypeName,
                      })
                    ),
                  ]}
                />
              </FormItem>
            </Col>
          </FormRow>
          {/* <FormRow gutter={10}>
              <Col span={24}>
                <FormItem label="Тариф" name="UNKNOWN">
                  <UiSelect>
                    {tariffs.map(({ id, name }) => (
                      <Option value={String(id)} key={id}>
                        {name}
                      </Option>
                    ))}
                  </UiSelect>
                </FormItem>
              </Col>
            </FormRow> */}
          <FormRow gutter={10}>
            <Col>
              <FormItem name="manualBlock" valuePropName="checked">
                <UiFormCheckbox>Ручная блокировка</UiFormCheckbox>
              </FormItem>
            </Col>
          </FormRow>
          <FormButtonsContainer>
            <Spin spinning={loading}>
              <StyledButton
                htmlType="submit"
                type="primary"
                disabled={isSubmitDisabled || hasErrors}
              >
                Сохранить
              </StyledButton>
            </Spin>
          </FormButtonsContainer>
        </Form>
      </FormContainer>
    </ConnectorFormContainer>
  );
}
