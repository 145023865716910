import { HTMLInputTypeAttribute, useState } from 'react';
import { Input } from 'antd';

import { FloatLabelWrapper, IconContainer } from './styles';

type Props = {
  label: string;
  placeholder: string;
  type: HTMLInputTypeAttribute;
  disabled?: boolean;
  hasFocus: boolean;
  hasError: boolean;
  suffix: JSX.Element;
  // only required fields for customized form controls (see antd docs)
  value?: string;
  onChange?: (value: string) => void;
};

export function UiFloatInput({
  value = '',
  onChange,
  disabled,
  hasFocus,
  hasError,
  label,
  placeholder,
  type,
  suffix,
}: Props) {
  const [text, setText] = useState('');
  const [focus, setFocus] = useState(hasFocus);

  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setText(newValue);
    onChange?.(newValue);
  };

  if (!placeholder) placeholder = label;

  const isOccupied = focus || (text && text.length !== 0);

  const labelClass = isOccupied ? 'label as-label' : 'label as-placeholder';

  const getWrapperClass = () => {
    if (hasError) {
      return 'error';
    }

    if (text.length > 0) {
      return 'success';
    }

    return 'undefined';
  };

  return (
    <FloatLabelWrapper
      className={getWrapperClass()}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <Input
        type={type}
        value={value || text}
        onChange={onTextChange}
        suffix={
          <IconContainer status={getWrapperClass()}>{suffix}</IconContainer>
        }
        autoFocus={hasFocus}
        disabled={disabled}
      />
      <label className={labelClass}>{isOccupied ? label : placeholder}</label>
    </FloatLabelWrapper>
  );
}
