import { useParams } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';

import {
  getServerErrorText,
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { ErrorMessage, EmptyData } from 'shared/ui';

import {
  ConnectedUserForm,
  useGetUserQuery,
  UserDto,
  UserFormData,
} from 'entities/user';

import { DeleteUserModal } from 'features/user/delete';

// TODO
import { useCustomUpdateUserMutation } from '../../../../redux/services/identity';

import { EDIT_SUCCESS, EDIT_ERROR } from '../consts';

const getInitialValues = (user: UserDto): UserFormData => {
  const { id, userName, firstName, lastName, middleName, email, groups } = user;

  return {
    id,
    userName,
    lastName,
    firstName,
    middleName,
    email,
    userPass: '',
    groupName: groups[0] ?? '',
  };
};

export function EditUser() {
  const [updateTrigger, { isLoading: isUpdateLoading }] =
    useCustomUpdateUserMutation();

  const { userName } = useParams() as { userName: string };

  const { isFetching, error, data } = useGetUserQuery(userName);

  const handleSubmit = async (values: UserFormData) => {
    const { userPass, groupName, ...rest } = values;

    const req = {
      ...rest,
      ...(userPass !== '' ? { newPassword: userPass } : {}),
      oldGroupName: data?.user.groups[0] ?? '',
      newGroupName: groupName,
      // status: status === '0' ? false : true,
    };

    try {
      const res = await updateTrigger(req).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);
        return;
      }
      //

      openSuccessNotification(EDIT_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : EDIT_ERROR;

      openErrorNotification(errorText);
    }
  };

  const showDeleteModal = () => {
    NiceModal.show(DeleteUserModal, {
      userName,
    });
  };

  if (isFetching) {
    return <div>Loading...</div>;
  }

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!data || !data.user) {
    return <EmptyData />;
  }

  const initialValues = getInitialValues(data.user);

  return (
    <>
      <ConnectedUserForm
        initialValues={initialValues}
        handleSubmit={handleSubmit}
        loading={isUpdateLoading}
        title={data.user.userName}
        handleDelete={showDeleteModal}
        disabled
      />
    </>
  );
}
