import SortIconSvg from './images/arrow-down.svg';

import { SortingIcon } from './styles';

type Props = {
  isDesc: boolean;
};

export function SortingButton({ isDesc }: Props) {
  return <SortingIcon src={SortIconSvg} isDesc={isDesc} />;
}
