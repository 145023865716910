import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { ConfirmDialog, UiModal } from 'shared/ui';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';

import { useDeleteChargePointPhotoMutation } from 'entities/charge-point-photo';

import { DELETE_ERROR, DELETE_SUCCESS } from '../consts';

type Props = {
  photoId: number;
  cpName: string;
};

export const DeleteChargePointPhotoModal = NiceModal.create((props: Props) => {
  const modal = useModal();

  const [trigger, { isLoading }] = useDeleteChargePointPhotoMutation();

  const handleDelete = async () => {
    try {
      const res = await trigger(props).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);

        return;
      }
      //

      openSuccessNotification(DELETE_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : DELETE_ERROR;

      openErrorNotification(errorText);
    } finally {
      modal.hide();
    }
  };

  return (
    <UiModal
      title={`Удалить фотографию?`}
      visible={modal.visible}
      onCancel={modal.hide}
      afterClose={modal.remove}
    >
      <ConfirmDialog
        onCancel={modal.hide}
        onConfirm={handleDelete}
        loading={isLoading}
      />
    </UiModal>
  );
});
