import { Link } from 'react-router-dom';

import { CAR_BRAND_ROUTES } from 'shared/consts/routes/car-brands';
import { NavigationTabButton } from 'shared/ui';

export function AddCarModelButtonLink() {
  return (
    <Link to={CAR_BRAND_ROUTES.ADD_CAR_MODEL}>
      <NavigationTabButton title="Добавить модель" />
    </Link>
  );
}
