import { useParams } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';

import {
  getServerErrorText,
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';
import { ErrorMessage, EmptyData } from 'shared/ui';

import {
  RoleDto,
  RoleForm,
  RoleFormData,
  useGetRoleQuery,
  useUpdateRoleMutation,
} from 'entities/role';

import { DeleteRoleModal } from 'features/role/delete';

import { EDIT_ERROR, EDIT_SUCCESS } from '../consts';

const getInitialValues = (role: RoleDto): RoleFormData => role;

export function EditRole() {
  const [updateTrigger, { isLoading: isUpdateLoading }] =
    useUpdateRoleMutation();

  const { roleName } = useParams() as { roleName: string };

  const { isFetching, error, data } = useGetRoleQuery(roleName);

  const handleSubmit = async (values: RoleFormData) => {
    try {
      const res = await updateTrigger(values).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);
        return;
      }
      //

      openSuccessNotification(EDIT_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : EDIT_ERROR;

      openErrorNotification(errorText);
    }
  };

  const showDeleteModal = () => {
    NiceModal.show(DeleteRoleModal, {
      roleName,
    });
  };

  if (isFetching) {
    return <div>Loading...</div>;
  }

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!data || !data.role) {
    return <EmptyData />;
  }

  const initialValues = getInitialValues(data.role);

  return (
    <>
      <RoleForm
        initialValues={initialValues}
        handleSubmit={handleSubmit}
        loading={isUpdateLoading}
        title={data.role.name}
        handleDelete={showDeleteModal}
        disabled
      />
    </>
  );
}
