import { BANK_ROUTES } from 'shared/consts/routes/bank';
import { MenuAddButton } from 'shared/ui/section-menu';

export function AddButton() {
  return (
    <MenuAddButton
      path={BANK_ROUTES.ADD_BANK}
      requiredRights={[
        {
          controller: 'Bank',
          right: 'Write',
        },
      ]}
    />
  );
}
