import { BONUS_SERVICE } from 'entities/authorization';
import { BalanceTransactionResponse } from 'entities/balance/model/api';
import { baseApi } from 'shared/api';

import { getEndpointPath } from 'shared/lib';

import { apiSlice } from '../../../redux/services/apiSlice';
import { BonusProgram } from '../model/program';
import {
  AddBonusProgramRequest,
  BonusProgramAchievementResponse,
  UpdateBonusProgramRequest,
} from '../model/program-api';

const KEY = 'BonusProgram';

const getUrl = getEndpointPath(BONUS_SERVICE, KEY);

interface GetTransactionsByProgramParams {
  bonusProgramId: number;
  page: number;
  limit: number;
  dateFrom: string;
  dateTo: string;
}

// TODO: замена на baseApi

export const bonusProgramApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPersonAchievement: builder.query<
      BonusProgramAchievementResponse,
      string
    >({
      query: (personId) => ({
        url: getUrl(`GetPersonAchievement`),
        params: { personId },
      }),
    }),
    getAllBonusPrograms: builder.query<Array<BonusProgram>, void>({
      query: (body) => ({
        url: getUrl('GetAll'),
      }),
      providesTags: ['BonusPrograms'],
    }),
    getBonusProgramById: builder.query<BonusProgram, string>({
      query: (id) => ({
        url: getUrl(`GetById/${id}`),
      }),
      providesTags: (result, error, arg) => [{ type: 'BonusProgram', id: arg }],
    }),
    addBonusProgram: builder.mutation<BonusProgram, AddBonusProgramRequest>({
      query: (body) => ({
        url: getUrl('Add'),
        method: 'POST',
        body,
      }),
      invalidatesTags: ['BonusPrograms'],
    }),
    updateBonusProgram: builder.mutation<void, UpdateBonusProgramRequest>({
      query: (body) => ({
        url: getUrl('Update'),
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'BonusProgram', id: arg.id },
        'BonusPrograms',
      ],
    }),
    deleteBonusProgram: builder.mutation<void, string>({
      query: (id) => ({
        url: getUrl(`DeleteById/${id}`),
        method: 'DELETE',
      }),
      invalidatesTags: ['BonusPrograms'],
    }),
    getTransactionsByProgram: builder.query<
      BalanceTransactionResponse,
      GetTransactionsByProgramParams
    >({
      query: (params) => ({
        url: getUrl(`GetTransactionsByProgram`),
        params,
      }),
    }),
  }),
});

export const {
  useGetPersonAchievementQuery,
  useGetAllBonusProgramsQuery,
  useGetBonusProgramByIdQuery,
  useAddBonusProgramMutation,
  useUpdateBonusProgramMutation,
  useDeleteBonusProgramMutation,
  useGetTransactionsByProgramQuery,
} = bonusProgramApi;
