import styled from 'styled-components';

import { COLOR } from 'shared/consts';
interface MonitroingMapContainerProps {
  isFullWidth: boolean;
}

export const MonitroingMapContainer = styled.div<MonitroingMapContainerProps>`
  grid-column: ${(props) => (props.isFullWidth ? 'span 2' : 'span 1')};
  grid-row: ${(props) => (props.isFullWidth ? '2/3' : 'span 2')};
  display: flex;
  flex-direction: column;
`;

export const MapContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
`;

export const MapTitle = styled.h4`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${COLOR.black};
  flex-grow: 0;
`;

export const Mapcontainer = styled.div`
  flex-grow: 1;
  display: flex;
  overflow: auto;
`;

export const MapWrapper = styled.div`
  width: 100%;
  background-color: ${COLOR.white};
  padding: 15px;
  border-radius: 8px;
`;
