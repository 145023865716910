import { useParams } from 'react-router-dom';

import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
  getServerErrorText,
} from 'shared/lib';

import { EmptyData, ErrorMessage } from 'shared/ui';

import {
  type Hub,
  type HubFormData,
  useGetHubQuery,
  useUpdateHubMutation,
  HubForm,
} from 'entities/hub';

import { EDIT_ERROR, EDIT_SUCCESS } from '../consts';

const getInitialValues = (hub: Hub): HubFormData => {
  const { id, isDeleted, ...rest } = hub;

  return { ...rest, id: String(id) };
};

export function EditHub() {
  const [trigger, { isLoading: isMutationLoading }] = useUpdateHubMutation();

  const { hubId } = useParams() as { hubId: string };

  const { isFetching, error, data } = useGetHubQuery(hubId);

  const handleSubmit = async (values: HubFormData) => {
    try {
      const res = await trigger(values).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);
        return;
      }
      //

      openSuccessNotification(EDIT_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : EDIT_ERROR;

      openErrorNotification(errorText);
    }
  };

  if (isFetching) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  if (!data?.hub) {
    return <EmptyData />;
  }

  const initialValues = getInitialValues(data.hub);

  return (
    <HubForm
      handleSubmit={handleSubmit}
      loading={isMutationLoading}
      initialValues={initialValues}
      title={data.hub.name}
      disabled
    />
  );
}
