import styled from 'styled-components';

import { COLOR, DEFAULT_BORDER } from 'shared/consts';

export const StyledCard = styled.article`
  background: ${COLOR.white};
  border-radius: 8px;
`;

export const TechInfoTable = styled.table`
  table-layout: fixed;
  width: 100%;
`;

export const TechInfoTableHead = styled.thead`
  & tr th:nth-child(2) {
    border-right: ${DEFAULT_BORDER};
  }
`;

export const TechInfoTableBody = styled.tbody`
  & tr:nth-child(even) {
    background-color: ${COLOR.tableEvenRow};
  }

  & tr td:nth-child(2) {
    border-right: ${DEFAULT_BORDER};
  }
`;

export const TechInfoTableRow = styled.tr``;

export const TechInfoTableTd = styled.td`
  padding: 10px 15px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR.black};
  overflow-wrap: break-word;
  word-wrap: break-word;
`;

interface TechInfoTableThProps {
  highlighted?: boolean;
}

export const TechInfoTableTh = styled.th<TechInfoTableThProps>`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: ${(props) => (props.highlighted ? COLOR.greenMenu : COLOR.darkGrey)};
  padding: 10px 20px;
  text-align: left;
`;

export const CardTitle = styled.h4`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${COLOR.black};
  padding: 20px;
  border-bottom: ${DEFAULT_BORDER};
`;

export const WarningText = styled.p`
  font-weight: 700;
`;

type Props = {
  highlighted: boolean;
};

export const HighlightedRedText = styled.p<Props>`
  color: ${(props) => (props.highlighted ? COLOR.red : COLOR.black)};
`;
