import { Col, Form, FormProps, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useWatch } from 'antd/lib/form/Form';
import { useSelector } from 'react-redux';
import { QuestionCircleFilled } from '@ant-design/icons';

import { formHasErrors, getInputHtmlSize, VALIDATION_RULE } from 'shared/lib';
import { UiDivider, UiFormCheckbox, UiInput, UiSelect } from 'shared/ui/ui-kit';
import { PERCENT_INPUT_HTML_SIZE } from 'shared/consts';

import {
  ButtonsContainer,
  UiSubmitButton,
  UiEditFormButton,
  UiCancelFormButton,
  FormControlsContainer,
  FieldError,
  RequiredLabel,
} from 'shared/ui';

import {
  selectTokenRoles,
  selectTokenClaims,
  checkControllerRight,
} from 'entities/authorization';
import { BankDTO } from 'entities/bank';

import {
  CheckboxWithInputLabel,
  CardTitleContainer,
  TariffBadge,
  TariffFormContainer,
  LabelInner,
  InputLabel,
  TariffCheckbox,
  LabelContainer,
  InputWrapper,
  DivContainer,
} from './styles';

import {
  CardFormWrapper,
  FormRow,
  FormItem,
  LabelWithHint,
} from '../../../../common-styles';

import { TariffFormData, TariffFormProps } from '../../model';

type FormData = TariffFormData;

export function TariffForm({
  initialValues,
  banks,
  handleSubmit,
  loading,
  title,
  disabled = false,
}: TariffFormProps) {
  const roles = useSelector(selectTokenRoles);
  const claims = useSelector(selectTokenClaims);

  const hasWriteRight = checkControllerRight(
    'ChargeTariff',
    'Write',
    roles,
    claims
  );

  const [isFormDisabled, setIsFormDisabled] = useState(disabled);

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [hasErrors, setHasErrors] = useState(false);

  const [form] = Form.useForm<FormData>();

  const DCPerKilowatt = useWatch('DCPerKilowatt', form);
  const DCPerMinute = useWatch('DCPerMinute', form);
  // const ACPerKilowatt = useWatch('ACPerKilowatt', form);
  const batteryPercentageTrigger = useWatch('batteryPercentageTrigger', form);
  const powerDropTrigger = useWatch('powerDropTrigger', form);

  const costOfQuickCharging = useWatch('costOfQuickCharging', form);
  // const costOfSlowCharging = useWatch('costOfSlowCharging', form);
  const batteryPercentageFee = useWatch('batteryPercentageFee', form);
  const powerDropFee = useWatch('powerDropFee', form);
  const powerDrop = useWatch('powerDrop', form);

  const bankId = useWatch('bankId', form);

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  useEffect(() => {
    if (DCPerKilowatt === true) {
      form.setFieldValue('DCPerMinute', false);
    }
  }, [DCPerKilowatt]);

  useEffect(() => {
    if (DCPerMinute === true) {
      form.setFieldValue('DCPerKilowatt', false);

      form.setFieldValue('batteryPercentageTrigger', false);
      form.setFieldValue('batteryPercentage', '0');
      form.setFieldValue('batteryPercentageFee', '0');

      form.setFieldValue('powerDropTrigger', false);
      form.setFieldValue('powerDrop', '');
      form.setFieldValue('powerDropFee', '0');
    }
  }, [DCPerMinute]);

  useEffect(() => {
    if (batteryPercentageTrigger === true) {
      form.setFieldValue('powerDropTrigger', false);
    }
  }, [batteryPercentageTrigger]);

  useEffect(() => {
    if (powerDropTrigger === true) {
      form.setFieldValue('batteryPercentageTrigger', false);
    }
  }, [powerDropTrigger]);

  const onFieldsChange: FormProps<FormData>['onFieldsChange'] = (
    _,
    _allFields
  ) => {
    setHasErrors(formHasErrors(form));
  };

  const onValuesChange: FormProps<FormData>['onValuesChange'] = (
    _,
    allValues
  ) => {
    if (
      allValues.name !== '' &&
      (allValues.DCPerKilowatt || allValues.DCPerMinute) &&
      allValues.bankId !== ''
    ) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  const getTariffBadge = () => {
    let content = '';

    if (batteryPercentageTrigger || powerDropTrigger) {
      content = 'Комбинированный тариф';
    } else if (DCPerKilowatt) {
      content = 'Тарификация за кВт*ч';
    } else if (DCPerMinute) {
      content = 'Поминутная тарификация';
    }

    return content === '' ? null : <TariffBadge>{content}</TariffBadge>;
  };

  const isDCPerKilowattDisabled = isFormDisabled || DCPerKilowatt !== true;
  const isDCPerMinuteDisabled = isFormDisabled || DCPerMinute !== true;
  // const isACDisabled = isFormDisabled || ACPerKilowatt !== true;
  const isBatterPercentageDisabled =
    isFormDisabled || batteryPercentageTrigger !== true;
  const isPowerDropDisabled = isFormDisabled || powerDropTrigger !== true;

  const selectedBank = bankId
    ? (banks.find((bank) => String(bank.bankId) === bankId) as BankDTO)
    : '';

  const currencyText = selectedBank
    ? selectedBank.country.currencyText
    : 'руб.';

  const primaryButton = isFormDisabled ? (
    <UiEditFormButton onClick={() => setIsFormDisabled(false)} />
  ) : (
    <UiSubmitButton disabled={isSubmitDisabled || hasErrors} />
  );

  let formControls: JSX.Element | null = null;

  if (hasWriteRight) {
    formControls = (
      <FormControlsContainer>
        <ButtonsContainer spinning={loading}>
          {primaryButton}
          <UiCancelFormButton />
        </ButtonsContainer>
      </FormControlsContainer>
    );
  }

  return (
    <TariffFormContainer>
      <CardFormWrapper
        title={
          initialValues.id ? (
            <CardTitleContainer>
              {title}
              {getTariffBadge()}
            </CardTitleContainer>
          ) : (
            title
          )
        }
      >
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={handleSubmit}
          onValuesChange={onValuesChange}
          onFieldsChange={onFieldsChange}
          initialValues={initialValues}
        >
          <FormRow gutter={20}>
            <Col span={12}>
              <FormItem
                label="Наименование тарифа"
                name="name"
                rules={[VALIDATION_RULE.REQUIRED]}
              >
                <UiInput disabled={isFormDisabled} />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem label="Описание" name="editProperties">
                <UiInput disabled={isFormDisabled} />
              </FormItem>
            </Col>
          </FormRow>

          <FormRow gutter={20}>
            <Col span={12}>
              <FormItem label="ID тарифа" name="id">
                <UiInput disabled />
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Процессинг"
                name="bankId"
                rules={[VALIDATION_RULE.REQUIRED]}
              >
                <UiSelect
                  disabled={isFormDisabled}
                  options={banks.map(({ bankId, name }) => ({
                    value: String(bankId),
                    label: name,
                  }))}
                />
              </FormItem>
            </Col>
          </FormRow>

          <UiDivider />

          <FormRow gutter={20}>
            <Col>
              <FormItem
                label={`Плата за старт, ${currencyText}`}
                name="startFee"
                rules={[VALIDATION_RULE.NULL_OR_POSITIVE_NUMBER]}
              >
                <UiInput disabled={isFormDisabled} />
              </FormItem>
            </Col>
          </FormRow>

          <UiDivider />

          <FormRow gutter={20}>
            <Col>
              <FormItem
                label={`Стоимость бронирования, ${currencyText}`}
                name="costOfReserve"
                rules={[VALIDATION_RULE.NULL_OR_POSITIVE_NUMBER]}
              >
                <UiInput disabled={isFormDisabled} />
              </FormItem>
            </Col>
          </FormRow>

          <UiDivider />

          <Col>
            <CheckboxWithInputLabel>
              <RequiredLabel>Тарификация</RequiredLabel>
            </CheckboxWithInputLabel>
          </Col>

          <FormRow gutter={20}>
            <Col>
              <LabelContainer checked={DCPerKilowatt} disabled={isFormDisabled}>
                <LabelInner>
                  <FormItem name="DCPerKilowatt" valuePropName="checked">
                    <TariffCheckbox disabled={isFormDisabled}>
                      Тарификация за кВт*ч
                    </TariffCheckbox>
                  </FormItem>
                  <FormItem
                    name="costOfQuickCharging"
                    help=""
                    rules={[VALIDATION_RULE.NULL_OR_POSITIVE_NUMBER]}
                  >
                    <UiInput
                      disabled={isDCPerKilowattDisabled}
                      htmlSize={getInputHtmlSize(costOfQuickCharging)}
                    />
                  </FormItem>
                  <InputLabel>{currencyText}/кВт*ч</InputLabel>
                </LabelInner>
              </LabelContainer>
              <FieldError errors={form.getFieldError('costOfQuickCharging')} />
            </Col>

            <Col>
              <LabelContainer checked={DCPerMinute} disabled={isFormDisabled}>
                <LabelInner>
                  <FormItem name="DCPerMinute" valuePropName="checked">
                    <TariffCheckbox disabled={isFormDisabled}>
                      Поминутная
                    </TariffCheckbox>
                  </FormItem>
                  <FormItem
                    name="costOfQuickCharging"
                    help=""
                    rules={[VALIDATION_RULE.NULL_OR_POSITIVE_NUMBER]}
                  >
                    <UiInput
                      disabled={isDCPerMinuteDisabled}
                      htmlSize={getInputHtmlSize(costOfQuickCharging)}
                    />
                  </FormItem>
                  <InputLabel>{currencyText}/мин</InputLabel>
                </LabelInner>
              </LabelContainer>
              <FieldError errors={form.getFieldError('costOfQuickCharging')} />
            </Col>
          </FormRow>

          {/* <Col span={12}>
                    <CheckboxWithInputLabel>
                      Тарификация (AC)
                    </CheckboxWithInputLabel>
                  </Col> */}

          {/* <FormRow gutter={20}>
                    <Col>
                      <LabelContainer
                        checked={ACPerKilowatt}
                        disabled={isFormDisabled}
                      >
                        <LabelInner>
                          <FormItem
                            name="ACPerKilowatt"
                            valuePropName="checked"
                          >
                            <TariffCheckbox disabled={isFormDisabled}>
                              Тарификация за кВт*ч
                            </TariffCheckbox>
                          </FormItem>
                          <FormItem
                            name="costOfSlowCharging"
                            help=""
                            rules={[VALIDATION_RULE.NULL_OR_POSITIVE_NUMBER]}
                          >
                            <UiInput
                              disabled={isACDisabled}
                              htmlSize={getInputHtmlSize(costOfSlowCharging)}
                            />
                          </FormItem>
                          <InputLabel>руб/кВт*ч</InputLabel>
                        </LabelInner>
                      </LabelContainer>
                      <FieldError
                        errors={form.getFieldError(
                          'costOfSlowCharging'
                        )}
                      />
                    </Col>
                  </FormRow> */}

          <Col span={12}>
            <CheckboxWithInputLabel>
              <LabelWithHint>
                Вид триггера
                <Tooltip title="Триггер срабатывает только на тарификации за кВт*ч">
                  <QuestionCircleFilled />
                </Tooltip>
              </LabelWithHint>
            </CheckboxWithInputLabel>
          </Col>

          <FormRow gutter={20}>
            <Col>
              <LabelContainer
                checked={batteryPercentageTrigger}
                disabled={isFormDisabled}
              >
                <DivContainer>
                  <LabelInner>
                    <FormItem
                      name="batteryPercentageTrigger"
                      valuePropName="checked"
                    >
                      <TariffCheckbox disabled={isFormDisabled}>
                        % заряда батареи
                      </TariffCheckbox>
                    </FormItem>
                    <FormItem
                      name="batteryPercentage"
                      rules={[VALIDATION_RULE.PERCENT]}
                      help=""
                    >
                      <UiInput
                        disabled={isBatterPercentageDisabled}
                        htmlSize={PERCENT_INPUT_HTML_SIZE}
                      />
                    </FormItem>
                    <InputLabel>%</InputLabel>
                  </LabelInner>
                  <FieldError
                    errors={form.getFieldError('batteryPercentage')}
                  />
                </DivContainer>
                <DivContainer>
                  <InputWrapper>
                    <FormItem
                      name="batteryPercentageFee"
                      help=""
                      rules={[VALIDATION_RULE.NULL_OR_POSITIVE_NUMBER]}
                    >
                      <UiInput
                        disabled={isBatterPercentageDisabled}
                        htmlSize={getInputHtmlSize(batteryPercentageFee)}
                      />
                    </FormItem>
                    <InputLabel>{currencyText}/мин</InputLabel>
                  </InputWrapper>
                  <FieldError
                    errors={form.getFieldError('batteryPercentageFee')}
                  />
                </DivContainer>
              </LabelContainer>
            </Col>
          </FormRow>

          <FormRow gutter={20}>
            <Col>
              <LabelContainer
                checked={powerDropTrigger}
                disabled={isFormDisabled}
              >
                <DivContainer>
                  <LabelInner>
                    <FormItem name="powerDropTrigger" valuePropName="checked">
                      <TariffCheckbox disabled={isFormDisabled}>
                        падение мощности ниже
                      </TariffCheckbox>
                    </FormItem>
                    <FormItem
                      name="powerDrop"
                      rules={[VALIDATION_RULE.POSITIVE_INTEGER]}
                      help=""
                    >
                      <UiInput
                        disabled={isPowerDropDisabled}
                        htmlSize={getInputHtmlSize(powerDrop)}
                      />
                    </FormItem>
                    <InputLabel>Вт</InputLabel>
                  </LabelInner>
                  <FieldError errors={form.getFieldError('powerDrop')} />
                </DivContainer>
                <DivContainer>
                  <InputWrapper>
                    <FormItem
                      name="powerDropFee"
                      help=""
                      rules={[VALIDATION_RULE.NULL_OR_POSITIVE_NUMBER]}
                    >
                      <UiInput
                        disabled={isPowerDropDisabled}
                        htmlSize={getInputHtmlSize(powerDropFee)}
                      />
                    </FormItem>
                    <InputLabel>{currencyText}/мин</InputLabel>
                  </InputWrapper>
                  <FieldError errors={form.getFieldError('powerDropFee')} />
                </DivContainer>
              </LabelContainer>
            </Col>
          </FormRow>

          <UiDivider />

          <FormRow gutter={20}>
            <Col>
              <FormItem
                label={`Плата за простой, ${currencyText}/мин.`}
                name="parkFee"
                rules={[VALIDATION_RULE.NULL_OR_POSITIVE_NUMBER]}
              >
                <UiInput disabled={isFormDisabled} />
              </FormItem>
            </Col>
            <Col>
              <FormItem
                label="Время до начала взимания платы, мин."
                name="parkTimeAfter"
                rules={[VALIDATION_RULE.NULL_OR_POSITIVE_INTEGER]}
              >
                <UiInput disabled={isFormDisabled} />
              </FormItem>
            </Col>
          </FormRow>
          {initialValues.id && (
            <>
              <UiDivider />
              <FormRow>
                <Col>
                  <FormItem name="isArchive" valuePropName="checked">
                    <UiFormCheckbox disabled={isFormDisabled}>
                      Архивировать тариф
                    </UiFormCheckbox>
                  </FormItem>
                </Col>
              </FormRow>
            </>
          )}
          {formControls}
        </Form>
      </CardFormWrapper>
    </TariffFormContainer>
  );
}
