import { Col, Form, FormProps, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { QuestionCircleFilled } from '@ant-design/icons';

import { formHasErrors, VALIDATION_RULE, checkMaxLengthRule } from 'shared/lib';
import { UiInput } from 'shared/ui/ui-kit';
import {
  ButtonsContainer,
  UiSubmitButton,
  UiEditFormButton,
  UiCancelFormButton,
  FormControlsContainer,
} from 'shared/ui';

import {
  selectTokenRoles,
  selectTokenClaims,
  checkControllerRight,
} from 'entities/authorization';

import {
  CardFormWrapper,
  FormItem,
  FormRow,
  LabelWithHint,
} from '../../../../common-styles';

import { CountryFormData, CountryFormProps } from '../../model';

import { COUNTRY_CODE_MAX_LENGTH } from '../../consts';

export function CountryForm({
  title,
  handleSubmit,
  initialValues,
  loading,
  disabled = false,
}: CountryFormProps) {
  const roles = useSelector(selectTokenRoles);
  const claims = useSelector(selectTokenClaims);

  const hasWriteRight = checkControllerRight('Country', 'Write', roles, claims);

  const [isFormDisabled, setIsFormDisabled] = useState(disabled);

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [hasErrors, setHasErrors] = useState(false);

  const [form] = Form.useForm<CountryFormData>();

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  const onFieldsChange: FormProps<CountryFormData>['onFieldsChange'] = (
    _,
    _allFields
  ) => {
    setHasErrors(formHasErrors(form));
  };

  const onValuesChange: FormProps<CountryFormData>['onValuesChange'] = (
    _,
    allValues
  ) => {
    if (
      allValues.name !== '' &&
      allValues.code !== '' &&
      allValues.currencySymbol !== '' &&
      allValues.currencyText !== '' &&
      allValues.phonePrefix !== '' &&
      allValues.phoneMask !== '' &&
      allValues.language !== ''
    ) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  const primaryButton = isFormDisabled ? (
    <UiEditFormButton onClick={() => setIsFormDisabled(false)} />
  ) : (
    <UiSubmitButton disabled={isSubmitDisabled || hasErrors} />
  );

  let formControls: JSX.Element | null = null;

  if (hasWriteRight) {
    formControls = (
      <FormControlsContainer>
        <ButtonsContainer spinning={loading}>
          {primaryButton}
          <UiCancelFormButton />
        </ButtonsContainer>
      </FormControlsContainer>
    );
  }

  return (
    <CardFormWrapper title={title}>
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={handleSubmit}
        onFieldsChange={onFieldsChange}
        onValuesChange={onValuesChange}
        initialValues={initialValues}
      >
        <FormRow>
          <Col span={24}>
            <FormItem label="ID" name="id">
              <UiInput disabled />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow>
          <Col span={24}>
            <FormItem
              label="Название"
              name="name"
              rules={[VALIDATION_RULE.REQUIRED, VALIDATION_RULE.NO_WHITESPACE]}
            >
              <UiInput disabled={isFormDisabled} />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow>
          <Col span={24}>
            <FormItem
              label={
                <LabelWithHint>
                  Код страны
                  <Tooltip title="Двухбуквенный код страны ISO">
                    <QuestionCircleFilled />
                  </Tooltip>
                </LabelWithHint>
              }
              name="code"
              rules={[
                checkMaxLengthRule(COUNTRY_CODE_MAX_LENGTH),
                VALIDATION_RULE.REQUIRED,
                VALIDATION_RULE.NO_WHITESPACE,
              ]}
            >
              <UiInput disabled={isFormDisabled} />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow>
          <Col span={24}>
            <FormItem
              label="Символ валюты"
              name="currencySymbol"
              rules={[VALIDATION_RULE.REQUIRED, VALIDATION_RULE.NO_WHITESPACE]}
            >
              <UiInput disabled={isFormDisabled} />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow>
          <Col span={24}>
            <FormItem
              label="Название валюты"
              name="currencyText"
              rules={[VALIDATION_RULE.REQUIRED, VALIDATION_RULE.NO_WHITESPACE]}
            >
              <UiInput disabled={isFormDisabled} />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow>
          <Col span={24}>
            <FormItem
              label={
                <LabelWithHint>
                  Телефонный префикс
                  <Tooltip title="Префикс страны, например +7">
                    <QuestionCircleFilled />
                  </Tooltip>
                </LabelWithHint>
              }
              name="phonePrefix"
              rules={[VALIDATION_RULE.REQUIRED, VALIDATION_RULE.NO_WHITESPACE]}
            >
              <UiInput disabled={isFormDisabled} />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow>
          <Col span={24}>
            <FormItem
              label={
                <LabelWithHint>
                  Телефонная маска
                  <Tooltip
                    title={
                      <>
                        <p>
                          Формат заполнения:
                          *префикс:количество_цифр_после_префикса*
                        </p>
                        <p>
                          Например 7:10 - формат российских номеров телефона.
                        </p>
                        <p>
                          Могут быть указать несколько масок через запятую,
                          например 7:10,7:9
                        </p>
                      </>
                    }
                  >
                    <QuestionCircleFilled />
                  </Tooltip>
                </LabelWithHint>
              }
              name="phoneMask"
              rules={[VALIDATION_RULE.REQUIRED, VALIDATION_RULE.NO_WHITESPACE]}
            >
              <UiInput disabled={isFormDisabled} />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow>
          <Col span={24}>
            <FormItem label="URL иконки флага" name="urlFlag">
              <UiInput disabled={isFormDisabled} />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow>
          <Col span={24}>
            <FormItem label="Описание" name="description">
              <UiInput disabled={isFormDisabled} />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow>
          <Col span={24}>
            <FormItem
              label="Язык"
              name="language"
              rules={[VALIDATION_RULE.REQUIRED, VALIDATION_RULE.NO_WHITESPACE]}
            >
              <UiInput disabled={isFormDisabled} />
            </FormItem>
          </Col>
        </FormRow>
        <FormRow>
          <Col span={24}>
            <FormItem
              label="URL политики конфиденциальности"
              name="privacyPolicyUrl"
              rules={[VALIDATION_RULE.URL]}
            >
              <UiInput disabled={isFormDisabled} />
            </FormItem>
          </Col>
        </FormRow>
        {formControls}
      </Form>
    </CardFormWrapper>
  );
}
