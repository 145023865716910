import { Route } from 'react-router-dom';

import { ChapterLayout } from 'widgets/layout/chapter';
import { MonitoringSidebar } from 'widgets/sidebar/monitoring';

import NotFound from '../../../pages/NotFound';

import { TariffRoutes } from '../../../app/router/routes/tariff';
import { OwnerRoutes } from '../../../app/router/routes/owner';
import { ReportRoutes } from '../../../app/router/routes/report';
import { MonitoringPage } from '../../../pages/MonitoringPage';
import { PushNotificationRoutes } from '../../../app/router/routes/push-notification';
import { DiscountRoutes } from '../../../app/router/routes/discount';
import { BonusRoutes } from '../../../app/router/routes/bonus';
import { HubRoutes } from '../../../app/router/routes/hub';

// TODO
import { PersonRoutes } from '../../../components/User/routes';
import { ChargePointRoutes } from '../../../components/ChargePoint/routes';

export const MonitoringRoutes = (
  <Route path="/" element={<ChapterLayout siderbar={<MonitoringSidebar />} />}>
    <Route index element={<MonitoringPage />} />
    {TariffRoutes}
    {DiscountRoutes}
    {ChargePointRoutes}
    {HubRoutes}
    {OwnerRoutes}
    {PersonRoutes}
    {ReportRoutes}
    {PushNotificationRoutes}
    {BonusRoutes}
    <Route path="*" element={<NotFound />} />
  </Route>
);
