import { EmptyData, NavMenuError } from 'shared/ui';
import { SectionMenu } from 'shared/ui/section-menu';
import { APP_SETTING_ROUTES } from 'shared/consts/routes/app-settings';

import { SECTION } from 'entities/navigation';
import { useGetSettingsListQuery } from 'entities/settings';

import { AddButton } from './add-button';

const { label, path } = SECTION['app-settings'];

export function ConnectedAppSettingsMenu() {
  const { data, isFetching, error } = useGetSettingsListQuery();

  if (data && data.statusCode !== 0) {
    return <NavMenuError text={data.statusDescription} />;
  }

  if (error) {
    return <NavMenuError />;
  }

  if (!isFetching && !data) {
    return <EmptyData />;
  }

  const items = (
    data?.settings.filter((setting) => setting.country !== null) || []
  ).map(({ country: { name, id } }) => ({
    id,
    name,
  }));

  return (
    <SectionMenu
      loading={isFetching}
      items={items}
      menuKey={path}
      absolutePath={APP_SETTING_ROUTES.APP_SETTINGS}
      label={label}
      addButton={<AddButton />}
    />
  );
}
