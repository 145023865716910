import { GroupDto } from 'entities/group';

export const getSelectedGroupsNames = (
  groupsParam: string,
  groups: GroupDto[]
): string =>
  groupsParam
    .split(',')
    .map(
      (groupId) => groups.find((group) => String(group.id) === groupId)?.name
    )
    .join(', ');
