import { Col, Form, FormProps, Spin } from 'antd';
import { useState } from 'react';

import { openErrorNotification, openSuccessNotification } from 'shared/lib';
import { UiInput } from 'shared/ui/ui-kit';

import { ManualCommandType } from 'entities/manual-command';

import { ADD_ERROR, ADD_SUCCESS } from '../../../consts';

import {
  FormButtonsContainer,
  FormItem,
  FormRow,
  StyledButton,
} from '../../../../../common-styles';

import { useSendCommandMutation } from '../../../../../redux/services/private-methods';
import { VALIDATION_RULE } from '../../../../../constants/validation-rules';

interface FormData {
  chargePointName: string;
  transactionId: string;
}

type Props = {
  commandType: ManualCommandType;
  cpName: string;
  transactionId?: string;
  closeModal: () => void;
  isDisabled?: boolean;
};

export function FinishChargingForm({
  closeModal,
  isDisabled = true,
  commandType,
  cpName,
  transactionId,
}: Props) {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(isDisabled);

  const [sendCommandTrigger, { isLoading }] = useSendCommandMutation();

  const [form] = Form.useForm<FormData>();

  const onValuesChange: FormProps<FormData>['onValuesChange'] = (
    _,
    allValues
  ) => {
    if (allValues.chargePointName !== '' && allValues.transactionId !== '') {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  const handleSubmit = async (values: FormData) => {
    const req = {
      command: commandType,
      cpName: values.chargePointName,
      body: { transactionId: values.transactionId },
    };

    try {
      const res = await sendCommandTrigger(req).unwrap();

      res.statusCode === 0
        ? openSuccessNotification(ADD_SUCCESS)
        : openErrorNotification(res.statusDescription);
    } catch (e) {
      openErrorNotification(ADD_ERROR);
    } finally {
      closeModal();
    }
  };

  const getInitialValues = () => {
    return {
      chargePointName: cpName,
      transactionId,
    };
  };

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onFinish={handleSubmit}
      onValuesChange={onValuesChange}
      initialValues={getInitialValues()}
    >
      <FormRow gutter={10}>
        <Col span={12}>
          <FormItem
            label="Наименование ЭЗС"
            name="chargePointName"
            rules={[VALIDATION_RULE.REQUIRED]}
          >
            <UiInput />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            label="ID транзакции"
            name="transactionId"
            rules={[VALIDATION_RULE.REQUIRED]}
          >
            <UiInput />
          </FormItem>
        </Col>
      </FormRow>
      <FormButtonsContainer>
        <Spin spinning={isLoading}>
          <StyledButton
            htmlType="submit"
            type="primary"
            disabled={isSubmitDisabled}
          >
            Добавить
          </StyledButton>
        </Spin>
        <StyledButton htmlType="button" type="default" onClick={closeModal}>
          Отмена
        </StyledButton>
      </FormButtonsContainer>
    </Form>
  );
}
