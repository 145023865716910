import { useParams } from 'react-router-dom';

import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
  getServerErrorText,
} from 'shared/lib';

import { EmptyData, ErrorMessage } from 'shared/ui';

import {
  ConnectedOwnerForm,
  OwnerDto,
  OwnerFormData,
  OwnerMaxBonusPay,
  prepareRequestData,
  useGetOwnerQuery,
  useUpdateOwnerMutation,
  useGetByIdOwnerMaxBonusPayQuery,
  useCustomUpdateOwnerMutation,
} from 'entities/owner';

import { EDIT_SUCCESS, EDIT_ERROR } from '../consts';
import { useGetAllOwnerMaxBonusPayQuery } from 'entities/owner/api';

const getInitialValues = (
  owner: OwnerDto,
  ownerMaxBonusPay: OwnerMaxBonusPay | undefined
): OwnerFormData => {
  const {
    tariff,
    isDeleted,
    iconName,
    phoneNumber,
    telegram,
    inn,
    kpp,
    legalName,
    dirName,
    ...rest
  } = owner;

  return {
    ...rest,
    id: String(rest.id),
    tariffId: String(tariff.id),
    // Костыль для ранее созданных Владельцев (с phoneNumber/telegram = null)
    inn: inn ?? '',
    kpp: kpp ?? '',
    legalName: legalName ?? '',
    phoneNumber: phoneNumber ?? '',
    telegram: telegram ?? '',
    dirName: dirName ?? '',
    maxOwnerBonusPay: ownerMaxBonusPay
      ? String(ownerMaxBonusPay.maxBonusPayPercentages)
      : '100',
  };
};

export function EditOwner() {
  const { ownerId } = useParams() as { ownerId: string };

  const [trigger, { isLoading: isMutationLoading }] =
    useCustomUpdateOwnerMutation();

  const { data, isFetching, error: ownerError } = useGetOwnerQuery(ownerId);

  const {
    data: ownersMaxBonusPay,
    isFetching: isOwnersMaxBonusPayFetching,
    error: ownersMaxBonusPayError,
  } = useGetAllOwnerMaxBonusPayQuery();

  if (isFetching || isOwnersMaxBonusPayFetching) {
    return <div>Loading...</div>;
  }

  const error = [ownerError, ownersMaxBonusPayError].find(
    (err) => err !== undefined
  );

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  if (!data?.owner) {
    return <EmptyData />;
  }

  const ownerMaxBonusPay = ownersMaxBonusPay?.find(
    (el) => String(el.ownerId) === ownerId
  );

  const handleSubmit = async (values: OwnerFormData) => {
    const req = {
      ...prepareRequestData(values),
      id: Number(ownerId),
      oldMaxOwnerBonusPay: ownerMaxBonusPay?.maxBonusPayPercentages,
      maxOwnerBonusPayId: ownerMaxBonusPay?.id,
    };

    try {
      const res = await trigger(req).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);
        return;
      }
      //

      openSuccessNotification(EDIT_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : EDIT_ERROR;

      openErrorNotification(errorText);
    }
  };

  const initialValues = getInitialValues(data.owner, ownerMaxBonusPay);

  return (
    <ConnectedOwnerForm
      handleSubmit={handleSubmit}
      loading={isMutationLoading}
      initialValues={initialValues}
      title={data.owner.name}
      disabled
    />
  );
}
