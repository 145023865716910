import { useParams } from 'react-router-dom';

import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
  getServerErrorText,
  toCurrencyUnit,
} from 'shared/lib';

import { EmptyData, ErrorMessage } from 'shared/ui';

import {
  ChargeTariffBankIdDto,
  ConnectedTariffForm,
  prepareRequestData,
  TariffFormData,
  useGetTariffQuery,
  useUpdateTariffMutation,
} from 'entities/tariff';

import { EDIT_ERROR, EDIT_SUCCESS } from '../consts';

const getInitialValues = (tariff: ChargeTariffBankIdDto): TariffFormData => {
  const {
    name,
    editProperties,
    id,
    bankId,
    startFee,
    costOfQuickCharging,
    costOfReserve,
    // costOfSlowCharging,
    triggerValue,
    triggerFee,
    parkFee,
    parkTimeAfter,
    isArchive,
    tariffType,
    triggerType,
  } = tariff;

  return {
    name,
    editProperties,
    id: String(id),
    bankId: String(bankId),
    startFee: toCurrencyUnit(startFee),
    DCPerKilowatt: tariffType === 0 ? true : false,
    costOfQuickCharging: toCurrencyUnit(costOfQuickCharging),
    DCPerMinute: tariffType === 1 ? true : false,
    // ACPerKilowatt: false, // пока не используется?
    // costOfSlowCharging: toCurrencyUnit(costOfSlowCharging),
    batteryPercentageTrigger: triggerType === 1 ? true : false,
    batteryPercentage: triggerType === 1 ? String(triggerValue) : '0',
    batteryPercentageFee: triggerType === 1 ? toCurrencyUnit(triggerFee) : '0',
    powerDropTrigger: triggerType === 2 ? true : false,
    powerDrop: triggerType === 2 ? String(triggerValue) : '',
    powerDropFee: triggerType === 2 ? toCurrencyUnit(triggerFee) : '0',
    parkFee: toCurrencyUnit(parkFee),
    parkTimeAfter: String(parkTimeAfter),
    isArchive,
    costOfReserve: toCurrencyUnit(costOfReserve),
  };
};

export function EditTariff() {
  const [trigger, { isLoading: isMutationLoading }] = useUpdateTariffMutation();

  const { tariffId } = useParams() as { tariffId: string };

  const { isFetching, error, data } = useGetTariffQuery(tariffId);

  const handleSubmut = async (values: TariffFormData) => {
    const req = prepareRequestData(values);

    try {
      const res = await trigger(req).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);
        return;
      }
      //

      openSuccessNotification(EDIT_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : EDIT_ERROR;

      openErrorNotification(errorText);
    }
  };

  if (isFetching) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  if (!data?.chargeTariff) {
    return <EmptyData />;
  }

  const initialValues = getInitialValues(data.chargeTariff);

  return (
    <ConnectedTariffForm
      handleSubmit={handleSubmut}
      loading={isMutationLoading}
      initialValues={initialValues}
      title={data.chargeTariff.name}
      disabled
    />
  );
}
