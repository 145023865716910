import { Col, Form, FormProps, Spin } from 'antd';
import { useState, useEffect } from 'react';

import { formHasErrors, VALIDATION_RULE } from 'shared/lib';
import { UiInput } from 'shared/ui/ui-kit';

import { FormSubtitle } from './styles';
import {
  FormButtonsContainer,
  FormItem,
  FormRow,
  StyledButton,
} from '../../../../../../../common-styles';
import { ProfileFormData } from '../../model';

type Props = {
  initialValues: ProfileFormData;
  handleSubmit: (values: ProfileFormData) => Promise<void>;
  loading: boolean;
};

export function ProfileForm({ handleSubmit, initialValues, loading }: Props) {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const [hasErrors, setHasErrors] = useState(false);

  const [form] = Form.useForm<ProfileFormData>();

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  const onFieldsChange: FormProps<ProfileFormData>['onFieldsChange'] = (
    _,
    _allFields
  ) => {
    setHasErrors(formHasErrors(form));
  };

  const onValuesChange: FormProps<ProfileFormData>['onValuesChange'] = (
    _,
    allValues
  ) => {
    if (allValues.email !== '') {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onFinish={handleSubmit}
      onFieldsChange={onFieldsChange}
      onValuesChange={onValuesChange}
      initialValues={initialValues}
    >
      <FormSubtitle>Личные данные</FormSubtitle>
      <FormRow gutter={10}>
        <Col span={24}>
          <FormItem label="Имя" name="firstName">
            <UiInput />
          </FormItem>
        </Col>
      </FormRow>
      <FormRow gutter={10}>
        <Col span={24}>
          <FormItem label="Фамилия" name="lastName">
            <UiInput />
          </FormItem>
        </Col>
      </FormRow>
      <FormRow gutter={10}>
        <Col span={24}>
          <FormItem label="Отчество" name="middleName">
            <UiInput />
          </FormItem>
        </Col>
      </FormRow>
      <FormRow gutter={10}>
        <Col span={24}>
          <FormItem
            label="E-mail"
            name="email"
            rules={[VALIDATION_RULE.REQUIRED, VALIDATION_RULE.EMAIL]}
          >
            <UiInput />
          </FormItem>
        </Col>
      </FormRow>
      <FormSubtitle>Смена пароля</FormSubtitle>
      <FormRow gutter={10}>
        <Col span={24}>
          <FormItem label="Новый пароль" name="newPassword">
            <UiInput type="password" autoComplete="new-password" />
          </FormItem>
        </Col>
      </FormRow>
      <FormButtonsContainer>
        <Spin spinning={loading}>
          <StyledButton
            htmlType="submit"
            type="primary"
            disabled={isSubmitDisabled || hasErrors}
          >
            Сохранить
          </StyledButton>
        </Spin>
      </FormButtonsContainer>
    </Form>
  );
}
