import { ADD_PREFIX, SECURITY } from './common';

export const GROUPS = 'groups';
export const GROUP = ':groupName';
export const USERS = 'users';

export const GROUP_ROUTES = {
  GROUPS: `/${SECURITY}/${GROUPS}`,
  GROUP: `/${SECURITY}/${GROUPS}/${GROUP}`,
  USERS_LIST: `/${SECURITY}/${GROUPS}/${GROUP}/${USERS}`,
  ADD_GROUP: `/${SECURITY}/${GROUPS}/${ADD_PREFIX}`,
};
