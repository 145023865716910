import { COMPANY_ROUTES } from 'shared/consts/routes/company';
import { MenuAddButton } from 'shared/ui/section-menu';

export function AddButton() {
  return (
    <MenuAddButton
      path={COMPANY_ROUTES.ADD_COMPANY}
      requiredRights={[
        {
          controller: 'Person',
          right: 'Write',
        },
      ]}
    />
  );
}
