export function TurtleIcon() {
  return (
    <svg
      fill="rgba(0, 0, 0, 0.5)"
      height="11"
      width="11"
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 338.199 338.199"
      xmlSpace="preserve"
    >
      <g id="XMLID_311_">
        <g>
          <g>
            <path
              d="M127.088,141.809l-36.345-50.03c-10.148,8.759-18.831,19.169-25.586,30.845H60c-33.138,0-60,26.863-60,60h49.1
       c0,9.446,1.098,18.634,3.161,27.451l58.864-19.127L127.088,141.809z"
            />
            <path
              d="M194.916,130.062v-0.001l36.354-50.055c-6.963-4.227-14.374-7.788-22.17-10.544V42.624c0-22.091-17.908-40-40-40
       c-22.092,0-40,17.909-40,40v26.838c-7.797,2.756-15.209,6.318-22.172,10.545l36.354,50.054H194.916z"
            />
            <path
              d="M58.44,229.109c4.555,10.831,10.649,20.854,18.017,29.792c-14.349,25.382-8.402,58.125,15.238,76.673l32.629-41.589
       c10.891,4.383,22.571,7.21,34.769,8.219l0.004-61.827l-41.823-30.392L58.44,229.109z"
            />
            <path
              d="M278.199,122.624h-5.157c-6.755-11.676-15.439-22.086-25.587-30.846l-36.344,50.029l15.963,49.14l58.864,19.126
       c2.063-8.816,3.161-18.004,3.161-27.449h49.1C338.199,149.487,311.337,122.624,278.199,122.624z"
            />
            <path
              d="M220.926,209.984l-41.822,30.391l0.005,61.828c12.196-1.009,23.877-3.836,34.767-8.219l32.629,41.589
       c23.641-18.548,29.588-51.29,15.238-76.673c7.367-8.937,13.462-18.961,18.017-29.791L220.926,209.984z"
            />
            <polygon points="192.764,150.06 145.436,150.06 130.807,195.096 169.1,222.922 207.393,195.096 			" />
          </g>
        </g>
      </g>
    </svg>
  );
}
