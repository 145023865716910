import {
  ADD_PREFIX,
  CHARGE_POINTS_RIGHTS,
  CONTROLLER_CLAIMS,
  SECURITY,
} from './common';

export const USERS = 'users';
export const USER = ':userName';
export const ROLES = 'roles';

export const USER_ROUTES = {
  USERS: `/${SECURITY}/${USERS}`,
  USER: `/${SECURITY}/${USERS}/${USER}`,
  CONTROLLER_CLAIMS: `/${SECURITY}/${USERS}/${USER}/${CONTROLLER_CLAIMS}`,
  CHARGE_POINTS_RIGHTS: `/${SECURITY}/${USERS}/${USER}/${CHARGE_POINTS_RIGHTS}`,
  ROLES_LIST: `/${SECURITY}/${USERS}/${USER}/${ROLES}`,
  ADD_USER: `/${SECURITY}/${USERS}/${ADD_PREFIX}`,
};
