import { getEndpointPath } from 'shared/lib';

import { PLATFORM_SERVICE } from 'entities/authorization';

import { apiSlice } from '../../../redux/services/apiSlice';
import { ReserveSessionBaseResponse, SessionsBaseResponse } from './types';

export interface GetSessionsParams {
  page: number;
  limit: number;
  type?: string;
  phone?: string;
  cpName?: string;
  dateFrom?: string;
  dateTo?: string;
  search?: string;
  searchText?: string;
  ordering?: string;
  groupId?: string;
  isProblemFiscal?: boolean;
}

const KEY = 'PrivateMethods';

const getUrl = getEndpointPath(PLATFORM_SERVICE, KEY);

export const api = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSessions: builder.query<SessionsBaseResponse, GetSessionsParams>({
      query: (params) => ({
        url: getUrl(`GetSessions`),
        params: {
          ...params,
          groupId: params.groupId || 0,
        },
      }),

      providesTags: (result, error, arg) => [{ type: 'Sessions' }],
    }),
    getSessionReserves: builder.query<
      ReserveSessionBaseResponse,
      GetSessionsParams
    >({
      query: (params) => ({
        url: getUrl(`GetSessionReserves`),
        params: {
          ...params,
          groupId: params.groupId || 0,
        },
      }),

      providesTags: (result, error, arg) => [{ type: 'SessionsReserves' }],
    }),
  }),
});

export const { useGetSessionsQuery, useGetSessionReservesQuery } = api;
