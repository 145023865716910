import { createColumnHelper } from '@tanstack/react-table';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';

import { useTableSettings } from 'shared/ui/table/visible-columns/use-table-settings';
import { TColumnDef } from 'shared/ui/table/types';

import { selectUsersTable } from '../../../../redux/slices/tables/tables-slice';
import { setVisibleColumns, TableColumns, TableColumnsState } from '../store';

import { UserDto } from 'entities/user';

const columnHelper = createColumnHelper<UserDto>();

const DATA_COLUMNS: TColumnDef<UserDto, TableColumns>[] = [
  {
    accessorKey: 'userName',
    id: 'userName',
    header: 'Логин',
  },
  {
    accessorKey: 'email',
    id: 'email',
    header: 'E-mail',
  },
  {
    accessorKey: 'lastName',
    id: 'lastName',
    header: 'Фамилия',
  },
  {
    accessorKey: 'firstName',
    id: 'firstName',
    header: 'Имя',
  },
  {
    accessorKey: 'groups',
    id: 'groups',
    header: 'Группы',
    cell: (props) => {
      return props.row.original.groups.join(', ');
    },
  },
  {
    accessorKey: 'roles',
    id: 'roles',
    header: 'Роли',
    cell: (props) => {
      return props.row.original.roles.join(', ');
    },
  },
];

export const useColumns = () => {
  const dispatch = useDispatch();

  const settings = useSelector(selectUsersTable);

  const settingsColumn = useTableSettings({
    columnHelper,
    columns: DATA_COLUMNS.map(({ id, header }) => {
      return {
        key: id,
        label: header as string,
        isChecked: settings[id],
      };
    }),
    settings,
    renderCell: (props) => '',
    setVisibleColumns: (cols: TableColumnsState) => {
      dispatch(setVisibleColumns(cols));
    },
  });

  const visibleColumns = useMemo(() => {
    const dataCols = settings
      ? DATA_COLUMNS.filter((el) => settings[el.id])
      : DATA_COLUMNS;

    return [...dataCols, settingsColumn];
  }, [settings]);

  return visibleColumns;
};
