import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Form, Space, Spin } from 'antd';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  checkControllerRight,
  Controller,
  selectTokenClaims,
  selectTokenRoles,
} from 'entities/authorization';

import { StyledButton } from '../../../../../common-styles';
import { ControllerRweRight } from '../../../../../models/identity';
import { FormButtonsContainer } from '../../../../FormButtons/styles';
import { UserControllerRightsTableItem } from '../../types';
import {
  AdminInfoPanel,
  RightCheckboxContainer,
  RightsFormItem,
} from './styles';

import { UiCheckbox } from 'shared/ui/ui-kit/checkbox';
import { RightsTable } from 'shared/ui/table';

const columnHelper = createColumnHelper<UserControllerRightsTableItem>();

type RoleRight = {
  [k in Controller]?: Partial<Omit<ControllerRweRight, 'controllerName'>>;
};

type Props = {
  tableData: UserControllerRightsTableItem[];
  loading: boolean;
  handleSubmit: (values: Record<string, boolean>) => Promise<void>;
  initialValues: Record<string, boolean>;
  isAdmin: boolean;
  roleRight: RoleRight;
};

export function UserControllerRights({
  tableData,
  loading,
  handleSubmit,
  isAdmin,
  initialValues,
  roleRight,
}: Props) {
  const roles = useSelector(selectTokenRoles);
  const claims = useSelector(selectTokenClaims);

  const hasWriteRight = checkControllerRight(
    'Identity',
    'Write',
    roles,
    claims
  );

  const navigate = useNavigate();

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  const [form] = Form.useForm<Record<string, boolean>>();

  const columns = [
    columnHelper.accessor('label', {
      header: 'Разделы',
    }),
    columnHelper.accessor('read', {
      cell: (props) => {
        const type = 'read';

        const { controllerName } = props.row.original;

        const isRoleRight = Boolean(
          roleRight[controllerName as Controller]?.[type]
        );

        return (
          <RightCheckboxContainer isRoleRight={isRoleRight}>
            <RightsFormItem
              name={`${controllerName}/${type}`}
              valuePropName="checked"
            >
              <UiCheckbox disabled={isAdmin} />
            </RightsFormItem>
          </RightCheckboxContainer>
        );
      },
      header: 'Чтение',
    }),
    columnHelper.accessor('write', {
      header: 'Запись',
      cell: (props) => {
        const type = 'write';

        const { controllerName } = props.row.original;

        const isRoleRight = Boolean(
          roleRight[controllerName as Controller]?.[type]
        );

        return (
          <RightCheckboxContainer isRoleRight={isRoleRight}>
            <RightsFormItem
              name={`${controllerName}/${type}`}
              valuePropName="checked"
            >
              <UiCheckbox disabled={isAdmin} />
            </RightsFormItem>
          </RightCheckboxContainer>
        );
      },
    }),
    columnHelper.accessor('execute', {
      header: 'Выполнение',
      cell: (props) => {
        const type = 'execute';

        const { controllerName } = props.row.original;

        const isRoleRight = Boolean(
          roleRight[controllerName as Controller]?.[type]
        );

        return (
          <RightCheckboxContainer isRoleRight={isRoleRight}>
            <RightsFormItem
              name={`${controllerName}/${type}`}
              valuePropName="checked"
            >
              <UiCheckbox disabled={isAdmin} />
            </RightsFormItem>
          </RightCheckboxContainer>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      {isAdmin ? (
        <AdminInfoPanel>Назначена роль "Администратор"</AdminInfoPanel>
      ) : null}
      <Form
        form={form}
        onFinish={handleSubmit}
        initialValues={initialValues}
        disabled={isAdmin}
      >
        <RightsTable table={table} />

        {hasWriteRight ? (
          <Spin spinning={loading}>
            <FormButtonsContainer>
              <Space direction="horizontal" size={10}>
                <StyledButton htmlType="submit" type="primary">
                  Сохранить
                </StyledButton>
                <StyledButton
                  htmlType="button"
                  type="default"
                  onClick={() => navigate(-1)}
                >
                  Отмена
                </StyledButton>
              </Space>
            </FormButtonsContainer>
          </Spin>
        ) : null}
      </Form>
    </>
  );
}
