import { Route } from 'react-router-dom';

import { SectionLayout } from 'shared/ui/layout';
import { CPO, CPOS } from 'shared/consts/routes/cpo';
import { ADD_PREFIX } from 'shared/consts/routes/common';

import { RequireAuth } from 'entities/authorization';
import { ConnectedCposMenu } from 'entities/cpo';

import { AddCpoPage, EditCpoPage } from 'pages/cpo';

export const CpoRoutes = (
  <Route element={<RequireAuth controllerName="Cpo" rightType="Read" />}>
    <Route path={CPOS} element={<SectionLayout menu={<ConnectedCposMenu />} />}>
      <Route element={<RequireAuth controllerName="Cpo" rightType="Write" />}>
        <Route path={ADD_PREFIX} element={<AddCpoPage />} />
      </Route>
      <Route path={CPO} element={<EditCpoPage />} />
    </Route>
  </Route>
);
