import { useSearchParams } from 'react-router-dom';
import { useMemo, useState } from 'react';
import {
  createColumnHelper,
  getCoreRowModel,
  getGroupedRowModel,
  useReactTable,
  GroupingState,
} from '@tanstack/react-table';
import Excel from 'exceljs';

import { ExportButton } from 'shared/ui';
import {
  FORMAT_TO_MINUTES,
  showTimeString,
  renderBooleanAsString,
  getRuFormatDateString,
  TABLE_PHONE_COLUMN_WIDTH,
  replaceSubstring,
  toCurrencyUnit,
  toConsumeUnit,
} from 'shared/lib';

import {
  ReportInfo,
  ReportTableLayout,
  StyledReportCard,
  ReportHeader,
  applyWorksheetStyles,
  downloadReport,
  setColumnsAutoWidth,
  setReportMetadata,
  DEFAULT_REPORT_COLUMN_WIDTH,
  type MongoDebitReportDto,
  highlightLastRow,
} from 'entities/report';

const COLUMN = {
  chargeEndTime: {
    key: 'chargeEndTime',
    label: 'Дата завершения зарядки',
  },
  cpName: { key: 'cpName', label: 'Номер ЭЗС' },
  userBrowseName: { key: 'userBrowseName', label: 'Номер телефона' },
  calculatedPayment: {
    key: 'calculatedPayment',
    label: 'Сумма задолженности (руб.)',
  },
  calculatedConsume: { key: 'calculatedConsume', label: 'Потреблено (кВт*ч)' },
  bankStatus: { key: 'bankStatus', label: 'Привязка карты' },
  cpAddress: { key: 'cpAddress', label: 'Адрес' },
};

const REPORT_TITLE = 'Отчет по задолженности';
const DATA_START_ROW = 4;

const columnHelper = createColumnHelper<MongoDebitReportDto>();

const emptyArr: MongoDebitReportDto[] = [];

type Props = {
  debits: MongoDebitReportDto[];
  loading: boolean;
};

export function DebitReportTable({ debits, loading }: Props) {
  const [searchParams, setSearchParams] = useSearchParams();

  const dateFromParam = searchParams.get('dateFrom');
  const dateToParam = searchParams.get('dateTo');

  const [grouping, setGrouping] = useState<GroupingState>([]);

  const generateTable = async () => {
    const workbook = new Excel.Workbook();

    setReportMetadata(workbook);

    const worksheet = workbook.addWorksheet(REPORT_TITLE);

    worksheet.mergeCells('A1', 'G1');

    worksheet.getCell('A2').value = 'Отчетный период:';
    worksheet.mergeCells('B2', 'G2');
    worksheet.getCell('B2').value = `${getRuFormatDateString(
      dateFromParam as string
    )}-${getRuFormatDateString(dateToParam as string)}`;

    worksheet.getRow(DATA_START_ROW).values = [
      COLUMN.chargeEndTime.label,
      COLUMN.cpName.label,
      COLUMN.userBrowseName.label,
      COLUMN.calculatedPayment.label,
      COLUMN.calculatedConsume.label,
      COLUMN.bankStatus.label,
      COLUMN.cpAddress.label,
    ];

    worksheet.columns = [
      { key: COLUMN.chargeEndTime.key, width: DEFAULT_REPORT_COLUMN_WIDTH },
      { key: COLUMN.cpName.key, width: DEFAULT_REPORT_COLUMN_WIDTH },
      { key: COLUMN.userBrowseName.key, width: DEFAULT_REPORT_COLUMN_WIDTH },
      { key: COLUMN.calculatedPayment.key, width: DEFAULT_REPORT_COLUMN_WIDTH },
      { key: COLUMN.calculatedConsume.key, width: DEFAULT_REPORT_COLUMN_WIDTH },
      { key: COLUMN.bankStatus.key, width: DEFAULT_REPORT_COLUMN_WIDTH },
      { key: COLUMN.cpAddress.key, width: DEFAULT_REPORT_COLUMN_WIDTH },
    ];

    debits.forEach(
      ({
        chargeEndTime,
        userBrowseName,
        bankStatus,
        operation: { cpName, calculatedPayment, calculatedConsume, cpAddress },
      }) => {
        worksheet.addRow({
          chargeEndTime: replaceSubstring(
            showTimeString(chargeEndTime, FORMAT_TO_MINUTES),
            '-',
            '.'
          ),
          cpName,
          userBrowseName,
          calculatedPayment: toCurrencyUnit(calculatedPayment),
          calculatedConsume: toConsumeUnit(calculatedConsume),
          bankStatus: renderBooleanAsString(bankStatus),
          cpAddress,
        });
      }
    );

    worksheet.addRow({
      chargeEndTime: 'Итого:',
      cpName: '',
      userBrowseName: '',
      calculatedPayment: replaceSubstring(paymentSummary, '.', ','),
      calculatedConsume: replaceSubstring(consumeSummary, '.', ','),
      bankStatus: `Всего операций: ${operationsSummary}`,
      cpAddress: '',
    });

    applyWorksheetStyles(worksheet, DATA_START_ROW);
    highlightLastRow(worksheet);

    const lastRowIndex = debits.length + DATA_START_ROW + 1;

    worksheet.mergeCells(`A${lastRowIndex}:C${lastRowIndex}`);
    worksheet.mergeCells(`F${lastRowIndex}:G${lastRowIndex}`);

    worksheet.getCell('A1').value = REPORT_TITLE;
    worksheet.getCell('B2').alignment = {
      horizontal: 'left',
    };

    setColumnsAutoWidth(worksheet, DATA_START_ROW);

    const reportFileName = `${REPORT_TITLE}-${dateFromParam}-${dateToParam}`;

    downloadReport(workbook, reportFileName);
  };

  const paymentSummary = toCurrencyUnit(
    debits.reduce((acc, el) => {
      return acc + el.operation.calculatedPayment;
    }, 0)
  );

  const consumeSummary = toConsumeUnit(
    debits.reduce((acc, el) => {
      return acc + el.operation.calculatedConsume;
    }, 0)
  );

  const operationsSummary = debits.length;

  const defaultColumns = [
    columnHelper.group({
      header: 'Итого',
      footer: (props) => {
        return 'Итого';
      },
      columns: [
        columnHelper.accessor('chargeEndTime', {
          header: COLUMN.chargeEndTime.label,
          id: 'chargeEndTime',
          cell: (props) =>
            showTimeString(props.row.original.chargeEndTime, FORMAT_TO_MINUTES),
          size: 155,
        }),
        columnHelper.accessor('operation', {
          header: COLUMN.cpName.label,
          id: 'operation.cpName',
          cell: (props) => props.row.original.operation.cpName,
        }),
        columnHelper.accessor('userBrowseName', {
          header: COLUMN.userBrowseName.label,
          id: 'userBrowseName',
          size: TABLE_PHONE_COLUMN_WIDTH,
        }),
      ],
    }),
    columnHelper.group({
      header: 'calculatedPayment',
      footer: paymentSummary,
      columns: [
        columnHelper.accessor('operation', {
          header: COLUMN.calculatedPayment.label,
          id: 'operation.calculatedPayment',
          cell: (props) =>
            toCurrencyUnit(props.row.original.operation.calculatedPayment),
        }),
      ],
    }),
    columnHelper.group({
      header: 'calculatedConsume',
      footer: consumeSummary,
      columns: [
        columnHelper.accessor('operation', {
          header: COLUMN.calculatedConsume.label,
          id: 'operation.calculatedConsume',
          cell: (props) =>
            toConsumeUnit(props.row.original.operation.calculatedConsume),
        }),
      ],
    }),
    columnHelper.group({
      header: 'Operations',
      footer: (props) => {
        return `Всего операций: ${operationsSummary}`;
      },
      columns: [
        columnHelper.accessor('bankStatus', {
          header: COLUMN.bankStatus.label,
          id: 'bankStatus',
          cell: (props) => renderBooleanAsString(props.row.original.bankStatus),
        }),
        columnHelper.accessor('operation', {
          header: COLUMN.cpAddress.label,
          id: 'operation.cpAddress',
          cell: (props) => props.row.original.operation.cpAddress,
        }),
      ],
    }),
  ];

  const columns = useMemo(
    () => defaultColumns,
    [paymentSummary, consumeSummary, operationsSummary]
  );

  const data = useMemo(() => {
    return debits ?? emptyArr;
  }, [debits]);

  const table = useReactTable({
    data,
    columns,
    state: {
      grouping,
    },
    onGroupingChange: setGrouping,
    getGroupedRowModel: getGroupedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <StyledReportCard>
      <ReportHeader>
        <ReportInfo
          title={REPORT_TITLE}
          dateFromParam={dateFromParam}
          dateToParam={dateToParam}
        />
        <ExportButton
          onClick={generateTable}
          disabled={loading || !debits.length}
        />
      </ReportHeader>
      <ReportTableLayout
        table={table}
        loading={loading}
        ignoreHeaderGroups={['0']}
        includeFooterGroups={['0']}
      />
    </StyledReportCard>
  );
}
