import { type MongoAcquiringReportDTO } from 'entities/report';
import { BankDTO } from 'entities/bank';

import { AcquiringReportTable } from 'features/report/acquiring/table';
import { AcquiringReportForm } from 'features/report/acquiring/form';

type Props = {
  allowReportFetching: () => void;
  banks: BankDTO[];
  sessions: MongoAcquiringReportDTO[];
  loading: boolean;
};

export function AcquiringReport({
  allowReportFetching,
  banks,
  sessions,
  loading,
}: Props) {
  return (
    <>
      <AcquiringReportForm
        allowReportFetching={allowReportFetching}
        banks={banks}
        loading={loading}
      />
      <AcquiringReportTable
        sessions={sessions}
        loading={loading}
        banks={banks}
      />
    </>
  );
}
