import { useSelector } from 'react-redux';
import NiceModal from '@ebay/nice-modal-react';
import { useNavigate } from 'react-router-dom';
import { MoreOutlined } from '@ant-design/icons';

import { UiPopover } from 'shared/ui';

import {
  checkControllerRight,
  selectTokenClaims,
  selectTokenRoles,
} from 'entities/authorization';
import {
  BaseInfoManualCommandType,
  BASE_INFO_MANUAL_COMMAND_TYPE,
} from 'entities/manual-command';

import { ManualCommandModal } from 'features/manual-command';

import { PopoverButtonsContainer } from '../../../../common-styles';

type Props = {
  chargePointName: string;
  chargePointId?: string;
};

export function BaseInfoCardActions({ chargePointName, chargePointId }: Props) {
  const roles = useSelector(selectTokenRoles);
  const claims = useSelector(selectTokenClaims);

  const hasWriteRight = checkControllerRight(
    'ChargePoint',
    'Write',
    roles,
    claims
  );

  const hasExecuteRight = checkControllerRight(
    'PrivateMethods',
    'Execute',
    roles,
    claims
  );

  const navigate = useNavigate();

  const showManualCommandModal = (type: BaseInfoManualCommandType) => {
    NiceModal.show(ManualCommandModal, {
      commandType: type,
      chargePointName,
      chargePointId,
    });
  };

  const manualCommands = (
    Object.keys(BASE_INFO_MANUAL_COMMAND_TYPE) as unknown as Array<
      keyof typeof BASE_INFO_MANUAL_COMMAND_TYPE
    >
  ).map((type) => {
    return (
      <button
        key={BASE_INFO_MANUAL_COMMAND_TYPE[type]}
        onClick={() => {
          showManualCommandModal(type);
        }}
      >
        {BASE_INFO_MANUAL_COMMAND_TYPE[type]}
      </button>
    );
  });

  const chargePointMutations = (
    <button
      onClick={() => {
        navigate('edit');
      }}
    >
      Редактировать
    </button>
  );

  const content = (
    <>
      {hasExecuteRight ? (
        <PopoverButtonsContainer>{manualCommands}</PopoverButtonsContainer>
      ) : null}
      {hasWriteRight ? (
        <PopoverButtonsContainer>
          {chargePointMutations}
        </PopoverButtonsContainer>
      ) : null}
    </>
  );

  if (!hasExecuteRight && !hasWriteRight) {
    return null;
  }

  return (
    <UiPopover content={content}>
      <MoreOutlined rotate={90} />
    </UiPopover>
  );
}
