import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { ConfirmDialog, UiModal } from 'shared/ui';
import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';

import {
  UnbindBankCardRequest,
  useUnbindBankCardMutation,
} from '../../../../redux/services/manual-command';

import { UNBIND_ERROR, UNBIND_SUCCESS } from '../consts';

type Props = UnbindBankCardRequest;

export const UnbindCardModal = NiceModal.create((props: Props) => {
  const modal = useModal();

  const [trigger, { isLoading }] = useUnbindBankCardMutation();

  const handleDelete = async (req: UnbindBankCardRequest) => {
    try {
      const res = await trigger(props).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);
        return;
      }
      //

      openSuccessNotification(UNBIND_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : UNBIND_ERROR;

      openErrorNotification(errorText);
    } finally {
      modal.hide();
    }
  };

  return (
    <UiModal
      title={'Отвязать карту?'}
      visible={modal.visible}
      onCancel={modal.hide}
      afterClose={modal.remove}
    >
      <ConfirmDialog
        onCancel={modal.hide}
        onConfirm={() => handleDelete(props)}
        loading={isLoading}
      />
    </UiModal>
  );
});
