import styled from 'styled-components';

import { COLOR } from 'shared/consts';

export const StyledCard = styled.article`
  background: ${COLOR.white};
  border-radius: 8px;
  padding: 20px;
  height: 271px; // temp
`;

export const LocationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
`;

export const AddressInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const AddressTitle = styled.h4`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #171725;
`;

export const Address = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR.darkGrey};
`;

export const MapContainer = styled.div`
  border-radius: 8px;
  flex-grow: 1;
  position: relative;
`;
