import { useLocation, useParams } from 'react-router-dom';
import useWindowFocus from 'use-window-focus';

import { POLLING_INTERVAL } from 'shared/consts';
import { NavMenuError } from 'shared/ui';

import { useGetChargePointsQuery } from '../../../../../redux/services/charge-points';
import { useGetChargePointGroupsQuery } from 'entities/group';

import { ChargePointsMenu } from '../menu';

export function ConnectedChargePointsMenu() {
  const isWindowFocused = useWindowFocus();

  const { groupId, chargePointName } = useParams() as {
    groupId: string;
    chargePointName: string;
  };

  const {
    data: chargePoints,
    isFetching: isChargePointsFetching,
    error: chargePointsError,
    currentData,
  } = useGetChargePointsQuery(
    {
      limit: -1,
      page: 0,
      groupId,
    },
    {
      skip: groupId === undefined,
      pollingInterval: isWindowFocused ? POLLING_INTERVAL : undefined,
    }
  );

  const {
    data: groups,
    isLoading: isGroupsLoading,
    error: groupsError,
  } = useGetChargePointGroupsQuery();

  if (groupsError || chargePointsError) {
    return <NavMenuError />;
  }

  return (
    <ChargePointsMenu
      groups={groups?.groups || []}
      chargePoints={chargePoints?.chargePoints || []}
      isChargePointsFetching={isChargePointsFetching}
      isGroupsLoading={isGroupsLoading}
      hasChargePointCurrentData={Boolean(currentData)}
    />
  );
}
