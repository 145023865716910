import { useParams } from 'react-router-dom';

import { PersonHeader } from 'entities/person';

import { ConnectedClientBindingsTable } from 'features/bank-card/bindings-table';

export function PersonBindingsPage() {
  const { userLogin, groupId } = useParams() as {
    groupId: string;
    userLogin: string;
  };

  return (
    <>
      <PersonHeader />
      <ConnectedClientBindingsTable userLogin={userLogin} />
    </>
  );
}
