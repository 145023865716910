import { ADD_PREFIX, SETTINGS } from './common';

export const COUNTRIES = 'countries';
export const COUNTRY = ':countryId';

export const COUNTRY_ROUTES = {
  COUNTRIES: `/${SETTINGS}/${COUNTRIES}`,
  COUNTRY: `/${SETTINGS}/${COUNTRIES}/${COUNTRY}`,
  ADD_COUNTRY: `/${SETTINGS}/${COUNTRIES}/${ADD_PREFIX}`,
};
