import { Sidebar } from 'shared/ui/sidebar';
import { SiderbarMenuItem } from 'shared/ui/sidebar/types';
import { OwnersIcon } from 'shared/ui/sidebar/images/owners';
import { OWNERS } from 'shared/consts/routes/owner';
import { TariffsIcon } from 'shared/ui/sidebar/images/tariffs';
import { TARIFFS } from 'shared/consts/routes/tariff';
import { DiscountsIcon } from 'shared/ui/sidebar/images/discounts';
import { ChargePointsIcon } from 'shared/ui/sidebar/images/charge-points';
import { PersonsIcon } from 'shared/ui/sidebar/images/persons';
import { PERSONS } from 'shared/consts/routes/person';
import { DISCOUNTS } from 'shared/consts/routes/discount';
import { ReportsIcon } from 'shared/ui/sidebar/images/reports';
import { PUSH_NOTIFICATIONS } from 'shared/consts/routes/push-notification';
import { PushNotificationsIcon } from 'shared/ui/sidebar/images/push-notifications';
import { BonusesIcon } from 'shared/ui/sidebar/images/bonuses';
import { HUBS } from 'shared/consts/routes/hub';
import { HubsIcon } from 'shared/ui/sidebar/images/hubs';

import { LogoutButton } from 'features/authorization/logout';

const ITEMS: SiderbarMenuItem[] = [
  {
    label: 'Владельцы ЭЗС',
    path: OWNERS,
    icon: <OwnersIcon />,
    controller: 'Owner',
  },
  {
    label: 'Тарифы',
    path: TARIFFS,
    icon: <TariffsIcon />,
    controller: 'ChargeTariff',
  },
  {
    label: 'Скидки',
    path: DISCOUNTS,
    icon: <DiscountsIcon />,
    controller: 'Discount',
  },
  {
    label: 'Список ЭЗС',
    path: 'charge-points',
    icon: <ChargePointsIcon />,
    controller: 'ChargePoint',
  },
  {
    label: 'Зарядные хабы',
    path: HUBS,
    icon: <HubsIcon />,
    controller: 'Hub',
  },
  {
    label: 'Клиенты',
    path: PERSONS,
    icon: <PersonsIcon />,
    controller: 'Person',
  },
  {
    label: 'Отчеты',
    path: 'reports',
    icon: <ReportsIcon />,
    controller: 'Report',
  },
  {
    label: 'Пуш уведомления',
    path: PUSH_NOTIFICATIONS,
    icon: <PushNotificationsIcon />,
    controller: 'PushNotification',
  },
  {
    label: 'Кешбэк и награды',
    path: 'bonuses',
    icon: <BonusesIcon />,
    controller: 'BonusService', //
  },
];

export function MonitoringSidebar() {
  return <Sidebar logoutButton={<LogoutButton />} items={ITEMS} />;
}
