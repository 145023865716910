import { addSpacesToNumber, toFixedDecimals } from 'shared/lib';

import { GraphMetric, GraphMetricType } from '../model/metrics';

export const getValueSummaryInfo = (
  type: GraphMetricType,
  metrics: GraphMetric[]
) => {
  const rawSummary = metrics.reduce((acc, el) => acc + el.value, 0);

  switch (type) {
    case '0':
      return `${addSpacesToNumber(rawSummary)} успешных сессий`;
    case '1':
      return `${addSpacesToNumber(Math.round(rawSummary))} потребленных кВт*ч`;
    case '2':
      return `Объем выручки: ${addSpacesToNumber(
        Number(toFixedDecimals(rawSummary, 2))
      )} рублей`;
  }
};
