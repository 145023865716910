import styled from 'styled-components';

export const BonusInfoCurrencyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const NoBonusesContainer = styled.div`
  display: flex;
  gap: 25px;
  align-items: center;
`;

export const NoBonusesText = styled.p`
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
`;
