import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Leaves } from 'shared/lib/types';

import { MongoSessionDTO } from 'entities/session';

type ObjectLeaves = Leaves<MongoSessionDTO>;

export type TableColumns = Extract<
  ObjectLeaves,
  'psLastUpdateTime' | 'orderNumber' | 'status' | 'statusHR'
>;

export type TableColumnsState = Record<TableColumns, boolean>;

const initialState: TableColumnsState = {
  psLastUpdateTime: true,
  orderNumber: true,
  status: true,
  statusHR: true,
};

const slice = createSlice({
  name: 'clientBankCardSessionsTable',
  initialState,
  reducers: {
    setVisibleColumns: (state, action: PayloadAction<TableColumnsState>) => {
      return action.payload;
    },
  },
});

export const { setVisibleColumns } = slice.actions;
export default slice.reducer;
