import { Outlet } from 'react-router';

type Props = {
  siderbar: JSX.Element;
};

export function ChapterLayout({ siderbar }: Props) {
  return (
    <>
      {siderbar}
      <Outlet />
    </>
  );
}
