import { Carousel } from 'antd';
import styled from 'styled-components';

import { COLOR } from 'shared/consts';

export const CarouselContainer = styled.div`
  background: #3b3838;
  border-radius: 15px;
  padding: 40px;
  min-width: 594px;

  @media (max-width: 375px) {
    display: none;
  }
`;

export const LogoContainer = styled.div`
  margin-bottom: 80px;
`;

export const StyledCarousel = styled(Carousel)`
  background: #2c2929;
  border-radius: 15px;
`;

export const CarouselTitle = styled.p`
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: ${COLOR.white};
  margin-bottom: 20px;
`;

export const CarouselDescription = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 47px;
`;

export const PictureMock = styled.img`
  border-radius: 15px;
  width: 100%;
`;
