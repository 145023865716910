import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import { getEndpointPath } from 'shared/lib';

import {
  AddChargePointToGroupRequest,
  AddGroupBaseResponse,
  AddGroupRequest,
  AddGroupToGroupRequest,
  AddPersonToGroupRequest,
  CustomAddGroupRequest,
  CustomDeleteGroupRequest,
  CustomUpdateGroupRequest,
  DeleteChargePointFromGroupRequest,
  DeleteGroupFromGroupRequest,
  DeletePersonFromGroupRequest,
  GetChargePointGroupsRequestArgs,
  GroupBaseResponse,
  GroupsBaseResponse,
  UpdateGroupRequest,
} from 'entities/group';

import {
  CustomUpdateChargePointsGroupRequest,
  CustomUpdateUsersGroupRequest,
  UpdateChargePointsGroupRequest,
  UpdateUserGroupRequest,
} from 'entities/group/api/types';

import { apiSlice } from '../../../redux/services/apiSlice';
import { PLATFORM_SERVICE } from 'entities/authorization';

const KEY = 'Group';

const getUrl = getEndpointPath(PLATFORM_SERVICE, KEY);

// TODO: замена на baseApi

export const groupsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getChargePointGroups: builder.query<GroupsBaseResponse, void>({
      query: () => getUrl('GetChargePointGroups'),
      providesTags: ['ChargePointGroups'],
    }),
    getPersonsGroups: builder.query<GroupsBaseResponse, void>({
      query: () => getUrl('GetUsersGroups'),
      providesTags: ['PersonsGroups'],
    }),
    getGroup: builder.query<GroupBaseResponse, string>({
      query: (groupId) => ({
        url: getUrl(`GetGroup`),
        params: { groupId },
      }),
      providesTags: (result, error, arg) => [{ type: 'Group', id: arg }],
    }),
    addGroup: builder.mutation<AddGroupBaseResponse, AddGroupRequest>({
      query: (group) => ({
        url: getUrl('AddGroup'),
        method: 'POST',
        body: group,
      }),
    }),
    updateGroup: builder.mutation<GroupBaseResponse, UpdateGroupRequest>({
      query: (group) => ({
        url: getUrl('UpdateGroup'),
        method: 'PATCH',
        body: group,
      }),
    }),
    deleteGroup: builder.mutation<ResponseBase, string>({
      query: (id) => ({
        url: getUrl(`DeleteGroup`),
        params: { id },
        method: 'DELETE',
      }),
    }),
    addGroupToGroup: builder.mutation<ResponseBase, AddGroupToGroupRequest>({
      query: ({ groupId, groupToAddId }) => ({
        url: getUrl(`AddGroupToGroup`),
        params: { groupId, groupToAddId },
        method: 'POST',
      }),
    }),
    deleteGroupFromGroup: builder.mutation<
      ResponseBase,
      DeleteGroupFromGroupRequest
    >({
      query: ({ groupId, groupFromDeleteId }) => ({
        url: getUrl(`DeleteGroupFromGroup`),
        params: { groupId, groupFromDeleteId },
        method: 'DELETE',
      }),
    }),
    addChargePointToGroup: builder.mutation<
      ResponseBase,
      AddChargePointToGroupRequest
    >({
      query: ({ chargePointName, groupId }) => ({
        url: getUrl(`AddChargePointToGroup`),
        params: { chargePointName, groupId },
        method: 'POST',
      }),
    }),
    addPersonToGroup: builder.mutation<ResponseBase, AddPersonToGroupRequest>({
      query: ({ login, groupId }) => ({
        url: getUrl(`AddPersonToGroup`),
        params: { login, groupId },
        method: 'POST',
      }),
    }),
    deleteChargePointFromGroup: builder.mutation<
      ResponseBase,
      DeleteChargePointFromGroupRequest
    >({
      query: ({ chargePointName, groupId }) => ({
        url: getUrl(`DeleteChargePointFromGroup`),
        params: { chargePointName, groupId },
        method: 'DELETE',
      }),
    }),
    deletePersonFromGroup: builder.mutation<
      ResponseBase,
      DeletePersonFromGroupRequest
    >({
      query: ({ login, groupId }) => ({
        url: getUrl(`DeletePersonFromGroup`),
        params: { login, groupId },
        method: 'DELETE',
      }),
    }),
    // Complex custom queries/mutations
    //
    // При создании новой группы клиентов/эзс:
    // 1) Создаем группу
    // 2) Если задан parentId - добавляем её как child группу в parent
    //
    customAddGroup: builder.mutation<ResponseBase, CustomAddGroupRequest>({
      async queryFn(args, _queryApi, _extraOptions, baseQuery) {
        const { parentId, ...group } = args;

        const addGroupReq: AddGroupRequest = group;

        const addGroupRes = await baseQuery({
          url: getUrl(`AddGroup`),
          method: 'POST',
          body: addGroupReq,
        });

        if (addGroupRes.error) {
          return {
            error: addGroupRes.error as FetchBaseQueryError,
          };
        }

        const addGroupResData = addGroupRes.data as AddGroupBaseResponse;

        if (addGroupResData.statusCode !== 0) {
          return {
            error: {
              error: addGroupResData.statusDescription,
              status: `CUSTOM_ERROR`,
            },
          };
        }

        if (parentId !== '') {
          const addGroupToGroupReq: AddGroupToGroupRequest = {
            groupId: String(addGroupResData.groupId),
            groupToAddId: parentId,
          };

          const addGroupToGroupRes = await baseQuery({
            url: getUrl(`AddGroupToGroup`),
            params: addGroupToGroupReq,
            method: 'POST',
          });

          if (addGroupToGroupRes.error) {
            return {
              error: addGroupToGroupRes.error as FetchBaseQueryError,
            };
          }

          const addGroupToGroupResData =
            addGroupToGroupRes.data as ResponseBase;

          if (addGroupToGroupResData.statusCode !== 0) {
            return {
              error: {
                error: addGroupToGroupResData.statusDescription,
                status: `CUSTOM_ERROR`,
              },
            };
          }
        }

        return { data: { statusCode: 0, statusDescription: '' } };
      },
      invalidatesTags: (result, error, arg) => [
        arg.groupType === 1 ? 'PersonsGroups' : 'ChargePointGroups',
      ],
    }),
    //
    // При апдейте группы клиентов/эзс:
    // 1) Создаем группу
    // 2) Если был oldParentId, а теперь parentId пустой - удаляем из parent группы
    // 3) Если не было oldParentId, а parentId непустой - добавляем в parent группу
    // 4) Если был oldParentId, а parentId новый - удаляем из старой и добавляем в новую
    //
    customUpdateGroup: builder.mutation<ResponseBase, CustomUpdateGroupRequest>(
      {
        async queryFn(args, _queryApi, _extraOptions, baseQuery) {
          const { oldParentId, parentId, ...group } = args;

          const { id } = args;

          const updateGroupReq: UpdateGroupRequest = group;

          const updateGroupRes = await baseQuery({
            url: getUrl(`UpdateGroup`),
            method: 'PATCH',
            body: updateGroupReq,
          });

          if (updateGroupRes.error) {
            return {
              error: updateGroupRes.error as FetchBaseQueryError,
            };
          }

          const updateGroupResData = updateGroupRes.data as GroupBaseResponse;

          if (updateGroupResData.statusCode !== 0) {
            return {
              error: {
                error: updateGroupResData.statusDescription,
                status: `CUSTOM_ERROR`,
              },
            };
          }

          if (oldParentId && parentId === '') {
            const deleteGroupFromGroupReq: DeleteGroupFromGroupRequest = {
              groupId: String(id),
              groupFromDeleteId: Number(oldParentId),
            };

            const deteleGroupFromGroupRes = await baseQuery({
              url: getUrl(`DeleteGroupFromGroup`),
              params: deleteGroupFromGroupReq,
              method: 'DELETE',
            });

            if (deteleGroupFromGroupRes.error) {
              return {
                error: deteleGroupFromGroupRes.error as FetchBaseQueryError,
              };
            }

            const deteleGroupFromGroupResData =
              deteleGroupFromGroupRes.data as ResponseBase;

            if (deteleGroupFromGroupResData.statusCode !== 0) {
              return {
                error: {
                  error: deteleGroupFromGroupResData.statusDescription,
                  status: `CUSTOM_ERROR`,
                },
              };
            }
          }

          if (!oldParentId && parentId !== '') {
            const addGroupToGroupReq: AddGroupToGroupRequest = {
              groupId: String(id),
              groupToAddId: parentId,
            };

            const addGroupToGroupRes = await baseQuery({
              url: getUrl(`AddGroupToGroup`),
              params: addGroupToGroupReq,
              method: 'POST',
            });

            if (addGroupToGroupRes.error) {
              return {
                error: addGroupToGroupRes.error as FetchBaseQueryError,
              };
            }

            const addGroupToGroupResData =
              addGroupToGroupRes.data as ResponseBase;

            if (addGroupToGroupResData.statusCode !== 0) {
              return {
                error: {
                  error: addGroupToGroupResData.statusDescription,
                  status: `CUSTOM_ERROR`,
                },
              };
            }
          }

          if (oldParentId && parentId && oldParentId !== parentId) {
            const deleteGroupFromGroupReq: DeleteGroupFromGroupRequest = {
              groupId: String(id),
              groupFromDeleteId: Number(oldParentId),
            };

            const deteleGroupFromGroupRes = await baseQuery({
              url: getUrl(`DeleteGroupFromGroup`),
              params: deleteGroupFromGroupReq,
              method: 'DELETE',
            });

            if (deteleGroupFromGroupRes.error) {
              return {
                error: deteleGroupFromGroupRes.error as FetchBaseQueryError,
              };
            }

            const deteleGroupFromGroupResData =
              deteleGroupFromGroupRes.data as ResponseBase;

            if (deteleGroupFromGroupResData.statusCode !== 0) {
              return {
                error: {
                  error: deteleGroupFromGroupResData.statusDescription,
                  status: `CUSTOM_ERROR`,
                },
              };
            }

            const addGroupToGroupReq: AddGroupToGroupRequest = {
              groupId: String(id),
              groupToAddId: parentId,
            };

            const addGroupToGroupRes = await baseQuery({
              url: getUrl(`AddGroupToGroup`),
              params: addGroupToGroupReq,
              method: 'POST',
            });

            if (addGroupToGroupRes.error) {
              return {
                error: addGroupToGroupRes.error as FetchBaseQueryError,
              };
            }

            const addGroupToGroupResData =
              addGroupToGroupRes.data as ResponseBase;

            if (addGroupToGroupResData.statusCode !== 0) {
              return {
                error: {
                  error: addGroupToGroupResData.statusDescription,
                  status: `CUSTOM_ERROR`,
                },
              };
            }
          }

          return { data: { statusCode: 0, statusDescription: '' } };
        },
        invalidatesTags: (result, error, arg) => [
          arg.groupType === 1 ? 'PersonsGroups' : 'ChargePointGroups',
          { type: 'Group', id: arg.id },
        ],
      }
    ),
    customUpdateUsersGroup: builder.mutation<
      ResponseBase,
      CustomUpdateUsersGroupRequest
    >({
      async queryFn(args, _queryApi, _extraOptions, baseQuery) {
        const { oldParentId, parentId, ...group } = args;

        const { id } = args;

        const updateGroupReq: UpdateUserGroupRequest = group;

        const updateGroupRes = await baseQuery({
          url: getUrl(`UpdateGroup`),
          method: 'PATCH',
          body: updateGroupReq,
        });

        if (updateGroupRes.error) {
          return {
            error: updateGroupRes.error as FetchBaseQueryError,
          };
        }

        const updateGroupResData = updateGroupRes.data as GroupBaseResponse;

        if (updateGroupResData.statusCode !== 0) {
          return {
            error: {
              error: updateGroupResData.statusDescription,
              status: `CUSTOM_ERROR`,
            },
          };
        }

        if (oldParentId && parentId === '') {
          const deleteGroupFromGroupReq: DeleteGroupFromGroupRequest = {
            groupId: String(id),
            groupFromDeleteId: Number(oldParentId),
          };

          const deteleGroupFromGroupRes = await baseQuery({
            url: getUrl(`DeleteGroupFromGroup`),
            params: deleteGroupFromGroupReq,
            method: 'DELETE',
          });

          if (deteleGroupFromGroupRes.error) {
            return {
              error: deteleGroupFromGroupRes.error as FetchBaseQueryError,
            };
          }

          const deteleGroupFromGroupResData =
            deteleGroupFromGroupRes.data as ResponseBase;

          if (deteleGroupFromGroupResData.statusCode !== 0) {
            return {
              error: {
                error: deteleGroupFromGroupResData.statusDescription,
                status: `CUSTOM_ERROR`,
              },
            };
          }
        }

        if (!oldParentId && parentId !== '') {
          const addGroupToGroupReq: AddGroupToGroupRequest = {
            groupId: String(id),
            groupToAddId: parentId,
          };

          const addGroupToGroupRes = await baseQuery({
            url: getUrl(`AddGroupToGroup`),
            params: addGroupToGroupReq,
            method: 'POST',
          });

          if (addGroupToGroupRes.error) {
            return {
              error: addGroupToGroupRes.error as FetchBaseQueryError,
            };
          }

          const addGroupToGroupResData =
            addGroupToGroupRes.data as ResponseBase;

          if (addGroupToGroupResData.statusCode !== 0) {
            return {
              error: {
                error: addGroupToGroupResData.statusDescription,
                status: `CUSTOM_ERROR`,
              },
            };
          }
        }

        if (oldParentId && parentId && oldParentId !== parentId) {
          const deleteGroupFromGroupReq: DeleteGroupFromGroupRequest = {
            groupId: String(id),
            groupFromDeleteId: Number(oldParentId),
          };

          const deteleGroupFromGroupRes = await baseQuery({
            url: getUrl(`DeleteGroupFromGroup`),
            params: deleteGroupFromGroupReq,
            method: 'DELETE',
          });

          if (deteleGroupFromGroupRes.error) {
            return {
              error: deteleGroupFromGroupRes.error as FetchBaseQueryError,
            };
          }

          const deteleGroupFromGroupResData =
            deteleGroupFromGroupRes.data as ResponseBase;

          if (deteleGroupFromGroupResData.statusCode !== 0) {
            return {
              error: {
                error: deteleGroupFromGroupResData.statusDescription,
                status: `CUSTOM_ERROR`,
              },
            };
          }

          const addGroupToGroupReq: AddGroupToGroupRequest = {
            groupId: String(id),
            groupToAddId: parentId,
          };

          const addGroupToGroupRes = await baseQuery({
            url: getUrl(`AddGroupToGroup`),
            params: addGroupToGroupReq,
            method: 'POST',
          });

          if (addGroupToGroupRes.error) {
            return {
              error: addGroupToGroupRes.error as FetchBaseQueryError,
            };
          }

          const addGroupToGroupResData =
            addGroupToGroupRes.data as ResponseBase;

          if (addGroupToGroupResData.statusCode !== 0) {
            return {
              error: {
                error: addGroupToGroupResData.statusDescription,
                status: `CUSTOM_ERROR`,
              },
            };
          }
        }

        return { data: { statusCode: 0, statusDescription: '' } };
      },
      invalidatesTags: (result, error, arg) => [
        'PersonsGroups',
        { type: 'Group', id: arg.id },
      ],
    }),
    customUpdateChargePointsGroup: builder.mutation<
      ResponseBase,
      CustomUpdateChargePointsGroupRequest
    >({
      async queryFn(args, _queryApi, _extraOptions, baseQuery) {
        const { oldParentId, parentId, ...group } = args;

        const { id } = args;

        const updateGroupReq: UpdateChargePointsGroupRequest = group;

        const updateGroupRes = await baseQuery({
          url: getUrl(`UpdateGroup`),
          method: 'PATCH',
          body: updateGroupReq,
        });

        if (updateGroupRes.error) {
          return {
            error: updateGroupRes.error as FetchBaseQueryError,
          };
        }

        const updateGroupResData = updateGroupRes.data as GroupBaseResponse;

        if (updateGroupResData.statusCode !== 0) {
          return {
            error: {
              error: updateGroupResData.statusDescription,
              status: `CUSTOM_ERROR`,
            },
          };
        }

        if (oldParentId && parentId === '') {
          const deleteGroupFromGroupReq: DeleteGroupFromGroupRequest = {
            groupId: String(id),
            groupFromDeleteId: Number(oldParentId),
          };

          const deteleGroupFromGroupRes = await baseQuery({
            url: getUrl(`DeleteGroupFromGroup`),
            params: deleteGroupFromGroupReq,
            method: 'DELETE',
          });

          if (deteleGroupFromGroupRes.error) {
            return {
              error: deteleGroupFromGroupRes.error as FetchBaseQueryError,
            };
          }

          const deteleGroupFromGroupResData =
            deteleGroupFromGroupRes.data as ResponseBase;

          if (deteleGroupFromGroupResData.statusCode !== 0) {
            return {
              error: {
                error: deteleGroupFromGroupResData.statusDescription,
                status: `CUSTOM_ERROR`,
              },
            };
          }
        }

        if (!oldParentId && parentId !== '') {
          const addGroupToGroupReq: AddGroupToGroupRequest = {
            groupId: String(id),
            groupToAddId: parentId,
          };

          const addGroupToGroupRes = await baseQuery({
            url: getUrl(`AddGroupToGroup`),
            params: addGroupToGroupReq,
            method: 'POST',
          });

          if (addGroupToGroupRes.error) {
            return {
              error: addGroupToGroupRes.error as FetchBaseQueryError,
            };
          }

          const addGroupToGroupResData =
            addGroupToGroupRes.data as ResponseBase;

          if (addGroupToGroupResData.statusCode !== 0) {
            return {
              error: {
                error: addGroupToGroupResData.statusDescription,
                status: `CUSTOM_ERROR`,
              },
            };
          }
        }

        if (oldParentId && parentId && oldParentId !== parentId) {
          const deleteGroupFromGroupReq: DeleteGroupFromGroupRequest = {
            groupId: String(id),
            groupFromDeleteId: Number(oldParentId),
          };

          const deteleGroupFromGroupRes = await baseQuery({
            url: getUrl(`DeleteGroupFromGroup`),
            params: deleteGroupFromGroupReq,
            method: 'DELETE',
          });

          if (deteleGroupFromGroupRes.error) {
            return {
              error: deteleGroupFromGroupRes.error as FetchBaseQueryError,
            };
          }

          const deteleGroupFromGroupResData =
            deteleGroupFromGroupRes.data as ResponseBase;

          if (deteleGroupFromGroupResData.statusCode !== 0) {
            return {
              error: {
                error: deteleGroupFromGroupResData.statusDescription,
                status: `CUSTOM_ERROR`,
              },
            };
          }

          const addGroupToGroupReq: AddGroupToGroupRequest = {
            groupId: String(id),
            groupToAddId: parentId,
          };

          const addGroupToGroupRes = await baseQuery({
            url: getUrl(`AddGroupToGroup`),
            params: addGroupToGroupReq,
            method: 'POST',
          });

          if (addGroupToGroupRes.error) {
            return {
              error: addGroupToGroupRes.error as FetchBaseQueryError,
            };
          }

          const addGroupToGroupResData =
            addGroupToGroupRes.data as ResponseBase;

          if (addGroupToGroupResData.statusCode !== 0) {
            return {
              error: {
                error: addGroupToGroupResData.statusDescription,
                status: `CUSTOM_ERROR`,
              },
            };
          }
        }

        return { data: { statusCode: 0, statusDescription: '' } };
      },
      invalidatesTags: (result, error, arg) => [
        'ChargePointGroups',
        { type: 'Group', id: arg.id },
      ],
    }),
    customDeleteGroup: builder.mutation<ResponseBase, CustomDeleteGroupRequest>(
      {
        async queryFn(args, _queryApi, _extraOptions, baseQuery) {
          const { childrenGroups, id, parentId } = args;

          for (const childGroup of childrenGroups) {
            const deleteGroupFromGroupReq: DeleteGroupFromGroupRequest = {
              groupId: String(childGroup.id),
              groupFromDeleteId: Number(id),
            };

            const deteleGroupFromGroupRes = await baseQuery({
              url: getUrl(`DeleteGroupFromGroup`),
              params: deleteGroupFromGroupReq,
              method: 'DELETE',
            });

            if (deteleGroupFromGroupRes.error) {
              return {
                error: deteleGroupFromGroupRes.error as FetchBaseQueryError,
              };
            }

            const deteleGroupFromGroupResData =
              deteleGroupFromGroupRes.data as ResponseBase;

            if (deteleGroupFromGroupResData.statusCode !== 0) {
              return {
                error: {
                  error: deteleGroupFromGroupResData.statusDescription,
                  status: `CUSTOM_ERROR`,
                },
              };
            }
          }

          // TODO: проверить откуда 0
          if (parentId !== 0) {
            const deleteGroupFromGroupReq: DeleteGroupFromGroupRequest = {
              groupId: String(id),
              groupFromDeleteId: Number(parentId),
            };

            const deteleGroupFromGroupRes = await baseQuery({
              url: getUrl(`DeleteGroupFromGroup`),
              params: deleteGroupFromGroupReq,
              method: 'DELETE',
            });

            if (deteleGroupFromGroupRes.error) {
              return {
                error: deteleGroupFromGroupRes.error as FetchBaseQueryError,
              };
            }

            const deteleGroupFromGroupResData =
              deteleGroupFromGroupRes.data as ResponseBase;

            if (deteleGroupFromGroupResData.statusCode !== 0) {
              return {
                error: {
                  error: deteleGroupFromGroupResData.statusDescription,
                  status: `CUSTOM_ERROR`,
                },
              };
            }
          }

          const deleteRes = await baseQuery({
            url: getUrl(`DeleteGroup`),
            params: { id },
            method: 'DELETE',
          });

          if (deleteRes.error) {
            return {
              error: deleteRes.error as FetchBaseQueryError,
            };
          }

          const deleteResData = deleteRes.data as ResponseBase;

          if (deleteResData.statusCode !== 0) {
            return {
              error: {
                error: deleteResData.statusDescription,
                status: `CUSTOM_ERROR`,
              },
            };
          }

          return { data: { statusCode: 0, statusDescription: '' } };
        },
        invalidatesTags: (result, error, arg) => [
          arg.groupType === 1 ? 'PersonsGroups' : 'ChargePointGroups',
        ],
      }
    ),
  }),
});

export const {
  useGetGroupQuery,
  useGetChargePointGroupsQuery,
  useGetPersonsGroupsQuery,
  useCustomAddGroupMutation,
  useCustomUpdateUsersGroupMutation,
  useCustomUpdateChargePointsGroupMutation,
  useCustomDeleteGroupMutation,
} = groupsApi;
