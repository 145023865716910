import { GraphMetricType } from '../model/metrics';

export const GRAPTH_METRIC_TYPE: Record<GraphMetricType, string> = {
  0: 'Количество успешных сессий',
  1: 'Количество потребленной энергии',
  2: 'Количество полученных денег',
};

export const METRIC_RESPONSE_DATE_FORMAT = 'DD-MM-YYYY';
export const METRIC_REQUEST_DATE_FORMAT = 'YYYY-MM-DD';
