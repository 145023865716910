import { Link } from 'react-router-dom';

import { NavigationTabButton } from 'shared/ui';
import { GROUP_ROUTES } from 'shared/consts/routes/group';

export function AddUsersGroupButtonLink() {
  return (
    <Link to={GROUP_ROUTES.ADD_GROUP}>
      <NavigationTabButton title="Добавить группу" />
    </Link>
  );
}
