import { RcFile } from 'antd/lib/upload/interface';
import { useNavigate } from 'react-router-dom';
import { FIRMWARE_ROUTES } from 'shared/consts/routes/firmware';

import {
  isErrorWithMessage,
  openErrorNotification,
  openSuccessNotification,
} from 'shared/lib';

import {
  FirmwareForm,
  FirmwareFormData,
  prepareRequestData,
  useAddFirmwareMutation,
} from 'entities/firmware';

import { ADD_ERROR, ADD_SUCCESS } from '../consts';

const initialValues: FirmwareFormData = {
  id: '',
  description: '',
  files: [],
  formatUrl: '',
};

export function AddFirmware() {
  const [trigger, { isLoading }] = useAddFirmwareMutation();

  const navigate = useNavigate();

  const handleSubmit = async (values: FirmwareFormData) => {
    const { id, files, ...rest } = prepareRequestData(values);

    const formData = new FormData();

    files.forEach((file) => {
      formData.append('fileFirmware', file.originFileObj as RcFile);
    });

    try {
      const res = await trigger({
        ...rest,
        fileFirmware: formData,
      }).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);

        return;
      }
      //

      openSuccessNotification(ADD_SUCCESS);

      navigate(FIRMWARE_ROUTES.FIRMWARES);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : ADD_ERROR;

      openErrorNotification(errorText);
    }
  };

  return (
    <FirmwareForm
      title="Добавление новой прошивки"
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      loading={isLoading}
    />
  );
}
