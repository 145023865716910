import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import { getEndpointPath } from 'shared/lib';

import { PLATFORM_SERVICE } from 'entities/authorization';

import {
  AddPersonFormRequest,
  UpdatePersonFormRequest,
  PersonDtoResponse,
  CustomAddPersonRequest,
  CustomUpdatePersonRequest,
  UpdatePersonalInformationFormRequest,
} from 'entities/person';

import { apiSlice } from './apiSlice';

const KEY = 'Person';

const getUrl = getEndpointPath(PLATFORM_SERVICE, KEY);

export const personsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addPerson: builder.mutation<PersonDtoResponse, AddPersonFormRequest>({
      query: (person) => ({
        url: getUrl('AddPerson'),
        method: 'POST',
        body: person,
      }),
      invalidatesTags: ['Persons'],
    }),
    updatePerson: builder.mutation<PersonDtoResponse, UpdatePersonFormRequest>({
      query: (person) => ({
        url: getUrl('UpdatePerson'),
        method: 'PATCH',
        body: person,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Person', id: arg.login },
        'Persons',
      ],
    }),

    customAddPerson: builder.mutation<ResponseBase, CustomAddPersonRequest>({
      async queryFn(args, _queryApi, _extraOptions, baseQuery) {
        const { firstName, lastName, patronymic, dateOfBirth, sex, ...rest } =
          args;

        const addPersonRes = await baseQuery({
          url: getUrl(`AddPerson`),
          method: 'POST',
          body: {
            ...rest,
          },
        });

        if (addPersonRes.error) {
          return {
            error: addPersonRes.error as FetchBaseQueryError,
          };
        }

        const addPersonResData = addPersonRes.data as PersonDtoResponse;

        if (addPersonResData.statusCode !== 0) {
          return {
            error: {
              error: addPersonResData.statusDescription,
              status: `CUSTOM_ERROR`,
            },
          };
        }

        const personId = addPersonResData.person.id;

        const updatePersonInformationReq: UpdatePersonalInformationFormRequest =
          {
            id: personId,
            firstName,
            lastName,
            patronymic,
            dateOfBirth,
            sex,
          };

        const updatePersonInformationRes = await baseQuery({
          url: getUrl('UpdatePersonalInformation'),
          method: 'PATCH',
          body: updatePersonInformationReq,
        });

        if (updatePersonInformationRes.error) {
          return {
            error: updatePersonInformationRes.error as FetchBaseQueryError,
          };
        }

        const updatePersonInformationResData =
          updatePersonInformationRes.data as PersonDtoResponse;

        if (updatePersonInformationResData.statusCode !== 0) {
          return {
            error: {
              error: updatePersonInformationResData.statusDescription,
              status: `CUSTOM_ERROR`,
            },
          };
        }

        return { data: { statusCode: 0, statusDescription: '' } };
      },
      invalidatesTags: ['Persons'],
    }),
    customUpdatePerson: builder.mutation<
      ResponseBase,
      CustomUpdatePersonRequest
    >({
      async queryFn(args, _queryApi, _extraOptions, baseQuery) {
        const { firstName, lastName, patronymic, dateOfBirth, sex, ...rest } =
          args;

        const updatePersonRes = await baseQuery({
          url: getUrl(`UpdatePerson`),
          method: 'PATCH',
          body: {
            ...rest,
          },
        });

        if (updatePersonRes.error) {
          return {
            error: updatePersonRes.error as FetchBaseQueryError,
          };
        }

        const updatePersonResData = updatePersonRes.data as PersonDtoResponse;

        if (updatePersonResData.statusCode !== 0) {
          return {
            error: {
              error: updatePersonResData.statusDescription,
              status: `CUSTOM_ERROR`,
            },
          };
        }

        const personId = updatePersonResData.person.id;

        const updatePersonInformationReq: UpdatePersonalInformationFormRequest =
          {
            id: personId,
            firstName,
            lastName,
            patronymic,
            dateOfBirth,
            sex,
          };

        const updatePersonInformationRes = await baseQuery({
          url: getUrl('UpdatePersonalInformation'),
          method: 'PATCH',
          body: updatePersonInformationReq,
        });

        if (updatePersonInformationRes.error) {
          return {
            error: updatePersonInformationRes.error as FetchBaseQueryError,
          };
        }

        const updatePersonInformationResData =
          updatePersonInformationRes.data as PersonDtoResponse;

        if (updatePersonInformationResData.statusCode !== 0) {
          return {
            error: {
              error: updatePersonInformationResData.statusDescription,
              status: `CUSTOM_ERROR`,
            },
          };
        }

        return { data: { statusCode: 0, statusDescription: '' } };
      },
      invalidatesTags: (result, error, arg) => [
        { type: 'Person', id: arg.login },
        'Persons',
      ],
    }),
  }),
});

export const {
  useAddPersonMutation,
  useUpdatePersonMutation,

  // Кастомные query/mutations
  useCustomAddPersonMutation,
  useCustomUpdatePersonMutation,
} = personsApi;
