import { ADD_PREFIX, SETTINGS } from './common';

export const MOBILE_APPS = 'mobile-apps';
export const MOBILE_APP = ':mobileAppId';
export const RIGHTS = 'rights';

export const MOBILE_APP_ROUTES = {
  MOBILE_APPS: `/${SETTINGS}/${MOBILE_APPS}`,
  MOBILE_APP: `/${SETTINGS}/${MOBILE_APPS}/${MOBILE_APP}`,
  MOBILE_APP_RIGHTS: `/${SETTINGS}/${MOBILE_APPS}/${MOBILE_APP}/${RIGHTS}`,
  ADD_MOBILE_APP: `/${SETTINGS}/${MOBILE_APPS}/${ADD_PREFIX}`,
};
