import styled from 'styled-components';

export const DraggerText = styled.p`
  margin-bottom: 5px;
`;

export const DraggerTextWrapper = styled.div`
  margin-bottom: 15px;
`;

type UploadListPictureWrapperProps = {
  isError: boolean;
};

export const UploadListPictureWrapper = styled.div<UploadListPictureWrapperProps>``;

export const FormButtonsContainer = styled.div`
  margin-top: 50px;
`;
