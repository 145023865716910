import { EmptyData, NavMenuError } from 'shared/ui';
import { SectionMenu } from 'shared/ui/section-menu';
import { ROLE_ROUTES } from 'shared/consts/routes/role';

import { SECTION } from 'entities/navigation';
import { useGetRolesQuery } from 'entities/role';

const { label, path } = SECTION['roles'];

export function ConnectedRolesMenu() {
  const { data, isFetching, error } = useGetRolesQuery();

  if (data && data.statusCode !== 0) {
    return <NavMenuError text={data.statusDescription} />;
  }

  if (error) {
    return <NavMenuError />;
  }

  if (!isFetching && !data) {
    return <EmptyData />;
  }

  const items = (data?.roles || []).map(({ id, name }) => ({
    id: encodeURIComponent(name),
    name,
  }));

  return (
    <SectionMenu
      loading={isFetching}
      items={items}
      menuKey={path}
      absolutePath={ROLE_ROUTES.ROLES}
      label={label}
    />
  );
}
