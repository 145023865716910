import { Route } from 'react-router-dom';

import {
  MOBILE_APP,
  MOBILE_APPS,
  RIGHTS,
} from 'shared/consts/routes/mobile-app';
import { ADD_PREFIX } from 'shared/consts/routes/common';
import { MainContentLayout, SectionLayout } from 'shared/ui/layout';

import { RequireAuth } from 'entities/authorization';
import { ConnectedMobileAppsMenu } from 'entities/mobile-app';
import { MobileAppLayout } from 'entities/mobile-app/mobile-app-layout';

import { AddMobileApp } from 'features/mobile-app/add';
import { EditMobileApp } from 'features/mobile-app/edit';

// TODO
import { ConnectedMobileAppRights } from '../../../components/Settings/MobileApp/components/ConnectedMobileAppRights';

export const MobileAppRoutes = (
  <Route element={<RequireAuth controllerName="MobileApp" rightType="Write" />}>
    <Route
      path={MOBILE_APPS}
      element={<SectionLayout menu={<ConnectedMobileAppsMenu />} />}
    >
      <Route
        element={<RequireAuth controllerName="MobileApp" rightType="Write" />}
      >
        <Route
          path={ADD_PREFIX}
          element={<MainContentLayout element={<AddMobileApp />} />}
        />
      </Route>
      <Route path={MOBILE_APP} element={<MobileAppLayout />}>
        <Route index element={<EditMobileApp />} />
        <Route path={RIGHTS} element={<ConnectedMobileAppRights />} />
      </Route>
    </Route>
  </Route>
);
