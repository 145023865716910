import { BonusProgramLevel } from 'entities/bonus-program/model/levels';

import { OpenAddBonusLevelModalButton } from 'features/bonus/add-bonus-level/ui/open-modal-button';

import { Level } from '../level';
import { LevelsContainer } from './styles';

type Props = {
  levels: Array<BonusProgramLevel>;
};

export function BonusProgramLevels({ levels }: Props) {
  let content: React.ReactNode;

  if (levels.length === 0) {
    content = <div>Нет уровней</div>;
  } else {
    const sorted = [...levels].sort((a, b) => {
      return a.level - b.level;
    });

    content = sorted.map((el) => {
      return <Level key={el.id} level={el} />;
    });
  }

  return (
    <LevelsContainer>
      {content}
      <OpenAddBonusLevelModalButton />
    </LevelsContainer>
  );
}
